import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as CloseIcon } from '../../assets/icons/property/close.svg';
import Button from '../../components/common/button/button';
import IconContainer from '../../components/common/icon-container';
import InputElement from '../../components/common/input';
import Dropdown from '../../components/common/select-dropdown';
import Switch from '../../components/common/switch';
import { OrganisationContext } from '../../context/organisationContext';
import { initModal } from '../../helpers/utils';

const AddFamily = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [family, setFamily] = useState({
    id: nanoid(),
    priority: 0,
    name: '',
    is_default: false,
    description: '',
    icon: '',
    tag: '',
  });

  const { modal, setModal } = useContext(OrganisationContext);
  const { content } = modal;
  const { product, updateProductInfo } = content;

  const { name, description, is_default } = family;

  function updateFamily(key, value) {
    setFamily({ ...family, [key]: value });
  }

  function handleAddFeature() {
    updateProductInfo('families', [family, ...product.families]);
    setModal(initModal);
  }

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <AddFamilyWrapper>
        <div className="px-10 py-9">
          <div className="flex justify-between">
            <div>
              <p className="inter-600-text font-20 line-height-28 natural-900-text ">{t('NEW_FAMILY')}</p>
            </div>
            <IconContainer
              iconContainerClassname="cursor"
              onClick={() => setModal(initModal)}
              backgroundColor={'white'}
              Icon={CloseIcon}
            />
          </div>

          <div className="mt-8">
            <InputElement
              name={t('NAME')}
              placeholder={t('NAME_PLACEHOLDER')}
              value={name}
              onChange={e => updateFamily('name', e)}
            />
          </div>

          <div className="mt-6">
            <InputElement
              type="textarea"
              name={t('DESCRIPTION')}
              placeholder={t('DESCRIPTION_PLACEHOLDER')}
              value={description}
              onChange={e => updateFamily('description', e)}
            />
          </div>

          <div className="mt-6">
            <Dropdown options={[]} name={t('TAG')} placeholder={t('TAG_PLACEHOLDER')} />
          </div>

          <div className="mt-6 flex items-center justify-between">
            <div>
              <p className="inter-500-text font-14 line-height-20 natural-900-text">{t('DEFAULT')}</p>
            </div>
            <Switch enabled={is_default} onClick={() => updateFamily('is_default', !is_default)} />
          </div>

          <div className="mt-10 flex gap-5">
            <Button
              label={t('CANCEL')}
              onClick={() => setModal(initModal)}
              size="large"
              className="py-3"
              bgColor={theme.white}
              borderColor={theme.natural_200}
              color={theme.natural_700}
            />
            <Button
              label={t('ADD')}
              size="large"
              className="py-3"
              onClick={handleAddFeature}
              borderColor={theme.primary_500}
              bgColor={theme.primary_500}
              color={theme.white}
              disabled={!(name && description)}
            />
          </div>
        </div>
      </AddFamilyWrapper>
    </CSSTransition>
  );
};

export default AddFamily;

const AddFamilyWrapper = styled.div`
  width: 455px;
  max-height: 775px;
`;
