import styled from 'styled-components';
const QuoteTemplateWrapper = styled.div`
  border-radius: 6px;

  .template-edit-dropdown {
    width: 322px;
    height: 32px !important;
    .css-6pstop-container {
      max-width: 100%;
      .searchable-select__control {
        height: 32px;
        border: 1px solid ${({ theme }) => theme.natural_300};
      }
    }
  }

  .edit-template-quote {
    width: 322px;
    height: 32px !important;
    padding: 2px 8px;
    border-radius: 6px;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.natural_100} !important;
      .o0 {
        opacity: 1;
      }
    }
  }
`;

export default QuoteTemplateWrapper;
