import { createSlice } from '@reduxjs/toolkit';
import { getItemFromLocalStorage } from '../../helpers/localstorage';
import api from '../services';

export const teamsManagementSlice = createSlice({
  name: 'teamsManagement',
  initialState: {
    teams: [],
    newTeam: [],
    teamDetails: null,
    teamMembers: null,
  },
  reducers: {
    setTeams: (state, { payload }) => {
      const { data, merge } = payload;
      if (merge) {
        state.teams = { ...data, content: [...state.teams.content, ...data.content] };
      } else {
        state.teams = data;
      }
    },
    setTeamDetails: (state, { payload }) => {
      state.teamDetails = payload;
    },
    setTeamMembers: (state, { payload }) => {
      state.teamMembers = payload;
    },
    removeTeamFromList: (state, { payload }) => {
      state.teams.content = [...state.teams.content.filter(team => team.id !== payload.id)];
    },
    removeTeamMemberFromList: (state, { payload }) => {
      state.teamMembers = [...state.teamMembers.filter(member => member.user?.id !== payload.id)];
    },
    setServices: (state, { payload }) => {
      state.services = payload;
    },
    addNewTeamList: (state, { payload }) => {
      state.newTeam = [...state.newTeam, payload];
    },
  },
});

export const getTeamsList = payload => async dispatch => {
  try {
    const localUser = getItemFromLocalStorage('user');
    const { merge, params, forFetchOnly = false } = payload;
    const { data } = await api.get(`/organizations/${localUser?.organizations[0]?.id}/teams`, {
      params: params,
    });
    !forFetchOnly && dispatch(setTeams({ data, merge }));
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getTeamDetails = payload => async dispatch => {
  try {
    const localUser = getItemFromLocalStorage('user');
    const { data } = await api.get(`/organizations/${localUser?.organizations[0]?.id}/teams/${payload.teamId}`);
    dispatch(setTeamDetails(data));
    return Promise.resolve({ data });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getTeamMembers = payload => async dispatch => {
  try {
    const localUser = getItemFromLocalStorage('user');
    const { data } = await api.get(`/organizations/${localUser?.organizations[0]?.id}/teams/${payload.teamId}/users`, {
      params: payload.params,
    });
    dispatch(setTeamMembers(data));
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteTeam = payload => async dispatch => {
  const { organization_id, id } = payload;
  try {
    const { data } = await api.delete(`/organizations/${organization_id}/teams/${id}`);
    dispatch(removeTeamFromList(payload));
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getServices = () => async dispatch => {
  const localUser = getItemFromLocalStorage('user');
  try {
    const { data } = await api.get(`/organizations/${localUser?.organizations[0]?.id}/services`);
    dispatch(setServices(data));
    return Promise.resolve({ data });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const createNewTeam = payload => async dispatch => {
  const localUser = getItemFromLocalStorage('user');
  const organization_id = localUser?.organizations[0]?.id;
  try {
    const { data } = await api.post(`/organizations/${organization_id}/teams`, payload.request);
    dispatch(addNewTeamList(data));
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteUserFromTeam =
  ({ organization_id, team_id, user_id }) =>
  async dispatch => {
    try {
      const { data } = await api.delete(`/organizations/${organization_id}/teams/${team_id}/users/${user_id}`);
      dispatch(removeTeamMemberFromList({ id: user_id }));
      return Promise.resolve({ data });
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const {
  setTeams,
  removeTeamFromList,
  setTeamDetails,
  setTeamMembers,
  setServices,
  addNewTeamList,
  removeTeamMemberFromList,
} = teamsManagementSlice.actions;
export default teamsManagementSlice.reducer;
