import classNames from 'classnames';
import { motion } from 'framer-motion';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as AddIcon } from '../../assets/icons/add-icon.svg';
import { ReactComponent as EmptyIcon } from '../../assets/icons/empty-document.svg';
import { ReactComponent as LeftIcon } from '../../assets/images/arrow-left.svg';
import { OrganisationContext } from '../../context/organisationContext';
import useDebounce from '../../helpers/useDebounceHook';
import { capitalize, formatText, getUserInitials } from '../../helpers/utils';
import { getActivityList, getActivityTasks, getCancleReason } from '../../store/features/activitiesSlice';
import { getNoteList } from '../../store/features/notesSlice';
import { getEvidences } from '../../store/features/propertySlice';
import { addToast } from '../../store/features/toastSlice';
import ActivitiesList from '../activities-list';
import AvatarGroup from '../common/avatar-group';
import Button from '../common/button/button';
import InfiniteScrollV2 from '../common/infinite-scroll-v2';
import InputSearch from '../common/input-search';
import MenuV2 from '../common/menu/menu-v2';
import NoData from '../common/no-data';
import SkeletonTransition from '../common/skeleton-transition';
import Files from '../files';
import NotesList from '../notes';
import Tasks from '../task-list';

const ActivityTabs = ({ selectedTab, setSelectedTab, tasksListLength, notesLength, filesLength, activityLength }) => {
  const { hasMultiplePermission } = useContext(OrganisationContext);

  const defaultTabs = [
    { name: 'Resources', length: activityLength, key: 'RESOURCES' },
    { name: 'Tasks', length: tasksListLength, key: 'TASKS', permissions: ['TASKS_VIEW', 'TASKS_VIEW_OWN'] },
    { name: 'Files', length: filesLength, key: 'FILES' },
    { name: 'Forms', length: 0, key: 'FORMS' },
    { name: 'Notes', length: notesLength, key: 'NOTES' },
  ];

  return (
    <div className="flex border-bottom justify-between items-center">
      <div className="flex gap-3">
        {defaultTabs
          .filter(tab => !tab.permissions || hasMultiplePermission(tab.permissions))
          .map(tab => (
            <div
              key={tab.key}
              onClick={() => {
                setSelectedTab(tab.key);
              }}
              className={classNames('p-4 cursor flex col-gap-2', selectedTab === tab.key && 'selected-tab')}>
              <label className="inter-500-text natural-700-text">{tab.name}</label>
              <label
                className={classNames(
                  'item-length inter-500-text natural-700-text px-2_5 py-0_5 radius-6 font-12',
                  selectedTab === tab.key ? 'bg-lightgray-1-active' : 'bg-lightgray-1',
                )}>
                {tab.length}
              </label>
            </div>
          ))}
      </div>
    </div>
  );
};

const PropertyActivities = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pageRef = useRef(null);

  const { setModal, hasMultiplePermission } = useContext(OrganisationContext);

  const { workitemDetails } = useSelector(state => state.board);
  const engagement_id = workitemDetails?.engagement?.id;

  const [activities, setActivities] = useState({ content: [] });
  const [showActivity, setShowActivity] = useState('');
  const [selectedTab, setSelectedTab] = useState('RESOURCES');
  const [activityNotesList, setActivityNotesList] = useState({});
  const [reasonList, setReasoneList] = useState({});
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [tasksList, setTasksList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [files, setFiles] = useState([]);

  const debouncedSearch = useDebounce(search, 500);
  const hasTaskViewPermission = hasMultiplePermission(['TASKS_VIEW', 'TASKS_VIEW_OWN']);

  const fetchActivityTasks = id => {
    if (!hasTaskViewPermission) return;
    setLoading(true);
    dispatch(getActivityTasks({ params: { activity_id: id, size: 100 } }))
      .then(data => {
        const { content } = data;
        setTasksList(content);
        setLoading(false);
      })
      .catch(() => dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_TASKS') })))
      .finally(() => setLoading(false));
  };

  const fetchNotesList = id => {
    dispatch(getNoteList({ engagement_id: engagement_id, params: { activity_id: id } }))
      .then(data => {
        setActivityNotesList(data);
      })
      .catch(() => dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_NOTES_LIST') })))
      .finally(() => setLoading(false));
  };

  const fetchResoneList = id => {
    dispatch(getCancleReason())
      .then(data => {
        setReasoneList(data);
      })
      .catch(() => dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_NOTES_LIST') })))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (showActivity) {
      fetchActivityTasks(showActivity);
      fetchNotesList(showActivity);
    }
  }, [showActivity]);

  useEffect(() => {
    fetchResoneList();
  }, []);

  const isActivityOpened = activity => {
    return activity.id === showActivity;
  };

  const fetchActivityList = (page, merge, debouncedSearch, showLoading = true) => {
    pageRef.current = page;
    if (showLoading && !merge) {
      setLoading(true);
    }
    dispatch(
      getActivityList({
        engagement_id: engagement_id,
        params: {
          page: page,
          size: 20,
          search: debouncedSearch,
          sort_by: 'EXPECTED_DATE',
          order_by: 'DESC',
        },
      }),
    )
      .then(data => {
        if (merge) {
          setActivities({ ...data, content: [...activities.content, ...data.content] });
        } else {
          setActivities(data);
        }
        pageRef.current = page;
      })
      .catch(() => dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_ACTIVITIES') })))
      .finally(() => setLoading(false));
  };

  const fetchMoreData = () => {
    fetchActivityList(pageRef.current + 1, true, debouncedSearch, false);
  };

  useEffect(() => {
    fetchActivityList(0, false, debouncedSearch, true);
  }, [debouncedSearch]);

  const onAddActivity = () => {
    setModal({
      type: 'add-activity',
      content: {
        engagement: workitemDetails?.engagement,
        onSuccess: () => fetchActivityList(0, false, debouncedSearch, true),
      },
    });
  };

  const fetchFiles = id => {
    dispatch(getEvidences({ params: { activity_id: id } }))
      .then(data => setFiles(data))
      .catch(() => {
        dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_FILES') }));
        setFiles([]);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (showActivity) {
      fetchFiles(showActivity);
    }

    return () => {
      setFiles([]);
    };
  }, [showActivity]);

  const onCancel = activity => {
    setModal({
      type: 'delete-activity',
      content: {
        activity: activity,
        onSuccess: () => fetchActivityList(0, false, debouncedSearch, true),
      },
      reasonList: { reasonList },
      title: t('CANCEL_THIS_ACTIVITY'),
      description: t('REASON_OF_YOUR_CANCELLATION'),
    });
  };

  const onReschedule = activity => {
    setModal({
      type: 'add-activity',
      content: {
        activity: activity,
        engagement: activity?.engagement,
        isReschedule: true,
        onSuccess: () => fetchActivityList(0, false, debouncedSearch, true),
      },
    });
  };

  const activityItem = activity => {
    const { id: activityId, resources, task_count, actual_date, expected_date } = activity || {};
    const { start_date, duration } = actual_date || {};
    const { start_date: expected_start_date, duration: expected_duration } = expected_date || {};

    const startTimeMoment = moment.unix(start_date || expected_start_date);

    return (
      <ActivityItemWrapper
        key={`activity-list-${activityId}`}
        initial={{ opacity: 0.3, x: 10 }}
        animate={{ opacity: 1, x: 0, transition: { duration: 0.9 } }}
        className="flex-column gap-3 overflow-scroll"
        onClick={() => setSelectedActivity(activity)}>
        <div
          key={activityId}
          className="activity-list flex justify-between border radius-1_5  p-3 cursor"
          onClick={() => (isActivityOpened(activity) ? setShowActivity('') : setShowActivity(activity.id))}>
          <div className="flex items-center justify-between gap-3">
            <div className="flex-column items-center px-3 py-1_5 radius-1 bg-natural-50">
              <label className="error-text block inter-600-text font-10 text-uppercase">
                {startTimeMoment?.format('MMM')}
              </label>
              <label className="inter-600-text font-18 natural-900-text">{startTimeMoment?.get('date')}</label>
            </div>
            <div className="flex-column justify-between">
              <label className="font-14 inter-500-text natural-900-text">{activity?.name}</label>
              <label className="block font-14 inter-400-text natural-500-text">{`${startTimeMoment?.format(
                'HH:mm',
              )} - ${startTimeMoment
                ?.clone()
                .add(duration || expected_duration, 'minutes')
                .format('HH:mm')}`}</label>
            </div>
          </div>
          <div className="other-activity items-center justify-center">
            <div className="text-right pr-4">
              <div className="flex items-center">
                <label
                  className={classNames(
                    'px-3 py-0_5 radius-6 inter-400-text font-12 mr-3 w-fit-content',
                    activity?.status === 'COMPLETED' && 'bg-lightblue color-blue',
                    activity?.status === 'PENDING' && 'bg-pink color-purple',
                    activity?.status === 'CANCELLED' && 'bg-lightgray-1 color-light-black-1',
                    activity?.status === 'POSTPONED' && 'bg-lightyellow color-yellow',
                  )}>
                  {capitalize(formatText(activity?.status))}
                </label>
                <AvatarGroup
                  avatars={resources}
                  avatarVariant="medium"
                  getAvatarName={user => getUserInitials(user?.user)}
                />
                <MenuV2
                  menuList={[
                    { name: t('RESCHEDULE'), onClick: () => onReschedule(activity), permission: 'ACTIVITIES_MANAGE' },
                    { name: t('CANCEL'), onClick: () => onCancel(activity), permission: 'ACTIVITIES_MANAGE' },
                  ].filter(Boolean)}
                  iconClassname="rotate-90 dots-icon ml-3"
                />
              </div>
              <label className="font-12 inter-400-text natural-900-text">{task_count || 0} tasks</label>
            </div>
            {/* <div className="pl-3 accordion-icon-wrapper">
              {isActivityOpened(activity) ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </div> */}
          </div>
        </div>
        <div className={classNames('flex-column tasks-details', isActivityOpened(activity) && 'opened-activity')}>
          <div className="flex-column row-gap-4">
            <ActivityTabs
              selectedTab={selectedTab}
              tasksListLength={tasksList?.length}
              notesLength={activityNotesList?.content?.length}
              activityLength={resources?.length}
              setSelectedTab={setSelectedTab}
              filesLength={files?.length}
            />
            {selectedTab === 'RESOURCES' && (
              <ActivitiesList accordionContent={resources} loading={loading} arrows={true} />
            )}
            {selectedTab === 'TASKS' && (
              <Tasks
                accordionContent={tasksList}
                activityId={activityId}
                fetchActivityTasks={() => fetchActivityTasks(showActivity)}
                loading={loading}
                arrows={true}
                resources={resources}
              />
            )}
            {selectedTab === 'NOTES' && (
              <NotesList
                notesHeader={false}
                noteListClassName={'activity-note-list flex-1'}
                activityNotesList={activityNotesList}
              />
            )}
            {selectedTab === 'FORMS' && (
              <NoData
                className="w-full"
                title={t('NO_FORMS')}
                description={t('NO_FORMS_HERE')}
                EmptyIcon={EmptyIcon}
                iconClassName="relative"
              />
            )}
            {selectedTab === 'FILES' && <Files setFiles={setFiles} files={files} loading={loading} />}
          </div>
        </div>
      </ActivityItemWrapper>
    );
  };

  useEffect(() => {
    if (selectedActivity) {
      const updatedSelectedActivity = activities.content.find(data => data.id === selectedActivity?.id) || null;
      if (updatedSelectedActivity) setSelectedActivity(updatedSelectedActivity);
    }
  }, [activities]);

  return (
    <ShowActivityListWrapper className="pxy-6 flex-column overflow-hidden">
      {selectedActivity ? (
        <div className="flex-column flex-1 overflow-hidden">
          <div
            className="flex items-center gap-2 mb-6_5 cursor"
            onClick={() => {
              setSelectedActivity(null);
              setShowActivity('');
            }}>
            <LeftIcon width={18} height={18} />
            <label className="inter-500-text font-14 natural-700-text">{t('BACK_TO_ACTIVITIES')}</label>
          </div>
          {activityItem(selectedActivity)}
        </div>
      ) : (
        <div className="flex-column flex-1 overflow-scroll">
          <div className="flex items-center justify-between pb-7 pt-1">
            <div className="flex items-center">
              <div className="pl-1">
                <InputSearch placeholder={'Search'} value={search} onChange={setSearch} />
              </div>
            </div>
            <div className="flex">
              <Button
                fontSize="14px"
                size="average"
                width="136px"
                borderRadius="100px"
                className="primary specified-width px-4 py-2_5 ml-3"
                afterIcon={<AddIcon className="mr-0 white-text" height={16} width={16} />}
                label={t('ADD_ACTIVITY')}
                lableSize="w-82px line-height-20"
                onClick={onAddActivity}
              />
            </div>
          </div>
          <div className="notes-list-deatils flex-1">
            <div className="w-full flex-column items-center row-gap-6 flex-1">
              <SkeletonTransition
                loading={loading}
                loaderClassName="item w-full flex items-center justify-center flex-1 mb-6"
                height={'50vh'}
                containerClassName="line-height-1 flex-1"
                baseColor="#CDCDCD"
                highlightColor="#E8E8E8">
                {activities?.content?.length > 0 ? (
                  <>
                    <InfiniteScrollV2
                      infiniteScrollClassName="w-full"
                      hasMore={!activities?.last}
                      fetchMoreData={fetchMoreData}>
                      <div className="flex-column row-gap-3 overflow-scroll">
                        {(activities?.content || []).map(activity => activityItem(activity))}
                      </div>
                    </InfiniteScrollV2>
                  </>
                ) : (
                  <NoData
                    title={t('NO_ACTIVITIES')}
                    description={t('NO_ACTIVITIES_HERE')}
                    className="flex-1"
                    EmptyIcon={EmptyIcon}
                    iconClassName="relative"
                  />
                )}
              </SkeletonTransition>
            </div>
          </div>
        </div>
      )}
    </ShowActivityListWrapper>
  );
};

const ShowActivityListWrapper = styled.div`
  height: 100vh;
  .notes-list-header {
    padding: 0;
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    border: none;

    .header-title {
      display: none;
    }
  }
  .notes-list-deatils {
    width: 100%;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    .note-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      gap: 8px;

      .notes-manue {
        .menu-item {
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }
      .date {
        font-size: 12px;
        gap: 6px;
        color: #a3a3a3;
      }
      .content-text {
        display: flex;
        padding: 12px 16px;
        align-items: flex-start;
        flex-direction: column;
        align-self: stretch;
        border-radius: 6px;
        background: #eff6ff;
        p {
          color: #404040;
        }
        textarea {
          background: transparent;
          border: 0;
          width: 100%;
        }
        button {
          display: flex;
          max-width: 72px;
          padding: 6px 12px;
          justify-content: center;
          align-items: center;
          gap: 6px;
          label {
            font-family: Inter;
            font-weight: 500;
            line-height: 20px;
          }
        }
        .cancel-btn {
          border: 1px solid #e5e5e5;
          background: #fff;
          font-size: 12px;
          color: #404040;
          gap: 6px;
        }
      }
    }
  }
  .accordion-icon-wrapper {
    svg {
      path {
        stroke: #737373;
      }
    }
  }
`;

const ActivityItemWrapper = styled(motion.div)`
  .activity-list {
    &:hover {
      background-color: ${({ theme }) => theme.natural_50};
    }
  }
  .dots-icon {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .tasks-details {
    transition: all 500ms ease-in-out;
    display: grid;
    row-gap: 0;
    grid-template-rows: repeat(1, 0fr);

    & > div {
      overflow: hidden;
    }
  }

  .opened-activity {
    height: auto;
    row-gap: 12px;
    grid-template-rows: repeat(1, 1fr);
  }

  .activity-note-list {
    padding: 0;

    .notes-list-deatils {
      margin-top: 0;
    }
  }
`;

export default PropertyActivities;
