import { createSlice } from '@reduxjs/toolkit';
import api from '../services/index';

export const partnersSlice = createSlice({
  name: 'partners',
  initialState: {},
  reducers: {},
});

// export const createCosts =
//   ({ engagement_id, request }) =>
//   async dispatch => {
//     try {
//       const { data } = await api.post(`/engagements/${engagement_id}/costs`, request);
//       return Promise.resolve(data);
//     } catch (error) {
//       return Promise.reject(error);
//     }
//   };

export const getPartnersList =
  ({ engagement_id, params = {} }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/engagements/${engagement_id}/partners`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const deletePartner =
  ({ engagement_id, partnerId }) =>
  async dispatch => {
    try {
      const { data } = await api.delete(`/engagements/${engagement_id}/partners/${partnerId}`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const {} = partnersSlice.actions;
export default partnersSlice.reducer;
