import { createSlice } from '@reduxjs/toolkit';
import api from '../services';

export const personsSlice = createSlice({
  name: 'persons',
  initialState: {
    personsList: {},
  },
  reducers: {
    setPersonsList: (state, action) => {
      state.personsList = action.payload;
    },
  },
});

export const getPersonsList =
  ({ params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/contacts`, { params: params });
      dispatch(setPersonsList(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const { setPersonsList } = personsSlice.actions;
export default personsSlice.reducer;
