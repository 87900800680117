import { createSlice } from '@reduxjs/toolkit';
import api from '../services';

export const accountsSlice = createSlice({
  name: 'accounts',
  initialState: {
    accountsList: {},
  },
  reducers: {
    setAccountsList: (state, { payload }) => {
      const { data, merge } = payload;
      if (merge) {
        state.accountsList = { ...data, content: [...state.accountsList.content, ...data.content] };
      } else {
        state.accountsList = data;
      }
    },
  },
});

export const getAccountsList =
  ({ organization_id, params, merge }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/organizations/${organization_id}/network`, { params: params });
      dispatch(setAccountsList({ data, merge }));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getAccountsListItems =
  ({ organization_id, params, merge }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/organizations/${organization_id}/network`, { params: params });
      dispatch(setAccountsList({ data, merge }));
      return Promise.resolve(data.content);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const onAccessAccount =
  ({ organization_id }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/organizations/${organization_id}/masquerade`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getOrganizationCategories =
  ({ params = {} }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/organization_categories`, { params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const createAccount =
  ({ request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/organizations`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const { setAccountsList } = accountsSlice.actions;
export default accountsSlice.reducer;
