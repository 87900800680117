import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { ReactComponent as AlertIcon } from '../../assets/icons/AlertIcon.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/CrossIcons.svg';
import Button from '../../components/common/button/button';
import { OrganisationContext } from '../../context/organisationContext';
import { initModal } from '../../helpers/utils';
import { deleteFinancial } from '../../store/features/quotesSlice';
import { addToast } from '../../store/features/toastSlice';

const DeletePPA = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { modal, setModal } = useContext(OrganisationContext);
  const { financial, title, solar_id, description, onSuccess } = modal?.content;
  const { id } = financial || {};

  const [loading, setLoading] = useState(false);

  const onDone = () => {
    setLoading(true);

    dispatch(deleteFinancial({ finacial_id: id, solar_id: solar_id }))
      .then(() => {
        dispatch(addToast({ error: false, text: t('PPA_DELETED'), id: nanoid() }));
        onSuccess && onSuccess();
        setModal(initModal);
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: t('ERROR_REMOVING_QUOTE'), id: nanoid() }));
      })
      .finally(() => setLoading(false));
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <PPAActionWrapper className="flex-column items-center radius-3 pxy-6">
        <div className="flex-column content-wrapper gap-5 items-center relative">
          <AlertIcon />
          <CrossIcon className="absolute right-0 cursor" onClick={() => setModal(initModal)} />
          <div className="flex-column gap-2 items-center justify-start w-full">
            <label className="inter-500-text font-18 text-center">{title}</label>
            <p className="inter-400-text natural-400-text text-center">{description}</p>
          </div>
        </div>
        <div className="action-wrapper flex-column justify-center w-full gap-3">
          <Button
            className={classNames('negative', loading && 'disabled')}
            label={t('DELETE')}
            disabled={loading}
            onClick={() => onDone()}
            size="large"
            borderRadius="100px"
          />
          <Button
            className={classNames('primary-grey', loading && 'disabled')}
            label={t('CANCEL')}
            disabled={loading}
            onClick={() => setModal(initModal)}
            size="large"
            borderRadius="100px"
          />
        </div>
      </PPAActionWrapper>
    </CSSTransition>
  );
};

export const PPAActionWrapper = styled.div`
  width: 384px;
  gap: 32px;
`;

export default DeletePPA;
