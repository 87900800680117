import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as PlusIcon } from '../../assets/icons/add-icon.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit-pensil.svg';
import { ReactComponent as MinusIcon } from '../../assets/icons/minus.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import { OrganisationContext } from '../../context/organisationContext';
import useDebounce from '../../helpers/useDebounceHook';
import { capitalize, formatText, getContactValueByType } from '../../helpers/utils';
import { getPropertyContacts } from '../../store/features/propertySlice';
import Button from '../common/button/button';
import SkeletonTransition from '../common/skeleton-transition';

const ContactDetails = ({ loading = false }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { propertyContacts, propertyDetails } = useSelector(state => state.property);
  const { setSideModal, setModal, hasPermission } = useContext(OrganisationContext);
  const { workitemDetails } = useSelector(state => state.board);
  const engagement_id = workitemDetails?.engagement?.id;

  const [showContact, setShowContact] = useState(null);
  const [showEmployee, setShowEmployee] = useState(null);
  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, 500);

  const onAddContact = () => {
    setModal({
      type: 'add-new-contact',
      content: {
        propertyData: propertyDetails,
        onSuccess: () => {
          dispatch(
            getPropertyContacts({
              property_id: propertyDetails?.id,
              params: {
                page: 0,
                size: 20,
                search: debouncedSearch,
              },
            }),
          );
        },
      },
    });
  };

  const onEditContact = (contact, employee) => {
    setSideModal({
      type: 'edit-contact',
      content: {
        propertyAddress: propertyDetails,
        property_id: propertyDetails?.id,
        companyContactDetails: contact,
        employeeDetail: employee,
        onSuccess: () => {
          dispatch(
            getPropertyContacts({
              property_id: propertyDetails?.id,
              params: {
                page: 0,
                size: 20,
                search: debouncedSearch,
              },
            }),
          );
        },
      },
    });
  };
  const onAddEditEmployee = companyData => {
    setModal({
      type: 'add-edit-employee',
      content: {
        companyData: companyData,
        propertyData: propertyDetails,
        engagement_id: engagement_id,
        onSuccess: () => {
          dispatch(
            getPropertyContacts({
              property_id: propertyDetails?.id,
              params: {
                page: 0,
                size: 20,
                search: debouncedSearch,
              },
            }),
          );
        },
      },
    });
  };

  const groupedContact = propertyContacts.reduce((acc, item) => {
    if (item.contact_type === 'COMPANY') {
      acc.push({
        ...item,
        employees: [],
      });
    } else if (item.parent) {
      const parentCompany = acc.find(company => company.id === item.parent.id);
      if (parentCompany) {
        parentCompany.employees.push(item);
      }
    } else {
      acc.push({
        ...item,
      });
    }

    return acc;
  }, []);

  function getFormattedAddress(addresses) {
    const primaryAddress = addresses.find(address => address.is_primary);
    if (primaryAddress) {
      return primaryAddress.formatted_address;
    }
    if (addresses.length > 0) {
      return addresses[0].formatted_address;
    }
    return 'No Address';
  }

  const getContactValue = (contactTypes, type, valueType, fallback) => {
    const contactType = getContactValueByType(contactTypes, type, valueType);
    return ['N/A', 'n/a', 'na', 'N/a', ''].includes(contactType) ? fallback : contactType;
  };

  const getPreferredCallTime = callTime => {
    if (!callTime) return '-';
    const startTime = `${callTime.start_time[0]}:${callTime.start_time[1] === 0 ? '00' : callTime.start_time[1]}`;
    const endTime = `${callTime.end_time[0]}:${callTime.end_time[1] === 0 ? '00' : callTime.end_time[1]}`;
    return `${startTime}-${endTime}`;
  };

  return (
    <SkeletonTransition
      loading={loading}
      height={'260px'}
      containerClassName="line-height-1"
      baseColor="#CDCDCD"
      highlightColor="#E8E8E8">
      <ContactDetailsWrapper
        key={'contact-details'}
        initial={{ opacity: 0.3, y: 10 }}
        animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}
        className={classNames(`card radius-2 pxy-6 flex-column`)}>
        <div className={classNames(`flex items-center justify-between pb-3 border-bottom`)}>
          <label className="inter-600-text natural-900-text font-16">{t('CONTACT_DETAILS')}</label>
          {hasPermission('PROPERTY_CONTACTS_MANAGE') && (
            <Button
              afterIcon={<PlusIcon className="primary-500-text mr-0" />}
              width="32px"
              className="specified-width secondary"
              borderRadius="50%"
              height="32px"
              onClick={() => onAddContact()}
            />
          )}
        </div>
        <div className="flex-column row-gap-4 mt-5">
          {groupedContact?.map((contact, index) => {
            const key = contact.id + '__' + index;
            const isContactOpen = key !== showContact;
            return (
              <div className={classNames(`flex-column border radius-2 px-4 py-3`)} key={key}>
                <div className="flex items-center justify-between">
                  <div className="flex-column gap-0_7">
                    <label className="inter-500-text natural-900-text">
                      {contact.contact_type === 'COMPANY'
                        ? capitalize(formatText(contact.company_name)) || '-'
                        : contact.name}
                    </label>
                    <label className="inter-400-text natural-400-text flex items-center">
                      <UserIcon className="mr-1" />
                      {capitalize(formatText(contact.property_contact_type)) || '-'}
                    </label>
                  </div>
                  <div className="pxy-1 flex justify-between w-48px">
                    <div className="cursor">
                      <EditIcon
                        className={classNames(isContactOpen ? 'natural-400-text' : 'primary-500-text')}
                        onClick={() => onEditContact(contact)}
                      />
                    </div>
                    <div className="cursor" onClick={() => (isContactOpen ? setShowContact(key) : setShowContact(''))}>
                      {isContactOpen ? <PlusIcon className="natural-400-text" /> : <MinusIcon />}
                    </div>
                  </div>
                </div>
                <div
                  className={classNames(
                    { 'company-details': contact.contact_type === 'COMPANY' },
                    { 'user-details': contact.contact_type !== 'COMPANY' },
                    !isContactOpen && contact.contact_type === 'COMPANY' && 'border-top pt-3 mt-3 opened-company',
                    !isContactOpen && contact.contact_type !== 'COMPANY' && 'border-top pt-3 mt-3 opened-user',
                  )}>
                  <div className="flex-column gap-3">
                    {contact.contact_type !== 'COMPANY' && (
                      <div className="flex items-center justify-between">
                        <label className="inter-400-text natural-400-text">{t('MOBILE')}</label>
                        <label
                          className={
                            getContactValue(contact.phones || [], 'contact_type', 'MOBILE', t('NO_MOBILE')) ===
                            'No Mobile'
                              ? 'inter-400-text natural-500-text'
                              : 'inter-400-text natural-900-text one-line pl-2'
                          }>
                          {getContactValue(contact.phones || [], 'contact_type', 'MOBILE', t('NO_MOBILE'))}
                        </label>
                      </div>
                    )}
                    <div className="flex items-center justify-between w-full">
                      <label className="inter-400-text natural-400-text">{t('LANDLINE')}</label>
                      <label
                        className={
                          getContactValue(contact.phones || [], 'contact_type', 'LANDLINE', t('NO_LANDLINE')) ===
                          'No Landline'
                            ? 'inter-400-text natural-500-text'
                            : 'inter-400-text natural-900-text one-line pl-2'
                        }>
                        {getContactValue(contact.phones || [], 'contact_type', 'LANDLINE', t('NO_LANDLINE'))}
                      </label>
                    </div>
                    <div className="flex items-center justify-between w-full">
                      <label className="inter-400-text natural-400-text self-start">{t('PREFERRED_CALL_TIME')}</label>
                      <div className="flex-column">
                        {contact.contact_periods.length > 0 ? (
                          contact.contact_periods
                            .sort((a, b) => {
                              // Compare the hours first
                              if (a.start_time[0] !== b.start_time[0]) {
                                return a.start_time[0] - b.start_time[0];
                              }

                              // If hours are the same, compare the minutes
                              return a.start_time[1] - b.start_time[1];
                            })
                            .map(time => (
                              <label key={time.id} className="inter-400-text natural-900-text one-line pl-2">
                                {getPreferredCallTime(time)}
                              </label>
                            ))
                        ) : (
                          <label className={'inter-400-text natural-500-text'}>-</label>
                        )}
                      </div>
                    </div>

                    <div className="flex items-center justify-between">
                      <label className="inter-400-text natural-400-text">{t('EMAIL')}</label>
                      <label
                        className={
                          getContactValue(contact.emails || [], 'is_primary', true, t('NO_EMAIL')) === 'No Email'
                            ? 'inter-400-text natural-500-text'
                            : 'inter-400-text natural-900-text one-line pl-2'
                        }>
                        {getContactValue(contact.emails || [], 'is_primary', true, t('NO_EMAIL'))}
                      </label>
                    </div>
                    <div className="flex items-streach justify-between">
                      <label className="inter-400-text natural-400-text">{t('ADDRESS')}</label>
                      <label
                        className={
                          getContactValue(contact.addresses || [], 'is_primary', true, t('NO_ADDRESS')) ===
                            'No Address' && getFormattedAddress(contact.addresses) === 'No Address'
                            ? 'inter-400-text natural-500-text'
                            : 'inter-400-text natural-900-text pl-2 text-right'
                        }>
                        {getFormattedAddress(contact.addresses)}
                        {/* {contact.contact_type === 'COMPANY'
                      ? getFormattedAddress(contact.addresses)
                      : getContactValue(contact.addresses || [], 'is_primary', true, 'No Address')} */}
                      </label>
                    </div>
                    {contact.contact_type === 'COMPANY' && (
                      <div className="flex-column gap-2 mt-2">
                        <div
                          className={classNames('flex items-center justify-between pb-0_5', isContactOpen && 'py-2')}>
                          <label className="inter-500-text natural-900-text font-14">
                            {`${t('EMPLOYEES')} (${contact?.employees.length})`}
                          </label>
                          {hasPermission('PROPERTY_CONTACTS_MANAGE') && (
                            <Button
                              afterIcon={<PlusIcon className="primary-500-text mr-0" />}
                              width="24px"
                              className="specified-width secondary"
                              borderRadius="50%"
                              height="24px"
                              onClick={() => onAddEditEmployee({ ...contact })}
                            />
                          )}
                        </div>
                        {contact.employees.map((employee, index) => {
                          const isContactEmployeeOpen = employee.id !== showEmployee;
                          return (
                            <div
                              className={classNames('flex-column border radius-2 px-4 py-3', isContactOpen && 'mb-2')}
                              key={index}>
                              <div className="flex items-center justify-between">
                                <div className="flex-column">
                                  <label className="inter-500-text natural-900-text">{employee.name}</label>
                                  <label className="inter-400-text natural-400-text mt-1 flex items-center">
                                    <UserIcon className="mr-1" />
                                    {capitalize(formatText(employee.property_contact_type)) || '-'}
                                  </label>
                                </div>
                                <div className="pxy-1 flex justify-between w-48px">
                                  <div className="cursor">
                                    <EditIcon
                                      className={classNames(
                                        isContactEmployeeOpen ? 'natural-400-text' : 'primary-500-text',
                                      )}
                                      onClick={() => onEditContact(contact, employee)}
                                    />
                                  </div>
                                  <div
                                    className="cursor"
                                    onClick={() =>
                                      isContactEmployeeOpen ? setShowEmployee(employee.id) : setShowEmployee('')
                                    }>
                                    {isContactEmployeeOpen ? <PlusIcon className="natural-400-text" /> : <MinusIcon />}
                                  </div>
                                </div>
                              </div>
                              <div
                                className={classNames(
                                  'employee-details',
                                  !isContactEmployeeOpen && 'border-top pt-3 mt-3 opened-employee',
                                )}>
                                <div className="flex items-center justify-between">
                                  <label className="inter-400-text natural-400-text">{t('MOBILE')}</label>
                                  <label
                                    className={
                                      getContactValue(
                                        employee.phones || [],
                                        'contact_type',
                                        'MOBILE',
                                        t('NO_MOBILE'),
                                      ) === 'No Mobile'
                                        ? 'inter-400-text natural-500-text'
                                        : 'inter-400-text natural-900-text'
                                    }>
                                    {getContactValue(employee.phones || [], 'contact_type', 'MOBILE', t('NO_MOBILE'))}
                                  </label>
                                </div>
                                <div className="flex items-center justify-between">
                                  <label className="inter-400-text natural-400-text">{t('LANDLINE')}</label>
                                  <label
                                    className={
                                      getContactValue(
                                        employee.phones || [],
                                        'contact_type',
                                        'LANDLINE',
                                        t('NO_LANDLINE'),
                                      ) === 'No Landline'
                                        ? 'inter-400-text natural-500-text'
                                        : 'inter-400-text natural-900-text one-line pl-2'
                                    }>
                                    {getContactValue(
                                      employee.phones || [],
                                      'contact_type',
                                      'LANDLINE',
                                      t('NO_LANDLINE'),
                                    )}
                                  </label>
                                </div>
                                <div className="flex items-center justify-between w-full">
                                  <label className="inter-400-text natural-400-text self-start">
                                    {t('PREFERRED_CALL_TIME')}
                                  </label>
                                  <div className="flex-column">
                                    {contact.contact_periods.length > 0 ? (
                                      contact.contact_periods
                                        .sort((a, b) => {
                                          // Compare the hours first
                                          if (a.start_time[0] !== b.start_time[0]) {
                                            return a.start_time[0] - b.start_time[0];
                                          }

                                          // If hours are the same, compare the minutes
                                          return a.start_time[1] - b.start_time[1];
                                        })
                                        .map(time => (
                                          <label
                                            key={time.id}
                                            className="inter-400-text natural-900-text one-line pl-2">
                                            {getPreferredCallTime(time)}
                                          </label>
                                        ))
                                    ) : (
                                      <label className={'inter-400-text natural-500-text'}>-</label>
                                    )}
                                  </div>
                                </div>
                                <div className="flex items-center justify-between">
                                  <label className="inter-400-text natural-400-text">{t('EMAIL')}</label>
                                  <label
                                    className={
                                      getContactValue(employee.emails || [], 'email_type', 'WORK', t('NO_EMAIL')) ===
                                      'No Email'
                                        ? 'inter-400-text natural-500-text'
                                        : 'inter-400-text natural-900-text one-line pl-2'
                                    }>
                                    {getContactValue(employee.emails || [], 'email_type', 'WORK', t('NO_EMAIL'))}
                                  </label>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </ContactDetailsWrapper>
    </SkeletonTransition>
  );
};

const ContactDetailsWrapper = styled(motion.div)`
  .user-details {
    transition: all 500ms ease-in-out;
    display: grid;
    row-gap: 0;
    grid-template-rows: repeat(1, 0fr);

    & > div {
      overflow: hidden;
    }
  }

  .opened-user {
    height: auto;
    row-gap: 12px;
    grid-template-rows: repeat(1, 1fr);
  }
  .company-details {
    transition: all 500ms ease-in-out;
    display: grid;
    grid-template-rows: repeat(1, 0fr);
    row-gap: 0px;
    & > div {
      overflow: hidden;
    }
  }

  .opened-company {
    transition: all 500ms ease-in-out;
    height: auto;
    row-gap: 12px;
    grid-template-rows: repeat(1, 1fr);
  }
  .employee-details {
    transition: all 500ms ease-in-out;
    display: grid;
    grid-template-rows: repeat(3, 0fr);
    row-gap: 0;

    & > div {
      overflow: hidden;
    }
  }
  .opened-employee {
    height: auto;
    row-gap: 12px;
    grid-template-rows: repeat(3, 1fr);
  }
`;

export default ContactDetails;
