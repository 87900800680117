import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as HorizontalDots } from '../../../../assets/images/HorizontalDots.svg';
import { ReactComponent as UsersIcon } from '../../../../assets/images/security-settings-outline.svg';

import { useTranslation } from 'react-i18next';
import MenuV2 from '../../../common/menu/menu-v2';

const TeamDetailsHeader = () => {
  const { t } = useTranslation();

  const { teamDetails } = useSelector(state => state.teamManagement);

  return (
    <div className="px-6 pt-5">
      <div className="flex-row justify-between w-full border-bottom items-center pb-3">
        <div className="inter-600-text natural-900-text  font-18">{teamDetails?.name}</div>
        <div className="flex-row items-center gap-3">
          <div className="member-count ">
            <UsersIcon height={12} width={14} />
            <div className="inter-400-text font-14">{teamDetails?.members}</div>
          </div>
          <MenuV2 Icon={HorizontalDots} menuList={[{ name: t('NAME'), onClick: () => {} }]} />
        </div>
      </div>
    </div>
  );
};

export default TeamDetailsHeader;
