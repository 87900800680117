import React, { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import AutomationList from '../../components/automations-list';
import PageHeader from '../../components/page-header';
import { OrganisationContext } from '../../context/organisationContext';
import useDebounce from '../../helpers/useDebounceHook';

const Automations = () => {
  const { t } = useTranslation();
  const { setModal } = useContext(OrganisationContext);

  const [search, setSearchText] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const onAddClick = () => {
    setModal({
      type: 'add-automation',
    });
  };

  return (
    <AutomationsWrapper className="flex-column flex-1">
      <Routes>
        <Route
          index
          element={
            <Fragment>
              <PageHeader
                names={[
                  { text: t('PLATFORMS'), onClick: () => {} },
                  { text: t('AUTOMATION'), onClick: () => {} },
                ]}
                showAddNewBtn
                showSearch
                onAddClick={onAddClick}
                addBtnPermission="AUTOMATIONS_MANAGE"
                searchText={search}
                addButtonLabel={t('ADD_AUTOMATION')}
                onSearchChange={setSearchText}
              />
              <AutomationList debouncedSearch={debouncedSearch} />
            </Fragment>
          }
        />
      </Routes>
    </AutomationsWrapper>
  );
};

export const AutomationsWrapper = styled.div`
  max-height: 100vh;
  overflow: hidden;
`;

export default Automations;
