import { createSlice } from '@reduxjs/toolkit';
import api from '../services';

export const notesSlice = createSlice({
  name: 'notes',
  initialState: {
    noteList: [],
    addNotes: [],
    updateNote: {},
  },
  reducers: {
    setNotesList: (state, { payload }) => {
      state.noteList = payload;
    },
    setNotesDetails: (state, { payload }) => {
      state.addNotes = payload;
    },
    setNotesUpdateList: (state, { payload }) => {
      state.updateNote = payload;
    },
    setNotesStageList: (state, { payload }) => {
      state.boardStageList = payload;
    },
  },
});

export const getNoteList = payload => async dispatch => {
  try {
    const { engagement_id, params } = payload;
    const { data } = await api.get(`/engagements/${engagement_id}/notes`, { params: params });
    dispatch(setNotesList(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addNote = payload => async dispatch => {
  try {
    const { engagement_id, request } = payload;
    const { data } = await api.post(`/engagements/${engagement_id}/notes`, request);
    dispatch(setNotesList(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateNote = payload => async dispatch => {
  try {
    const { note_id, request } = payload;
    const { data } = await api.put(`/notes/${note_id}`, { note: request.note });
    dispatch(setNotesUpdateList(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteNote = payload => async dispatch => {
  try {
    const { note_id } = payload;
    const { data } = await api.delete(`/notes/${note_id}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const { setNotesList, setNotesUpdateList, setNotesStageList, setNotesDetails } = notesSlice.actions;
export default notesSlice.reducer;
