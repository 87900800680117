import { nanoid } from 'nanoid';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import MaskedInput from 'react-text-mask';
import DateSelectorElement from '../../components/common/date-selector/date-selector-element';
import InputElement from '../../components/common/input';
import InputNumber from '../../components/common/input-number/inputNumber';
import SearchableDropdown from '../../components/common/searchable-dropdown';
import { createNumberMask } from '../../helpers/createNumberMask';
import { getCampaignsList } from '../../store/features/campaignSlice';
import { getJobTypeList, getProjectList } from '../../store/features/jobSlice';
import { addToast } from '../../store/features/toastSlice';

const JobsInfo = props => {
  const [searchJob, setSearchJob] = useState('');
  const [searchProject, setSearchProject] = useState('');
  const [searchCampaign, setSearchCampaign] = useState('');
  const { job, setJob, error, setError, property } = props;
  const {
    job_type,
    job_name,
    campaign_type,
    job_description,
    project,
    expected_completion,
    deadline,
    job_number,
    job_value,
    po_number,
  } = job;

  const descriptionRef = useRef();
  const workflowClearKeyRef = useRef(nanoid());
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onJobUpdate = (key, value) => {
    let additionalUpdate = {};
    if (key === 'job_type' || key === 'project') {
      additionalUpdate = {
        workflow: null,
      };
      workflowClearKeyRef.current = nanoid();
    }
    if (key === 'project') {
      additionalUpdate = {
        job_type: null,
      };
    }
    setJob({ ...job, ...additionalUpdate, [key]: value });
    setError({ ...error, [key]: error?.[key] && !value });
  };

  const onExpectedCompletionChange = date => {
    if (deadline && date > deadline) {
      dispatch(
        addToast({
          error: true,
          text: t('START_DATE_ERROR'),
          id: nanoid(),
        }),
      );
      return;
    }
    onJobUpdate('expected_completion', date);
  };

  const onDeadlineChange = date => {
    if (expected_completion && date < expected_completion) {
      dispatch(
        addToast({
          error: true,
          text: t('END_DATE_ERROR'),
          id: nanoid(),
        }),
      );
      return;
    }
    onJobUpdate('deadline', date);
  };

  useEffect(() => {
    const target = descriptionRef.current;
    if (target) {
      handleJobDescription(target.value, target, false);
    }
  }, []);

  const handleJobDescription = (value, ele, update = true) => {
    if (value) {
      const scrollHeight = ele.scrollHeight;
      ele.style.height = scrollHeight + 'px';
    } else {
      ele.style.removeProperty('height');
    }
    if (update) {
      onJobUpdate('job_description', value);
    }
  };

  return (
    <div>
      <div className="flex-column row-gap-5">
        <div className="job-details row-gap-5 col-gap-5">
          <SearchableDropdown
            isClearable
            inputValue={searchProject}
            onInputChange={setSearchProject}
            className="w-full"
            placeholder={t('SELECT_PROJECT')}
            name={t('PROJECT')}
            value={project}
            onChange={option => onJobUpdate('project', option)}
            isSearchable={true}
            defaultAdditional={{ page: 0, fetchFunction: getProjectList, pageable: false }}
            error={error.project && !job.project}
          />
          <SearchableDropdown
            isClearable
            inputValue={searchCampaign}
            onInputChange={setSearchCampaign}
            className="w-full"
            sub_name={t('OPTIONAL')}
            placeholder={'Select campaign'}
            name={t('CAMPAIGN')}
            value={campaign_type}
            onChange={option => onJobUpdate('campaign_type', option)}
            isSearchable={true}
            defaultAdditional={{
              page: 0,
              fetchFunction: getCampaignsList,
              pageable: false,
            }}
          />
          <SearchableDropdown
            key={project?.id}
            isClearable
            inputValue={searchJob}
            onInputChange={setSearchJob}
            className="w-full"
            placeholder={t('SELECT_JOB_TYPE')}
            name={t('JOB_TYPE')}
            value={job_type}
            onChange={option => onJobUpdate('job_type', option)}
            isSearchable={true}
            isDisabled={project ? false : true}
            defaultAdditional={{
              page: 0,
              fetchFunction: getJobTypeList,
              pageable: false,
              params: { project_id: project?.id },
            }}
            error={error.job_type && !job.job_type}
          />
          <InputElement
            className="w-full"
            name={t('JOB_NAME')}
            sub_name={t('OPTIONAL')}
            placeholder={t('ENTER_JOB_NAME')}
            value={job_name}
            onChange={value => onJobUpdate('job_name', value)}
            error={error.job_name && !job.job_name}
          />
          <div className="span-2">
            <InputElement
              inputRef={descriptionRef}
              className="w-full"
              type="textarea"
              name={t('JOB_DESCRIPTION')}
              placeholder={t('ENTER_JOB_DETAILS')}
              value={job_description}
              onChange={handleJobDescription}
              error={error.job_description && !job.job_description}
            />
          </div>
          <InputNumber
            className="w-full"
            name={t('JOB_NUMBER')}
            sub_name={t('OPTIONAL')}
            placeholder={t('ENTER_JOB_NUMBER')}
            value={job_number}
            onChange={value => onJobUpdate('job_number', value)}
          />
          <InputNumber
            className="w-full"
            name={t('PO_NUMBER')}
            sub_name={t('OPTIONAL')}
            placeholder={t('ENTER_PO_NUMBER')}
            value={po_number}
            onChange={value => onJobUpdate('po_number', value)}
          />
        </div>
        <div className="job-value-details row-gap-5 col-gap-5">
          <div className="flex-column">
            <div className="flex-1 mb-1">
              <span className="inter-500-text natural-900-text">{t('ESTIMATED_JOB_VALUE')}</span>
            </div>
            <MaskedInput
              mask={createNumberMask({
                prefix: '£',
                allowDecimal: true,
                includeThousandsSeparator: false,
              })}
              value={job_value}
              className={`input w-full ${error?.job_value ? 'error-border' : ''}`}
              placeholder={'£00.00'}
              guide={false}
              onChange={({ target: { value } }) => onJobUpdate('job_value', value)}
            />
          </div>
          <DateSelectorElement
            selectedDate={expected_completion}
            setSelectedDate={date => onExpectedCompletionChange(date)}
            placeholder={t('SELECT_A_DATA')}
            name={t('EXPECTED_COMPLETION')}
            sub_name={t('OPTIONAL')}
          />
          <DateSelectorElement
            selectedDate={deadline}
            setSelectedDate={date => onDeadlineChange(date)}
            placeholder={t('SELECT_A_DATA')}
            name={t('DEADLINE')}
            sub_name={t('OPTIONAL')}
          />
        </div>
      </div>
    </div>
  );
};

export default JobsInfo;
