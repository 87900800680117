import React from 'react';
import { useSelector } from 'react-redux';
import TeamMemberBody from './team-members-body';
import TeamMemberHeader from './team-members-header';
const TeamMembers = () => {
  const { teamMembers: teamMembers } = useSelector(state => state.teamManagement);

  return (
    <div
      className="border right-box flex-column w-70 justify-start h-auto content-box radius-4 overflow-hidden"
      style={{ height: '80vh' }}>
      <TeamMemberHeader />
      <TeamMemberBody />
    </div>
  );
};

export default TeamMembers;
