import React from 'react';
import { SidebarWrapper } from '../../../styles/components/main-layout/sidebar.styled';
import MenuItem from './menu-item';

const Sidebar = ({ menuItems, onItemClick }) => {
  return (
    <SidebarWrapper>
      <div className="sidebar-top mt-1" tabIndex="0">
        <div className="menu-items">
          {menuItems
            ?.filter(({ hasPermission, isHidden }) => hasPermission && !isHidden)
            .map((item, i) => (
              <MenuItem key={i} item={item} onClick={onItemClick} />
            ))}
        </div>
      </div>
    </SidebarWrapper>
  );
};

export default Sidebar;
