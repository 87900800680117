import isEqual from 'lodash.isequal';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { capitalize, formatText } from '../../../../helpers/utils';
import { getIntegrationActionConfig, getTemplateListOptions } from '../../../../store/features/automationsSlice';
import Dropdown from '../../../common/select-dropdown';
import CustomTooltip from '../../../common/tooltip-new';

const defaultAttachmentOptions = [
  {
    label: 'Appointments ics',
    name: 'Appointments ics',
    id: 'appointment.icalendar',
    value: 'appointment.icalendar',
    shortcode: 'appointment.icalendar',
  },
];

const getInitialConfigDetailValues = integrationConfigValues => {
  return (integrationConfigValues || []).map(param => ({
    param_name: param.param_type,
    param_value: param.param_value,
    display_name: param.display_value,
  }));
};

const IntegrationConfig = ({ integration, action_type, setIntegrationData, integrationConfigValues }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [integrationConfig, setIntegrationConfig] = useState([]);
  const [configDetails, setConfigDetails] = useState([]);
  const [configDetailValues, setConfigDetailValues] = useState(getInitialConfigDetailValues(integrationConfigValues));
  const [errorFields, setErrorFields] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchIntegrationConfig = () => {
    setLoading(true);
    dispatch(getIntegrationActionConfig({ integration_id: integration?.id, actionType: action_type }))
      .then(({ data }) => {
        setIntegrationConfig(data);
      })
      .catch(error => {
        setLoading(false);
        setConfigDetails([]);
        setConfigDetailValues([]);
      });
  };

  useEffect(() => {
    fetchIntegrationConfig();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const configs = await Promise.all(
        integrationConfig?.map(async curr => {
          if (!curr.conditional) {
            if (curr?.param_type === 'LIST') {
              const filteredConfigs = configDetails.filter(item => item.param_type === 'LIST');
              const matchingConfig = filteredConfigs.find(config => config.id === curr.id);

              const { choices, params: prevParams } = matchingConfig || {};
              const params = getConfigParamValues(curr);
              const isBothSame = isEqual(params, prevParams);

              if (choices && isBothSame) {
                return {
                  ...curr,
                  params: prevParams,
                  choices: matchingConfig.choices,
                  value: getIntegrationParamValue(curr.id),
                  depends: await getConditional(curr),
                };
              } else {
                const { data, params } = await getOptions(curr);
                return {
                  ...curr,
                  choices: data,
                  params,
                  value: getIntegrationParamValue(curr.id),
                  depends: await getConditional(curr),
                };
              }
            } else if (curr?.param_type === 'ATTACHMENTS') {
              return {
                ...curr,
                choices: defaultAttachmentOptions,
                value: getIntegrationParamValue(curr.id),
                depends: await getConditional(curr),
              };
            } else {
              const data = await getConditional(curr);
              return { ...curr, value: getIntegrationParamValue(curr.id), depends: data };
            }
          }
          return null;
        }),
      );
      setLoading(false);
      const filteredConfigs = configs.filter(config => config !== null);
      setConfigDetails(filteredConfigs);
    };
    if (integrationConfig.length) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [integrationConfig, configDetailValues]);

  const getConfigParamValues = config => {
    const { required_params } = config;
    const paramsValues = required_params?.reduce((acc, param) => {
      const value = configDetailValues.find(config => config.param_name === param);
      acc[param] = value?.param_value;
      return acc;
    }, {});
    return paramsValues;
  };

  const isAllRequiredParamsAvailable = config => {
    const { required_params } = config;
    return required_params.length > 0
      ? required_params.every(param => {
          return configDetailValues.find(config => config.param_name === param);
        })
      : true;
  };

  const getRequiredParamsTooltipContent = config => {
    const { required_params } = config;
    const paramsText = required_params.map(param => capitalize(formatText(param, ' '))).join(', ');
    return `You need to select ${paramsText} first`;
  };

  const getOptions = curr => {
    const isAllDataAvailable = isAllRequiredParamsAvailable(curr);
    const paramsValues = getConfigParamValues(curr);

    if (!isAllDataAvailable) {
      return { data: [], params: paramsValues };
    }

    return new Promise((resolve, reject) => {
      dispatch(
        getTemplateListOptions({
          integration_id: integration?.id,
          actionType: action_type,
          paramId: curr?.id,
          params: paramsValues,
        }),
      )
        .then(response => {
          const responseData = response?.data?.map(item => ({ ...item, value: item.id, label: item.name }));
          resolve({ data: responseData, params: paramsValues });
        })
        .catch(err => {
          resolve({ data: [], params: paramsValues });
        });
    });
  };

  useEffect(() => {
    setIntegrationData(configDetailValues);
  }, [configDetailValues]);

  const getConditional = config => {
    const findCondition = integrationConfig.filter(c => c.conditional?.param_name === config.id);
    const finalData = findCondition.map(f => ({
      ...f,
      value: getIntegrationParamValue(f.id),
      depends: getConditional(f),
    }));
    return finalData;
  };

  const getIntegrationParamValue = name => {
    return configDetailValues?.find(param => param.param_name === name)?.param_value || '';
  };

  const updateIntegrationParams = (name, value, displayName) => {
    const isAvailable = configDetailValues?.find(param => param.param_name === name);
    if (isAvailable) {
      setConfigDetailValues([
        ...configDetailValues.map(param =>
          param.param_name === name ? { ...param, param_value: value, display_name: displayName?.name } : param,
        ),
      ]);
    } else {
      setConfigDetailValues([
        ...configDetailValues,
        { param_name: name, param_value: value, display_name: displayName?.name },
      ]);
    }
    setErrorFields({ ...errorFields, [name]: false });
  };

  const renderInput = (config, dependentConfig = null) => {
    return config.conditional ? (
      <>
        {config.conditional.param_value === dependentConfig?.value && (
          <div className="flex-column integration-input relative w-full">
            <div className="mb-1 one-line">
              <label className="inter-500-text natural-900-text">{config.name}</label>
            </div>
            <input
              autoComplete="turnoff"
              className={`input name-input-form w-full ${errorFields[config.id] && 'error-info'} ${
                config.param_type === 'PASSWORD' && !showPassword[config.id] && 'password-mask'
              }`}
              onChange={e => updateIntegrationParams(config.id, e.target.value)}
              placeholder={config.name}
              type="text"
              value={config.value || ''}
            />
            {config.param_type === 'PASSWORD' && (
              <div className="flex items-center cursor mr-16 input-show-img">
                <img
                  alt="icon"
                  onClick={() => setShowPassword({ [config.id]: !showPassword[config.id] })}
                  src={showPassword[config.id] ? ShowPassword : HidePassword}
                />
              </div>
            )}
          </div>
        )}
      </>
    ) : config.param_type === 'LIST' ? (
      <div className="flex-column">
        <CustomTooltip
          hidden={isAllRequiredParamsAvailable(config)}
          tooltipClassname="param-tooltip"
          content={<span className="inter-400-text font-12">{getRequiredParamsTooltipContent(config)}</span>}
          id={`tooltip-${config.id}`}>
          <Dropdown
            className={`integration-input-dropdown`}
            onChange={option => updateIntegrationParams(config.id, option.id, option)}
            options={config.choices || []}
            placeholder={'Select'}
            isSearchable={true}
            value={config.choices?.find(c => c.id === config.value) || null}
            name={config.name}
            error={errorFields[config.id]}
            isDisabled={!isAllRequiredParamsAvailable(config)}
          />
        </CustomTooltip>
      </div>
    ) : config.param_type === 'ATTACHMENTS' ? (
      <div className="flex-column">
        <Dropdown
          isMulti={true}
          className={`integration-input-dropdown`}
          onChange={option => updateIntegrationParams(config.id, option)}
          options={config.choices || []}
          placeholder={config.name}
          isSearchable={true}
          value={config.value || null}
          name={config.name}
          error={errorFields[config.id]}
        />
      </div>
    ) : (
      <div className="flex-column integration-input relative w-full">
        <div className="mb-1 one-line">
          <label className="inter-500-text natural-900-text">{config.name}</label>
        </div>
        <input
          autoComplete="turnoff"
          className={`input name-input-form w-full ${errorFields[config.id] && 'error-info'} ${
            config.param_type === 'PASSWORD' && !showPassword[config.id] && 'password-mask'
          }`}
          onChange={e => updateIntegrationParams(config.id, e.target.value)}
          placeholder={config.name}
          type="text"
          value={config.value || ''}
        />
        {config.param_type === 'PASSWORD' && (
          <div className="flex items-center cursor mr-16 input-show-img">
            <img
              alt="icon"
              onClick={() => setShowPassword({ [config.id]: !showPassword[config.id] })}
              src={showPassword[config.id] ? ShowPassword : HidePassword}
            />
          </div>
        )}
      </div>
    );
  };

  const renderConditionalConfigs = (config, dependentConfig = null, parentConfig = null) => {
    return (
      <>
        {(!config.conditional || (config.conditional && config.conditional.param_value === dependentConfig?.value)) && (
          <div className="flex-column action-container relative">
            <div className="flex-column">{renderInput(config, dependentConfig)}</div>
            {config.depends?.map(c => renderConditionalConfigs(c, config, dependentConfig ? parentConfig : config))}
          </div>
        )}
      </>
    );
  };

  return (
    <IntegrationConfigWrapper>
      {loading ? (
        <div className="config-grid items-center w-full">
          <Skeleton height={60} key={'skeleton-1'} />
          <Skeleton height={60} key={'skeleton-2'} />
          <Skeleton height={60} key={'skeleton-3'} />
          <Skeleton height={60} key={'skeleton-4'} />
          <Skeleton height={60} key={'skeleton-5'} />
          <Skeleton height={60} key={'skeleton-6'} />
        </div>
      ) : (
        <div className="flex-column row-gap-6">
          {(configDetails || []).length ? (
            <>
              <div className="config-grid items-center w-full">
                {configDetails.map(config => renderConditionalConfigs(config))}
              </div>
              {/* <div className="">
                <Editor
                  onChange={e => updateIntegrationParams('TAGS', e)}
                  getParmsValue={getIntegrationParamValue('TAGS')}
                />
              </div> */}
            </>
          ) : (
            <div className="flex items-center justify-center w-full">
              <label className="inter-400-text natural-400-text">{t('NO_INTEGRATIONS_PARAMS_FOUND')}</label>
            </div>
          )}
        </div>
      )}
    </IntegrationConfigWrapper>
  );
};

const IntegrationConfigWrapper = styled.div`
  .config-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 24px;
    column-gap: 16px;
  }

  .param-tooltip {
    padding: 8px 12px;
    box-shadow: 0px 2px 6px -4px rgba(16, 24, 40, 0.1), 0px 8px 15px -3px rgba(16, 24, 40, 0.1);
  }
`;

export default IntegrationConfig;
