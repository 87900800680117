import React from 'react';
import styled from 'styled-components';
import AutomationDetailsLeft from './automation-details-left';
import AutomationDetailsRight from './automation-details-right';

const AutomationDetails = () => {
  return (
    <AutomationDetailsWrapper className="flex flex-1 col-gap-6 flex-1 py-6 px-6  ">
      <AutomationDetailsLeft />
      <AutomationDetailsRight />
    </AutomationDetailsWrapper>
  );
};

export const AutomationDetailsWrapper = styled.div`
  height: calc(100% - 45px);
`;
export default AutomationDetails;
