import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { capitalizeFirstLetterOfWords, formatText } from '../../helpers/utils';
import { useFilteredMenuList } from '../../hooks/useFilteredMenuList';
import Menu from '../common/menu';
import Status from '../common/status';

const RenderLabelText = ({ text, condition, className, defaultText }) => {
  return (
    <div className={classNames('brd-right flex items-center w-full', className)}>
      <label
        className={classNames('inter-400-text natural-400-text font-14 one-line', condition && 'natural-900-text')}>
        {text || defaultText}
      </label>
    </div>
  );
};

const QuoteTemplateListItem = ({ templateList, redirectQuoteTemplateDetails, handleDeleteQuoteTemplate }) => {
  const { t } = useTranslation();

  return (
    <QuoteTemplateListItemWrapper
      className="data-container items-center justify-center w-full py-0 cursor sidebar-close-ignore"
      onClick={() => redirectQuoteTemplateDetails(templateList)}>
      <RenderLabelText text={templateList?.name} condition={templateList?.name} defaultText={t('NO_DATA')} />
      <RenderLabelText
        text={templateList?.description}
        condition={templateList?.description}
        defaultText={t('NO_DATA')}
      />
      <div className="brd-right flex items-center w-full overflow-hidden justify-center">
        {templateList?.is_default && (
          <Status
            withDot={false}
            withDottedBorder
            status={templateList?.is_default ? 'DEFAULT' : ''}
            statusText={templateList?.is_default ? capitalizeFirstLetterOfWords(formatText(t('DEFAULT'))) : ''}
          />
        )}
      </div>
      <div>
        <Menu
          menuList={useFilteredMenuList({
            menuList: [
              { name: t('EDIT'), onClick: () => redirectQuoteTemplateDetails(templateList), permission: '' },
              { name: t('DUPLICATE'), onClick: () => {}, permission: '' },
              {
                name: t('DELETE'),
                onClick: () => handleDeleteQuoteTemplate(templateList),
                permission: 'QUOTE_TEMPLATES_MANAGE',
              },
            ],
          })}
          iconClassName="rotate-90 dots-icon"
        />
      </div>
    </QuoteTemplateListItemWrapper>
  );
};

const QuoteTemplateListItemWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.lightGrey};

  &:hover {
    background-color: ${({ theme }) => theme.natural_50};
  }
`;

export default QuoteTemplateListItem;
