import classNames from 'classnames';
import moment from 'moment';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { ReactComponent as ArrowDownIcon } from '../../assets/images/menu/arrow-down-black.svg';
import { Avatar } from '../../components/common/avatar-group';
import IconContainer from '../../components/common/icon-container';
import CustomTooltip from '../../components/common/tooltip-new';
import { OrganisationContext } from '../../context/organisationContext';

const statusOptions = [
  { label: 'To do', value: 'TODO' },
  { label: 'In-progress', value: 'STARTED' },
  { label: 'Blocked', value: 'BLOCKED' },
  { label: 'Done', value: 'COMPLETE' },
];

const SubTaskItem = props => {
  const { sideModal, setSideModal } = useContext(OrganisationContext);
  const {
    content: { selectedTask, isSubTask },
  } = sideModal;
  const { start_date, status } = selectedTask?.progress || {};
  const theme = useTheme();
  const { t } = useTranslation();
  const { task, subTasks, index } = props;
  const lastIndex = task?.length - 1;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(statusOptions.find(item => item?.value === status));

  const getStatusStyles = status => {
    switch (status) {
      case 'COMPLETE':
        return {
          color: theme.success_500,
          background: theme.success_50,
        };
      case 'BLOCKED':
        return {
          color: theme.primary_500,
          background: theme.primary_50,
        };
      case 'STARTED':
        return {
          color: theme.purple_500,
          background: theme.purple_50,
        };
      default:
        return {
          color: theme.natural_600,
          background: theme.natural_150,
        };
    }
  };

  const StatusLabel = ({ data, isMainContent = false }) => {
    const { color, background } = useMemo(() => getStatusStyles(data?.value, theme), [data?.value, theme]);

    if (isMainContent) {
      return (
        <label
          style={{
            background,
            color,
            borderRadius: '50px',
            width: 'max-content',
            minWidth: 'auto',
          }}
          onClick={() => setTooltipOpen(true)}
          className="select-label flex items-center justify-between font-12 px-3 text-center">
          <label>{selectedStatus?.label}</label>
          <IconContainer
            Icon={ArrowDownIcon}
            iconColor={color}
            backgroundColor={background}
            iconWidth={14}
            iconHeight={14}
          />
        </label>
      );
    } else {
      return (
        <label
          style={{
            background,
            color,
            borderRadius: '50px',
          }}
          className="flex items-center justify-center font-12 px-3 py-1 text-center">
          {data?.label}
        </label>
      );
    }
  };

  const dateFormat = data => {
    const currentDate = moment();
    const isSameDay = currentDate.isSame(data, 'day');
    return (
      <div className="flex-column justify-center row-gap-2 task-items overflow-hidden">
        <label className={`inter-400-text natural-700-text line-height-20 font-14 ${isSameDay && 'color-blue'}`}>
          {!isSameDay && start_date ? moment.unix(start_date).format('DD MMM') : 'Today'}
        </label>
      </div>
    );
  };

  return (
    <div className={classNames('flex items-center justify-between', lastIndex !== index && 'border-bottom pb-3')}>
      <div className="flex items-center gap-2">
        <CustomTooltip
          wrapperClassName="status-tooltip"
          tooltipClassname="custom-scrollbar thin-scrollbar"
          id={`status-tooltip-${index}`}
          place="bottom"
          clickable
          isOpen={tooltipOpen}
          onBlurTooltip={() => setTooltipOpen(false)}
          hidden={!tooltipOpen}
          noArrow={true}
          content={statusOptions?.map((data, index) => (
            <div
              key={data?.id}
              onClick={() => {
                setSelectedStatus(data);
                setTooltipOpen(false);
              }}
              className="px-4 py-1 font-14 border-bottom cursor proposal-config">
              <StatusLabel data={data} />
            </div>
          ))}>
          <StatusLabel data={selectedStatus} isMainContent={true} />
        </CustomTooltip>
        <label className="inter-400-text natural-700-text line-height-20 font-14 text-ellipsis">{task?.name}</label>
      </div>
      <div className="flex items-center gap-2">
        <div className="flex items-center">
          {selectedTask?.assignee?.name && (
            <div className="flex items-center col-gap-1 one-line">
              <Avatar avatar={selectedTask?.assignee} variant="medium" />
            </div>
          )}
        </div>
        <div className="flex-column justify-center row-gap-2 task-items overflow-hidden">
          {start_date ? dateFormat(start_date) : ''}
        </div>
      </div>
    </div>
  );
};

export default SubTaskItem;
