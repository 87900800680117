import React from 'react';
import { useSelector } from 'react-redux';
import { AutomationLogDetailsRightWrapper } from '../../../styles/components/automation-log-detail/automation-log-details.styled';
import AutomationLogAction from './automation-log-action';

const AutomationLogDetailsRight = () => {
  const { automationLogDetails } = useSelector(state => state.automations);
  return (
    <AutomationLogDetailsRightWrapper className="flex-column flex-1 w-full overflow-scroll p-6 border">
      {(automationLogDetails?.actions || [])?.map((data, index) => (
        <>
          <AutomationLogAction automationAction={data} key={data?.id} index={index} />
          {index + 1 !== automationLogDetails?.actions.length && <div className="border-left vertical-line ml-10" />}
        </>
      ))}
    </AutomationLogDetailsRightWrapper>
  );
};

export default AutomationLogDetailsRight;
