import { createSlice } from '@reduxjs/toolkit';
import api from '../services';

export const jobSlice = createSlice({
  name: 'jobs',
  initialState: {},
  reducers: {},
});

export const getJobTypeList =
  ({ params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/job_types`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getJobTypeOrganizations =
  ({ id, params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/job_types/${id}/organizations`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };
export const createJobPartners =
  ({ id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/engagements/${id}/partners`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getProjectList =
  ({ params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/projects`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };
export const createProject =
  ({ request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/projects`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getProjectDetails =
  ({ projects_id }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/projects/${projects_id}`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };
export const updateProjectDetails =
  ({ projects_id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.put(`/projects/${projects_id}`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getWorkflowList =
  ({ params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/workflows`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getStagesListData =
  ({ params, workflowId }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/workflows/${workflowId}/stages`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getJobs =
  ({ params = {} }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/jobs`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const createJob =
  ({ request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/jobs`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updateJob =
  ({ id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.put(`/jobs/${id}`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const createWorkitem =
  ({ request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/workitems`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const {} = jobSlice.actions;
export default jobSlice.reducer;
