import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import styled from 'styled-components';
import { ReactComponent as CrossIcon } from '../assets/icons/CrossIcons.svg';
import Button from '../components/common/button/button';
import InputElement from '../components/common/input';
import { OrganisationContext } from '../context/organisationContext';
import { getItemFromLocalStorage } from '../helpers/localstorage';
import { initModal } from '../helpers/utils';
import { createNewRole } from '../store/features/roleUserSlice';
import { addToast } from '../store/features/toastSlice';
import { getUserRoles } from '../store/features/userManagementSlice';

const AddNewRole = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { userOrganization } = useSelector(state => state.user);
  const { role: userRole } = userOrganization;

  const { modal, setModal } = useContext(OrganisationContext);
  const { title } = modal?.content || {};

  const [role, setRole] = useState({});
  const [error, setError] = useState({ name: false });
  const [loading, setLoading] = useState(false);

  const checkAddNewRoleErrors = () => {
    if (!role?.name?.trim() || !role?.description?.trim() || !role?.tier?.trim()) {
      setError({
        name: !role?.name?.trim(),
        description: !role?.description?.trim(),
        tier: !role?.tier?.trim(),
      });
      dispatch(
        addToast({
          error: true,
          text: t('ENTER_NAME_DESCRIPTION_TIER'),
        }),
      );
      return true;
    } else if (role?.tier < userRole?.tier) {
      setError({
        name: false,
        description: false,
        tier: true,
      });
      dispatch(
        addToast({
          error: true,
          text: t('YOU_CANT_ADD_ROLES_WITH_TIER_ABOVE_YOU'),
        }),
      );
      return true;
    }
    return false;
  };

  const onDone = async () => {
    const userLocal = getItemFromLocalStorage('user');
    const organization_id = userLocal?.organizations[0]?.id;
    if (loading) {
      return;
    }
    if (checkAddNewRoleErrors()) {
      return;
    }
    setLoading(true);
    const request = {
      name: role.name,
      description: role.description,
      permissions: [],
      is_default: false,
      tier: role?.tier,
      requires_mfa: false,
      is_super_user: false,
      tag: null,
      applications: null,
    };
    dispatch(createNewRole({ request: request, organization_id: organization_id }))
      .then(({ data }) => {
        dispatch(
          addToast({
            error: false,
            text: t('SUCCESSFULLY_RILE_ADDED'),
            id: uuid(),
          }),
        );
        dispatch(getUserRoles());
        setLoading(false);
        setModal(initModal);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <AddNewRoleWrapper className="flex-column items-center py-9 px-10">
      <div className="w-full flex justify-between items-center">
        <label className="font-20 inter-600-text text-black text-start">{title}</label>
        <CrossIcon className="cursor" onClick={() => setModal(initModal)} width={24} height={24} />
      </div>

      <div className="w-full flex-column gap-5px mt-8">
        <InputElement
          name={t('ROLE_NAME')}
          onChange={value => {
            setRole({ ...role, name: value });
            setError({ ...error, name: false });
          }}
          error={error.name}
          placeholder={t('TYPE_ROLE_FULL_NAME')}
          value={role?.name}
        />
      </div>
      <div className="w-full flex-column gap-5px mt-6">
        <InputElement
          name={t('DESCRIPTION')}
          onChange={value => {
            setRole({ ...role, description: value });
            setError({ ...error, description: false });
          }}
          error={error.description}
          value={role.description || ''}
          placeholder={t('DESCRIPTION_PLACEHOLDER_ROLE')}
          type="textarea"
        />
      </div>
      <div className="w-full flex-column gap-5px mt-6">
        <InputElement
          name={t('TIER')}
          type="number"
          onChange={value => {
            setRole({ ...role, tier: value });
            setError({ ...error, tier: false });
          }}
          error={error.tier}
          placeholder={t('TYPE_ROLE_TIER')}
          value={role?.tier}
        />
      </div>
      <div className="flex col-gap-5 justify-center mt-10 w-full">
        <Button
          className={`default flex-1 `}
          label={t('CANCEL')}
          disabled={loading}
          onClick={() => setModal(initModal)}
          secondary={true}
          size="large"
          borderRadius="100px"
        />
        <Button
          className={`primary flex-1`}
          label={t('SAVE')}
          disabled={loading}
          onClick={onDone}
          size="large"
          borderRadius="100px"
        />
      </div>
    </AddNewRoleWrapper>
  );
};

const AddNewRoleWrapper = styled.div`
  width: 455px;

  .error-border {
    border-color: ${({ theme }) => theme.additionalRed};

    .organisations-border {
      border-color: ${({ theme }) => theme.additionalRed} !important;
    }
  }

  .input,
  .textarea {
    color: #171717;
  }
`;

export default AddNewRole;
