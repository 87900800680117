import { createSlice } from '@reduxjs/toolkit';
import { getItemFromLocalStorage } from '../../helpers/localstorage';
import api from '../services';

export const usersManagementSlice = createSlice({
  name: 'UsersManagement',
  initialState: {
    users: [],
    userRoles: [],
  },
  reducers: {
    setUsers: (state, { payload }) => {
      const { data, merge } = payload;
      if (merge) {
        state.users = { ...data, content: [...state.users.content, ...data.content] };
      } else {
        state.users = data;
      }
    },
    updateUsersList: (state, { payload }) => {
      state.users.content = [
        ...state.users.content.map(user =>
          user.id === payload.id
            ? {
                ...user,
                is_active: !user.is_active,
                status:
                  user.status === 'ACTIVE' || user.status === 'INACTIVE'
                    ? user.is_active
                      ? 'INACTIVE'
                      : 'ACTIVE'
                    : user.status,
              }
            : { ...user },
        ),
      ];
    },
    removeUserFromList: (state, { payload }) => {
      state.users.content = [...state.users.content.filter(user => user.id !== payload.id)];
    },
    setUserRoles: (state, { payload }) => {
      state.userRoles = payload.data;
    },
    setUserStatus: (state, { payload }) => {
      state.users.content = [
        ...state.users.content.map(user =>
          user.id === payload.id
            ? {
                ...user,
                status: payload.status,
              }
            : { ...user },
        ),
      ];
    },
    updateUserRole: (state, { payload }) => {
      state.userRoles = [...state.userRoles.map(role => (role.id === payload.id ? { ...role, ...payload } : role))];
    },
    removeTeamFromUserList: (state, { payload }) => {
      const user = state.users?.content?.find(user => user.id === payload.user_id);
      if (user) {
        const updatedUser = {
          ...user,
          teams: user.teams.filter(team => team.id !== payload.team_id),
        };
        const updatedUsers = state.users?.content?.map(user => (user.id === payload.user_id ? updatedUser : user));
        state.users.content = updatedUsers;
      }
    },
    addTeamToUserList: (state, { payload }) => {
      const user = state.users?.content?.find(user => user.id === payload.user_id);
      if (user) {
        const updatedUser = {
          ...user,
          teams: [...user.teams, { name: payload.team_name, id: payload.team_id }],
        };
        const updatedUsers = state.users?.content?.map(user => (user.id === payload.user_id ? updatedUser : user));
        state.users.content = updatedUsers;
      }
    },
  },
});

export const getUsers = payload => async dispatch => {
  try {
    const { merge, params, forFetchOnly = false } = payload;
    const { data } = await api.get(`/users`, { params: params });
    !forFetchOnly && dispatch(setUsers({ data, merge }));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateUsersProfile = payload => async () => {
  try {
    const { id, request } = payload;
    const { data } = await api.put(`/users/${id}`, request);
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const OTPConfirmation = payload => async () => {
  try {
    const { request } = payload;
    const { data } = await api.post(`/users/profile/confirm`, request);
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateUsersServices = payload => async () => {
  try {
    const { user_id, request } = payload;
    const { data } = await api.put(`/users/${user_id}/services`, request);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateUsersTeams = payload => async dispatch => {
  const localUser = getItemFromLocalStorage('user');
  try {
    const { team_id, team_name, request } = payload;
    const { data } = await api.post(
      `/organizations/${localUser?.organizations[0]?.id}/teams/${team_id}/users`,
      request,
    );
    dispatch(addTeamToUserList({ user_id: request[0].user.id, team_id: team_id, team_name: team_name }));
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const removeUserFromTeam =
  ({ team_id, user_id }) =>
  async dispatch => {
    const localUser = getItemFromLocalStorage('user');
    try {
      const response = await api.delete(
        `/organizations/${localUser?.organizations[0]?.id}/teams/${team_id}/users/${user_id}`,
      );
      dispatch(removeTeamFromUserList({ user_id: user_id, team_id: team_id }));
      return Promise.resolve(response.status);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updateUsersStatus = payload => async () => {
  try {
    const { id, params } = payload;
    const { data } = await api.put(`/users/${id}/active`, params);
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addUsers = payload => async dispatch => {
  const userLocal = getItemFromLocalStorage('user');
  try {
    const { isInviteUserAPI } = payload;
    const { data } = await api.post(`/organizations/${userLocal.organizations[0].id}/users`, payload.user);
    if (isInviteUserAPI) {
      await dispatch(inviteUsers({ organization_id: userLocal.organizations[0].id, user: data }));
    }
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const inviteUsers = payload => async dispatch => {
  try {
    const { organization_id, user } = payload;
    const { data } = await api.post(`/organizations/${organization_id}/users/${user?.id}/invite`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteUser = payload => async dispatch => {
  try {
    const { data } = await api.delete(`/users/${payload.id}`);
    dispatch(removeUserFromList(payload));
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getUserRoles = payload => async dispatch => {
  const userLocal = getItemFromLocalStorage('user');
  const { data } = await api.get(`/organizations/${userLocal.organizations[0].id}/roles`);
  dispatch(setUserRoles({ data: data }));
};

export const addUsersToTeam =
  ({ team_id, request }) =>
  async dispatch => {
    const userLocal = getItemFromLocalStorage('user');
    try {
      const { data } = await api.post(
        `/organizations/${userLocal.organizations[0].id}/teams/${team_id}/users`,
        request,
      );
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getOrganizationUsersList =
  ({ organization_id, params = {} }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/organizations/${organization_id}/users`, { params: params });
      return Promise.resolve(data.content);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getUserData =
  ({ user_id }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/users/${user_id}`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const {
  setUsers,
  updateUsersList,
  removeUserFromList,
  setUserRoles,
  setUserStatus,
  updateUserRole,
  removeTeamFromUserList,
  addTeamToUserList,
} = usersManagementSlice.actions;
export default usersManagementSlice.reducer;
