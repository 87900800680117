import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as CloseIcon } from '../../assets/icons/property/close.svg';
import Button from '../../components/common/button/button';
import IconContainer from '../../components/common/icon-container';
import InputElement from '../../components/common/input';
import { OrganisationContext } from '../../context/organisationContext';
import { initModal } from '../../helpers/utils';

const AddFeature = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { modal, setModal } = useContext(OrganisationContext);
  const [loading, setLoading] = useState(false);

  const { content } = modal;
  const { product, updateProductInfo } = content;
  const [feature, setFeature] = useState({
    id: nanoid(),
    language: 'en',
    name: '',
    priority: 0,
    description: '',
    long_description: '',
  });

  const { name, description, long_description } = feature;

  function updateFeature(key, value) {
    setFeature({ ...feature, [key]: value });
  }

  function handleAddFeature() {
    updateProductInfo('features', [feature, ...product.features]);
    setModal(initModal);
  }

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <AddFeatureWrapper>
        <div className="px-10 py-9">
          <div className="flex justify-between">
            <div>
              <p className="inter-600-text font-20 line-height-28 natural-900-text">{t('NEW_FEATURE')}</p>
            </div>
            <IconContainer
              iconContainerClassname="cursor"
              onClick={() => setModal(initModal)}
              backgroundColor={'white'}
              Icon={CloseIcon}
            />
          </div>
          <div className="mt-8">
            <InputElement
              name={t('NAME')}
              placeholder={t('NAME_PLACEHOLDER')}
              value={name}
              onChange={e => updateFeature('name', e)}
            />
          </div>
          <div className="mt-6">
            <InputElement
              sub_name="(small)"
              name={t('DESCRIPTION')}
              placeholder={t('DESCRIPTION_PLACEHOLDER')}
              value={description}
              onChange={e => updateFeature('description', e)}
            />
          </div>
          <div className="mt-6">
            <InputElement
              type="textarea"
              sub_name="(long)"
              name={t('DESCRIPTION')}
              placeholder={t('DESCRIPTION_PLACEHOLDER')}
              value={long_description}
              onChange={e => updateFeature('long_description', e)}
            />
          </div>
          <div className="mt-10 flex gap-5">
            <Button
              label={t('CANCEL')}
              onClick={() => setModal(initModal)}
              size="large"
              className="py-3"
              bgColor={theme.white}
              borderColor={theme.natural_200}
              color={theme.natural_700}
            />
            <Button
              label={t('ADD')}
              size="large"
              className="py-3"
              onClick={handleAddFeature}
              borderColor={theme.primary_500}
              bgColor={theme.primary_500}
              color={theme.white}
              disabled={!(name && description && long_description)}
            />
          </div>
        </div>
      </AddFeatureWrapper>
    </CSSTransition>
  );
};

export default AddFeature;

const AddFeatureWrapper = styled.div`
  width: 455px;
  max-height: 700px;

  .textarea-element {
    height: 255px;
  }
`;
