import React from 'react';

import { MainLayoutWrapper } from '../../styles/components/main-layout/main-layout.styles';
import NavBar from './navbar/navbar';
import Sidebar from './sidebar/sidebar';

const MainLayout = ({ menuItems, onItemClick, user, children }) => {
  return (
    <MainLayoutWrapper className="flex-column w-full">
      <NavBar />
      <div className="flex w-full h-full">
        <Sidebar menuItems={menuItems} onItemClick={onItemClick} user={user} />
        {children}
      </div>
    </MainLayoutWrapper>
  );
};

export default MainLayout;
