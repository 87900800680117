import classNames from 'classnames';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { OrganisationContext } from '../../../../context/organisationContext';
import { capitalize, formatText, getFormattedDate } from '../../../../helpers/utils';
import MenuV2 from '../../../common/menu/menu-v2';

const AutomationLogItem = ({ automationLog, fromAutomations = flase }) => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { setModal } = useContext(OrganisationContext);

  const onAutomationLog = () => {
    const { automation, id } = automationLog || {};
    if (fromAutomations) {
      navigate(`/platforms/automations/${automation?.id}/automation-logs/${id}`);
      return;
    } else {
      navigate(`/platforms/automation-logs/${id}`);
    }
  };

  const onCancel = () => {
    setModal({
      type: 'automation-cancel',
      content: { automationLog },
      title: t('CANCLE_AUTOMATION'),
      description: t('UNDO_ACTION'),
    });
  };

  return (
    <AutomationLogWrapper
      className="data-container items-center justify-center w-full py-0 cursor"
      onClick={() => onAutomationLog()}>
      <div className="brd-right flex items-cente w-full">
        <label className="inter-500-text color-neutral-900 ont-14 one-line">{automationLog?.id}</label>
      </div>
      <div className="brd-right flex items-center w-full overflow-hidden">
        <label className="inter-400-text color-neutral-900 font-14 one-line">
          {getFormattedDate(automationLog?.created_on, 'DD/MM/YY - hh:mm a')}
        </label>
      </div>
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center flex-1">
          <div
            className={classNames(
              'flex items-center justify-center radius-6 status-container',
              formatText(automationLog?.status, ''),
            )}>
            <label className="inter-500-text color-neutral-900 font-12 one-line">
              {capitalize(formatText(automationLog?.status, ''))}
            </label>
          </div>
        </div>
      </div>
      <div>
        <MenuV2
          menuList={[
            { name: t('DETAILS'), onClick: () => onAutomationLog(), permission: 'AUTOMATION_LOGS_VIEW' },
            automationLog?.status === 'PENDING' && { name: t('CANCEL'), onClick: () => onCancel() },
          ].filter(Boolean)}
          iconClassName="rotate-90 dots-icon"
        />
      </div>
    </AutomationLogWrapper>
  );
};

const AutomationLogWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  min-height: 56px;

  .dots-icon {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .status-container {
    padding: 2px 10px;
    max-height: 24px;
  }
  .success {
    background: ${({ theme }) => theme.success_50};
    label {
      color: ${({ theme }) => theme.success_500};
    }
  }
  .cancelled {
    background: ${({ theme }) => theme.natural_50};
    label {
      color: ${({ theme }) => theme.natural_700};
    }
  }
  .pending {
    background: ${({ theme }) => theme.orange_50};
    label {
      color: ${({ theme }) => theme.orange_500};
    }
  }
  .failure {
    background: ${({ theme }) => theme.error_50};
    label {
      color: ${({ theme }) => theme.error_500};
    }
  }
  .partialfail {
    background: ${({ theme }) => theme.additionalRed};
    label {
      color: #ffff;
    }
  }

  .skipped {
    background: ${({ theme }) => theme.additionalYellow2};
    label {
      color: #ffff;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.lightGreyBackground};
  }
`;

export default AutomationLogItem;
