import { createSlice } from '@reduxjs/toolkit';
import api from '../services';

export const propertySlice = createSlice({
  name: 'property',
  initialState: {
    propertyList: [],
    propertyDetails: {},
    propertyContacts: [],
    propertyEpcs: [],
    propertyEngageMents: [],
    jobDetails: {},
    roofInformation: [],
    roofPanelInformation: [],
  },
  reducers: {
    setPropertyList: (state, { payload }) => {
      const { data, merge } = payload;
      if (merge) {
        state.propertyList = { ...data, content: [...state.propertyList.content, ...data.content] };
      } else {
        state.propertyList = data;
      }
    },
    setPropertyDetails: (state, { payload }) => {
      state.propertyDetails = payload;
    },
    setPropertyContacts: (state, { payload }) => {
      state.propertyContacts = payload;
    },
    setPropertyEpcs: (state, { payload }) => {
      state.propertyEpcs = payload;
    },
    setPropertyEngageMent: (state, { payload }) => {
      state.propertyEngageMents = payload;
    },
    setJobDetails: (state, { payload }) => {
      state.jobDetails = payload;
    },
    setEvidenceFileList: (state, { payload }) => {
      state.evidenceFileList = payload;
    },
    setRoofInformation: (state, { payload }) => {
      state.roofInformation = payload;
    },

    updateRoofInformation: (state, { payload }) => {
      const { data, whole_roof_id } = payload;
      const isNewRoof = state.roofInformation.every(item => item?.whole_roof?.id !== whole_roof_id);
      if (isNewRoof) {
        state.roofInformation = [...state.roofInformation, data];
      } else {
        state.roofInformation = state.roofInformation.map(item =>
          item?.whole_roof?.id === whole_roof_id ? data : item,
        );
      }
    },
    updateRoofSegmentInformation: (state, { payload }) => {
      const { data, whole_roof_id } = payload;
      state.roofInformation = state.roofInformation.reduce((acc, item) => {
        if (item?.whole_roof?.id === whole_roof_id) {
          return [
            ...acc,
            { ...item, segments: item.segments?.map(segment => (segment.id === data.id ? data : segment)) },
          ];
        }
        return [...acc, item];
      }, []);
    },
    setRoofPanelInformation: (state, { payload }) => {
      state.roofPanelInformation = payload;
    },
    setEvidenceFile: (state, { payload }) => {
      state.evidenceFileList = state.evidenceFileList.map(item => {
        if (item.id === payload.id) return payload;
        return item;
      });
    },
    setUpdateFilesList: (state, { payload }) => {
      state.updateFilesList = payload;
    },
  },
});

export const getPropertyList =
  ({ params, merge }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/properties`, { params: params });
      dispatch(setPropertyList({ data, merge }));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getPropertyDetails =
  ({ property_id, params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/properties/${property_id}`, { params: params });
      dispatch(setPropertyDetails(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getPropertyEngagements =
  ({ property_id, params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/properties/${property_id}/engagements`, {
        params: params,
      });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getPropertyContacts =
  ({ property_id, forFetchOnly, params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/properties/${property_id}/contacts`, { params: params });
      !forFetchOnly && dispatch(setPropertyContacts(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getPropertyEpcs =
  ({ property_id, params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/properties/${property_id}/epcs`, { params: params });
      dispatch(setPropertyEpcs(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getEvidences =
  ({ params, forFetchOnly = false }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/evidence`, { params: params });
      !forFetchOnly && dispatch(setEvidenceFileList(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getEvidencesData =
  ({ id }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/evidence/${id}`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updateEvidencePropertyFile = payload => async dispatch => {
  try {
    const { request, evidence_id } = payload;
    const { data } = await api.put(`/evidence/${evidence_id}`, request);
    dispatch(setUpdateFilesList(data));
    dispatch(setEvidenceFile(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createEvidences =
  ({ request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/evidence`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const deleteFiles = payload => async dispatch => {
  try {
    const { file_id } = payload;
    const { data } = await api.delete(`/evidence/${file_id}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getJobDetails =
  ({ job_id }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/jobs/${job_id}`);
      dispatch(setJobDetails(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getSolarOptions =
  ({ id, params = {} }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/solar_designs/${id}/solar_options`, {
        params: params,
      });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getRoofInformation =
  ({ params, id }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/solar_designs/${id}/roofs`, { params: params });
      dispatch(setRoofInformation(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const importRoofInformation =
  ({ id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/solar_designs/${id}/roofs`, request);
      dispatch(updateRoofInformation({ data: data, whole_roof_id: data.whole_roof.id }));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const importRoofInformationFromGoogle =
  ({ property_id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/solar_designs/${property_id}/roofs/import`, request);
      dispatch(updateRoofInformation({ data: data, whole_roof_id: data.whole_roof.id }));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getRoofPanelInformation =
  ({ params, id, roof_id }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/solar_designs/${id}/roofs/${roof_id}/panels`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updateRoofSegment =
  ({ request, id, roof_id, whole_roof_id }) =>
  async dispatch => {
    try {
      const { data } = await api.put(`/solar_designs/${id}/roofs/${roof_id}`, request);
      dispatch(updateRoofSegmentInformation({ data: data, whole_roof_id: whole_roof_id }));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };
export const deleteRoofEdit =
  ({ id, roof_id }) =>
  async dispatch => {
    try {
      const { data } = await api.delete(`/solar_designs/${id}/roofs/${roof_id}`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getPropertyImages =
  ({ params, id }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/solar_designs/${id}/images`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updateRoofPanels =
  ({ request, id, roof_id, params = {} }) =>
  async dispatch => {
    try {
      const { data } = await api.put(`/solar_designs/${id}/roofs/${roof_id}/panels`, request, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      console.log('error', error);
      return Promise.reject(error);
    }
  };

export const updatePropertyDetails =
  ({ id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.put(`/properties/${id}`, request);
      dispatch(setPropertyDetails(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getEnergyConsumption =
  ({ id, type, params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/properties/${id}/energy_consumption/${type}`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const importEnergyConsumption =
  ({ id, type, request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/properties/${id}/energy_consumption/${type}`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const addRoofInformation =
  ({ id, request, forFetchOnly = false }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/solar_designs/${id}/roofs`, request);
      !forFetchOnly && dispatch(updateRoofInformation({ data: data, whole_roof_id: data.whole_roof.id }));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const createSegmentPanels =
  ({ request, id, roof_id, params = {} }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/solar_designs/${id}/roofs/${roof_id}/panels`, request, { params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getEnergyConsumptionPatterns =
  ({ params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/energy_consumption_patterns`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getEnergyCosts =
  ({ property_id, type, params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/properties/${property_id}/energy_costs/${type}`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updateEnergyCosts =
  ({ property_id, type, params, request }) =>
  async dispatch => {
    try {
      const { data } = await api.put(`/properties/${property_id}/energy_costs/${type}`, request, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updatePropertyDetailsData =
  ({ property_id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.put(`/properties/${property_id}/details`, request);
      dispatch(setPropertyDetails(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const {
  setPropertyList,
  setPropertyDetails,
  setPropertyContacts,
  setPropertyEpcs,
  setPropertyEngageMent,
  setJobDetails,
  setEvidenceFileList,
  setUpdateFilesList,
  setEvidenceFile,
  setRoofInformation,
  setRoofPanelInformation,
  updateRoofInformation,
  updateRoofSegmentInformation,
} = propertySlice.actions;
export default propertySlice.reducer;
