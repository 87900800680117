import { createSlice } from '@reduxjs/toolkit';
import api from '../services';

export const activitySlice = createSlice({
  name: 'activities',
  initialState: {},
  reducers: {},
});

export const getActivityList = payload => async dispatch => {
  try {
    const { engagement_id, params } = payload;
    const { data } = await api.get(`/engagements/${engagement_id}/activities`, { params: params });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getActivity = payload => async dispatch => {
  try {
    const { activity_id } = payload;
    const { data } = await api.get(`/activities/${activity_id}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAvailability =
  ({ params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/availability`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getOrganizationServices =
  ({ organization_id, params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/organizations/${organization_id}/services`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getJobTypeServices =
  ({ job_type_id, params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/job_types/${job_type_id}/services`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const createActivities =
  ({ request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/activities`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getActivityTasks = payload => async dispatch => {
  try {
    const { activity_id, params = {} } = payload;
    const { data } = await api.get(`/tasks`, { params });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getCancleReason = payload => async dispatch => {
  try {
    const { data } = await api.get(`/proptech/cancel_reasons`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateActivityStatus = payload => async dispatch => {
  try {
    const { activity_id, request } = payload;
    const { data } = await api.put(`/activities/${activity_id}/status`, request);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getNotificationToken = token => async dispatch => {
  try {
    const { data } = await api.get(`/notifications/token`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getActivities =
  ({ params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/activities`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updateActivity =
  ({ activity_id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.put(`/activities/${activity_id}`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const createActivityResorces =
  ({ activity_id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/activities/${activity_id}/resources`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updateActivityResorces =
  ({ activity_id, resource_id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.put(`/activities/${activity_id}/resources/${resource_id}`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const deleteActivityResorces =
  ({ activity_id, resource_id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.delete(`/activities/${activity_id}/resources/${resource_id}`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getTaskConfigGroups =
  ({ params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/task_configs`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const createTasks =
  ({ request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/tasks`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const {} = activitySlice.actions;
export default activitySlice.reducer;
