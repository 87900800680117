import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { OrganisationContext } from '../../context/organisationContext';
import MenuV2 from '../common/menu/menu-v2';

const TeamListItem = ({ team, menuPortal }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { setModal } = useContext(OrganisationContext);

  const onDeleteTeam = () => {
    setModal({
      type: 'team-action',
      content: {
        team: team,
        top: false,
        isDelete: true,
        title: t('YOU_WANT_TO_DELETE_TEAM'),
        description: t('DELETE_ACTION_DESCRIPTION'),
      },
    });
  };

  const redirect = () => {
    navigate(`/settings/security/teams/${team.id}`);
  };

  return (
    <TeamsListItemWrapper
      className="data-container items-center justify-center w-full py-0 cursor"
      onClick={() => redirect()}>
      <div className="brd-right flex items-cente w-full">
        <label className="inter-500-text color-neutral-900 ont-14 one-line">{team?.name}</label>
      </div>
      <div className="brd-right flex items-center w-full overflow-hidden">
        <label className="inter-400-text color-neutral-900 font-14 one-line">{team?.description}</label>
      </div>
      <div className="flex items-center justify-between w-full">
        <label className="inter-400-text color-neutral-900 font-14 one-line">{team?.members}</label>
      </div>
      <div>
        <MenuV2
          menuList={[
            { name: t('DETAILS'), onClick: () => redirect(), permission: 'TEAMS_VIEW' },
            // { name: t('DUPLICATE), onClick: () => {}, permission: 'TEAMS_MANAGE' },
            { name: t('DELETE'), onClick: () => onDeleteTeam(), permission: 'TEAMS_MANAGE' },
          ]}
          iconClassName="rotate-90 dots-icon"
          portal={menuPortal}
        />
      </div>
    </TeamsListItemWrapper>
  );
};

const TeamsListItemWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.lightGrey};
  .dots-icon {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.lightGreyBackground};
  }
`;

export default TeamListItem;
