import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as CrossIcon } from '../../assets/icons/CrossIcons.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/add-icon.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit-pensil.svg';
import { ReactComponent as RightIcon } from '../../assets/icons/right-Icons.svg';
// import IconContainer from '../../components/common/icon-container';
import Button from '../../components/common/button/button';
import SearchableDropdown from '../../components/common/searchable-dropdown';
import { getErrorDescription, getFormattedDate, uppercase } from '../../helpers/utils';
import { getTeamsList } from '../../store/features/teamsManagmentSlice';
import { addToast } from '../../store/features/toastSlice';
import { removeUserFromTeam, updateUsersTeams } from '../../store/features/userManagementSlice';

const RenderTeamsItem = ({ teamsData }) => {
  const { t } = useTranslation();

  const { id, name, joined_date } = teamsData;

  return (
    <div className="flex items-center justify-between gap-2" key={id}>
      <div>
        <label className="inter-400-text font-14 line-height-20 natural-900-text">{name}</label>
      </div>
      <div className="flex items-center col-gap-2">
        <label className="inter-400-text font-14 line-height-20 natural-500-text">{t('JOINED')}:</label>
        <p className="inter-400-text font-14 line-height-20 natural-900-text">
          {joined_date ? getFormattedDate(joined_date, 'DD/MM/YY') : '-'}
        </p>
      </div>
    </div>
  );
};

const EditTeam = ({ item, index, onUpdateTeam, onRemoveTeam, alreadyTeamUserAdded }) => {
  const [searchedTeam, setSearchedTeam] = useState('');
  const { t } = useTranslation();

  return (
    <div className="flex items-center gap-4">
      <SearchableDropdown
        defaultAdditional={{
          page: 0,
          pageable: true,
          fetchFunction: getTeamsList,
        }}
        isCustomSearchable={false}
        isSearchable={true}
        inputValue={searchedTeam}
        menuPlacement="top"
        onInputChange={setSearchedTeam}
        placeholder={t('SELECT_TEAM')}
        name={`Team ${index + 1}`}
        value={item.id ? item : null}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
        onChange={option => {
          const updatedTeam = { ...item, ...option };
          onUpdateTeam(updatedTeam);
        }}
        filterOption={option => {
          return !alreadyTeamUserAdded.includes(option.value);
        }}
      />
      <div className="mt-5 cursor">
        <CrossIcon color="#171717" width={16} height={16} onClick={() => onRemoveTeam(item)} />
      </div>
    </div>
  );
};

const UserTeams = ({ teams = [], editKey, setEditKey, user_id, fetchProfileDetails }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [teamsToAdd, setTeamsToAdd] = useState(teams);
  const [isLoading, setIsLoading] = useState(false);
  const alreadyTeamUserAdded = teamsToAdd?.map(m => m?.id) || [];

  const onUpdateUserTeams = async () => {
    setIsLoading(true);
    const teamsIdsSet = new Set(teams.filter(item => item && item.id).map(item => item.id));
    const newTeamsIdsSet = new Set(teamsToAdd.filter(item => item && item.id).map(item => item.id));

    const teamsIdToDelete = teams.filter(team =>
      [...teamsIdsSet].filter(id => !newTeamsIdsSet.has(id)).includes(team.id),
    );
    const teamsIdToAdd = teamsToAdd.filter(team =>
      [...newTeamsIdsSet].filter(id => !teamsIdsSet.has(id)).includes(team.id),
    );

    await Promise.all([
      Promise.all(
        teamsIdToAdd?.map(team =>
          dispatch(
            updateUsersTeams({
              team_id: team.id,
              team_name: team.name,
              request: [
                {
                  user: {
                    id: user_id,
                  },
                  level: 'MEMBER',
                },
              ],
            }),
          ),
        ),
      ),
      Promise.all(teamsIdToDelete?.map(team => dispatch(removeUserFromTeam({ team_id: team.id, user_id: user_id })))),
    ])
      .then(() => {
        dispatch(addToast({ error: false, text: t('USER_VALUE_UPDATED', { value: 'teams' }) }));
        setEditKey(null);
        fetchProfileDetails(user_id);
      })
      .catch(error => {
        const errorText = getErrorDescription(error, t('ERROR_WHILE_UPDATING_PROFILE_DETAIL'));
        dispatch(addToast({ error: true, text: errorText, id: nanoid() }));
      })
      .finally(() => setIsLoading(false));
  };

  const onAddTeam = () => {
    setTeamsToAdd([...teamsToAdd, { team_id: nanoid() }]);
  };

  const onRemoveTeam = team => {
    setTeamsToAdd(teamsToAdd.filter(s => s.team_id !== team.team_id));
  };

  const onUpdateTeam = team => {
    const updatedTeams = teamsToAdd.map(s => (s.team_id === team.team_id ? team : s));
    setTeamsToAdd(updatedTeams);
  };

  return (
    <TamsListWrapper>
      <div
        className="py-5 teams-service-edit-wrapper cursor"
        onClick={() => editKey !== 'TEAMS' && setEditKey('TEAMS')}>
        <div className="flex justify-between items-center w-full mb-4">
          <label className="inter-600-text font-14 line-height-20 natural-900-text">{uppercase(t('TEAMS'))}</label>
          {editKey === 'TEAMS' ? (
            <>
              <Button
                fontSize="12px"
                size="average"
                width="auto"
                borderRadius="100px"
                className="secondary specified-width px-4 py-2_5 ml-3"
                afterIcon={<PlusIcon className="after-icon blue-primary-icon" height={14} width={14} />}
                label={`${t('ADD_NEW_ITEM', { item: t('TEAMS') })}`}
                onClick={onAddTeam}
              />
            </>
          ) : (
            <EditIcon width={14} height={14} className="edit-icon cursor" color="#2496FF" />
          )}
        </div>

        {editKey === 'TEAMS' ? (
          <div>
            <div className="flex-column service-grid row-gap-4">
              {teamsToAdd?.length ? (
                teamsToAdd?.map((item, index) => (
                  <EditTeam
                    key={index}
                    index={index}
                    item={item}
                    onRemoveTeam={onRemoveTeam}
                    onUpdateTeam={onUpdateTeam}
                    alreadyTeamUserAdded={alreadyTeamUserAdded}
                  />
                ))
              ) : (
                <label className="inter-400-text font-14 line-height-20 natural-400-text">{t('NO_DATA')}</label>
              )}
            </div>
            <div className="w-full flex justify-end relative">
              <div
                className={classNames(
                  'flex justify-center items-end radius-3 profile-update-action mt-1 border pxy-2 gap-2_5 absolute',
                  isLoading && 'disabled',
                )}>
                <span
                  className="flex justify-center items-center cross-icon border radius-100 cursor"
                  onClick={() => {
                    setEditKey(null);
                    setTeamsToAdd(teams);
                  }}>
                  <CrossIcon color="#171717" />
                </span>
                <span
                  className="flex justify-center items-center right-icon border radius-100 cursor"
                  onClick={() => onUpdateUserTeams()}>
                  <RightIcon color="#FFFFFF" />
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex-column row-gap-2">
            {teams.length > 0 ? (
              teams.map(teamsData => <RenderTeamsItem teamsData={teamsData} key={teamsData.name} />)
            ) : (
              <label className="inter-400-text font-14 line-height-20 natural-400-text">{t('NO_DATA')}</label>
            )}
          </div>
        )}
      </div>
    </TamsListWrapper>
  );
};

const TamsListWrapper = styled.div``;

export default UserTeams;
