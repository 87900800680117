import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as NoDocumentIcon } from '../../assets/icons/empty-document.svg';
import { addToast } from '../../store/features/toastSlice';
import { getUserRoles, setUserRoles } from '../../store/features/userManagementSlice';
import InfiniteScrollV2 from '../common/infinite-scroll-v2';
import NoData from '../common/no-data';
import SkeletonTransition from '../common/skeleton-transition';
import TableHeader from '../common/table-header';
import RolesListItem from './roles-list-item';

const rolesListTableHeaders = [
  { name: 'ROLE', key: 'ROLE' },
  { name: 'DESCRIPTION', key: 'DESCRIPTION' },
  { name: '', key: 'MENU' },
];

const RolesList = ({ debouncedSearch = '' }) => {
  const pageRef = useRef(0);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { userRoles } = useSelector(state => state.usersManagement);
  const [showLoading, setShowLoading] = useState(true);

  const fetchRolesList = (page, merge = false, debouncedSearch, showLoading = true) => {
    setShowLoading(showLoading);
    dispatch(
      getUserRoles({
        merge,
        params: {
          page: page,
          size: 15,
          search: debouncedSearch,
        },
      }),
    )
      .then(() => {
        pageRef.current = page;
      })
      .catch(() => dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_TEAM_LIST'), id: nanoid() })))
      .finally(() => {
        setShowLoading(false);
      });
  };

  const fetchMoreData = () => {
    fetchRolesList(pageRef.current + 1, true, debouncedSearch, false);
  };

  useEffect(() => {
    fetchRolesList(0, false, debouncedSearch);
  }, [debouncedSearch]);

  useEffect(() => {
    return () => {
      dispatch(setUserRoles({ data: [] }));
    };
  }, []);

  return (
    <RolesListWrapper className="flex-column flex-1 p-6 w-full radius-1 overflow-hidden">
      <SkeletonTransition
        loading={showLoading}
        loaderClassName="item flex items-center justify-center flex-1 mb-6"
        height={'100%'}
        containerClassName="line-height-1 h-full w-full">
        {userRoles?.length > 0 ? (
          <div className="w-full card radius-1_5 border overflow-scroll">
            <div className="flex-column w-fit-content min-w-full h-full">
              <TableHeader headers={rolesListTableHeaders} />
              <InfiniteScrollV2 hasMore={!userRoles} fetchMoreData={fetchMoreData}>
                {(userRoles || []).map((roles, index) => (
                  <RolesListItem roles={roles} key={index} mennuPortal={userRoles?.length < 3} />
                ))}
              </InfiniteScrollV2>
            </div>
          </div>
        ) : debouncedSearch ? (
          <NoData className="flex-1" title={t('NO_RESULTS_FOUND')} description={t('NO_RESULTS_DESCRIPTION')} />
        ) : (
          <div className="flex-column flex-1 items-center justify-center">
            <NoData
              title={t('NO_ROLES')}
              description={t('NO_RULES_HERE')}
              className="search-roles"
              EmptyIcon={NoDocumentIcon}
              iconClassName="relative"
            />
          </div>
        )}
      </SkeletonTransition>
    </RolesListWrapper>
  );
};

const RolesListWrapper = styled.div`
  border-radius: 6px;

  .header-container {
    height: 52px;
    background-color: ${({ theme }) => theme.natural_50};
  }

  .header-container,
  .data-container {
    display: grid;
    grid-template-columns: minmax(200px, 1fr) minmax(200px, 3fr) 80px;
    column-gap: 48px;
    padding: 16px 24px;
  }

  .search-roles {
    max-width: 400px;
  }
`;

export default RolesList;
