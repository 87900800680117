import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { ReactComponent as AlertIcon } from '../../assets/icons/AlertIcon.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/CrossIcons.svg';
import Button from '../../components/common/button/button';
import { OrganisationContext } from '../../context/organisationContext';
import { initModal } from '../../helpers/utils';
import { addToast } from '../../store/features/toastSlice';

const ActivityAction = () => {
  const { modal, setModal } = useContext(OrganisationContext);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { title, description, activity_Id } = modal.content;
  const { t } = useTranslation();

  const handleDone = () => {
    setLoading(true);
    setModal(initModal);

    dispatch(
      addToast({
        error: false,
        title: t('ACTIVITY_REMOVED'),
        text: t('SUCCESSFULLY_REMOVED_ACTIVITY'),
      }),
    );
  };
  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <ActivityActionWrapper>
        <div className="flex-column content-wrapper items-center relative">
          <AlertIcon />
          <CrossIcon className="absolute right-0 cursor" onClick={() => setModal(initModal)} />

          <div className="flex-column gap-2 items-center justify-start w-full">
            <label className="inter-500-text font-18 text-center">{title}</label>
            <p className="inter-400-text  text-center">{description}</p>
          </div>
        </div>
        <div className="action-wrapper flex-column justify-center w-full gap-3">
          <Button
            className={`remove-btn negative ${loading && 'disabled'}`}
            label={t('REMOVE')}
            disabled={loading}
            onClick={() => handleDone()}
            size="large"
            borderRadius="100px"
          />
          <Button
            className={`cancel-btn ${loading && 'disabled'}`}
            label={t('CANCEL')}
            disabled={loading}
            onClick={() => setModal(initModal)}
            size="large"
            borderRadius="100px"
          />
        </div>
      </ActivityActionWrapper>
    </CSSTransition>
  );
};

export const ActivityActionWrapper = styled.div`
  width: 384px;
  padding: 24px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  flex: 1 0 0;
  .content-wrapper {
    gap: 20px;
    label {
      color: #171717;
    }
    p {
      color: #737373;
    }
  }
  .action-wrapper {
    button {
      display: flex;
      padding: 12px 20px;
      justify-content: center;
      font-size: 16px;
      line-height: 24px;
      align-items: center;
      gap: 8px;
      align-self: stretch;
    }
    .remove-btn {
      background: #ef4444;
    }
    .cancel-btn {
      border: 1px solid #e5e5e5;
      background: #fff;
      color: #404040;
    }
  }
`;

export default ActivityAction;
