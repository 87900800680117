import styled from 'styled-components';

export const PersonsListWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.lightGrey};
  overflow: scroll;
`;

export const PersonsListItemWrapper = styled.div`
  display: grid;
  grid-template-columns:
    minmax(200px, 230px) minmax(180px, 1fr) minmax(180px, 240px) minmax(170px, 200px) minmax(200px, 200px)
    minmax(60px, 60px);
  min-height: 56px;
  max-height: 56px;
  border-bottom: 1px solid ${({ theme }) => theme.lightGrey};
  padding: 8px 16px;
  width: fit-content;

  &:hover {
    background: #f5f9fc80;
  }

  .icon-container {
    padding: 4px;
    border-radius: 6px;
    background: ${({ theme }) => theme.lightGreyBackground};
  }
`;
