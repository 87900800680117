import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import uuid from 'react-uuid';
import { ReactComponent as AlertIcon } from '../assets/icons/AlertIcon.svg';
import { ReactComponent as CrossIcon } from '../assets/icons/CrossIcons.svg';
import Button from '../components/common/button/button';
import { OrganisationContext } from '../context/organisationContext';
import { getFormattedDate, initModal } from '../helpers/utils';
import { cancelAutomationLogs } from '../store/features/automationsSlice';
import { AutomationLogCancel } from './modal.styled';

const AutomationCancel = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { modal, setModal } = useContext(OrganisationContext);
  const [loading, setLoading] = useState(false);
  const { title, description } = modal || {};
  const { automationLog } = modal.content || {};

  const handleConfirm = () => {
    try {
      setLoading(true);
      dispatch(cancelAutomationLogs({ automationLogId: automationLog?.automation?.id })).then(resposne => {
        setModal(initModal);
        setLoading(false);
      });
    } catch (error) {
      dispatch(
        addToast({
          error: true,
          text: t('SOMETHING_WENT_WRONG'),
          id: uuid(),
        }),
      );
      setLoading(false);
    }
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <AutomationLogCancel className="flex-column items-center p-6 gap-6">
        <div className="flex-column gap-5 content-wrapper items-center relative w-full">
          <AlertIcon />
          <CrossIcon className="absolute right-0 cursor" onClick={() => setModal(initModal)} />
          <div className="flex-column gap-2 items-center justify-start w-full">
            <p className="font-18 text-center inter-500-text natural-900-text">{title}</p>
            <span className="inter-400-text font-14 natural-400-text text-center">{description}</span>
          </div>
        </div>
        <div className="radius-1_5 border py-4 px-3 flex-column w-full">
          <div className="inter-600-text font-16 natural-900-text">{automationLog?.automation?.name}</div>
          <div className="flex items-center pt-4">
            <div className="font-14 inter-400-text natural-900-text">ID:</div>
            <div className="font-14 inter-400-text natural-500-text ml-1">
              {' '}
              {automationLog && automationLog?.automation?.id}
            </div>
          </div>
          <div className="flex pt-4">
            <div className="font-14 inter-400-text natural-900-tex">{t('DATE')}:</div>
            <div className="font-14 inter-400-text natural-500-text ml-1">
              {getFormattedDate(automationLog && automationLog?.created_on, 'DD/MM/YY - hh:mm a')}
            </div>
          </div>
        </div>
        <div className="flex-column justify-center w-full gap-3">
          <Button
            className={classNames('negative delete-btn', loading && 'disabled')}
            disabled={loading}
            label={t('CANCEL')}
            onClick={() => handleConfirm()}
            size="large"
            borderRadius="100px"
          />
          <Button
            className={classNames('cancel-btn default', loading && 'disabled')}
            label={t('KEEP')}
            onClick={() => setModal(initModal)}
            size="large"
            borderRadius="100px"
            bgColor={'#ffff'}
            borderColor={'#2496FF'}
          />
        </div>
      </AutomationLogCancel>
    </CSSTransition>
  );
};

export default AutomationCancel;
