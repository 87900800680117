import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { ReactComponent as EmptyIcon } from '../../assets/icons/empty-document.svg';
import { ReactComponent as Clock } from '../../assets/icons/property/clock.svg';
import { ReactComponent as Weithers } from '../../assets/icons/property/weithers.svg';
import { Avatar } from '../common/avatar-group';
import NoData from '../common/no-data';

const ActivitiesList = props => {
  const { t } = useTranslation();
  const { loading = false, arrows = false, accordionContent = [] } = props;

  return (
    <ActivityWrapper className="flex-column flex-1 radius-1 overflow-hidden">
      {loading ? (
        <div className="item flex items-center justify-center flex-1 mb-6">
          <Skeleton height={'270px'} containerClassName="line-height-1 h-full w-full" />
        </div>
      ) : accordionContent?.length > 0 ? (
        <div className="w-full  overflow-scroll">
          {accordionContent.map((listResource, index) => {
            const startTimeMoment = moment.unix(
              listResource?.actual_date?.start_date || listResource?.expected_date?.start_date,
            );
            return (
              listResource.user && (
                <div key={index} className="flex items-center justify-between pb-4">
                  <div className="flex items-center gap-2">
                    <Avatar variant="medium" avatar={listResource.user} backgroundColorIndex={index} />
                    <p className="text-inter-500 font-14 natural-900-text line-height-20">{listResource.user.name}</p>
                  </div>
                  <div className="flex items-center gap-3">
                    <div className="flex items-center gap-1">
                      <Weithers width={14} height={14} className="natural-400-text" />
                      <p className="text-inter-400 font-14 natural-900-text line-height-20">
                        {listResource.service.name}
                      </p>
                    </div>
                    <div className="justify-center">
                      <div className="bg-natural-200" style={{ width: '1px', height: '18px' }}></div>
                    </div>
                    <div className="flex items-center gap-1">
                      <Clock width={14} height={14} />

                      <label className="text-inter-400 font-14 natural-900-text line-height-20">{`${startTimeMoment?.format(
                        'HH:mm',
                      )} - ${startTimeMoment
                        ?.clone()
                        .add(listResource?.actual_date?.duration || listResource?.expected_date?.duration, 'minutes')
                        .format('HH:mm')}`}</label>
                    </div>
                  </div>
                </div>
              )
            );
          })}
        </div>
      ) : (
        <NoData
          title={t('NO_TASK')}
          description={t('NO_TASK_HERE')}
          className="mt-12"
          EmptyIcon={EmptyIcon}
          iconClassName="mb-6 relative"
        />
      )}
    </ActivityWrapper>
  );
};

const ActivityWrapper = styled.div`
  .activity-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }
`;

export default ActivitiesList;
