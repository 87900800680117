import React, { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
// import { downloadPdf } from '@helpers/utils';
import CloseIcon from '@assets/images/close-image.svg';
import ArrowKeysReact from 'arrow-keys-react';
import { useTranslation } from 'react-i18next';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import styled from 'styled-components';
import DownloadIcon from '../../assets/images/download-pdf.svg';
import Loading from '../../assets/images/loading.svg';
import ZoomIn from '../../assets/images/zoom-in.svg';
import ZoomOut from '../../assets/images/zoom-out.svg';

const ViewFile = ({ file, remove, close, showRemove = true, selectedDoc, downloadPdf }) => {
  const { t } = useTranslation();
  const fileMainRefs = useRef({});
  const fileLeftRefs = useRef({});

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.0);
  const [loading, setLoading] = useState({ left: true, main: true });
  const [pageChangeFromLeft, setPageChangeFromLeft] = useState(false);

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  const onDocumentLoadSuccess = ({ numPages }, side) => {
    setNumPages(numPages);
    setLoading(loading => ({ ...loading, [side]: false }));
  };

  const onRemove = () => {
    close();
    remove(selectedDoc);
  };

  const onClick = e => {
    e.preventDefault();
    if (e.target === e.currentTarget) {
      close();
    }
  };

  useEffect(() => {
    const keyEnter = event => {
      if (event.key === 'Escape') {
        event.preventDefault();
        close();
      }
    };
    document.addEventListener('keydown', keyEnter);

    return () => {
      document.removeEventListener('keydown', keyEnter);
    };
  }, []);

  const goToPreviousPage = () => {
    const updatedPageNumber = pageNumber > 1 ? pageNumber - 1 : pageNumber;
    setPageNumber(updatedPageNumber);
    setPageChangeFromLeft(true);
    scrollMainFile(updatedPageNumber - 1);
  };

  const goToNextPage = () => {
    const updatedPageNumber = pageNumber < numPages ? pageNumber + 1 : pageNumber;
    setPageNumber(updatedPageNumber);
    setPageChangeFromLeft(true);
    scrollMainFile(updatedPageNumber - 1);
  };

  ArrowKeysReact.config({
    left: () => {
      goToPreviousPage();
    },
    right: () => {
      goToNextPage();
    },
  });

  const scrollMainFile = page => {
    const targetEle = fileMainRefs.current[page] || null;
    if (targetEle) {
      const elementMain = document.getElementsByClassName('pdf-container-main')[0];
      elementMain.scrollTo({
        behavior: 'smooth',
        top: targetEle.offsetTop - elementMain.offsetTop,
      });
    }
  };

  const scrollLeftFile = page => {
    const targetEle = fileLeftRefs.current[page - 1] || null;
    if (targetEle) {
      const pos = targetEle.style.position;
      const top = targetEle.style.top;
      targetEle.style.position = 'relative';
      targetEle.style.top = '-20px';
      fileLeftRefs.current[page - 1].scrollIntoView({ behavior: 'smooth', block: 'start' });
      targetEle.style.top = top;
      targetEle.style.position = pos;
    }
  };

  const onPageChangeFromLeft = page => {
    setPageNumber(page + 1);
    setPageChangeFromLeft(true);
    scrollMainFile(page);
  };

  useEffect(() => {
    document.getElementById('view-file-id').focus();
  }, []);

  const updatePageNumber = (page, pageChangeFromLeft) => {
    if (!pageChangeFromLeft) {
      setPageNumber(page);
      scrollLeftFile(page);
    } else {
      page === pageNumber && setPageChangeFromLeft(false);
    }
  };

  const handleScroll = (e, pageChangeFromLeft) => {
    const elementMain = document.getElementsByClassName('pdf-container-main')[0];
    const { scrollTop, scrollHeight, clientHeight } = elementMain;
    const scrollPercentage = scrollTop / (scrollHeight - clientHeight);
    const scrollPercentageOfPages = parseInt(scrollPercentage * numPages);
    updatePageNumber(scrollPercentageOfPages + 1, pageChangeFromLeft);
  };

  return (
    <ViewFileModalWrapper id="view-file-id" onClick={onClick} {...ArrowKeysReact.events} tabIndex="1">
      {(loading.left || loading.main) && (
        <div className="pdf-loading">
          <img alt="loading" height="50px" src={Loading} />
          <label>{t('LOADING_DOCUMENT')}</label>
        </div>
      )}
      <div className="view-file-content" style={{ display: loading.left || loading.main ? 'none' : 'flex' }}>
        <img alt="icon" className="close-btn cursor" onClick={() => close()} src={CloseIcon} />
        {file.name && (
          <div className="mb-8 w-full flex items-center justify-center">
            <label className="bold-text font-24">{file.name}</label>
          </div>
        )}
        <div className="flex pdf-view-container">
          <div className="pdf-left-container">
            <Document
              className="pdf-document-left"
              file={selectedDoc.media?.url}
              loading={
                <div className="pdf-loading">
                  <img alt="loading" height="50px" src={Loading} />
                  <label>{t('LOADING_DOCUMENT')}</label>
                </div>
              }
              onLoadError={e => {
                setLoading(loading => ({ ...loading, left: false }));
                console.log(e);
              }}
              onLoadSuccess={pdf => onDocumentLoadSuccess(pdf, 'left')}>
              {Array.from(new Array(numPages), (el, index) => (
                <div
                  key={`page_${index + 1}`}
                  ref={element => (fileLeftRefs.current[index] = element)}
                  className={`document-left-page ${pageNumber === index + 1 && 'selected-page'}`}>
                  <Page
                    className={`pdf-page`}
                    loading={
                      <div className="pdf-loading">
                        <img alt="loading" height="50px" src={Loading} />
                        <label>{t('LOADING_PAGE')}</label>
                      </div>
                    }
                    onClick={() => onPageChangeFromLeft(index)}
                    pageNumber={index + 1}
                    scale={1.0}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                  />
                  <p className="regular-text">{index + 1}</p>
                </div>
              ))}
            </Document>
          </div>
          <div className="flex-column h-full flex-1">
            <div className="flex pdf-action-container">
              <div className="flex items-center justify-center">
                <label className="medium-text font-16">
                  {t('PAGE')} {pageNumber}
                </label>
              </div>
              <div className="flex">
                <div className="pdf-scale-container flex mr-4">
                  <div className="pdf-scale items-center justify-between">
                    <div className="pdf-action cursor">
                      <img onClick={() => setScale(scale - 0.1)} src={ZoomOut} />
                    </div>
                    <label className="medium-text">{Math.round(scale * 100)}%</label>
                    <div className="pdf-action cursor">
                      <img onClick={() => setScale(scale + 0.1)} src={ZoomIn} />
                    </div>
                  </div>
                </div>
                <div className="pdf-actions ml-4">
                  {/* {showRemove && (
                    <div
                      className="pdf-action cursor mr-3"
                      onClick={() => checkPermission(() => onRemove(), 'EVIDENCE_MANAGE')}
                      style={{ opacity: hasPermission('EVIDENCE_MANAGE') ? 1 : 0.5 }}>
                      <img alt="remove" src={RemoveIcon} />
                    </div>
                  )} */}
                  <div className="pdf-action cursor">
                    <img alt="download" onClick={() => downloadPdf(file)} src={DownloadIcon} />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="pdf-container-main"
              id="pdf-container-main-id"
              onScroll={e => handleScroll(e, pageChangeFromLeft)}>
              <Document
                className="pdf-document-main"
                file={selectedDoc.media?.url}
                loading={
                  <div className="pdf-loading">
                    <img alt="loading" height="50px" src={Loading} />
                    <label>{t('LOADING_DOCUMENT')}</label>
                  </div>
                }
                onLoadError={e => {
                  setLoading(loading => ({ ...loading, main: false }));
                  console.log(e);
                }}
                onLoadSuccess={pdf => onDocumentLoadSuccess(pdf, 'main')}>
                {Array.from(new Array(numPages), (el, index) => (
                  <div
                    key={`pageNumber_${index + 1}`}
                    ref={element => (fileMainRefs.current[index] = element)}
                    data-page={index}
                    id={`pageNumber_${index + 1}`}>
                    <Page
                      className="pdf-page"
                      loading={
                        <div className="pdf-loading">
                          <img alt="loading" height="50px" src={Loading} />
                          <label>{t('LOADING_PAGE')}</label>
                        </div>
                      }
                      pageNumber={index + 1}
                      scale={scale}
                    />
                  </div>
                ))}
              </Document>
            </div>
          </div>
        </div>
      </div>
    </ViewFileModalWrapper>
  );
};

const ViewFileModalWrapper = styled.div`
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(4, 32, 48, 0.8);
  top: 0;
  left: 0;
  user-select: none;
  outline: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .close-btn {
    position: absolute;
    right: 30px;
    top: 20px;
    cursor: pointer;
  }

  .pdf-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    label {
      font-family: Roboto bold;
      font-size: 16px;
      color: #ffffff;
      margin-top: 24px;
    }
  }

  .view-file-content {
    height: 92vh;
    width: 92vw;
    background-color: #ffffff;
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 48px;
    border-radius: 16px;

    .pdf-view-container {
      overflow-y: scroll;
      border-radius: 8px;
      column-gap: 24px;
      width: 100%;
    }

    .pdf-actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      right: 16px;
      top: 24px;
      z-index: 2;
    }

    .pdf-left-container {
      overflow-y: scroll;
      border-radius: 8px;
      background: #f4f7f9;
      height: 100%;
      min-width: 185px;

      .pdf-document-left {
        background: #f4f7f9;
        padding: 16px;

        .document-left-page {
          margin-bottom: 24px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        .pdf-page {
          height: auto;
          width: 151px;
          max-width: 151px;
          min-width: 151px;
          max-height: 220px;
          margin-bottom: 8px;

          canvas {
            height: auto !important;
            max-width: 151px !important;
            max-height: 220px !important;
            // width: 151px !important;
            border: 1px solid #d2e5ed;
            border-radius: 4px;
            object-fit: contain;
          }
          .react-pdf__Page__textContent {
            height: auto !important;
            width: 151px !important;
            max-width: 151px !important;
            max-height: 220px !important;
          }
        }

        .selected-page {
          canvas {
            border: 2px solid #40f1de;
            border-radius: 4px;
          }
        }
      }
    }

    .pdf-container-main {
      flex: 1 1;
      overflow-y: scroll;
      border: 1px solid #d2e5ed;
      border-radius: 8px;

      .pdf-document-main {
        max-width: calc(92vw - 96px - 185px - 28px);
        overflow: scroll;

        .pdf-page {
          margin: 8px 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .pdf-action-container {
      background: #f4f7f9;
      border-radius: 8px;
      justify-content: space-between;
      padding: 4px 12px;
      margin-bottom: 16px;

      .pdf-scale-container {
        align-items: center;
        justify-content: center;

        .pdf-scale {
          border-radius: 100px;
          width: 110px;
          height: 32px;

          label {
            font-size: 16px;
          }
        }
      }

      .pdf-action {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        background: rgba(5, 49, 73, 0.3);
        border-radius: 6px;
      }
    }
  }

  .loading-document {
  }
`;

export default ViewFile;
