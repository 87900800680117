import React, { useEffect } from 'react';
import styled from 'styled-components';

const SolarApiDemo = () => {
  const firstTimeRef = React.useRef(true);

  useEffect(() => {
    if (!firstTimeRef.current) {
      return;
    }
    firstTimeRef.current = false;
    const script = document.createElement('script');
    const solarDemoScript = document.createElement('script');

    function initMap() {
      solarDemoScript.src =
        'https://nora-soderlund.github.io/google-maps-solar-api-panels-demo/public/scripts/client.js';
      solarDemoScript.type = 'module';
      solarDemoScript.defer = '';
      solarDemoScript.id = 'solar-demo-script';

      document.body.appendChild(solarDemoScript);

      setTimeout(() => {
        const element = document.getElementById('solar-panels-container');

        const solarPanelsMap = new window.SolarPanelsMap(process.env.REACT_APP_GOOGLE_API_KEY, element);
      }, 1000);
    }
    window.initMap = initMap;
    script.async = true;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&loading=async&callback=initMap`;

    document.body.appendChild(script);

    return () => {
      const solarDemoScriptElement = document.getElementById('solar-demo-script');
      document.body.removeChild(script);
      solarDemoScriptElement && document.body.removeChild(solarDemoScriptElement);
    };
  }, []);

  return (
    <SolarApiDemoWrapper style={{ height: '100dvh' }}>
      <div id="solar-panels-container" style={{ height: `100%` }} />
    </SolarApiDemoWrapper>
  );
};

const SolarApiDemoWrapper = styled.div`
  .solar-panels {
    height: 100%;
    width: 100%;

    margin: auto;
  }

  .solar-panels {
    position: relative;
  }

  .solar-panels-map {
    height: 100%;
  }

  .solar-panels-form {
    position: absolute;

    left: 0;
    top: 0;

    width: 100%;
    height: 100%;

    background: rgba(0, 0, 0, 0.5);

    display: none;
  }

  .solar-panels-form.active {
    display: flex;
  }

  .solar-panels-form-input {
    margin: auto;

    padding: 5px;

    min-width: 50%;

    height: auto;

    border-radius: 5px;

    border: none;

    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
  }

  .solar-panels-form-input:focus {
    outline: none;
  }

  .solar-panels-form-label {
    color: #fff;

    margin: auto;

    font-weight: normal;
  }

  .solar-panels-panel {
    background: #fff;

    margin: 10px 10px 0;
    padding: 10px;

    border-radius: 3px;

    width: 200px;
  }

  .solar-panels-panel-slider {
    width: 100%;
  }

  .solar-panels-panel {
    display: grid;
    grid-auto-flow: column;
    gap: 10px;
  }

  .solar-panels-panel-container {
    display: flex;

    flex-direction: column;

    align-items: center;

    gap: 5px;

    flex: 1 1 0%;
  }

  .solar-panels-panel-title {
    margin: 0 auto;

    font-weight: normal;
  }

  .solar-panels-panel-conic {
    width: 68px;
    height: 68px;

    border-radius: 50%;

    display: flex;

    align-items: center;
    justify-content: center;

    transform: rotate(-135deg);

    background: conic-gradient(rgb(232, 234, 237) 75%, transparent 0deg);
  }

  .solar-panels-panel-conic::before {
    content: '';

    position: absolute;

    inset: 0px;

    width: 68px;
    height: 68px;

    border-radius: 50%;

    background: conic-gradient(currentColor var(--progress), transparent 0);
  }

  .solar-panels-panel-conic-inner {
    display: flex;

    align-items: center;
    justify-content: center;

    border-radius: 50%;

    background: white;

    font-weight: bold;

    text-align: center;

    transform: rotate(135deg);

    width: 60px;
    height: 60px;
  }
`;

export default SolarApiDemo;
