import { createSlice } from '@reduxjs/toolkit';

export const navbarSlice = createSlice({
  name: 'navbar',
  initialState: {
    breadcrumbs: [],
  },
  reducers: {
    setBreadcrumbs: (state, { payload }) => {
      state.breadcrumbs = payload;
    },
  },
});

export const addBreadcrumbs = data => dispatch => {
  dispatch(setBreadcrumbs(data));
};

export const removeBreadcrumbs = () => dispatch => {
  dispatch(setBreadcrumbs([]));
};

export const { setBreadcrumbs } = navbarSlice.actions;
export default navbarSlice.reducer;
