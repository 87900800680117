import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import PageHeader from '../../../components/page-header';
import UserList from '../../../components/users-list';
import { OrganisationContext } from '../../../context/organisationContext';
import useDebounce from '../../../helpers/useDebounceHook';
import { addToast } from '../../../store/features/toastSlice';
import { getUsers } from '../../../store/features/userManagementSlice';
import UsersWrapper from '../../../styles/pages/users.styled';

function Users() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const pageRef = useRef(null);
  const { users } = useSelector(state => state.usersManagement);
  const { checkPermission, setModal } = useContext(OrganisationContext);

  const [search, setSearchText] = useState('');
  const [selectedPage, setSelectedPage] = useState(0);
  const debouncedSearch = useDebounce(search, 500);
  const [loading, setLoading] = useState(false);

  const fetchUsersList = (page, merge, debouncedSearch, showLoading = true) => {
    pageRef.current = page;
    if (showLoading && !merge) {
      setLoading(true);
    }
    dispatch(
      getUsers({
        merge,
        params: {
          page: page,
          size: 15,
          search: debouncedSearch,
        },
      }),
    )
      .catch(() => {
        dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_USERS') }));
      })
      .finally(() => setLoading(false));
  };

  const fetchMoreData = () => {
    fetchUsersList(pageRef.current + 1, true, debouncedSearch, false);
  };

  useEffect(() => {
    fetchUsersList(0, false, debouncedSearch);
  }, [debouncedSearch]);

  const handleInviteUser = () => {
    setModal({
      type: 'invite-user',
      content: {
        onSuccess: () => fetchUsersList(0, false, debouncedSearch, true),
      },
    });
  };

  return (
    <UsersWrapper className="flex-column flex-1">
      <Routes>
        <Route
          index
          element={
            <Fragment>
              <PageHeader
                names={[
                  { text: t('SETTINGS'), onClick: () => navigate('/settings') },
                  { text: t('SECURITY'), onClick: () => navigate('/settings') },
                  { text: t('USERS'), onClick: () => {} },
                ]}
                showInviteBtn
                inviteBtnLabel={t('INVITE_USER')}
                inviteBtnPermission="USERS_MANAGE"
                onInviteClick={() => checkPermission(() => handleInviteUser(), 'USERS_MANAGE')}
                showSearch
                showPagination={false}
                searchText={search}
                onSearchChange={setSearchText}
                pagination={{
                  selectedPage: selectedPage,
                  totalPages: users?.total_pages || 0,
                  setSelectedPage: setSelectedPage,
                }}
              />
              <UserList loading={loading} fetchMoreData={fetchMoreData} />
            </Fragment>
          }
        />
      </Routes>
    </UsersWrapper>
  );
}

export default Users;
