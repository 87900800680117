import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
// import { ReactComponent as EditIcon } from '../../../../assets/icons/property/edit.svg';
import { EditTeamMemberField } from './team-name-edit_details';

const TeamDetailsBody = () => {
  const { t } = useTranslation();

  const { teamDetails } = useSelector(state => state.teamManagement);

  const [editField, setEditField] = useState({});
  const [error, setError] = useState({});

  const onCancelUpdateData = () => {
    setEditField({});
    setError({});
  };

  return (
    <TeamDetailsLeftWrapper className="flex-column">
      <div className="px-3 py-6 flex-column overflow-scroll flex-1 ">
        <div className="flex-column">
          {editField.type === 'NAME' ? (
            <div className="update-detail-contaner relative">
              <EditTeamMemberField
                data={editField}
                inputType={editField.type === 'NAME'}
                value={editField.value}
                onCancelUpdateData={onCancelUpdateData}
                onUpdateData={() => {}}
                setEditField={setEditField}
                setError={setError}
                error={error}
              />
            </div>
          ) : (
            // remove false condition on click
            <div
              className="flex items-center justify-between w-full cursor hover-edit relative"
              onClick={() => false && setEditField({ type: 'NAME', value: teamDetails?.name })}>
              <div className="flex-column w-full">
                <span className="inter-400-text grey-text-04 font-12 mb-1">{t('NAME')}</span>
                <div className="flex items-center ">
                  <label className="font-14 inter-400-text color-neutral-900 one-line flex-1 hover-margin regular-text">
                    {teamDetails?.name}
                  </label>
                </div>
              </div>
              {/* <EditIcon width={14} height={14} className="edit-icon" color="#2496FF"/> */}
            </div>
          )}
        </div>
        <div className="flex-column mt-4">
          {editField.type === 'DESCRIPTION' ? (
            <div className="update-detail-contaner relative">
              <EditTeamMemberField
                data={editField}
                textAreaType={editField.type === 'DESCRIPTION'}
                onCancelUpdateData={onCancelUpdateData}
                value={editField.value}
                onUpdateData={() => {}}
                setEditField={setEditField}
                setError={setError}
                error={error}
              />
            </div>
          ) : (
            // add hover-edit class for edit & remove false condition on click
            <div
              className="flex items-center justify-between w-full cursor hover-edit relative"
              onClick={() => false && setEditField({ type: 'DESCRIPTION', value: teamDetails?.description })}>
              <div className="flex-column w-full">
                <span className="inter-400-text grey-text-04 font-12 mb-1">{t('DESCRIPTION')}</span>
                <div className="flex items-center">
                  <label className="font-14 inter-400-text color-neutral-900 one-line flex-1 hover-margin regular-text">
                    {teamDetails?.description}
                  </label>
                </div>
              </div>
              {/* <EditIcon width={14} height={14} className="edit-icon" color="#2496FF"/> */}
            </div>
          )}
        </div>
      </div>
    </TeamDetailsLeftWrapper>
  );
};

const TeamDetailsLeftWrapper = styled.div`
  overflow: hidden;

  .update-detail-contaner {
    display: flex;
    padding: 12px;
    gap: 32px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 6px;
    background: var(--generic-white, #fff);
    box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1), 0px 4px 6px -4px rgba(16, 24, 40, 0.1);
    .input {
      height: 40px;
    }
  }

  .hover-edit {
    padding: 8px 12px;
    .edit-icon {
      display: none;
      width: 32px;
      height: 32px;
      padding: 0 9px;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      background: var(--primary-50, #eff6ff);
    }

    /* &:hover {
      box-shadow: 0px 4px 10px rgba(9, 19, 53, 0.1);
      padding: 8px 12px;
      border-radius: 8px;
      .edit-icon {
        display: flex;
      }
    } */
  }
`;

export default TeamDetailsBody;
