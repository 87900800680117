import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  getPropertyContacts,
  getPropertyDetails,
  getPropertyEpcs,
  setPropertyContacts,
  setPropertyDetails,
  setPropertyEpcs,
  setRoofInformation,
} from '../../store/features/propertySlice';
import { addToast } from '../../store/features/toastSlice';
import ContactDetails from '../property-details-components/contact-details';
import MainPropertyDetails from '../property-details-components/main-property-details';
import PropertyDetails from '../property-details-components/property-details';
import PropertyDetailsMap from '../property-details-components/property-details-map';

const PropertyDetailsContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { property_id } = useParams();

  const [propertyDetailsLoading, setPropertyDetailsLoading] = useState(true);
  const [propertyContactsLoading, setPropertyContactsLoading] = useState(true);
  const [propertyEpcsLoading, setPropertyEpcsLoading] = useState(true);
  const [roofInfoLoading, setRoofInfoLoading] = useState(false);
  const [showMapScreen, setShowmapScreen] = useState(false);

  const fetchPropertyDetails = async property_id => {
    setPropertyDetailsLoading(true);
    await dispatch(getPropertyDetails({ property_id: property_id }))
      .then(() => setPropertyDetailsLoading(false))
      .catch(() => dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_PROPERTY_DETAILS') })));
  };

  const fetchPropertyContacts = async property_id => {
    setPropertyContactsLoading(true);
    await dispatch(getPropertyContacts({ property_id: property_id }))
      .then(() => setPropertyContactsLoading(false))
      .catch(() => dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_PROPERTY_CONTACTS') })));
  };

  const fetchPropertyEpcs = async property_id => {
    setPropertyEpcsLoading(true);
    await dispatch(getPropertyEpcs({ property_id: property_id }))
      .then(() => setPropertyEpcsLoading(false))
      .catch(() => dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_PROPERTY_EPCS') })));
  };

  useEffect(() => {
    fetchPropertyDetails(property_id);
    fetchPropertyContacts(property_id);
    fetchPropertyEpcs(property_id);
    return () => {
      dispatch(setPropertyDetails({}));
      dispatch(setPropertyContacts([]));
      dispatch(setPropertyEpcs([]));
      dispatch(setRoofInformation([]));
    };
  }, [property_id]);

  return (
    <PropertyDetailsContainerWrapper className="flex-column flex-1 overflow-hidden">
      {showMapScreen ? (
        <PropertyDetailsMap setShowmapScreen={setShowmapScreen} />
      ) : (
        <div className="property flex-1 col-gap-6 overflow-hidden">
          <div className="flex-column row-gap-6 mb-4 flex-1 overflow-scroll">
            <Fragment>
              <PropertyDetails loading={propertyDetailsLoading} />
              <ContactDetails loading={propertyContactsLoading} />
            </Fragment>
          </div>
          <div className="flex-column row-gap-6 mb-4 flex-1 overflow-scroll">
            <MainPropertyDetails
              loading={propertyDetailsLoading || propertyEpcsLoading || roofInfoLoading}
              isShowNotes={false}
              isShowFiles={false}
              isShort={false}
              setShowmapScreen={setShowmapScreen}
            />
          </div>
        </div>
      )}
    </PropertyDetailsContainerWrapper>
  );
};

const PropertyDetailsContainerWrapper = styled.div`
  transition: all 100ms ease-in;

  .property {
    display: grid;
    grid-template-columns: minmax(313px, 20%) 1fr;
  }
`;

export default PropertyDetailsContainer;
