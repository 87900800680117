import { useGoogleMap } from '@react-google-maps/api';
import React, { useState } from 'react';
import styled from 'styled-components';
const WebGLOverlay = () => {
  const map = useGoogleMap();
  const [directionValues, setDirectionValues] = useState({ compass: 0, tilt: 0 });

  const webGLOverlay = new google.maps.WebGLOverlayView();
  webGLOverlay.onAdd = () => {
    // Add your WebGL initialization code here
    // console.log('WebGL overlay added');
  };
  webGLOverlay.onContextRestored = ({ gl }) => {
    // Setup WebGL context here
    // console.log('WebGL onContextRestored');
  };
  webGLOverlay.onDraw = ({ gl, transformer }) => {
    // Add your WebGL drawing code here
    // console.log('WebGL onDraw');
  };
  webGLOverlay.setMap(map);

  return (
    <ActionWrapper
      top={'none'}
      bottom={'24px'}
      right={'60px'}
      as={'div'}
      padding={'8px'}
      className="flex-column bg-white radius-2 border-0 flex items-center justify-center">
      <div className="flex items-center grid-layout">
        <label className="inter-500-text">Compass: {directionValues.compass}</label>
        <input
          type="range"
          min="0"
          max="360"
          step="1"
          value={directionValues.compass}
          className="compass-range"
          onChange={({ target: { value } }) => {
            map.setHeading(parseFloat(value));
            setDirectionValues({ ...directionValues, compass: value });
          }}
        />
      </div>
      <div className="flex items-center grid-layout">
        <label className="inter-500-text">Tilt: {directionValues.tilt}</label>
        <input
          type="range"
          min="0"
          max="67"
          step="1"
          value={directionValues.tilt}
          className="tilt-range"
          onChange={({ target: { value } }) => {
            map.setTilt(parseFloat(value));
            setDirectionValues({ ...directionValues, tilt: value });
          }}
        />
      </div>
    </ActionWrapper>
  );
};

const ActionWrapper = styled.div`
  position: absolute;
  right: ${({ right }) => right || '8px'};
  padding: ${({ padding }) => padding || '0px'};
  top: ${({ top }) => top || '8px'};
  bottom: ${({ bottom }) => bottom || 'none'};
  left: ${({ left }) => left || 'none'};

  .grid-layout {
    display: grid;
    grid-template-columns: 100px 1fr;
  }
`;

export default WebGLOverlay;
