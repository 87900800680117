import { useChannel } from 'ably/react';
import { useEffect, useRef, useState } from 'react';
import { isJson } from '../helpers/utils';

export const useAblyMessage = ({ channel_name }) => {
  const [message, setMessage] = useState({ data: {} });
  const timeoutRef = useRef(null);

  const { channel } = useChannel({ channelName: channel_name }, message => {
    clearTimeout(timeoutRef.current);
    const { data } = message;
    setMessage({ ...message, data: isJson(data) ? JSON.parse(data) : data });

    timeoutRef.current = setTimeout(() => {
      setMessage({ data: {} });
    }, 1000);
  });

  useEffect(() => {
    return () => {
      channel.unsubscribe();
    };
  }, [channel]);

  return message;
};
