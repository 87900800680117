import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { Fragment, memo, useCallback, useMemo, useRef, useState } from 'react';
const libraries = ['places', 'geometry', 'drawing'];

const options = {
  streetViewControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
  mapTypeId: 'satellite',
};

const defaultLatLng = {
  lat: 50.935174,
  lng: -1.411288,
};

const PropertyMap = ({
  coordinates = null,
  wrapperClassName,
  isRoofEditV2 = false,
  additionalOptions = {},
  onClick = () => {},
  children,
}) => {
  const center = useMemo(() => {
    return coordinates
      ? {
          lat: parseFloat(coordinates?.lat),
          lng: parseFloat(coordinates?.lon),
        }
      : defaultLatLng;
  }, [coordinates]);

  const centerRef = useRef(center);

  const { isLoaded } = useJsApiLoader({
    libraries: libraries,
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    // mapIds: ['1ccafa48855564be'],
  });

  const [map, setMap] = useState(null);

  const onLoad = useCallback(map => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(map => {
    setMap(null);
  }, []);

  return (
    <motion.div className={classNames('h-full w-full', wrapperClassName)}>
      {isLoaded ? (
        <Fragment>
          <GoogleMap
            center={centerRef.current}
            id="map-google"
            mapContainerStyle={{ width: '100%', height: '100%', borderRadius: '6px' }}
            options={{ ...options, ...additionalOptions, mapId: '1ccafa48855564be' }}
            zoom={20}
            onLoad={onLoad}
            onUnmount={onUnmount}>
            {children}
          </GoogleMap>
        </Fragment>
      ) : (
        <div />
      )}
    </motion.div>
  );
};

export default memo(PropertyMap);
