import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import uuid from 'react-uuid';
import styled from 'styled-components';
import { ReactComponent as AlertIcon } from '../assets/icons/AlertIcon.svg';
import { ReactComponent as AlertInfoIcon } from '../assets/icons/AlertInfoIcon.svg';
import { ReactComponent as CrossIcon } from '../assets/icons/CrossIcons.svg';
import Button from '../components/common/button/button';
import { OrganisationContext } from '../context/organisationContext';
import { getItemFromLocalStorage } from '../helpers/localstorage';
import { initModal } from '../helpers/utils';
import { deleteUserRoles } from '../store/features/roleUserSlice';
import { addToast } from '../store/features/toastSlice';

const RoleActions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { modal, setModal } = useContext(OrganisationContext);
  const { roles, isDelete, title, description } = modal.content;

  const [loading, setLoading] = useState(false);

  const onConfirmAction = async () => {
    const userLocal = getItemFromLocalStorage('user');
    const organization_id = userLocal?.organizations[0]?.id;
    setLoading(true);
    try {
      if (isDelete) {
        dispatch(deleteUserRoles({ ...roles, organization_id: organization_id }))
          .then(() => {
            dispatch(
              addToast({
                error: false,
                title: t('ROLE_DELETE'),
                text: t('SUCCESSFULLY_DELETED_ROLE', { value: roles?.name }),
                id: uuid(),
              }),
            );
          })
          .catch(error => {
            dispatch(
              addToast({
                error: true,
                text: t('ERROR_WHILE_DELETING', { value: 'role' }),
                id: uuid(),
              }),
            );
          });
        setLoading(false);
        setModal(initModal);
      }
    } catch (error) {
      setLoading(false);
      dispatch(
        addToast({
          error: true,
          text: `Failed to ${isDelete ? 'delete' : 'disable'} Role`,
          id: uuid(),
        }),
      );
    }
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <RoleActionsWrapper className="flex-column items-center pxy-6 gap-8">
        <div className="flex-column gap-5 content-wrapper items-center relative">
          {isDelete ? <AlertIcon /> : <AlertInfoIcon />}
          <CrossIcon className="absolute right-0 cursor" width={20} height={20} onClick={() => setModal(initModal)} />
          <div className="flex-column gap-2 items-center justify-start w-full">
            <p className="font-18 text-center inter-500-text natural-900-text">{title}</p>
            <span className="inter-400-text font-14 natural-500-text text-center">{description}</span>
          </div>
        </div>

        <div className="px-3 py-4 flex-column gap-3 w-full radius-1_5 border white-background team-details-container">
          <div className="flex-column text-start">
            <div className="flex items-center justify-between w-full">
              <label className="font-16 inter-600-text natural-900-text">{roles.name}</label>
            </div>
            <label className="inter-400-text font-14 natural-500-text mt-2">{roles?.description}</label>
          </div>
        </div>

        <div className="action-wrapper flex-column justify-center w-full gap-3">
          <Button
            className={`primary ${loading && 'disabled'} ${isDelete ? 'delete-btn' : ''}`}
            disabled={loading}
            label={`${isDelete ? t('DELETE') : t('CONFIRM')}`}
            onClick={onConfirmAction}
            primary={true}
            size="large"
            borderRadius="100px"
          />
          <Button
            className={`cancel-btn default ${loading && 'disabled'}`}
            label={t('CANCEL')}
            onClick={() => setModal(initModal)}
            size="large"
            borderRadius="100px"
          />
        </div>
      </RoleActionsWrapper>
    </CSSTransition>
  );
};

const RoleActionsWrapper = styled.div`
  width: 384px;

  .delete-btn {
    background: ${({ theme }) => theme.additionalRed};
    border: 1px solid ${({ theme }) => theme.additionalRed};
  }

  .delete-btn {
    background: ${({ theme }) => theme.additionalRedDark};
    border: 1px solid ${({ theme }) => theme.additionalRedDark};
  }
`;

export default RoleActions;
