import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../../components/page-header';
import RolesList from '../../../components/roles-list';
import { OrganisationContext } from '../../../context/organisationContext';
import useDebounce from '../../../helpers/useDebounceHook';
import UserRolesWrapper from '../../../styles/pages/user-roles.styled';

function UserRoles() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [search, setSearchText] = useState('');

  const debouncedSearch = useDebounce(search, 500);
  const { setModal } = useContext(OrganisationContext);

  const handleAddNewRole = () => {
    setModal({
      type: 'add-new-role',
      content: {
        title: t('ADD_ROLE'),
      },
    });
  };

  return (
    <UserRolesWrapper className="flex-column w-full h-full items-start justify-start flex-1">
      <PageHeader
        names={[
          { text: t('SETTINGS'), onClick: () => navigate('/settings') },
          { text: t('SECURITY'), onClick: () => navigate('/settings') },
          { text: t('ROLES'), onClick: () => {} },
        ]}
        showAddNewBtn
        onAddClick={handleAddNewRole}
        showSearch
        addBtnPermission="ROLES_MANAGE"
        searchText={search}
        addButtonLabel={t('ADD_ROLE')}
        onSearchChange={setSearchText}
      />
      <RolesList debouncedSearch={debouncedSearch} />
    </UserRolesWrapper>
  );
}

export default UserRoles;
