import { createSlice } from '@reduxjs/toolkit';
import api from '../services';

const fileUploadSlice = createSlice({
  name: 'fileUpload',
  initialState: {},
  reducers: {},
});

const getFileData = (chunk, length, folder, resize, chunk_number, session_id) => {
  const url = chunk_number === 0 ? '/files' : `/files/${session_id}`;
  const formData = new FormData();

  if (chunk_number === 0) {
    formData.append('file', chunk);
    formData.append('folder', folder);
    formData.append('backend', '');
    formData.append('resize', resize);
    formData.append('length', length);
  } else {
    formData.append('file', chunk);
    formData.append('offset', chunk_number);
  }

  return { formData, url };
};

export const uploadFile =
  ({ file, folder = 'files', resize = '', onSuccess, onError, setProgress }) =>
  async dispatch => {
    let data = null;
    const chunkSize = 256 * 1024; // (adjust based on your requirements)
    const totalChunks = Math.floor(file.size / chunkSize) + 1;
    const chunkProgress = 100 / totalChunks;
    let chunkNumber = 0;
    let start = 0;
    let end = chunkSize;

    const uploadNextChunk = async () => {
      if (chunkNumber < totalChunks) {
        try {
          let chunk = file.slice(start, end);
          chunk = new File([chunk], file.name, { type: file.type, lastModified: file.lastModified });

          const { formData, url } = getFileData(chunk, file.size, folder, resize, start, data?.session_id);

          const response = await api.post(url, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            timeout: 50000,
          });

          data = response.data;
          chunkNumber++;
          start = end;
          end = start + chunkSize;
          const progress = Number((chunkNumber + 1) * chunkProgress);
          setProgress(progress > 100 ? 100 : progress);
          uploadNextChunk();
        } catch (error) {
          onError(error);
        }
      } else {
        onSuccess(data);
      }
    };

    uploadNextChunk();
  };

export const {} = fileUploadSlice.actions;
export default fileUploadSlice.reducer;
