import classNames from 'classnames';
import { motion } from 'framer-motion';
import isEqual from 'lodash.isequal';
import { nanoid } from 'nanoid';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import uuid from 'react-uuid';
import styled from 'styled-components';
import { ReactComponent as AddIcon, ReactComponent as PlusIcon } from '../assets/icons/add-icon.svg';
import { ReactComponent as Usericon } from '../assets/icons/contact-user.svg';
import { ReactComponent as DownArrow } from '../assets/icons/down-arrow.svg';
import { ReactComponent as EditIcon, ReactComponent as EditPensil } from '../assets/icons/edit-pensil.svg';
import { ReactComponent as BedroomIcon } from '../assets/icons/property/bedroom.svg';
import { ReactComponent as CalendarIcon } from '../assets/icons/property/calendar.svg';
import { ReactComponent as ConservatoryIcon } from '../assets/icons/property/conservatory.svg';
import { ReactComponent as ElectricIcon } from '../assets/icons/property/electric.svg';
import { ReactComponent as ExtentionIcon } from '../assets/icons/property/extentions.svg';
import { ReactComponent as HouseIcon } from '../assets/icons/property/house.svg';
import { ReactComponent as LeftIcon } from '../assets/icons/property/left.svg';
import { ReactComponent as LivingRoomIcon } from '../assets/icons/property/living-room.svg';
import { ReactComponent as LocationIcon } from '../assets/icons/property/location-icon.svg';
import { ReactComponent as MainGasIcon } from '../assets/icons/property/main-gas.svg';
import { ReactComponent as SizeIcon } from '../assets/icons/property/size.svg';
import { ReactComponent as StoreyIcon } from '../assets/icons/property/storeys.svg';
import { ReactComponent as ToiletIcon } from '../assets/icons/property/toilet.svg';
import { ReactComponent as RightArrow } from '../assets/icons/right-arrow.svg';
import { ReactComponent as DeleteIcons } from '../assets/images/DeleteIcons.svg';
import { ReactComponent as SearchIcon } from '../assets/images/search.svg';
import Button from '../components/common/button/button';
import Checkbox from '../components/common/checkbox';
import IconContainer from '../components/common/icon-container';
import InputElement from '../components/common/input';
import InputNumber from '../components/common/input-number/inputNumber';
import MobileInput from '../components/common/mobile-input';
import PhoneInput from '../components/common/phone-input';
import SearchableDropdown from '../components/common/searchable-dropdown';
import Dropdown from '../components/common/select-dropdown';
import Status from '../components/common/status';
import CommonStepper from '../components/common/stepper-common';
import { OrganisationContext } from '../context/organisationContext';
import {
  ageBandEnumOptions,
  companyTypeOptions,
  conservatoryOptionals,
  contactTitleOptions,
  contactTypeOptions,
  employeeTypeOptions,
  orientationOptions,
  primaryHeatingOptions,
  propertySubTypeOptions,
  propertyTypeOptions,
  secondaryHeatingOption,
} from '../helpers/optionData';
import { capitalize, capitalizeFirstLetterOfWords, formatText, initModal } from '../helpers/utils';
import {
  createProperty,
  getContactDetails,
  getPropertyAddress,
  getPropertyAddressById,
} from '../store/features/newPropertySlice';
import { addToast } from '../store/features/toastSlice';

const formatted_address_format = ['line1', 'line2', 'line3', 'city', 'state', 'postcode'];

const steps = [
  { title: 'Address', value: 'PROPERTY' },
  { title: 'Property', value: 'DETAILS' },
  { title: 'Contact', value: 'CONTACT' },
  { title: 'Summary', value: 'SUMMARY' },
];

const commercialBlockSteps = [
  { title: 'Address', value: 'PROPERTY' },
  { title: 'Contact', value: 'CONTACT' },
  { title: 'Summary', value: 'SUMMARY' },
];

const PropertyAddress = ({
  propertyAddress,
  setPropertyAddress,
  error,
  setError,
  setLoadingAddress,
  propertyClass,
  setPropertyClass,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { formatted_address, line1, line2, line3, city, state, postcode, name } = propertyAddress || {};

  const [search, setSearch] = useState('');

  const onAddressUpdate = address => {
    const formatted_address = formatted_address_format
      .map(a => address[a])
      .filter(value => Boolean(value))
      .join(', ');
    setPropertyAddress({
      is_billing: true,
      is_postal: true,
      is_primary: true,
      is_residential: true,
      ...address,
      formatted_address,
    });
  };

  const onChangeAddress = (key, value) => {
    const updatedAddress = { ...propertyAddress, [key]: value };
    const newErrors = { ...error, [key]: false };
    const formatted_address = formatted_address_format
      .map(a => updatedAddress[a])
      .filter(value => Boolean(value))
      .join(', ');
    setPropertyAddress({
      is_billing: true,
      is_postal: true,
      is_primary: true,
      is_residential: true,
      ...updatedAddress,
      formatted_address,
    });
    setError(newErrors);
  };

  const onAddressSelect = address => {
    if (!address) {
      onAddressUpdate({});
      return;
    }
    setLoadingAddress(true);

    dispatch(getPropertyAddressById({ id: address.id }))
      .then(data => {
        onAddressUpdate({ ...data, external_reference: address.id });
      })
      .catch(e => {
        return '';
      })
      .finally(() => setLoadingAddress(false));
  };

  const fetchChildOpt = async (
    search,
    _prevOptions,
    { page, merge, fetchFunction, hasMore, pageable, params = {} },
  ) => {
    if (!fetchFunction) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
          merge: merge,
          fetchFunction: fetchFunction,
          hasMore: hasMore,
        },
      };
    }
    try {
      const optionData = await dispatch(
        fetchFunction({
          forFetchOnly: true,
          params: {
            page: page,
            search: search,
            ...params,
          },
        }),
      );
      let optionContent = [];
      let last = true;
      if (pageable) {
        const { content, ...restResponse } = optionData || {};
        optionContent = optionData ? content : [];
        last = restResponse.last;
      } else {
        optionContent = optionData || [];
      }
      const changedOptions = optionContent.map(option => ({ ...option, label: option.address, value: option.id }));
      return {
        options: changedOptions,
        hasMore: !last,
        additional: {
          page: page + 1,
          merge: merge,
          fetchFunction: fetchFunction,
          hasMore: !last,
          pageable,
        },
      };
    } catch (error) {
      return {
        options: [],
        hasMore: hasMore,
        additional: {
          page: page,
          merge: merge,
          fetchFunction: fetchFunction,
          hasMore: hasMore,
        },
      };
    }
  };

  return (
    <div className="flex-column row-gap-6">
      <div className="flex items-center justify-between border-bottom pb-3">
        <p className="inter-600-text font-16 flex-1">{t('PROPERTY_ADDRESS')}</p>
        <div className="flex items-center col-gap-4">
          <Checkbox
            is_rounded
            name={'Residential'}
            checked={propertyClass === 'RESIDENTIAL'}
            onChange={() => setPropertyClass('RESIDENTIAL')}
          />
          <Checkbox
            is_rounded
            name={'Land'}
            checked={propertyClass === 'LAND'}
            onChange={() => setPropertyClass('LAND')}
          />
          <Checkbox
            is_rounded
            name={'Commercial'}
            checked={propertyClass === 'COMMERCIAL'}
            onChange={() => setPropertyClass('COMMERCIAL')}
          />
          <Checkbox
            is_rounded
            name={'Block'}
            checked={propertyClass === 'BLOCK'}
            onChange={() => setPropertyClass('BLOCK')}
          />
        </div>
      </div>
      <div className="property-search-dropdown relative">
        <div className="search-icon absolute o03">
          <SearchIcon />
        </div>
        <SearchableDropdown
          isClearable
          loadOptionsOnMenuOpen={false}
          loadOptions={fetchChildOpt}
          className="w-full"
          placeholder={t('SEARCH_FOR_AN_ADDRESS')}
          customStyle={{
            control: { height: '32px', borderRadius: '100px', background: '#F5F5F5' },
            valueContainer: { padding: '0 16px' },
          }}
          inputValue={search}
          onInputChange={setSearch}
          value={formatted_address ? { label: formatted_address } : null}
          onChange={onAddressSelect}
          isSearchable={true}
          isCustomSearchable={false}
          defaultAdditional={{ page: 0, fetchFunction: getPropertyAddress, pageable: false }}
        />
      </div>
      {propertyClass !== 'RESIDENTIAL' && (
        <div className="flex-column row-gap-6">
          <div className="w-full">
            <InputElement
              className="w-full"
              name={t('NAME')}
              placeholder={t('EXAMPLE_NAME')}
              value={name || ''}
              onChange={value => onChangeAddress('name', value)}
            />
          </div>
        </div>
      )}
      <div className="flex-column row-gap-6">
        <div className="propertyDetails-details col-gap-6">
          <InputElement
            className="w-full"
            name={t('ADDRESS_LINE_ONE')}
            placeholder={t('EXAMPLE_WALNUT_AVENUE')}
            value={line1 || ''}
            onChange={value => onChangeAddress('line1', value)}
            error={error.line1 && !line1}
          />
          <InputElement
            className="w-full"
            name={t('ADDRESS_LINE_TWO')}
            sub_name={t('OPTIONAL')}
            placeholder={t('EXAMPLE_APARTMENT')}
            value={line2 || ''}
            onChange={value => onChangeAddress('line2', value)}
            error={error.line2 && !line2}
          />
        </div>
        <div className="propertyDetails-details col-gap-6">
          <InputElement
            className="w-full"
            name={t('ADDRESS_LINE_THREE')}
            sub_name={t('OPTIONAL')}
            placeholder={t('EXAMPLE_ACOMB')}
            value={line3 || ''}
            onChange={value => onChangeAddress('line3', value)}
            error={error.line3 && !line3}
          />
          <InputElement
            className="w-full"
            name={t('CITY')}
            placeholder={t('EXAMPLE_RYDE')}
            value={city || ''}
            onChange={value => onChangeAddress('city', value)}
            error={error.city && !city}
          />
        </div>
        <div className="propertyDetails-details col-gap-6">
          <InputElement
            className="w-full"
            name={t('POSTCODE')}
            placeholder={t('EXAMPLE_BN16_78F')}
            value={postcode || ''}
            onChange={value => onChangeAddress('postcode', value)}
            error={error.postcode && !postcode}
          />
          <InputElement
            className="w-full"
            name={t('COUNTY')}
            sub_name={t('OPTIONAL')}
            placeholder={t('EXAMPLE_ISLE_OF_WIGHT')}
            value={state || ''}
            onChange={value => onChangeAddress('state', value)}
          />
        </div>
      </div>
    </div>
  );
};

const PropertyDetails = ({ propertyDetails, setPropertyDetails }) => {
  const {
    propertyType,
    propertySubType,
    age,
    orientation,
    storeys,
    bedrooms,
    bathrooms,
    reception,
    extensions,
    conservatory,
    size,
    mainHeating,
    secondHeating,
  } = propertyDetails;

  const { t } = useTranslation();

  const onPropertyAddressUpdate = (key, value) => {
    setPropertyDetails({ ...propertyDetails, [key]: value });
  };

  return (
    <div className="flex-column row-gap-6">
      <p className="inter-600-text font-16 border-bottom pb-3">{t('PROPERTY_DETAILS')}</p>
      <div className="flex-column row-gap-6">
        <div className="flex col-gap-6">
          <Dropdown
            onChange={option => {
              onPropertyAddressUpdate('propertyType', option);
            }}
            name={t('PROPERTY_TYPE')}
            sub_name={t('OPTIONAL')}
            options={propertyTypeOptions}
            placeholder={t('SELECT_FROM_LIST')}
            className={!(propertySubType?.value === 'SEMI_DETACHED') && 'property-option'}
            value={propertyType}
          />

          {/* <div className="flex col-gap-6"> */}
          <Dropdown
            onChange={option => {
              onPropertyAddressUpdate('propertySubType', option);
            }}
            name={t('PROPERTY_SUB_TYPE')}
            sub_name={t('OPTIONAL')}
            options={propertySubTypeOptions}
            placeholder={t('SELECT_FROM_LIST')}
            value={propertySubType}
          />
          {propertySubType?.value === 'SEMI_DETACHED' && (
            <Dropdown
              onChange={option => {
                onPropertyAddressUpdate('orientation', option);
              }}
              name={t('ORIENTATION')}
              sub_name={t('OPTIONAL')}
              options={orientationOptions}
              placeholder={t('SELECT_FROM_LIST')}
              value={orientation}
            />
          )}
          <Dropdown
            onChange={option => {
              onPropertyAddressUpdate('age', option);
            }}
            name={t('AGE')}
            sub_name={t('OPTIONAL')}
            options={ageBandEnumOptions}
            placeholder={t('SELECT_FROM_LIST')}
            value={age}
          />
          {/* </div> */}
        </div>

        <div className="flex col-gap-6">
          <InputNumber
            className="w-full"
            name={t('STOREYS')}
            sub_name={t('OPTIONAL')}
            placeholder={'0'}
            value={storeys}
            onChange={value => onPropertyAddressUpdate('storeys', value)}
          />
          <InputNumber
            className="w-full"
            name={t('BEDROOMS')}
            sub_name={t('OPTIONAL')}
            placeholder={'0'}
            value={bedrooms}
            onChange={value => onPropertyAddressUpdate('bedrooms', value)}
          />
          <InputNumber
            className="w-full"
            name={t('BATHROOMS')}
            sub_name={t('OPTIONAL')}
            placeholder={'0'}
            value={bathrooms}
            onChange={value => onPropertyAddressUpdate('bathrooms', value)}
            step={0.5}
          />
          <InputNumber
            className="w-full"
            name={t('RECEPTIONS')}
            sub_name={t('OPTIONAL')}
            placeholder={'0'}
            value={reception}
            onChange={value => onPropertyAddressUpdate('reception', value)}
          />
          <InputNumber
            className="w-full"
            name={t('EXTENSIONS')}
            sub_name={t('OPTIONAL')}
            placeholder={'0'}
            value={extensions}
            onChange={value => onPropertyAddressUpdate('extensions', value)}
          />
        </div>

        <div className="property-details col-gap-6">
          <Dropdown
            onChange={option => {
              onPropertyAddressUpdate('conservatory', option);
            }}
            name={t('CONSERVATORY')}
            sub_name={t('OPTIONAL')}
            options={conservatoryOptionals}
            placeholder={t('SELECT')}
            value={conservatory}
          />
          <InputElement
            className="w-full"
            name={t('SIZE')}
            sub_name={t('OPTIONAL')}
            placeholder={t('ENTER_SIZE')}
            value={size}
            extra_text={t('SQ_MTR')}
            onChange={value => onPropertyAddressUpdate('size', value)}
          />
        </div>
        <div className="property-details col-gap-6">
          <Dropdown
            onChange={option => {
              onPropertyAddressUpdate('mainHeating', option);
            }}
            menuPlacement="top"
            name={t('MAIN_HEATING_SYSTEM')}
            sub_name={t('OPTIONAL')}
            options={primaryHeatingOptions}
            placeholder={t('SELECT_FROM_LIST')}
            value={mainHeating}
          />
          <Dropdown
            onChange={option => {
              onPropertyAddressUpdate('secondHeating', option);
            }}
            menuPlacement="top"
            options={secondaryHeatingOption}
            name={t('SECOND_HEATING_SYSTEM')}
            sub_name={t('OPTIONAL')}
            placeholder={t('SELECT_FROM_LIST')}
            value={secondHeating}
          />
        </div>
      </div>
    </div>
  );
};

export const ContactSummary = ({
  propertyDetails,
  setSelectedTab,
  contacts,
  setActiveStep,
  propertyAddress,
  steps,
  propertyClass,
  isContact,
}) => {
  const {
    age,
    storeys,
    bathrooms,
    bedrooms,
    reception,
    extensions,
    conservatory,
    propertySubType,
    propertyType,
    size,
    mainHeating,
    type,
    orientation,
  } = propertyDetails;

  const { t } = useTranslation();

  const [showDetails, setShowDetails] = useState(Array(contacts.length).fill(true));
  const detailsWrapperRef = useRef();

  const handleToggle = index => {
    const parent = detailsWrapperRef.current;
    if (parent) {
      const eleToScroll = parent.querySelector(`.details__wrapper-${index}`);
      setTimeout(() => {
        eleToScroll.scrollIntoView({
          behavior: 'smooth',
        });
      }, 501);
    }
    setShowDetails(prevState => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const rendetPropertyDetail = (icon, text, containerClassName) => {
    return (
      <div className={classNames('flex items-center', containerClassName)}>
        <IconContainer
          iconContainerClassname="mr-2 flex h-fit-content"
          iconClassName="natural-600-text"
          iconHeight={16}
          iconWidth={16}
          Icon={icon}
          backgroundColor="natural_100"
        />
        <label className="inter-400-text natural-900-text">{text}</label>
      </div>
    );
  };
  return (
    <div className="flex-column row-gap-flex-column row-gap-10">
      {!isContact && (
        <div className="flex-column row-gap-flex-column">
          <div className=" border-bottom pb-3 flex items-center justify-between">
            <p className="inter-600-text font-16">{t('PROPERTY')}</p>
            <div className="bg-primary-50 w-24px h-24px flex items-center justify-center radius-1_5 ">
              <EditPensil
                width={16}
                height={16}
                className="blue-primary-icon cursor"
                onClick={() => {
                  setSelectedTab('ADDRESS');
                  setActiveStep(0);
                }}
              />
            </div>
          </div>

          <div className="flex items-center mt-6 justify-between">
            {rendetPropertyDetail(LocationIcon, propertyAddress?.formatted_address || '-', 'span-2')}
            <div>
              <Status
                withDot={false}
                withDottedBorder={false}
                status={propertyClass}
                statusText={capitalizeFirstLetterOfWords(formatText(propertyClass))}
              />
            </div>
          </div>
        </div>
      )}
      <div>
        <div className="border-bottom pb-3 flex itmes-center justify-between">
          <p className="inter-600-text font-16">{t('CONTACT_DETAILS')}</p>
          <div className="bg-primary-50 w-24px h-24px flex items-center justify-center radius-1_5 ">
            <EditPensil
              width={16}
              height={16}
              className="blue-primary-icon cursor"
              onClick={() => {
                const contactStepIndex = steps.findIndex(step => step.value === 'CONTACT');
                setSelectedTab('CONTACT');
                setActiveStep(contactStepIndex);
              }}
            />
          </div>
        </div>

        <div className="contact-summary mt-5 flex-column gap-4" ref={detailsWrapperRef}>
          {contacts?.length ? (
            contacts?.map((contactSummary, index) => (
              <div key={index} className="flex-column row-gap-flex-column gap-4">
                <ContactDetailsWrapper
                  initial={{ opacity: 0.3, y: 10 }}
                  animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}
                  className="radius-2 flex-column">
                  <div className={`flex-column row-gap-4 details__wrapper-${index}`}>
                    {contactSummary?.contact_type === 'PERSON' && (
                      <div className="flex-column border radius-2 px-4 py-3">
                        <div className="flex justify-between items-center cursor" onClick={() => handleToggle(index)}>
                          <div className="flex gap-3 items-center">
                            {showDetails[index] ? <DownArrow /> : <RightArrow />}
                            <span className="inter-500-text font-14 natural-900-text">
                              {contactSummary?.title?.label} {contactSummary?.name}
                            </span>
                          </div>
                          <div className={classNames('bg-pink radius-4 px-3 py-0_5 line-height-1')}>
                            <label className={'inter-400-text color-purple font-12 option-text line-height-20'}>
                              {contactSummary?.type?.label}
                            </label>
                          </div>
                        </div>
                        <div
                          className={classNames(
                            'summary-details',
                            showDetails[index] && 'border-top pt-3 mt-3 opened-user',
                          )}>
                          <div className="flex col-gap-4 row-gap-3 fw-w">
                            <p className="inter-400-text font-14 natural-900-text">
                              <span className="natural-500-text">{t('NAME')}:</span> {contactSummary?.name}
                            </p>
                            <p className="inter-400-text font-14 natural-900-text">
                              <span className="natural-500-text">{t('MOBILE')}:</span> {contactSummary?.country_code}
                              {contactSummary?.mobile}
                            </p>
                            <p className="inter-400-text font-14 natural-900-text">
                              <span className="natural-500-text">{t('LANDLINE')}:</span> {contactSummary?.landline}
                            </p>
                            <p className="inter-400-text font-14 natural-900-text">
                              <span className="natural-500-text">{t('EMAIL')}:</span> {contactSummary?.email}
                            </p>
                            <p className="inter-400-text font-14 natural-900-text">
                              <span className="natural-500-text">{t('ADDRESS')}:</span>{' '}
                              {contactSummary?.address?.formatted_address}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                    {contactSummary?.contact_type === 'COMPANY' && (
                      <div className="flex-column border radius-2 px-4 py-3">
                        <div className="flex justify-between items-center cursor" onClick={() => handleToggle(index)}>
                          <div className="flex gap-2 items-center">
                            {showDetails[index] ? <DownArrow /> : <RightArrow />}
                            <span className="inter-400-text font-14 natural-500-text">{t('COMPANY')}:</span>
                            <span className="inter-500-text font-14 natural-900-text">{contactSummary?.name}</span>
                          </div>
                          <div className={classNames('bg-pink radius-4 px-3 py-0_5 line-height-1')}>
                            <label className={'inter-400-text color-purple font-12 option-text line-height-20'}>
                              {contactSummary?.type?.label}
                            </label>
                          </div>
                        </div>
                        <div
                          className={classNames(
                            'summary-details',
                            showDetails[index] && 'border-top pt-3 mt-3 opened-user',
                          )}>
                          <div className="flex-column row-gap-6">
                            <div className="flex col-gap-4 row-gap-3 fw-w">
                              <p className="inter-400-text font-14 natural-900-text">
                                <span className="natural-500-text">{t('NAME')}:</span> {contactSummary?.name}
                              </p>
                              <p className="inter-400-text font-14 natural-900-text">
                                <span className="natural-500-text">{t('LANDLINE')}:</span>{' '}
                                {contactSummary?.landline || '-'}
                              </p>
                              <p className="inter-400-text font-14 natural-900-text">
                                <span className="natural-500-text">{t('EMAIL')}:</span> {contactSummary?.email}
                              </p>
                              <p className="inter-400-text font-14 natural-900-text">
                                <span className="natural-500-text">{t('ADDRESS')}:</span>{' '}
                                {contactSummary?.address?.formatted_address}
                              </p>
                            </div>
                            {contactSummary?.employees?.map((employee, index) => (
                              <div className="flex-column border radius-2 px-4 py-3 contact-details" key={index}>
                                <div className="flex justify-between items-center cursor">
                                  <div className="flex gap-2 items-center">
                                    <span className="inter-400-text font-14 natural-500-text">
                                      {t('EMPLOYEE')} {index + 1}:
                                    </span>
                                    <span className="inter-500-text font-14 natural-900-text">
                                      {employee?.title?.label} {employee?.name}
                                    </span>
                                  </div>
                                  <div className={classNames('bg-pink radius-4 px-3 py-0_5 line-height-1')}>
                                    <label className={'inter-400-text color-purple font-12 option-text line-height-20'}>
                                      {employee?.type?.label}
                                    </label>
                                  </div>
                                </div>
                                <div className={classNames('summary-details border-top pt-3 mt-3 opened-user')}>
                                  <div className="flex col-gap-8 row-gap-3 fw-w">
                                    <p className="inter-400-text font-14 natural-900-text">
                                      <span className="natural-500-text">{t('TITLE')}:</span> {employee?.title?.label}
                                    </p>
                                    <p className="inter-400-text font-14 natural-900-text">
                                      <span className="natural-500-text">{t('FULL_NAME')}:</span> {employee?.name}
                                    </p>
                                    <p className="inter-400-text font-14 natural-900-text">
                                      <span className="natural-500-text">{t('MOBILE')}:</span> {employee?.mobile}
                                    </p>
                                    <p className="inter-400-text font-14 natural-900-text">
                                      <span className="natural-500-text">{t('LANDLINE')}:</span> {employee?.landline}
                                    </p>
                                    <p className="inter-400-text font-14 natural-900-text">
                                      <span className="natural-500-text">{t('EMAIL')}:</span> {employee?.email}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </ContactDetailsWrapper>
              </div>
            ))
          ) : (
            <label className="inter-400-text font-14 line-height-20 natural-400-text">{t('NO_DATA')}</label>
          )}
        </div>
      </div>
      {propertyClass === 'RESIDENTIAL' && (
        <div className="flex-column row-gap-flex-column">
          <div className=" border-bottom pb-3 flex items-center justify-between">
            <p className="inter-600-text font-16">{t('PROPERTY_DETAILS')}</p>
            <div className="bg-primary-50 w-24px h-24px flex items-center justify-center radius-1_5 ">
              <EditPensil
                width={16}
                height={16}
                className="blue-primary-icon cursor"
                onClick={() => {
                  const propertyStepIndex = steps.findIndex(step => step.value === 'DETAILS');
                  setSelectedTab('PROPERTY');
                  setActiveStep(propertyStepIndex);
                }}
              />
            </div>
          </div>
          <div className="main-property items-center mt-6 justify-center">
            {rendetPropertyDetail(HouseIcon, capitalize(formatText(propertyType?.label)) || '-')}
            {rendetPropertyDetail(HouseIcon, capitalize(formatText(propertySubType?.label) || '-'), 'capitalize-value')}
            {propertySubType?.value === 'SEMI_DETACHED' &&
              rendetPropertyDetail(LeftIcon, capitalize(formatText(orientation?.label)) || '-')}
            {rendetPropertyDetail(CalendarIcon, `${age?.label || '-'}`)}
            {rendetPropertyDetail(StoreyIcon, `${storeys || 0} ${t('STOREYS')}`)}
            {rendetPropertyDetail(BedroomIcon, `${bedrooms || 0} ${t('BEDROOMS')}`)}
            {rendetPropertyDetail(ToiletIcon, `${bathrooms || 0} ${t('BATHROOMS')}`)}
            {rendetPropertyDetail(LivingRoomIcon, `${reception || 0} ${t('RECEPTIONS')}`)}
            {rendetPropertyDetail(ExtentionIcon, `${extensions || 0} ${t('EXTENSIONS')}`)}
            {rendetPropertyDetail(ConservatoryIcon, `${conservatory?.label || 'No'} ${t('CONSERVATORY')}`)}
            {rendetPropertyDetail(SizeIcon, `${size || 0} ${t('SQ_MTR')}`)}
            {rendetPropertyDetail(ElectricIcon, `${t('ELECTRIC_BOILER') || '_'}`)}
            {rendetPropertyDetail(MainGasIcon, `Main gas - ${mainHeating?.value ? 'Yes' : 'No'}`)}
          </div>
        </div>
      )}
    </div>
  );
};

export const ContactDetailsNew = ({ contacts, setContacts, propertyAddress }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { setInsideModal } = useContext(OrganisationContext);
  const contactScrollRef = useRef(null);

  const [search, setSearch] = useState('');
  const [error, setError] = useState({});
  const [employeeError, setEmployeeError] = useState({});
  const [showContactEdit, setShowContactEdit] = useState(!contacts.length);
  const [contactEdit, setContactEdit] = useState({ isNew: true, contact_id: uuid(), contact_type: 'PERSON' });
  const [selectedTab, setSelectedTab] = useState('PERSON');

  const [contactEditEmployee, setContactEditEmployee] = useState([]);

  const personSelectedContactIds = contacts.filter(c => c.contact_type === 'PERSON').map(c => c.value);
  const companySelectedContactIds = contacts.filter(c => c.contact_type === 'COMPANY').map(c => c.value);

  const onSelectContact = contact => {
    const name =
      contact.contact_type === 'COMPANY'
        ? contact?.company_name || contact?.name || ''
        : contact?.forename || contact?.surname
        ? `${contact?.forename || ''} ${contact?.surname || ''}`
        : '';
    const typeOptions = contact?.contact_type === 'PERSON' ? contactTitleOptions : companyTypeOptions;
    const emailPrimary = contact.emails?.find(email => email.is_primary) || contact?.emails?.[0] || {};
    const mobilePrimary =
      contact.phones?.find(phone => phone.is_primary && phone.contact_type === 'MOBILE') ||
      contact?.phones?.find(phone => phone.contact_type === 'MOBILE') ||
      {};
    const landlinePrimary =
      contact.phones?.find(phone => phone.is_primary && phone.contact_type === 'LANDLINE') ||
      contact?.phones?.find(phone => phone.contact_type === 'LANDLINE') ||
      {};
    const addressPrimary = contact.addresses?.find(address => address.is_primary) || contact?.addresses?.[0] || {};
    setContactEdit({
      contact_type: contactEdit.contact_type,
      employees: contactEdit.employees || [],
      value: contact?.value,
      label: contact?.label,
      isNew: contactEdit?.isNew,
      contact_id: contactEdit?.contact_id,
      title: contact.title ? contactTitleOptions.find(t => t.label === contact.title) : null,
      type: contact.type ? typeOptions.find(t => t.label === contact.type) : null,
      name: name,
      email: emailPrimary?.value || '',
      mobile: mobilePrimary.value || '',
      country_code: mobilePrimary.country_code || '+44',
      landline: landlinePrimary.value || '',
      address: addressPrimary,
    });
  };

  const checkContactErrors = () => {
    const { type, title, name, email, mobile, landline, address } = contactEdit;
    const isContactError = !email && !mobile && !landline;
    if (isContactError && !!title && !!name && !!address?.formatted_address && !!type) {
      setError({
        type: !type,
        title: !title,
        name: !name,
        contact: isContactError,
        address: !address?.formatted_address,
      });
      return 'contactError';
    } else if (!title || !name || !address?.formatted_address || !type || isContactError) {
      setError({
        type: !type,
        title: !title,
        name: !name,
        contact: isContactError,
        address: !address?.formatted_address,
      });
      return true;
    }
    setError({});
    return false;
  };

  const checkCompanyErrors = () => {
    const { type, name, email, landline, address } = contactEdit;
    const isContactError = !email && !landline;
    if (isContactError && !!type && !!name && !!address?.formatted_address && !!type) {
      setError({
        type: !type,
        name: !name,
        contact: isContactError,
        address: !address?.formatted_address,
      });
      return 'contactError';
    } else if (!name || !address?.formatted_address || !type || isContactError) {
      setError({
        type: !type,
        name: !name,
        contact: isContactError,
        address: !address?.formatted_address,
      });
      return true;
    }
    setError({});
    return false;
  };

  const checkEmployeeErrors = employee => {
    const { type, title, name, email, mobile, landline } = employee;
    const isContactError = !email && !mobile && !landline;
    if (isContactError && !!title && !!name && !!type) {
      setEmployeeError({
        contact_id: employee.contact_id,
        type: !type,
        title: !title,
        name: !name,
        contact: isContactError,
      });
      return 'employeeError';
    } else if (!title || !name || !type || isContactError) {
      setEmployeeError({
        contact_id: employee.contact_id,
        type: !type,
        title: !title,
        name: !name,
        contact: isContactError,
      });
      return true;
    }
    setEmployeeError({});
    return false;
  };

  const onSaveContact = () => {
    if (checkContactErrors() === 'companyError') {
      dispatch(addToast({ error: true, text: t('PLEASE_ENTER_ONE_METHOD_CONTACT') }));
      return;
    } else if (checkContactErrors()) {
      dispatch(addToast({ error: true, text: t('PLEASE_FILL_THE_REQUIRED_FIELDS') }));
      return;
    }
    const isContactAvailable = contacts.find(c => c.contact_id === contactEdit.contact_id);
    setShowContactEdit(false);
    setContacts(prevContacts =>
      isContactAvailable
        ? [...prevContacts].map(c => (c.contact_id === contactEdit.contact_id ? contactEdit : c))
        : [...prevContacts, { ...contactEdit, isNew: false }],
    );
    setError({});
    setContactEdit({ isNew: true, contact_id: uuid() });
  };

  const onSaveCompany = () => {
    const { employees } = contactEdit;
    if (employees?.length > 0 && employees?.some(employee => employee.isOpened)) {
      dispatch(addToast({ error: true, text: t('PLEASE_SAVE_EMPLOYEE_DETAILS') }));
      return;
    }
    if (checkCompanyErrors() === 'companyError') {
      dispatch(addToast({ error: true, text: t('PLEASE_ENTER_ONE_METHOD_CONTACT') }));
      return;
    } else if (checkCompanyErrors()) {
      dispatch(addToast({ error: true, text: t('PLEASE_FILL_THE_REQUIRED_FIELDS') }));
      return;
    }
    const isContactAvailable = contacts.find(c => c.contact_id === contactEdit.contact_id);
    setShowContactEdit(false);
    setContacts(prevContacts =>
      isContactAvailable
        ? [...prevContacts].map(c =>
            c.contact_id === contactEdit.contact_id ? { ...contactEdit, contact_type: selectedTab } : c,
          )
        : [...prevContacts, { ...contactEdit, contact_type: selectedTab, isNew: false }],
    );
    setError({});
    setContactEdit({ isNew: true, contact_id: uuid() });
  };

  const onSaveEmployee = employee => {
    if (checkEmployeeErrors(employee) === 'employeeError') {
      dispatch(addToast({ error: true, text: t('PLEASE_ENTER_ONE_METHOD_CONTACT') }));
      return;
    } else if (checkEmployeeErrors(employee)) {
      dispatch(addToast({ error: true, text: t('PLEASE_FILL_THE_REQUIRED_FIELDS') }));
      return;
    }
    setContactEdit({
      ...contactEdit,
      employees: [...contactEdit.employees].map(e => (e.contact_id === employee.contact_id ? employee : e)),
    });
  };

  const onEditContact = contact => {
    setShowContactEdit(true);
    setContactEdit(contact);
    setSelectedTab(contact.contact_type);
  };

  const onEditEmployee = (contact, employee) => {
    setShowContactEdit(true);
    setContactEdit(contact);
    setSelectedTab(contact.contact_type);
    setContactEdit({
      ...contact,
      employees: [...contact.employees].map(e => (e.contact_id === employee.contact_id ? { ...e, isOpened: true } : e)),
    });
  };

  useEffect(() => {
    // const employee = contactEdit
    if (contactEdit.employees) {
      const employEdit = contactEdit.employees.find(e => e.isOpened);
      if (!employEdit) return;
      const ele = document.getElementById(employEdit.contact_id);
      ele?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [contactEdit]);

  const onAddEmployee = () => {
    setContactEdit({
      ...contactEdit,
      employees: [
        ...(contactEdit.employees || []),
        {
          isNew: true,
          isOpened: true,
          contact_id: uuid(),
          parent_id: contactEdit?.contact_id,
          contact_type: 'EMPLOYEE',
        },
      ],
    });
  };

  // For perform the scroll based on add employees
  useEffect(() => {
    if (contactEdit.employees?.length > 0) {
      contactScrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [contactEdit.employees?.length]);

  const onDeleteContact = contact => {
    const newContacts = contacts.filter(c => c.contact_id !== contact.contact_id);
    const isContactsEmpty = newContacts.length === 0;
    setContacts(newContacts);
    if (isContactsEmpty) {
      setShowContactEdit(isContactsEmpty);
      setSelectedTab('PERSON');
      setContactEdit({ isNew: true, contact_id: uuid(), contact_type: 'PERSON' });
      setError({});
    }
  };

  const onDeleteEmployee = employee => {
    const newContacts = contacts.map(c => ({
      ...c,
      employees: c.employees.filter(e => e.contact_id !== employee.contact_id),
    }));
    setContacts(newContacts);
  };

  const onAddressAddOrUpdate = () => {
    setInsideModal({
      type: 'address-popup',
      content: {
        addressDetails: contactEdit?.address,
        setAddressDetails: address => setContactEdit(prevContactEdit => ({ ...prevContactEdit, address })),
        propertyAddress: propertyAddress,
      },
    });
  };

  const fetchChildOpt = async (
    search,
    _prevOptions,
    { page, merge, fetchFunction, hasMore, pageable, params = {}, contact_type },
  ) => {
    if (!fetchFunction) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
          merge: merge,
          fetchFunction: fetchFunction,
          hasMore: hasMore,
        },
      };
    }
    try {
      const optionData = await dispatch(
        fetchFunction({
          forFetchOnly: true,
          params: {
            page: page,
            search: search,
            ...params,
          },
        }),
      );
      let optionContent = [];
      let last = true;
      if (pageable) {
        const { content, ...restResponse } = optionData || {};
        optionContent = optionData ? content : [];
        last = restResponse.last;
      } else {
        optionContent = optionData || [];
      }
      const changedOptions = optionContent.map(option => ({
        ...option,
        label: contact_type === 'COMPANY' ? option.company_name || option.name : option.name,
        value: option.id,
      }));
      return {
        options: changedOptions,
        hasMore: !last,
        additional: {
          page: page + 1,
          merge: merge,
          fetchFunction: fetchFunction,
          hasMore: !last,
          pageable,
        },
      };
    } catch (error) {
      return {
        options: [],
        hasMore: hasMore,
        additional: {
          page: page,
          merge: merge,
          fetchFunction: fetchFunction,
          hasMore: hasMore,
        },
      };
    }
  };

  const renderContactInfo = (contacts, contactType) => {
    if (contacts && contacts.length > 0) {
      const filteredContacts = contactType
        ? contacts.filter(contact => contact.contact_type === contactType)
        : contacts;

      return (
        <>
          {filteredContacts.map((contact, index) => (
            <div className="" key={index}>
              <span className={'inter-400-text natural-700-text font-12 option-text'}>{contact.value}</span>
              {contact?.formatted_address && (
                <div className="title-one-line one-line">
                  <span key={contact.id} className={'inter-400-text natural-700-text font-12 option-text '}>
                    {contact?.formatted_address}
                  </span>
                </div>
              )}
            </div>
          ))}
        </>
      );
    }
    return null;
  };

  const CustomOption = props => {
    const { innerProps, data } = props;

    return (
      <div className={'w-full px-4 py-3 cursor border-bottom option-wrapper'} {...innerProps}>
        <div className="flex items-center justify-between pb-1_5 gap-3 select-primary-contact">
          <span className={'inter-400-text natural-900-text font-14 option-text'}>{data.label}</span>
        </div>
        <div className="flex items-center gap-2">
          {renderContactInfo(data.emails, null)}
          <div style={{ width: '2px', height: '12px', background: '#D4D4D4' }}></div>
          {renderContactInfo(data.phones, 'MOBILE')}
          <div style={{ width: '2px', height: '12px', background: '#D4D4D4' }}></div>
          {renderContactInfo(data.phones, 'LANDLINE')}
        </div>
        <div>
          <span className={'inter-400-text natural-900-text font-14 option-text'}>
            {renderContactInfo(data.addresses, null)}
          </span>
        </div>
      </div>
    );
  };

  const tabs = [
    { name: 'Person', key: 'PERSON' },
    { name: 'Company', key: 'COMPANY' },
  ];

  const onChangeTab = tab => {
    setSelectedTab(tab);
    setError({});
    setContactEdit({ isNew: true, contact_id: uuid(), contact_type: tab });
  };

  const updateEmployee = employee => {
    setContactEdit({
      ...contactEdit,
      employees: [...contactEdit.employees].map(e => (e.contact_id === employee.contact_id ? employee : e)),
    });
  };

  const onSelectEmployeeContact = contact => {
    const emailPrimary = contact.emails?.find(email => email.is_primary) || contact?.emails?.[0] || {};
    const mobilePrimary =
      contact.phones?.find(phone => phone.is_primary && phone.contact_type === 'MOBILE') ||
      contact?.phones?.find(phone => phone.contact_type === 'MOBILE') ||
      {};
    const landlinePrimary =
      contact.phones?.find(phone => phone.is_primary && phone.contact_type === 'LANDLINE') ||
      contact?.phones?.find(phone => phone.contact_type === 'LANDLINE') ||
      {};
    const employee = {
      isOpened: true,
      contact_type: 'EMPLOYEE',
      parent_id: contact?.parent_id,
      value: contact.value || null,
      label: contact.label || null,
      isNew: contact?.isNew,
      contact_id: contact?.contact_id,
      title: contact.title ? contactTitleOptions.find(t => t.label === contact.title) : null,
      type: contact.type ? employeeTypeOptions.find(t => t.label === contact.type) : null,
      name: contact?.forename || contact?.surname ? `${contact.forename} ${contact.surname}` : '',
      email: emailPrimary.value || '',
      mobile: mobilePrimary.value || '',
      country_code: mobilePrimary.country_code || '+44',
      landline: landlinePrimary.value || '',
    };
    setEmployeeError({});
    updateEmployee(employee);
  };

  const handleCompanyContactSave = () => {};

  return (
    <div className="flex-column row-gap-6 overflow-scroll">
      <p className="inter-600-text font-16 border-bottom pb-3">{t('CONTACT_DETAILS')}</p>
      {showContactEdit ? (
        <div className="flex flex-column row-gap-6 pxy-5 border radius-1_5" ref={contactScrollRef}>
          <div className="flex gap-5 border-bottom">
            {tabs.map(tab => (
              <div
                key={tab.key}
                onClick={() => {
                  onChangeTab(tab.key);
                }}
                className={classNames('pr-3 pb-6 cursor', selectedTab === tab.key && 'selected-tab')}>
                <label className="inter-500-text natural-700-text">{tab.name}</label>
              </div>
            ))}
          </div>
          {selectedTab === 'PERSON' && (
            <Fragment>
              <div className="search-address flex justify-between border-bottom pb-5">
                <SearchableDropdown
                  isClearable
                  loadOptions={fetchChildOpt}
                  className="w-full"
                  placeholder={t('SEARCH_FOR_AN_EXISTING_CONTACT')}
                  customStyle={{
                    control: {
                      height: '32px',
                      borderRadius: '100px',
                      background: '#F5F5F5',
                      width: '485px',
                      border: 'none',
                    },
                    valueContainer: { padding: '0 16px' },
                  }}
                  inputValue={search}
                  onInputChange={setSearch}
                  value={contactEdit?.value ? contactEdit : null}
                  onChange={option => onSelectContact({ ...option, contact_type: 'PERSON' })}
                  isSearchable={true}
                  isCustomSearchable={false}
                  filterOption={option => !contactEdit?.isNew || !personSelectedContactIds.includes(option.value)}
                  defaultAdditional={{
                    page: 0,
                    fetchFunction: getContactDetails,
                    pageable: true,
                    params: { contact_type: 'PERSON' },
                  }}
                  customComponent={{ Option: CustomOption }}
                />
                <div className="flex items-center gap-3">
                  {!contactEdit.isNew && (
                    <Button
                      className={`primary-grey specified-width bg-white`}
                      label={t('CANCEL')}
                      onClick={() => {
                        setShowContactEdit(false);
                        setContactEdit({ isNew: true, contact_id: uuid() });
                      }}
                      size="customsize"
                      borderRadius="100px"
                      width="113px"
                      height="40px"
                      backgroundColor="transparent"
                    />
                  )}
                  <Button
                    className={`secondary specified-width`}
                    label={t('SAVE')}
                    onClick={onSaveContact}
                    size="large"
                    borderRadius="100px"
                    width="113px"
                    height="40px"
                  />
                </div>
              </div>
              <div className="flex-column row-gap-6">
                <div className="flex col-gap-6">
                  <Dropdown
                    className="w-full provider-selector"
                    onChange={option => {
                      setContactEdit({ ...contactEdit, type: option });
                    }}
                    name={t('TYPE')}
                    options={contactTypeOptions}
                    placeholder={t('SELECT_FROM_LIST')}
                    value={contactEdit.type}
                    error={error.type && !contactEdit.type}
                  />

                  <Dropdown
                    className="w-full provider-selector"
                    onChange={option => {
                      setContactEdit({ ...contactEdit, title: option });
                    }}
                    name={t('TITLE')}
                    options={contactTitleOptions}
                    placeholder={t('SELECT_FROM_LIST')}
                    value={contactEdit.title}
                    error={error.title && !contactEdit.title}
                  />

                  <InputElement
                    className="w-full"
                    name={t('FULL_NAME')}
                    placeholder={t('ENTER_FULL_NAME')}
                    value={contactEdit.name}
                    onChange={value => setContactEdit({ ...contactEdit, name: value })}
                    error={error.name && !contactEdit.name}
                  />
                </div>

                <div className="flex col-gap-6">
                  <InputElement
                    className="w-full"
                    name={t('EMAIL')}
                    sub_name={t('PRIMARY')}
                    placeholder={t('ENTER_EMAIL')}
                    value={contactEdit.email}
                    onChange={value => setContactEdit({ ...contactEdit, email: value })}
                    error={error?.contact && !contactEdit.mobile && !contactEdit.landline && !contactEdit.email}
                  />

                  <div className="w-full flex-column gap-1">
                    <div className="one-line">
                      <span className="inter-500-text natural-900-text">{t('MOBILE')}</span>
                      <span className="inter-400-text natural-400-text ml-1">{t('PRIMARY')}</span>
                    </div>
                    <PhoneInput
                      selectedCountry={contactEdit.country_code}
                      setSelectedCountry={country_code =>
                        setContactEdit({ ...contactEdit, country_code: country_code, mobile: '' })
                      }
                      phone={contactEdit.mobile}
                      setPhone={phone => setContactEdit({ ...contactEdit, mobile: phone })}
                      error={error?.contact && !contactEdit.mobile && !contactEdit.landline && !contactEdit.email}
                    />
                  </div>
                  <div className="w-full flex-column gap-1">
                    <div className="one-l">
                      <span className="inter-500-text natural-900-text">{t('LANDLINE')}</span>
                      <span className="inter-400-text natural-400-text ml-1">{t('PRIMARY')}</span>
                    </div>
                    <MobileInput
                      phone={contactEdit.landline}
                      setPhone={phone => setContactEdit({ ...contactEdit, landline: phone })}
                      error={error?.contact && !contactEdit.mobile && !contactEdit.landline && !contactEdit.email}
                    />
                  </div>
                </div>
                <div className="flex-column">
                  <div className="mb-1 one-line">
                    <span className="inter-500-text natural-900-text">{t('ADDRESS')}</span>
                    <span className="inter-400-text natural-400-text ml-1">{t('PRIMARY')}</span>
                  </div>
                  <div
                    className={classNames(
                      'radius-1_5 px-4 py-3 flex items-center justify-between cursor address-field',
                      error.address && !contactEdit.address?.formatted_address && 'address-field-error',
                    )}
                    onClick={onAddressAddOrUpdate}>
                    {contactEdit.address?.formatted_address && (
                      <span className="inter-400-text natural-900-text font-14">
                        {contactEdit.address?.formatted_address || ''}
                      </span>
                    )}
                    {!contactEdit.address?.formatted_address && (
                      <span className="inter-300-text natural-400-text font-14">{t('CLICK_TO_ADD_ADDRESS')}</span>
                    )}
                    <div className="address-plus-icon o03">
                      {contactEdit.address?.formatted_address ? (
                        <EditIcon height={20} width={20} />
                      ) : (
                        <PlusIcon className="w-full h-full pxy-0_5" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
          {selectedTab === 'COMPANY' && (
            <Fragment>
              <Fragment>
                <div className="search-address flex justify-between border-bottom pb-5">
                  <SearchableDropdown
                    isClearable
                    loadOptions={fetchChildOpt}
                    className="w-full"
                    placeholder="Search for an existing contact by name, address or postcode"
                    customStyle={{
                      control: {
                        height: '32px',
                        borderRadius: '100px',
                        background: '#F5F5F5',
                        width: '485px',
                        border: 'none',
                      },
                      valueContainer: { padding: '0 16px' },
                    }}
                    inputValue={search}
                    onInputChange={setSearch}
                    value={contactEdit?.value ? contactEdit : null}
                    onChange={option => onSelectContact({ ...option, contact_type: 'COMPANY' })}
                    filterOption={option => !contactEdit?.isNew || !companySelectedContactIds.includes(option.value)}
                    isSearchable={true}
                    isCustomSearchable={false}
                    defaultAdditional={{
                      page: 0,
                      fetchFunction: getContactDetails,
                      pageable: true,
                      params: { contact_type: 'COMPANY' },
                      contact_type: 'COMPANY',
                    }}
                    customComponent={{ Option: CustomOption }}
                  />
                  <div className="flex items-center gap-3">
                    {!contactEdit.isNew && (
                      <Button
                        className={`primary-grey specified-width bg-white`}
                        label={t('CANCEL')}
                        onClick={() => {
                          setShowContactEdit(false);
                          setContactEdit({ isNew: true, contact_id: uuid() });
                        }}
                        size="customsize"
                        borderRadius="100px"
                        width="113px"
                        height="40px"
                        backgroundColor="transparent"
                      />
                    )}
                    <Button
                      className={`secondary specified-width`}
                      label={t('SAVE')}
                      onClick={onSaveCompany}
                      size="large"
                      borderRadius="100px"
                      width="113px"
                      height="40px"
                    />
                  </div>
                </div>
                <div className="flex-column row-gap-6">
                  <div className="flex col-gap-6">
                    <Dropdown
                      className="w-full provider-selector"
                      onChange={option => {
                        setContactEdit({ ...contactEdit, type: option });
                      }}
                      name={t('TYPE')}
                      options={companyTypeOptions}
                      placeholder={t('SELECT_FROM_LIST')}
                      value={contactEdit.type}
                      error={error.type && !contactEdit.type}
                    />
                    <InputElement
                      className="w-full"
                      name={t('COMPANY_NAME')}
                      placeholder={t('ENTER_FULL_NAME')}
                      value={contactEdit.name}
                      onChange={value => setContactEdit({ ...contactEdit, name: value })}
                      error={error.name && !contactEdit.name}
                    />
                  </div>

                  <div className="flex col-gap-6">
                    <InputElement
                      className="w-full"
                      name={t('EMAIL')}
                      sub_name={t('PRIMARY')}
                      placeholder={t('ENTER_EMAIL')}
                      value={contactEdit.email}
                      onChange={value => setContactEdit({ ...contactEdit, email: value })}
                      error={error?.contact && !contactEdit.mobile && !contactEdit.landline && !contactEdit.email}
                    />
                    <div className="w-full flex-column gap-1">
                      <div className="one-line">
                        <span className="inter-500-text natural-900-text">{t('LANDLINE')}</span>
                        <span className="inter-400-text natural-400-text ml-1">{t('PRIMARY')}</span>
                      </div>
                      <MobileInput
                        phone={contactEdit.landline}
                        setPhone={phone => setContactEdit({ ...contactEdit, landline: phone })}
                        error={error?.contact && !contactEdit.mobile && !contactEdit.landline && !contactEdit.email}
                      />
                    </div>
                  </div>
                  <div className="flex-column">
                    <div className="mb-1 one-line">
                      <span className="inter-500-text natural-900-text">{t('ADDRESS')}</span>
                      <span className="inter-400-text natural-400-text ml-1">{t('PRIMARY')}</span>
                    </div>
                    <div
                      className={classNames(
                        'radius-1_5 px-4 py-3 flex items-center justify-between cursor address-field',
                        error.address && !contactEdit.address?.formatted_address && 'address-field-error',
                      )}
                      onClick={onAddressAddOrUpdate}>
                      {contactEdit.address?.formatted_address && (
                        <span className="inter-400-text natural-900-text font-14">
                          {contactEdit.address?.formatted_address || ''}
                        </span>
                      )}
                      {!contactEdit.address?.formatted_address && (
                        <span className="inter-300-text natural-400-text font-14">{t('CLICK_TO_ADD_ADDRESS')}</span>
                      )}
                      <div className="address-plus-icon o03">
                        {contactEdit.address?.formatted_address ? (
                          <EditIcon height={20} width={20} />
                        ) : (
                          <PlusIcon className="w-full h-full pxy-0_5" />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
              <Fragment>
                {contactEdit?.employees?.map((employee, index) => (
                  <div className="row-gap-6 flex-column" key={employee?.contact_id} id={employee?.contact_id}>
                    <div className="flex items-center justify-between pb-2 border-bottom">
                      <label className="inter-500-text natural-900-text font-14">
                        {t('EMPLOYEE')} {index + 1}
                        {employee?.title && employee?.name && (
                          <span className="inter-500-text natural-900-text font-14">
                            : {employee?.title?.label} {employee?.name}
                          </span>
                        )}
                      </label>
                      <div className="flex items-center gap-3">
                        <div
                          className="bg-primary-50 w-24px h-24px flex items-center justify-center"
                          onClick={() => {
                            setContactEdit({
                              ...contactEdit,
                              employees: [...contactEdit.employees].filter(e => e.contact_id !== employee.contact_id),
                            });
                          }}>
                          <DeleteIcons width={16} height={16} className="blue-primary-icon cursor" />
                        </div>
                        <div
                          className="w-24px h-24px flex items-center justify-center cursor"
                          onClick={() => {
                            if (employee?.isOpened) {
                              const currentEmployee = contactEditEmployee.find(
                                e => e.contact_id === employee.contact_id,
                              );
                              if (!isEqual(employee, currentEmployee)) {
                                dispatch(addToast({ error: true, text: t('PLEASE_SAVE_EMPLOYEE_DETAILS') }));
                                return;
                              }
                            }
                            updateEmployee({ ...employee, isOpened: !employee.isOpened });
                          }}>
                          {employee?.isOpened ? <DownArrow /> : <RightArrow />}
                        </div>
                      </div>
                    </div>
                    {employee.isOpened && (
                      <Fragment>
                        <div className="search-address flex justify-between border-bottom pb-5">
                          <SearchableDropdown
                            isClearable
                            loadOptions={fetchChildOpt}
                            className="w-full"
                            placeholder={t('SEARCH_FOR_AN_EXISTING_CONTACT')}
                            customStyle={{
                              control: {
                                height: '32px',
                                borderRadius: '100px',
                                background: '#F5F5F5',
                                width: '485px',
                                border: 'none',
                              },
                              valueContainer: { padding: '0 16px' },
                            }}
                            key={contactEdit?.value}
                            inputValue={search}
                            onInputChange={setSearch}
                            noOptionsMessage={t('NO_EMPLOYEE_FOR_COMPANY')}
                            value={employee?.value ? employee : null}
                            onChange={option =>
                              onSelectEmployeeContact({
                                ...option,
                                contact_id: employee.contact_id,
                                parent_id: contactEdit?.value || null,
                                isNew: employee?.isNew,
                                contact_type: 'EMPLOYEE',
                              })
                            }
                            filterOption={option => {
                              const isEmployeeAvailable = contactEdit?.employees?.find(e => e.value === option.value);
                              return !isEmployeeAvailable;
                            }}
                            isSearchable={true}
                            isCustomSearchable={false}
                            defaultAdditional={{
                              page: 0,
                              fetchFunction: getContactDetails,
                              pageable: true,
                              params: { contact_type: 'EMPLOYEE', parent_id: contactEdit?.value },
                            }}
                            customComponent={{ Option: CustomOption }}
                          />
                          <div className="flex items-center gap-3">
                            {!contactEdit.isNew && (
                              <Button
                                className={`primary-grey specified-width bg-white`}
                                label={t('CANCEL')}
                                onClick={() => {
                                  setShowContactEdit(false);
                                  setContactEdit({ isNew: true, contact_id: uuid() });
                                }}
                                size="customsize"
                                borderRadius="100px"
                                width="113px"
                                height="40px"
                                backgroundColor="transparent"
                              />
                            )}
                            <Button
                              className={`secondary specified-width`}
                              label={'Save'}
                              onClick={() => {
                                onSaveEmployee({ ...employee, isOpened: false });
                                const isPresent = contactEditEmployee.filter(e => e.contact_id !== employee.contact_id);
                                setContactEditEmployee([...isPresent, employee]);
                              }}
                              size="large"
                              borderRadius="100px"
                              width="113px"
                              height="40px"
                            />
                          </div>
                        </div>
                        <div className="flex-column row-gap-6">
                          <div className="flex col-gap-6">
                            <Dropdown
                              className="w-full provider-selector"
                              onChange={option => {
                                updateEmployee({ ...employee, type: option });
                              }}
                              name={t('TYPE')}
                              options={employeeTypeOptions}
                              menuPlacement="top"
                              placeholder={t('SELECT_FROM_LIST')}
                              value={employee.type}
                              error={
                                employeeError?.contact_id === employee?.contact_id &&
                                employeeError.type &&
                                !employee.type
                              }
                            />
                            <Dropdown
                              className="w-full provider-selector"
                              onChange={option => {
                                updateEmployee({ ...employee, title: option });
                              }}
                              name={t('TITLE')}
                              menuPlacement="top"
                              options={contactTitleOptions}
                              placeholder={t('SELECT_FROM_LIST')}
                              value={employee.title}
                              error={
                                employeeError?.contact_id === employee?.contact_id &&
                                employeeError.title &&
                                !employee.title
                              }
                            />
                            <InputElement
                              className="w-full"
                              name={t('FULL_NAME')}
                              placeholder={t('ENTER_FULL_NAME')}
                              value={employee.name}
                              onChange={value => updateEmployee({ ...employee, name: value })}
                              error={
                                employeeError?.contact_id === employee?.contact_id &&
                                employeeError.name &&
                                !employee.name
                              }
                            />
                          </div>
                          <div className="flex col-gap-6">
                            <InputElement
                              className="w-full"
                              sub_name="Primary"
                              name={'Email'}
                              placeholder={'Enter email'}
                              value={employee.email}
                              onChange={value => updateEmployee({ ...employee, email: value })}
                              error={
                                employeeError?.contact_id === employee?.contact_id &&
                                employeeError?.contact &&
                                !employee.mobile &&
                                !employee.landline &&
                                !employee.email
                              }
                            />
                            <div className="w-full flex-column gap-1">
                              <div className="one-line">
                                <span className="inter-500-text natural-900-text">{t('MOBILE')}</span>
                                <span className="inter-400-text natural-400-text ml-1">{t('PRIMARY')}</span>
                              </div>
                              <PhoneInput
                                selectedCountry={employee.country_code}
                                setSelectedCountry={country_code =>
                                  setContactEdit({ ...employee, country_code: country_code, mobile: '' })
                                }
                                phone={employee.mobile}
                                setPhone={phone => updateEmployee({ ...employee, mobile: phone })}
                                error={
                                  employeeError?.contact_id === employee?.contact_id &&
                                  employeeError?.contact &&
                                  !employee.mobile &&
                                  !employee.landline &&
                                  !employee.email
                                }
                              />
                            </div>
                            <div className="w-full flex-column gap-1">
                              <div className="one-line">
                                <span className="inter-500-text natural-900-text">{t('LANDLINE')}</span>
                                <span className="inter-400-text natural-400-text ml-1">{t('PRIMARY')}</span>
                              </div>
                              <MobileInput
                                phone={employee.landline}
                                setPhone={phone => updateEmployee({ ...employee, landline: phone })}
                                error={
                                  employeeError?.contact_id === employee?.contact_id &&
                                  employeeError?.contact &&
                                  !employee.mobile &&
                                  !employee.landline &&
                                  !employee.email
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    )}
                  </div>
                ))}
              </Fragment>
              {!contactEdit?.employees?.some(e => e.isOpened) && (
                <div>
                  <Button
                    className={`secondary h-40px gap-6px add-contact-another`}
                    label={t('ADD_EMPLOYEE')}
                    size="customsize"
                    onClick={() => onAddEmployee(contactEdit)}
                    afterIcon={<AddIcon className="mr-0 blue-primary-icon" height={16} width={16} />}
                    borderRadius="100px"
                  />
                </div>
              )}
            </Fragment>
          )}
        </div>
      ) : (
        <div className="contact-list-wrapper row-gap-6 flex-column">
          <div className="flex-column overflow-scroll row-gap-6">
            {contacts?.map(data => (
              <Fragment key={data?.contact_id}>
                <div className="flex-column">
                  <ContactDetailsWrapper
                    initial={{ opacity: 0.3, y: 10 }}
                    animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}
                    className="radius-2 flex-column">
                    {data?.contact_type === 'PERSON' && (
                      <div className="flex-column row-gap-4 ">
                        <div className="flex-column border radius-2 pxy-5">
                          <div className="flex items-center justify-between">
                            <div className="flex gap-2 items-center cursor">
                              <Usericon />
                              <span className="inter-500-text font-14 natural-900-text">
                                {data?.title?.label} {data?.name}
                              </span>
                              <div
                                className={classNames(
                                  'bg-pink radius-4 px-3 py-0_5 line-height-1',
                                  formatText(data?.type?.label),
                                )}>
                                <label className={'inter-400-text color-purple font-12 option-text line-height-20'}>
                                  {data?.type?.label}
                                </label>
                              </div>
                            </div>
                            <div className="flex items-center gap-3">
                              <div
                                className="bg-primary-50 w-24px h-24px flex items-center justify-center radius-1_5 "
                                onClick={() => onEditContact(data)}>
                                <EditPensil width={16} height={16} className="blue-primary-icon cursor" />
                              </div>
                              <div
                                className="bg-primary-50 w-24px h-24px flex items-center justify-center"
                                onClick={() => onDeleteContact(data)}>
                                <DeleteIcons width={16} height={16} className="blue-primary-icon cursor" />
                              </div>
                            </div>
                          </div>
                          <div className={classNames('summary-details border-top pt-3 mt-3 opened-user')}>
                            <div className="flex col-gap-8 row-gap-3 fw-w">
                              <p className="inter-400-text font-14 natural-900-text">
                                <span className="natural-500-text">{t('TITLE')}:</span> {data?.title?.label}
                              </p>
                              <p className="inter-400-text font-14 natural-900-text">
                                <span className="natural-500-text">{t('NAME')}:</span> {data?.name}
                              </p>
                              <p className="inter-400-text font-14 natural-900-text">
                                <span className="natural-500-text">{t('MOBILE')}:</span> {data?.mobile}
                              </p>
                              <p className="inter-400-text font-14 natural-900-text">
                                <span className="natural-500-text">{t('LANDLINE')}:</span> {data?.landline}
                              </p>
                              <p className="inter-400-text font-14 natural-900-text">
                                <span className="natural-500-text">{t('EMAIL')}:</span> {data?.email}
                              </p>
                              <p className="inter-400-text font-14 natural-900-text">
                                <span className="natural-500-text">{t('ADDRESS')}:</span>{' '}
                                {data?.address?.formatted_address}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {data?.contact_type === 'COMPANY' && (
                      <div className="flex-column row-gap-4 ">
                        <div className="flex-column border radius-2 px-4 py-3">
                          <Fragment>
                            <div className="flex items-center justify-between">
                              <div className="flex gap-2 items-center cursor">
                                <span className="inter-400-text natural-500-text font-16">{t('COMPANY')}:</span>
                                <span className="inter-400-text font-16 natural-900-text">{data?.name}</span>
                                <div
                                  className={`bg-pink radius-4 px-3 py-0_5 line-height-1 ${formatText(
                                    data?.type?.label,
                                  )}`}>
                                  <label className={'inter-400-text color-purple font-12 option-text line-height-20'}>
                                    {data?.type?.label}
                                  </label>
                                </div>
                              </div>
                              <div className="flex items-center gap-3">
                                <div
                                  className="bg-primary-50 w-24px h-24px flex items-center justify-center radius-1_5 "
                                  onClick={() => onEditContact(data)}>
                                  <EditPensil width={16} height={16} className="blue-primary-icon cursor" />
                                </div>
                                <div
                                  className="bg-primary-50 w-24px h-24px flex items-center justify-center"
                                  onClick={() => onDeleteContact(data)}>
                                  <DeleteIcons width={16} height={16} className="blue-primary-icon cursor" />
                                </div>
                              </div>
                            </div>
                            <div className={classNames('summary-details border-top pt-3 mt-3 opened-user')}>
                              <div className="flex col-gap-8 row-gap-3 fw-w">
                                <p className="inter-400-text font-14 natural-900-text">
                                  <span className="natural-500-text">{t('FULL_NAME')}:</span> {data?.name}
                                </p>
                                <p className="inter-400-text font-14 natural-900-text">
                                  <span className="natural-500-text">{t('LANDLINE')}:</span> {data?.landline}
                                </p>
                                <p className="inter-400-text font-14 natural-900-text">
                                  <span className="natural-500-text">{t('EMAIL')}:</span> {data?.email}
                                </p>
                                <p className="inter-400-text font-14 natural-900-text">
                                  <span className="natural-500-text">{t('ADDRESS')}:</span>{' '}
                                  {data?.address?.formatted_address}
                                </p>
                              </div>
                            </div>
                          </Fragment>
                          {data?.employees?.map((employee, index) => (
                            <div className="flex-column row-gap-4 mt-5" key={index}>
                              <div className="flex-column border radius-2 px-4 py-3">
                                <div className="flex items-center justify-between">
                                  <div className="flex gap-2 items-center cursor">
                                    <span className="inter-400-text natural-500-text font-16">
                                      {t('EMPLOYEE')} {index + 1}:{' '}
                                    </span>
                                    <span className="inter-500-text font-14 natural-900-text">
                                      {employee?.title?.label} {employee?.name}
                                    </span>
                                    <div
                                      className={classNames(
                                        'bg-pink radius-4 px-3 py-0_5 line-height-1',
                                        formatText(employee?.type?.label),
                                      )}>
                                      <label
                                        className={'inter-400-text color-purple font-12 option-text line-height-20'}>
                                        {employee?.type?.label}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="flex items-center gap-3">
                                    <div
                                      className="bg-primary-50 w-24px h-24px flex items-center justify-center radius-1_5 "
                                      onClick={() => onEditEmployee(data, employee)}>
                                      <EditPensil width={16} height={16} className="blue-primary-icon cursor" />
                                    </div>
                                    <div
                                      className="bg-primary-50 w-24px h-24px flex items-center justify-center"
                                      onClick={() => onDeleteEmployee(employee)}>
                                      <DeleteIcons width={16} height={16} className="blue-primary-icon cursor" />
                                    </div>
                                  </div>
                                </div>
                                <div className={classNames('summary-details border-top pt-3 mt-3 opened-user')}>
                                  <div className="flex col-gap-8 row-gap-3 fw-w">
                                    <p className="inter-400-text font-14 natural-900-text">
                                      <span className="natural-500-text">{t('TITLE')}:</span> {employee?.title?.label}
                                    </p>
                                    <p className="inter-400-text font-14 natural-900-text">
                                      <span className="natural-500-text">{t('NAME')}:</span> {employee?.name}
                                    </p>
                                    <p className="inter-400-text font-14 natural-900-text">
                                      <span className="natural-500-text">{t('MOBILE')}:</span> {employee?.mobile}
                                    </p>
                                    <p className="inter-400-text font-14 natural-900-text">
                                      <span className="natural-500-text">{t('LANDLINE')}:</span> {employee?.landline}
                                    </p>
                                    <p className="inter-400-text font-14 natural-900-text">
                                      <span className="natural-500-text">{t('EMAIL')}:</span> {employee?.email}
                                    </p>
                                    <p className="inter-400-text font-14 natural-900-text">
                                      <span className="natural-500-text">{t('COMPANY_NAME')}:</span> {data?.name}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </ContactDetailsWrapper>
                </div>
              </Fragment>
            ))}
          </div>
          <div>
            <Button
              className={`secondary h-40px gap-6px add-contact-another`}
              label={'Add Another Contact'}
              size="customsize"
              onClick={() => {
                setShowContactEdit(true);
                setContactEdit({ isNew: true, contact_id: uuid(), contact_type: 'PERSON' });
                setSelectedTab('PERSON');
              }}
              afterIcon={<AddIcon className="mr-0 blue-primary-icon" height={16} width={16} />}
              borderRadius="100px"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export const getContactRequest = ({ contacts }) => {
  const companyContacts = contacts.filter(contact => contact.contact_type === 'COMPANY');
  const personContacts = contacts.filter(contact => contact.contact_type === 'PERSON');
  const employeeContact = contacts
    .filter(contact => contact.contact_type === 'COMPANY')
    .map(contact => (contact?.employees || []).map(e => ({ ...e, parent_id: contact?.value || contact?.contact_id })))
    .flat()
    .filter(Boolean);

  return [...companyContacts, ...personContacts, ...employeeContact].map(contact => ({
    id: contact?.value || contact?.contact_id,
    forename: contact.contact_type === 'COMPANY' ? '' : contact?.name?.split(' ')?.[0],
    surname: contact.contact_type === 'COMPANY' ? '' : contact?.name?.split(' ')?.[1],
    title: contact?.title?.label || '',
    property_contact_type: contact?.type?.value,
    parent: contact?.parent_id
      ? {
          id: contact?.parent_id,
        }
      : null,
    company_name: contact.contact_type === 'COMPANY' ? contact?.name : null,
    contact_type: contact?.contact_type,
    date_of_birth: null,
    profession: null,
    emails: contact?.email
      ? [
          {
            value: contact?.email,
            is_primary: true,
            email_type: 'WORK',
          },
        ]
      : null,
    phones:
      contact?.landline || contact?.mobile
        ? [
            contact?.landline
              ? {
                  contact_type: 'LANDLINE',
                  contact_value: contact?.landline?.replaceAll(' ', ''),
                  is_primary: true,
                  phone_type: 'WORK',
                  country_code: '+44',
                }
              : null,
            contact?.mobile
              ? {
                  contact_type: 'MOBILE',
                  contact_value: contact?.mobile?.replaceAll(' ', ''),
                  is_primary: true,
                  phone_type: 'WORK',
                  country_code: contact?.country_code || '+44',
                }
              : null,
          ].filter(Boolean)
        : null,
    addresses:
      contact?.contact_type !== 'EMPLOYEE'
        ? [
            {
              country_code: 'GB',
              is_billing: true,
              is_residential: true,
              is_postal: true,
              ...contact?.address,
              location:
                contact?.address?.location?.lat && contact?.address?.location?.lon ? contact?.address?.location : null,
              is_primary: true,
            },
          ]
        : null,
  }));
};

const AddProperty = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { setModal, modal } = useContext(OrganisationContext);
  const { onSuccess } = modal?.content || {};

  const [propertyDetails, setPropertyDetails] = useState({});
  const [propertyClass, setPropertyClass] = useState('RESIDENTIAL');
  const [propertyAddress, setPropertyAddress] = useState({});
  const [contacts, setContacts] = useState([]);
  const [selectedTab, setSelectedTab] = useState('ADDRESS');
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [loadingAddress, setLoadingAddress] = useState(false);

  const newSteps = propertyClass === 'RESIDENTIAL' ? steps : commercialBlockSteps;

  const checkPropertyAddressErrors = () => {
    const { line1, city, postcode } = propertyAddress || {};
    if (!line1 || !city || !postcode) {
      setError({
        line1: !line1,
        city: !city,
        postcode: !postcode,
      });
      dispatch(addToast({ error: true, text: t('PLEASE_FILL_THE_REQUIRED_FIELDS') }));
      return true;
    }
    return false;
  };

  const checkPropertyContactErrors = () => {
    if (!contacts.length) {
      dispatch(addToast({ error: true, text: t('PLEASE_ADD_ATLEAST_ONE_CONTACT_OR_COMPANY'), id: nanoid() }));
      return true;
    }
    return false;
  };

  const onDone = async () => {
    setLoading(true);
    const {
      propertyType,
      bathrooms,
      bedrooms,
      reception,
      extensions,
      secondHeating,
      mainHeating,
      storeys,
      age,
      orientation,
      size,
    } = propertyDetails;

    const request = {
      country_code: 'GB',
      ...propertyAddress,
      location: null,
      name: propertyAddress?.name,
      address_reference: {
        integration_id: propertyAddress.integration_id || '',
        external_reference: propertyAddress.external_reference || '',
      },
      details:
        propertyClass === 'RESIDENTIAL'
          ? {
              property_type: propertyType?.value || null,
              sub_type: null,
              commercial_type: null,
              parkhome_size: null,
              orientation: orientation?.value,
              storeys: storeys || 0,
              rooms: {
                bedrooms: bedrooms || 0,
                bathrooms: bathrooms || 0,
                receptions: reception || 0,
              },
              tenure: 'PRIVATE_OWNER',
              sq_mtr: size || 0,
              age_band: age?.value,
              conservatories: 0,
              extensions: extensions || 0,
              primary_heating: mainHeating?.value || null,
              secondary_heating: secondHeating?.value || null,
              is_maingas: false,
            }
          : null,
      contacts: getContactRequest({ contacts: contacts }),
      property_class: {
        class_type: propertyClass,
      },
    };
    try {
      const response = await dispatch(createProperty({ request }));
      setLoading(false);
      setModal(initModal);
      dispatch(addToast({ error: false, text: t('SUCCESSFULLY_PROPERTY_CREATED') }));
      onSuccess && onSuccess(response);
    } catch (error) {
      setLoading(false);
      dispatch(
        addToast({ error: true, text: error?.response?.data?.error_description ?? t('ERROR_PROPERTY_CREATED') }),
      );
    }
  };

  const onNextOrDone = () => {
    if (propertyClass === 'RESIDENTIAL') {
      if (selectedTab === 'ADDRESS') {
        if (checkPropertyAddressErrors()) {
          return;
        }
        setActiveStep(activeStep + 1);
        setSelectedTab('PROPERTY');
      } else if (selectedTab === 'PROPERTY') {
        setActiveStep(activeStep + 1);
        setSelectedTab('CONTACT');
      } else if (selectedTab === 'CONTACT') {
        if (checkPropertyContactErrors()) {
          return;
        }
        setActiveStep(activeStep + 1);
        setSelectedTab('SUMMARY');
      } else {
        onDone();
      }
    } else {
      if (selectedTab === 'ADDRESS') {
        if (checkPropertyAddressErrors()) {
          return;
        }
        setActiveStep(activeStep + 1);
        setSelectedTab('CONTACT');
      } else if (selectedTab === 'CONTACT') {
        if (checkPropertyContactErrors()) {
          return;
        }
        setActiveStep(activeStep + 1);
        setSelectedTab('SUMMARY');
      } else {
        onDone();
      }
    }
  };

  const onBack = () => {
    if (propertyClass === 'RESIDENTIAL') {
      if (selectedTab === 'PROPERTY') {
        setActiveStep(activeStep - 1);
        setSelectedTab('ADDRESS');
      } else if (selectedTab === 'CONTACT') {
        setActiveStep(activeStep - 1);
        setSelectedTab('PROPERTY');
      } else if (selectedTab === 'SUMMARY') {
        setActiveStep(activeStep - 1);
        setSelectedTab('CONTACT');
      }
    } else {
      if (selectedTab === 'CONTACT') {
        setActiveStep(activeStep - 1);
        setSelectedTab('ADDRESS');
      } else if (selectedTab === 'SUMMARY') {
        setActiveStep(activeStep - 1);
        setSelectedTab('CONTACT');
      }
    }
  };

  const renderComponents = () => {
    switch (selectedTab) {
      case 'ADDRESS':
        return (
          <PropertyAddress
            setPropertyAddress={setPropertyAddress}
            propertyAddress={propertyAddress}
            setLoadingAddress={setLoadingAddress}
            setError={setError}
            error={error}
            propertyClass={propertyClass}
            setPropertyClass={setPropertyClass}
          />
        );
      case 'PROPERTY':
        return <PropertyDetails setPropertyDetails={setPropertyDetails} propertyDetails={propertyDetails} />;
      case 'CONTACT':
        return (
          <ContactDetailsNew
            propertyAddress={propertyAddress}
            setContacts={setContacts}
            contacts={contacts}
            setError={setError}
            error={error}
          />
        );
      case 'SUMMARY':
        return (
          <ContactSummary
            propertyDetails={propertyDetails}
            propertyAddress={propertyAddress}
            contacts={contacts}
            setSelectedTab={setSelectedTab}
            setActiveStep={setActiveStep}
            steps={newSteps}
            propertyClass={propertyClass}
          />
        );
      default:
        return null;
    }
  };

  return (
    <CSSTransition appear className="popup-fade" in timeout={300}>
      <AddPropertyWrapper className="flex-column" activeStep={activeStep}>
        <div className="flex justify-between px-10 pt-8 pb-5 border-bottom">
          <p className="inter-700-text natural-900-text font-24 mb-2">{'Add New Property'}</p>
          <div className="flex items-center" style={{ width: `${newSteps.length * 110}px`, height: '40px' }}>
            <CommonStepper steps={newSteps} actvieStep={activeStep} />
          </div>
        </div>
        <div className="px-10 py-7 flex-column overflow-scroll" style={{ maxHeight: '70vh' }}>
          {renderComponents()}
        </div>
        <div className="flex px-10 py-6 justify-between w-full border-top">
          <Button
            className={classNames('primary-grey specified-width', loading && 'disabled')}
            label={t('CANCEL')}
            disabled={loading}
            onClick={() => setModal(initModal)}
            size="large"
            borderRadius="100px"
            width="100px"
          />
          <div className="flex gap-3">
            {selectedTab !== 'ADDRESS' && (
              <Button
                className={classNames('secondary specified-width', loading && 'disabled')}
                label={t('BACK')}
                disabled={loading || loadingAddress}
                onClick={onBack}
                size="large"
                borderRadius="100px"
                width="100px"
              />
            )}
            <Button
              className={classNames('primary specified-width', loading && 'disabled')}
              label={selectedTab === 'SUMMARY' ? t('ADD') : t('NEXT')}
              disabled={loading || loadingAddress}
              onClick={onNextOrDone}
              size="large"
              borderRadius="100px"
              width="100px"
            />
          </div>
        </div>
      </AddPropertyWrapper>
    </CSSTransition>
  );
};

const AddPropertyWrapper = styled.div`
  width: 900px;

  .selected-tab {
    border-bottom: 1px solid ${({ theme }) => theme.selectedText};
    label {
      color: ${({ theme }) => theme.selectedText};
    }
  }

  .add-property-stepper {
    div div div div:nth-child(1) {
      box-shadow: 0px 0px 0px 4px #d3eafe;
    }
  }

  .custom-circle {
    box-shadow: 0px 0px 0px 4px #d3eafe;
  }

  .span-2 {
    grid-column: span 2;
  }

  .property-input-search {
    input {
      width: 100%;
    }
  }

  .search-address {
    .searchable-select__menu {
      width: 485px;
    }
  }

  .main-property {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 20px;
    column-gap: 16px;

    .capitalize-value {
      label {
        text-transform: capitalize;
      }
    }
  }

  .propertyDetails-details {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .property-option {
    min-width: 50%;
  }

  .property-details {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .address-plus-icon {
    top: 35px;
    width: 20px;
    height: 20px;
    right: 15px;
  }
  .property-search-dropdown {
    .search-icon {
      top: 14px;
      left: 16px;
      z-index: 999;
    }

    .searchable-select__input-container,
    .searchable-select__placeholder,
    .searchable-select__single-value {
      margin-left: 20px;
    }
  }

  .contact-list-wrapper {
    max-height: 56vh;
  }

  .contact-summary {
    max-height: 40vh;
  }

  .address-field {
    height: 44px;
    border: 1px solid ${({ theme }) => theme.natural_300};
    background-color: ${({ theme }) => theme.natural_100};
  }

  .address-field-error {
    border-color: ${({ theme }) => theme.error_500};

    &:hover {
      border-color: ${({ theme }) => theme.error_500};
    }

    &:focus {
      border-color: ${({ theme }) => theme.error_500};
      box-shadow: none;
    }
  }
`;

const ContactDetailsWrapper = styled(motion.div)`
  .summary-details {
    transition: all 500ms ease-in-out;
    row-gap: 0;
    display: grid;
    grid-template-rows: repeat(1, 0fr);
    scroll-behavior: smooth;
    & > div {
      overflow: hidden;
    }
    .contact-details {
      overflow: hidden;
    }
  }

  .opened-user {
    height: auto;
    row-gap: 12px;
    grid-template-rows: repeat(1, 1fr);
  }
`;

export default AddProperty;
