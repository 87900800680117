import styled from 'styled-components';

const QuoteDetailsInfoWrapper = styled.div`
  /* .contact-info {
    background-color: ${({ theme }) => theme.natural_50};
  } */

  .no-focus {
    :focus {
      border: unset;
      box-shadow: unset !important;
    }
  }

  .quote-detail-item {
    height: 100%;
    padding: 0 4px;
    min-height: 24px;

    &:hover {
      background-color: ${({ theme }) => theme.natural_100};
    }
  }
  @media (max-width: 1024px) {
    .quote-date-field {
      padding: 0;
    }
  }
  .quote-info-sec-top {
    display: grid;
    grid-template-columns:
      minmax(100px, 220px) 10px minmax(100px, 220px) 10px minmax(100px, 220px) 10px
      minmax(200px, 1fr);
    column-gap: 32px;
    @media (max-width: 1440px) {
      grid-template-columns:
        minmax(90px, 210px) 10px minmax(90px, 210px) 10px minmax(90px, 210px) 10px
        minmax(292px, 1fr);
      .edit-template-quote {
        width: 100%;
      }
    }
    @media (max-width: 1200px) {
      grid-template-columns:
        minmax(90px, 210px) 10px minmax(90px, 210px) 10px minmax(90px, 210px) 10px
        minmax(230px, 1fr);
      gap: 20px;

      .template-edit-dropdown .css-6pstop-container,
      .edit-template-quote {
        width: 152px;
      }
    }
    @media (max-width: 1024px) {
      overflow: scroll !important;
      grid-template-columns:
        minmax(215px, 200px) 10px minmax(155px, 200px) 10px minmax(155px, 200px) 10px
        minmax(230px, 1fr);
    }
  }
  .quote-info-sec-bottom {
    display: grid;
    grid-template-columns: minmax(116px, 6fr) minmax(100px, 4fr);
    column-gap: 16px;
  }
  .show-calendar {
    box-shadow: 0px 0px 0px 4px ${({ theme }) => theme.focus_border} !important;
    label {
      border-radius: 6px;
    }
  }
  .line-vertical {
    width: 1px;
    height: 24px;
    background-color: ${({ theme }) => theme.natural_200};
  }
  .quote-date-field.date-value {
    border: none;
    min-height: auto;
    border-radius: 0%;
    box-shadow: none;
    padding: 0 4px;
    min-height: 24px;
    border-radius: 6px;
    &:hover {
      background-color: ${({ theme }) => theme.natural_100};
    }
  }
`;

export default QuoteDetailsInfoWrapper;
