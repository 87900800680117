import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { capitalize, formatText, getFormattedDate } from '../../../helpers/utils';

const AutomationLogEventDetails = () => {
  const { t } = useTranslation();
  const { automationLogDetails: automationLog } = useSelector(state => state.automations);
  return (
    <>
      <div className="flex items-center border-bottom px-3 pb-3">
        <label className="flex-1 font-18 inter-600-text natural-900-text">{t('EVENT')}</label>
      </div>
      <div className="flex-column flex-1 mt-4">
        <div className="px-3 py-2 flex-column">
          <span className="font-12 inter-400-text natural-400-text line-height-20">{t('NAME')}</span>
          <p className="font-14 inter-400-text natural-900-text line-height-20 py-0_5 ">
            {capitalize(formatText(automationLog?.event?.event_type, ' '))}
          </p>
        </div>
        <div className="px-3 py-2 flex-column">
          <span className="font-12 inter-400-text natural-400-text line-height-20">{t('ID')}</span>
          <p className="font-14 inter-400-text natural-900-text line-height-20 py-0_5 ">{automationLog?.event?.id}</p>
        </div>
        <div className="px-3 py-2 flex-column">
          <span className="font-12 inter-400-text natural-400-text line-height-20">{t('DATE')}</span>
          <p className="font-14 inter-400-text natural-900-text line-height-20 py-0_5 ">
            {getFormattedDate(automationLog?.event?.created_on, 'DD/MM/YY - hh:mm a')}
          </p>
        </div>
      </div>
    </>
  );
};

export default AutomationLogEventDetails;
