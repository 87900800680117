import { nanoid } from 'nanoid';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputElement from '../../components/common/input';
import SearchableDropdown from '../../components/common/searchable-dropdown';
import { capitalize, formatText } from '../../helpers/utils';
import { getCampaignsList } from '../../store/features/campaignSlice';
import { getJobTypeList, getProjectList, getStagesListData, getWorkflowList } from '../../store/features/jobSlice';
import { getPropertyContacts } from '../../store/features/propertySlice';

const JobsDetails = props => {
  const [searchJob, setSearchJob] = useState('');
  const [searchProject, setSearchProject] = useState('');
  const [searchCampaign, setSearchCampaign] = useState('');
  const [searchedWorkflow, setSearchedWorkflow] = useState('');
  const [searchedContacts, setSearchedContacts] = useState('');
  const [startAt, setStartAt] = useState('');
  const { job, setJob, error, setError, property } = props;
  const { id: propertyId } = property || {};
  const { job_type, job_name, campaign_type, project, workflow, workflowStartAt } = job;

  const descriptionRef = useRef();
  const workflowClearKeyRef = useRef(nanoid());
  const { t } = useTranslation();

  const onJobUpdate = (key, value) => {
    let additionalUpdate = {};
    if (key === 'job_type' || key === 'project') {
      additionalUpdate = {
        workflow: null,
      };
      workflowClearKeyRef.current = nanoid();
    }
    if (key === 'project') {
      additionalUpdate = {
        job_type: null,
      };
    }
    if (key === 'workflow') {
      additionalUpdate = {
        workflowStartAt: null,
      };
    }
    if (key === 'primary_contact') {
      additionalUpdate = {
        primary_contact: null,
      };
    }
    setJob({ ...job, ...additionalUpdate, [key]: value });
    setError({ ...error, [key]: error?.[key] && !value });
  };

  useEffect(() => {
    const target = descriptionRef.current;
    if (target) {
      handleJobDescription(target.value, target, false);
    }
  }, []);

  const handleJobDescription = (value, ele, update = true) => {
    if (value) {
      const scrollHeight = ele.scrollHeight;
      ele.style.height = scrollHeight + 'px';
    } else {
      ele.style.removeProperty('height');
    }
    if (update) {
      onJobUpdate('job_description', value);
    }
  };

  const renderContactInfo = (contacts, contactType) => {
    if (contacts && contacts.length > 0) {
      const filteredContacts = contactType
        ? contacts.filter(contact => contact.contact_type === contactType)
        : contacts;

      return (
        <>
          {filteredContacts.map(contact => (
            <div key={contact.id}>
              <span className={'inter-400-text natural-700-text font-12 option-text'}>{contact.value}</span>
              {contact?.formatted_address && (
                <div className="title-one-line one-line">
                  <span key={contact.id} className={'inter-400-text natural-700-text font-12 option-text '}>
                    {contact?.formatted_address}
                  </span>
                </div>
              )}
            </div>
          ))}
        </>
      );
    }
    return null;
  };

  const CustomOption = props => {
    const { innerProps, data } = props;

    return (
      <div className={'w-full px-4 py-3 cursor border-bottom option-wrapper'} {...innerProps}>
        <div className="flex items-center justify-between pb-1_5 gap-3 select-primary-contact">
          <span className={'inter-400-text natural-900-text font-14 option-text'}>
            {data.contact_type === 'COMPANY' ? data.company_name : data.label}
          </span>
          <div className="bg-pink radius-4 px-3 py-0_5 line-height-1 ">
            <label className={'inter-400-text color-purple font-12 option-text line-height-20'}>
              {capitalize(formatText(data?.property_contact_type))}
            </label>
          </div>
        </div>
        <div className="flex items-center gap-2">
          {renderContactInfo(data.emails, null)}
          <div style={{ width: '2px', height: '12px', background: '#D4D4D4' }}></div>
          {renderContactInfo(data.phones, 'MOBILE')}
          <div style={{ width: '2px', height: '12px', background: '#D4D4D4' }}></div>
          {renderContactInfo(data.phones, 'LANDLINE')}
        </div>
        <div>
          <span className={'inter-400-text natural-900-text font-14 option-text'}>
            {renderContactInfo(data.addresses, null)}
          </span>
        </div>
      </div>
    );
  };

  const CustomOptionSelect = props => {
    const { data, ...otherProps } = props;

    return (
      <div className={'w-full  cursor select-option-primary'} {...otherProps}>
        <div className="flex items-center justify-between w-full pr-3 gap-3 select-primary-contact absolute input-center">
          <span className={'inter-400-text natural-900-text font-14 option-text'}>
            {data.contact_type === 'COMPANY' ? data.company_name : data.label}
          </span>
          <div className="bg-pink radius-4 px-3 py-0_5 line-height-1 mr-5">
            <label className={'inter-400-text color-purple font-12 option-text line-height-20'}>
              {capitalize(formatText(data?.property_contact_type))}
            </label>
          </div>
        </div>
      </div>
    );
  };

  const PrimaryContacts = ({}) => {
    return (
      <SearchableDropdown
        isClearable
        inputValue={searchedContacts}
        onInputChange={setSearchedContacts}
        className="w-full"
        placeholder={t('SELECT_PRIMARY_CONTACT')}
        name={t('PRIMARY_CONTACT')}
        value={job?.primary_contact}
        customClass={false}
        onChange={option => onJobUpdate('primary_contact', option)}
        isSearchable={true}
        isCustomSearchable={false}
        defaultAdditional={{
          page: 0,
          fetchFunction: getPropertyContacts,
          payload: { property_id: propertyId },
          pageable: false,
        }}
        customComponent={{ Option: CustomOption, SingleValue: CustomOptionSelect }}
        error={error?.primary_contact && !job?.primary_contact}
      />
    );
  };

  return (
    <div>
      <div className="">
        <p className="inter-600-text natural-900-text font-16 mb-6">{t('ADD_NEW_JOB')}</p>
        <div className="flex-column row-gap-6">
          <InputElement
            className="w-full"
            name={t('JOB_NAME')}
            sub_name={t('OPTIONAL')}
            placeholder={t('ENTER_JOB_NAME')}
            value={job_name}
            onChange={value => onJobUpdate('job_name', value)}
            error={error.job_name && !job.job_name}
          />
          <SearchableDropdown
            isClearable
            inputValue={searchProject}
            onInputChange={setSearchProject}
            className="w-full"
            placeholder={t('SELECT_PROJECT')}
            name={t('PROJECT')}
            value={project}
            onChange={option => onJobUpdate('project', option)}
            isSearchable={true}
            defaultAdditional={{ page: 0, fetchFunction: getProjectList, pageable: false }}
            error={error.project && !job.project}
          />
          <SearchableDropdown
            isClearable
            inputValue={searchCampaign}
            onInputChange={setSearchCampaign}
            className="w-full"
            sub_name={t('OPTIONAL')}
            placeholder={'Select campaign'}
            name={t('CAMPAIGN')}
            value={campaign_type}
            onChange={option => onJobUpdate('campaign_type', option)}
            isSearchable={true}
            defaultAdditional={{
              page: 0,
              fetchFunction: getCampaignsList,
              pageable: false,
            }}
          />
          <SearchableDropdown
            key={project?.id}
            isClearable
            inputValue={searchJob}
            onInputChange={setSearchJob}
            className="w-full"
            placeholder={t('SELECT_JOB_TYPE')}
            name={t('JOB_TYPE')}
            value={job_type}
            onChange={option => onJobUpdate('job_type', option)}
            isSearchable={true}
            isDisabled={project ? false : true}
            defaultAdditional={{
              page: 0,
              fetchFunction: getJobTypeList,
              pageable: false,
              params: { project_id: project?.id },
            }}
            error={error.job_type && !job.job_type}
          />
          <SearchableDropdown
            isClearable
            inputValue={searchedWorkflow}
            onInputChange={setSearchedWorkflow}
            className="w-full"
            placeholder={t('SELECT_WORKFLOW')}
            name={t('WORKFLOW')}
            value={workflow}
            menuPlacement="top"
            onChange={option => onJobUpdate('workflow', option)}
            isSearchable={true}
            defaultAdditional={{
              page: 0,
              fetchFunction: getWorkflowList,
              params: { project_id: project?.id, job_type_id: job_type?.id },
              pageable: false,
            }}
            error={error?.workflow && !job?.workflow}
          />
          <SearchableDropdown
            isClearable
            isCustomSearchable={false}
            inputValue={startAt}
            key={workflow?.id}
            onInputChange={setStartAt}
            className="w-full"
            placeholder={t('SELECT')}
            name={t('STAGE')}
            value={workflowStartAt}
            menuPlacement="top"
            onChange={option => onJobUpdate('workflowStartAt', option)}
            isSearchable={true}
            defaultAdditional={{
              page: 0,
              fetchFunction: getStagesListData,
              payload: { workflowId: workflow?.id || 'noId' },
              params: { stage_type: 'CREATOR,TRANSITION' },
              pageable: false,
              formatOptions: options => {
                const formattedOptions = options.reduce((acc, curr) => {
                  const { decisions, stage_type, name, id } = curr;
                  const newDecisions = decisions
                    ? decisions.map(d => ({
                        ...d,
                        label: stage_type === 'CREATOR' ? d.stage.name : name,
                        stage_type,
                        stage_id: id,
                      }))
                    : [];
                  return [...acc, ...newDecisions];
                }, []);
                return formattedOptions;
              },
            }}
            isDisabled={workflow ? false : true}
          />
          <PrimaryContacts />
        </div>
      </div>
    </div>
  );
};

export default JobsDetails;
