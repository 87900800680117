import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { Fragment, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as MasqueradeIcon } from '../../assets/icons/masquerade.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { setItemInLocalStorage } from '../../helpers/localstorage';
import { getContactValueByType } from '../../helpers/utils';
import { useFilteredMenuList } from '../../hooks/useFilteredMenuList';
import { onAccessAccount } from '../../store/features/accountsSlice';
import { addToast } from '../../store/features/toastSlice';
import { setUser } from '../../store/features/userSlice';
import Button from '../common/button/button';
import MenuV2 from '../common/menu/menu-v2';

const AccountsListItem = ({ account = {}, account_type, menuPortal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { user } = useSelector(state => state.user);
  const { hasPermission } = useContext(OrganisationContext);

  const { id: accountId, name, primary_location, contact_details, owners, category } = account;

  const [loading, setLoading] = useState(false);

  const onAccountsClick = () => {
    // TODO: Redirect to account details
  };

  const onAccessAccountClick = accountDetails => {
    const account_id = accountDetails.id;
    setLoading(true);
    dispatch(onAccessAccount({ organization_id: account_id }))
      .then(data => {
        const api_tokens = user.api_tokens;
        const organizations = user.organizations;
        const updatedUser = {
          ...user,
          original_api_tokens: { ...api_tokens },
          original_organizations: organizations,
          api_tokens: { ...data.api_tokens },
          accountDetails: accountDetails,
          isAccessAccount: true,
          organizations: data.organizations,
          selectedAccount: { ...accountDetails },
        };
        setItemInLocalStorage('user', updatedUser);
        dispatch(setUser(updatedUser));
        setLoading(false);
        navigate('/properties');
        dispatch(addToast({ error: false, text: t('ACCOUNT_ACCESSED') }));
      })
      .catch(e => {
        setLoading(false);
        dispatch(addToast({ error: true, text: t('ACCOUNT_CANNOT_ACCESSED') }));
      });
  };

  const renderItem = useCallback((value, labelClassName = 'one-line') => {
    return (
      <div className={classNames('flex items-center overflow-hidden py-4')}>
        <label className={classNames('inter-400-text natural-900-text', labelClassName)}>{value}</label>
      </div>
    );
  }, []);

  const renderPrimaryContact = useCallback(owners => {
    const primaryContact = owners?.[0];
    return primaryContact ? (
      <div className={classNames('flex items-center overflow-hidden py-4')}>
        <label className={classNames('inter-400-text natural-900-text one-line')}>{primaryContact?.name}</label>
      </div>
    ) : (
      <div />
    );
  }, []);

  return (
    <AccountsListItemWrapper
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}
      key={accountId}
      className="data-container py-2 border-bottom cursor"
      onClick={onAccountsClick}>
      {renderItem(name)}
      {renderItem(primary_location?.formatted_address, 'two-lines')}
      {renderItem(getContactValueByType(contact_details, 'contact_type', 'MOBILE', 'contact_value'))}
      {renderItem(getContactValueByType(contact_details, 'contact_type', 'EMAIL', 'contact_value'))}
      {renderPrimaryContact(owners)}
      {renderItem(category?.name, 'two-lines')}
      <MenuV2
        menuList={useFilteredMenuList({ menuList: [{ name: t('DETAILS'), onClick: onAccountsClick }] })}
        menuClassName=" h-fit-content"
        iconClassname="my-4"
        portal={menuPortal}
        ExtraOptions={
          <Fragment>
            {hasPermission('MASQUERADE') && account_type === 'ACCOUNTS' && (
              <div className="pxy-2 border-top">
                <Button
                  size="medium"
                  width="180px"
                  icon={<MasqueradeIcon className="white-text" />}
                  className="primary specified-width"
                  label={t('ACCESS_ACCOUNT')}
                  borderRadius="100px"
                  disabled={loading}
                  onClick={() => onAccessAccountClick(account)}
                />
              </div>
            )}
          </Fragment>
        }
      />
    </AccountsListItemWrapper>
  );
};

const AccountsListItemWrapper = styled(motion.div)`
  min-height: 72px;
  max-height: 72px;

  &:hover {
    background: ${({ theme }) => theme.natural_50};
  }
`;

export default AccountsListItem;
