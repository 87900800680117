import styled from 'styled-components';

const QuoteFileUploadWrapper = styled.div`
  .off-droparea {
    border-color: ${({ theme }) => theme.natural_300};
    background-color: ${({ theme }) => theme.natural_50};
  }
  .on-droparea {
    border-color: ${({ theme }) => theme.primary_500};
    background-color: ${({ theme }) => theme.primary_50};
  }
  .menu-btn {
    width: 24px;
    height: 24px;
  }
  .progress-container {
    height: 4px;
    background-color: ${({ theme }) => theme.natural_200};
    border-radius: 100px;

    .progress-bar {
      background-color: ${({ theme }) => theme.primary_500};
      border-radius: 100px;
    }
  }

  .uploaded-file {
    .uploading {
      height: 48px;
      width: 48px;
      border-radius: 6px;
    }

    .uploaded {
      height: 24px;
      width: 24px;
      border-radius: 2px;
    }
  }

  .edit-file-input {
    .input-element {
      height: 24px;
      padding: 2px 8px;
    }
  }
`;

export default QuoteFileUploadWrapper;
