import React, { Fragment, useContext, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import AccountsList from '../../components/accounts-list';
import PageHeader from '../../components/page-header';
import { OrganisationContext } from '../../context/organisationContext';
import useDebounce from '../../helpers/useDebounceHook';
import { capitalize, formatText } from '../../helpers/utils';

const AccountsPartners = ({ account_type, add_btn_label, manage_permission }) => {
  const { setModal } = useContext(OrganisationContext);

  const [addedAccount, setAddedAccount] = useState(null);
  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, 500);

  const onAddAccount = () => {
    setModal({
      type: 'add-account',
      content: {
        account_type,
        onSuccess: data => setAddedAccount(data),
      },
    });
  };

  return (
    <AccountsPartnersWrapper className="flex-column flex-1">
      <Routes>
        <Route
          index
          element={
            <Fragment>
              <PageHeader
                headerTitle={capitalize(formatText(account_type))}
                showAddNewBtn
                wrapperClassName="px-6 pb-4 pt-5"
                showSearch
                addBtnPermission={manage_permission}
                onAddClick={onAddAccount}
                addButtonLabel={add_btn_label}
                searchText={searchText}
                onSearchChange={setSearchText}
              />
              <AccountsList account_type={account_type} debouncedSearch={debouncedSearch} addedAccount={addedAccount} />
            </Fragment>
          }
        />
      </Routes>
    </AccountsPartnersWrapper>
  );
};

const AccountsPartnersWrapper = styled.div`
  max-height: 100vh;
  overflow: hidden;
`;

export default AccountsPartners;
