import React from 'react';
import { Quill } from 'react-quill';
import styled from 'styled-components';

const fontOptions = ['Arial', 'Comic Sans', 'Courier New', 'Georgia', 'Helvetica', 'Lucida'];

const generateOptions = options =>
  options.map((option, index) => (
    <option key={index} value={option.toLowerCase().replace(/\s/g, '-')}>
      {option}
    </option>
  ));

const fontSizeArr = [
  '12px',
  '13px',
  '14px',
  '15px',
  '16px',
  '17px',
  '18px',
  '20px',
  '21px',
  '22px',
  '24px',
  '25px',
  '26px',
  '28px',
  '29px',
  '30px',
  '32px',
  '33px',
  '34px',
  '36px',
  '37px',
  '38px',
  '40px',
  '41px',
  '42px',
  '44px',
  '45px',
  '46px',
  '48px',
];

var Size = Quill.import('attributors/style/size');
Size.whitelist = fontSizeArr;
Quill.register(Size, true);

const Font = Quill.import('formats/font');
Font.whitelist = ['arial', 'comic-sans', 'courier-new', 'georgia', 'helvetica', 'lucida'];
Quill.register(Font, true);

export const modules = {
  toolbar: {
    container: '#toolbar',
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
};

export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'list',
  'bullet',
  'link',
  'image',
  'color',
];

export const EditorToolbar = () => (
  <EditorWrapper>
    <div id="toolbar">
      <ToolbarGroup>
        <FormatButton className="ql-bold" />
        <FormatButton className="ql-italic" />
        <FormatButton className="ql-underline" />
        <FormatButton className="ql-strike" />
      </ToolbarGroup>
      <VerticalLine />

      <ToolbarGroup>
        <select className="ql-size" defaultValue={'14px'}>
          {generateOptions(fontSizeArr)}
        </select>
        <VerticalLine />
        <select className="ql-font" defaultValue="arial">
          {generateOptions(fontOptions)}
        </select>
      </ToolbarGroup>
      <VerticalLine />
      <ToolAlignImage>
        <FormatButton className="ql-align" value="" />
        <FormatButton className="ql-align" value="justify" />
        <FormatButton className="ql-align" value="center" />
        <FormatButton className="ql-align" value="right" />
      </ToolAlignImage>
      <VerticalLine />

      <ToolAlignImage>
        <FormatButton className="ql-list" value="ordered" />
        <FormatButton className="ql-list" value="bullet" />
      </ToolAlignImage>
      <VerticalLine />

      <ToolAlignImage>
        <FormatButton className="ql-link" />
        <FormatButton className="ql-image" />
      </ToolAlignImage>
    </div>
  </EditorWrapper>
);

const EditorWrapper = styled.div`
  .ql-toolbar.ql-snow {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    align-items: center;
    padding: 12px 20px;
    background: #f5f5f5;
    border: none;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    border-bottom: 1px solid #f5f5f5;
  }

  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    height: 165px;
    overflow: scroll;
  }
  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    border: none !important;
  }
`;

const VerticalLine = styled.span`
  width: 1px;
  height: 24px;
  background-color: ${({ theme }) => theme.natural_200};
`;
const ToolbarGroup = styled.span`
  display: flex;
  gap: 16px;
`;
const ToolAlignImage = styled.span`
  display: flex;
  gap: 12px;
`;

const FormatButton = styled.button`
  width: 18px !important;
  height: 18px !important;
  padding: 0 !important;
`;

export default EditorToolbar;
