import styled from 'styled-components';

export const AutomationLogsListWrapper = styled.div`
  border-radius: 6px;

  .header-container {
    height: 52px;
    background-color: ${({ theme }) => theme.natural_50};
  }

  .header-container,
  .data-container {
    display: grid;
    grid-template-columns: minmax(400px, 1fr) 200px 150px 150px 80px;
    column-gap: 48px;
    padding: 0px 24px;
  }
`;

export const AutomationLogItemWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  top: 0;
  cursor: pointer;
  min-height: 56px;

  .dots-icon {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .status-container {
    padding: 2px 10px;
    max-height: 24px;
  }
  .success {
    background: ${({ theme }) => theme.success_50};
    label {
      color: ${({ theme }) => theme.success_500};
    }
  }
  .cancelled {
    background: ${({ theme }) => theme.natural_50};
    label {
      color: ${({ theme }) => theme.natural_700};
    }
  }
  .pending {
    background: ${({ theme }) => theme.orange_50};
    label {
      color: ${({ theme }) => theme.orange_500};
    }
  }
  .failure {
    background: ${({ theme }) => theme.error_50};
    label {
      color: ${({ theme }) => theme.error_500};
    }
  }
  .partialfail {
    background: ${({ theme }) => theme.additionalRed};
    label {
      color: #ffff;
    }
  }

  .skipped {
    background: ${({ theme }) => theme.additionalYellow2};
    label {
      color: #ffff;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.lightGreyBackground};
  }
`;
