import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import uuid from 'react-uuid';
import Button from '../components/common/button/button';
import { OrganisationContext } from '../context/organisationContext';
import { getItemFromLocalStorage } from '../helpers/localstorage';
import { initModal } from '../helpers/utils';
import { addToast } from '../store/features/toastSlice';
import api from '../store/services';
import { light } from '../styles/theme';
import { AddNewMember } from './modal.styled';

const AddTeamMember = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const id = window.location.href.split('/').pop();
  const { teamMembers: teamMembers } = useSelector(state => state.teamManagement);
  const { setModal } = useContext(OrganisationContext);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectTeam, setSelectTeam] = useState();
  const isAddButtonDisabled = selectedOptions.length === 0 || !selectTeam;
  const customStyles = {
    dropdownIndicator: (base, state) => ({
      ...base,
      display: 'none',
    }),

    menu: baseStyles => ({
      ...baseStyles,
      borderRadius: '16px',
      padding: '8px',
      zIndex: 11,
    }),
    menuList: baseStyles => ({
      ...baseStyles,
      padding: '0px',
      fontSize: '16px',
    }),
    multiValue: baseStyles => ({
      ...baseStyles,
      display: 'flex',
      alignItems: 'center',
      backgroundColor: light.primaryGrey,
      padding: '4px',
      borderRadius: '6px',
      margin: '2px 8px 2px 0px',
    }),
    multiValueLabel: baseStyles => ({
      ...baseStyles,
      fontSize: '14px',
      position: 'relative',
      padding: '0',
    }),
    multiValueRemove: baseStyles => ({
      ...baseStyles,
      display: 'flex',
      alignItems: 'center',
      backgroundColor: light.navBackground,
      padding: '4px',
      borderRadius: '6px',
      marginLeft: '8px',

      ':hover': {
        borderColor: light.white,
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'white' : 'inherit',
      color: state.isSelected ? '#333' : '#666',
      cursor: 'pointer',
      ':hover': {
        backgroundColor: light.primaryGrey,
        borderRadius: '8px',
      },
    }),
  };

  const loadOptions = async inputValue => {
    try {
      const { data } = await api.get(`/users?search=${inputValue}`);
      const users = data.content.map(user => ({
        value: user.id,
        label: user.name,
      }));
      const filteredOptions = users.filter(user => !teamMembers.some(member => member.id === user.value));
      setOptions(filteredOptions);
      if (filteredOptions.length === 0) {
      }
      return filteredOptions;
    } catch (err) {
      return Promise.reject(err);
    }
  };

  const handleSelectChange = selected => {
    setSelectedOptions(selected);
  };

  const onDone = async () => {
    const localUser = getItemFromLocalStorage('user');
    const selectedId = selectedOptions.map(item => ({
      id: item.value,
    }));
    const result = {
      level: selectTeam,
      users: selectedId,
    };
    try {
      await api.post(`/organizations/${localUser?.organizations[0]?.id}/teams/${id}/users`, result);
      dispatch(
        addToast({
          error: false,
          text: t('USER_MEMBER'),
          id: uuid(),
        }),
      );
      setModal(initModal);
    } catch (err) {
      dispatch(
        addToast({
          error: false,
          text: t('SOMETHING_WENT_WRONG'),
          id: uuid(),
        }),
      );
    }
  };

  return (
    <AddNewMember className="flex-column items-center pb-12 px-12 pt-10">
      <label className="bold-text font-24 pb-8">{t('ADD_NEW_MEMBERS')}</label>
      <AsyncSelect
        isMulti
        className={`options-select-container`}
        classNamePrefix="select"
        closeMenuOnSelect={false}
        loadOptions={loadOptions}
        styles={customStyles}
        defaultOptions={options}
        placeholder={t('SEARCH_USERS')}
        onChange={handleSelectChange}
      />
      <div className="flex pt-6 pb-10 gap-3">
        <div
          className={`button flex items-center justify-center medium-text ${
            selectTeam === 'LEADER' ? 'border-blue' : ''
          } `}
          onClick={() => setSelectTeam('LEADER')}>
          {t('TEAM_LEAD')}
        </div>
        <div
          className={`button flex items-center justify-center medium-text ${
            selectTeam === 'MEMBER' ? 'border-blue' : ''
          }`}
          onClick={() => setSelectTeam('MEMBER')}>
          {t('TEAM_MEMBER')}
        </div>
      </div>
      <div className="flex col-gap-6 justify-center mt-10 w-full">
        <Button className={`default flex-1 `} label={t('CANCEL')} onClick={() => setModal(initModal)} size="large" />
        <Button
          className={`${isAddButtonDisabled ? 'disabled ' : 'primary'} flex-1`}
          label={t('ADD')}
          disabled={isAddButtonDisabled}
          onClick={onDone}
          size="large"
        />
      </div>
    </AddNewMember>
  );
};

export default AddTeamMember;
