export const TRANSLATIONS_EN_PROPERTY_QUOTE = {
  ADD_QUOTE: 'Add Quote',
  CREATED: 'Created',
  ISSUED: 'Issued',
  EXP_DATE: 'Exp date',
  NET: 'Net',
  TAX: 'Tax',
  TOTAL: 'Total',
  TOTAL_DISCOUNT: 'Total discount',
  NO_RESULTS_FOUND: 'No results found',
  NOTHING_FOUND_FOR_REQUEST: 'Nothing found for your request',
  NO_QUOTES: 'No quotes',
  NO_QUOTES_AVAILABLE: 'There are currently no quotes available.',
  REMOVE_THIS_QUOTE: 'Remove this quote?',
  REMOVE_THIS_QUOTE_DESCRIPTION:
    'Are you sure you want to remove this quote? Once removed, this quote cannot be recovered.',
  DISCOUNT_BELOW_QUOTE: 'Discount should be below 100%',
  MARGIN_BELOW_QUOTE: 'Margin should be below 100%',
  QUOTE_TEMPLATES: 'Quote Templates',
  QUOTE_DETAILS: 'Quote Details',
  QUOTE_MANAGEMENT: 'Quote Management',
  PROJECTS_MANAGEMENT: 'Projects Management',
  PRODUCTS_MANAGEMENT: 'Products Management',
  PRODUCTS: 'Products',
  ERROR_WHILE_FETCHING_QUOTE_TEMPLATE_LIST: 'Error while fetching quote template list',
  NO_QUOTE_TEMPLATE: 'No quote templates',
  ADD_QUOTE_TEMPLATE: 'New Quote Template',
  NO_QUOTE_TEMPLATE_HERE:
    'There are currently no quote templates here. Please check back later or add a new quote template.',
  TEMPLATE: 'Template',
  PREVIEW_ON: 'Preview on',
  NOT_ISSUED: 'Not issued',
  ERROR_WHILE_CREATING_QUOTE: 'Error while creating quote',
};
