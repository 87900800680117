import { createSlice } from '@reduxjs/toolkit';
import api from '../services';

export const addNewContactSlice = createSlice({
  name: 'newPropety',
  initialState: {},
  reducers: {},
});

export const createContact = payload => async dispatch => {
  const { id, request } = payload;
  try {
    const { data } = await api.post(`/properties/${id}/contacts`, request);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateContact = payload => async dispatch => {
  const { id, contact_id, request } = payload;
  try {
    const { data } = await api.put(`/properties/${id}/contacts/${contact_id}`, request);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const {} = addNewContactSlice.actions;
export default addNewContactSlice.reducer;
