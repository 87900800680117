import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { components } from 'react-select';
import styled from 'styled-components';
import { ReactComponent as CrossIcon } from '../assets/icons/CrossIcons.svg';
import { ReactComponent as SearchIcon } from '../assets/images/search.svg';
import { Avatar } from '../components/common/avatar-group';
import Button from '../components/common/button/button';
import SearchableDropdown from '../components/common/searchable-dropdown';
import Dropdown from '../components/common/select-dropdown';
import { OrganisationContext } from '../context/organisationContext';
import { initModal } from '../helpers/utils';
import { addToast } from '../store/features/toastSlice';
import { addUsersToTeam, getUsers } from '../store/features/userManagementSlice';

const PlaceholderInput = props => {
  const { selectProps } = props;
  return (
    <components.Input
      {...props}
      value={selectProps.inputValue ? selectProps.inputValue : ''}
      placeholder={selectProps.inputValue ? '' : selectProps.placeholder}
    />
  );
};

const viewRoleOptions = [
  { label: 'Team Lead', value: 'LEADER' },
  { label: 'Team Member  ', value: 'MEMBER' },
];

const AddTeamUser = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { setModal, modal } = useContext(OrganisationContext);
  const { onSuccess, teamDetails, teamMembers } = modal?.content || {};

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);

  const isAddButtonDisabled = selectedOptions.length === 0 || selectedOptions?.some(s => !s.level);
  const alreadyAddedTeamMembers = teamMembers?.map(m => m?.user?.id) || [];
  const alreadySelectedUsers = selectedOptions?.map(u => u?.id) || [];

  const handleSelectChange = selected => {
    setSelectedOptions([...selectedOptions, selected]);
  };

  const handleRemoveUser = data => {
    setSelectedOptions([...selectedOptions].filter(o => o.id !== data.id));
  };

  const handleDropdownChange = (option, data) => {
    const newOptions = [...selectedOptions].map(o => (o.id === data.id ? { ...o, level: option } : o));
    setSelectedOptions(newOptions);
  };

  const onDone = async () => {
    const request = selectedOptions.map(item => ({
      user: {
        id: item.value,
      },
      level: item?.level?.value,
    }));
    setLoading(true);
    dispatch(
      addUsersToTeam({
        team_id: teamDetails?.id,
        request: request,
      }),
    )
      .then(data => {
        dispatch(
          addToast({
            error: false,
            title: t('USERS_ADDED'),
            text: `You have successfully added ${selectedOptions?.length} users to ${teamDetails?.name}.`,
            id: nanoid(),
          }),
        );
        setModal(initModal);
        onSuccess && onSuccess();
      })
      .catch(error => {
        dispatch(
          addToast({
            error: false,
            text: t('ERROR_WHILE_ADDING_USER_TO_TEAM'),
            id: nanoid(),
          }),
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <AddNewUser className="flex-column items-center  px-10 py-9">
      <label className="inter-600-text natural-900-text  font-20 pb-7 w-full">{t('ADD_USERS')}</label>
      <div className="user-search-dropdown relative w-full">
        <div className="search-icon absolute o03">
          <SearchIcon />
        </div>
        <SearchableDropdown
          className="options-select-container"
          placeholder="Search for existing users"
          customStyle={{
            control: { width: '100%', height: '32px', borderRadius: '100px', background: '#F5F5F5' },
            valueContainer: { padding: '0 16px' },
          }}
          inputValue={search}
          onInputChange={setSearch}
          onChange={handleSelectChange}
          isSearchable={true}
          isCustomSearchable={false}
          hideSelectedOptions={true}
          closeMenuOnSelect={true}
          defaultAdditional={{ page: 0, fetchFunction: getUsers, pageable: true }}
          customComponent={{ Input: PlaceholderInput }}
          filterOption={option => {
            return !(alreadyAddedTeamMembers.includes(option.value) || alreadySelectedUsers.includes(option.value));
          }}
        />
      </div>
      {selectedOptions?.length > 0 && (
        <div className="mt-12 w-full flex-column row-gap-6">
          {selectedOptions?.map((data, index) => {
            return (
              <div className="flex items-center justify-between py-3" key={data?.id}>
                <div className="flex items-center col-gap-3">
                  <Avatar variant="large" avatar={data} backgroundColorIndex={index} />
                  <div>
                    <p className="inter-400-text font-16 natural-900-text">{data?.label}</p>
                    <p className="inter-400-text font-14 natural-500-text">{data?.email}</p>
                  </div>
                </div>
                <div className="flex items-center col-gap-4">
                  <div>
                    <Dropdown
                      isDisabled={false}
                      options={viewRoleOptions}
                      placeholder={t('SELECT_ROLE')}
                      value={data?.level}
                      onChange={option => handleDropdownChange(option, data)}
                      isSearchable={false}
                      customStyle={{
                        control: { width: '161px', height: '44px', fontSize: '14px', borderRadius: '6px' },
                      }}
                    />
                  </div>
                  <CrossIcon height={20} width={20} className="cursor" onClick={() => handleRemoveUser(data)} />
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className="flex col-gap-6 justify-center mt-10 w-full">
        <Button
          className={classNames('default flex-1 ', loading && 'disabled')}
          disabled={loading}
          label={t('CANCEL')}
          onClick={() => setModal(initModal)}
          size="large"
        />
        <Button
          className={classNames('primary flex-1', (isAddButtonDisabled || loading) && 'disabled')}
          label={t('ADD')}
          disabled={isAddButtonDisabled || loading}
          onClick={onDone}
          size="large"
        />
      </div>
    </AddNewUser>
  );
};

export const AddNewUser = styled.div`
  width: 600px;

  .user-search-dropdown {
    .search-icon {
      top: 14px;
      left: 16px;
      z-index: 999;
    }
    .searchable-select__input-container {
      display: flex !important;
    }
    .searchable-select__value-container {
      margin-left: 20px;
    }
  }
`;

export default AddTeamUser;
