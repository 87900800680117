import classNames from 'classnames';
import React from 'react';
import Loading from '../../assets/images/loading.svg';

const Loader = ({ height = 44, width = 44, wrapperClassName = '' }) => {
  return (
    <div className={classNames('flex items-center justify-center flex-1 my-2 w-full', wrapperClassName)}>
      <img src={Loading} height={height} width={width} />
    </div>
  );
};

export default Loader;
