import { nanoid } from 'nanoid';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Box from '../../../components/common/Box';
import PageHeader from '../../../components/page-header';
import { OrganisationContext } from '../../../context/organisationContext';
import { formatText, uppercase } from '../../../helpers/utils';

const PlatformConfiguration = () => {
  const { checkMultiplePermission, hasPermission } = useContext(OrganisationContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const listPlatformtManagment = [
    // {
    //   managetitle: t('TASK_MANAGEMENT'),
    //   optionConfiguration: [
    //     {
    //       id: nanoid(),
    //       title: t('TASK_GROUPS'),
    //       subtitle: t('MANAGE_EXISTING_TASK_INVITE'),
    //       path: `/settings`,
    //       permissions: [''],
    //     },
    //   ],
    // },
    // {
    //   managetitle: t('WORKFLOW_MANAGEMENT'),
    //   optionConfiguration: [
    //     {
    //       id: nanoid(),
    //       title: t('WORKFLOW'),
    //       subtitle: t('MANAGE_EXISTING_WORKFLOW_INVITE'),
    //       path: `/settings`,
    //       permissions: [''],
    //     },
    //     {
    //       id: nanoid(),
    //       title: t('BOARDS'),
    //       subtitle: t('MANAGE_EXISTING_BOARDS_INVITE'),
    //       path: `/settings`,
    //       permissions: [''],
    //     },
    //   ],
    // },
    // {
    //   managetitle: t('FORM_MANAGEMENT'),
    //   optionConfiguration: [
    //     {
    //       id: nanoid(),
    //       title: t('FORMS'),
    //       subtitle: t('MANAGE_EXISTING_FORM_INVITE'),
    //       path: `/settings`,
    //       permissions: [''],
    //     },
    //   ],
    // },
    hasPermission('QUOTE_TEMPLATES_VIEW') && {
      managetitle: t('QUOTE_MANAGEMENT'),
      optionConfiguration: [
        {
          id: nanoid(),
          title: t('QUOTE_TEMPLATES'),
          subtitle: t('MANAGE_EXISTING_QUOTE_TEMPLATES_INVITE'),
          path: '/settings/platform-configuration/quote-templates',
          permissions: ['QUOTE_TEMPLATES_VIEW'],
        },
      ],
    },
  ];
  return (
    <PlatformConfigurationWrapper className="flex-column flex-1 py-2">
      <PageHeader
        names={[
          { text: t('SETTINGS'), onClick: () => navigate('/settings') },
          { text: t('PLATFORM_CONFIGURATION'), onClick: () => {} },
        ]}
        onAddClick={() => {}}
      />
      <div
        className="flex-column row-gap-8 justify-start w-full h-auto content-box radius-4 pxy-6 mxy-6"
        style={{ width: 'calc(100% - 48px)' }}>
        <div className="border-bottom pb-3">
          <label className="inter-600-text natural-900-text font-20">{t('PLATFORM_CONFIGURATION')}</label>
        </div>
        <div className="flex-column items-start justify-start flex-1 row-gap-52px">
          {listPlatformtManagment.map((data, index) => (
            <div className="flex-column w-full row-gap-3" key={index}>
              <label className="inter-600-text font-12 line-height-20 natural-500-text letter-spacing-1">
                {uppercase(formatText(data?.managetitle))}
              </label>
              {data?.optionConfiguration?.map(dataConfig => (
                <div
                  key={dataConfig.id}
                  className={`w-full  ${dataConfig.disabled ? 'disabled' : ''}`}
                  onClick={() =>
                    checkMultiplePermission(() => navigate(`${dataConfig?.path}`), dataConfig.permissions)
                  }>
                  <Box title={dataConfig.title} subTitle={dataConfig.subtitle} />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </PlatformConfigurationWrapper>
  );
};

const PlatformConfigurationWrapper = styled.div`
  .content-box {
    border: 1px solid ${({ theme }) => theme.lightGrey};
  }
  .subtitle-text {
    color: ${({ theme }) => theme.greyText2};
  }
`;

export default PlatformConfiguration;
