import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { ReactComponent as AlertIcon } from '../../assets/icons/AlertIcon.svg';
import { ReactComponent as AlertInfoIcon } from '../../assets/icons/AlertInfoIcon.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/CrossIcons.svg';
import { ReactComponent as MailIcon } from '../../assets/images/mail.svg';
import { ReactComponent as MobileIcon } from '../../assets/images/mobile.svg';
import Button from '../../components/common/button/button';
import { OrganisationContext } from '../../context/organisationContext';
import { getItemFromLocalStorage } from '../../helpers/localstorage';
import { initModal } from '../../helpers/utils';
import { deleteUserFromTeam } from '../../store/features/teamsManagmentSlice';
import { addToast } from '../../store/features/toastSlice';

const TeamUserActions = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { modal, setModal } = useContext(OrganisationContext);
  const { teamuser, isDelete, title, description, team } = modal.content;

  const [loading, setLoading] = useState(false);

  const onConfirmAction = async () => {
    const localUser = getItemFromLocalStorage('user');
    const organization_id = localUser?.organizations[0]?.id;

    if (isDelete) {
      setLoading(true);
      dispatch(deleteUserFromTeam({ organization_id: organization_id, team_id: team.id, user_id: teamuser.id }))
        .then(data => {
          dispatch(
            addToast({
              error: false,
              title: t('TEAM_MEMBER_DELETED'),
              text: t('TEAM_MEMBER_DELETED_SUCCESSFULLY', { value: teamuser?.name }),
              id: nanoid(),
            }),
          );
          setLoading(false);
          setModal(initModal);
        })
        .catch(error => {
          dispatch(
            addToast({
              error: true,
              text: t('ERROR_REMOVING_MAMBER_TEAM'),
              id: nanoid(),
            }),
          );
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <TeamActionsWrapper className="flex-column items-center pxy-6 gap-8">
        <div className="flex-column gap-5 content-wrapper items-center relative">
          {isDelete ? <AlertIcon /> : <AlertInfoIcon />}
          <CrossIcon className="absolute right-0 cursor" width={20} height={20} onClick={() => setModal(initModal)} />
          <div className="flex-column gap-2 items-center justify-start w-full">
            <p className="font-18 text-center inter-500-text natural-900-text">{title}</p>
            <span className="inter-400-text font-14 natural-500-text text-center">{description}</span>
          </div>
        </div>

        <div className="px-3 py-4 flex-column gap-3 w-full radius-1_5 border white-background team-details-container">
          <div className="flex-column text-start">
            <div className="w-full pb-4">
              <label className="font-16 inter-600-text natural-900-text line-height-24">{teamuser?.name}</label>
              <div className="flex items-center justify-start gap-1">
                <label className="font-14 inter-400-text natural-500-text line-height-20">{t('NO_ROLE')}</label>
              </div>
            </div>
            <div className="flex-column gap-2">
              <div className="flex items-center justify-start w-full gap-2">
                <div className="flex items-center justify-center icon-wrapper w-24px h-24px">
                  <MailIcon className="flex items-center justify-center " height={12} width={14} />
                </div>
                <label
                  className={`inter-400-text line-height-20 one-line font-14 ${
                    !teamuser?.email ? 'natural-900-text' : ''
                  }`}>
                  {teamuser?.email ? teamuser?.email : t('NOT_PROVIDED')}
                </label>
              </div>

              <div className="flex items-center justify-start w-full gap-2">
                <div className="flex items-center justify-center icon-wrapper w-24px h-24px">
                  <MobileIcon className="flex items-center justify-center " height={12} width={14} />
                </div>
                <label
                  className={`inter-400-text line-height-20 one-line font-14 ${
                    !teamuser.phone ? 'natural-900-text' : ''
                  }`}>
                  {teamuser?.phone ? teamuser?.phone : t('NOT_PROVIDED')}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="action-wrapper flex-column justify-center w-full gap-3">
          <Button
            className={classNames('primary', isDelete && 'negative', loading && 'disabled')}
            disabled={loading}
            label={`${isDelete ? t('REMOVE') : t('CONFIRM')}`}
            onClick={onConfirmAction}
            primary={true}
            size="large"
            borderRadius="100px"
          />
          <Button
            className={classNames('primary-grey', loading && 'disabled')}
            label={t('CANCEL')}
            disabled={loading}
            onClick={() => setModal(initModal)}
            size="large"
            borderRadius="100px"
          />
        </div>
      </TeamActionsWrapper>
    </CSSTransition>
  );
};

const TeamActionsWrapper = styled.div`
  width: 384px;

  .delete-btn {
    background: ${({ theme }) => theme.additionalRed};
    border: 1px solid ${({ theme }) => theme.additionalRed};
  }

  .delete-btn {
    background: ${({ theme }) => theme.additionalRedDark};
    border: 1px solid ${({ theme }) => theme.additionalRedDark};
  }
`;

export default TeamUserActions;
