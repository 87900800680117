import classNames from 'classnames';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { OrganisationContext } from '../../../context/organisationContext';

const Switch = ({ enabled, onClick, permission = '', disabled = false, switchWrapperClassName = '' }) => {
  const { checkPermission } = useContext(OrganisationContext);

  const onSwitchChange = () => {
    checkPermission(() => {
      onClick && onClick();
    }, permission);
  };

  return (
    <SwitchWrapper>
      <div
        className={classNames(
          'flex items-center cursor switch ml-1',
          enabled && 'switch-selected',
          disabled ? 'not-allowed' : 'cursor',
          switchWrapperClassName,
        )}
        onClick={e => {
          e.stopPropagation();
          onSwitchChange();
        }}>
        <div className="selector" />
      </div>
    </SwitchWrapper>
  );
};

const SwitchWrapper = styled('div')`
  .switch {
    width: 40px;
    height: 24px;
    background: ${({ theme }) => theme.lightGrey};
    border-radius: 12px;

    &:hover {
      background: ${({ theme }) => theme.lightGrey};
    }

    .selector {
      min-width: 20px;
      min-height: 20px;
      background: #ffffff;
      border-radius: 50%;
      margin: 0 2px;
      cursor: pointer;
      transition: transform 400ms;
    }
  }

  .switch-selected {
    background: ${({ theme }) => theme.selectedText};

    &:hover {
      background: ${({ theme }) => theme.selectedText};
    }

    .selector {
      transform: translate(16px);
    }
  }
`;

export default Switch;
