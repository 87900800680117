import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as CrossIcon } from '../../../assets/icons/CrossIcons.svg';
import { ReactComponent as FilterIcon } from '../../../assets/icons/filter.svg';
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/folder-outline.svg';
import { ReactComponent as UserIcon } from '../../../assets/icons/user-outline.svg';
import { ReactComponent as JobIcon } from '../../../assets/images/jobs-outline.svg';
import { getAccountsListItems } from '../../../store/features/accountsSlice';
import { getCampaignsList } from '../../../store/features/campaignSlice';
import { getJobTypeList, getProjectList } from '../../../store/features/jobSlice';
import { addToast } from '../../../store/features/toastSlice';
import { getUsers } from '../../../store/features/userManagementSlice';
import Accordion from '../../common/Accordion';
import Button from '../../common/button/button';
import Checkbox from '../../common/checkbox';
import InputSearch from '../../common/input-search';
import SearchableDropdown from '../../common/searchable-dropdown';
import CustomTooltip from '../../common/tooltip-new';

const BoardsFilter = ({
  productDrawStatusFilter,
  boardsFilters,
  setBoardsFilters,
  owners,
  setOwners,
  avatars,
  onApplyFilters = () => {},
  onClearFilters = () => {},
}) => {
  const { t } = useTranslation();
  const { boardList, boardAccessList, workitemDetails, accountsList } = useSelector(state => state.board);
  const { userOrganization } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const [search, setSearchText] = useState('');
  const [searchProjects, setSearchProjects] = useState('');
  const [searchJobType, setSearchJobType] = useState('');
  const [searchCampaign, setSearchCampaign] = useState('');
  const [projectList, setProjectList] = useState([]);
  const [jobTypeList, setJobTypeList] = useState([]);
  const [campaignList, setCampaignList] = useState([]);
  const [isFiltersOpen, setFilterOpen] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [filters, setFilters] = useState({});

  function updateFilters(key, value) {
    setFilters(prevFilters => {
      const newFilters = { ...prevFilters };
      if (Array.isArray(newFilters[key])) {
        if (newFilters[key].includes(value)) {
          newFilters[key] = newFilters[key].filter(item => item !== value);
        } else {
          newFilters[key] = [...newFilters[key], value];
        }
      } else {
        newFilters[key] = newFilters[key] === value ? null : value;
      }
      return newFilters;
    });
  }

  const applyFilters = () => {
    setBoardsFilters(filters);
    onApplyFilters();
    setFilterOpen(false);
  };

  const fetchProjectList = (debouncedSearch, showLoading = true) => {
    setShowLoading(showLoading);
    dispatch(
      getProjectList({
        params: {
          page: 0,
          search: debouncedSearch,
        },
      }),
    )
      .then(data => {
        setProjectList(data);
        setShowLoading(false);
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_PROJECT_LIST'), id: nanoid() })),
          setProjectList([]);
      });
  };

  const fetchJobTypeList = (debouncedSearch, showLoading = true) => {
    setShowLoading(showLoading);
    dispatch(
      getJobTypeList({
        params: {
          page: 0,
          search: debouncedSearch,
        },
      }),
    )
      .then(data => {
        setJobTypeList(data);
        setShowLoading(false);
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_PROJECT_LIST'), id: nanoid() })),
          setJobTypeList([]);
      });
  };

  const fetchCampaignList = (debouncedSearch, showLoading = true) => {
    setShowLoading(showLoading);
    dispatch(
      getCampaignsList({
        params: {
          page: 0,
          search: debouncedSearch,
        },
      }),
    )
      .then(data => {
        setCampaignList(data);
        setShowLoading(false);
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_PROJECT_LIST'), id: nanoid() })),
          setCampaignList([]);
      });
  };

  useEffect(() => {
    fetchProjectList(searchProjects, false);
  }, [searchProjects]);

  useEffect(() => {
    fetchJobTypeList(searchJobType, false);
  }, [searchJobType]);

  useEffect(() => {
    fetchCampaignList(searchCampaign, false);
  }, [searchCampaign]);

  const Filters = () => (
    <BoardsFilterWrapper className="w-full py-5" onClick={e => e.stopPropagation()}>
      <div>
        <div className="flex px-5 mb-6 items-center justify-between">
          <p className="inter-500-text font-18 natural-900-text">Filter by</p>
          <CrossIcon onClick={() => setFilterOpen(false)} className="cursor natural-700-text" width={22} height={22} />
        </div>
        <div>
          <Accordion
            titleClass="natural-400-text"
            title="Project"
            headerClass="mx-5 mr-1 mt-3"
            showIcon={ArrowDownIcon}>
            <div className="px-4 py-5 bg-natural-50 mt-3">
              <InputSearch
                className={'mb-3'}
                placeholder={'Search'}
                value={searchProjects}
                onChange={setSearchProjects}
              />
              <div className="checkbox-content">
                {projectList?.map(project => {
                  return (
                    <div className="flex items-center gap-2 mb-4" key={project?.id}>
                      <Checkbox
                        is_checked_done={true}
                        checked={filters?.project_id === project?.id || false}
                        onChange={() => updateFilters('project_id', project?.id)}
                        id={`project-${project?.id}`}
                      />
                      <label htmlFor={`project-${project?.id}`} className="no-select inter-400-text font-16">
                        {project?.name}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </Accordion>
        </div>
        <div>
          <Accordion
            titleClass="natural-400-text"
            title={t('SELECT_JOB_TYPE')}
            headerClass="mx-5 mr-1 mt-3"
            showIcon={JobIcon}>
            <div className="px-4 py-5 bg-natural-50 mt-3">
              <InputSearch
                className={'mb-3'}
                placeholder={'Search'}
                value={searchJobType}
                onChange={setSearchJobType}
              />
              <div className="checkbox-content">
                {jobTypeList?.map(job => {
                  return (
                    <div className="flex items-center gap-2 mb-4" key={job?.id}>
                      <Checkbox
                        is_checked_done={true}
                        checked={filters?.job_type_id === job?.id || false}
                        onChange={() => updateFilters('job_type_id', job?.id)}
                        id={`job-${job?.id}`}
                      />
                      <label htmlFor={`job-${job?.id}`} className="no-select inter-400-text font-16">
                        {job?.name}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </Accordion>
        </div>
        <div>
          <Accordion
            titleClass="natural-400-text"
            title="Campaign"
            headerClass="mx-5 mr-1 mt-3"
            showIcon={ArrowDownIcon}>
            <div className="px-4 py-5 bg-natural-50 mt-3">
              <InputSearch
                className={'mb-3'}
                placeholder={'Search'}
                value={searchCampaign}
                onChange={setSearchCampaign}
              />
              <div className="checkbox-content">
                {campaignList?.map(campaign => {
                  return (
                    <div className="flex items-center gap-2 mb-4" key={campaign?.id}>
                      <Checkbox
                        is_checked_done={true}
                        checked={filters?.campaign_id === campaign?.id || false}
                        onChange={() => updateFilters('campaign_id', campaign?.id)}
                        id={`campaign-${campaign?.id}`}
                      />
                      <label htmlFor={`campaign-${campaign?.id}`} className="no-select inter-400-text font-16">
                        {campaign?.name}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </Accordion>
        </div>

        <div className="mb-7">
          <Accordion
            titleClass="natural-400-text"
            title="Lead source"
            contentClass="overflow-revert"
            headerClass="mx-5 mr-1 mt-3"
            showIcon={UserIcon}>
            <div className="px-4 py-5 bg-natural-50 mt-3 flex flex-column gap-2">
              <SearchableDropdown
                isSearchable={true}
                isCustomSearchable={false}
                isClearable
                inputValue={search}
                onInputChange={setSearchText}
                className="w-full"
                name="Organisation"
                placeholder={t('SELECT_OR_TYPE_ORGANISATION')}
                showIconInOption={false}
                value={filters?.lead_organisation}
                onChange={option => updateFilters('lead_organisation', option)}
                defaultAdditional={{
                  page: 0,
                  fetchFunction: getAccountsListItems,
                  payload: { organization_id: userOrganization?.id },
                  params: {
                    category_tag: 'LEAD_GENERATION',
                  },
                  pageable: false,
                }}
              />
              <CustomTooltip
                id={'toggle-overlay'}
                place="left"
                wrapperClassName="tooltip-wrapper"
                content={
                  !filters?.lead_organisation && (
                    <span className="inter-400-text font-12">{t('PLEASE_SELECT_ORGANISATION_FIRST')}</span>
                  )
                }>
                <SearchableDropdown
                  isSearchable={false}
                  isClearable
                  inputValue={search}
                  onInputChange={setSearchText}
                  className="w-full"
                  placeholder={t('SELECT_OR_TYPE_USER')}
                  showIconInOption={false}
                  value={filters?.lead_user}
                  onChange={option => updateFilters('lead_user', option)}
                  isDisabled={filters?.lead_organisation ? false : true}
                  defaultAdditional={{
                    page: 0,
                    fetchFunction: getUsers,
                    params: {
                      role_tag: 'LEAD_GENERATION',
                      organization_id: filters?.lead_organisation?.id,
                    },
                    pageable: true,
                  }}
                />
              </CustomTooltip>
            </div>
          </Accordion>
        </div>
        <div className="px-5 flex items-center justify-between">
          <Button
            className={classNames('primary-grey specified-width')}
            label={'Clear all'}
            onClick={() => {
              setFilterOpen(false);
              onClearFilters();
            }}
            size="large"
            borderRadius="100px"
            width="100px"
            height="40px"
          />

          <Button
            className={classNames('primary specified-width')}
            label={'Apply filters'}
            onClick={applyFilters}
            size="large"
            borderRadius="100px"
            width="113px"
            height="40px"
          />
        </div>
      </div>
    </BoardsFilterWrapper>
  );

  const ref = useOnclickOutside(() => setFilterOpen(false));

  return (
    <div ref={ref} onClick={() => setFilterOpen(!isFiltersOpen)}>
      <CustomTooltip
        isOpen={isFiltersOpen}
        content={<Filters />}
        openOnClick={true}
        clickable
        wrapperClassName="owner-tooltip"
        otherClass="p-0"
        id={`boards-filter-tooltip`}
        place="bottom"
        positionStrategy={'fixed'}
        noArrow={false}>
        <div className="flex items-center justify-center h-full py-2 px-4 ml-3 cursor icon-container filter">
          <label className="inter-500-text primary-text mr-1">{t('FILTER')}</label>
          <FilterIcon className="primary-text" />
        </div>
      </CustomTooltip>
    </div>
  );
};

const BoardsFilterWrapper = styled.div`
  max-width: 357px;
  min-width: 357px;
  pointer-events: all;
  .drop-down-filter {
    .select__value-container,
    .searchable-select__placeholder,
    .searchable-select__value-container,
    .searchable-select__indicator {
      padding: 0 !important;
      margin: 0px !important;
    }
  }
  .date-value {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    box-shadow: none;
  }
  .checkbox-content {
    max-height: 150px;
    overflow: auto;
  }
  .overflow-revert {
    overflow: revert;
  }
`;

export default BoardsFilter;
