import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as NoDocumentIcon } from '../../assets/icons/empty-document.svg';
import InfiniteScrollV2 from '../common/infinite-scroll-v2';
import NoData from '../common/no-data';
import SkeletonTransition from '../common/skeleton-transition';
import TableHeader from '../common/table-header';
import ProductListItem from './product-list-items';

const ProductList = ({
  debouncedSearch = '',
  fetchMoreData,
  showLoading,
  productList,
  hasMore,
  isFromAddProduct = false,
  setSelectedProducts,
  selectedProducts = [],
  onDeleteProduct = () => {},
}) => {
  const { t } = useTranslation();

  const productListTableHeaders = useMemo(() => {
    return [
      ...(isFromAddProduct ? [{ name: '', key: 'ITEM_SELECTOR' }] : []),
      { name: 'PREVIEW', key: 'PREVIEW' },
      { name: t('NAME'), key: 'NAME' },
      { name: t('DESCRIPTION'), key: 'DESCRIPTION' },
      { name: 'SKU', key: 'SKU' },
      { name: t('SOLD_AS'), key: 'SOLD AS' },
      { name: t('STATUS'), key: 'STATUS' },
      ...(isFromAddProduct ? [] : [{ name: '', key: 'MENU' }]),
    ];
  }, []);

  return (
    <ProjectListWrapper
      className={classNames('flex-column flex-1 w-full radius-1 overflow-hidden', isFromAddProduct ? 'pt-6' : 'p-6')}>
      <SkeletonTransition
        loading={showLoading}
        loaderClassName={'item flex items-center justify-center flex-1 mb-6'}
        height={'100%'}
        containerClassName="line-height-1 h-full w-full add-product-table">
        {productList?.length > 0 ? (
          <div className="w-full card radius-1_5 border overflow-scroll">
            <div className="flex-column w-fit-content min-w-full h-full">
              <TableHeader
                headers={productListTableHeaders}
                is_cBAChecked_done={true}
                allSelected={selectedProducts.length === productList.length}
                is_checked_done={true}
                onSelectorClick={() => {
                  if (selectedProducts.length === productList.length) {
                    setSelectedProducts([]);
                  } else {
                    setSelectedProducts(productList.map(product => product));
                  }
                }}
              />
              <InfiniteScrollV2 hasMore={hasMore} fetchMoreData={fetchMoreData} containerId="">
                {(productList || []).map((productData, index) => (
                  <ProductListItem
                    product={productData}
                    key={productData?.id}
                    menuPortal={productList?.length < 3}
                    checked={selectedProducts}
                    setChecked={setSelectedProducts}
                    isFromAddProduct={isFromAddProduct}
                    onDeleteProduct={onDeleteProduct}
                  />
                ))}
              </InfiniteScrollV2>
            </div>
          </div>
        ) : debouncedSearch ? (
          <NoData
            className={classNames('flex-1', isFromAddProduct && 'add-product-table')}
            title={t('NO_RESULTS_FOUND')}
            description={t('NO_RESULTS_DESCRIPTION')}
          />
        ) : (
          <div className="flex-column flex-1 items-center justify-center">
            <NoData
              className={classNames('search-project', isFromAddProduct && 'add-product-table')}
              title={t('NO_PRODUCT')}
              description={t('NO_PRODUCTS_HERE')}
              EmptyIcon={NoDocumentIcon}
              iconClassName="relative"
            />
          </div>
        )}
      </SkeletonTransition>
    </ProjectListWrapper>
  );
};

const ProjectListWrapper = styled.div`
  border-radius: 6px;

  .header-container {
    height: 52px;
    background-color: ${({ theme }) => theme.natural_50};
  }

  .search-project {
    max-width: 400px;
  }
`;

export default ProductList;
