import { useGoogleMap } from '@react-google-maps/api';
import classNames from 'classnames';
import React, { Fragment, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as AddRoofIcon } from '../../assets/icons/add-roof.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as EnergyLeafIcon } from '../../assets/icons/energy-leaf.svg';
import { ReactComponent as HandIcon } from '../../assets/icons/hand.svg';
import { ReactComponent as LayoutIcon } from '../../assets/icons/layout.svg';
import { ReactComponent as PanelRotateLandscapeIcon } from '../../assets/icons/panel-rotate-landscape.svg';
import { ReactComponent as PanelRotatePortraitIcon } from '../../assets/icons/panel-rotate-portrait.svg';
import { ReactComponent as PointerIcon } from '../../assets/icons/pointer.svg';
import { ReactComponent as SolarPanelIcon } from '../../assets/icons/solar-panel.svg';
import { getFormattedNumberStyle } from '../../helpers/utils';
import IconContainer from '../common/icon-container';
import CustomTooltip from '../common/tooltip-new';
import Roof from './roof';

const InstrumentTools = () => {
  const map = useGoogleMap();

  const [selectedInstrument, setSelectedInstrument] = useState(null);
  const [newRoofPolygon, setNewRoofPolygon] = useState(null);

  const onInstrumentClick = instrument => {
    setSelectedInstrument(instrument);
  };

  const instrumentTools = useMemo(() => {
    return [
      {
        name: 'Hand',
        icon: HandIcon,
        key: 'HAND',
        onClick: onInstrumentClick,
      },
      {
        name: 'Pointer',
        icon: PointerIcon,
        key: 'POINTER',
        onClick: onInstrumentClick,
      },
      {
        name: 'Rotate portrait',
        icon: PanelRotatePortraitIcon,
        key: 'ROTATE_PORTRAIT',
        onClick: () => {},
        isDisabled: true,
      },
      {
        name: 'Rotate landscape',
        icon: PanelRotateLandscapeIcon,
        key: 'ROTATE_LANDSCAPE',
        onClick: () => {},
        isDisabled: true,
      },
      {
        name: 'Add roof',
        icon: AddRoofIcon,
        key: 'ADD_ROOF',
        onClick: onInstrumentClick,
        isDisabled: !!newRoofPolygon,
      },
      {
        name: 'Delete',
        icon: DeleteIcon,
        key: 'DELETE',
        onClick: onInstrumentClick,
      },
      {
        name: 'Layout',
        icon: LayoutIcon,
        key: 'LAYOUT',
        onClick: onInstrumentClick,
        isDisabled: true,
      },
    ];
  }, [map, newRoofPolygon]);

  return instrumentTools.map(tool => {
    const { key, icon: Icon, name, onClick, isDisabled } = tool;
    const isSelected = selectedInstrument?.key === key;

    return (
      <CustomTooltip
        key={key}
        id={key}
        place="bottom"
        wrapperClassName="instrument-tooltip-wrapper"
        delayShow={200}
        content={<span className="inter-400-text font-12">{name}</span>}>
        <IconContainer
          key={key}
          Icon={Icon}
          iconColor={isDisabled ? 'natural_300' : isSelected ? 'primary_500' : 'natural_500'}
          backgroundColor="natural_100"
          iconWidth={18}
          iconHeight={18}
          onClick={() => onClick(tool)}
          iconContainerClassname={classNames('cursor px-1_5 py-1_5', isDisabled && 'opacity-50 pointer-events-none')}
        />
      </CustomTooltip>
    );
  });
};

const RoofDesignQuote = ({ roofInformation, property_id }) => {
  const [selectedTab, setSelectedTab] = useState('DESIGN');
  const [currentActivePanelCount, setCurrentActivePanelCount] = useState(0);
  const [currentPanelEnergy, setCurrentPanelEnergy] = useState(0);
  const [totalPanels, setTotalPanels] = useState(0);
  const [totalPanelEnergy, setTotalPanelEnergy] = useState(0);

  const tabs = useMemo(() => {
    return [
      {
        name: 'Design',
        key: 'DESIGN',
      },
      {
        name: 'Products',
        key: 'PRODUCTS',
      },
    ];
  }, []);

  return (
    <RoofDesignQuoteWrapper className="absolute">
      <div className="flex-column data-wrapper bg-white radius-2">
        <div className="pxy-2 flex items-center">
          {tabs.map(({ key, name }) => (
            <div
              key={key}
              onClick={() => setSelectedTab(key)}
              className={classNames(
                'pxy-2 flex-1 flex items-center justify-center cursor',
                selectedTab === key ? 'border-bottom-primary' : 'border-bottom',
              )}>
              <label
                className={classNames('inter-600-text', selectedTab === key ? 'primary-500-text' : 'natural-700-text')}>
                {name}
              </label>
            </div>
          ))}
        </div>
        <Fragment>
          <div className="px-4 pb-5 pt-3 flex items-center flex-1 col-gap-4">
            <div className="flex flex-1 items-center col-gap-2 bg-natural-50 radius-3 py-2 px-3">
              <SolarPanelIcon width={40} height={40} className="primary-500-text" />
              <div className="flex-column">
                <label className="inter-600-text natural-500-text font-12">Panels</label>
                <label className="inter-700-text font-16">{totalPanels}</label>
              </div>
            </div>
            <div className="flex flex-1 items-center col-gap-2 bg-natural-50 radius-3 py-2 px-3">
              <EnergyLeafIcon width={40} height={40} className="success-500-text" />
              <div className="flex-column">
                <label className="inter-600-text natural-500-text font-12">Output</label>
                <label className="inter-700-text font-16">{`${getFormattedNumberStyle(
                  currentPanelEnergy || 0,
                  'decimal',
                  0,
                  0,
                )} kWh`}</label>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-evenly border-top px-3 py-4">
            <InstrumentTools />
          </div>
          <div className="px-4 py-4 border-top flex-column row-gap-4 overflow-scroll custom-scrollbar thin-scrollbar">
            {roofInformation?.map((info, index) => (
              <Roof key={info.id} info={info} index={index} property_id={property_id} />
            ))}
          </div>
        </Fragment>
      </div>
    </RoofDesignQuoteWrapper>
  );
};

const RoofDesignQuoteWrapper = styled.div`
  left: 8px;
  top: 8px;
  overflow: hidden;
  height: calc(100% - 16px);
  overflow: hidden;

  .data-wrapper {
    min-width: 396px;
    max-width: 396px;
    height: fit-content;
    max-height: 100%;
    overflow: hidden;
  }

  .dot {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.natural_300};
  }

  .segment-detail-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .selected-segment {
    border: 1px solid ${({ theme }) => theme.focus_border};
    box-shadow: 0px 0px 0px 4px ${({ theme }) => theme.focus_border};
    .dot {
      background-color: ${({ theme }) => theme.primary_500};
    }
  }

  .panel-details {
    .input {
      height: 24px;
      padding: 4px;
      width: 60px;
      font-size: 12px;
      &:focus {
        box-shadow: none;
        border: 1px solid ${({ theme }) => theme.primary_500};
      }
    }
  }

  .input-pitch,
  .input-azimuth {
    .input-element {
      &:focus {
        box-shadow: 0px 0px 0px 2px ${({ theme }) => theme.focus_border};
      }
    }
  }

  .instrument-tooltip-wrapper {
    .custom-tooltip {
      padding: 6px 12px;
      filter: drop-shadow(0px 3px 3px #0000001f);
    }
  }

  .grid-ai-container {
    padding: 2px;
  }
`;

export default RoofDesignQuote;
