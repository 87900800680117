import { configureStore } from '@reduxjs/toolkit';
import toastActionSlice from './features/ToastActionSlice';
import accountsSlice from './features/accountsSlice';
import activitySlice from './features/activitiesSlice';
import automationsSlice from './features/automationsSlice';
import boardSlice from './features/boardSlice';
import campaignSlice from './features/campaignSlice';
import costsSlice from './features/costsSlice';
import downloadSlice from './features/downloadSlice';
import fileuploadSlice from './features/fileuploadSlice';
import integrationsSlice from './features/integrationsSlice';
import navbarSlice from './features/navbarSlice';
import addNewContactSlice from './features/newContactSlice';
import addNewPropertySlice from './features/newPropertySlice';
import notesSlice from './features/notesSlice';
import personsSlice from './features/personsSlice';
import productsSlice from './features/productsSlice';
import propertySlice from './features/propertySlice';
import quotesSlice from './features/quotesSlice';
import reloadSlice from './features/reloadSlice';
import roleUserSlice from './features/roleUserSlice';
import taskSlice from './features/taskSlice';
import teamsManagementSlice from './features/teamsManagmentSlice';
import toastSlice from './features/toastSlice';
import usersManagementSlice from './features/userManagementSlice';
import userSlice from './features/userSlice';
import workitemSlice from './features/workitemSlice';

export default configureStore({
  reducer: {
    reload: reloadSlice,
    toast: toastSlice,
    toastAction: toastActionSlice,
    user: userSlice,
    integrations: integrationsSlice,
    usersManagement: usersManagementSlice,
    teamManagement: teamsManagementSlice,
    automations: automationsSlice,
    persons: personsSlice,
    property: propertySlice,
    task: taskSlice,
    board: boardSlice,
    notes: notesSlice,
    workItem: workitemSlice,
    activities: activitySlice,
    newPropety: addNewPropertySlice,
    download: downloadSlice,
    fileUpload: fileuploadSlice,
    navbar: navbarSlice,
    quotes: quotesSlice,
    accounts: accountsSlice,
    rolesUser: roleUserSlice,
    campaigns: campaignSlice,
    costs: costsSlice,
    newContact: addNewContactSlice,
    products: productsSlice,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.REACT_APP_STAGE === 'development',
});
