import React, { Fragment, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import IntegrationsList from '../../components/integration-list';
import IntegrationsDetail from '../../components/integrations-detail';
import PageHeader from '../../components/page-header';
import { OrganisationContext } from '../../context/organisationContext';
import useDebounce from '../../helpers/useDebounceHook';

const Integrations = () => {
  const dispatch = useDispatch();
  const { setModal } = useContext(OrganisationContext);

  const { integrationDetails } = useSelector(state => state.integrations);

  const [search, setSearchText] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const onAddClick = () => {
    setModal({
      type: 'add-integration',
      content: { top: true },
    });
  };

  return (
    <IntegrationsWrapper className="flex-column flex-1">
      <Routes>
        <Route
          element={
            <IntegrationDetailsWrapper className="flex-column flex-1">
              <PageHeader
                names={[
                  { text: 'Platforms', onClick: () => {} },
                  { text: 'Integrations', path: '/platforms/integrations' },
                  { text: integrationDetails?.connector?.name, onClick: () => {} },
                ]}
              />
              <IntegrationsDetail />
            </IntegrationDetailsWrapper>
          }
          path="/integration-details/:integration_id"
        />
        <Route
          element={
            <Fragment>
              <PageHeader
                names={[
                  { text: 'Platforms', onClick: () => {} },
                  { text: 'Integrations', onClick: () => {} },
                ]}
                showAddNewBtn
                showSearch
                onAddClick={onAddClick}
                addBtnPermission="INTEGRATIONS_MANAGE"
                searchText={search}
                addButtonLabel="Add Integration"
                onSearchChange={setSearchText}
              />
              <IntegrationsList debouncedSearch={debouncedSearch} />
            </Fragment>
          }
          path="/*"
        />
      </Routes>
    </IntegrationsWrapper>
  );
};

export const IntegrationsWrapper = styled.div`
  max-height: 100vh;
  overflow: hidden;
`;

const IntegrationDetailsWrapper = styled.div`
  max-height: 100vh;
  overflow: hidden;
`;

export default Integrations;
