import React from 'react';
import { useTranslation } from 'react-i18next';
import UpdateAction from '../../../common/update-action';

export const EditTeamMemberField = ({
  data,
  onCancelUpdateData,
  onUpdateData,
  setEditField,
  setError,
  error,
  value,
  inputType,
  textAreaType,
}) => {
  const { t } = useTranslation();

  const renderInputOrTextarea = () => {
    const commonProps = {
      onChange: e => {
        setEditField({ ...data, value: e.target.value });
        const errorKey = inputType ? t('NAME') : t('DESCRIPTION');
        setError({ ...error, [errorKey]: false });
      },
      value: value || '',
    };

    return (
      <>
        {inputType && (
          <input autoComplete="turnoff" className="input w-full name-input" {...commonProps} placeholder={t('NAME')} />
        )}
        {textAreaType && (
          <textarea
            className="w-full input textarea"
            rows={4}
            {...commonProps}
            placeholder={t('DESCRIPTION_PLACEHOLDER')}
          />
        )}
      </>
    );
  };

  return (
    <>
      <div className="flex-column row-gap-1 w-full">
        <span className="inter-500-text natural-900-text font-14 mb-1">
          {inputType && t('NAME')} {textAreaType && t('DESCRIPTION')}
        </span>
        {renderInputOrTextarea()}
      </div>

      <UpdateAction
        size="medium"
        onCancel={onCancelUpdateData}
        onUpdate={onUpdateData}
        width="100%"
        height="40px"
        className="update-action specified-width"
      />
    </>
  );
};
