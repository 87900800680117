import { OverlayView, OverlayViewF } from '@react-google-maps/api';
import React from 'react';

const PropertyImageOverlay = ({ propertyImages, showOverlay }) => {
  const propertyImage = propertyImages?.find(image => image.image_type === 'AERIAL');
  const { image, boundary } = propertyImage || {};

  if (image && boundary) {
    return (
      <OverlayViewF
        bounds={
          new google.maps.LatLngBounds(
            new google.maps.LatLng(boundary[2].lat, boundary[3].lon),
            new google.maps.LatLng(boundary[0].lat, boundary[1].lon),
          )
        }
        getPixelPositionOffset={(width, height) => {
          return { x: Math.sin(-width), y: Math.sin(-height) };
        }}
        mapPaneName={OverlayView.MAP_PANE}>
        <div>
          <img
            src={image?.url}
            alt="property"
            height={'100%'}
            width={'100%'}
            style={{ border: '4px solid white', visibility: showOverlay ? 'visible' : 'hidden' }}
          />
        </div>
      </OverlayViewF>
    );
  }

  return null;
};

export default PropertyImageOverlay;
