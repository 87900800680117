import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { Fragment, memo, useCallback, useMemo, useRef, useState } from 'react';
import MarkerPin from '../../../assets/icons/google-map-pin.svg';
import SolarApi from '../../../pages/solar-api';
import QuoteSolar from '../../../pages/solar-api/quote-solar';
import RoofEdit from '../../../pages/solar-api/roof-edit';
import RoofEditV2 from '../../../pages/solar-api/roof-edit-v2';

const libraries = ['places', 'geometry', 'drawing'];

const defaultOptions = { streetViewControl: true, mapTypeControl: true, mapTypeId: 'satellite' };
const solarOptions = {
  streetViewControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
  mapTypeId: 'satellite',
  // tilt: 0,
  // rotateControl: false,
};

const defaultLatLng = {
  lat: 50.935174,
  lng: -1.411288,
};

const GoogleMaps = ({
  coordinates = [],
  wrapperClassName,
  isSolar = true,
  isQuoteSolar = false,
  isRoofEdit = false,
  isRoofEditV2 = false,
  additionalOptions = {},
  onClick = () => {},
  ...rest
}) => {
  const options = useMemo(() => {
    return isSolar || isQuoteSolar || isRoofEdit || isRoofEditV2 ? solarOptions : defaultOptions;
  }, []);

  const zoom = useMemo(() => {
    return isSolar || isQuoteSolar || isRoofEdit || isRoofEditV2 ? 20 : 19;
  }, []);

  const center = useMemo(() => {
    return coordinates?.[0]
      ? {
          lat: parseFloat(coordinates?.[0]?.lat),
          lng: parseFloat(coordinates?.[0]?.lon),
        }
      : defaultLatLng;
  }, [coordinates]);

  const centerRef = useRef(center);

  const { isLoaded } = useJsApiLoader({
    libraries: libraries,
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    // mapIds: ['1ccafa48855564be'],
  });

  const [map, setMap] = useState(null);

  const onLoad = useCallback(map => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(map => {
    setMap(null);
  }, []);

  return (
    <motion.div className={classNames('h-full w-full', wrapperClassName)}>
      {isLoaded ? (
        <Fragment>
          <GoogleMap
            center={centerRef.current}
            id="map-google"
            mapContainerStyle={{ width: '100%', height: '100%', borderRadius: '6px' }}
            options={{ ...options, ...additionalOptions, mapId: '1ccafa48855564be' }}
            zoom={zoom}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onClick={onClick}>
            {!isSolar &&
              !isQuoteSolar &&
              !isRoofEdit &&
              !isRoofEditV2 &&
              coordinates?.length > 0 &&
              coordinates?.map((coord, index) => (
                <MarkerF
                  key={index}
                  position={{ lat: parseFloat(coord?.lat), lng: parseFloat(coord?.lon) }}
                  icon={{ url: MarkerPin, anchor: { x: 22, y: 22 } }}
                />
              ))}
            {isSolar && <SolarApi coord={center} map={map} />}
            {isQuoteSolar && <QuoteSolar coord={center} map={map} />}
            {isRoofEdit && <RoofEdit coord={center} {...rest} />}
            {isRoofEditV2 && <RoofEditV2 coord={center} {...rest} />}
          </GoogleMap>
        </Fragment>
      ) : (
        <div />
      )}
    </motion.div>
  );
};

export default memo(GoogleMaps);
