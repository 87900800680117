import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as NoDocumentIcon } from '../../assets/icons/empty-document.svg';
import InfiniteScrollV2 from '../common/infinite-scroll-v2';
import NoData from '../common/no-data';
import SkeletonTransition from '../common/skeleton-transition';
import TableHeader from '../common/table-header';
import ProjectListItem from './project-list-items';

const ProjectList = ({
  debouncedSearch = '',
  onDeleteProject,
  redirectToProjectDetails,
  fetchMoreData,
  showLoading,
  projectList,
}) => {
  const { t } = useTranslation();

  const projectListTableHeaders = useMemo(() => {
    return [
      { name: t('NAME'), key: 'NAME' },
      { name: t('DESCRIPTION'), key: 'DESCRIPTION' },
      { name: t('CODE'), key: 'CODE' },
      { name: '', key: 'MENU' },
    ];
  }, []);

  return (
    <ProjectListWrapper className="flex-column flex-1 p-6 w-full radius-1 overflow-hidden">
      <SkeletonTransition
        loading={showLoading}
        loaderClassName={'item flex items-center justify-center flex-1 mb-6'}
        height={'100%'}
        containerClassName="line-height-1 h-full w-full">
        {projectList?.length > 0 ? (
          <div className="w-full card radius-1_5 border overflow-scroll">
            <div className="flex-column w-fit-content min-w-full h-full">
              <TableHeader headers={projectListTableHeaders} />
              <InfiniteScrollV2 hasMore={!projectList} fetchMoreData={fetchMoreData}>
                {(projectList || []).map((projectData, index) => (
                  <ProjectListItem
                    projects={projectData}
                    key={index}
                    onDeleteProject={onDeleteProject}
                    redirectToProjectDetails={redirectToProjectDetails}
                    menuPortal={projectList?.length < 3}
                  />
                ))}
              </InfiniteScrollV2>
            </div>
          </div>
        ) : debouncedSearch ? (
          <NoData className="flex-1" title={t('NO_RESULTS_FOUND')} description={t('NO_RESULTS_DESCRIPTION')} />
        ) : (
          <div className="flex-column flex-1 items-center justify-center">
            <NoData
              title={t('NO_PROJECTS')}
              description={t('NO_PROJECTS_HERE')}
              className="search-project"
              EmptyIcon={NoDocumentIcon}
              iconClassName="relative"
            />
          </div>
        )}
      </SkeletonTransition>
    </ProjectListWrapper>
  );
};

const ProjectListWrapper = styled.div`
  border-radius: 6px;

  .header-container {
    height: 52px;
    background-color: ${({ theme }) => theme.natural_50};
  }

  .header-container,
  .data-container {
    display: grid;
    grid-template-columns: minmax(200px, 1fr) minmax(200px, 3fr) minmax(200px, 1fr) 80px;
    column-gap: 48px;
    padding: 16px 24px;
  }

  .search-project {
    max-width: 400px;
  }
`;

export default ProjectList;
