import { ReactComponent as RightIcon } from '@assets/images/arrow-right.svg';
import { ReactComponent as CloseIcon } from '@assets/images/toast-close.svg';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Button from '../button/button';
import Checkbox from '../checkbox';
import IconContainer from '../icon-container';
import InputSearch from '../input-search';

const FilterPopover = ({
  setShowFilterPopover,
  isReschedule,
  setResourceType,
  services,
  selectedService,
  setSelectedService,
}) => {
  const { t } = useTranslation();
  const [isGeneralExpanded, setIsGeneralExpanded] = useState(true);
  const [isServicesExpanded, setIsServicesExpanded] = useState(true);
  const [selectedType, setSelectedType] = useState('USER');
  const [tempSelectedService, setTempSelectedService] = useState(selectedService || []);
  const [search, setSearch] = useState('');
  return (
    <FilterPopoverWrapper className="popover-content bg-white py-5 radius-2 flex-column">
      <div className="justify-between px-5">
        <label className="inter-500-text font-18 natural-900-text line-height-28">{t('FILTER_BY')}</label>
        <IconContainer
          Icon={CloseIcon}
          iconHeight={16}
          iconWidth={16}
          iconColor="natural_500"
          backgroundColor="transparent"
          iconContainerClassname="cursor"
          onClick={() => setShowFilterPopover(false)}
        />
      </div>
      <div className={classNames('my-7 ', { 'scroll-container': isServicesExpanded })}>
        <div>
          <div className={classNames('justify-between pb-3 px-5', { 'border-bottom': !isGeneralExpanded })}>
            <label
              className={classNames('inter-500-text font-16  ', {
                'natural-700-text': isGeneralExpanded,
                'natural-500-text': !isGeneralExpanded,
              })}>
              {t('GENERAL')}
            </label>
            <IconContainer
              Icon={RightIcon}
              iconHeight={16}
              iconWidth={16}
              iconColor={isGeneralExpanded ? 'natural_700' : 'natural_500'}
              backgroundColor="transparent"
              iconContainerClassname={classNames('cursor', {
                'rotate-90': !isGeneralExpanded,
                'rotate-270': isGeneralExpanded,
              })}
              onClick={() => setIsGeneralExpanded(prev => !prev)}
            />
          </div>
          <motion.div
            initial={{ opacity: 0, height: 0, padding: 0 }}
            animate={{
              opacity: isGeneralExpanded ? 1 : 0,
              height: isGeneralExpanded ? 'auto' : 0,
              padding: isGeneralExpanded ? '20px' : 0,
              transition: { duration: 0.5 },
            }}
            className="pxy-5 bg-natural-50 px-5">
            <div className="radio-container items-center flex">
              <input
                type="radio"
                id="user-radio"
                onChange={e => e.target.value === 'on' && setSelectedType('USER')}
                checked={selectedType === 'USER'}
              />
              <label
                htmlFor="user-radio"
                className="natural-700-text inter-400-text font-16 line-height-24 radio-label">
                {t('USERS')}
              </label>
            </div>
            <div className="items-center flex">
              <input
                type="radio"
                id="teams-radio"
                onChange={e => e.target.value === 'on' && setSelectedType('TEAM')}
                checked={selectedType === 'TEAM'}
              />
              <label
                htmlFor="teams-radio"
                className="natural-700-text inter-400-text font-16 line-height-24 radio-label">
                {t('TEAMS')}
              </label>
            </div>
          </motion.div>
        </div>
        {!isReschedule && (
          <div className="mt-7">
            <div className={classNames('justify-between pb-3 px-5', { 'border-bottom': !isServicesExpanded })}>
              <label
                className={classNames('inter-500-text font-16', {
                  'natural-700-text': isServicesExpanded,
                  'natural-500-text': !isServicesExpanded,
                })}>
                {t('SERVICES')}
              </label>
              <IconContainer
                Icon={RightIcon}
                iconHeight={16}
                iconWidth={16}
                iconColor={isServicesExpanded ? 'natural_700' : 'natural_500'}
                backgroundColor="transparent"
                iconContainerClassname={classNames('cursor', {
                  'rotate-90': !isServicesExpanded,
                  'rotate-270': isServicesExpanded,
                })}
                onClick={() => setIsServicesExpanded(prev => !prev)}
              />
            </div>
            <motion.div
              initial={{ opacity: 0, height: 0, padding: 0 }}
              animate={{
                opacity: isServicesExpanded ? 1 : 0,
                height: isServicesExpanded ? 'auto' : 0,
                padding: isServicesExpanded ? '20px' : 0,
                transition: { duration: 0.5 },
              }}
              className={classNames('bg-natural-50')}>
              <InputSearch placeholder={t('SEARCH')} value={search} onChange={setSearch} />
              <div className="flex-column service-container">
                {services
                  .filter(service => service.name?.toLowerCase().includes(search?.toLowerCase()))
                  .map(service => (
                    <Checkbox
                      key={service.id}
                      name={service.name}
                      is_checked_done
                      checked={tempSelectedService.some(item => item.id === service.id)}
                      labelClassname="natural-700-text inter-500-text font-16"
                      onChange={value => {
                        if (value) {
                          setTempSelectedService(selectedValues => [...selectedValues, service]);
                        } else {
                          setTempSelectedService(selectedValues =>
                            selectedValues.filter(value => value.id !== service.id),
                          );
                        }
                      }}
                    />
                  ))}
              </div>
            </motion.div>
          </div>
        )}
      </div>
      <div className="flex col-gap-6 px-5 justify-center mt-1 ">
        <Button
          className={classNames('primary-grey')}
          label={t('CLEAR_ALL')}
          onClick={() => {
            setSelectedType('USER');
            setResourceType('USER');
            if (isReschedule) return;
            setTempSelectedService([]);
            setSelectedService([]);
          }}
          size="average"
          borderRadius="100px"
        />
        <Button
          className={classNames('primary ')}
          label={t('APPLy_FILTERS')}
          onClick={() => {
            setResourceType(selectedType);
            setSelectedService(tempSelectedService);
          }}
          size="average"
          borderRadius="100px"
        />
      </div>
    </FilterPopoverWrapper>
  );
};

const FilterPopoverWrapper = styled.div`
  &.popover-content {
    z-index: 10;
    width: 357px;
    box-shadow: 0px 2px 8px -4px rgba(16, 24, 40, 0.1), 0px 6px 17px -3px rgba(16, 24, 40, 0.1);
  }

  .radio-container {
    margin-bottom: 14px;
  }

  .radio-label {
    margin-left: 5px;
  }

  input[type='radio'] {
    width: 20px;
    height: 20px;
  }

  .service-container {
    gap: 14px;
    margin-top: 14px;
  }
  .scroll-container {
    max-height: 350px;
    overflow: auto;
  }
`;

export default FilterPopover;
