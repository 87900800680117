import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { components } from 'react-select';
import styled, { useTheme } from 'styled-components';
import useDebounce from '../../helpers/useDebounceHook';
import { getQuoteTemplate } from '../../store/features/quotesSlice';
import SearchableDropdown from '../common/searchable-dropdown';
import QuoteTemplateWrapper from './wrapper/QuoteTemplateWrapper';

const CustomTemplateOption = props => {
  const { innerProps, data, isSelected, isFocused, selectProps } = props;
  const { getOptionLabel } = selectProps;

  return (
    <components.Option {...props} getStyles={() => {}}>
      <div
        className={classNames(
          'relative flex items-center w-full px-4 py-3 cursor border-bottom option-wrapper',
          isSelected && 'selected',
          isFocused && 'bg-lightgray-1',
        )}
        {...innerProps}>
        <div className="flex-column row-gap-1 w-full">
          <div className="flex items-center justify-between">
            <label className="inter-400-text natural-900-text option-text one-line fw-500">
              {getOptionLabel(data)}
            </label>
          </div>
        </div>
      </div>
    </components.Option>
  );
};

const TemplateQuoteEdit = ({ quote_template, setEditKey, onUpdateQuote }) => {
  const theme = useTheme();

  const { t } = useTranslation();

  const [searchedTemplate, setSearchedTemplate] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState();

  const debouncedSearch = useDebounce(searchedTemplate, 500);

  return (
    <div className="flex-column row-gap-1">
      <QuoteTemplateWrapper className="quote-template-wrapper">
        <div className="flex col-gap-3 items-center radius-1_5 contact-info">
          <label className="inter-400-text natural-400-text font-14">{t('TEMPLATE')}:</label>
          <div className="flex items-center col-gap-6 w-full template-edit-dropdown">
            <SearchableDropdown
              inputValue={searchedTemplate}
              onInputChange={setSearchedTemplate}
              placeholder={t('TEMPLATE_NAME_GOES_HERE')}
              isSearchable={true}
              isCustomSearchable={false}
              autoFocus
              openMenuOnFocus
              value={
                selectedTemplate
                  ? selectedTemplate
                  : quote_template
                  ? { id: quote_template.id, label: quote_template.name }
                  : null
              }
              onChange={option => onUpdateQuote('quote_template', option)}
              onClose={() => {
                setEditKey(null);
              }}
              getOptionLabel={option => {
                return option.name;
              }}
              defaultAdditional={{
                page: 0,
                fetchFunction: getQuoteTemplate,
                pageable: false,
                search: debouncedSearch,
              }}
              customStyle={{
                control: {
                  height: 24,
                  minHeight: 24,
                  backgroundColor: theme.natural_150,
                  padding: '2px 8px',
                  gap: '20px',
                  borderRadius: '6px',
                  borderColor: theme.natural_300,
                },
                container: {
                  maxWidth: '175px',
                },
                input: {
                  margin: 0,
                  padding: 0,
                },
                singleValue: {
                  color: theme.natural_900,
                  margin: 0,
                },
                valueContainer: {
                  padding: 0,
                  width: '125px',
                },
                indicatorsContainer: {
                  height: 16,
                  width: 16,
                  alignSelf: 'center',
                },
                dropdownIndicator: {
                  padding: 0,
                  height: 16,
                  width: 16,
                },
                loadingIndicator: {
                  display: 'none',
                },
                menuPortal: {
                  zIndex: 10,
                  top: ' 100%',
                  left: 0,
                  right: 0,
                  width: '100%',
                },
                menu: {
                  right: 0,
                  width: 400,
                },
              }}
              customComponent={{ Option: CustomTemplateOption }}
            />
          </div>
        </div>
      </QuoteTemplateWrapper>
    </div>
  );
};

const QuoteTempaletEditWrapper = styled.div``;

export default TemplateQuoteEdit;
