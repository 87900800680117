import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

import { ReactComponent as ImageBlank } from '../../assets/icons/property/blank-image.svg';

const renderContactInfo = (label, value, isMobile = false, isLabel, t, isUser) => (
  <div className="flex items-center justify-between">
    {isLabel && <label className="inter-400-text font-14 line-height-20 natural-400-text">{label}</label>}
    <p
      className={classNames(
        'inter-400-text font-14 line-height-20',
        value ? 'natural-900-text one-line' : 'natural-400-text',
      )}>
      {isUser && !value ? t('NO_USER') : value || (isMobile ? t('NO_MOBILE') : t('NO_EMAIL'))}
    </p>
  </div>
);

const PropertyLeadSourceGen = ({ loading = false, dataJob, title }) => {
  const { t } = useTranslation();

  if (loading) {
    return (
      <Skeleton height={'260px'} containerClassName="line-height-1" baseColor="#CDCDCD" highlightColor="#E8E8E8" />
    );
  }

  return (
    <PropertyLeadSourceGenWrapper className="card radius-2 pxy-6 flex-column gap-5">
      <div className="justify-between items-center justify-between border-bottom border-bottom pb-3">
        <label className="inter-600-text natural-900-text font-16">{title}</label>
        {/* <div className="justify-between items-center gap-2">
          {dataJob ? (
            <IconContainer
              iconContainerClassname="flex icon-action cursor"
              iconClassName="natural-400-text"
              iconHeight={14}
              iconWidth={14}
              iconColor={'primary_500'}
              backgroundColor={'primary_50'}
              Icon={PensilIcon}
            />
          ) : (
            <>
              <IconContainer
                iconContainerClassname="flex icon-action cursor"
                iconClassName="natural-400-text"
                iconHeight={14}
                iconWidth={14}
                iconColor={'primary_500'}
                backgroundColor={'primary_50'}
                Icon={PlusIcon}
              />
            </>
          )}
        </div> */}
      </div>
      {dataJob ? (
        <div className="">
          <div className="pb-4 border-bottom">
            <div className="flex col-gap-4 items-center">
              <div className="border radius-1_5 w-72px h-72px flex items-center justify-center">
                {dataJob?.organization?.logo ? (
                  <img alt="logo" src={dataJob?.organization?.logo?.thumbnail_url} className="w-full h-full" />
                ) : (
                  <ImageBlank />
                )}
              </div>
              <div>
                <label className="inter-500-text font-14 line-height-20 natural-900-text pb-1">
                  {dataJob?.organization?.name || '-'}
                </label>
                {renderContactInfo(t('MOBILE'), dataJob?.organization?.phone, true, false, t)}
                {renderContactInfo(t('EMAIL'), dataJob?.organization?.email, false, false, t)}
              </div>
            </div>
          </div>
          <div className="pt-4 flex-column row-gap-2">
            {renderContactInfo(t('USER'), dataJob?.user?.name, false, true, t, true)}
            {renderContactInfo(t('MOBILE'), dataJob?.user?.phone, true, true, t)}
            {renderContactInfo(t('EMAIL'), dataJob?.user?.email, false, true, t)}
          </div>
        </div>
      ) : (
        <label className="inter-400-text font-14 line-height-20 natural-400-text">{t('NO_DATA')}</label>
      )}
    </PropertyLeadSourceGenWrapper>
  );
};

export const PropertyLeadSourceGenWrapper = styled.div`
  .icon-action {
    border-radius: 100%;
    padding: 0px;
    width: 32px;
    height: 32px;
  }
`;

export default PropertyLeadSourceGen;
