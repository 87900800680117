import React, { useCallback, useEffect, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useSelector } from 'react-redux';
import { Route, Routes, useParams } from 'react-router-dom';
import styled from 'styled-components';
import BoardDetails from '../../components/board-details';
import OwnersPopover from '../../components/board-details/filter/owner-popover';
import SortPopover from '../../components/board-details/sort';
import BreadcrumbWrapper from '../../components/common/breadcrumb-wrapper';
import Popover from '../../components/common/popover';
import PageHeader from '../../components/page-header';
import WorkitemDetails from '../../components/workitem-details';
import useDebounce from '../../helpers/useDebounceHook';

const Boards = () => {
  const { board_id } = useParams();
  const { boardList, boardAccessList, workitemDetails } = useSelector(state => state.board);

  const [selectedBoard, setSelectedBoard] = useState({});
  const [boardsFilters, setBoardsFilters] = useState({});
  const [owners, setOwners] = useState({});
  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, 500);
  const [showSortPopover, setShowSortPopover] = useState(false);
  const [showOwnerPopover, setShowOwnerPopover] = useState(false);
  const [selectOwners, setSelectOwners] = useState([]);
  const [orderBy, setOrderBy] = useState('DESC');
  const [sortBy, setSortBy] = useState('created_date');
  const ownerList = boardAccessList[0]?.users || [];

  useEffect(() => {
    setSelectedBoard(boardList?.find(board => board.id === board_id) || {});

    return () => {
      setSelectedBoard({});
      setSearchText('');
    };
  }, [boardList, board_id]);

  const SortWrapper = useCallback(
    ({ children }) => {
      const ref = useOnclickOutside(() => setShowSortPopover(false));
      return (
        <Popover
          isOpen={showSortPopover}
          offset={[0, 2]}
          content={
            <div ref={ref}>
              <SortPopover
                setShowSortPopover={setShowSortPopover}
                setMainOrderBy={setOrderBy}
                setMainSortBy={setSortBy}
                mainOrderBy={orderBy}
                mainSortBy={sortBy}
              />
            </div>
          }>
          {children}
        </Popover>
      );
    },
    [showSortPopover],
  );

  const BoardOwnersWrapper = useCallback(
    ({ children }) => {
      const ref = useOnclickOutside(() => {
        setShowOwnerPopover(false);
      });
      return (
        <Popover
          isOpen={showOwnerPopover}
          offset={[0, 2]}
          content={
            <div ref={ref}>
              <OwnersPopover
                setShowOwnerPopover={setShowOwnerPopover}
                owners={ownerList}
                setBoardsFilters={setBoardsFilters}
                setSelectOwners={setSelectOwners}
                selectOwners={selectOwners}
              />
            </div>
          }>
          {children}
        </Popover>
      );
    },
    [showOwnerPopover],
  );

  return (
    <Routes>
      <Route
        element={
          <BoardPropertyWrapper className="flex-column flex-1">
            <BreadcrumbWrapper
              breadcrumbs={[
                { text: selectedBoard.name, path: `/boards/board/${board_id}` },
                { text: workitemDetails?.engagement?.name },
              ]}
            />
            <WorkitemDetails selectedBoard={selectedBoard} />
          </BoardPropertyWrapper>
        }
        path="workitem/:workitem_id/*"
      />
      <Route
        element={
          <BoardsWrapper className="flex-column flex-1">
            <PageHeader
              headerTitle={selectedBoard.name}
              wrapperClassName="pt-8 pb-7 px-6"
              headerTitleIcon={
                selectedBoard.id ? (
                  <img
                    src={selectedBoard.icon.active}
                    alt="header-titl-icon"
                    height={32}
                    width={32}
                    className="mr-3 border pxy-1 radius-2"
                  />
                ) : null
              }
              addBtnPermission="BOARDS_VIEW"
              showSearch
              showSort
              showBoardAvatars
              avatars={ownerList}
              onAddClick={() => {}}
              searchText={searchText}
              selectOwners={selectOwners}
              onSearchChange={setSearchText}
              showBoardsFilter
              showBoardsSort
              onApplyFilters={() => {}}
              onClearFilters={() => setBoardsFilters({})}
              setOwners={setOwners}
              owners={owners}
              setBoardsFilters={setBoardsFilters}
              boardsFilters={boardsFilters}
              SortWrapper={SortWrapper}
              BoardOwnersWrapper={BoardOwnersWrapper}
              onSortClick={() => setShowSortPopover(prev => !prev)}
              onAvatarClick={() => setShowOwnerPopover(prev => !prev)}
            />
            <BoardDetails
              debouncedSearch={debouncedSearch}
              boardsFilters={boardsFilters}
              orderBy={orderBy}
              sortBy={sortBy}
            />
          </BoardsWrapper>
        }
        path="*"
      />
    </Routes>
  );
};

const BoardsWrapper = styled.div`
  max-height: 100vh;
  overflow: hidden;
`;

const BoardPropertyWrapper = styled.div`
  max-height: 100vh;
  overflow: hidden;

  .page-header {
    padding: 12px 24px;
    background-color: white;
  }
`;

export default Boards;
