import React, { useCallback, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { OrganisationContext } from '../../context/organisationContext';
import { getFormattedNumber, getFormattedNumberStyle } from '../../helpers/utils';

const QuoteTotalValues = ({ products }) => {
  const { hasPermission } = useContext(OrganisationContext);

  const hasConstViewPermission = useMemo(() => hasPermission('QUOTE_COST_VIEW'), [hasPermission]);

  const amountDetails = useMemo(() => {
    return products.reduce(
      (acc, product) => {
        const { discount_rate, total_net, taxation_scheme, cost_net, cost_discount_rate, cost_taxation_scheme } =
          product;
        const { rate } = taxation_scheme || { rate: 0 };
        const { rate: cost_rate } = cost_taxation_scheme || { rate: 0 };

        const discountAmount = total_net * (discount_rate / 100) || 0;
        const taxableAmount = (total_net - discountAmount) * (rate / 100) || 0;

        const discountCostAmount = cost_net * (cost_discount_rate / 100) || 0;
        const taxableCostAmount = (cost_net - discountCostAmount) * (cost_rate / 100) || 0;

        const totalNetWithoutTax = total_net - discountAmount;
        const costNetTotalWithoutTax = (cost_net || 0) - discountCostAmount;

        const profit = totalNetWithoutTax - costNetTotalWithoutTax;

        acc.subTotal += Math.abs(total_net);
        acc.discount += discountAmount || 0;
        acc.tax += taxableAmount || 0;
        acc.total += Math.abs(total_net) + (taxableAmount || 0) - (discountAmount || 0);

        acc.costSubTotal += Math.abs(cost_net || 0);
        acc.costDiscount += discountCostAmount || 0;
        acc.costTax += taxableCostAmount || 0;
        acc.costTotal += Math.abs(cost_net || 0) + (taxableCostAmount || 0) - (discountCostAmount || 0);

        acc.profit += profit;
        acc.totalNetWithoutTax += totalNetWithoutTax || 0;

        return acc;
      },
      {
        subTotal: 0,
        discount: 0,
        tax: 0,
        total: 0,
        costSubTotal: 0,
        costDiscount: 0,
        costTax: 0,
        costTotal: 0,
        profit: 0,
        totalNetWithoutTax: 0,
      },
    );
  }, [products]);

  const productMargin = useMemo(() => {
    const { profit, totalNetWithoutTax } = amountDetails;
    return ((profit || 0) / (totalNetWithoutTax || 1)) * 100;
  }, [amountDetails]);

  const renderPaymentValue = useCallback((key, value) => {
    return (
      <div className="flex items-center justify-between px-2 py-1 radius-1_5 payment-value">
        <label className="inter-400-text natural-400-text font-12">{key}</label>
        <label className="inter-500-text natural-900-text">{value}</label>
      </div>
    );
  }, []);

  return (
    <QuoteTotalValuesWrapper hasConstViewPermission={hasConstViewPermission} className="justify-end">
      {hasConstViewPermission && (
        <div className="flex-column gap-2">
          <label className="inter-600-text font-12 letter-spacing-1">COST PRICES</label>
          {renderPaymentValue('Sub-total', getFormattedNumber(amountDetails.costSubTotal))}
          {renderPaymentValue('Discount', getFormattedNumber(amountDetails.costDiscount))}
          {renderPaymentValue('Tax', getFormattedNumber(amountDetails.costTax))}
          {renderPaymentValue('Total', getFormattedNumber(amountDetails.costTotal))}
        </div>
      )}
      <div className="flex-column gap-2">
        <label className="inter-600-text font-12 letter-spacing-1">RETAIL PRICES</label>
        {renderPaymentValue('Sub-total', getFormattedNumber(amountDetails.subTotal))}
        {renderPaymentValue('Discount', getFormattedNumber(amountDetails.discount))}
        {renderPaymentValue('Tax', getFormattedNumber(amountDetails.tax))}
        {renderPaymentValue('Total', getFormattedNumber(amountDetails.total))}
      </div>
      {hasConstViewPermission && (
        <div className="profit-margin-wrapper flex-column gap-2">
          {renderPaymentValue('Profit', getFormattedNumber(amountDetails.profit))}
          {renderPaymentValue('Margin', getFormattedNumberStyle(productMargin / 100, 'percent'))}
        </div>
      )}
    </QuoteTotalValuesWrapper>
  );
};

const QuoteTotalValuesWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ hasConstViewPermission }) => (hasConstViewPermission ? '184px 184px' : '184px')};
  grid-template-rows: auto;
  gap: 24px;

  .payment-value {
    background-color: ${({ theme }) => theme.natural_100};
    width: 100%;
    height: 28px;
  }

  .profit-margin-wrapper {
    grid-column: 1 / 3;
  }
`;

export default QuoteTotalValues;
