import classNames from 'classnames';
import moment from 'moment/moment';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { ReactComponent as AlertIcon } from '../assets/icons/AlertIcon.svg';
import { ReactComponent as AlertInfoIcon } from '../assets/icons/AlertInfoIcon.svg';
import { ReactComponent as CrossIcon } from '../assets/icons/CrossIcons.svg';
import { ReactComponent as MailIcon } from '../assets/images/mail.svg';
import Button from '../components/common/button/button';
import { OrganisationContext } from '../context/organisationContext';
import { capitalize, formatText, initModal } from '../helpers/utils';
import { retryAutomationLog } from '../store/features/automationsSlice';
import { AutomationLogActionsWrapper } from './modal.styled';

const AutomationLogAction = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { modal, setModal } = useContext(OrganisationContext);
  const [loading, setLoading] = useState(false);
  const { automationLog, isCancel, title, isRetry, automationAction } = modal.content;

  const handleCorfirm = () => {
    try {
      if (isRetry) {
        dispatch(retryAutomationLog({ automationLogId: automationAction?.id })).then(resposne => {
          setModal(initModal);
        });
      } else if (isCancel) {
        setModal(initModal);
      }
    } catch (error) {}
  };

  const cancelContent = (
    <>
      <label className="bold-text font-18">{automationLog?.automation?.name}</label>
      <div className="flex-column items-start justify-start w-full mt-4">
        <label className="regular-text grey-text font-12">{t('ID')}:</label>
        <label className="regular-text font-14 mt-1">{automationLog?.id}</label>
      </div>
      <div className="flex-column items-start justify-start w-full mt-4">
        <label className="regular-text grey-text font-12">{t('DATE')}:</label>
        <label className="regular-text font-14 mt-1">
          {moment(automationLog?.created_on * 1000).format('DD/MM/YY - hh:mm a')}
        </label>
      </div>
    </>
  );

  const retryContent = (
    <>
      <label className="font-16 inter-600-text natural-900-text">{'Action 1'}</label>
      <div className="send-email-box flex items-center justify-start gap-3 radius-1_5 mt-3 pxy-3 border">
        <div className="flex items-center justify-center border bg-white radius-1_5 pxy-1_5">
          <MailIcon className="flex items-center justify-center" color="#404040" height={24} width={24} />
        </div>

        <div className="flex-column items-start justify-start flex-1">
          <label className="natural-700-text inter-500-text font-14 line-height-20">
            {automationAction?.action?.action_type?.name}
          </label>
          <label className="natural-500-text inter-400-text font-12 line-height-20">
            {automationAction?.action?.action_type?.description}
          </label>
        </div>
      </div>
      <div className="flex items-center justify-start w-full mt-5">
        <div className="icon-wrapper flex items-center justify-center border bg-white radius-1_3">
          <img
            src={`${automationAction?.action?.integration?.connector?.icon?.active}`}
            style={{ width: 17, height: 17 }}
          />
        </div>
        <label className="ml-2 inter-600-text natural-700-text font-14 line-height-20">
          {automationAction?.action?.integration?.name}
        </label>
      </div>
      {automationAction?.action?.params.length > 0 && (
        <div className="flex-column items-start justify-start w-full mt-3 gap-2">
          {automationAction?.action?.params?.map((param, index) => (
            <div className={`flex items-center justify-start w-full ${index === 0 ? '' : 'mt-2'}`} key={param.id}>
              <label className="inter-400-text natural-500-text font-14 line-height-20">
                {capitalize(formatText(param.param_type, ' '))}: &nbsp;
              </label>
              <label className="inter-400-text natural-900-text font-14 line-height-20">
                {' '}
                {param.param_value || param.param_value}
              </label>
            </div>
          ))}
        </div>
      )}
    </>
  );

  const getContent = () => {
    if (isCancel) {
      return cancelContent;
    } else if (isRetry) {
      return retryContent;
    }
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <AutomationLogActionsWrapper className="flex-column items-center p-6 gap-6">
        <div className="flex-column items-center relative w-full">
          {isCancel ? <AlertIcon /> : <AlertInfoIcon />}
          <p className="font-18 text-center inter-500-text natural-900-text mt-5">{title}</p>
          <CrossIcon className="absolute right-0 cursor" onClick={() => setModal(initModal)} />
          <p className="inter-400-text font-14 natural-400-text text-center mt-2">
            {isCancel ? t('NOT_ABLE_TO_UNDO_THIS_ACTION') : t('LOREM_IPSUM')}
          </p>
        </div>
        <div className="flex-column w-full radius-1_5 border pt-4 pb-5 px-5">{getContent()}</div>
        <div className="action-wrapper flex-column justify-center w-full gap-3">
          <Button
            className={classNames('primary', loading && 'disabled', isCancel && 'delete-btn')}
            disabled={loading}
            label={isRetry ? t('RETRY') : t('CACEL')}
            onClick={() => handleCorfirm()}
            primary={true}
            size="large"
            borderRadius="100px"
          />
          <Button
            className={classNames('cancel-btn default', loading && 'disabled')}
            label={isRetry ? t('CANCEL') : t('KEEP')}
            onClick={() => setModal(initModal)}
            size="large"
            borderRadius="100px"
          />
        </div>
      </AutomationLogActionsWrapper>
    </CSSTransition>
  );
};

export default AutomationLogAction;
