import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import styled, { useTheme } from 'styled-components';
import MessageContext from '../../context/MessageContext';
import { getStageDecisions, getStageWorkitemList, updateStageWorkitemList } from '../../store/features/boardSlice';
import { addToast } from '../../store/features/toastSlice';
import { progressWorkitem } from '../../store/features/workitemSlice';
import InfiniteScrollComponent from '../common/infinite-scroll';
import StageWorkitem from './stage-workitem';

const backgroundColors = ['#2496FF', '#8324FF', '#F59E0B', '#22C55E', '#D942FF'];

const WorkItemsLoader = () => {
  return (
    <div className="flex-column row-gap-4 py-4">
      {[...Array(4)].map((key, index) => (
        <Skeleton
          key={index}
          height={'100%'}
          baseColor="white"
          highlightColor="#F0F0F0"
          containerClassName="line-height-1 radius-3 flex-1 workitem-skeleton mx-4"
        />
      ))}
    </div>
  );
};

export const BoardStage = ({
  boardStage,
  index,
  debouncedSearch,
  setDecisions = () => {},
  allowedToProgress,
  isDragging,
  boardsFilters,
  orderBy,
  sortBy,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pageRef = useRef(0);
  const theme = useTheme();

  const { message } = useContext(MessageContext);

  const { stageWorkitemList } = useSelector(state => state.board);

  const { id, stage } = boardStage;

  const workitems = stageWorkitemList[stage.id] || [];

  const [pageInfo, setPageInfo] = useState({});
  const [loading, setLoading] = useState(true);

  const isDroppable = allowedToProgress.includes(stage.id); // Check if the stage is allowed to progress

  const fetchStageWorkitemList = (page, merge = false, loading = true) => {
    setLoading(loading);
    dispatch(
      getStageWorkitemList({
        stage_id: stage.id,
        merge,
        params: {
          page,
          size: 10,
          search: debouncedSearch,
          job_type_id: boardsFilters?.job_type_id,
          project_id: boardsFilters?.project_id,
          campaign_id: boardsFilters?.campaign_id,
          lead_generator_id: boardsFilters?.lead_organisation?.id,
          lead_source_id: boardsFilters?.lead_user?.id,
          sortBy: sortBy,
          orderBy: orderBy,
        },
      }),
    )
      .then(data => {
        const { content, ...restData } = data;
        setPageInfo(restData);
        pageRef.current = page;
      })
      .catch(() => dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_STAGE_WORKITEMS') })))
      .finally(() => setLoading(false));
  };

  const fetchStageDesitionsList = () => {
    dispatch(getStageDecisions({ stage_id: stage.id }))
      .then(data => {
        setDecisions(prev => ({ ...prev, [stage.id]: data }));
      })
      .catch(() => dispatch(addToast({ error: true, text: t('FAILED_TO_RETRIVE_STAGES') })))
      .finally(() => setLoading(false));
  };

  const fetchMoreData = () => {
    fetchStageWorkitemList(pageRef.current + 1, true, false);
  };

  useEffect(() => {
    fetchStageWorkitemList(0, false);
    fetchStageDesitionsList();
  }, [stage.id, debouncedSearch, boardsFilters, orderBy, sortBy]);

  useEffect(() => {
    const { data } = message;
    const { action, new_stage_id, old_stage_id } = data || {};

    if (action === 'PROGRESSED' && (new_stage_id === stage.id || old_stage_id === stage.id)) {
      fetchStageWorkitemList(0, false, false);
    }
  }, [message]);

  return (
    <BoardStageWrapper key={id} className="flex-column radius-2 overflow-hidden">
      <div className="flex pxy-4 justify-between border-bottom stage-name-wrapper">
        <div className="flex items-center">
          <div className="dot" style={{ backgroundColor: backgroundColors[index % 5] }} />
          <label className="inter-500-text natural-900-text flex items-center stage-name one-line">{stage.name}</label>
          <div className="flex items-center justify-center pxy-1 radius-4 ml-2 card-counter">
            <label className="inter-500-text natural-900-text font-12">{pageInfo?.total_elements}</label>
          </div>
        </div>
      </div>
      <Droppable droppableId={stage.id}>
        {provided => (
          <div
            id={stage.id}
            className="flex-column flex-1 row-gap-3 overflow-hidden"
            {...provided.droppableProps}
            ref={provided.innerRef}>
            {loading ? (
              <WorkItemsLoader />
            ) : isDragging && isDragging !== stage.id ? (
              <div
                className="flex items-center justify-center border mxy-4 h-full radius-3"
                style={{
                  backgroundColor: isDragging ? (isDroppable ? theme.success_50 : theme.error_50) : theme.natural_150,
                  borderColor: isDragging ? (isDroppable ? theme.success_500 : theme.error_500) : 'transparent',
                }}>
                <label
                  className={classNames(
                    'inter-500-text natural-900-text',
                    isDroppable ? 'success-500-text' : 'error-text',
                  )}>
                  {isDroppable ? t('MOVE_HERE') : t('YOU_CAN_NOT_MOVE_HERE')}
                </label>
              </div>
            ) : (
              workitems.length > 0 && (
                <InfiniteScrollComponent
                  infiniteScrollClassName="workitem-list"
                  infiniteScrollListClassName="custom-scrollbar thin-scrollbar py-4"
                  dataLength={workitems.length}
                  height={150}
                  hasMore={!pageInfo.last}
                  fetchMoreData={fetchMoreData}>
                  {workitems.map((workitem, i) => (
                    <Draggable key={workitem.id} draggableId={workitem.id} index={i}>
                      {provided => (
                        <StageWorkitem
                          workitem={workitem}
                          key={workitem?.id}
                          providedRef={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </InfiniteScrollComponent>
              )
            )}
          </div>
        )}
      </Droppable>
    </BoardStageWrapper>
  );
};

const BoardStages = ({ debouncedSearch, boardsFilters, orderBy, sortBy }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { boardStageList } = useSelector(state => state.board);
  const { stageWorkitemList } = useSelector(state => state.board);

  const transitionStageIds = boardStageList
    ?.filter(({ stage }) => stage.stage_type === 'TRANSITION')
    ?.map(({ stage }) => stage.id);

  const [decisions, setDecisions] = useState({});
  const [isDragging, setIsDragging] = useState(false);
  const [stageIdsAllowedToProgress, setStageIdsAllowedToProgress] = useState([]);

  const updateWorkitemInStageLocally = ({ workitem, sourceStageId, destinationStageId, destinationIndex }) => {
    const sourceWorkitems = stageWorkitemList[sourceStageId];
    const destinationWorkitems = stageWorkitemList[destinationStageId];

    const updatedSourceWorkitems = sourceWorkitems.filter(item => item.id !== workitem.id);
    const updatedDestinationWorkitems = [...destinationWorkitems];
    updatedDestinationWorkitems.splice(destinationIndex, 0, workitem);

    dispatch(
      updateStageWorkitemList({
        ...stageWorkitemList,
        [sourceStageId]: updatedSourceWorkitems,
        [destinationStageId]: updatedDestinationWorkitems,
      }),
    );
  };

  const onProgressWorkitem = ({
    stage,
    workitem,
    sourceStageId,
    destinationStageId,
    sourceIndex,
    destinationIndex,
  }) => {
    const { id: workitem_id, owners, visibility } = workitem;
    const request = {
      decision: null,
      stage: stage ? { id: stage?.id } : null,
      visible: visibility?.suspended_date ? { suspended_date: visibility?.suspended_date } : null,
      owners: owners,
    };
    dispatch(progressWorkitem({ workitem_id: workitem_id, request }))
      .then(() => {
        dispatch(addToast({ error: false, text: t('WORKITEM_MOVED_SUCCESSFULLY'), id: nanoid() }));
      })
      .catch(e => {
        updateWorkitemInStageLocally({
          workitem,
          sourceStageId: destinationStageId,
          destinationStageId: sourceStageId,
          destinationIndex: sourceIndex,
        });
        dispatch(addToast({ error: true, text: t('FAILED_TO_MOVE_WORKITEM'), id: nanoid() }));
      });
  };

  const onDragEnd = result => {
    const { destination, source, draggableId } = result;
    const { droppableId: sourceStageId, index: sourceIndex } = source || {};
    const { droppableId: destinationStageId, index: destinationIndex } = destination || {};

    if (!destination) {
      setStageIdsAllowedToProgress([]);
      setIsDragging(false);
      return;
    }

    if (sourceStageId === destinationStageId) {
      setStageIdsAllowedToProgress([]);
      setIsDragging(false);
      return;
    }

    if (!stageIdsAllowedToProgress.includes(destinationStageId)) {
      dispatch(addToast({ error: true, text: t('WORKITEM_NOT_MOVED_CONFIG_PERMISSION_ERROR'), id: nanoid() }));
      setStageIdsAllowedToProgress([]);
      setIsDragging(false);
      return;
    }

    const sourceWorkitems = stageWorkitemList[sourceStageId];
    const draggedWorkitem = sourceWorkitems?.find(workitem => workitem.id === draggableId);

    updateWorkitemInStageLocally({ workitem: draggedWorkitem, sourceStageId, destinationStageId, destinationIndex });
    onProgressWorkitem({
      stage: { id: destinationStageId },
      workitem: draggedWorkitem,
      sourceStageId,
      sourceIndex,
      destinationStageId,
      destinationIndex,
    });

    setStageIdsAllowedToProgress([]);
    setIsDragging(false);
  };

  const onDragStart = result => {
    const { source } = result;
    const { droppableId } = source;
    const sourceDecitions = decisions[droppableId];
    const allowedToProgress = sourceDecitions?.map(decition => decition?.stage?.id);
    setStageIdsAllowedToProgress([droppableId, ...transitionStageIds, ...allowedToProgress]);
    setIsDragging(droppableId);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <div className="w-full flex-1 overflow-scroll custom-horizontal">
        <div className="flex w-fit-content min-w-full h-full col-gap-4">
          {boardStageList.map((boardStage, index) => (
            <BoardStage
              debouncedSearch={debouncedSearch}
              boardsFilters={boardsFilters}
              boardStage={boardStage}
              index={index}
              key={boardStage?.id}
              setDecisions={setDecisions}
              allowedToProgress={stageIdsAllowedToProgress}
              isDragging={isDragging}
              orderBy={orderBy}
              sortBy={sortBy}
            />
          ))}
        </div>
      </div>
    </DragDropContext>
  );
};

export const BoardStageWrapper = styled.div`
  background-color: ${({ theme }) => theme.natural_150};
  width: 312px;

  .stage-name-wrapper {
    background-color: ${({ theme }) => theme.natural_300};

    .dot {
      border-radius: 50%;
      margin-right: 8px;
      width: 8px;
      height: 8px;
      background-color: ${({ theme }) => theme.natural_100};
    }
  }

  .card-counter {
    background-color: ${({ theme }) => theme.natural_100};
    width: 20px;
    height: 20px;
  }

  .workitem-skeleton {
    min-height: 180px;
    height: 100%;
  }
`;

export default BoardStages;
