import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReactComponent as NoDocumentIcon } from '../../assets/icons/empty-document.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { getUsers } from '../../store/features/userManagementSlice';
import { UsersListWrapper } from '../../styles/components/users-list/users-list.styled';
import InfiniteScrollV2 from '../common/infinite-scroll-v2';
import NoData from '../common/no-data';
import SkeletonTransition from '../common/skeleton-transition';
import TableHeader from '../common/table-header';
import UserListItem from './user-list-item';

const userTableHeaders = [
  { name: 'USER', key: 'USER' },
  { name: 'CONTACT', key: 'CONTACT' },
  { name: 'TEAMS', key: 'TEAMS' },
  { name: 'STATUS', key: 'STATUS' },
];

const UsersList = ({ loading, fetchMoreData = () => {} }) => {
  const { t } = useTranslation();

  const { setSideModal } = useContext(OrganisationContext);

  const { users } = useSelector(state => state.usersManagement);
  const { content: usersList, last } = users || {};

  const onEditUserProfile = user => {
    setSideModal({
      type: 'edit-user-profile',
      content: {
        user_id: user?.id,
        onSuccess: () => {
          dispatch(
            getUsers({
              merge: true,
              params: {
                page: 0,
                size: 15,
                search: '',
              },
            }),
          );
        },
      },
    });
  };

  return (
    <UsersListWrapper className="flex-column flex-1 radius-1 mt-6 mx-6 overflow-hidden">
      <SkeletonTransition
        loading={loading}
        loaderClassName="item flex items-center justify-center flex-1 mb-6 w-full"
        height={'100%'}
        containerClassName="line-height-1 h-full w-full">
        {(usersList || []).length > 0 ? (
          <div className="w-full card radius-1_5 border overflow-x-scroll">
            <div className="flex-column w-fit-content min-w-full h-full">
              <TableHeader headers={userTableHeaders} />
              <InfiniteScrollV2 hasMore={!last} fetchMoreData={fetchMoreData}>
                {(usersList || []).map(user => (
                  <UserListItem
                    user={user}
                    key={user.id}
                    onEditUserProfile={onEditUserProfile}
                    menuPortal={usersList?.length < 3}
                  />
                ))}
              </InfiniteScrollV2>
            </div>
          </div>
        ) : (
          <div className="flex-column flex-1 mb-6 items-center justify-center">
            <NoData
              title={t('NO_USERS')}
              description={t('NO_USERS_HERE')}
              className="mt-12"
              EmptyIcon={NoDocumentIcon}
              iconClassName="relative"
            />
          </div>
        )}
      </SkeletonTransition>
    </UsersListWrapper>
  );
};

export default UsersList;
