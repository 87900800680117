import { createSlice } from '@reduxjs/toolkit';
import api from '../services/index';

export const costsSlice = createSlice({
  name: 'quotes',
  initialState: {},
  reducers: {},
});

export const createCosts =
  ({ engagement_id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/engagements/${engagement_id}/costs`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getCostsList =
  ({ engagement_id, params = {} }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/engagements/${engagement_id}/costs`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const deleteCosts =
  ({ cost_id }) =>
  async dispatch => {
    try {
      const { data } = await api.delete(`/costs/${cost_id}`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const {} = costsSlice.actions;
export default costsSlice.reducer;
