import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PageHeader from '../../../../components/page-header';
import { OrganisationContext } from '../../../../context/organisationContext';
import useDebounce from '../../../../helpers/useDebounceHook';
import { getTeamMembers } from '../../../../store/features/teamsManagmentSlice';
import { addToast } from '../../../../store/features/toastSlice';

const TeamMemberHeader = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { id } = useParams();

  const { setModal } = useContext(OrganisationContext);
  const { teamDetails, teamMembers } = useSelector(state => state.teamManagement);

  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, 500);

  const fetchTeamMembers = () => {
    dispatch(getTeamMembers({ teamId: id, params: { search: debouncedSearch } })).catch(() => {
      dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_TEAM_MEMBERS'), id: nanoid() }));
    });
  };

  useEffect(() => {
    fetchTeamMembers();
  }, [debouncedSearch]);

  const onAddClick = () => {
    setModal({
      type: 'add-new-team-user',
      content: {
        top: true,
        teamDetails: teamDetails,
        teamMembers: teamMembers,
        onSuccess: () => fetchTeamMembers(),
      },
    });
  };

  return (
    <div className="flex-row justify-between w-full border-bottom">
      <PageHeader
        headerTitle={<div className="font-14">{t('TEAM_MEMBERS')}</div>}
        showSearch
        showInviteBtn
        onInviteClick={onAddClick}
        inviteBtnLabel={t('ADD_USERS')}
        inviteBtnPermission="TEAMS_MANAGE"
        searchText={searchText}
        onSearchChange={setSearchText}
      />
    </div>
  );
};

export default TeamMemberHeader;
