import * as turf from '@turf/turf';
import * as L from 'leaflet';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet/dist/leaflet.css';
import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, ZoomControl, useMap } from 'react-leaflet';

const DrawControl = ({ boundaryLayer, onBoundaryCreated, drawnItems }) => {
  const map = useMap();

  const onEventCreated = event => {
    const layer = event.layer;
    if (boundaryLayer) {
      drawnItems.clearLayers();
    }
    drawnItems.addLayer(layer);
    onBoundaryCreated(layer);
  };

  useEffect(() => {
    map.addLayer(drawnItems);

    const drawControl = new L.Control.Draw({
      draw: {
        polygon: true,
        polyline: false,
        rectangle: false,
        circle: false,
        marker: false,
      },
      edit: {
        featureGroup: drawnItems,
      },
    });

    map.addControl(drawControl);

    map.on(L.Draw.Event.CREATED, onEventCreated);
    console.log('Adding DrawControl');
    return () => {
      console.log('Removing DrawControl');
      map.off(L.Draw.Event.CREATED, onEventCreated);
      map.removeLayer(drawnItems);
      map.removeControl(drawControl);
    };
  }, []);
};

const MapLeafLet = ({ coordinates, ...rest }) => {
  const { lat, lon } = coordinates;

  const mapRef = useRef(null);
  const boundaryLayerRef = useRef(null);
  const drawControlRef = useRef(null);
  const geoJSONLayerRef = useRef(null);
  const geoJSONDataRef = useRef(null);
  const originalBoundaryGeoJSONRef = useRef(null);
  const gridGeoJSONRef = useRef(null);
  const gridLayerRef = useRef(null);
  const draggedLayersRef = useRef(new Set());

  const defaultPanelWidth = 1.879;
  const defaultPanelHeight = 1.045;
  const defaultPitch = 0;
  let currentRotation = 0;
  let currentOffset = { lat: 0, lng: 0 };

  const [panelHeight, setPanelHeight] = useState(1.045);
  const [panelWidth, setPanelWidth] = useState(1.879);
  const [pitch, setPitch] = useState(0);
  const [orientation, setOrientation] = useState('LANDSCAPE');
  const [addMode, setAddMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [drawnItems, setDrawnItems] = useState(L.featureGroup());
  const [boundaryLayer, setBoundaryLayer] = useState(null);

  function toggleAddMode() {
    setAddMode(true);
    setDeleteMode(false);
    console.log('Add mode activated');
  }

  function toggleDeleteMode() {
    setDeleteMode(true);
    setAddMode(false);
    console.log('Delete mode activated');
  }

  const enableDrawing = () => {
    drawControlRef.current = new L.Draw.Polygon(mapRef.current);
    drawControlRef.current.enable();
  };

  const onBoundaryCreated = layer => {
    console.log('Boundary drawn:', layer.toGeoJSON());
    boundaryLayerRef.current = layer;
    addGeoJSONLayer(layer);
    drawControlRef.current.disable();
  };

  const addGeoJSONLayer = boundaryLayer => {
    if (!boundaryLayer) {
      alert('Please draw the boundary first.');
      return;
    }
    let geoJSONData = geoJSONDataRef.current;
    let originalBoundaryGeoJSON = originalBoundaryGeoJSONRef.current;
    let geoJSONLayer = geoJSONLayerRef.current;

    geoJSONData = boundaryLayer.toGeoJSON();
    originalBoundaryGeoJSON = geoJSONData;

    if (geoJSONLayer) {
      mapRef.current.removeLayer(geoJSONLayer);
    }

    geoJSONLayer = L.geoJSON(geoJSONData, {
      style: function (feature) {
        return {
          color: 'black',
          weight: 2,
          opacity: 0.6,
          fillOpacity: 0.2,
        };
      },
    }).addTo(mapRef.current);

    drawnItems.clearLayers(); // Clear the drawn polygon boundary
    geoJSONDataRef.current = geoJSONData;
    originalBoundaryGeoJSONRef.current = originalBoundaryGeoJSON;
    geoJSONLayerRef.current = geoJSONLayer;

    console.log('GeoJSON layer added:', geoJSONData);
    drawSolar();
  };

  function calculateAngleInMeters(point1, point2) {
    const R = 6371000; // Earth radius in meters

    const lat1 = (point1.lat * Math.PI) / 180;
    const lat2 = (point2.lat * Math.PI) / 180;
    const lon1 = (point1.lng * Math.PI) / 180;
    const lon2 = (point2.lng * Math.PI) / 180;

    const x = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2);
    const y = lat2 - lat1;

    const distanceX = x * R;
    const distanceY = y * R;

    return Math.atan2(distanceY, distanceX) * (180 / Math.PI);
  }

  const calculateOrientation = () => {
    let geoJSONLayer = geoJSONLayerRef.current;
    let geoJSONData = geoJSONDataRef.current;
    if (!geoJSONLayer) {
      alert('Please add the boundary as a GeoJSON layer first.');
      return;
    }

    try {
      const latlngs = geoJSONLayer.getLayers()[0].getLatLngs()[0];
      const mostWest = latlngs.reduce((prev, curr) => (curr.lng < prev.lng ? curr : prev));
      const mostSouth = latlngs.reduce((prev, curr) => (curr.lat < prev.lat ? curr : prev));

      console.log('Most West:', mostWest);
      console.log('Most South:', mostSouth);

      // Calculate the angle relative to north in meters
      const angle = calculateAngleInMeters(mostWest, mostSouth);
      const adjustedAngle = 90 - Math.abs(angle);

      console.log('Calculated Angle:', angle);
      console.log('Adjusted Angle for North Alignment:', adjustedAngle);

      return {
        angle: adjustedAngle.toFixed(2),
        longestSide: mostWest.distanceTo(mostSouth).toFixed(2),
        area: turf.area(geoJSONData).toFixed(2),
      };
    } catch (error) {
      console.error('Error calculating orientation:', error);
    }
  };

  function metersToDegreesLatitude(meters) {
    const earthCircumference = 40075017; // meters
    const degreesPerMeter = 360 / earthCircumference;
    return meters * degreesPerMeter;
  }

  function metersToDegreesLongitude(meters, latitude) {
    const earthCircumference = 40075017; // meters
    const degreesPerMeter = 360 / earthCircumference;
    return (meters * degreesPerMeter) / Math.cos((latitude * Math.PI) / 180);
  }

  function adjustHeightForPitch(height, pitch) {
    const pitchRadians = pitch * (Math.PI / 180);
    return height / Math.cos(pitchRadians);
  }

  function handleLayerColorChange(layer) {
    if (addMode) {
      layer.setStyle({ fillColor: 'green', fillOpacity: 0.6 });
      layer.feature.properties.isDeleted = false; // Mark as not deleted
    } else if (deleteMode) {
      layer.setStyle({ fillColor: 'red', fillOpacity: 0.6 });
      layer.feature.properties.isDeleted = true; // Mark as deleted
    }
  }

  function drawGrid(bounds, panelWidth, panelHeight, pitch, orientation) {
    // Calculate cell dimensions in degrees
    let gridGeoJSON = gridGeoJSONRef.current;
    let gridLayer = gridLayerRef.current;
    let isDragging = false;
    let draggedLayers = draggedLayersRef.current;
    const centerLat = (bounds.getNorth() + bounds.getSouth()) / 2;
    let cellWidth = metersToDegreesLongitude(panelWidth, centerLat);
    let cellHeight = metersToDegreesLatitude(adjustHeightForPitch(panelHeight, pitch));

    if (orientation === 'PORTRAIT') {
      [cellWidth, cellHeight] = [cellHeight, cellWidth];
    }

    // Find the longest side cheking bothe meqsurement not assuming north south is longest
    const longestSide = bounds.getNorthWest().distanceTo(bounds.getSouthWest());
    const shortestSide = bounds.getNorthWest().distanceTo(bounds.getNorthEast());

    // Code needs the panel height adjusted by pitch onot added
    const rows = Math.floor(longestSide / (panelHeight + pitch));
    const cols = Math.floor(shortestSide / panelWidth);

    const northWest = bounds.getNorthWest();

    const gridFeatures = [];

    for (let row = 0; row < rows; row++) {
      for (let col = 0; col < cols; col++) {
        const cellLat = northWest.lat - row * cellHeight;
        const cellLng = northWest.lng + col * cellWidth;

        const cellPolygon = [
          [cellLng, cellLat],
          [cellLng + cellWidth, cellLat],
          [cellLng + cellWidth, cellLat - cellHeight],
          [cellLng, cellLat - cellHeight],
          [cellLng, cellLat],
        ];

        gridFeatures.push({
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [cellPolygon],
          },
          properties: {
            row: row,
            col: col,
            width: panelWidth,
            height: panelHeight,
            isEmpty: true, // intial state of p[olygon
            isDeleted: false, // Add property to track deleted state
          },
        });
      }
    }

    gridGeoJSON = {
      type: 'FeatureCollection',
      features: gridFeatures,
    };

    if (gridLayer) {
      mapRef.current.removeLayer(gridLayer);
    }

    gridLayer = L.geoJSON(gridGeoJSON, {
      style: function (feature) {
        return {
          color: 'blue',
          weight: 2,
          fillOpacity: feature.properties.isDeleted ? 0 : 0.6,
          dashArray: feature.properties.isDeleted ? '5, 5' : null, // Dotted line for deleted panels
        };
      },
      onEachFeature: function (feature, layer) {
        layer.on('mouseover', function () {
          handleCellHover(layer, feature.properties);
        });

        layer.on('mousedown', function (event) {
          isDragging = true;
          mapRef.current.dragging.disable(); // Disable map dragging
          draggedLayers.add(layer);
          handleLayerColorChange(layer);
        });

        layer.on('click', function () {
          toggleLayerColor(layer);
        });

        layer.on('mouseover', function (event) {
          if (isDragging) {
            draggedLayers.add(layer);
            handleLayerColorChange(layer);
          }
        });
      },
    }).addTo(mapRef.current);

    gridGeoJSONRef.current = gridGeoJSON;
    gridLayerRef.current = gridLayer;
    draggedLayersRef.current = draggedLayers;

    console.log('Grid GeoJSON:', gridGeoJSON);
    return gridGeoJSON;
  }

  function rotateBoundaryToNorth(angle) {
    let geoJSONLayer = geoJSONLayerRef.current;
    let geoJSONData = geoJSONDataRef.current;

    if (!geoJSONLayer) {
      alert('Please add the boundary as a GeoJSON layer first.');
      return;
    }

    const center = turf.centerOfMass(geoJSONData).geometry.coordinates;
    const rotatedGeoJSON = turf.transformRotate(geoJSONData, angle, {
      pivot: center,
    });

    if (geoJSONLayer) {
      mapRef.current.removeLayer(geoJSONLayer);
    }

    geoJSONLayer = L.geoJSON(rotatedGeoJSON, {
      style: function (feature) {
        return {
          color: 'black',
          weight: 2,
          opacity: 0.6,
          fillOpacity: 0.2,
        };
      },
    }).addTo(mapRef.current);

    geoJSONLayerRef.current = geoJSONLayer;

    return rotatedGeoJSON;
  }

  function rotateGeoJSON(geoJSON, angle, center) {
    return turf.transformRotate(geoJSON, angle, { pivot: center });
  }

  function handleCellHover(layer, properties) {
    layer.bindTooltip(`ID: ${properties.id || 'N/A'}`).openTooltip();
  }

  function toggleLayerColor(layer) {
    const currentColor = layer.options.fillColor;
    const newColor = currentColor === 'green' ? 'red' : 'green';
    layer.setStyle({ fillColor: newColor, fillOpacity: 0.6 });
    layer.feature.properties.isDeleted = newColor === 'red'; // Update the deleted state
  }

  const drawSolar = () => {
    const pWidth = panelWidth || defaultPanelWidth;
    const pHeight = panelHeight || defaultPanelHeight;
    const pitchValue = pitch || defaultPitch;
    const orientationValue = orientation || 'LANDSCAPE';

    const orientationValues = calculateOrientation();
    const rotationAngle = parseFloat(orientationValues.angle);

    // Rotate boundary to north
    const rotatedBoundaryGeoJSON = rotateBoundaryToNorth(rotationAngle);

    // Draw grid within the rectangle
    const solarGrid = drawGrid(
      L.geoJSON(rotatedBoundaryGeoJSON).getBounds(),
      pWidth,
      pHeight,
      pitchValue,
      orientationValue,
    );

    let gridLayer = gridLayerRef.current;
    let draggedLayers = draggedLayersRef.current;
    let originalBoundaryGeoJSON = originalBoundaryGeoJSONRef.current;
    let geoJSONLayer = geoJSONLayerRef.current;
    let isDragging = false;

    // Rotate solar grid and boundary back to original orientation
    const center = turf.centerOfMass(solarGrid).geometry.coordinates;
    const rotatedSolarGrid = rotateGeoJSON(solarGrid, -rotationAngle, center);
    originalBoundaryGeoJSON = rotateGeoJSON(rotatedBoundaryGeoJSON, -rotationAngle, center);

    if (gridLayer) {
      mapRef.current.removeLayer(gridLayer);
    }

    gridLayer = L.geoJSON(rotatedSolarGrid, {
      style: function (feature) {
        return {
          color: 'blue',
          weight: 2,
          fillOpacity: feature.properties.isDeleted ? 0 : 0.6,
          dashArray: feature.properties.isDeleted ? '5, 5' : null, // Dotted line for deleted panels
        };
      },
      onEachFeature: function (feature, layer) {
        layer.on('mouseover', function () {
          handleCellHover(layer, feature.properties);
        });

        layer.on('mousedown', function (event) {
          isDragging = true;
          mapRef.current.dragging.disable(); // Disable map dragging
          draggedLayers.add(layer);
          handleLayerColorChange(layer);
        });

        layer.on('click', function () {
          toggleLayerColor(layer);
        });

        layer.on('mouseover', function (event) {
          if (isDragging) {
            draggedLayers.add(layer);
            handleLayerColorChange(layer);
          }
        });
      },
    }).addTo(mapRef.current);

    if (geoJSONLayer) {
      mapRef.current.removeLayer(geoJSONLayer);
    }

    geoJSONLayer = L.geoJSON(originalBoundaryGeoJSON, {
      style: function (feature) {
        return {
          color: 'black',
          weight: 2,
          opacity: 0.6,
          fillOpacity: 0.2,
        };
      },
    }).addTo(mapRef.current);

    console.log('Solar Grid GeoJSON:', rotatedSolarGrid);

    // Update current rotation and offset
    currentRotation = rotationAngle;
    currentOffset = { lat: 0, lng: 0 };

    mapRef.current.removeLayer(gridLayer); // Ensure the Solar Grid layer is the topmost
    mapRef.current.addLayer(gridLayer);
    originalBoundaryGeoJSONRef.current = originalBoundaryGeoJSON;
    gridLayerRef.current = gridLayer;
    draggedLayersRef.current = draggedLayers;
    geoJSONLayerRef.current = geoJSONLayer;
  };

  function toggleBoundary() {
    let geoJSONLayer = geoJSONLayerRef.current;
    if (geoJSONLayer) {
      if (mapRef.current.hasLayer(geoJSONLayer)) {
        mapRef.current.removeLayer(geoJSONLayer);
      } else {
        mapRef.current.addLayer(geoJSONLayer);
      }
    }
  }

  const rotateGrid = angleIncrement => {
    let gridGeoJSON = gridGeoJSONRef.current;
    let gridLayer = gridLayerRef.current;
    let isDragging = false;
    let draggedLayers = draggedLayersRef.current;

    if (!gridGeoJSON) {
      alert('Please draw the grid first.');
      return;
    }

    currentRotation += angleIncrement;

    const center = turf.centerOfMass(gridGeoJSON).geometry.coordinates;

    gridGeoJSON = rotateGeoJSON(gridGeoJSON, angleIncrement, center);

    if (gridLayer) {
      mapRef.current.removeLayer(gridLayer);
    }

    gridLayer = L.geoJSON(gridGeoJSON, {
      style: function (feature) {
        return {
          color: 'blue',
          weight: 2,
          fillOpacity: feature.properties.isDeleted ? 0 : 0.6,
          dashArray: feature.properties.isDeleted ? '5, 5' : null, // Dotted line for deleted panels
        };
      },
      onEachFeature: function (feature, layer) {
        layer.on('mouseover', function () {
          handleCellHover(layer, feature.properties);
        });

        layer.on('mousedown', function (event) {
          isDragging = true;
          mapRef.current.dragging.disable(); // Disable map dragging
          draggedLayers.add(layer);
          handleLayerColorChange(layer);
        });

        layer.on('click', function () {
          toggleLayerColor(layer);
        });

        layer.on('mouseover', function (event) {
          if (isDragging) {
            draggedLayers.add(layer);
            handleLayerColorChange(layer);
          }
        });
      },
    }).addTo(mapRef.current);

    console.log('Rotated Grid GeoJSON:', gridGeoJSON);

    mapRef.current.removeLayer(gridLayer); // Ensure the Solar Grid layer is the topmost
    mapRef.current.addLayer(gridLayer);

    gridGeoJSONRef.current = gridGeoJSON;
    gridLayerRef.current = gridLayer;
    draggedLayersRef.current = draggedLayers;
  };

  const moveGrid = direction => {
    let gridGeoJSON = gridGeoJSONRef.current;
    let gridLayer = gridLayerRef.current;
    let isDragging = false;
    let draggedLayers = draggedLayersRef.current;
    const moveStep = 0.5; // move step in meters

    if (!gridGeoJSON) {
      alert('Please draw the grid first.');
      return;
    }

    const moveAmount =
      moveStep *
      (direction === 'left' || direction === 'right'
        ? metersToDegreesLongitude(1, mapRef.current.getCenter().lat)
        : metersToDegreesLatitude(1));
    const moveLng = direction === 'left' ? -moveAmount : direction === 'right' ? moveAmount : 0;
    const moveLat = direction === 'up' ? moveAmount : direction === 'down' ? -moveAmount : 0;

    currentOffset.lat += moveLat;
    currentOffset.lng += moveLng;

    gridGeoJSON.features.forEach(feature => {
      feature.geometry.coordinates[0] = feature.geometry.coordinates[0].map(([lng, lat]) => [
        lng + moveLng,
        lat + moveLat,
      ]);
    });

    if (gridLayer) {
      mapRef.current.removeLayer(gridLayer);
    }

    gridLayer = L.geoJSON(gridGeoJSON, {
      style: function (feature) {
        return {
          color: 'blue',
          weight: 2,
          fillOpacity: feature.properties.isDeleted ? 0 : 0.6,
          dashArray: feature.properties.isDeleted ? '5, 5' : null, // Dotted line for deleted panels
        };
      },
      onEachFeature: function (feature, layer) {
        layer.on('mouseover', function () {
          handleCellHover(layer, feature.properties);
        });

        layer.on('mousedown', function (event) {
          isDragging = true;
          mapRef.current.dragging.disable(); // Disable map dragging
          draggedLayers.add(layer);
          handleLayerColorChange(layer);
        });

        layer.on('click', function () {
          toggleLayerColor(layer);
        });

        layer.on('mouseover', function (event) {
          if (isDragging) {
            draggedLayers.add(layer);
            handleLayerColorChange(layer);
          }
        });
      },
    }).addTo(mapRef.current);

    console.log('Moved Grid GeoJSON:', gridGeoJSON);

    mapRef.current.removeLayer(gridLayer); // Ensure the Solar Grid layer is the topmost
    mapRef.current.addLayer(gridLayer);

    gridGeoJSONRef.current = gridGeoJSON;
    gridLayerRef.current = gridLayer;
    draggedLayersRef.current = draggedLayers;
  };

  const deleteRedPanels = () => {
    let gridGeoJSON = gridGeoJSONRef.current;
    let gridLayer = gridLayerRef.current;
    let isDragging = false;
    let draggedLayers = draggedLayersRef.current;

    if (!gridGeoJSON) {
      alert('Please draw the grid first.');
      return;
    }

    gridGeoJSON.features.forEach(feature => {
      if (feature.properties.isDeleted) {
        feature.properties.isDeleted = true;
      }
    });

    // Redraw the grid layer with updated styles
    if (gridLayer) {
      mapRef.current.removeLayer(gridLayer);
    }

    gridLayer = L.geoJSON(gridGeoJSON, {
      style: function (feature) {
        return {
          color: 'blue',
          weight: 2,
          fillOpacity: feature.properties.isDeleted ? 0 : 0.6,
          dashArray: feature.properties.isDeleted ? '5, 5' : null, // Dotted line for deleted panels
        };
      },
      onEachFeature: function (feature, layer) {
        layer.on('mouseover', function () {
          handleCellHover(layer, feature.properties);
        });

        layer.on('mousedown', function (event) {
          isDragging = true;
          mapRef.current.dragging.disable(); // Disable map dragging
          draggedLayers.add(layer);
          handleLayerColorChange(layer);
        });

        layer.on('click', function () {
          toggleLayerColor(layer);
        });

        layer.on('mouseover', function (event) {
          if (isDragging) {
            draggedLayers.add(layer);
            handleLayerColorChange(layer);
          }
        });
      },
    }).addTo(mapRef.current);

    gridLayerRef.current = gridLayer;
    draggedLayersRef.current = draggedLayers;
    gridGeoJSONRef.current = gridGeoJSON;
    console.log('Red Panels Deleted:', gridGeoJSON);
  };

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <div id="controls" className="flex wrap col-gap-2 row-gap-2 mb-4">
        <label>Panel Width (m):</label>
        <input
          type="number"
          id="panelWidth"
          step="0.001"
          value={panelWidth}
          onChange={e => setPanelWidth(e.target.value)}
        />
        <label>Panel Height (m):</label>
        <input
          type="number"
          id="panelHeight"
          step="0.001"
          value={panelHeight}
          onChange={e => setPanelHeight(e.target.value)}
        />
        <label>Pitch (degrees):</label>
        <input type="number" id="pitch" step="0.01" value={pitch} onChange={e => setPitch(e.target.value)} />
        <label>Orientation:</label>
        <select onChange={e => setOrientation(e.target.value)}>
          <option value="LANDSCAPE">LANDSCAPE</option>
          <option value="PORTRAIT">PORTRAIT</option>
        </select>
        <button onClick={enableDrawing}>Draw Boundary</button>
        {/* <button onClick={addGeoJSONLayer}>Add Boundary as GeoJSON</button>
        <button onClick={calculateOrientation}>Calculate Orientation</button> */}
        <button onClick={() => drawSolar()}>Draw Solar</button>
        <button onClick={toggleBoundary}>Toggle Boundary</button>
        <button onClick={() => rotateGrid(1)}>Rotate Grid +</button>
        <button onClick={() => rotateGrid(-1)}>Rotate Grid -</button>
        <button onClick={() => moveGrid('left')}>Move Left</button>
        <button onClick={() => moveGrid('right')}>Move Right</button>
        <button onClick={() => moveGrid('up')}>Move Up</button>
        <button onClick={() => moveGrid('down')}>Move Down</button>
        <br />
        <button onClick={toggleAddMode}>Add Mode</button>
        <button onClick={toggleDeleteMode}>Delete Mode</button>
        <button onClick={deleteRedPanels}>Execute Delete</button>
      </div>
      {lat && lon && (
        <MapContainer
          style={{
            height: '80%',
            width: '100%',
          }}
          zoomControl={false}
          center={[lat, lon]}
          ref={mapRef}
          zoom={20}>
          <DrawControl
            boundaryLayer={boundaryLayer}
            setBoundryLayer={setBoundaryLayer}
            onBoundaryCreated={onBoundaryCreated}
            setDrawnItems={setDrawnItems}
            drawnItems={drawnItems}
          />
          <ZoomControl position="bottomright" />
          <TileLayer
            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
            maxZoom={22}
            keepBuffer={1000}
            attribution="Google Maps"
            url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
          />
        </MapContainer>
      )}
    </div>
  );
};

export default MapLeafLet;
