import * as Ably from 'ably';
import { AblyProvider } from 'ably/react';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getNotificationToken } from '../../store/features/activitiesSlice';

const AblyProviderWrapper = ({ children }) => {
  const dispatch = useDispatch();

  const [ablyClient, setAblyClient] = useState(null);

  const authCallback = async (tokenParams, callback) => {
    try {
      const tokenRequest = await dispatch(getNotificationToken()); // Make a network request to your server
      callback(null, tokenRequest);
    } catch (error) {
      callback(error, null);
    }
  };

  useEffect(() => {
    const client = new Ably.Realtime.Promise({
      authCallback,
    });
    setAblyClient(client);

    return () => {
      client.close();
    };
  }, []);

  return ablyClient && <AblyProvider client={ablyClient}>{children}</AblyProvider>;
};

export default AblyProviderWrapper;
