import classNames from 'classnames';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';
import Loading from '../../../assets/images/loading.svg';

const InfiniteScrollComponent = ({
  dataLength,
  height,
  hasMore,
  children,
  fetchMoreData = () => {},
  scrollableTarget = 'scrollableDiv',
  infiniteScrollClassName = '',
  infiniteScrollListClassName = '',
}) => {
  return (
    <InfiniteScrollComponentWrapper
      className={classNames('flex-column flex-1 overflow-scroll', infiniteScrollClassName)}
      id="scrollableDiv">
      <InfiniteScroll
        className={classNames('flex-column flex-1', infiniteScrollListClassName)}
        dataLength={dataLength}
        hasMore={hasMore}
        height={height ? height : 0}
        scrollThreshold={0.9}
        loader={
          <div className="item flex items-center justify-center my-1">
            <img alt="loading" height="24px" src={Loading} />
          </div>
        }
        next={fetchMoreData}
        scrollableTarget={scrollableTarget}>
        {children}
      </InfiniteScroll>
    </InfiniteScrollComponentWrapper>
  );
};

const InfiniteScrollComponentWrapper = styled('div')`
  .infinite-scroll-component__outerdiv {
    height: 100%;
    .infinite-scroll-component {
      height: calc(100% + 3px) !important;
    }
  }
`;

export default InfiniteScrollComponent;
