import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { OrganisationContext } from '../../context/organisationContext';
import { getPaymentTerms } from '../../store/features/quotesSlice';
import SearchableDropdown from '../common/searchable-dropdown';
import CustomTooltip from '../common/tooltip-new';
import { QuoteDetailsPaymentWrapper } from './wrapper';

const QuoteDetailsPayment = ({
  isQuoteEditable,
  notEditableQuoteInfo,
  quoteDetailsInitial,
  setQuoteDetailsInitial,
}) => {
  const paymentTermTemplateApiRef = useRef(nanoid());

  const { t } = useTranslation();
  const theme = useTheme();
  const { setModal } = useContext(OrganisationContext);
  const { payment_terms_template } = quoteDetailsInitial || {};
  const [searchedPaymentTerm, setSearchedPaymentTerm] = useState('');
  const [paymentTermsTemplateInitial, setPaymentTermsTemplateInitial] = useState(payment_terms_template || {});

  useEffect(() => {
    setPaymentTermsTemplateInitial(payment_terms_template);
  }, [payment_terms_template]);

  const newPaymentTermModal = () => {
    setModal({
      type: 'add-new-payment-term',
      content: {
        onSuccess: data => {
          setQuoteDetailsInitial({
            ...quoteDetailsInitial,
            payment_terms_template: {
              ...data,
            },
          });
          paymentTermTemplateApiRef.current = nanoid();
        },
      },
    });
  };

  return (
    <QuoteDetailsPaymentWrapper className="flex flex-1">
      <div className="payment-terms">
        <CustomTooltip
          id={`quote-tooltip-payment-terms`}
          place="bottom-start"
          hidden={isQuoteEditable}
          content={<label className="flex col-gap-1 items-center">{notEditableQuoteInfo}</label>}>
          <div className="mb-4 width-wrapper">
            <SearchableDropdown
              key={paymentTermTemplateApiRef.current}
              name={t('PAYMENT_TERMS')}
              placeholder={t('SELECT_PAYMENT_TERMS')}
              value={paymentTermsTemplateInitial}
              getOptionLabel={option => option?.name}
              getOptionValue={option => option?.id}
              onChange={option => {
                setQuoteDetailsInitial({ ...quoteDetailsInitial, payment_terms_template: option?.id ? option : null });
              }}
              defaultAdditional={{
                page: 0,
                fetchFunction: getPaymentTerms,
                pageable: false,
                defaultOptions: [{ name: 'None', id: null }],
              }}
              onInputChange={setSearchedPaymentTerm}
              inputValue={searchedPaymentTerm}
              isCustomSearchable={true}
              isSearchable={true}
              isDisabled={!isQuoteEditable}
              showAddOnMenu={true}
              addClickFunction={newPaymentTermModal}
            />
          </div>
        </CustomTooltip>
      </div>
    </QuoteDetailsPaymentWrapper>
  );
};

export default QuoteDetailsPayment;
