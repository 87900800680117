import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import BreadcrumbWrapper from '../../../components/common/breadcrumb-wrapper';
import PageHeader from '../../../components/page-header';
import QuoteTemplatetList from '../../../components/quote-templates-list';
import { OrganisationContext } from '../../../context/organisationContext';
import useDebounce from '../../../helpers/useDebounceHook';
import { getQuoteTemplate } from '../../../store/features/quotesSlice';
import { addToast } from '../../../store/features/toastSlice';

function QuoteTemplates() {
  const pageRef = useRef(0);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setModal, setSideModal } = useContext(OrganisationContext);

  const [search, setSearchText] = useState('');
  const [showLoading, setShowLoading] = useState(true);
  const [quotesTemplatetList, setQuoteTemplateList] = useState([]);

  const debouncedSearch = useDebounce(search, 500);

  const fetchQuoteTemplateList = (page, debouncedSearch, showLoading = true) => {
    setShowLoading(showLoading);
    dispatch(
      getQuoteTemplate({
        params: {
          page: page,
          search: debouncedSearch,
        },
      }),
    )
      .then(data => {
        setQuoteTemplateList(data);
        setShowLoading(false);
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_QUOTE_TEMPLATE_LIST'), id: nanoid() })),
          setQuoteTemplateList([]);
        setShowLoading(false);
      });
  };

  const fetchMoreData = () => {
    fetchQuoteTemplateList(pageRef.current + 1, debouncedSearch, false);
  };

  useEffect(() => {
    fetchQuoteTemplateList(0, debouncedSearch);
  }, [debouncedSearch]);

  const handleAddQuoteTemplate = () => {
    setModal({
      type: 'add-quote-template',
      content: {
        title: t('ADD_QUOTE_TEMPLATE'),
        onSuccess: () => fetchQuoteTemplateList(0, debouncedSearch),
      },
    });
  };

  const handleDeleteQuoteTemplate = templateList => {
    setModal({
      type: 'delete-quote-template',
      content: {
        quoteTemplateData: templateList,
        onSuccess: () => setQuoteTemplateList(prev => prev.filter(p => p.id !== templateList.id)),
        top: false,
        title: t('ARE_YOU_SURE_WANT_TO_DELETE_THIS_QUOTE_TEMPLATE'),
        description: t('DELETE_ACTION_DESCRIPTION'),
      },
    });
  };

  const redirectQuoteTemplateDetails = templateList => {
    setSideModal({
      type: 'quote-template-details',
      content: {
        quoteTemplateData: templateList,
        handleDeleteQuoteTemplate: handleDeleteQuoteTemplate,
        updateList: (id, data) => {
          const index = quotesTemplatetList.findIndex(item => item.id === id);
          if (index === -1) {
            return;
          }
          let updatedItems;
          if (data.is_default) {
            updatedItems = quotesTemplatetList.map(item => ({ ...item, is_default: false }));
          } else {
            updatedItems = [...quotesTemplatetList];
          }
          updatedItems[index] = {
            ...updatedItems[index],
            name: data.name,
            description: data.description,
            is_default: data.is_default,
          };
          setQuoteTemplateList(updatedItems);
        },
      },
    });
  };

  return (
    <PlatformWrapper className="flex-column w-full h-full items-start justify-start flex-1">
      <BreadcrumbWrapper
        breadcrumbs={[
          { text: t('SETTINGS'), onClick: () => navigate('/settings', { state: 'PLATFORM_CONFIGURATION' }) },
          {
            text: t('PLATFORM_CONFIGURATION'),
            onClick: () => navigate('/settings', { state: 'PLATFORM_CONFIGURATION' }),
          },
          { text: t('QUOTE_TEMPLATES'), onClick: () => {} },
        ]}
      />
      <PageHeader
        showAddNewBtn
        showSearchOnLeft={true}
        onAddClick={handleAddQuoteTemplate}
        showSearch
        searchText={search}
        addButtonLabel={t('CREATE_TEMPLATE')}
        addBtnPermission={'QUOTE_TEMPLATES_MANAGE'}
        onSearchChange={setSearchText}
      />
      <QuoteTemplatetList
        debouncedSearch={debouncedSearch}
        fetchMoreData={fetchMoreData}
        quotesTemplatetList={quotesTemplatetList}
        showLoading={showLoading}
        handleDeleteQuoteTemplate={handleDeleteQuoteTemplate}
        redirectQuoteTemplateDetails={redirectQuoteTemplateDetails}
      />
    </PlatformWrapper>
  );
}

const PlatformWrapper = styled.div``;

export default QuoteTemplates;
