import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as EmptyIconImage } from '../../../assets/icons/empty-document.svg';

const NoData = ({
  title = '',
  description = '',
  className = '',
  iconClassName = '',
  EmptyIcon = EmptyIconImage,
  iconHeight = 100,
  iconWidth = 160,
}) => {
  return (
    <NoDataWrapper className={classNames('flex-column items-center justify-center w-full', className)}>
      <EmptyIcon className={iconClassName} height={iconHeight} width={iconWidth} />
      <div className="flex-column items-center w-full justify-center gap-6px mt-6">
        <label className="font-16 text-center inter-500-text natural-900-text">{title}</label>
        <p className="font-14 text-center inter-400-text natural-500-text description">{description}</p>
      </div>
    </NoDataWrapper>
  );
};

const NoDataWrapper = styled('div')`
  .description {
    width: min(400px, 70%);
  }
`;

export default NoData;
