import { nanoid } from 'nanoid';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { components } from 'react-select';
import { ReactComponent as CloseIcon } from '../../../assets/images/toast-close.svg';
import { capitalize, emailRegEx, formatText } from '../../../helpers/utils';
import { getPropertyContacts } from '../../../store/features/propertySlice';
import { addToast } from '../../../store/features/toastSlice';
import IconContainer from '../icon-container';
import SearchableDropdown from '../searchable-dropdown';

const RenderContactInfo = ({ contacts, contactType, showLine = true }) => {
  if (contacts && contacts.length > 0) {
    const filteredContacts = contactType ? contacts.filter(contact => contact.contact_type === contactType) : contacts;

    return (
      <Fragment>
        {filteredContacts.map(contact => (
          <div key={contact.id}>
            <span className={'inter-400-text natural-700-text font-12 option-text'}>{contact.value}</span>
            {contact?.formatted_address && (
              <div className="title-one-line one-line">
                <span key={contact.id} className={'inter-400-text natural-700-text font-12 option-text '}>
                  {contact?.formatted_address}
                </span>
              </div>
            )}
          </div>
        ))}
        {showLine && <div style={{ width: '2px', height: '12px', background: '#D4D4D4' }} />}
      </Fragment>
    );
  }
  return null;
};

const CustomOption = props => {
  const { innerProps, data } = props;

  return (
    <div className={'w-full px-4 py-3 cursor border-bottom option-wrapper'} {...innerProps}>
      <div className="flex items-center justify-between pb-1_5 gap-3 select-primary-contact">
        <span className={'inter-400-text natural-900-text font-14 option-text'}>
          {data.contact_type === 'COMPANY' ? data.company_name : data.label || data.name}
        </span>
        <div className="bg-pink radius-4 px-3 py-0_5 line-height-1 ">
          <label className={'inter-400-text color-purple font-12 option-text line-height-20'}>
            {capitalize(formatText(data.property_contact_type, ' '))}
          </label>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <RenderContactInfo contacts={data.emails} contactType={null} />
        <RenderContactInfo contacts={data.phones} contactType={'MOBILE'} />
        <RenderContactInfo contacts={data.phones} contactType={'LANDLINE'} showLine={false} />
      </div>
      <div>
        <span className={'inter-400-text natural-900-text font-14 option-text'}>
          <RenderContactInfo contacts={data.addresses} contactType={null} showLine={false} />
        </span>
      </div>
    </div>
  );
};

const MultiValue = props => {
  const { data } = props;
  const { value: email } = data;
  return (
    <components.MultiValue {...props}>
      <div className="flex items-center gap-2">
        <span className={'inter-400-text natural-900-text font-14 option-text word-break-all white-space-break-spaces'}>
          {email}
        </span>
      </div>
    </components.MultiValue>
  );
};

const MultiValueRemove = props => {
  return (
    <components.MultiValueRemove {...props}>
      <IconContainer Icon={CloseIcon} backgroundColor="transparent" iconHeight={12} iconWidth={12} />
    </components.MultiValueRemove>
  );
};

const EmailSuggestions = ({
  property_id,
  name,
  placeholder,
  selectedEmails = [],
  setSelectedEmails = () => {},
  ignoreEmails = [],
  sub_name = '',
}) => {
  const dispatch = useDispatch();

  const selectedEmailValues = selectedEmails.map(({ value }) => value);
  const ignoreEmailValues = ignoreEmails.map(({ value }) => value);

  const [searchedEmail, setSearchedEmail] = useState('');
  const [propertyContacts, setPropertyContacts] = useState([]);

  const onInputChange = (value, { action } = {}) => {
    if (action === 'input-change') {
      const valueContainsSpace = value.includes(' ');
      if (valueContainsSpace) {
        const email = value.split(' ')[0];
        const isValidEmail = emailRegEx.test(email);
        if (isValidEmail) {
          setSelectedEmails([...selectedEmails, { value: email, isAdded: true }]);
          setSearchedEmail('');
        } else {
          dispatch(addToast({ error: true, text: 'Please enter valid email', id: nanoid() }));
        }
      } else {
        setSearchedEmail(value);
      }
    }
    if (action === 'input-blur' || action === 'menu-close') {
      setSearchedEmail('');
    }
  };

  const onSelect = option => {
    const addedEmails = option.filter(o => o.isAdded).map(({ value }) => value);
    const notAddedEmails = option
      .filter(o => !o.isAdded)
      .map(opt => opt.emails || [])
      .flat()
      .map(({ value }) => value);
    const allOptionEmails = [...addedEmails, ...notAddedEmails];
    const uniqueEmails = allOptionEmails.filter(
      (value, index) => allOptionEmails.findIndex(e => e === value) === index,
    );
    const emailsToAdd = uniqueEmails.map(value => ({ value, isAdded: true }));
    setSelectedEmails([...(emailsToAdd || [])]);
    setSearchedEmail('');
  };

  const fetchPropertyContacts = () => {
    dispatch(getPropertyContacts({ property_id: property_id, forFetchOnly: true }))
      .then(data => {
        setPropertyContacts(data);
      })
      .catch(error => {
        setPropertyContacts([]);
      });
  };

  useEffect(() => {
    if (property_id) {
      fetchPropertyContacts();
    }
  }, [property_id]);

  return (
    <SearchableDropdown
      isMulti
      key={`email-suggestions-${propertyContacts.length}`}
      loadOptionsOnMenuOpen={true}
      openMenuOnFocus={true}
      ignoreMenuDomClick={true}
      isSearchable={true}
      isCustomSearchable={false}
      closeMenuOnSelect={false}
      menuPortalTarget={document.body}
      inputValue={searchedEmail}
      value={selectedEmails}
      onInputChange={onInputChange}
      placeholder={placeholder}
      name={name}
      sub_name={sub_name}
      onChange={onSelect}
      defaultAdditional={{
        defaultOptions: propertyContacts,
      }}
      filterOption={(option, inputValue) => {
        const { data } = option;
        const { emails: optionEmails } = data;
        const emailValues = optionEmails.map(({ value }) => value);
        const uniqueEmails = emailValues.filter((value, index) => emailValues.findIndex(e => e === value) === index);

        let emailAlreadyAdded = uniqueEmails.find(
          value => selectedEmailValues.includes(value) || ignoreEmailValues.includes(value),
        );

        if (emailAlreadyAdded) {
          return false;
        }

        if (inputValue) {
          return uniqueEmails.some(value => value.includes(inputValue.toLowerCase()));
        }

        return true;
      }}
      customStyle={{
        valueContainer: {
          padding: '4px 8px',
        },
        input: {
          width: '1px',
        },
        dropdownIndicator: {
          display: 'none',
        },
      }}
      customComponent={{ Option: CustomOption, MultiValue, MultiValueRemove }}
    />
  );
};

export default EmailSuggestions;
