import { ReactComponent as MobileIcon } from '@assets/icons/phone.svg';
import { ReactComponent as ImageBlank } from '@assets/icons/property/blank-image.svg';
import { ReactComponent as MailIcon } from '@assets/icons/property/mail.svg';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { ReactComponent as AlertIcon } from '../assets/icons/AlertIcon.svg';
import { ReactComponent as CrossIcon } from '../assets/icons/CrossIcons.svg';
import Button from '../components/common/button/button';
import IconContainer from '../components/common/icon-container';
import { OrganisationContext } from '../context/organisationContext';
import { capitalize, formatText, initModal } from '../helpers/utils';
import { deletePartner } from '../store/features/partnersSlice';
import { addToast } from '../store/features/toastSlice';

const JobPartnerDelete = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { modal, setModal } = useContext(OrganisationContext);
  const { title, description, partner, engagement_id, onSuccess } = modal.content;
  const { organization } = partner;

  const [loading, setLoading] = useState(false);

  const handleDone = async () => {
    setLoading(true);
    dispatch(deletePartner({ engagement_id, partnerId: partner.id }))
      .then(data => {
        setModal(initModal);
        setLoading(false);
        onSuccess && onSuccess(partner.id);
        dispatch(
          addToast({
            error: false,
            title: t('PARTNER_DELETED'),
            text: t('SUCCESSFULLY_DELETED_PARTNER', { value: partner?.organization?.name }),
          }),
        );
      })
      .catch(response => {
        dispatch(
          addToast({
            error: true,
            text: t('ERROR_WHILE_DELETING_PARTNER'),
            id: nanoid(),
          }),
        );
        setLoading(false);
      });
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <JobPartnerDeleteWrapper className="flex-column items-center pxy-6 gap-8">
        <div className="flex-column gap-5 content-wrapper items-center relative">
          <AlertIcon />
          <CrossIcon className="absolute right-0 cursor" width={20} height={20} onClick={() => setModal(initModal)} />
          <div className="flex-column gap-2 items-center justify-start w-full">
            <p className="font-18 text-center inter-500-text natural-900-text">{title}</p>
            <span className="inter-400-text font-14 natural-500-text text-center">{description}</span>
          </div>
        </div>

        <div className="px-4 py-4 flex gap-4 w-full radius-1_5 border white-background team-details-container">
          <div
            className="border flex justify-center items-center radius-1_5 w-48px h-48px flex-shrink-0"
            style={{ width: '48px' }}>
            {organization?.image?.url ? (
              <img alt="logo" src={organization.image.url} className="w-full h-full object-fit-contain" />
            ) : (
              <ImageBlank />
            )}
          </div>
          <div className="flex-column gap-1 justify-center w-full">
            <label className="inter-600-text  line-height-20 natural-900-text">{organization.name}</label>
            <div className="flex items-center gap-2">
              <IconContainer
                iconHeight={16}
                iconWidth={16}
                backgroundColor="transparent"
                iconColor="natural_700"
                Icon={MailIcon}
                iconContainerClassname="p-0"
              />
              <label
                className={classNames(
                  'inter-400-text font-12 line-height-20 natural-400-text',
                  organization.email && 'natural-900-text',
                )}>
                {organization.email || capitalize(formatText(t('NO_EMAIL')))}
              </label>
            </div>

            <div className="flex items-center gap-2">
              <IconContainer
                iconHeight={16}
                iconWidth={16}
                iconColor="natural_700"
                backgroundColor="transparent"
                iconContainerClassname="p-0"
                Icon={MobileIcon}
              />
              <label
                className={classNames(
                  'inter-400-text line-height-20 font-12 natural-400-text',
                  organization?.phone && 'natural-900-text',
                )}>
                {organization?.phone || capitalize(formatText(t('NO_PHONE')))}
              </label>
            </div>

            <label
              className={classNames(
                'inter-400-text font-12 line-height-20 natural-400-text',
                organization?.formatted_address && 'natural-500-text',
              )}>
              {organization?.formatted_address || capitalize(formatText(t('NO_ADDRESS')))}
            </label>
          </div>
        </div>

        <div className="action-wrapper flex-column justify-center w-full gap-3">
          <Button
            className={`delete-btn negative`}
            label={t('DELETE')}
            disabled={loading}
            loading={loading}
            onClick={() => handleDone()}
            size="large"
            borderRadius="100px"
          />
          <Button
            className={`cancel-btn default`}
            label={t('CANCEL')}
            disabled={loading}
            onClick={() => setModal(initModal)}
            size="large"
            borderRadius="100px"
          />
        </div>
      </JobPartnerDeleteWrapper>
    </CSSTransition>
  );
};

export const JobPartnerDeleteWrapper = styled.div`
  width: 384px;
  padding: 24px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  flex: 1 0 0;
`;

export default JobPartnerDelete;
