import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as UploadFileIcon } from '../../../assets/icons/upload-file.svg';
import IconContainer from '../icon-container';

const MediaDroparea = ({ setFiles = () => {} }) => {
  const { t } = useTranslation();

  const [onDroparea, setOnDroparea] = useState(false);

  const onAddMedia = addedFiles => {
    const newFiles = addedFiles?.map(file => ({
      file_data: file,
      name: file.name,
      size: file.size,
      type: file.type,
      url: URL.createObjectURL(file),
      id: nanoid(),
    }));
    setFiles(newFiles);
  };

  const onFileDrop = e => {
    e.preventDefault();
    onAddMedia([...e.dataTransfer?.files]);
    setOnDroparea(false);
  };

  const onFileAdd = e => {
    e.preventDefault();
    onAddMedia([...e?.target?.files]);
    e.target.value = null;
  };

  return (
    <MediaDropareaWrapper
      className={classNames(
        'py-10 border-dotted radius-1_5 flex-column items-center justify-center mb-6',
        onDroparea ? 'on-droparea' : 'off-droparea',
      )}
      onDragLeave={e => {
        e.preventDefault();
        setOnDroparea(false);
      }}
      onDragOver={e => {
        e.preventDefault();
        setOnDroparea(true);
      }}
      onDrop={onFileDrop}>
      <IconContainer
        iconContainerClassname="mr-2"
        Icon={UploadFileIcon}
        iconColor="natural_300"
        backgroundColor="transparent"
        iconWidth={52}
        iconHeight={52}
      />
      <p className="flex font-16 inter-400-text mt-4">
        <span className="natural-700-text mr-1">{t('DRAG_AND_DEOP_FILES_HERE')}</span>
        <p className="flex">
          <input type="file" multiple id="file-upload-input" style={{ display: 'none' }} onChange={onFileAdd} />
          <label htmlFor="file-upload-input" className="primary-text cursor">
            {t('BROWSE')}
          </label>
        </p>
      </p>
      <label className="font-12 inter-400-text natural-500-text mt-1">{t('MAXIMUM_FILE_SIZE_50MP')}</label>
    </MediaDropareaWrapper>
  );
};

const MediaDropareaWrapper = styled.div`
  &.off-droparea {
    border-color: ${({ theme }) => theme.natural_300};
    background-color: ${({ theme }) => theme.natural_50};
  }
  &.on-droparea {
    border-color: ${({ theme }) => theme.primary_500};
    background-color: ${({ theme }) => theme.primary_50};
  }
`;

export default MediaDroparea;
