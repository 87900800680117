import classNames from 'classnames';
import { motion } from 'framer-motion';
import moment from 'moment';
import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as Chart } from '../../assets/icons/property/chart.svg';
import { ReactComponent as Square } from '../../assets/icons/property/square.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/images/menu/arrow-down-black.svg';
import { ReactComponent as ArrowLeftIcon } from '../../assets/images/menu/arrow-right-next.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { capitalizeFirstLetterOfWords, formatText } from '../../helpers/utils';
import { getTaskSubTasks } from '../../store/features/taskSlice';
import { Avatar } from '../common/avatar-group';
import IconContainer from '../common/icon-container';
import MenuV2 from '../common/menu/menu-v2';
import Status from '../common/status';
import CustomTooltip from '../common/tooltip-new';

const statusOptions = [
  { label: 'To do', value: 'TODO' },
  { label: 'In-progress', value: 'STARTED' },
  { label: 'Blocked', value: 'BLOCKED' },
  { label: 'Done', value: 'COMPLETE' },
];

const priorityOptions = [
  { label: 'Highest', value: 'VERY_HIGH' },
  { label: 'High', value: 'HIGH' },
  { label: 'Medium', value: 'MEDIUM' },
  { label: 'Low', value: 'LOW' },
  { label: 'Lowest', value: 'VERY_LOW' },
];

const TaskListItem = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { setSideModal } = useContext(OrganisationContext);
  const { id: taskId, task, arrows = false, isPropertyTaskList, fetchTasks } = props;
  const {
    progress: { start_date, status },
    engagement,
    engagement: { workitems, property },
  } = task || {};
  const dispatch = useDispatch();
  const [showAccordion, setShowAccordion] = useState(null);
  const [subTasks, setSubTasks] = useState(false);
  const isAccordionOpened = task => {
    return task?.id === showAccordion;
  };

  const handleShowTaskDetails = (data, isSubTask = false, isJobDetailsTask = false) => {
    setSideModal({
      type: 'view-task-details',
      content: {
        taskDetails: data,
        isSubTask: isSubTask,
        isJobDetailsTask: isJobDetailsTask,
        onSuccess: () => {
          fetchTasks && fetchTasks();
        },
      },
    });
  };

  const fetchSubTasks = () => {
    dispatch(getTaskSubTasks({ task_id: task?.id }))
      .then(data => {
        setSubTasks(data);
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_SUB_TASKS') }));
        setSubTasks([]);
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (task?.id && task?.subtask_count > 0) {
      fetchSubTasks();
    }
  }, [task?.id]);

  const dateFormat = data => {
    const currentDate = moment();
    const isSameDay = currentDate.isSame(data, 'day');
    return (
      <div className="flex-column justify-center row-gap-2 task-items overflow-hidden py-3">
        <label className={`inter-400-text font-14 natural-900-text ${isSameDay && 'color-blue'}`}>
          {start_date ? (!isSameDay ? moment.unix(start_date).format('DD MMM') : 'Today') : '-'}
        </label>
      </div>
    );
  };

  const getStatusStyles = status => {
    switch (status) {
      case 'COMPLETE':
        return {
          color: theme.success_500,
          background: theme.success_50,
        };
      case 'BLOCKED':
        return {
          color: theme.primary_500,
          background: theme.primary_50,
        };
      case 'STARTED':
        return {
          color: theme.purple_500,
          background: theme.purple_50,
        };
      default:
        return {
          color: theme.natural_700,
          background: theme.natural_150,
        };
    }
  };

  const getStageNameOfJob = () => {
    const workitem = workitems?.[0] || {};
    const workitemLength = workitems?.length || 0;
    const stageName = workitem?.stage?.name || null;
    return stageName ? `${workitem?.stage?.name}${workitemLength > 1 ? ` + ${workitemLength}` : ''}` : '-';
  };

  const onJobClick = () => {
    const { workitems } = engagement;
    const firstWorkitem = workitems?.[0];
    if (firstWorkitem) {
      navigate(`/properties/property/${property?.id}/workitems/${firstWorkitem.id}`);
    }
  };

  const JobListItem = ({ tooltip, index }) => {
    const isLastItem = index === workitems?.length - 1;

    return (
      <div
        onClick={e => {
          e.stopPropagation();
          onJobClick();
        }}
        className={classNames(
          'flex-column justify-center gap-1 border-bottom-0 jobs-content-tooltip cursor',
          tooltip ? 'py-2 px-2' : 'py-2 px-2 my-2',
          !isLastItem && 'border-bottom',
        )}>
        <label className="inter-500-text natural-700-text line-height-20 font-14 text-ellipsis">
          {engagement?.name}
        </label>
        <div className="flex items-center gap-2 justify-between">
          <div className="flex items-center gap-2">
            <div className="flex gap-1">
              <Chart className="orange-500-text mr-1" />
              <label className="flex items-center inter-400-text natural-900-text line-height-20 font-14">
                {workitems[0]?.days_in_process || '0'}
              </label>
            </div>
            <div className="flex gap-1">
              <div className="flex items-center">
                <Square className="success-500-text" />
                <label className="flex items-center inter-400-text natural-900-text line-height-20 font-14">
                  {workitems[0]?.days_in_stage || '0'}
                </label>
              </div>
            </div>
          </div>

          {getStageNameOfJob() && (
            <div className="flex gap-1 cursor">
              <label className="color-purple bg-pink px-3 py-0_5 radius-6 inter-500-text font-12 line-height-20">
                {getStageNameOfJob()}
              </label>
            </div>
          )}
        </div>
      </div>
    );
  };

  const TaskContent = ({ taskData }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [priorityTooltipOpen, setPriorityTooltipOpen] = useState(false);
    const [assigneeTooltipOpen, setAssigneeTooltipOpen] = useState(false);
    const [jobTooltipOpened, setJobTooltipOpened] = useState(false);
    const {
      progress: { start_date, status },
      engagement,
      engagement: { workitems, property },
    } = taskData;
    const [selectedStatus, setSelectedStatus] = useState(statusOptions.find(item => item?.value === status));
    const [selectedPriority, setSelectedPriority] = useState(
      priorityOptions.find(item => item?.value === task?.priority),
    );
    const [searchText, setSearchText] = useState('');

    const StatusLabel = ({ data, isMainContent = false }) => {
      const { color, background } = useMemo(() => getStatusStyles(data?.value, theme), [data?.value, theme]);

      if (isMainContent) {
        return (
          <label
            style={{
              background,
              color,
              borderRadius: '50px',
              width: 'fit-content',
              height: '24px',
            }}
            onClick={e => {
              e.stopPropagation();
              setTooltipOpen(true);
            }}
            className="select-label flex items-center justify-between font-12 px-3 text-center gap-1">
            <label style={{ color }} className="font-12 inter-500-text fw-500">
              {data?.label}
            </label>
            <IconContainer
              Icon={ArrowDownIcon}
              iconColor={color}
              backgroundColor={background}
              iconContainerClassname="p-0"
              iconWidth={14}
              iconHeight={14}
            />
          </label>
        );
      } else {
        return (
          <label
            style={{
              background,
              color,
              borderRadius: '50px',
              height: '24px',
            }}
            className="flex items-center justify-center font-12 px-3 text-center w-full gap-1 font-12 fw-500">
            {data?.label}
          </label>
        );
      }
    };

    return (
      <>
        <div className="flex items-center overflow-hidden gap-4 py-3">
          <div className="flex flex-column items-start gap-1 overflow-hidden">
            <label className="inter-500-text font-14 natural-900-text one-line w-full">{taskData?.name}</label>
          </div>
        </div>
        <div className="flex-column justify-center row-gap-2 task-items overflow-hidden py-3">
          <p className="inter-400-text font-14 natural-400-text">{taskData?.subtask_count}</p>
        </div>
        <div className="flex-column justify-center row-gap-2 task-items overflow-hidden py-3">
          {dateFormat(start_date || '')}
        </div>
        {/* <div className="flex-column justify-center row-gap-2 task-items overflow-hidden py-3">
              <p className="inter-400-text font-14 natural-900-text">
                {capitalize(taskData?.task_type?.toLowerCase())}
              </p>
            </div> */}
        {!isPropertyTaskList && (
          <CustomTooltip
            wrapperClassName="status-tooltip"
            tooltipClassname="custom-scrollbar thin-scrollbar"
            id={`status-tooltip-${taskData?.id}`}
            place="bottom"
            clickable
            isOpen={tooltipOpen}
            onBlurTooltip={() => setTooltipOpen(false)}
            hidden={!tooltipOpen}
            noArrow={true}
            content={statusOptions
              ?.filter(status => status?.label !== selectedStatus?.label)
              ?.map((data, index) => (
                <div
                  key={data?.label}
                  onClick={e => {
                    e.stopPropagation();
                    setSelectedStatus(data);
                    setTooltipOpen(false);
                  }}
                  className="px-4 py-2 font-14 border-bottom cursor proposal-config">
                  <StatusLabel data={data} />
                </div>
              ))}>
            <StatusLabel data={selectedStatus} isMainContent={true} />
          </CustomTooltip>
        )}

        <CustomTooltip
          wrapperClassName="status-tooltip"
          tooltipClassname="custom-scrollbar thin-scrollbar"
          id={`priority-tooltip-${taskData?.id}`}
          place="bottom"
          clickable
          isOpen={priorityTooltipOpen}
          onBlurTooltip={() => setPriorityTooltipOpen(false)}
          hidden={!priorityTooltipOpen}
          noArrow={true}
          content={priorityOptions
            ?.filter(priority => priority?.label !== selectedPriority?.label)
            ?.map((data, index) => (
              <div
                key={data?.label}
                onClick={e => {
                  e.stopPropagation();
                  setSelectedPriority(data);
                  setPriorityTooltipOpen(false);
                }}
                className="px-4 py-2 font-14 border-bottom cursor proposal-config">
                <div className="flex items-center">
                  <Status
                    withDot={false}
                    withDottedBorder={false}
                    status={data?.value}
                    icon={true}
                    statusTextClassName="font-10"
                    statusText={capitalizeFirstLetterOfWords(formatText(data?.label))}
                  />
                </div>
              </div>
            ))}>
          <div
            className="flex items-center"
            onClick={e => {
              e.stopPropagation();
              setPriorityTooltipOpen(true);
            }}>
            <Status
              withDot={false}
              withDottedBorder={false}
              status={selectedPriority?.value}
              icon={true}
              statusTextClassName="font-10 line-height-20 inter-600-text"
              statusText={capitalizeFirstLetterOfWords(formatText(selectedPriority?.label))}
            />
          </div>
        </CustomTooltip>
        <div
          className=""
          onClick={e => {
            e.stopPropagation();
            setAssigneeTooltipOpen(true);
          }}>
          <Avatar
            backgroundColor={theme.primary_500}
            avatar={task?.assignee?.id ? task?.assignee : { name: '' }}
            variant="semi_large"
            avatarWrapperClassname="assign-user"
          />
        </div>
        {!isPropertyTaskList &&
          (workitems?.length > 1 ? (
            <div className="flex-column row-gap-2 justify-center overflow-hidden py-4">
              <CustomTooltip
                wrapperClassName="job-tooltip"
                tooltipClassname="custom-scrollbar thin-scrollbar"
                id={`${taskData?.id}-workflow`}
                place="bottom"
                clickable
                isOpen={jobTooltipOpened}
                hidden={!jobTooltipOpened}
                onBlurTooltip={() => setJobTooltipOpened(false)}
                noArrow={true}
                content={workitems?.map((data, index) => (
                  <JobListItem key={data?.id} tooltip={true} index={index} />
                ))}>
                <Status
                  onClick={e => {
                    e.stopPropagation();
                    setJobTooltipOpened(!jobTooltipOpened);
                  }}
                  withDot={false}
                  withDottedBorder={false}
                  status={'DRAFT'}
                  statusText={capitalizeFirstLetterOfWords(formatText(`${workitems?.length} ${t('JOBS')}`))}
                />
              </CustomTooltip>
            </div>
          ) : workitems?.length == 1 ? (
            workitems?.map((jobitem, index) => <JobListItem key={jobitem?.id} index={index} />)
          ) : (
            <div className="flex items-center col-gap-1">
              <label className="inter-400-text natural-400-text font-14 line-height-20">{t('NO_JOB')}</label>
            </div>
          ))}

        <MenuV2
          menuList={[
            {
              name: t('DETAILS'),
            },
          ]}
          menuClassName=" h-fit-content property-menu"
          iconClassname="my-4"
        />
      </>
    );
  };

  return (
    <TaskListItemWrapper
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}
      key={taskId}
      className="relative w-full">
      {task?.subtask_count && arrows ? (
        isAccordionOpened(task) ? (
          <ArrowDownIcon
            width={20}
            height={20}
            className={classNames('absolute arrow-icon cursor', isPropertyTaskList && 'arrow-icon-small')}
            onClick={e => {
              task?.subtask_count && setShowAccordion('');
              e.stopPropagation();
            }}
          />
        ) : (
          <ArrowLeftIcon
            width={20}
            height={20}
            className={classNames('grey-text-04 absolute arrow-icon cursor', isPropertyTaskList && 'arrow-icon-small')}
            onClick={e => {
              task?.subtask_count && setShowAccordion(task?.id);
              e.stopPropagation();
            }}
          />
        )
      ) : (
        <Fragment />
      )}
      <div
        className={`data-container cursor items-center sidebar-close-ignore ${
          isAccordionOpened(task) ? 'border-0' : ''
        }`}
        onClick={() => handleShowTaskDetails(task, false, isPropertyTaskList)}>
        <TaskContent taskData={task} />
      </div>

      {isAccordionOpened(task) &&
        subTasks &&
        subTasks.map(taskData => (
          <div
            key={taskData?.id}
            className={classNames('tasks-list flex-column gap-3 ', isAccordionOpened(task) && 'opened-subtask')}>
            <div className="">
              <div
                className={`data-container cursor items-center sidebar-close-ignore ${
                  isAccordionOpened(task) ? 'border-0' : 'border-bottom'
                }`}
                onClick={() => handleShowTaskDetails(taskData, true, isPropertyTaskList)}>
                <TaskContent taskData={taskData} />
              </div>
            </div>
          </div>
        ))}
    </TaskListItemWrapper>
  );
};

const TaskListItemWrapper = styled(motion.div)`
  .arrow-icon {
    left: 6px;
    top: 30px;
  }
  .arrow-icon-small {
    top: 24px;
  }

  &:hover {
    background: ${({ theme }) => theme.natural_50};
  }
  .status-tooltip {
    position: relative;
    .custom-tooltip {
      position: fixed !important;
      padding: 0;
      height: auto;
      max-height: 272px !important;
      overflow: auto;
      filter: none;
      border-radius: 6px;
      box-shadow: rgb(179 179 179 / 67%) 0px 0px 3px 0px !important;
    }

    .proposal-config {
      &:hover {
        background-color: ${({ theme }) => theme.natural_100};
      }
    }
    .assign-user {
      border: none;
      transition: all 0.1s;
      &:hover {
        box-shadow: #d3eafe 0px 1px 4px, #d3eafe 0px 0px 0px 3px;
      }
    }
  }
  .jobs-content-tooltip {
    &:hover {
      background-color: ${({ theme }) => theme.primary_50};
    }
  }
  .job-tooltip {
    position: relative;
    .custom-tooltip {
      position: fixed !important;
      padding: 0;
      width: 276px;
      height: auto;
      max-height: 272px !important;
      overflow: auto;
      filter: none;
      box-shadow: rgb(179 179 179 / 67%) 0px 0px 3px 0px !important;
    }
  }
`;

const JobWrapper = styled(motion.div)`
  padding: 6px 8px;
  border-radius: 4px;
  background: ${({ theme }) => theme.natural_100};

  &:hover {
    background: ${({ theme }) => theme.natural_50};
  }
`;

export default TaskListItem;
