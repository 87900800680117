import { createSlice } from '@reduxjs/toolkit';
import api from '../services';
export const campaignSlice = createSlice({
  name: 'jobs',
  initialState: {},
  reducers: {},
});

export const getCampaignsList =
  ({ params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/campaigns`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export default campaignSlice.reducer;
