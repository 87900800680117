import styled from 'styled-components';

const QuoteDetailsWrapper = styled.div`
  .input-container {
    .input {
      height: 26px;
      min-height: 26px;
      background-color: ${({ theme }) => theme.natural_100};
      border-color: ${({ theme }) => theme.natural_200};
      padding: 2px 8px;
      box-shadow: none;
      &:focus {
        box-shadow: 0px 0px 0px 4px ${({ theme }) => theme.focus_border};
      }
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }

    .cross-icon {
      width: 32px;
      height: 32px;
    }

    .right-icon {
      background-color: ${({ theme }) => theme.primary_500};
      width: 32px;
      height: 32px;
    }

    .quote-update-action {
      background-color: ${({ theme }) => theme.white};
      z-index: 999;
      box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1);
    }
  }

  .cancel-btn {
    width: 100px !important;
  }

  .save-button-btn {
    width: 150px !important;
  }

  .family-wrapper {
    min-width: 134px;
    max-width: 134px;
    height: 305px;
    overflow-y: auto;

    .selected {
      border-bottom: 2px solid ${({ theme }) => theme.primary_500};
    }
  }
`;

export default QuoteDetailsWrapper;
