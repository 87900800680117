import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageHeader from '../../components/page-header';
import PersonsList from '../../components/persons-list';
import useDebounce from '../../helpers/useDebounceHook';
import { getPersonsList } from '../../store/features/personsSlice';
import { PersonsWrapper } from '../../styles/pages/persons.styled';

const Persons = () => {
  const dispatch = useDispatch();
  const { personsList } = useSelector(state => state.persons);
  const { total_pages } = personsList || {};

  const [search, setSearchText] = useState('');
  const [selectedPage, setSelectedPage] = useState(0);
  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    setSelectedPage(0);
    if (debouncedSearch !== '') {
      dispatch(getPersonsList({ params: { search: debouncedSearch, page: 0, size: 15 } }));
    } else {
      dispatch(getPersonsList({ params: { page: 0, size: 15 } }));
    }
  }, [debouncedSearch]);

  useEffect(() => {
    if (debouncedSearch !== '') {
      dispatch(getPersonsList({ params: { search: debouncedSearch, page: selectedPage, size: 15 } }));
    } else {
      dispatch(getPersonsList({ params: { page: selectedPage, size: 15 } }));
    }
  }, [selectedPage]);

  const onAddClick = () => {
    // setModal({
    //   type: 'add-integration',
    //   content: { top: true },
    // });
  };

  return (
    <PersonsWrapper className="flex-column flex-1">
      <PageHeader
        headerTitle="Persons"
        showAddNewBtn
        showSearch
        showPagination
        onAddClick={onAddClick}
        addBtnPermission="AUTOMATIONS_MANAGE"
        searchText={search}
        showConfiguration
        showSorting
        onSearchChange={setSearchText}
        pagination={{ selectedPage: selectedPage, totalPages: total_pages, setSelectedPage: setSelectedPage }}
      />
      <PersonsList />
    </PersonsWrapper>
  );
};

export default Persons;

// search: debouncedSearch,
