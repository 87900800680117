import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as ClipBoard } from '../../assets/icons/copy-clipboard-icon.svg';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy-icons.svg';
import { ReactComponent as PinIcon } from '../../assets/icons/map-pin.svg';
import { ReactComponent as RightIcon } from '../../assets/icons/right-Icons.svg';
import { capitalizeFirstLetterOfWords, formatText, handleCopyClick } from '../../helpers/utils';
import IconContainer from '../common/icon-container';
import SkeletonTransition from '../common/skeleton-transition';
import Status from '../common/status';
import CustomTooltip from '../common/tooltip-new';

const PropertyDetails = ({ loading = false, showViewDetails = false }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { propertyDetails } = useSelector(state => state.property);
  const { formatted_address, id: property_Id, property_class, name } = propertyDetails || {};
  const { class_type } = property_class || {};

  const isCommercial = class_type === 'COMMERCIAL';
  const isLand = class_type === 'LAND';

  const [isCopy, setIsCopy] = useState('');

  const clickToViewDetails = () => {
    navigate(`/properties/property/${property_Id}`);
  };

  const onCopyClick = async value => {
    const response = await handleCopyClick(value);
    if (response) {
      setIsCopy(response);
      setTimeout(() => setIsCopy(''), 3000);
    }
  };

  return (
    <SkeletonTransition
      loading={loading}
      height={'260px'}
      containerClassName="line-height-1"
      baseColor="#CDCDCD"
      highlightColor="#E8E8E8">
      <PropertyDetailsWrapper
        key={'property-details'}
        initial={{ opacity: 0.3, y: 10 }}
        animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}
        className="card radius-2 pxy-6 flex-column">
        <div className="flex items-center justify-between pb-3 border-bottom">
          <label className="inter-600-text natural-900-text font-16">{t('PROPERTY')}</label>
          {class_type && (
            <Status
              withDot={false}
              withDottedBorder={false}
              status={class_type || '-'}
              statusText={capitalizeFirstLetterOfWords(formatText(class_type))}
            />
          )}
          {showViewDetails && (
            <label onClick={clickToViewDetails} className="inter-500-text primary-text cursor">
              {t('VIEW_DETAILS')}
            </label>
          )}
        </div>
        <div className="flex-column row-gap-3 mt-5">
          {(isCommercial || isLand) && name && (
            <div className="flex-column row-gap-1">
              <label className="flex-1 inter-400-text font-12 natural-400-text line-height-20">
                {t('PROPERTY_NAME')}
              </label>
              <label className="flex-1 inter-400-text natural-900-text">{name}</label>
            </div>
          )}
          <div className="flex-column row-gap-1">
            <label className="flex-1 inter-400-text font-12 natural-400-text line-height-20">{t('ADDRESS')}</label>
            <div className="flex">
              <IconContainer
                iconContainerClassname="mr-2 flex h-fit-content"
                iconClassName="natural-600-text"
                iconHeight={16}
                iconWidth={16}
                Icon={PinIcon}
                backgroundColor="natural_100"
              />
              <label className="flex-1 inter-400-text natural-900-text line-height-20">{formatted_address}</label>
              <CustomTooltip
                wrapperClassName="copy-to-clip-board-tooltip h-fit-content"
                id="tooltip"
                place="bottom-start"
                content={
                  <label className="flex col-gap-1 items-center">
                    {isCopy ? (
                      <>
                        <RightIcon />{' '}
                        <span className="copy-text inter-500-text font-12 white-text">{t('COPIED')}!</span>
                      </>
                    ) : (
                      <>
                        <CopyIcon />{' '}
                        <span className="copy-text inter-500-text font-12 white-text">{t('COPY_TO_CLIPBOARD')}</span>
                      </>
                    )}
                  </label>
                }>
                <IconContainer
                  iconContainerClassname="mr-2 flex h-fit-content"
                  iconClassName="natural-600-text cursor"
                  iconHeight={16}
                  iconWidth={16}
                  Icon={ClipBoard}
                  backgroundColor="natural_100"
                  onClick={() => onCopyClick(formatted_address)}
                />
              </CustomTooltip>
            </div>
          </div>
        </div>
      </PropertyDetailsWrapper>
    </SkeletonTransition>
  );
};

const PropertyDetailsWrapper = styled(motion.div)`
  .copy-to-clip-board-tooltip {
    .custom-tooltip {
      padding: 8px 12px 8px 10px;
      border-radius: 6px;
      background: #404040;
    }
  }
`;

export default PropertyDetails;
