import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ReactComponent as CrossIcon } from '../../assets/icons/CrossIcons.svg';
import { ReactComponent as DownIcon } from '../../assets/icons/down-arrow.svg';
import { ReactComponent as MobileIcon } from '../../assets/icons/mobile.svg';
import { ReactComponent as RightIcon } from '../../assets/icons/right-Icons.svg';
import { OrganisationContext } from '../../context/organisationContext';
import useDebounce from '../../helpers/useDebounceHook';
import { getContactValueByType, getFormattedDate } from '../../helpers/utils';
import { getQuoteTemplate } from '../../store/features/quotesSlice';
import { addToast } from '../../store/features/toastSlice';
import DateSelectorElement from '../common/date-selector/date-selector-element';
import IconContainer from '../common/icon-container';
import CustomTooltip from '../common/tooltip-new';
import EditQuoteRecipient from './EditQuoteRecipient';
import TemplateQuoteEdit from './TemplateQuoteEdit';
import { QuoteDetailsInfoWrapper, QuoteRecipientWrapper } from './wrapper';
import QuoteTemplateWrapper from './wrapper/QuoteTemplateWrapper';

const QuoteDetailsInfo = ({
  isQuoteEditable = true,
  quoteDetailsInitial,
  setQuoteDetailsInitial,
  notEditableQuoteInfo = '',
  isNewQuote = false,
  setQuoteTemplateList,
  defaultTemplate,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { hasPermission } = useContext(OrganisationContext);

  const [isEditKey, setEditKey] = useState('');
  const [quoteDetailsInfo, setQuoteDetailsInfo] = useState(quoteDetailsInitial || {});
  const [search, setSearchText] = useState('');
  const [showLoading, setShowLoading] = useState(true);

  const debouncedSearch = useDebounce(search, 500);

  const fetchQuoteTemplateList = (page, debouncedSearch, showLoading = true) => {
    setShowLoading(showLoading);
    dispatch(
      getQuoteTemplate({
        params: {
          page: page,
          search: debouncedSearch,
        },
      }),
    )
      .then(data => {
        setQuoteTemplateList(data);
        setShowLoading(false);
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_QUOTE_TEMPLATE_LIST'), id: nanoid() })),
          setQuoteTemplateList([]);
        setShowLoading(false);
      });
  };
  useEffect(() => {
    fetchQuoteTemplateList(0, debouncedSearch);
  }, [debouncedSearch]);

  const { code, description, created_on, expiry_date, recipient, status, issued_date, quote_template } =
    quoteDetailsInfo || {};
  const onUpdateQuote = (key, value) => {
    setQuoteDetailsInitial({ ...quoteDetailsInitial, [key]: value });
    setEditKey(null);
  };

  const handleEnterKeyPress = (event, key, value) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      onUpdateQuote(key, value);
    }
  };

  const handleInputFocus = event => {
    event.target.select();
  };

  const renderItem = (value, key, labelValue, noValueData = '', isEditable = true) => {
    return (
      <div
        className={classNames(
          'flex items-center col-gap-3 info-details-quote',
          key === 'DESCRIPTION' && 'flex-column row-gap-1 items-baseline px-3 py-2 overflow-hidden',
        )}>
        <label
          className={classNames(
            'inter-400-text natural-400-text font-12',
            isEditable && 'px-1',
            key !== 'DESCRIPTION' && 'font-14',
            key === 'DESCRIPTION' && 'one-line',
          )}>
          {labelValue}:
        </label>
        {!isQuoteEditable ? (
          <CustomTooltip
            wrapperClassName="quote-tooltip h-fit-content"
            id="quote-tooltip"
            place="bottom-start"
            content={<label className="flex col-gap-1 items-center">{notEditableQuoteInfo}</label>}>
            <label className={classNames('inter-400-text natural-900-text px-1', key !== 'DESCRIPTION' && 'one-line')}>
              {value}
            </label>
          </CustomTooltip>
        ) : (
          <div
            className={classNames(
              'flex ites-center radius-1_5',
              isEditable && isQuoteEditable && 'cursor quote-detail-item',
              key === 'DESCRIPTION' && 'w-full',
            )}
            onClick={() => isEditable && isQuoteEditable && setEditKey(key)}>
            <label
              onFocus={() => isEditable && isQuoteEditable && setEditKey(key)}
              tabIndex="0"
              className={classNames(
                'inter-400-text flex items-center one-line',
                value ? 'natural-900-text' : 'natural-400-text',
                key === 'DESCRIPTION' && 'block',
              )}>
              {value || noValueData}
            </label>
          </div>
        )}
      </div>
    );
  };

  const editContent = (key, value, labelValue, placeholder, type) => {
    return (
      <div className={classNames('flex-column w-full input-container relative', { 'pxy-2-3': key === 'description' })}>
        <label className="inter-400-text natural-400-text font-12 mb-1">{labelValue}</label>
        <input
          className={classNames('inter-400-text natural-900-text one-line input ', {
            'no-focus': key === 'description',
          })}
          value={value}
          autoFocus
          type={type}
          placeholder={placeholder}
          onFocus={handleInputFocus}
          onKeyDown={event => handleEnterKeyPress(event, key, value)}
          onBlur={() => onUpdateQuote(key, value)}
          onChange={({ target: { value } }) => setQuoteDetailsInfo({ ...quoteDetailsInfo, [key]: value })}
        />
        <div className="w-full flex justify-end">
          <div className="flex justify-center items-end radius-3 quote-update-action mt-1 border pxy-2 gap-2_5 absolute">
            <span
              className="flex justify-center items-center cross-icon border radius-100 cursor"
              onClick={() => {
                setEditKey(null);
                setQuoteDetailsInfo({ ...quoteDetailsInitial });
              }}>
              <CrossIcon color="#171717" />
            </span>
            <span
              className="flex justify-center items-center right-icon border radius-100 cursor"
              onClick={() => onUpdateQuote(key, value)}>
              <RightIcon color="#FFFFFF" />
            </span>
          </div>
        </div>
      </div>
    );
  };

  const onQuoteExpiryChange = date => {
    if (issued_date && date < issued_date) {
      dispatch(
        addToast({
          error: true,
          text: t('ERROR_UPDATE_EXPIRY_ON_DATE'),
          id: nanoid(),
        }),
      );
      return;
    }
    onUpdateQuote('expiry_date', date);
  };

  useEffect(() => {
    setQuoteDetailsInfo(quoteDetailsInitial);
  }, [quoteDetailsInitial]);

  return (
    <QuoteDetailsInfoWrapper className="flex-column row-gap-3 border-bottom pb-4 pt-5 px-6">
      <div className="items-center quote-info-sec-top ">
        <div className="flex-column row-gap-1">
          {isEditKey === 'CODE'
            ? editContent('code', code, t('QUOTE_NUMBER'), t('EXAMPLE_EH_0001'), 'text')
            : renderItem(
                code ?? '',
                'CODE',
                t('QUOTE_NUMBER'),
                t('EXAMPLE_EH_0001'),
                hasPermission('QUOTES_MANAGE') && isNewQuote,
              )}
        </div>
        <div className="line-vertical"></div>
        <div className="flex items-center col-gap-3 info-details-quote">
          <label className="inter-400-text natural-400-text font-14">{t('ISSUED')}:</label>
          <label className="inter-400-text natural-900-text">
            {!issued_date ? (
              <label className="inter-400-text natural-400-text">{t('NOT_ISSUED')}</label>
            ) : (
              getFormattedDate(issued_date, 'DD/MM/YYYY', false)
            )}
          </label>
        </div>
        <div className="line-vertical"></div>

        <div
          className="flex items-center col-gap-3 info-details-quote"
          onFocus={() => setEditKey('EXPIRY')}
          onBlur={() => setEditKey(null)}>
          <label className="inter-400-text natural-400-text font-14 ">{t('EXPIRY')}:</label>
          {isQuoteEditable ? (
            <DateSelectorElement
              selectedDate={expiry_date}
              isCalendarIcon={false}
              isOpen={isEditKey === 'EXPIRY'}
              useParentFocus={true}
              minStartDate={created_on}
              setSelectedDate={date => onQuoteExpiryChange(date)}
              className="quote-date-field"
              onCalendarClose={() => setEditKey(null)}
            />
          ) : (
            <CustomTooltip
              wrapperClassName="quote-tooltip h-fit-content"
              id="quote-tooltip"
              place="bottom-start"
              content={<label className="flex col-gap-1 items-center">{notEditableQuoteInfo}</label>}>
              <label className="inter-400-text natural-900-text">
                {getFormattedDate(expiry_date, 'DD/MM/YYYY', false)}
              </label>
            </CustomTooltip>
          )}
        </div>
        <div className="line-vertical"></div>

        <div id="template-wrapper" className="relative">
          {isEditKey === 'EDIT_TEMPLATE' ? (
            <TemplateQuoteEdit
              setEditKey={setEditKey}
              onUpdateQuote={onUpdateQuote}
              quoteDetailsInitial={quoteDetailsInitial}
              quote_template={quote_template}
            />
          ) : (
            <div className="flex-column row-gap-1">
              <QuoteTemplateWrapper>
                <div className="flex col-gap-3 items-center  radius-1_5 contact-info">
                  <label className="inter-400-text natural-400-text font-14">{t('TEMPLATE')}:</label>
                  <div className="flex items-center col-gap-2 flex-grow">
                    {isQuoteEditable ? (
                      <div
                        className="flex col-gap-2 edit-template-quote"
                        tabIndex="0"
                        onFocus={() => setEditKey('EDIT_TEMPLATE')}
                        onClick={() => setEditKey('EDIT_TEMPLATE')}>
                        <p
                          className={classNames(
                            'inter-400-text flex-1 one-line',
                            quote_template?.name || defaultTemplate?.name ? 'natural-900-text' : 'natural-400-text',
                          )}>
                          {quote_template?.name || defaultTemplate?.name}
                        </p>
                        <DownIcon height={16} width={16} className="o0 color-gray-secondary" />
                      </div>
                    ) : (
                      <CustomTooltip
                        id="quote-tooltip"
                        place="bottom-start"
                        content={<label className="flex col-gap-1 items-center">{notEditableQuoteInfo}</label>}>
                        <div className="flex pr-6">
                          <p
                            className={classNames(
                              'inter-400-text natural-400-text',
                              quote_template?.name && 'natural-900-text',
                            )}>
                            {quote_template?.name || t('NO_TEMPLATE')}
                          </p>
                        </div>
                      </CustomTooltip>
                    )}
                  </div>
                </div>
              </QuoteTemplateWrapper>
            </div>
          )}
        </div>
      </div>
      <div className=" items-center  quote-info-sec-bottom">
        <div className="flex-column row-gap-1 w-full bg-natural-50">
          {isEditKey === 'DESCRIPTION'
            ? editContent('description', description, t('DESCRIPTION'), t('DESCRIPTION_PLACEHOLDER'), 'text')
            : renderItem(
                description ?? '',
                'DESCRIPTION',
                t('DESCRIPTION'),
                t('NO_DESCRIPTION'),
                hasPermission('QUOTES_MANAGE'),
              )}
        </div>
        <div className="flex col-gap-6 items-center justify-end w-full">
          <div id="recipient-wrapper" className="relative w-full">
            {isEditKey === 'EDIT_RECIPIENT' ? (
              <EditQuoteRecipient
                setEditKey={setEditKey}
                onUpdateQuote={onUpdateQuote}
                quoteDetailsInitial={quoteDetailsInitial}
                recipient={recipient}
              />
            ) : (
              <div className="flex-column row-gap-1">
                <QuoteRecipientWrapper>
                  <div className="flex-column row-gap-1 px-3 py-2 radius-1_5 contact-info">
                    <label className="inter-400-text natural-400-text font-12">{t('CONTACT_INFORMATION')}</label>
                    <div className="flex items-center col-gap-6">
                      {isQuoteEditable ? (
                        <div
                          className="flex col-gap-2 edit-recipient flex-grow"
                          tabIndex="0"
                          onFocus={() => setEditKey('EDIT_RECIPIENT')}
                          onClick={() => setEditKey('EDIT_RECIPIENT')}>
                          {recipient?.contact_type === 'COMPANY' ? (
                            <p
                              className={classNames(
                                'inter-400-text flex-1',
                                recipient?.company_name ? 'natural-900-text' : 'natural-400-text',
                              )}>
                              {recipient?.company_name || t('NAME')}
                            </p>
                          ) : (
                            <p
                              className={classNames(
                                'inter-400-text flex-1',
                                recipient?.name ? 'natural-900-text' : 'natural-400-text',
                              )}>
                              {recipient?.name || t('NAME')}
                            </p>
                          )}
                          <DownIcon height={16} width={16} className="o0 color-gray-secondary" />
                        </div>
                      ) : (
                        <CustomTooltip
                          id="quote-tooltip"
                          place="bottom-start"
                          content={<label className="flex col-gap-1 items-center">{notEditableQuoteInfo}</label>}>
                          <div className="flex pr-6">
                            <p className="inter-400-text natural-900-text">{recipient?.name}</p>
                          </div>
                        </CustomTooltip>
                      )}
                      <div className="flex items-center col-gap-1 flex-grow">
                        <IconContainer Icon={MobileIcon} iconColor="natural_500" backgroundColor="transparent" />
                        <label className="inter-400-text natural-900-text">
                          {getContactValueByType(recipient?.phones || [], 'contact_type', 'MOBILE', 'value')}
                        </label>
                      </div>
                    </div>
                  </div>
                </QuoteRecipientWrapper>
              </div>
            )}
          </div>
        </div>
      </div>
    </QuoteDetailsInfoWrapper>
  );
};

export default QuoteDetailsInfo;
