import { motion } from 'framer-motion';
import { nanoid } from 'nanoid';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as CrossIcon } from '../../assets/icons/CrossIcons.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { ReactComponent as EmptyIcon } from '../../assets/icons/empty-document.svg';
import { ReactComponent as FileIcon } from '../../assets/icons/file.svg';
import { downloadEvidence } from '../../store/features/downloadSlice';
import { addToast } from '../../store/features/toastSlice';
import Checkbox from '../common/checkbox';
import IconContainer from '../common/icon-container';
import NoData from '../common/no-data';
import ViewFile from '../view-file/view-file';
import ViewFileImage from '../view-file/view-file-image';

const Files = props => {
  const { t } = useTranslation();
  const { setFiles, files, loading } = props;
  const dispatch = useDispatch();

  const [checked, setChecked] = useState([]);
  const [isDownload, setIsDownload] = useState(false);
  const [showActivityImage, setShowActivityImage] = useState(false);
  const [showActivityFile, setShowActivityFile] = useState(false);

  const onDownloadFile = () => {
    setIsDownload(true);
    if (checked.length > 0) {
      const evidenceIds = checked.map(fileId => ({ id: fileId }));

      dispatch(downloadEvidence({ request: evidenceIds, filename: 'evidence.zip' }))
        .then(() => {
          setChecked([]);
          setIsDownload(false);
        })
        .catch(error => {
          dispatch(addToast({ error: true, text: t('ERROR_WHILE_DOWNLOADING_FILES'), id: nanoid() }));
          setIsDownload(false);
        });
    }
  };

  const onViewActivityFile = file => {
    if (file?.media?.format === 'pdf') {
      setShowActivityFile(file);
    } else if (file?.media?.type === 'PHOTO') {
      setShowActivityImage(file);
    } else {
      dispatch(
        addToast({
          error: true,
          text: file?.media ? t('FORMAT_NOT_SUPPORTED_TO_VIEW') : t('THERE_IS_NO_MEDIA_ATTECHED'),
          id: nanoid(),
        }),
      );
    }
  };

  return (
    <FilesWrapper>
      <div className="flex-column gap-4">
        {checked?.length ? (
          <div className="select-files justify-between items-center radius-full py-2 px-4">
            <div className="flex items-center gap-2">
              <CrossIcon height={20} width={20} className="cursor" onClick={() => setChecked([])} />
              <span className="font-14 inter-500-text color-neutral-900">
                {checked?.length} {t('SELECTED')}
              </span>
            </div>
            <div className="download-icon flex items-center justify-center border radius-full cursor">
              <DownloadIcon height={14} width={14} onClick={onDownloadFile} />
            </div>
          </div>
        ) : null}

        {files.length > 0 && (
          <div className="flex items-center gap-2">
            <Checkbox
              onChange={() => {
                if (checked.length === files.length) {
                  setChecked([]);
                } else {
                  setChecked(files.map(file => file.id));
                }
              }}
              checked={checked.length === files.length}
              is_checked_done={true}
            />
            <span className="font-14 inter-400-text color-neutral-700">{t('SELECT_ALL')}</span>
          </div>
        )}

        {loading ? (
          <div className="item w-full flex items-center justify-center flex-1">
            <Skeleton
              height={'50vh'}
              containerClassName="line-height-1 flex-1 radius-2"
              baseColor="#CDCDCD"
              highlightColor="#E8E8E8"
            />
          </div>
        ) : (
          <FileListWrapper className="flex-column flex-1 overflow-hidden inter-600-text font-12">
            {files.length > 0 ? (
              <motion.div
                key={'file-details'}
                initial={{ opacity: 0.3, y: 10 }}
                animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}
                className="w-full overflow-scroll">
                <div className="flex gap-3 fw-w">
                  {(files || []).map((file, index) => (
                    <FileListItemWrapper
                      initial={{ opacity: 0.3, y: 10 }}
                      animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}
                      key={file.id}
                      onDoubleClick={() => onViewActivityFile(file)}
                      className="">
                      <Checkbox
                        onChange={() => {
                          if (checked.includes(file.id)) {
                            setChecked(checked?.filter(id => id !== file.id));
                          } else {
                            setChecked([...checked, file.id]);
                          }
                        }}
                        checked={checked.includes(file.id)}
                        className="select-checkbox absolute"
                        is_checked_done={true}
                      />
                      {file?.media?.thumbnail_url && file?.media?.type === 'PHOTO' ? (
                        <div className=" file-img relative">
                          <img alt={file?.name} className="" src={file?.media?.thumbnail_url} />
                          <div className="absolute content-box flex items-end px-3 pb-2">
                            <p className="flex gap-6px font-14 inter-400-text white-text items-center">
                              <span className="dot"></span> {file?.name}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className="relative file-box h-full">
                          <IconContainer Icon={FileIcon} iconColor="natural_500" iconContainerClassname="h-full" />
                          <div className="absolute flex items-end fill-info px-3 pb-2">
                            <p className="font-14 inter-500-text natural-700-text">
                              {file?.name}
                              {file?.media?.format}
                            </p>
                          </div>
                        </div>
                      )}
                    </FileListItemWrapper>
                  ))}
                </div>
              </motion.div>
            ) : (
              <NoData
                title={t('NO_FILES')}
                description={t('NO_FILES_HERE')}
                className="mt-12"
                EmptyIcon={EmptyIcon}
                iconClassName="relative"
              />
            )}
          </FileListWrapper>
        )}
        {showActivityFile && (
          <ViewFile
            close={() => setShowActivityFile(null)}
            file={showActivityFile}
            selectedDoc={showActivityFile}
            downloadPdf={onDownloadFile}
          />
        )}
        {showActivityImage && (
          <ViewFileImage
            close={() => setShowActivityImage(null)}
            file={showActivityImage}
            selectedDoc={showActivityImage}
          />
        )}
      </div>
      {isDownload && (
        <div className="flex justify-end items-center relative">
          <div className="download-toast justify-between items-center radius-full pxy-3 absolute">
            <div className="flex items-center gap-3">
              <div className="flex items-center justify-center border downloading">
                <DownloadIcon height={14} width={14} />
              </div>
              <span className="font-14 inter-400-text color-neutral-900">
                {t('DOWNLOADING')} {checked?.length} {t('FILES')}
              </span>
            </div>
            <CrossIcon height={16} width={16} className="cursor" />
          </div>
        </div>
      )}
    </FilesWrapper>
  );
};

const FilesWrapper = styled.div`
  .select-files {
    background: ${({ theme }) => theme.natural_50};

    .download-icon {
      width: 32px;
      height: 32px;
    }
  }

  .download-toast {
    width: 415px;
    border-radius: 6px;
    bottom: 12px;
    right: -12px;
    z-index: 99;
    background-color: #fff;
    box-shadow: 0px 20px 25px -5px rgba(16, 24, 40, 0.1), 0px 8px 10px -6px rgba(16, 24, 40, 0.1);
    .downloading {
      width: 28px;
      height: 28px;
      border-radius: 6px;
      background: ${({ theme }) => theme.natural_100};
    }
  }
`;

const FileListWrapper = styled.div`
  .header-container,
  .data-container {
    display: grid;
  }

  .header-container {
    background-color: ${({ theme }) => theme.natural_50};
  }

  .file-box-border {
    border: 0.25px solid #e5e5e5 !important;
  }
`;

const FileListItemWrapper = styled(motion.div)`
  width: 136px;
  height: 136px;
  position: relative;

  .select-checkbox {
    top: 9px;
    left: 9px;
    z-index: 9;
  }
  .file-img {
    width: 100%;
    border-radius: 12px;
    height: 100%;
    overflow: hidden;
    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
    .content-box {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 13.76%, rgba(0, 0, 0, 0) 100%);
      height: 60px;
      width: 100%;
      bottom: 0px;
      left: 0px;
      .dot {
        width: 6px;
        height: 6px;
        background-color: #2496ff;
        border-radius: 20px;
      }
    }
  }
  .file-box {
    border-radius: 12px;
    border: 1px solid #e5e5e5;
    background: #f5f5f5;
    .fill-info {
      bottom: 0px;
      left: 0px;
      width: 100%;
      height: 60px;
    }
    svg {
      width: 36px;
      height: 36px;
    }
  }
`;

export default Files;
