import styled from 'styled-components';

export const UsersListWrapper = styled.div`
  border-radius: 6px;
  .header-container,
  .data-container {
    display: grid;
    grid-template-columns: minmax(150px, 230px) minmax(200px, 350px) minmax(350px, 1fr) minmax(150px, 200px);
    border-bottom: 1px solid ${({ theme }) => theme.lightGrey};
    padding: 16px 24px;
  }

  .header-container {
    height: 52px;
    background-color: ${({ theme }) => theme.natural_50};
  }
  .no-data-container {
    width: 300px;
  }
`;

export const UsersListItemWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(150px, 230px) minmax(200px, 350px) minmax(350px, 1fr) minmax(150px, 200px);
  min-height: 80px;
  border-bottom: 1px solid ${({ theme }) => theme.lightGrey};
  padding: 16px 24px;

  &:hover {
    background-color: ${({ theme }) => theme.natural_50};
  }

  .icon-wrapper {
    background: ${({ theme }) => theme.primaryGrey};
    padding: 5px;
    border-radius: 6px;
  }

  .active {
    background: ${({ theme }) => theme.success_50};
    label {
      color: ${({ theme }) => theme.success_500};
    }
  }

  .inactive {
    background-color: ${({ theme }) => theme.orange_50};
    label {
      color: ${({ theme }) => theme.orange_500};
    }
  }

  .expired {
    background-color: #fef2f2;
    label {
      color: ${({ theme }) => theme.additionalRedDark} !important;
    }
  }

  .invited {
    background: ${({ theme }) => theme.orange_50};
    label {
      color: ${({ theme }) => theme.orange_500};
    }
  }

  .dots-icon {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .menu-wrapper {
    padding: 0 !important;
    border-radius: 0px !important;
    min-width: 180px !important;

    .menu-item {
      border-radius: 0px;
      border-bottom: 1px solid ${({ theme }) => theme.natural_200};
      border-top: none;
      &:hover {
        background-color: ${({ theme }) => theme.natural_50};
      }
    }
  }

  .active-wrapper {
    &:hover {
      background-color: ${({ theme }) => theme.natural_50};
    }
  }

  .toggle-btn {
    margin: 0 !important;
  }

  .custom-tooltip {
    border-radius: 6px;
    overflow: hidden;
  }
`;
