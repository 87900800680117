import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { OrganisationContext } from '../../context/organisationContext';
import { useFilteredMenuList } from '../../hooks/useFilteredMenuList';
import MenuV2 from '../common/menu/menu-v2';

const RolesListItem = ({ roles, menuPortal }) => {
  const { t } = useTranslation();

  const { setModal, setSideModal } = useContext(OrganisationContext);

  const onDeleteRole = () => {
    setModal({
      type: 'roles-action',
      content: {
        roles: roles,
        top: false,
        isDelete: true,
        title: t('ARE_YOU_SURE_WANT_TO_DELETE_THIS_ROLE'),
        description: t('DELETE_ACTION_DESCRIPTION'),
      },
    });
  };

  const redirectToRolesDetails = () => {
    setSideModal({
      type: 'view-role-details',
      content: {
        roles: roles,
        onDeleteRole: onDeleteRole,
      },
    });
  };

  return (
    <RolesListItemWrapper
      className="data-container items-center justify-center w-full sidebar-close-ignore py-0 cursor"
      onClick={() => redirectToRolesDetails()}>
      <div className="brd-right flex items-cente w-full">
        <label className="inter-500-text color-neutral-900 ont-14 one-line">{roles?.name}</label>
      </div>
      <div className="brd-right flex items-center w-full overflow-hidden">
        <label className="inter-400-text color-neutral-900 font-14 one-line">{roles?.description}</label>
      </div>
      <div>
        <MenuV2
          menuList={useFilteredMenuList({
            menuList: [
              { name: t('DETAILS'), onClick: () => redirectToRolesDetails(), permission: 'ROLES_VIEW' },
              { name: t('DELETE'), onClick: () => onDeleteRole(), permission: 'ROLES_MANAGE' },
            ],
          })}
          iconClassName="rotate-90 dots-icon"
          portal={menuPortal}
        />
      </div>
    </RolesListItemWrapper>
  );
};

const RolesListItemWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.lightGrey};

  &:hover {
    background-color: ${({ theme }) => theme.lightGreyBackground};
  }
`;

export default RolesListItem;
