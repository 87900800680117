import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AutomationDetailsPage from '../../components/automation-details';
import PageHeader from '../../components/page-header';
import { getAutomationsDetails, getUsersList } from '../../store/features/automationsSlice';
import { AutomationDetailContainerWrapper } from '../../styles/pages/automation-details.styled';

const AutomationDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { automationDetails } = useSelector(state => state.automations);

  useEffect(() => {
    dispatch(getAutomationsDetails(id));
    dispatch(getUsersList({ params: { size: 15 } }));
  }, []);

  return (
    <AutomationDetailContainerWrapper className="w-full">
      <PageHeader
        names={[
          { text: 'Platforms', onClick: () => {} },
          { text: 'Automation', path: '/platforms/automations' },
          { text: automationDetails?.name, onClick: () => {} },
        ]}
      />
      <AutomationDetailsPage />
    </AutomationDetailContainerWrapper>
  );
};

export default AutomationDetails;
