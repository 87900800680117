import React from 'react';
import TeamDetailsBody from './team-name-details-body';
import TeamsNameDetailsHeader from './team-name-details-header';

const TeamDetails = () => {
  return (
    <div className="border flex-column left-box justify-start  h-full content-box radius-4 " style={{ width: '350px' }}>
      <TeamsNameDetailsHeader />
      <TeamDetailsBody />
    </div>
  );
};

export default TeamDetails;
