import styled from 'styled-components';

export const AutomationLogDetailWrapper = styled.div`
  overflow: hidden;

  .status-container {
    padding: 1px 4px;
    max-height: 24px;
  }

  .success {
    background: ${({ theme }) => theme.success_50};
    label {
      color: #ffff;
      color: ${({ theme }) => theme.success_500};
    }
  }
  .cancelled {
    background: ${({ theme }) => theme.darkGrey};
    label {
      color: #ffff;
      color: ${({ theme }) => theme.natural_700};
    }
  }
  .pending {
    background: ${({ theme }) => theme.orange_50};
    label {
      color: #ffff;
      color: ${({ theme }) => theme.orange_500};
    }
  }

  .skipped {
    background: ${({ theme }) => theme.additionalYellow2};
    label {
      color: #ffff;
    }
  }

  .failure {
    background: ${({ theme }) => theme.error_50};
    label {
      color: #ffff;
      color: ${({ theme }) => theme.error_500};
    }
  }
  .partialfail {
    background: ${({ theme }) => theme.additionalRed};
    label {
      color: #ffff;
    }
  }
`;

export const AutomationLogDetailLeftWrapper = styled.div`
  .left-box-wrapper {
    height: fit-content;
    min-width: 350px;
    max-width: 350px;
    border-radius: 12px;
    max-height: 100%;
    overflow: hidden;
  }
`;

export const AutomationLogDetailsRightWrapper = styled.div`
  border-radius: 12px;
  max-height: 100%;

  .vertical-line {
    height: 24px;
    border-color: red;
  }
  .automation-log-action-wrapper {
    .header-border-left {
      border-left: 4px solid ${({ theme }) => theme.additionalGreen};
    }

    .action-container {
      width: calc(100% - 24px);
    }

    .retry-text {
      color: ${({ theme }) => theme.selectedText};
    }

    .sub-header-border {
      border: 1px solid ${({ theme }) => theme.borderColor};
      width: calc(100% - 24px);
    }

    .schedule-conditon-wrapper {
      background: ${({ theme }) => theme.lightGreyBackground};
    }

    .dot {
      height: 6px;
      width: 6px;
      border-radius: 50%;
      background: ${({ theme }) => theme.primaryGreen};
    }
  }
`;
