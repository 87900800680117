import React from 'react';
import styled from 'styled-components';
import { ReactComponent as LogoZervioWhite } from '../../../assets/images/logo-zervio-white.svg';

const AuthContainer = ({ children }) => {
  return (
    <AuthContainerWrapper className="flex">
      <div className="flex flex-1 items-center justify-center logo-container">
        <LogoZervioWhite />
      </div>
      <div className="flex flex-1 items-center justify-center children-container overflow-scroll">{children}</div>
    </AuthContainerWrapper>
  );
};

const AuthContainerWrapper = styled.div`
  height: 100dvh;
  width: 100dvw;
  overflow: hidden;
  .logo-container {
    background: linear-gradient(135deg, rgba(46, 155, 255, 1), rgba(49, 236, 225, 1));
  }
`;

export default AuthContainer;
