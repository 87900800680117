import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import uuid from 'react-uuid';
import styled from 'styled-components';
import { ReactComponent as AlertIcon } from '../../assets/icons/AlertIcon.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/CrossIcons.svg';
import Button from '../../components/common/button/button';
import { OrganisationContext } from '../../context/organisationContext';
import { initModal } from '../../helpers/utils';
import { deleteRoofEdit } from '../../store/features/propertySlice';
import { addToast } from '../../store/features/toastSlice';

const DeleteActionRoofSegment = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { modal, setModal } = useContext(OrganisationContext);
  const {
    title,
    description,
    roof_id,
    property_id,
    isSegment,
    onSuccess = () => {},
    selectedDesign = {},
  } = modal.content;

  const [loading, setLoading] = useState(false);

  const handleDone = () => {
    setLoading(true);
    dispatch(deleteRoofEdit({ id: selectedDesign.id, roof_id: roof_id }))
      .then(data => {
        onSuccess();
        dispatch(
          addToast({
            error: false,
            text: isSegment ? t('SEGMENT_DELETED_SUUCESSFULLY') : t('ROOF_DELETED_SUUCESSFULLY'),
          }),
        );
        setModal(initModal);
        setLoading(false);
      })
      .catch(({ response }) => {
        const { data } = response;
        dispatch(
          addToast({
            error: true,
            text: data?.error_description
              ? data?.error_description
              : isSegment
              ? t('ERROR_WHILE_DELETING_SEGMENT')
              : t('ERROR_WHILE_DELETING_ROOF'),
            id: uuid(),
          }),
        );
        setLoading(false);
      });
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <RoofSegmentActionWrapper>
        <div className="flex-column content-wrapper items-center relative">
          <AlertIcon />
          <CrossIcon className="absolute right-0 cursor" onClick={() => setModal(initModal)} />

          <div className="flex-column gap-2 items-center justify-start w-full">
            <label className="inter-500-text font-18 text-center">{title}</label>
            <p className="inter-400-text  text-center">{description}</p>
          </div>
        </div>
        <div className="action-wrapper flex-column justify-center w-full gap-3">
          <Button
            className={`delete-btn negative `}
            label={t('DELETE')}
            disabled={loading}
            onClick={() => handleDone()}
            size="large"
            borderRadius="100px"
          />
          <Button
            className={`cancel-btn `}
            label={t('CANCEL')}
            disabled={loading}
            onClick={() => setModal(initModal)}
            size="large"
            borderRadius="100px"
          />
        </div>
      </RoofSegmentActionWrapper>
    </CSSTransition>
  );
};

export const RoofSegmentActionWrapper = styled.div`
  width: 384px;
  padding: 24px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  flex: 1 0 0;
  .content-wrapper {
    gap: 20px;
    label {
      color: #171717;
    }
    p {
      color: #737373;
    }
  }
  .action-wrapper {
    button {
      display: flex;
      padding: 12px 20px;
      justify-content: center;
      font-size: 16px;
      line-height: 24px;
      align-items: center;
      gap: 8px;
      align-self: stretch;
    }
    .delete-btn {
      background: ${({ theme }) => theme.additionalRedDark};
    }
    .cancel-btn {
      border: 1px solid #e5e5e5;
      background: #fff;
      color: #404040;
    }
  }
`;

export default DeleteActionRoofSegment;
