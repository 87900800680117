import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useFilteredMenuList } from '../../hooks/useFilteredMenuList';
import MenuV2 from '../common/menu/menu-v2';

const ProjectListItem = ({ projects, onDeleteProject, redirectToProjectDetails, menuPortal }) => {
  const { t } = useTranslation();

  return (
    <ProjectListItemWrapper
      className="data-container items-center justify-center w-full py-0 cursor sidebar-close-ignore"
      onClick={() => redirectToProjectDetails(projects)}>
      <div className="brd-right flex items-cente w-full">
        <label className="inter-500-text color-neutral-900 ont-14 one-line">{projects?.name || '-'}</label>
      </div>
      <div className="brd-right flex items-center w-full overflow-hidden">
        <label className="inter-400-text color-neutral-900 font-14 one-line">{projects?.description || '-'}</label>
      </div>
      <div className="brd-right flex items-center w-full overflow-hidden">
        <label className="inter-400-text color-neutral-900 font-14 one-line">{projects?.code || '-'}</label>
      </div>
      <div>
        <MenuV2
          menuList={useFilteredMenuList({
            menuList: [
              { name: t('EDIT'), onClick: () => redirectToProjectDetails(projects), permission: '' },
              // { name: t('DUPLICATE'), onClick: () => {}, permission: '' },
              { name: t('DELETE'), onClick: () => onDeleteProject(projects), permission: '' },
            ],
          })}
          iconClassname="rotate-90 dots-icon"
          portal={menuPortal}
        />
      </div>
    </ProjectListItemWrapper>
  );
};

const ProjectListItemWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.lightGrey};

  &:hover {
    background-color: ${({ theme }) => theme.natural_50};
  }
`;

export default ProjectListItem;
