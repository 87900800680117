import { createSlice } from '@reduxjs/toolkit';
import api from '../services';

export const addNewPropertySlice = createSlice({
  name: 'newPropety',
  initialState: {
    propertyAddress: [],
  },
  reducers: {
    setPropertyAddress: (state, { payload }) => {
      const { data, merge } = payload;
      if (merge) {
        state.propertyAddress = { ...data, content: [...state.propertyAddress.content, ...data.content] };
      } else {
        state.propertyAddress = data;
      }
    },
    setPropertyDetails: (state, { payload }) => {
      state.propertyDetails = payload;
    },
    setPropertyContacts: (state, { payload }) => {
      state.propertyContacts = payload;
    },
    setPropertyEpcs: (state, { payload }) => {
      state.propertyEpcs = payload;
    },
    setPropertyEngageMent: (state, { payload }) => {
      state.propertyEngageMents = payload;
    },
  },
});

export const getPropertyAddress =
  ({ params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/addresses`, { params: params });
      dispatch(setPropertyAddress({ data }));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getPropertyAddressById =
  ({ id }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/addresses/${id}`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getContactDetails =
  ({ params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/contacts`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const createProperty =
  ({ request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/properties`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const { setPropertyAddress, setPropertyDetails, setPropertyContacts, setPropertyEpcs, setPropertyEngageMent } =
  addNewPropertySlice.actions;
export default addNewPropertySlice.reducer;
