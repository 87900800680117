import { motion } from 'framer-motion';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as AddIcon } from '../../assets/icons/add-icon.svg';
import { ReactComponent as EmptyIcon } from '../../assets/icons/empty-document.svg';
import { ReactComponent as NoSearchIcon } from '../../assets/icons/no-search-result.svg';
import { OrganisationContext } from '../../context/organisationContext';
import useDebounce from '../../helpers/useDebounceHook';
import { getFormattedNumber } from '../../helpers/utils';
import { getCostsList } from '../../store/features/costsSlice';
import { addToast } from '../../store/features/toastSlice';
import Button from '../common/button/button';
import InfiniteScrollV2 from '../common/infinite-scroll-v2';
import InputSearch from '../common/input-search';
import NoData from '../common/no-data';
import SkeletonTransition from '../common/skeleton-transition';
import TableHeader from '../common/table-header';
import CostsListItem from './costs-list-item';

const PropertyDetailsCosts = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setModal, hasPermission } = useContext(OrganisationContext);
  const { workitemDetails } = useSelector(state => state.board);
  const engagement_id = workitemDetails?.engagement?.id;
  const costsTableHeaders = useMemo(() => {
    return [
      { name: t('NAME'), key: 'NAME' },
      { name: t('QTY'), key: 'QTY', classname: 'text-center' },
      { name: t('COST_TYPE'), key: 'COST_TYPE', classname: 'text-center' },
      { name: t('SALE_PRICE'), key: 'SALE_PRICE', classname: 'text-center' },
      { name: t('COST_PRICE'), key: 'COST_PRICE', classname: 'text-center' },
      { name: '', key: 'MENU' },
    ];
  }, []);

  const [costsList, setCostsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const saleTotalAmountSum = costsList.reduce((sum, item) => sum + item.sale_total_amount, 0);
  const costTotalAmountSum = costsList.reduce((sum, item) => sum + item.cost_total_amount, 0);

  const fetchCostsData = debouncedSearch => {
    setLoading(true);
    dispatch(
      getCostsList({
        engagement_id: engagement_id,
        params: { search: debouncedSearch },
      }),
    )
      .then(data => {
        setCostsList(data);
      })
      .catch(() => dispatch(addToast({ error: true, text: t('ERROR_FETCHING_COSTS_LIST') })))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchCostsData(debouncedSearch);
  }, [debouncedSearch]);

  const onAddEditCosts = (costs, id) => {
    setModal({
      type: 'add-costs',
      content: {
        costsData: costs,
        isEdit: id ? true : false,
        engagement_id: engagement_id,
        onSuccess: () => fetchCostsData(debouncedSearch),
      },
    });
  };

  const onDeleteCosts = costs => {
    setModal({
      type: 'delete-costs',
      content: {
        costs: costs,
        title: t('REMOVE_THIS_COSTS'),
        descriptions: t('REMOVE_THIS_COSTS_DESCRIPTION'),
        onSuccess: () => fetchCostsData(debouncedSearch),
      },
    });
  };

  return (
    <PropertCostsWrapper
      key={'property-costs'}
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}
      className=" flex-column flex-1 overflow-hidden">
      <div className="flex justify-between pxy-6">
        <div className="flex align-center">
          <InputSearch placeholder={t('SEARCH')} value={search} onChange={setSearch} />
        </div>
        {hasPermission('JOB_COSTS_MANAGE') && (
          <Button
            fontSize="14px"
            width="117px"
            size="average"
            borderRadius="100px"
            lableSize="font-14 inter-500-text w-63px line-height-20"
            className="primary specified-width px-4 py-2_5 ml-1_5"
            afterIcon={<AddIcon className="white-text mr-0" height={16} width={16} />}
            label={t('ADD_COST')}
            onClick={() => onAddEditCosts(costsList?.map(costsData => costsData))}
          />
        )}
      </div>
      <div className="w-full flex-column row-gap-6 flex-1  overflow-hidden">
        <SkeletonTransition
          loading={loading}
          loaderClassName="item w-full flex items-center justify-center flex-1 mb-6"
          height={'50vh'}
          containerClassName="line-height-1 flex-1"
          baseColor="#CDCDCD"
          highlightColor="#E8E8E8">
          {costsList?.length > 0 ? (
            <motion.div
              initial={{ opacity: 0.3, y: 10 }}
              animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}
              className="costs-wrapper flex-column row-gap-4 overflow-scroll px-6">
              <div className="px-6">
                <div className="flex justify-end col-gap-10 items-center px-6 total-price-costs">
                  <div className="flex items-center col-gap-1">
                    <p className="inter-500-text natural-700-text font-14 line-height-20">{t('TOTAL_SALE')}:</p>
                    <span className="inter-600-text natural-700-text font-14 line-height-20">
                      {getFormattedNumber(saleTotalAmountSum) || '-'}
                    </span>
                  </div>
                  <div className="flex items-center col-gap-1">
                    <p className="inter-500-text natural-700-text font-14 line-height-20">{t('TOTAL_COST')}:</p>
                    <span className="inter-600-text natural-700-text font-14 line-height-20">
                      {getFormattedNumber(costTotalAmountSum) || '-'}
                    </span>
                  </div>
                </div>
              </div>
              <div className="w-full border border-light overflow-scroll">
                <div className="flex-column w-fit-content min-w-full h-full">
                  <TableHeader headers={costsTableHeaders} headerClassName="border-light" />
                  <InfiniteScrollV2>
                    {(costsList || []).map(costsData => (
                      <CostsListItem
                        costitems={costsData}
                        key={costsData.id}
                        fetchCostsData={fetchCostsData}
                        onDeleteCosts={onDeleteCosts}
                        onAddEditCosts={onAddEditCosts}
                        menuPortal={costsList?.length < 3}
                      />
                    ))}
                  </InfiniteScrollV2>
                </div>
              </div>
            </motion.div>
          ) : debouncedSearch ? (
            <NoData
              title={t('NO_RESULTS_FOUND')}
              description={t('NOTHING_FOUND_FOR_REQUEST')}
              className="mt-12 h-full w-30"
              EmptyIcon={NoSearchIcon}
              iconClassName="mb-6 relative"
            />
          ) : (
            <NoData
              title={t('NO_COSTS')}
              description={t('NO_COSTS_CURRENTLY')}
              className="mt-12 h-full"
              EmptyIcon={EmptyIcon}
              iconClassName="relative"
            />
          )}
        </SkeletonTransition>
      </div>
    </PropertCostsWrapper>
  );
};

export const PropertCostsWrapper = styled(motion.div)`
  .header-container,
  .data-container {
    display: grid;
    grid-template-columns: minmax(200px, 1fr) 60px 85px 85px 85px 65px;
    column-gap: 48px;
    padding: 0 24px;
    @media (max-width: 1440px) {
      grid-template-columns: minmax(150px, 1fr) 60px 96px 96px 96px 80px;
      column-gap: 32px;
    }
  }
  .data-container-toggle {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 48px;
    padding: 0 24px;
    @media (max-width: 1440px) {
      grid-template-columns: 1fr;
    }
  }

  @media (max-width: 1024px) {
    .total-price-costs {
      gap: 28px !important;
    }
  }

  .costs-details {
    transition: all 500ms ease-in-out;
    display: grid;
    grid-template-columns: minmax(269px, 1fr) 110px 110px 110px 100px;
    column-gap: 12px;
    height: auto;
    @media (max-width: 1440px) {
      grid-template-columns: minmax(240px, 1fr) 105px 105px 105px 105px;
    }
  }

  .header-container {
    height: 40px;
    background-color: ${({ theme }) => theme.natural_50};
  }
`;

export default PropertyDetailsCosts;
