import { ReactComponent as RightIcon } from '@assets/images/arrow-right.svg';
import { ReactComponent as CloseIcon } from '@assets/images/toast-close.svg';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as CalenderPlusIcon } from '../../../assets/icons/calendar-plus-02.svg';
import { ReactComponent as LocationIcon } from '../../../assets/icons/property/location-icon.svg';
import { ReactComponent as UpArrow } from '../../../assets/icons/up-arrow.svg';
import Button from '../button/button';
import IconContainer from '../icon-container';

const SortPopover = ({ setShowSortPopover, setMainOrderBy, setMainSortBy, mainOrderBy, mainSortBy }) => {
  const { t } = useTranslation();
  const [isAddressExpanded, setIsAddressExpanded] = useState(true);
  const [isCreatedDateExpanded, setIsCreatedDateExpanded] = useState(true);
  const [orderBy, setOrderBy] = useState(mainOrderBy);
  const [sortBy, setSortBy] = useState(mainSortBy);
  return (
    <SortPopoverWrapper className="popover-content bg-white py-5 radius-2 flex-column">
      <div className="justify-between px-5">
        <label className="inter-500-text font-18 natural-900-text line-height-28">{t('SORT_BY')}</label>
        <IconContainer
          Icon={CloseIcon}
          iconHeight={16}
          iconWidth={16}
          iconColor="natural_500"
          backgroundColor="transparent"
          iconContainerClassname="cursor"
          onClick={() => setShowSortPopover(false)}
        />
      </div>
      <div className={classNames('my-7 ', { 'scroll-container': isAddressExpanded })}>
        <div>
          <div className={classNames('justify-between pb-3 px-5', { 'border-bottom': !isAddressExpanded })}>
            <div className="flex items-center gap-1">
              <IconContainer Icon={LocationIcon} backgroundColor="transparent" iconHeight={20} iconWidth={20} />
              <label
                className={classNames('inter-500-text font-16  ', {
                  'natural-700-text': isAddressExpanded,
                  'natural-500-text': !isAddressExpanded,
                })}>
                {t('ADDRESS')}
              </label>
            </div>
            <IconContainer
              Icon={RightIcon}
              iconHeight={16}
              iconWidth={16}
              iconColor={isAddressExpanded ? 'natural_700' : 'natural_500'}
              backgroundColor="transparent"
              iconContainerClassname={classNames('cursor', {
                'rotate-90': !isAddressExpanded,
                'rotate-270': isAddressExpanded,
              })}
              onClick={() => setIsAddressExpanded(prev => !prev)}
            />
          </div>
          <motion.div
            initial={{ opacity: 0, height: 0, padding: 0 }}
            animate={{
              opacity: isAddressExpanded ? 1 : 0,
              height: isAddressExpanded ? 'auto' : 0,
              padding: isAddressExpanded ? '20px' : 0,
              transition: { duration: 0.5 },
            }}
            className="pxy-5 bg-natural-50 px-5">
            <div className="radio-container items-center flex">
              <input
                type="radio"
                id="address-asc-radio"
                onChange={e => {
                  if (e.target.value === 'on') {
                    setOrderBy('ASC');
                    setSortBy('address');
                  }
                }}
                checked={sortBy === 'address' && orderBy === 'ASC'}
              />
              <label
                htmlFor="address-asc-radio"
                className="natural-700-text inter-400-text font-16 line-height-24 radio-label flex item-center">
                <IconContainer Icon={UpArrow} backgroundColor="transparent" />
                <span> {t('ASCENDING')}</span>
              </label>
            </div>
            <div className="items-center flex">
              <input
                type="radio"
                id="address-dec-radio"
                onChange={e => {
                  if (e.target.value === 'on') {
                    setOrderBy('DESC');
                    setSortBy('address');
                  }
                }}
                checked={sortBy === 'address' && orderBy === 'DESC'}
              />
              <label
                htmlFor="address-dec-radio"
                className="natural-700-text inter-400-text font-16 line-height-24 radio-label flex item-center">
                <IconContainer Icon={UpArrow} backgroundColor="transparent" iconClassName="rotate-180" />
                <span>{t('DESCENDING')}</span>
              </label>
            </div>
          </motion.div>
        </div>
        <div className="mt-7">
          <div className={classNames('justify-between pb-3 px-5', { 'border-bottom': !isCreatedDateExpanded })}>
            <div className="flex items-center gap-1">
              <IconContainer Icon={CalenderPlusIcon} iconHeight={20} iconWidth={20} backgroundColor="transparent" />
              <label
                className={classNames('inter-500-text font-16', {
                  'natural-700-text': isCreatedDateExpanded,
                  'natural-500-text': !isCreatedDateExpanded,
                })}>
                {t('CREATED_DATE')}
              </label>
            </div>
            <IconContainer
              Icon={RightIcon}
              iconHeight={16}
              iconWidth={16}
              iconColor={isCreatedDateExpanded ? 'natural_700' : 'natural_500'}
              backgroundColor="transparent"
              iconContainerClassname={classNames('cursor', {
                'rotate-90': !isCreatedDateExpanded,
                'rotate-270': isCreatedDateExpanded,
              })}
              onClick={() => setIsCreatedDateExpanded(prev => !prev)}
            />
          </div>
          <motion.div
            initial={{ opacity: 0, height: 0, padding: 0 }}
            animate={{
              opacity: isCreatedDateExpanded ? 1 : 0,
              height: isCreatedDateExpanded ? 'auto' : 0,
              padding: isCreatedDateExpanded ? '20px' : 0,
              transition: { duration: 0.5 },
            }}
            className={classNames('bg-natural-50')}>
            <div className="radio-container items-center flex">
              <input
                type="radio"
                id="date-asc-radio"
                onChange={e => {
                  if (e.target.value === 'on') {
                    setOrderBy('ASC');
                    setSortBy('created_date');
                  }
                }}
                checked={sortBy === 'created_date' && orderBy === 'ASC'}
              />
              <label
                htmlFor="date-asc-radio"
                className="natural-700-text inter-400-text font-16 line-height-24 radio-label flex item-center">
                <IconContainer Icon={UpArrow} backgroundColor="transparent" />
                <span> {t('ASCENDING')}</span>
              </label>
            </div>
            <div className="items-center flex">
              <input
                type="radio"
                id="date-dec-radio"
                onChange={e => {
                  if (e.target.value === 'on') {
                    setOrderBy('DESC');
                    setSortBy('created_date');
                  }
                }}
                checked={sortBy === 'created_date' && orderBy === 'DESC'}
              />
              <label
                htmlFor="date-dec-radio"
                className="natural-700-text inter-400-text font-16 line-height-24 radio-label flex item-center">
                <IconContainer Icon={UpArrow} backgroundColor="transparent" iconClassName="rotate-180" />
                <span>{t('DESCENDING')}</span>
              </label>
            </div>
          </motion.div>
        </div>
      </div>
      <div className="flex col-gap-10 px-5 justify-center mt-1 ">
        <Button
          className={classNames('primary-grey')}
          label={t('RESET')}
          onClick={() => {
            setOrderBy(null);
            setSortBy(null);
            setMainOrderBy(null);
            setMainSortBy(null);
          }}
          size="average"
          borderRadius="100px"
        />
        <Button
          className={classNames('primary ')}
          label={t('APPLY_SORT')}
          onClick={() => {
            setMainOrderBy(orderBy);
            setMainSortBy(sortBy);
            setShowSortPopover(false);
          }}
          size="average"
          borderRadius="100px"
        />
      </div>
    </SortPopoverWrapper>
  );
};

const SortPopoverWrapper = styled.div`
  &.popover-content {
    z-index: 10;
    width: 357px;
    box-shadow: 0px 2px 8px -4px rgba(16, 24, 40, 0.1), 0px 6px 17px -3px rgba(16, 24, 40, 0.1);
  }

  .radio-container {
    margin-bottom: 14px;
  }

  .radio-label {
    margin-left: 5px;
  }

  input[type='radio'] {
    width: 20px;
    height: 20px;
  }
`;

export default SortPopover;
