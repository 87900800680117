import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as PlusIcon } from '../../assets/icons/add-icon.svg';
import { ReactComponent as MinusIcon } from '../../assets/icons/minus.svg';
import { ReactComponent as HorizontalDots } from '../../assets/images/HorizontalDots.svg';
import { capitalize, capitalizeFirstLetterOfWords, formatText, getFormattedNumber } from '../../helpers/utils';
import MenuV2 from '../common/menu/menu-v2';
import Status from '../common/status';

const CostsListItem = ({ costitems = {}, onDeleteCosts, onAddEditCosts, menuPortal }) => {
  const { t } = useTranslation();
  const [showContact, setShowContact] = useState(null);

  const {
    name,
    quantity,
    cost_type,
    cost_total_amount,
    sale_total_amount,
    cost_unit_price,
    cost_tax_rate,
    sale_unit_price,
    sale_tax_rate,
    description,
  } = costitems;

  const isCostItemOpen = costitems => {
    return costitems.id !== showContact;
  };

  return (
    <>
      <CostsListItemWrapper
        initial={{ opacity: 0.3, y: 10 }}
        animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}
        key={costitems.id}
        className={classNames('data-container py-2 border-bottom cursor')}
        onClick={() => {}}>
        <div className="flex items-center overflow-hidden py-4">
          <div className="flex-column row-gap-2 justify-center overflow-hidden">
            <label className="inter-400-text natural-900-text three-lines font-14 line-height-20">
              {name ? name : '-'}
            </label>
          </div>
        </div>
        <div className="flex items-center overflow-hidden py-4 justify-center">
          <div className="flex-column row-gap-2 justify-center overflow-hidden">
            <label className="inter-400-text natural-900-text three-lines font-14 line-height-20">
              {quantity ? quantity : '0'}
            </label>
          </div>
        </div>
        <div className="flex items-center justify-center ">
          <Status
            withDot={false}
            withDottedBorder={false}
            status={cost_type || '-'}
            statusText={capitalizeFirstLetterOfWords(formatText(cost_type))}
          />
        </div>
        <div className="flex items-center overflow-hidden py-4 justify-center">
          <div className="flex-column row-gap-2 justify-center overflow-hidden">
            <label className="inter-400-text natural-900-text three-lines font-14 line-height-20">
              {getFormattedNumber(sale_total_amount || '0')}
            </label>
          </div>
        </div>
        <div className="flex items-center overflow-hidden py-4 justify-center">
          <div className="flex-column row-gap-2 justify-center overflow-hidden">
            <label className="inter-400-text natural-900-text three-lines font-14 line-height-20">
              {getFormattedNumber(cost_total_amount || '0')}
            </label>
          </div>
        </div>

        <div className="flex items-center justify-end col-gap-3">
          <div
            className="cursor"
            onClick={() => (isCostItemOpen(costitems) ? setShowContact(costitems.id) : setShowContact(''))}>
            {!isCostItemOpen(costitems) ? <MinusIcon /> : <PlusIcon className="natural-400-text" />}
          </div>
          <MenuV2
            Icon={HorizontalDots}
            menuList={[
              { name: t('EDIT'), onClick: () => {} },
              { name: t('DELETE'), onClick: () => onDeleteCosts(costitems) },
            ]}
            menuClassName="my-4"
            portal={menuPortal}
          />
        </div>
      </CostsListItemWrapper>
      {!isCostItemOpen(costitems) && (
        <div
          className={classNames(
            'costs-details col-gap-2 border-bottom px-6 py-3',
            !isCostItemOpen(costitems) && 'open-costs',
          )}>
          <div className={classNames('bg-natural-100 radius-1_5 px-2 py-1 flex items-center')}>
            <p className={classNames('inter-400-text font-12 line-height-20 natural-900-text ')}>
              {' '}
              {capitalize(formatText(description, ''))}
            </p>
          </div>
          <div className={classNames('bg-natural-100 radius-1_5 px-2 py-1 flex-column justify-center')}>
            <p className="inter-400-text font-12 line-height-20 natural-500-text">{t('COST_NET')}:</p>
            <span className="inter-400-text font-14 line-height-20 natural-900-text">
              {' '}
              {getFormattedNumber(cost_unit_price || '0')}
            </span>
          </div>
          <div className={classNames('bg-natural-100 radius-1_5 px-2 py-1 flex-column justify-center')}>
            <p className="inter-400-text font-12 line-height-20 natural-500-text">{t('COST_TAX')}:</p>
            <span className="inter-400-text font-14 line-height-20 natural-900-text">
              {cost_tax_rate ? `${cost_tax_rate}%` : '0'}
            </span>
          </div>
          <div className={classNames('bg-natural-100 radius-1_5 px-2 py-1 flex-column justify-center')}>
            <p className="inter-400-text font-12 line-height-20 natural-500-text">{t('SALE_NET')}:</p>
            <span className="inter-400-text font-14 line-height-20 natural-900-text">
              {getFormattedNumber(sale_unit_price || '0')}
            </span>
          </div>
          <div className={classNames('bg-natural-100 radius-1_5 px-2 py-1 flex-column justify-center')}>
            <p className="inter-400-text font-12 line-height-20 natural-500-text">{t('SALE_TAX')}:</p>
            <span className="inter-400-text font-14 line-height-20 natural-900-text">
              {sale_tax_rate ? `${sale_tax_rate}%` : '0'}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

const CostsListItemWrapper = styled(motion.div)`
  &:hover {
    background: ${({ theme }) => theme.natural_50};
  }
`;

export default CostsListItem;
