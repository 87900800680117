import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as CrossIcon } from '../../../assets/icons/CrossIcons.svg';
import { ReactComponent as FilterIcon } from '../../../assets/icons/filter.svg';
import { getProductFamilies } from '../../../store/features/quotesSlice';
import Accordion from '../../common/Accordion';
import Button from '../../common/button/button';
import Checkbox from '../../common/checkbox';
import SearchableDropdown from '../../common/searchable-dropdown';
import Dropdown from '../../common/select-dropdown';
import CustomTooltip from '../../common/tooltip-new';

const ProductFilter = ({
  family,
  productDrawStatusFilter,
  productFilters,
  setFamily,
  setProductFilters,
  onApplyFilters = () => {},
  onClearFilters = () => {},
}) => {
  const { t } = useTranslation();

  const [search, setSearchText] = useState('');
  const [isFiltersOpen, setFilterOpen] = useState(false);

  function updateFilters(key, value) {
    setProductFilters({
      ...productFilters,
      [key]: value,
    });
  }

  function handleSoldAs(key, value) {
    setProductFilters({
      ...productFilters,
      soldAs: {
        ...productFilters.soldAs,
        [key]: value,
      },
    });
  }

  const applyFilters = () => {
    onApplyFilters();
    setFilterOpen(false);
  };

  const Filters = () => (
    <ProductFilterWrapper className="w-full py-5" onClick={e => e.stopPropagation()}>
      <div>
        <div className="flex px-5 mb-6 items-center justify-between">
          <p className="inter-500-text font-18 natural-900-text">Filter by</p>
          <CrossIcon onClick={() => setFilterOpen(false)} className="cursor natural-700-text" width={22} height={22} />
        </div>
        <div className="px-5" style={{ marginBottom: '14px' }}>
          <Dropdown
            className="drop-down-filter"
            onChange={option => updateFilters('status', option)}
            options={productDrawStatusFilter}
            placeholder={'ALL'}
            value={productFilters.status}
            customStyle={{
              control: {
                borderRadius: '0px',
                borderTop: '0px',
                borderLeft: '0px',
                borderRight: '0px',
                borderBottom: '1px solid #E5E5E5',
                '&:hover': {
                  boxShadow: 'none',
                },
              },
              dropdownIndicator: {
                marginRight: '0px',
              },
            }}
          />
        </div>
        <div>
          <Accordion titleClass="natural-400-text" title="Sold as" headerClass="mx-5 mr-1">
            <div className="px-4 py-5 bg-natural-50 mt-3">
              <div className="flex items-center gap-2 mb-4">
                <Checkbox
                  is_checked_done={true}
                  checked={productFilters.soldAs.single}
                  onChange={e => handleSoldAs('single', e)}
                  id="sold-single"
                />
                <label htmlFor="sold-single" className="no-select inter-400-text font-16">
                  Single
                </label>
              </div>
              <div className="flex items-center gap-2">
                <Checkbox
                  is_checked_done={true}
                  id="sold-bundle"
                  checked={productFilters.soldAs.bundle}
                  onChange={e => handleSoldAs('bundle', e)}
                />
                <label htmlFor="sold-bundle" className="no-select inter-400-text font-16">
                  Bundle
                </label>
              </div>
            </div>
          </Accordion>
        </div>

        <div className="mb-7 px-5 ">
          <SearchableDropdown
            isSearchable={false}
            isClearable
            inputValue={search}
            onInputChange={setSearchText}
            className="w-full drop-down-filter"
            placeholder={'Family name'}
            showIconInOption={false}
            customStyle={{
              control: {
                borderRadius: '0px',
                borderTop: '0px',
                borderLeft: '0px',
                borderRight: '0px',
                borderBottom: '1px solid #E5E5E5',
                '&:hover': {
                  boxShadow: 'none',
                },
              },
            }}
            value={family}
            onChange={option => setFamily(option)}
            defaultAdditional={{
              page: 0,
              fetchFunction: getProductFamilies,
              pageable: false,
              setToRedux: false,
            }}
          />
        </div>
        <div className="px-5 flex items-center justify-between">
          <Button
            className={classNames('primary-grey specified-width')}
            label={'Clear all'}
            onClick={() => {
              setFilterOpen(false);
              onClearFilters();
            }}
            size="large"
            borderRadius="100px"
            width="100px"
            height="40px"
          />

          <Button
            className={classNames('primary specified-width')}
            label={'Apply filters'}
            onClick={applyFilters}
            size="large"
            borderRadius="100px"
            width="113px"
            height="40px"
          />
        </div>
      </div>
    </ProductFilterWrapper>
  );

  return (
    <div onClick={() => setFilterOpen(!isFiltersOpen)}>
      <CustomTooltip
        isOpen={isFiltersOpen}
        content={<Filters />}
        openOnClick={true}
        wrapperClassName="owner-tooltip"
        otherClass="p-0"
        id={`filter-tooltip`}
        place="bottom"
        positionStrategy={'fixed'}
        noArrow={false}>
        <div className="flex items-center justify-center h-full py-2 px-4 ml-3 cursor icon-container filter">
          <label className="inter-500-text primary-text mr-1">{t('FILTER')}</label>
          <FilterIcon className="primary-text" />
        </div>
      </CustomTooltip>
    </div>
  );
};

const ProductFilterWrapper = styled.div`
  max-width: 357px;
  min-width: 357px;
  pointer-events: all;
  .drop-down-filter {
    .select__value-container,
    .searchable-select__placeholder,
    .searchable-select__value-container,
    .searchable-select__indicator {
      padding: 0 !important;
      margin: 0px !important;
    }
  }
`;

export default ProductFilter;
