import React from 'react';
import { ReactComponent as Loader } from '../../../assets/images/white-loader.svg';
import ButtonWrapper from './button.styled.js';

const Button = ({
  bgColor = '#BEF5EC',
  borderColor = '#BEF5EC',
  color = '#053149',
  disabled,
  icon = null,
  afterIcon = null,
  label = '',
  lableSize = false,
  onClick = () => {},
  size = 'small',
  width = 'fit-content',
  borderRadius = '100px',
  borderWidth = '1px',
  loading = false,
  ...buttonProps
}) => {
  let height = '24px';
  let fontSize = '12px';

  switch (size) {
    case 'large':
      height = '48px';
      fontSize = '16px';
      break;
    case 'medium':
      height = '32px';
      fontSize = '14px';
      break;
    case 'tiny':
      height = '15px';
      break;
    case 'average':
      height = '40px';
      fontSize = '16px';
      break;
    case 'customsize':
      height = '44px';
      fontSize = '14px';
      break;
    default:
      break;
  }

  return (
    <ButtonWrapper
      bgColor={bgColor}
      borderColor={borderColor}
      borderRadius={borderRadius}
      color={color}
      fontSize={fontSize}
      height={height}
      onClick={onClick}
      width={width}
      borderWidth={borderWidth}
      {...buttonProps}
      {...(disabled && {
        onClick: null,
        className: `${buttonProps.className} disabled`,
      })}
      {...(loading && { className: `${buttonProps.className} pointer-events-none` })}>
      <div className="flex flex-1 items-center gap-6px justify-center h-full">
        {loading ? (
          <Loader />
        ) : (
          <>
            {icon && icon}
            {label && <label className={`inter-500-text ${lableSize ? lableSize : ''}`}>{label}</label>}
            {afterIcon && afterIcon}
          </>
        )}
      </div>
    </ButtonWrapper>
  );
};

export default Button;
