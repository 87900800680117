import CloseIcon from '@assets/images/close-image.svg';
import moment from 'moment';
import React, { useEffect } from 'react';
import styled from 'styled-components';

const ViewFileImage = ({ file, remove, close, showRemove = true }) => {
  const created_on = file?.evidence_date?.created_on || file?.created_on;
  const formattedDate = moment.unix(created_on).format('MMMM DD, YYYY');

  const onClick = e => {
    e.preventDefault();
    if (e.target === e.currentTarget) {
      close();
    }
  };

  useEffect(() => {
    const keyEnter = event => {
      if (event.key === 'Escape') {
        event.preventDefault();
        close();
      }
    };
    document.addEventListener('keydown', keyEnter);

    return () => {
      document.removeEventListener('keydown', keyEnter);
    };
  }, []);

  return (
    <ImageViewModalWrapper onClick={onClick}>
      <div className="view-content-box">
        <img alt="icon" className="close-btn cursor" onClick={() => close()} src={CloseIcon} />
        {file.name && (
          <div className="mb-5 w-full flex items-center ">
            <label className="natural_700-text font-18 inter-500-text pr-1">
              {file.name}.{file?.media?.format}
            </label>
            <p className="natural-400-text font-16 inter-400-text">({formattedDate})</p>
          </div>
        )}
        <div className="flex file-container">
          <img src={file?.media?.url} />
        </div>
      </div>
    </ImageViewModalWrapper>
  );
};

const ImageViewModalWrapper = styled.div`
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(4, 32, 48, 0.8);
  top: 0;
  left: 0;
  user-select: none;
  outline: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .close-btn {
    position: absolute;
    right: 30px;
    top: 20px;
    cursor: pointer;
  }

  .view-content-box {
    /* height: 90dvh; */
    width: 50dvw;
    background-color: #ffffff;
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 24px;
    border-radius: 16px;

    .file-container {
      border-radius: 6px;
      column-gap: 24px;
      width: 45dvw;
      height: 550px;
      overflow: hidden;
      img {
        max-width: 100%;
        width: 100%;
        object-fit: contain;
        max-height: 550px;
        aspect-ratio: 1 / 1;
      }
    }
  }
`;

export default ViewFileImage;
