import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ZervioLogoGray from '../../assets/icons/zervio-logo-gray.svg';
import { capitalize, formatText } from '../../helpers/utils';
import { useFilteredMenuList } from '../../hooks/useFilteredMenuList';
import Checkbox from '../common/checkbox';
import MenuV2 from '../common/menu/menu-v2';
import Status from '../common/status';
import CustomTooltip from '../common/tooltip-new';

const ProductListItem = ({
  product,
  onDeleteProduct = () => {},
  menuPortal,
  checked,
  setChecked,
  isFromAddProduct = false,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { user } = useSelector(state => state.user);
  const { organizations } = user || {};
  const { id: organisation_id } = organizations?.[0] || {};

  const { owner, id: product_id } = product || {};
  const { id: owner_id } = owner || {};

  const is_product_owner = owner_id === organisation_id;

  const handleProductItemClick = () => {
    if (isFromAddProduct) {
      if (checked.includes(product)) {
        setChecked(checked.filter(p => p !== product));
      } else {
        setChecked([...checked, product]);
      }
    } else {
      navigate(product_id);
    }
  };

  return (
    <ProductListItemWrapper
      className="data-container items-center justify-center w-full py-0 cursor sidebar-close-ignore"
      onClick={handleProductItemClick}>
      {isFromAddProduct && (
        <Checkbox onChange={handleProductItemClick} checked={checked.includes(product)} is_checked_done={true} />
      )}
      <img
        src={product?.image?.url || ZervioLogoGray}
        alt="product-preview"
        className="radius-1"
        height={40}
        width={40}
      />
      <div className="brd-right flex items-center w-full">
        <CustomTooltip
          wrapperClassName="w-full one-line"
          id={`product-name-tooltip-${product.id}`}
          content={<label className="inter-500-text white-space-break-spaces">{product.name}</label>}>
          <label className="inter-500-text color-neutral-900 font-14 one-line line-height-20">
            {product?.name || '-'}
          </label>
        </CustomTooltip>
      </div>
      <div className="brd-right flex items-center w-full overflow-hidden">
        <CustomTooltip
          wrapperClassName="w-full one-line"
          id={`product-description-tooltip-${product.id}`}
          content={<label className="inter-500-text white-space-break-spaces">{product.description}</label>}>
          <label className="inter-500-text color-neutral-900 font-14 one-line line-height-20">
            {product?.description || '-'}
          </label>
        </CustomTooltip>
      </div>
      <div className="brd-right flex items-center w-full">
        <CustomTooltip
          wrapperClassName="w-full one-line"
          id={`product-sku-tooltip-${product.id}`}
          content={<label className="inter-500-text">{product.sku}</label>}>
          <label className="inter-500-text color-neutral-900 font-14 one-line line-height-20">
            {product?.sku || '-'}
          </label>
        </CustomTooltip>
      </div>
      <div className="brd-right flex items-center w-full overflow-hidden">
        <Status
          withDot={false}
          withDottedBorder
          status={product?.sold_as || ''}
          statusText={capitalize(formatText(product?.sold_as || ''))}
        />
      </div>
      <div className="brd-right flex items-center w-full overflow-hidden">
        <Status
          withDot={false}
          withDottedBorder
          status={product?.lifecycle || ''}
          statusText={capitalize(formatText(product?.lifecycle || ''))}
        />
      </div>
      {!isFromAddProduct && (
        <div>
          <MenuV2
            menuList={useFilteredMenuList({
              menuList: [
                { name: t('EDIT'), onClick: () => {}, permission: '' },
                { name: t('DUPLICATE'), onClick: () => {}, permission: '' },
                {
                  name: is_product_owner ? t('DELETE') : t('REMOVE'),
                  onClick: () => onDeleteProduct(product, is_product_owner),
                  permission: '',
                },
              ],
            })}
            iconClassname="rotate-90 dots-icon"
            portal={menuPortal}
          />
        </div>
      )}
    </ProductListItemWrapper>
  );
};

const ProductListItemWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.lightGrey};

  &:hover {
    background-color: ${({ theme }) => theme.natural_50};
  }

  .custom-tooltip {
    padding: 10px 16px;
    max-width: 320px;
  }
`;

export default ProductListItem;
