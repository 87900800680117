import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AutomationLogsList from '../../components/automation-logs-list';
import PageHeader from '../../components/page-header';
import useDebounce from '../../helpers/useDebounceHook';
import { AutomationLogsWrapper } from '../../styles/pages/automation-logs.styled.js';

const AutomationLogs = () => {
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, 500);

  return (
    <AutomationLogsWrapper className="flex-column flex-1">
      <PageHeader
        names={[
          { text: t('PLATFORMS'), onClick: () => {} },
          { text: t('AUTOMATION_LOG'), onClick: () => {} },
        ]}
        showSearch
        searchText={searchText}
        onSearchChange={setSearchText}
      />
      <AutomationLogsList debouncedSearch={debouncedSearch} />
    </AutomationLogsWrapper>
  );
};

export default AutomationLogs;
