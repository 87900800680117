import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import uuid from 'react-uuid';
import styled from 'styled-components';
import { ReactComponent as CrossIcon } from '../assets/icons/property/cross_icon.svg';
import { ReactComponent as DisabledIcon } from '../assets/icons/property/disabled_icon.svg';
import { Avatar } from '../components/common/avatar-group';
import Button from '../components/common/button/button';
import { OrganisationContext } from '../context/organisationContext';
import { initModal } from '../helpers/utils';
import { deleteAutomation, updateAutomationStatus } from '../store/features/automationsSlice';
import { addToast } from '../store/features/toastSlice';

const AutomationAction = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { modal, setModal } = useContext(OrganisationContext);
  const { automation, isDelete, onSuccess, is_enabled } = modal.content;
  const { id, name, description, owner } = automation || {};

  const [loading, setLoading] = useState(false);

  const handleDone = () => {
    setLoading(true);
    try {
      if (isDelete) {
        dispatch(deleteAutomation({ automationID: id })).then(data => {
          setModal(initModal);
          setLoading(false);
          dispatch(
            addToast({
              error: false,
              title: t('AUTOMATION_DELETED'),
              text: t('SUCCESSFULLY_DELETED_AUTOMATION', { value: name }),
              id: uuid(),
            }),
          );
          onSuccess && onSuccess();
        });
      } else {
        dispatch(
          updateAutomationStatus({
            automationID: automation?.id,
            request: {
              enabled: !is_enabled,
            },
            updateList: true,
          }),
        )
          .then(() => {
            setLoading(false);
            setModal(initModal);
            dispatch(
              addToast({
                error: false,
                title: t('AUTOMATION_DISABLED'),
                text: `You have successfully ${is_enabled ? 'disabled' : 'enabled'} the automation '${name}'`,
                id: nanoid(),
              }),
            );
            onSuccess && onSuccess();
          })
          .catch(error => {
            setLoading(false);
            setModal(initModal);
            dispatch(
              addToast({
                error: true,
                text: `Error while ${is_enabled ? 'disabling' : 'enabling'} the automation`,

                id: nanoid(),
              }),
            );
          });
      }
    } catch (error) {
      setLoading(false);
      dispatch(
        addToast({
          error: true,
          text: `Failed to ${isDelete ? 'delete' : 'disable'} automation`,
          id: nanoid(),
        }),
      );
    }
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <AutomationActionWrapper className="flex-column items-center pxy-6">
        <div className="flex justify-center relative w-full">
          <div
            className={classNames(
              'flex p-3 items-start gap-2_5 radius-6',
              is_enabled || isDelete ? 'alert-icon-error' : 'alert-icon-success',
            )}>
            <DisabledIcon width={24} height={24} />
          </div>

          <CrossIcon onClick={() => setModal(initModal)} width={20} height={20} className="closeIcon absolute cursor" />
        </div>
        <label className="inter-500-text natural-900-text font-18 text-center mt-5">
          {isDelete ? 'Delete' : is_enabled ? 'Disable' : 'Enable'} this automation?
        </label>
        <div className="automation-details-container border radius-1_5 mt-6 py-4 px-6 w-full">
          <div className="flex justify-between items-center w-full">
            <label className="inter-600-text font-16 color-neutral-900">{name}</label>
            {owner?.name && <Avatar variant="medium" avatar={owner} />}
          </div>
          <div className="flex items-center justify-start w-full">
            <label className="inter-400-text font-14 color-neutral-900 mt-1">{description}</label>
          </div>
        </div>
        <div className="flex-column justify-between row-gap-3 mt-6 w-full action-wrapper">
          {isDelete ? (
            <Button
              className={classNames('negative', loading && 'disabled')}
              disabled={loading}
              label={t('DELETE')}
              onClick={() => handleDone()}
              size="large"
              borderRadius="100px"
            />
          ) : (
            <Button
              className={classNames(is_enabled ? 'negative' : 'primary', loading && 'disabled')}
              disabled={loading}
              label={is_enabled ? t('DISABLE') : t('ENABLE')}
              onClick={() => handleDone()}
              size="large"
              borderRadius="100px"
            />
          )}

          <Button
            className={classNames('primary-grey', loading && 'disabled')}
            label={t('CANCEL')}
            disabled={loading}
            onClick={() => setModal(initModal)}
            size="large"
            borderRadius="100px"
          />
        </div>
      </AutomationActionWrapper>
    </CSSTransition>
  );
};

const AutomationActionWrapper = styled.div`
  width: 384px;
  .alert-icon-error {
    background: #fef2f2;
  }
  .alert-icon-success {
    background: ${({ theme }) => theme.primary_50};
    svg {
      path {
        fill: ${({ theme }) => theme.primaryBlue};
      }
    }
  }

  .closeIcon {
    right: 0px;
  }

  .automation-details-container {
    .user-icon {
      height: 24px;
      width: 24px;
      background: ${({ theme }) => theme.primaryBlue};
      padding: 8px;
      border-radius: 50%;
      span {
        color: ${({ theme }) => theme.white};
        font-size: 9px;
      }
    }
  }
`;

export default AutomationAction;
