import { nanoid } from 'nanoid';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import styled from 'styled-components';
import { ReactComponent as CrossIcon } from '../assets/icons/CrossIcons.svg';
import Button from '../components/common/button/button';
import Popover from '../components/common/popover';
import FilterPopover from '../components/common/popover/add-product-filter-popover';
import PageHeader from '../components/page-header';
import ProductList from '../components/product-list';
import { OrganisationContext } from '../context/organisationContext';
import useDebounce from '../helpers/useDebounceHook';
import { initModal } from '../helpers/utils';
import { addNewProduct, getProductList } from '../store/features/quotesSlice';
import { addToast } from '../store/features/toastSlice';

const productDrawStatusFilter = [
  { id: 'ALL', value: 'ALL', label: 'All', selected: true },
  { id: 'ACTIVE', value: 'ACTIVE', label: 'Active' },
  { id: 'INACTIVE', value: 'INACTIVE', label: 'Inactive' },
];

const initialState = {
  status: productDrawStatusFilter.at(0),
  soldAs: {
    single: false,
    bundle: false,
  },
};

const AddNewProduct = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pageRef = useRef(0);
  const { hasPermission, modal, setModal, setSideModal } = useContext(OrganisationContext);
  const { content } = modal || {};
  const { onSuccess } = content || {};
  const [search, setSearchText] = useState('');
  const [showLoading, setShowLoading] = useState(true);
  const [showFilterPopover, setShowFilterPopover] = useState(false);
  const [products, setProducts] = useState({});
  const [productFilters, setProductFilters] = useState(initialState);
  const debouncedSearch = useDebounce(search, 500);
  const { content: productList, last } = products || { content: [], last: true };
  const [selectedProducts, setSelectedProducts] = useState([]);
  const { user } = useSelector(state => state.user);
  const { organizations } = user || {};
  const { id: organisation_id } = organizations[0] || {};

  const fetchProductList = (page, debouncedSearch, showLoading = true, merge = false, filters) => {
    const { status, soldAs, family } = filters;
    const filterParams = {
      lifecycle: status.value === 'ALL' ? 'ACTIVE,INACTIVE' : status.value,
      sold_as: [soldAs.single ? 'SINGLE' : '', soldAs.bundle ? 'BUNDLE' : ''].filter(Boolean).join(','),
      family_id: family ? [`${family.id}`] : undefined,
    };

    setShowLoading(showLoading);
    dispatch(
      getProductList({
        params: {
          is_default: true,
          page: page,
          size: 10,
          search: debouncedSearch,
          ...filterParams,
        },
      }),
    )
      .then(data => {
        const { content, ...rest } = data;
        if (merge) {
          setProducts(prev => ({ ...rest, content: [...(prev.content || []), ...content] }));
        } else {
          setProducts(data);
        }
        pageRef.current = page;
      })
      .catch(error => {
        dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_PRODUCT_LIST'), id: nanoid() }));
        setProducts({});
      })
      .finally(() => setShowLoading(false));
  };

  const fetchMoreData = () => {
    fetchProductList(pageRef.current + 1, debouncedSearch, false, true, productFilters);
  };

  useEffect(() => {
    fetchProductList(0, debouncedSearch, true, false, productFilters);
  }, [debouncedSearch]);

  const addProduct = async () => {
    if (showLoading) {
      return;
    }
    setShowLoading(true);
    const request = selectedProducts?.map(product => ({ id: product.id }));
    dispatch(addNewProduct({ id: organisation_id, request: request }))
      .then(({ data }) => {
        dispatch(
          addToast({
            error: false,
            text: t('PRODUCTS_ADDED_SUCCESSFULLY'),
            id: uuid(),
          }),
        );
        onSuccess && onSuccess();
      })
      .catch(() => {
        dispatch(
          addToast({
            error: true,
            text: t('ERROR_WHILE_ADDING_PRODUCTS'),
            id: uuid(),
          }),
        );
      })
      .finally(() => {
        setShowLoading(false);
        setModal(initModal);
      });
  };

  const SortWrapper = useCallback(
    ({ children }) => (
      <Popover
        isOpen={showFilterPopover}
        offset={[0, 2]}
        content={
          <FilterPopover
            productDrawStatusFilter={productDrawStatusFilter}
            productFilters={productFilters}
            setProductFilters={setProductFilters}
            onClearFilters={() => {
              fetchProductList(0, debouncedSearch, true, false, initialState);
            }}
            onApplyFilters={() => fetchProductList(0, debouncedSearch, true, false, productFilters)}
            setShowFilterPopover={setShowFilterPopover}
          />
        }>
        {children}
      </Popover>
    ),
    [showFilterPopover, productFilters],
  );

  const addNewProductManually = Products => {
    setModal({
      type: 'add-new-product-manually',
      content: {},
    });
  };

  return (
    <AddNewProductWrapper className="flex-column items-center py-9 px-10">
      <div className="w-full flex justify-between items-center">
        <label className="font-20 inter-600-text text-black text-start">{t('ADD_NEW_PRODUCT')}</label>
        <CrossIcon className="cursor" onClick={() => setModal(initModal)} width={24} height={24} />
      </div>

      <div className="flex-column w-full items-start justify-start flex-1 add-product-table">
        <PageHeader
          wrapperClassName="py-6"
          showSearchOnLeft={true}
          showAddNewBtn={hasPermission('PRODUCTS_MANAGE')}
          onAddClick={addNewProductManually}
          showSearch
          searchText={search}
          addButtonLabel={t('ADD_MANUALLY')}
          onSearchChange={setSearchText}
          btnClassName="secondary"
          btnIconClassName="add-icon"
          SortWrapper={SortWrapper}
          showAddProductFilter
          onAddProductFilterClick={() => setShowFilterPopover(true)}
        />
        <ProductList
          debouncedSearch={debouncedSearch}
          fetchMoreData={fetchMoreData}
          showLoading={showLoading}
          productList={productList || []}
          hasMore={!last}
          isFromAddProduct={true}
          setSelectedProducts={setSelectedProducts}
          selectedProducts={selectedProducts}
        />
      </div>

      <div className="flex items-center justify-between w-full mt-10">
        <Button
          className={`default specified-width`}
          label={t('CANCEL')}
          disabled={showLoading}
          onClick={() => setModal(initModal)}
          secondary={true}
          size="large"
          borderRadius="100px"
          width="176px"
        />
        <Button
          className={`primary specified-width`}
          label={t('ADD')}
          disabled={showLoading}
          onClick={() => addProduct()}
          size="large"
          borderRadius="100px"
          width="176px"
        />
      </div>
    </AddNewProductWrapper>
  );
};

const AddNewProductWrapper = styled.div`
  width: 100%;
  min-width: 94dvw;
  max-width: 94dvw;

  .error-border {
    border-color: ${({ theme }) => theme.additionalRed};

    .organisations-border {
      border-color: ${({ theme }) => theme.additionalRed} !important;
    }
  }
  .header-container,
  .data-container {
    display: grid;
    grid-template-columns: 24px 40px minmax(150px, 2fr) minmax(200px, 3fr) minmax(100px, 1fr) 80px 80px;
    column-gap: 36px;
    padding: 16px 24px;
  }

  .input,
  .textarea {
    color: #171717;
  }
  .add-icon {
    color: ${({ theme }) => theme?.primary_500};
  }
  .add-product-table {
    max-height: 600px;
    min-height: 400px;
  }
`;

export default AddNewProduct;
