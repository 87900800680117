import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as FolderOutlineIcon } from '../../assets/icons/folder-outline.svg';
import { ReactComponent as HourglassIcon } from '../../assets/icons/hourglass.svg';
import { ReactComponent as MapPinIcon } from '../../assets/icons/map-pin.svg';
import { ReactComponent as MobileIcon } from '../../assets/icons/mobile.svg';
import { ReactComponent as UserOutlineIcon } from '../../assets/icons/user-outline.svg';
import { getContactValueByType, getDateDifference, getFormattedDate } from '../../helpers/utils';
import { Avatar } from '../common/avatar-group';
import CustomTooltip from '../common/tooltip-new';

const StageWorkitem = ({ workitem, providedRef, ...rest }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id, engagement, owners, stage, workflow } = workitem || {};
  const { property, contact, project, name: engagementName } = engagement || {};
  const { name: contactName, phones } = contact || {};
  const ownerUser = owners?.[0] || {};

  const contactMobile = getContactValueByType(phones || [], 'contact_type', 'MOBILE');

  return (
    <StageWorkitemWrapper
      key={id}
      ref={providedRef}
      {...rest}
      className="pxy-4 mx-4 card radius-2 mb-3 cursor"
      onClick={() => navigate(`workitem/${id}`)}>
      <div className="flex items-center justify-center border-bottom-light pb-3">
        <label className="flex-1 inter-600-text natural-900-text one-line">{engagementName}</label>
      </div>
      <div className="flex-column mt-3 mb-3 row-gap-2 overflow-hidden">
        <div className="flex items-center col-gap-2">
          <UserOutlineIcon className="natural-400-text" height={16} width={16} />
          <label
            className={classNames(
              'inter-400-text flex-1 one-line',
              contactName ? 'natural-900-text' : 'natural-400-text',
            )}>
            {contactName || 'No contact'}
          </label>
        </div>
        <div className="flex items-center col-gap-2">
          <MobileIcon className="natural-400-text" height={16} width={16} />
          <label
            className={classNames(
              'inter-400-text flex-1 one-line',
              contactMobile ? 'natural-900-text' : 'natural-400-text',
            )}>
            {contactMobile || 'No mobile'}
          </label>
        </div>
        <div className="flex items-center col-gap-2">
          <MapPinIcon className="natural-400-text" height={16} width={16} />
          <label className="inter-400-text natural-900-text flex-1 one-line">{property?.formatted_address}</label>
        </div>
        <div className="flex items-center col-gap-2">
          <FolderOutlineIcon className="natural-400-text" height={16} width={16} />
          <label className="inter-400-text natural-900-text flex-1 one-line">{project?.name}</label>
        </div>
      </div>
      <div className="flex items-center border-top-light pt-2">
        <CustomTooltip
          wrapperClassName="owner-tooltip"
          id={`${id}-stage`}
          place="bottom"
          noArrow={true}
          content={
            <div className="flex-column row-gap-1">
              <label className="inter-500-text natural-700-text font-12">{t('DAYS_IN_THIS_STAGE')}</label>
              <label className="inter-400-text natural-700-text font-12">
                {getFormattedDate(stage?.arrived, 'DD/MM/YY')}: {stage.name}
              </label>
            </div>
          }>
          <div className="flex items-center mr-2">
            <HourglassIcon className="orange-500-text" />
            <label className="flex items-center inter-400-text natural-900-text">
              {getDateDifference(stage?.arrived, null, 'days')}
            </label>
          </div>
        </CustomTooltip>
        <CustomTooltip
          wrapperClassName="owner-tooltip"
          id={`${id}-workflow`}
          place="bottom"
          noArrow={true}
          content={
            <div className="flex-column row-gap-1">
              <label className="inter-500-text natural-700-text font-12">{t('DAYS_IN_THIS_PROCESS')}</label>
              <label className="inter-400-text natural-700-text font-12">
                {getFormattedDate(workflow?.arrived, 'DD/MM/YY')}: {workflow.name}
              </label>
            </div>
          }>
          <div className="flex items-center mr-2">
            <HourglassIcon className="success-500-text" />
            <label className="flex items-center inter-400-text natural-900-text">
              {getDateDifference(workflow?.arrived, null, 'days')}
            </label>
          </div>
        </CustomTooltip>
        {ownerUser?.user?.id && (
          <CustomTooltip
            wrapperClassName="owner-tooltip"
            id={ownerUser?.user?.id}
            place="bottom"
            noArrow={true}
            content={
              <label className="inter-400-text natural-700-text font-12">
                {t('OWNER')}: <span className="inter-500-text font-12">{ownerUser?.user?.name}</span>
              </label>
            }>
            <Avatar
              variant="medium"
              avatarWrapperClassname="workitem-avatar"
              avatar={ownerUser?.user || {}}
              backgroundColorIndex={0}
              avatarLabelClassname="font-9"
            />
          </CustomTooltip>
        )}
      </div>
    </StageWorkitemWrapper>
  );
};

export const StageWorkitemWrapper = styled.div`
  transition: all 0.3s ease-in-out;
  box-shadow: rgba(16, 24, 40, 0.1) 0px 2px 2px -4px, rgba(16, 24, 40, 0.1) 0px 4px 4px -3px;
  border: 1px solid transparent;

  label {
    user-select: none;
  }

  .workitem-avatar {
    height: 24px;
    width: 24px;
  }

  .owner-tooltip {
    .custom-tooltip {
      padding: 8px 12px;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.natural_50};
    border: 1px solid ${({ theme }) => theme.natural_300};
    box-shadow: rgba(16, 24, 40, 0.1) 0px 8px 7px -4px, rgba(16, 24, 40, 0.1) 0px 10px 15px -3px;
  }
`;

export default StageWorkitem;
