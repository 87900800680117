import { createSlice } from '@reduxjs/toolkit';
import apiV3 from '../services/apiV3';

export const productsSlice = createSlice({
  name: 'products',
  initialState: {},
  reducers: {},
});

export const getProductSpecifications =
  ({ product_id, params }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.get(`/api/products/${product_id}/specifications`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

// export const updateProductSpecifications =
//   ({ product_id, params }) =>
//   async dispatch => {
//     try {
//       const { data } = await apiV3.get(`/api/products/${product_id}/specifications`, { params: params });
//       return Promise.resolve(data);
//     } catch (error) {
//       return Promise.reject(error);
//     }
//   };

export const {} = productsSlice.actions;
export default productsSlice.reducer;
