import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../../components/page-header';
import TeamsList from '../../../components/teams-list';
import { OrganisationContext } from '../../../context/organisationContext';
import useDebounce from '../../../helpers/useDebounceHook';
import TeamsWrapper from '../../../styles/pages/teams.styled';

function Teams() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { teams } = useSelector(state => state.teamManagement);
  const { t } = useTranslation();

  const [search, setSearchText] = useState('');
  const [selectedPage, setSelectedPage] = useState(0);

  const debouncedSearch = useDebounce(search, 500);
  const { checkPermission, setModal } = useContext(OrganisationContext);

  const handleAddNewTeam = () => {
    setModal({
      type: 'add-new-team',
      content: {
        title: t('ADD_NEW_TEAM'),
      },
    });
  };

  return (
    <TeamsWrapper className="flex-column w-full h-full items-start justify-start flex-1">
      <PageHeader
        names={[
          { text: t('SETTINGS'), onClick: () => navigate('/settings') },
          { text: t('SECURITY'), onClick: () => navigate('/settings') },
          { text: t('TEAMS'), onClick: () => {} },
        ]}
        showAddNewBtn
        onAddClick={handleAddNewTeam}
        showSearch
        addBtnPermission="TEAMS_MANAGE"
        searchText={search}
        addButtonLabel={t('ADD_TEAM')}
        onSearchChange={setSearchText}
      />
      <TeamsList debouncedSearch={debouncedSearch} />
    </TeamsWrapper>
  );
}

export default Teams;
