import { createSlice } from '@reduxjs/toolkit';
import api from '../services';

export const workitemSlice = createSlice({
  name: 'workItem',
  initialState: {
    allowedProgressAbleStageIds: [],
    stageDecisions: [],
    workItemProgress: [],
  },
  reducers: {
    setWorkItemProgress: (state, { payload }) => {
      state.workItemProgress = payload;
    },
    setAllowedProgressAbleStageIds: (state, { payload }) => {
      state.allowedProgressAbleStageIds = payload;
    },
    setStageDecisions: (state, { payload }) => {
      state.stageDecisions = payload;
    },
  },
});

export const progressWorkitem =
  ({ workitem_id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/workitems/${workitem_id}/progress`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updateWorkitemOwners =
  ({ workitem_id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.put(`/workitems/${workitem_id}/owners`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getWorkItemAccess =
  ({ id, params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/workitems/${id}/access`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getEngagementAccess =
  ({ id, params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/engagements/${id}/access`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const { setAllowedProgressAbleStageIds, setStageDecisions, setWorkItemProgress } = workitemSlice.actions;
export default workitemSlice.reducer;
