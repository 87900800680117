import classNames from 'classnames';
import React from 'react';
import { Tooltip } from 'react-tooltip';
import styled from 'styled-components';

const CustomTooltip = ({
  id = '',
  place = 'top',
  clickable = false,
  children,
  content,
  wrapperClassName = '',
  hidden = false,
  noArrow = false,
  isOpen = undefined,
  onBlurTooltip = () => {},
  tooltipId = '',
  opacity = 1,
  positionStrategy = undefined,
  otherClass = '',
  tooltipClassname = '',
  onWrapperClick = () => {},
  wrapperClickable = false,
  ...restProps
}) => {
  const onBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      onBlurTooltip();
    }
  };

  return (
    <CustomTooltipWrapper
      onBlur={onBlur}
      tabIndex={-1}
      data-tooltip-id={id}
      as={wrapperClickable ? 'button' : 'div'}
      className={wrapperClassName}
      id={`tooltip-container-${id || tooltipId}`}
      onClick={onWrapperClick}>
      {children}
      <Tooltip
        opacity={opacity}
        id={id || tooltipId}
        place={place}
        clickable={clickable}
        positionStrategy={positionStrategy}
        className={classNames('custom-tooltip', otherClass, tooltipClassname)}
        classNameArrow="custom-arrow"
        hidden={hidden}
        noArrow={noArrow}
        isOpen={isOpen}
        {...restProps}>
        {content}
      </Tooltip>
    </CustomTooltipWrapper>
  );
};

const CustomTooltipWrapper = styled.div`
  .custom-tooltip {
    background-color: #fff;
    color: ${({ theme }) => theme.natural_900};
    border-radius: 8px;
    padding: 16px 20px;
    filter: drop-shadow(0px 3px 3px #0000000d);
    z-index: 1000;
  }
`;

export default CustomTooltip;
