import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { TeamMemberBodyWrapper } from '../../../../styles/components/test-name-details/test-name-details.styled';

import { useTranslation } from 'react-i18next';
import { ReactComponent as NoDocumentIcon } from '../../../../assets/icons/empty-document.svg';
import { ReactComponent as MailIcon } from '../../../../assets/images/mail.svg';
import { ReactComponent as MobileIcon } from '../../../../assets/images/mobile.svg';
import { OrganisationContext } from '../../../../context/organisationContext';
import { formatText, lowercase } from '../../../../helpers/utils';
import MenuV2 from '../../../common/menu/menu-v2';
import NoData from '../../../common/no-data';

const TeamMemberBody = () => {
  const { teamMembers, teamDetails } = useSelector(state => state.teamManagement);

  const { t } = useTranslation();
  const { setModal } = useContext(OrganisationContext);

  const onDeleteTeam = item => {
    setModal({
      type: 'team-user-action',
      content: {
        teamuser: item,
        team: teamDetails,
        top: true,
        isDelete: true,
        title: t('YOU_WANT_TO_DELETE_TEAM_MEMBER'),
        description: t('DELETE_TEAM_MEMBER_DESCRIPTION'),
      },
    });
  };

  return (
    <TeamMemberBodyWrapper className="flex flex-column flex-1 overflow-y-scroll">
      {teamMembers?.length > 0 ? (
        teamMembers?.map((item, index) => (
          <div className={`flex-row border-bottom`} key={index}>
            <div className="flex-row w-50 pxy-5 border-right justify-between my-1">
              <div className="flex-column">
                <div className=" font-14 mb-2 inter-500-text line-height-20 natural-900-text">{item?.user?.name}</div>
                <div className=" font-14 inter-400-text line-height-20 natural-500-text">
                  {lowercase(formatText(item?.level))}
                </div>
              </div>
              <label className="inter-500-text font-12 status-text">
                {item?.user?.is_active ? (
                  <div className="inter-500-text font-12 line-height-20 active text-lowercase">{t('ACTIVE')}</div>
                ) : null}
              </label>
            </div>
            <div className="flex-row w-50 justify-between py-3 px-6">
              <div className="flex-column gap-2">
                <div className="flex items-center justify-start w-full">
                  <div className="flex items-center justify-center icon-wrapper mr-4">
                    <MailIcon className="flex items-center justify-center " height={12} width={14} />
                  </div>
                  <label
                    className={`one-line font-14 inter-400-text line-height-20 natural-900-text ${
                      !item?.user?.email ? 'grey-text' : ''
                    }`}>
                    {item?.user?.email ? item?.user?.email : t('NOT_PROVIDED')}
                  </label>
                </div>

                <div className="flex items-center justify-start w-full mt-1">
                  <div className="flex items-center justify-center icon-wrapper mr-4">
                    <MobileIcon className="flex items-center justify-center " height={12} width={14} />
                  </div>
                  <label
                    className={`one-line font-14 inter-400-text line-height-20 natural-900-text ${
                      !item?.user?.phone ? 'grey-text' : ''
                    }`}>
                    {item?.user?.phone ? item?.user?.phone : t('NOT_PROVIDED')}
                  </label>
                </div>
              </div>
              <div className="flex items-center">
                <MenuV2
                  menuList={[
                    {
                      name: item.level === 'MEMBER' ? t('MAKE_TEAM_LEAD') : t('MAKE_TEAM_MEMBER'),
                      onClick: () => {},
                    },
                    { name: t('REMOVE_FROM_TEAM'), onClick: () => onDeleteTeam(item?.user) },
                  ]}
                />
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="flex-column flex-1 mb-6 items-center justify-center">
          <NoData
            title={t('NO_TEAM_MEMBERS')}
            className="search-terms"
            description={t('NO_TEAM_MEMBERS_HERE')}
            EmptyIcon={NoDocumentIcon}
            iconClassName="mb-6 relative"
          />
        </div>
      )}
    </TeamMemberBodyWrapper>
  );
};

export default TeamMemberBody;
