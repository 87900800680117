import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as SearchIcon } from '../../assets/images/search.svg';
import Checkbox from '../../components/common/checkbox';
import InputElement from '../../components/common/input';
import SearchableDropdown from '../../components/common/searchable-dropdown';
import UpdateAction from '../../components/common/update-action';
import { OrganisationContext } from '../../context/organisationContext';
import { initModal } from '../../helpers/utils';
import { getPropertyAddress, getPropertyAddressById } from '../../store/features/newPropertySlice';
import { addToast } from '../../store/features/toastSlice';

const formatted_address_format = ['line1', 'line2', 'line3', 'city', 'state', 'postcode'];

const Address = ({ address, onAddressUpdate, disabled, setLoadingAddress, className = '' }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [search, setSearch] = useState('');

  const onAddressSelect = address => {
    setLoadingAddress(true);
    dispatch(getPropertyAddressById({ id: address.id }))
      .then(data => {
        onAddressUpdate(data);
      })
      .catch(e => {
        return '';
      })
      .finally(() => setLoadingAddress(false));
  };

  const fetchChildOpt = async (
    search,
    _prevOptions,
    { page, merge, fetchFunction, hasMore, pageable, params = {} },
  ) => {
    if (!fetchFunction) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
          merge: merge,
          fetchFunction: fetchFunction,
          hasMore: hasMore,
        },
      };
    }
    try {
      const optionData = await dispatch(
        fetchFunction({
          forFetchOnly: true,
          params: {
            page: page,
            search: search,
            ...params,
          },
        }),
      );
      let optionContent = [];
      let last = true;
      if (pageable) {
        const { content, ...restResponse } = optionData || {};
        optionContent = optionData ? content : [];
        last = restResponse.last;
      } else {
        optionContent = optionData || [];
      }
      const changedOptions = optionContent.map(option => ({ ...option, label: option.address, value: option.id }));
      return {
        options: changedOptions,
        hasMore: !last,
        additional: {
          page: page + 1,
          merge: merge,
          fetchFunction: fetchFunction,
          hasMore: !last,
          pageable,
        },
      };
    } catch (error) {
      return {
        options: [],
        hasMore: hasMore,
        additional: {
          page: page,
          merge: merge,
          fetchFunction: fetchFunction,
          hasMore: hasMore,
        },
      };
    }
  };

  return (
    <AddressWrapper className={classNames('relative w-full', className)}>
      <div className="search-icon absolute o03">
        <SearchIcon />
      </div>
      <SearchableDropdown
        loadOptionsOnMenuOpen={false}
        loadOptions={fetchChildOpt}
        className="w-full"
        isDisabled={disabled}
        isCustomSearchable={false}
        placeholder={t('SEARCH_FOR_AN_ADDRESS')}
        customStyle={{
          control: { height: '32px', borderRadius: '100px', background: '#F5F5F5' },
          valueContainer: { padding: '0 16px' },
        }}
        inputValue={search}
        onInputChange={setSearch}
        value={address ? { label: address } : null}
        onChange={onAddressSelect}
        isSearchable={true}
        defaultAdditional={{ page: 0, fetchFunction: getPropertyAddress, pageable: false }}
      />
    </AddressWrapper>
  );
};

const AddressPopup = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { insideModal, setInsideModal } = useContext(OrganisationContext);
  const {
    addressDetails,
    setAddressDetails,
    propertyAddress,
    hideSameAsPropertyToggle = false,
  } = insideModal?.content || {};

  const [address, setAddress] = useState(addressDetails ?? {});
  const [error, setError] = useState({});
  const [loadingAddress, setLoadingAddress] = useState(false);
  const [isSameAsProperty, setIsSameAsProperty] = useState(false);

  const { formatted_address, line1, line2, line3, city, state, postcode } = address || {};

  const checkAddressError = () => {
    const { formatted_address, line1, city, postcode } = address;
    const error = {
      formatted_address: !formatted_address?.trim(),
      line1: !line1?.trim(),
      city: !city?.trim(),
      postcode: !postcode?.trim(),
    };
    setError(error);
    return Object.values(error).some(err => Boolean(err));
  };

  const onAddressUpdate = address => {
    const formatted_address = formatted_address_format
      .map(a => address[a])
      .filter(value => Boolean(value))
      .join(', ');
    setAddress({
      is_billing: false,
      is_postal: false,
      is_primary: false,
      is_residential: false,
      ...address,
      formatted_address,
    });
  };

  const onChangeAddress = (key, value) => {
    const updatedAddress = { ...address, [key]: value };
    const formatted_address = formatted_address_format
      .map(a => updatedAddress[a])
      .filter(value => Boolean(value))
      .join(', ');
    setAddress({
      is_billing: false,
      is_postal: false,
      is_primary: false,
      is_residential: false,
      ...updatedAddress,
      formatted_address,
    });
  };

  const onSave = () => {
    if (isSameAsProperty) {
      setAddressDetails(propertyAddress);
      setInsideModal(initModal);
      return;
    }
    if (checkAddressError()) {
      dispatch(addToast({ error: true, text: t('PLEASE_FILL_ALL_THE_REQUIRED_FIELDS'), id: nanoid() }));
      return;
    }
    setAddressDetails(address);
    setInsideModal(initModal);
  };
  return (
    <AddressPopupWrapper className="flex-column row-gap-6 pxy-6">
      <p className="inter-600-text font-20 border-bottom pb-5">{t('CONTACT_ADDRESS')}</p>
      <div className="mb-6">
        <div className="flex items-center justify-between">
          <Address
            disabled={isSameAsProperty}
            address={isSameAsProperty ? null : formatted_address}
            onAddressUpdate={onAddressUpdate}
            loadingAddress={loadingAddress}
            setLoadingAddress={setLoadingAddress}
          />
          {!hideSameAsPropertyToggle && (
            <div className="flex items-center col-gap-2">
              <Checkbox
                onChange={() => setIsSameAsProperty(!isSameAsProperty)}
                checked={isSameAsProperty}
                is_checked_done={true}
              />
              <label className="inter-500-text natural-700-text">{t('SAME_AS_PROPERTY_ADDRESS')}</label>
            </div>
          )}
        </div>
        {isSameAsProperty ? (
          <InputElement
            className="w-full mt-6"
            placeholder={t('PROPERTY_ADDRESS')}
            value={propertyAddress?.formatted_address}
            disabled
          />
        ) : (
          <div className="address-grid row-gap-6 mt-7">
            <InputElement
              className="w-full"
              name={t('ADDRESS_LINE_ONE')}
              placeholder={t('EXAMPLE_WALNUT_AVENUE')}
              value={line1}
              onChange={value => onChangeAddress('line1', value)}
              error={error.line1 && !address.line1}
            />
            <InputElement
              className="w-full"
              name={t('ADDRESS_LINE_TWO')}
              sub_name={t('OPTIONAL')}
              placeholder={t('EXAMPLE_APARTMENT')}
              value={line2}
              onChange={value => onChangeAddress('line2', value)}
              error={error.line2 && !address.line2}
            />
            <InputElement
              className="w-full"
              name={t('ADDRESS_LINE_THREE')}
              sub_name={t('OPTIONAL')}
              placeholder={t('EXAMPLE_ACOMB')}
              value={line3}
              onChange={value => onChangeAddress('line3', value)}
              error={error.line3 && !address.line3}
            />
            <InputElement
              className="w-full"
              name={t('CITY')}
              placeholder={t('EXAMPLE_RYDE')}
              value={city}
              onChange={value => onChangeAddress('city', value)}
              error={error.city && !address.city}
            />
            <InputElement
              className="w-full"
              name={t('POSTCODE')}
              placeholder={t('EXAMPLE_BN16_78F')}
              value={postcode}
              onChange={value => onChangeAddress('postcode', value)}
              error={error.postcode && !address.postcode}
            />
            <InputElement
              className="w-full"
              name={t('COUNTY')}
              sub_name={t('OPTIONAL')}
              placeholder={t('EXAMPLE_ISLE_OF_WIGHT')}
              value={state}
              onChange={value => onChangeAddress('state', value)}
              error={error.state && !address.state}
            />
          </div>
        )}
      </div>
      <div className="flex items-center justify-end">
        <UpdateAction
          disabled={loadingAddress}
          className="p-0 w-fit-content"
          size="average"
          width="100px"
          btnClassname="specified-width"
          onUpdate={onSave}
          onCancel={() => setInsideModal(initModal)}
        />
      </div>
    </AddressPopupWrapper>
  );
};

const AddressPopupWrapper = styled.div`
  width: 700px;

  .address-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }
`;

const AddressWrapper = styled.div`
  .search-icon {
    top: 14px;
    left: 16px;
    z-index: 999;
  }

  .searchable-select__input-container,
  .searchable-select__placeholder,
  .searchable-select__single-value {
    margin-left: 20px;
  }
`;

export default AddressPopup;
