import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { ReactComponent as AlertIcon } from '../../assets/icons/AlertIcon.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/CrossIcons.svg';
import Button from '../../components/common/button/button';
import Status from '../../components/common/status';
import { OrganisationContext } from '../../context/organisationContext';
import { getErrorDescription, getFormattedNumber, initModal } from '../../helpers/utils';
import { deleteOrganizationProduct, deleteProduct } from '../../store/features/quotesSlice';
import { addToast } from '../../store/features/toastSlice';

const DeleteProduct = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { user } = useSelector(state => state.user);
  const { organizations } = user || {};
  const { id: organization_id } = organizations?.[0] || {};

  const { modal, setModal } = useContext(OrganisationContext);
  const { product, title, descriptions, is_product_owner, onSuccess } = modal.content;
  const { id: product_id, name, sku, description, sold_as, pricing } = product;
  const [firstPricing] = pricing || [];
  const [price] = firstPricing?.prices || [];

  const sell_price = price?.price || 0;

  const [loading, setLoading] = useState(false);
  const onDone = async () => {
    setLoading(true);
    try {
      const deleteAPI = is_product_owner ? deleteProduct : deleteOrganizationProduct;
      await dispatch(deleteAPI({ product_id: product_id, id: organization_id }));

      const title = is_product_owner ? t('PRODUCT_DELETED_SUCCESSFULLY') : t('PRODUCT_REMOVED_SUCCESSFULLY');
      dispatch(addToast({ error: false, text: title, id: nanoid() }));
      onSuccess && onSuccess(product);
      setModal(initModal);
    } catch (error) {
      const errorMessage = is_product_owner ? t('ERROR_WHILE_DELETING_PRODUCT') : t('ERROR_WHILE_REMOVING_PRODUCT');
      const textFromError = getErrorDescription(error, errorMessage);
      dispatch(addToast({ error: true, text: textFromError, id: nanoid() }));
    } finally {
      setLoading(false);
    }
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <DeleteProductWrapper className="flex-column items-center radius-3 pxy-6">
        <div className="flex-column content-wrapper gap-5 items-center relative">
          <AlertIcon />
          <CrossIcon className="absolute right-0 cursor" onClick={() => setModal(initModal)} />
          <div className="flex-column gap-2 items-center justify-start w-full">
            <label className="inter-500-text font-18 text-center">{title}</label>
            <p className="inter-400-text natural-400-text text-center font-14">{descriptions}</p>
          </div>
        </div>
        <div className="flex-column border radius-1_5 py-4 px-5 w-full row-gap-2">
          <div className="flex items-center justify-between gap-5">
            <label className="inter-600-text font-16">{name}</label>
            <label className="inter-400-text">{sku}</label>
          </div>
          <label className="inter-400-text natural-500-text">{description}</label>
          <div className="flex items-center gap-2 mt-3">
            <label className="inter-400-text natural-500-text">{t('SALES_PRICE')}:</label>
            <label className="inter-500-text">{getFormattedNumber(sell_price || 0)}</label>
          </div>
          <div className="flex items-center gap-2 mt-3">
            <label className="inter-400-text natural-500-text">{t('SOLD_AS')}:</label>
            <Status status={sold_as} />
          </div>
        </div>
        <div className="action-wrapper flex-column justify-center w-full gap-3">
          <Button
            className={classNames('negative')}
            label={is_product_owner ? t('DELETE') : t('REMOVE')}
            disabled={loading}
            loading={loading}
            onClick={onDone}
            size="large"
            borderRadius="100px"
          />
          <Button
            className={classNames('primary-grey')}
            label={t('CANCEL')}
            disabled={loading}
            onClick={() => setModal(initModal)}
            size="large"
            borderRadius="100px"
          />
        </div>
      </DeleteProductWrapper>
    </CSSTransition>
  );
};

export const DeleteProductWrapper = styled.div`
  width: 420px;
  gap: 24px;
`;

export default DeleteProduct;
