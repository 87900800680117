import classNames from 'classnames';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { ReactComponent as AlertIcon } from '../../assets/icons/AlertIcon.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/CrossIcons.svg';
import AvatarGroup from '../../components/common/avatar-group';
import Button from '../../components/common/button/button';
import InputElement from '../../components/common/input';
import SearchableDropdown from '../../components/common/searchable-dropdown';
import { OrganisationContext } from '../../context/organisationContext';
import { getErrorDescription, getUserInitials, initModal } from '../../helpers/utils';
import { getCancleReason, updateActivityStatus } from '../../store/features/activitiesSlice';
import { addToast } from '../../store/features/toastSlice';

const CustomOption = ({ innerProps, data, isSelected }) => {
  return (
    <div
      className={classNames(' w-full px-4 py-3 cursor border-bottom option-wrapper', isSelected && 'selected')}
      {...innerProps}>
      <div>
        <span
          className={classNames(
            'flex-1 inter-400-text natural-900-text option-text ml-2',
            isSelected && 'inter-500-text',
          )}>
          {data.label}
        </span>
      </div>
      <div>
        <span
          className={classNames(
            'flex-1 inter-400-text natural-500-text option-text ml-2',
            isSelected && 'inter-500-text',
          )}>
          {data.description}
        </span>
      </div>
    </div>
  );
};

const ActivityCancel = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { modal, setModal } = useContext(OrganisationContext);
  const { title, description } = modal || {};
  const { onSuccess, activity } = modal.content || {};

  const { id: activityId, resources, task_count, status, actual_date, expected_date } = activity || {};
  const { start_date, duration } = actual_date || {};
  const { start_date: expected_start_date, duration: expected_duration } = expected_date || {};
  const startTimeMoment = moment.unix(start_date || expected_start_date);

  const [note, setNote] = useState('');
  const [reason, setReason] = useState(null);
  const [searchedReason, setSearchedReason] = useState('');
  const [error, setError] = useState({ reason: false });
  const [loading, setLoading] = useState(false);

  const handleConfirm = () => {
    if (!reason?.id) {
      dispatch(addToast({ error: true, text: 'Please select a reason', id: nanoid() }));
      setError({ reason: true });
      return;
    }
    setError({ reason: false });
    const request = {
      status: 'CANCELLED',
      actual_date: {
        start_date: start_date || expected_start_date,
        duration: duration || expected_duration,
      },
      cancellation: {
        note: note,
        reason: {
          id: reason.id,
        },
      },
    };

    setLoading(true);
    dispatch(updateActivityStatus({ activity_id: activityId, request: request }))
      .then(resposne => {
        setModal(initModal);
        dispatch(addToast({ error: false, text: t('ACTIVITY_CANCELLED'), id: nanoid() }));
        onSuccess && onSuccess();
      })
      .catch(error => {
        dispatch(
          addToast({
            error: true,
            text: getErrorDescription(error, t('FAILED_ACTIVITY_CANCELLED')),
            id: nanoid(),
          }),
        );
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <ActivityCancelWrapper className="flex-column items-center p-6 gap-6">
        <div className="flex-column gap-5 content-wrapper items-center relative w-full">
          <AlertIcon />
          <CrossIcon className="absolute right-0 cursor" onClick={() => setModal(initModal)} />
          <div className="flex-column gap-2 items-center justify-start w-full">
            <p className="font-18 text-center inter-500-text natural-900-text">{title}</p>
            <span className="inter-400-text font-14 natural-400-text text-center">{description}</span>
          </div>
        </div>
        <div className="flex justify-between w-full items-end">
          <div className="flex items-center gap-3">
            <div className="flex-column items-center px-3 py-1_5 radius-1 bg-natural-150">
              <label className="error-text block inter-600-text font-10 text-uppercase">
                {startTimeMoment?.format('MMM')}
              </label>
              <label className="inter-600-text font-18 natural-900-text">{startTimeMoment?.get('date')}</label>
            </div>
            <div className="flex-column justify-between">
              <label className="font-14 inter-500-text natural-900-text">{activity?.name}</label>
              <label className="block font-14 inter-400-text natural-500-text">{`${startTimeMoment?.format(
                'HH:mm',
              )} - ${startTimeMoment
                ?.clone()
                .add(duration || expected_duration, 'minutes')
                .format('HH:mm')}`}</label>
            </div>
          </div>
          <AvatarGroup avatars={resources} avatarVariant="medium" getAvatarName={user => getUserInitials(user?.user)} />
        </div>
        <div className="w-full gap-5px flex-column ">
          <SearchableDropdown
            isClearable
            inputValue={searchedReason}
            onInputChange={setSearchedReason}
            placeholder="Select Reason"
            name={t('REASON')}
            value={reason}
            onChange={option => {
              setReason(option);
              setError({ reason: false });
            }}
            defaultAdditional={{
              page: 0,
              fetchFunction: getCancleReason,
              pageable: false,
            }}
            customComponent={{ Option: CustomOption }}
            error={error.reason}
          />
        </div>

        <div className="w-full flex-column">
          <InputElement
            name={'Note'}
            onChange={e => {
              setNote(e);
            }}
            value={note}
            placeholder="e.g. Some notes goes here"
            type="textarea"
          />
        </div>

        <div className="flex-column justify-center w-full gap-3">
          <Button
            className={classNames('negative delete-btn', loading && 'disabled')}
            disabled={loading}
            label={'Cancel'}
            onClick={() => handleConfirm()}
            size="large"
            borderRadius="100px"
          />
          <Button
            className={classNames('cancel-btn default', loading && 'disabled')}
            label={'Keep'}
            size="large"
            loading={loading}
            borderRadius="100px"
            bgColor={'#ffff'}
            onClick={() => setModal(initModal)}
            borderColor={'#2496FF'}
          />
        </div>
      </ActivityCancelWrapper>
    </CSSTransition>
  );
};

const ActivityCancelWrapper = styled.div`
  width: 420px;
`;

export default ActivityCancel;
