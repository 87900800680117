import styled from 'styled-components';

const QuoteDetailsProductsWrapper = styled.div`
  .header-container {
    background-color: ${({ theme }) => theme.natural_50};
  }

  .header-container,
  .data-container {
    height: 100%;
    min-height: 52px;
    display: grid;
    grid-template-columns: minmax(100px, 1fr) minmax(100px, 2fr) 56px 118px 80px 80px 100px 90px;
    column-gap: 40px;
    padding: 12px 24px;
  }

  .header-container {
    grid-template-columns: minmax(100px, 1fr) minmax(100px, 2fr) 56px 118px 80px 80px 100px 90px;
  }

  .data-container {
    grid-template-rows: 44px 1fr;
    row-gap: 16px;

    .input {
      height: 28px;
      padding: 2px 12px;
      width: calc(100% - 16px);
    }
  }

  .components-container {
    height: 100%;
    min-height: 52px;
    display: grid;
    grid-template-columns: minmax(100px, 1fr) minmax(100px, 2fr) 56px 118px 80px 80px 100px 90px;
    column-gap: 40px;
    padding: 12px 0;
    margin: 0 24px;

    .component-name {
      justify-content: flex-start;
    }
  }
`;

export default QuoteDetailsProductsWrapper;
