import React from 'react';
import { useTranslation } from 'react-i18next';
import SearchableDropdown from '../../common/searchable-dropdown';
import UpdateAction from '../../common/update-action';

export const EditAutomationField = ({
  data,
  onCancelUpdateData,
  onUpdateData,
  setEditField,
  setError,
  error,
  customComponent,
  defaultAdditional,
  loadOptions,
  setSearchedOwner,
  searchedOwner,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex-column row-gap-1 w-full">
        <span className="inter-500-text natural-900-text font-14 mb-1">
          {data.type === 'NAME' && t('NAME')} {data.type === 'DESCRIPTION' && t('DESCRIPTION')}{' '}
          {data.type === 'OWNER' && t('OWNER')}
        </span>
        {data.type === 'NAME' && (
          <input
            autoComplete="turnoff"
            className={'input w-full name-input'}
            value={data?.value}
            onChange={e => {
              setEditField({ ...data, value: e?.target?.value });
              setError({ ...error, name: false });
            }}
            placeholder={t('NAME')}
          />
        )}
        {data.type === 'DESCRIPTION' && (
          <textarea
            className={`w-full input textarea`}
            onChange={e => {
              setEditField({ ...data, value: e.target.value });
              setError({ ...error, description: false });
            }}
            placeholder={t('DESCRIPTION_PLACEHOLDER')}
            rows={4}
            value={data.value || ''}
          />
        )}
        {data.type === 'OWNER' && (
          <>
            <SearchableDropdown
              isClearable
              inputValue={searchedOwner}
              onInputChange={setSearchedOwner}
              loadOptions={loadOptions}
              placeholder={t('SELECT_OWNER')}
              onChange={e => {
                setEditField({ ...data, value: e });
              }}
              value={data?.value}
              customComponent={customComponent}
              defaultAdditional={defaultAdditional}
            />
          </>
        )}
      </div>

      <UpdateAction
        size="medium"
        onCancel={() => onCancelUpdateData()}
        onUpdate={onUpdateData}
        width="100%"
        height="40px"
        className="update-action "
        btnClassname="specified-width"
      />
    </>
  );
};
