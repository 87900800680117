import styled from 'styled-components';

const SecurityWrapper = styled.div`
  .content-box {
    border: 1px solid ${({ theme }) => theme.lightGrey};
  }
  .subtitle-text {
    color: ${({ theme }) => theme.greyText2};
  }
`;

export default SecurityWrapper;
