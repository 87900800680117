import React from 'react';
import { Manager, Popper, Reference } from 'react-popper';
import { CSSTransition } from 'react-transition-group';
import styled, { createGlobalStyle } from 'styled-components';
// import { createPortal } from "react-dom";

const Popover = ({ isOpen, content, children, className, style, placement, offset }) => {
  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <div ref={ref} className={className}>
            {children}
          </div>
        )}
      </Reference>
      <Popper modifiers={[{ name: 'offset', options: { offset: offset || [0, 5] } }]} placement={placement || 'bottom'}>
        {({ ref, style, placement, arrowProps }) => (
          <>
            <CSSTransition classNames="tp-ds__popover" in={isOpen} timeout={200} unmountOnExit>
              <Content ref={ref} className="tooltip-content" data-placement={placement} style={style}>
                <div data-popper-arrow id="arrow" {...arrowProps}></div>
                {content}
              </Content>
            </CSSTransition>
          </>
        )}
      </Popper>
      <PopoverAnimationGlobalStyle />
    </Manager>
  );
};

export const Content = styled.div`
  background: white;
  z-index: 10;
  margin-left: 12px;
  &::before {
    content: '';
    background: white;
    width: 16px;
    height: 10px;
    transform: translate(-50%, -50%) rotate(45deg);
    position: absolute;
    left: 50%;
    top: 0px;
  }
`;

export const PopoverAnimationGlobalStyle = createGlobalStyle`
.tp-ds__popover-enter {
  opacity: 0;
}
.tp-ds__popover-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.tp-ds__popover-exit {
  opacity: 1;
}
.tp-ds__popover-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}
`;

export default Popover;
