import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addBreadcrumbs, removeBreadcrumbs } from '../../../store/features/navbarSlice';

const BreadcrumbWrapper = ({ breadcrumbs = [] }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addBreadcrumbs(breadcrumbs));
    return () => {
      dispatch(removeBreadcrumbs());
    };
  }, [breadcrumbs]);
};

export default BreadcrumbWrapper;
