import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import styled from 'styled-components';
import { ReactComponent as CrossIcon } from '../assets/icons/CrossIcons.svg';
import Button from '../components/common/button/button';
import InputElement from '../components/common/input';
import Dropdown from '../components/common/select-dropdown';
import { OrganisationContext } from '../context/organisationContext';
import { productType, soldAs } from '../helpers/optionData';
import { initModal } from '../helpers/utils';
import { addNewProductManually } from '../store/features/quotesSlice';
import { addToast } from '../store/features/toastSlice';

const AddNewProductManually = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { setModal } = useContext(OrganisationContext);
  const [product, setProduct] = useState({});
  const { name, description, sku, product_type, sold_as } = product || {};
  const [error, setError] = useState({ name: false });
  const [loading, setLoading] = useState(false);

  const onAddProduct = async () => {
    if (loading) {
      return;
    }

    setLoading(true);
    const request = {
      name: name,
      description: description,
      sku: sku,
      product_type: product_type,
      sold_as: sold_as,
      image: null,
      lifecycle: null,
      devices: null,
      long_description: null,
      lifecycle: 'INACTIVE',
    };
    dispatch(addNewProductManually({ request: request }))
      .then(data => {
        dispatch(
          addToast({
            error: false,
            text: t('PRODUCT_ADDED_SUCCESSFULLY'),
            id: uuid(),
          }),
        );
        navigate(`/settings/platform-configuration/products/${data?.id}`);
        setModal(initModal);
      })
      .catch(() => {
        dispatch(
          addToast({
            error: true,
            text: t('ERROR_WHILE_ADDING_PRODUCT'),
            id: uuid(),
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <AddNewProductManuallyWrapper className="flex-column items-center py-9 px-10">
      <div className="w-full flex justify-between items-center">
        <label className="font-20 inter-600-text text-black text-start">{t('NEW_PRODUCT')}</label>
        <CrossIcon className="cursor" onClick={() => setModal(initModal)} width={24} height={24} />
      </div>

      <div className="w-full flex-column gap-5px mt-8">
        <InputElement
          name={t('PRODUCT_NAME')}
          onChange={value => {
            setProduct({ ...product, name: value });
            setError({ ...error, name: false });
          }}
          error={error.name}
          placeholder={t('ENTER_PRODUCT_NAME')}
          value={name || ''}
        />
      </div>
      <div className="w-full flex-column gap-5px mt-8">
        <InputElement
          name={t('SKU')}
          onChange={value => {
            setProduct({ ...product, sku: value });
            setError({ ...error, sku: false });
          }}
          error={error.sku}
          placeholder="e.g. KS944UAN"
          value={sku || ''}
        />
      </div>
      <div className="w-full flex-column gap-5px mt-6">
        <InputElement
          name={t('DESCRIPTION')}
          onChange={value => {
            setProduct({ ...product, description: value });
            setError({ ...error, description: false });
          }}
          error={error.description}
          value={description || ''}
          placeholder={t('DESCRIPTION_PLACEHOLDER_PRODUCT')}
          type="textarea"
        />
      </div>
      <div className="w-full flex-column gap-5px mt-6">
        <Dropdown
          name={t('PRODUCT_TYPE')}
          id="product_type"
          placeholder={t('SELECT')}
          options={productType}
          value={productType.find(s => s.value === product_type) || ''}
          onChange={value => {
            setProduct({ ...product, product_type: value?.value });
            setError({ ...error, product_type: false });
          }}
        />
      </div>
      <div className="w-full flex-column gap-5px mt-6">
        <Dropdown
          name={t('SOLD_AS')}
          placeholder={t('SELECT')}
          id="product_sold_as"
          options={soldAs}
          value={soldAs.find(s => s.value === sold_as) || ''}
          onChange={value => {
            setProduct({ ...product, sold_as: value?.value });
            setError({ ...error, sold_as: false });
          }}
        />
      </div>
      <div className="flex col-gap-5 justify-center mt-10 w-full">
        <Button
          className={`default flex-1 `}
          label={t('CANCEL')}
          disabled={loading}
          onClick={() => setModal(initModal)}
          secondary={true}
          size="large"
          borderRadius="100px"
        />
        <Button
          className={`primary flex-1`}
          label={t('SAVE')}
          onClick={onAddProduct}
          size="large"
          borderRadius="100px"
          disabled={loading || !(name && description && sku && product_type && soldAs)}
        />
      </div>
    </AddNewProductManuallyWrapper>
  );
};

const AddNewProductManuallyWrapper = styled.div`
  width: 455px;

  .error-border {
    border-color: ${({ theme }) => theme.additionalRed};

    .organisations-border {
      border-color: ${({ theme }) => theme.additionalRed} !important;
    }
  }

  .input,
  .textarea {
    color: #171717;
  }
`;

export default AddNewProductManually;
