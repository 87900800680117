import classNames from 'classnames';
import React from 'react';
import { ReactComponent as ArrowRighIcon } from '../../../assets/images/arrow-right.svg';
import { uppercase } from '../../../helpers/utils';
import Button from '../button/button';
import Checkbox from '../checkbox';

const TableHeader = ({
  headers,
  headerClassName = '',
  allSelected = false,
  is_checked_done = false,
  onSelectorClick = () => {},
  headerLabelClassName = '',
  is_selected = false,
}) => {
  return (
    <div
      className={classNames('border-bottom header-container letter-spacing-1 flex items-center', headerClassName)}
      style={{ position: 'sticky', top: 0, zIndex: 9 }}>
      {headers.map(header =>
        header.key === 'ITEM_SELECTOR' ? (
          <Checkbox
            key={header.key}
            inputClassname=""
            checked={allSelected}
            onChange={() => onSelectorClick()}
            is_checked_done={is_checked_done}
          />
        ) : header.key === 'ITEM_DROPDOWN' ? (
          <Button
            afterIcon={<ArrowRighIcon className={classNames('natural-900-text', is_selected && 'rotate-90')} />}
            label={header.name}
            disabled={header?.disabled}
            onClick={() => header?.onClick()}
            className="grey-150 specified-width px-1"
            width="100%"
          />
        ) : (
          <label
            key={header.key}
            className={classNames('inter-600-text natural-700-text font-12', headerLabelClassName, header.classname)}>
            {uppercase(header.name)}
          </label>
        ),
      )}
    </div>
  );
};

export default TableHeader;
