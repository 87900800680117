export const TRANSLATIONS_EN_PROPERTY_COSTS = {
  ADD_COST: 'Add Cost',
  ADD_NEW_COSTS: 'Add New Cost',
  EDIT_COSTS: 'Edit Cost',
  NO_COSTS: 'No costs',
  NO_COSTS_CURRENTLY: 'There are currently no costs here. Please check back later or add a new cost.',
  TOTAL_SALE: 'Total sale',
  TOTAL_COST: 'Total cost',
  COST_DESCRIPTION_DETAIL: ' Cost description goes here, cost a bit long description goes here',
  COST_NET: 'Cost Net',
  COST_TAX: 'Cost Tax',
  SALE_NET: 'Sale Net',
  SALE_TAX: 'Sale Tax',
  QUANTITY: 'Quantity',
  COST_PRICE: 'Cost Price',
  SALE_PRICE: 'Sale Price',
  REMOVE_THIS_COSTS: 'Delete this cost?',
  REMOVE_THIS_COSTS_DESCRIPTION:
    'Are you sure you want to remove this cost? Once removed, this cost cannot be recovered.',
  COST_TYPE: 'Cost type',
  COST_NAME: 'Cost name',
  EXAMPLE_COST_NAME: 'e.g. Cost name',
  EXAMPLE_COST_DESCRIPTION: 'e.g. Cost description goes here',
  SELECT_COST_TYPE: 'Select cost type',
  INVENTORY: 'Inventory',
  COSTS_REMOVED: 'Costs removed',
  SUCCESSFULLY_REMOVED_COSTS: 'You have successfully removed the costs',
  ERROR_REMOVING_COSTS: 'Error while removing costs',
  COSTS_ADDED: 'Costs added',
  SUCCESSFULLY_COSTS_ADDED: `cost added successfully `,
  ERROR_FETCHING_COSTS_LIST: `Error while fetching Costs list `,
  ERROR_ADD_PRICE_ABOVE: `You cant add quantity and costs price above you. `,
  ERROR_ENTER_COST_PRICE: `Please enter a quantity , costs and sale price. `,
  ERROR_WHILE_ADDING_COST: 'Error while adding Cost',
};
