import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as UserIcon } from '../../../assets/icons/user-assignee.svg';
import { getUserInitials } from '../../../helpers/utils';

import IconContainer from '../icon-container';

const backgroundColors = ['#2496FF', '#8324FF', '#F59E0B', '#22C55E', '#D942FF', '#171717'];

const avatarVariants = {
  size_56: { width: '56px', height: '56px', fontSize: '20px' },
  large: { width: '40px', height: '40px', fontSize: '14px' },
  size_32: { width: '32px', height: '32px', fontSize: '12px' },
  semi_large: { width: '28px', height: '28px', fontSize: '10px' },
  medium: { width: '24px', height: '24px', fontSize: '8px' },
  small: { width: '20px', height: '20px', fontSize: '8px' },
};

export const Avatar = ({
  id,
  avatar,
  backgroundColor,
  backgroundColorIndex = 1,
  zIndex = 1,
  getAvatarName = getUserInitials,
  avatarLabelClassname = '',
  avatarWrapperClassname = '',
  variant = 'large',
}) => {
  const variantStyle = avatarVariants[variant] || avatarVariants.large;
  const colorIndex = backgroundColorIndex % backgroundColors.length;

  return (
    <AvatarWrapper
      {...variantStyle}
      className={classNames('flex items-center justify-center', avatarWrapperClassname)}
      zIndex={zIndex}
      key={id}
      backgroundColor={backgroundColor || backgroundColors[colorIndex]}>
      <label className={classNames('inter-600-text white-text', avatarLabelClassname)}>
        {avatar?.name ? (
          getAvatarName(avatar ?? {})
        ) : (
          <IconContainer
            Icon={UserIcon}
            iconColor="white"
            backgroundColor="transparent"
            iconContainerClassname="p-0"
            iconWidth={14}
            iconHeight={14}
          />
        )}
      </label>
    </AvatarWrapper>
  );
};

const AvatarGroup = ({
  avatars = [],
  groupWrapperClassname = '',
  getAvatarName = getUserInitials,
  avatarVariant = 'large',
}) => {
  const visibleAvatars = avatars.slice(0, 3);
  const hiddenAvatars = avatars.length - visibleAvatars.length;

  return (
    <AvatarGroupWrapper className={classNames('flex items-center justify-center', groupWrapperClassname)}>
      {visibleAvatars.map((avatar, index) => (
        <Avatar
          key={avatar.id || index}
          id={avatar.id}
          avatar={avatar}
          zIndex={index + 1}
          backgroundColor={backgroundColors[index]}
          avatarWrapperClassname="cursor avatar"
          getAvatarName={getAvatarName}
          variant={avatarVariant}
        />
      ))}
      {hiddenAvatars > 0 && (
        <Avatar
          id={'hidden-avatars'}
          zIndex={visibleAvatars.length + 1}
          backgroundColor={'#E5E5E5'}
          getAvatarName={() => `+${hiddenAvatars}`}
          avatarLabelClassname="inter-400-text natural-700-text"
          avatarWrapperClassname="cursor avatar"
          variant={avatarVariant}
        />
      )}
    </AvatarGroupWrapper>
  );
};

const AvatarGroupWrapper = styled.div`
  .avatar {
    margin-right: -8px;
  }
`;

const AvatarWrapper = styled.div`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  background: ${({ backgroundColor }) => backgroundColor};
  z-index: ${({ zIndex }) => zIndex};
  border: 2px solid #fff;
  border-radius: 50%;

  label {
    font-size: ${({ fontSize }) => fontSize};
  }
`;

export default AvatarGroup;
