import React from 'react';
import { AutomationLogDetailWrapper } from '../../styles/components/automation-log-detail/automation-log-details.styled';
import AutomationLogDetailsLeft from './automation-log-details-left/index.js';
import AutomationLogDetailsRight from './automation-log-details-right';

const AutomationLogDetails = () => {
  return (
    <AutomationLogDetailWrapper className="flex col-gap-6 flex-1 py-2 px-6 overflow-hidden">
      <AutomationLogDetailsLeft />
      <AutomationLogDetailsRight />
    </AutomationLogDetailWrapper>
  );
};

export default AutomationLogDetails;
