import styled from 'styled-components';

const QuoteDetailsPaymentWrapper = styled.div`
  .payment-terms {
    max-width: 470px;
    width: 100%;

    .width-wrapper {
      max-width: calc(100% - 30px);
      width: 100%;
    }
    .searchable-select__control--is-focused {
      box-shadow: 0px 0px 0px 4px ${({ theme }) => theme.focus_border};
    }
  }
`;

export default QuoteDetailsPaymentWrapper;
