import styled from 'styled-components';

export const AutomationLogDetailContainerWrapper = styled.div`
  max-height: 100vh;
  overflow: hidden;

  .automation-log-header {
    border-bottom: none;
  }
`;
