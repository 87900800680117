import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';

const IconContainer = ({
  Icon,
  iconHeight = 16,
  iconWidth = 16,
  backgroundColor = 'natural_150',
  iconColor = 'natural_500',
  iconClassName = '',
  iconContainerClassname = '',
  disabled = false,
  onClick = () => {},
}) => {
  return (
    <IconContainerWrapper
      backgroundColor={backgroundColor}
      iconColor={iconColor}
      disabled={disabled}
      onClick={onClick}
      className={classNames(
        'flex items-center justify-center pxy-1 radius-1',
        disabled && 'disabled',
        iconContainerClassname,
      )}>
      <Icon height={iconHeight} width={iconWidth} className={classNames('icon', iconClassName)} />
    </IconContainerWrapper>
  );
};

const IconContainerWrapper = styled.div`
  background: ${({ theme, backgroundColor }) => theme[backgroundColor]};

  .icon {
    color: ${({ theme, iconColor }) => theme[iconColor]};
  }

  &.disabled {
    cursor: not-allowed !important;
    pointer-events: none;
    opacity: 0.5;
  }
`;

export default IconContainer;
