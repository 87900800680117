/* eslint-disable prettier/prettier */
import classNames from 'classnames';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { ReactComponent as AddIcon } from '../../assets/icons/add-icon.svg';
import { ReactComponent as EditPensil } from '../../assets/icons/edit-pensil.svg';
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';
import { ReactComponent as NoSearchResult } from '../../assets/icons/no-search-result.svg';
// import { ReactComponent as PartnersIcon } from '../../assets/icons/partners-Icons.svg';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CalendarIcon } from '../../assets/icons/property/calendar.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/DeleteIcons.svg';
import { ReactComponent as HorizontalDots } from '../../assets/images/HorizontalDots.svg';
import { ReactComponent as RightIcon } from '../../assets/images/arrow-right.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/toast-close.svg';
import { Avatar } from '../../components/common/avatar-group';
import Button from '../../components/common/button/button';
import DateSelectorElement from '../../components/common/date-selector/date-selector-element';
import IconContainer from '../../components/common/icon-container';
import MenuV2 from '../../components/common/menu/menu-v2';
import NoData from '../../components/common/no-data';
import Popover from '../../components/common/popover';
import FilterPopover from '../../components/common/popover/filter-popover';
import TimeSelectorElement from '../../components/common/time-selector/time-selector-element';
import UpdateAction from '../../components/common/update-action';
import { OrganisationContext } from '../../context/organisationContext';
import { initModal } from '../../helpers/utils';
import {
  createActivities,
  createActivityResorces,
  deleteActivityResorces,
  getAvailability,
  getJobTypeServices,
  updateActivity,
  updateActivityResorces,
} from '../../store/features/activitiesSlice';
import { addToast } from '../../store/features/toastSlice';
import ActivityScheduler from './activity-scheduler';

const ActivityItem = ({
  activity,
  selectedActivity,
  setSelectedActivity,
  job_name,
  onUpdateActivityTime,
  onDeleteResource,
  selectedDate,
  services,
  activityIndex,
  setActivities,
  activities,
  appointments,
  setAppointments,
}) => {
  const { t } = useTranslation();
  const { id, resources = [], name } = activity;
  const isSelected = id === selectedActivity.id;
  const { setModal } = useContext(OrganisationContext);
  const [showResources, setShowResources] = useState(true);
  const [activityIDUpdate, setActivityIdUpdate] = useState('');
  const [valueUpdate, setValueUpdate] = useState(name);
  const [isEditing, setIsEditing] = useState(false);

  const getUserServicesName = resource => {
    const filteredServices = resource?.services?.filter(service => services?.some(s => s.id === service.id));
    return filteredServices?.map(service => service.name).join(', ');
  };

  const onDeleteActivity = id => {
    let uniqueAppointmentId = new Set(
      activity?.resources.map(resource => resource.appointment_id).filter(value => value != undefined),
    );

    const newAppointments = appointments.map(appointment => {
      return appointment?.resources.map(res => res.appointment_id).some(element => uniqueAppointmentId.has(element))
        ? {
            ...appointment,
            resources: appointment?.resources.filter(r => !uniqueAppointmentId.has(r.appointment_id)),
          }
        : appointment;
    });

    setAppointments(newAppointments);
    const updateActivityDetailsArray = [...activities];
    updateActivityDetailsArray.splice(activityIndex, 1);
    setActivities(updateActivityDetailsArray);
    setActivityIdUpdate(activityIDUpdate !== id);

    // setModal({
    //   type: 'activity-action',
    //   content: {
    //     activityDetails: 'activity',
    //     isEnable: true,
    //     activity_Id: id,
    //     title: 'Remove this activity?',
    //     description:
    //       'Are you sure you want to remove this activity? All services and resources within this activity will be removed too. This action cannot be undone.',
    //   },
    // });
  };

  const UpdateActivityData = () => {
    setActivityIdUpdate(id);
    setIsEditing(true);
    setActivityIdUpdate(activityIDUpdate !== id);
  };

  const onUpdateTime = (resource, type, date, time) => {
    const splitedTime = time?.split(':') || [];
    const [hour, minute] = splitedTime;
    if (hour < 8 || hour > 18) {
      return;
    }
    const currentDate = date || selectedDate;
    const updatedTime = moment.unix(currentDate).set({ hour: hour, minute: minute }).unix();
    onUpdateActivityTime(activity, { ...resource, [type]: updatedTime });
  };

  const renderTimeElements = resource => {
    const { start_date, end_date } = resource;
    const startTime = start_date ? moment.unix(start_date).format('HH:mm') : '00:00';
    const endTime = end_date ? moment.unix(end_date).format('HH:mm') : '00:00';

    return (
      <div className="flex col-gap-4 items-center">
        <TimeSelectorElement
          className="w-full"
          selectedTime={startTime}
          setSelectedTime={time => onUpdateTime(resource, 'start_date', start_date, time)}
          isRequired={true}
        />
        <span>-</span>
        <TimeSelectorElement
          className="w-full"
          selectedTime={endTime}
          setSelectedTime={time => onUpdateTime(resource, 'end_date', end_date, time)}
          isRequired={true}
        />
      </div>
    );
  };

  const updateActivityName = (id, newName) => {
    const updatedActivities = activities.map(activity =>
      activity.id === id ? { ...activity, name: newName } : activity,
    );
    const updatedAppointments = appointments.map(appointment => {
      if (appointment?.resources.some(resource => resource.activity.id === id)) {
        return {
          ...appointment,

          resources: appointment?.resources.map(resource => {
            if (resource.activity.id === id) {
              return { ...resource, job_name: newName, activity: { ...resource.activity, name: newName } };
            } else {
              return resource;
            }
          }),
        };
      }
      return appointment;
    });

    setActivities(updatedActivities);
    setAppointments(updatedAppointments);
    setSelectedActivity(activity => (activity.id === id ? { ...activity, name: newName } : activity));
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setIsEditing(false);
      setActivityIdUpdate(activityIDUpdate !== id);
      if (activityIDUpdate === selectedActivity.id) {
        updateActivityName(selectedActivity.id, valueUpdate);
        setActivityIdUpdate('');
      }
    }
  };

  return (
    <div className="px-6 py-5 border-top row-gap-5 flex-column">
      <div className="flex items-center">
        <IconContainer
          onClick={() => setSelectedActivity(activity)}
          iconContainerClassname={classNames('cursor', isSelected && 'rotate-90')}
          Icon={RightIcon}
          backgroundColor="transparent"
          iconColor={isSelected ? 'natural_900' : 'natural_400'}
        />

        <div
          onClick={() => setSelectedActivity(activity)}
          className={classNames(
            activityIDUpdate === id ? 'w-full' : '',
            'inter-600-text font-16 flex flex-1 cursor',
            isSelected ? 'natural-900-text' : 'natural-500-text',
          )}>
          {activityIDUpdate && activityIDUpdate === id && isEditing ? (
            <>
              <div className="flex w-full items-start gap-6 content-text h-24px">
                <input
                  type="text"
                  autoFocus
                  value={valueUpdate}
                  onChange={e => setValueUpdate(e.target.value)}
                  onKeyDown={handleKeyDown}
                  className="w-full h-full font-14 radius-1_5 px-2 py-3 edit-input"
                />
              </div>
            </>
          ) : (
            <>
              <p>{valueUpdate}</p>
            </>
          )}
        </div>

        {activityIDUpdate !== id && (
          <div className="flex items-center gap-3">
            <div className="flex items-center">
              <CalendarIcon />
              <label className={'inter-400-text natural-900-text ml-1'}>
                {moment.unix(selectedDate).format('DD/MM/YY')}
              </label>
            </div>
            <MenuV2
              Icon={HorizontalDots}
              menuList={[
                activities.length > 1 && {
                  name: t('REMOVE_ACTIVITY'),
                  withIcon: true,
                  icon: CloseIcon,
                  onClick: () => {
                    onDeleteActivity(id);
                  },
                },
                {
                  name: t('RENAME_ACTIVITY'),
                  withIcon: true,
                  icon: EditPensil,
                  onClick: () => {
                    UpdateActivityData(id);
                    setActivityIdUpdate(id);
                  },
                },
                // {
                //   name: 'Add Note',
                //   withIcon: true,
                //   icon: NotesIcon,
                // },
              ].filter(Boolean)}
            />
          </div>
        )}
      </div>
      {isSelected && (
        <div className="pxy-4 flex-column border radius-1_5 row-gap-4">
          <div className="flex items-center justify-between">
            <label className="inter-500-text natural-900-text font-16">
              Resources <span className="natural-500-text">({resources?.length})</span>
            </label>
            <IconContainer
              iconContainerClassname={classNames('cursor', showResources && 'rotate-90')}
              Icon={RightIcon}
              backgroundColor="transparent"
              iconColor={showResources ? 'natural_900' : 'natural_400'}
              onClick={() => setShowResources(!showResources)}
            />
          </div>
          {showResources && (
            <div className="flex-column row-gap-4">
              {resources?.length > 0 &&
                resources.map((resource, index) => (
                  <div className="flex-column row-gap-4 border radius-1_5 pxy-3" key={resource.id}>
                    <div className="flex items-center flex-1">
                      <Avatar backgroundColorIndex={index} variant="semi_large" avatar={resource?.user} />
                      <div className="flex-column flex-1 ml-1">
                        <label className="inter-500-text natural-900-text font-16">{resource?.user?.name}</label>
                        <label className="inter-400-text natural-500-text">{getUserServicesName(resource)}</label>
                      </div>
                      <IconContainer
                        iconContainerClassname={'border radius-4 cursor'}
                        Icon={DeleteIcon}
                        iconHeight={12}
                        iconWidth={12}
                        backgroundColor="transparent"
                        iconColor={isSelected ? 'natural_900' : 'natural_400'}
                        onClick={() => onDeleteResource(activity, resource)}
                      />
                    </div>
                    {renderTimeElements(resource)}
                  </div>
                ))}
              {resources?.length === 0 && (
                <NoData
                  title={t('NO_SERVICE_SELECTED')}
                  description={t('SHOW_AVAILABLE_RESOURCES_IN_CALENDAR')}
                  EmptyIcon={NoSearchResult}
                  className="no-resources p-6 bg-natural-100 radius-1_5 w-full"
                  iconHeight={60}
                  iconWidth={120}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const AddActivity = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const todayRef = useRef(moment().startOf('day').unix());

  const { jobDetails } = useSelector(state => state.property);
  const { job_type } = jobDetails || {};

  const { modal, setModal } = useContext(OrganisationContext);
  const { onSuccess, engagement, isReschedule, activity } = modal?.content || {};
  const { resources = [], expected_date } = activity || {};
  const activityDate = useMemo(() => moment.unix(expected_date?.start_date).startOf('day').unix(), [expected_date]);

  const activityServices = useMemo(() => {
    return resources
      .reduce((acc, curr) => {
        const { service } = curr;
        const isServiceAvailable = acc.find(s => s.id === service?.id);
        if (!isServiceAvailable) {
          return [...acc, service];
        }
        return acc;
      }, [])
      .map(service => ({ ...service, label: service.name, value: service.id }));
  }, [resources]);

  const activityResources = useMemo(() => {
    return resources.map(({ expected_date, id, ...rest }) => {
      const start_date = expected_date?.start_date;
      const end_date = moment.unix(start_date).add(expected_date?.duration, 'minutes').unix();
      return {
        ...rest,
        old_id: id,
        start_date,
        end_date,
        duration: expected_date?.duration,
        isNew: true,
        isRescheduleResource: true,
        appointment_id: nanoid(),
      };
    });
  }, [activity]);

  const job_name = engagement?.name || 'Job name';

  const [activities, setActivities] = useState(
    isReschedule ? [{ ...activity, resources: activityResources }] : [{ id: nanoid(), resources: [], name: job_name }],
  );
  const [selectedActivity, setSelectedActivity] = useState(activities.at(0));
  const [services, setServices] = useState([]);
  const [availability, setAvailability] = useState([]);
  const [resourceType, setResourceType] = useState('USER');
  const [selectedService, setSelectedService] = useState(isReschedule ? activityServices : null);
  const [selectedDate, setSelectedDate] = useState(isReschedule ? activityDate : todayRef.current);
  const [showFilterPopover, setShowFilterPopover] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [loadingAvailabilities, setLoadingAvailabilities] = useState(false);
  const [creatingActivities, setCreatingActivities] = useState(false);

  const fetchOrganizationServices = job_type_id => {
    dispatch(getJobTypeServices({ job_type_id: job_type_id }))
      .then(data => {
        const services = data.map(({ service, is_mandatory, estimated_duration }) => ({
          estimated_duration,
          is_mandatory,
          ...service,
          label: service.name,
          value: service.id,
        }));
        setServices(services);
        if (!isReschedule) {
          setSelectedService(services);
        }
        if (services.length === 0) {
          setLoadingAvailabilities(false);
        }
      })
      .catch(() => {});
  };

  const fetchAvailability = services => {
    const servicesIds = services.map(service => service.id).join(',');
    const activity_start_date = moment.unix(selectedDate).startOf('day').unix();
    const activity_end_date = moment.unix(selectedDate).endOf('day').unix();
    setLoadingAvailabilities(true);
    dispatch(
      getAvailability({
        params: {
          ...(servicesIds && { service_id: servicesIds }),
          resource_type: resourceType,
          'activity_date.start': activity_start_date,
          'activity_date.end': activity_end_date,
        },
      }),
    )
      .then(data => {
        setAvailability(data);
      })
      .catch(() => {
        setAvailability([]);
        dispatch(addToast({ error: true, text: t('SOMETHING_WRONG_WHILE_FETCHING_RESOUTCES'), id: nanoid() }));
      })
      .finally(() => setLoadingAvailabilities(false));
  };

  const getAddedAvailabilityResources = () => {
    const activityResources = activities.map(activity => activity?.resources).flat();
    const resourcesByUser = activityResources.reduce((acc, curr) => {
      const { user, appointment_id, duration, end_date, isNew, job_name, service, start_date, old_id } = curr;
      if (!acc[user.id]) {
        acc[user.id] = { user, resources: [] };
      }
      acc[user.id]?.resources.push({
        appointment_id,
        duration,
        end_date,
        isNew,
        job_name,
        service,
        start_date,
        old_id,
        activity: { id: selectedActivity?.id, name: selectedActivity?.name },
      });
      return acc;
    }, {});

    const availabilities = availability?.map(availability => {
      const { user } = availability;
      const resources = resourcesByUser[user.id]?.resources || [];
      const resourcesIds = resources.map(resource => resource.old_id);
      const mergeResources = availability?.resources
        .map(resource => {
          if (resourcesIds.includes(resource.id)) {
            return null;
          }
          return resource;
        })
        .filter(Boolean);
      return { ...availability, resources: [...mergeResources, ...resources] };
    });
    return availabilities;
  };

  useEffect(() => {
    if (job_type?.id) {
      fetchOrganizationServices(job_type?.id);
    }
  }, [job_type]);

  useEffect(() => {
    if (selectedService) {
      const selectedServices = selectedService ? selectedService : services;
      fetchAvailability(selectedServices);
    }
  }, [resourceType, selectedDate, selectedService]);

  useEffect(() => {
    const availabilities = getAddedAvailabilityResources();

    if (resourceType === 'USER' || resourceType === 'ORGANIZATION') {
      setAppointments(availabilities);
    }
    if (resourceType === 'TEAM') {
      const groupByTeam = availabilities?.reduce((acc, curr) => {
        const { team } = curr;
        if (!acc[team?.id]) {
          acc[team?.id] = { team, resources: [] };
        }
        acc[team?.id]?.resources.push({ ...curr, isPartOfTeamOrOrganization: true });
        return acc;
      }, {});
      setAppointments(
        Object.entries(groupByTeam)
          .map(([_, value]) => [
            { ...value.team, user: { ...value.team }, isTeamOrOrganization: true },
            ...value.resources,
          ])
          .flat(),
      );
    }
  }, [availability]);

  const updateAppointments = (userAppointment, newAppointment) => {
    return appointments.map(appointment =>
      appointment?.user?.id === userAppointment?.user?.id
        ? {
            ...appointment,
            resources:
              appointment?.user?.id === userAppointment?.user?.id
                ? appointment?.resources?.filter(item => item.isNew).length > 0 &&
                  appointment?.resources?.find(res => res.isNew && res.activity.id === selectedActivity.id)
                  ? appointment?.resources.map(item =>
                      item.isNew && item?.activity?.id === selectedActivity.id
                        ? {
                            ...item,
                            duration: newAppointment?.duration,
                            end_date: newAppointment?.end_date,
                            start_date: newAppointment?.start_date,
                          }
                        : { ...item },
                    )
                  : [...appointment?.resources, newAppointment]
                : [...appointment?.resources, newAppointment],
          }
        : appointment,
    );
  };

  const updateActivities = (userAppointment, newAppointment) => {
    return activities.map(activity =>
      activity.id === selectedActivity.id
        ? {
            ...activity,
            resources: activity?.resources.filter(res => res?.user?.id === userAppointment?.user?.id).length
              ? activity?.resources?.map(item =>
                  item?.user?.id === userAppointment?.user?.id
                    ? {
                        ...item,
                        start_date: newAppointment?.start_date,
                        end_date: newAppointment?.end_date,
                        duration: newAppointment?.duration,
                      }
                    : item,
                )
              : [...activity?.resources, { ...userAppointment, ...newAppointment }],
          }
        : activity,
    );
  };

  const onAddResource = (userAppointment, appointment) => {
    let isValidToAdd = true;
    const alreadyBookedResources = userAppointment?.resources;
    const newActivityStartTime = appointment.start_date;
    const newActivityEndTime = appointment.end_date;

    if (alreadyBookedResources.length > 0) {
      alreadyBookedResources?.forEach(singleBookedResource => {
        const startTime = singleBookedResource?.expected_date?.start_date || singleBookedResource?.start_date;
        const endTime =
          singleBookedResource?.end_date ||
          singleBookedResource.expected_date?.start_date + singleBookedResource?.expected_date.duration * 60;
        if (newActivityStartTime < startTime && newActivityEndTime > endTime) {
          isValidToAdd = false;
          dispatch(
            addToast({ error: true, text: 'Timings are overlapping, Please select other time for this activity' }),
          );
        }
        if (newActivityEndTime > startTime && newActivityEndTime < endTime) {
          appointment.end_date = startTime;
        }
        if (newActivityStartTime > startTime && newActivityStartTime < endTime) {
          appointment.start_date = endTime;
        }
      });
    }

    const duration = (appointment.end_date - appointment.start_date) / 60;
    appointment.duration = duration;
    const element = alreadyBookedResources.find(singleBookedResource => {
      if (
        (singleBookedResource?.expected_date?.start_date || singleBookedResource?.start_date) ===
          appointment.start_date &&
        (singleBookedResource?.expected_date?.start_date + duration * 60 || singleBookedResource?.end_date) ===
          appointment.end_date
      ) {
        return true;
      }
      return false;
    });

    if (!isValidToAdd || appointment.start_date === appointment.end_date || element) {
      return;
    }

    if (creatingActivities) {
      return;
    }
    if (!selectedActivity) {
      dispatch(addToast({ error: true, text: t('PLEASE_SELECT_FIRST_ACTIVITY'), id: nanoid() }));
      return;
    }
    if (!selectedService?.length) {
      dispatch(addToast({ error: true, text: t('PLEASE_SELECT_FIRST_SERVICE'), id: nanoid() }));
      return;
    }

    const newAppointment = {
      ...appointment,
      job_name: selectedActivity?.name || job_name,
      service: selectedService[0],
      appointment_id: nanoid(),
      activity: { id: selectedActivity.id, name: selectedActivity?.name },
    };

    const newAppointments = updateAppointments(userAppointment, newAppointment);
    const newActivities = updateActivities(userAppointment, newAppointment);

    setAppointments(newAppointments);
    setActivities(newActivities);
  };

  const onUpdateActivityTime = (activity, resource) => {
    let isValidToAdd = true;
    const { start_date, end_date } = resource;
    const difference = moment.unix(end_date).diff(moment.unix(start_date), 'minutes');
    if (creatingActivities) {
      return;
    }
    if (difference < 15) {
      dispatch(addToast({ error: true, text: 'Please add atleast 15 minutes for resource time', id: nanoid() }));
      return;
    }

    const userAppointments = appointments.find(ap => ap.user.id == resource.user.id);
    const filteredAppointments = userAppointments?.resources.filter(
      ua => ua.appointment_id !== resource.appointment_id,
    );
    filteredAppointments?.forEach(singleBookedResource => {
      const oldStartDate = singleBookedResource?.expected_date?.start_date || singleBookedResource?.start_date;
      const oldEndDate =
        singleBookedResource?.end_date ||
        singleBookedResource.expected_date?.start_date + singleBookedResource?.expected_date?.duration * 60;

      const startInBetween = end_date > oldStartDate && end_date < oldEndDate;
      const startDateBefore = start_date > oldStartDate && start_date < oldEndDate;
      const endInBetween = start_date < oldEndDate > end_date;
      const wholeActivityUnder = oldStartDate < end_date && oldEndDate < end_date && oldStartDate > start_date;

      const sameDate = start_date === oldStartDate || end_date === oldEndDate;
      if (startInBetween || startDateBefore || endInBetween || sameDate || wholeActivityUnder) {
        isValidToAdd = false;
        dispatch(
          addToast({ error: true, text: 'Timings are overlapping, Please select other time for this activity' }),
        );
        return;
      }
    });
    if (!isValidToAdd) return;
    const newActivities = activities.map(ac =>
      ac.id === activity.id
        ? {
            ...ac,
            resources: ac?.resources.map(r =>
              r.appointment_id === resource.appointment_id ? { ...resource, duration: difference } : r,
            ),
          }
        : ac,
    );
    const newAppointments = appointments.map(appointment =>
      appointment.id === resource.id
        ? {
            ...appointment,
            resources: appointment?.resources.map(r =>
              r.appointment_id === resource.appointment_id ? { ...r, start_date, end_date, duration: difference } : r,
            ),
          }
        : appointment,
    );
    setActivities(newActivities);
    setAppointments(newAppointments);
  };

  const onDeleteResource = (activity, resource) => {
    if (creatingActivities) {
      return;
    }
    const newActivities = activities.map(ac =>
      ac.id === activity.id
        ? {
            ...ac,
            resources: ac?.resources.filter(r => r.appointment_id !== resource.appointment_id),
          }
        : ac,
    );
    const newAppointments = appointments.map(appointment =>
      appointment.id === resource.id
        ? {
            ...appointment,
            resources: appointment?.resources.filter(r => r.appointment_id !== resource.appointment_id),
          }
        : appointment,
    );
    setActivities(newActivities);
    setAppointments(newAppointments);
  };

  const onRemoveResources = userAppointment => {
    if (creatingActivities) {
      return;
    }
    const newAppointments = appointments.map(appointment =>
      appointment?.user?.id === userAppointment?.user?.id
        ? { ...appointment, resources: appointment?.resources?.filter(r => !r.isNew) }
        : appointment,
    );
    const newActivities = activities.map(ac =>
      ac?.resources.some(r => r.user.id === userAppointment.user.id)
        ? { ...ac, resources: ac?.resources.filter(r => r.user.id !== userAppointment.user.id) }
        : ac,
    );
    setAppointments(newAppointments);
    setActivities(newActivities);
  };

  const getSelectedResourcesInActivity = selectedActivity => {
    const activity = activities.find(a => a.id === selectedActivity.id);
    if (!activity) {
      return [];
    }
    return activity?.resources.map(r => r.appointment_id);
  };

  const onSaveResources = async (activity, resourcesToAdd, resourcesToUpdate, resourcesToRemove) => {
    if (!resourcesToAdd?.length && !resourcesToUpdate?.length && !resourcesToRemove?.length) {
      return;
    }
    try {
      if (resourcesToAdd?.length) {
        await Promise.all(
          resourcesToAdd.map(async resource => {
            const { organization, user, services, team, start_date, end_date } = resource;
            const request = {
              organization: organization ? { id: organization?.id } : null,
              team: team ? { id: team?.id } : null,
              user: { id: user?.id },
              slot: null,
              service: selectedService
                ? selectedService
                    ?.filter(service => services?.some(s => s.id === service.id))
                    ?.map(s => ({ id: s.id }))?.[0]
                : null,
              expected_date: {
                start_date: start_date,
                duration: moment.unix(end_date).diff(moment.unix(start_date), 'minutes'),
              },
            };
            await dispatch(createActivityResorces({ request: request, activity_id: activity?.id }));
          }),
        );
      }
      if (resourcesToUpdate?.length) {
        await Promise.all(
          resourcesToUpdate.map(async resource => {
            const { organization, user, service, team, slot, start_date, end_date, status } = resource;
            const request = {
              status: status,
              organization: { id: organization?.id },
              team: team ? { id: team?.id } : null,
              user: { id: user?.id },
              slot: null,
              service: service ? { id: service?.id } : null,
              expected_date: {
                start_date: start_date,
                duration: moment.unix(end_date).diff(moment.unix(start_date), 'minutes'),
              },
            };
            await dispatch(
              updateActivityResorces({ request: request, activity_id: activity?.id, resource_id: resource?.old_id }),
            );
          }),
        );
      }
      if (resourcesToRemove?.length) {
        await Promise.all(
          resourcesToRemove.map(async resource => {
            const { old_id } = resource;
            await dispatch(deleteActivityResorces({ resource_id: old_id, activity_id: activity?.id }));
          }),
        );
      }
    } catch (error) {
      dispatch(addToast({ error: true, text: t('FAILED_UPDATE_RESOURCES'), id: nanoid() }));
      throw error;
    }
  };

  const getDateForResource = dateInUnix => {
    const selectedDateMomement = moment.unix(selectedDate).clone();
    const dateMoment = moment.unix(dateInUnix).clone();
    const newMoment = selectedDateMomement
      .set('hour', dateMoment.get('hours'))
      .set('minutes', dateMoment.get('minutes'))
      .set('seconds', dateMoment.get('seconds'));
    return newMoment.unix();
  };

  const onReschedule = async () => {
    if (!selectedService) {
      dispatch(addToast({ error: true, text: t('PLEASE_SELECT_SERVICE'), id: nanoid() }));
      return;
    }
    const resources = activities.map(activity => activity?.resources).flat();
    const resourcesToRemove = activityResources.filter(
      ar => ar.isRescheduleResource && !resources.some(r => r.old_id === ar.old_id),
    );
    const resourcesToAdd = resources
      .filter(r => r.isNew && !activityResources.some(ar => ar.old_id === r.old_id))
      .map(resource => {
        const { start_date, end_date, duration } = resource;
        if (start_date && end_date) {
          return {
            ...resource,
            expected_date: { start_date: getDateForResource(start_date), duration: duration },
          };
        }
        return null;
      })
      .filter(Boolean);

    const resourcesToUpdate = resources.reduce((acc, curr) => {
      const oldResource = activityResources.find(ar => ar.old_id === curr.old_id);
      if (oldResource) {
        const { start_date, end_date, duration } = oldResource;
        if (start_date !== curr.start_date || end_date !== curr.end_date || duration !== curr.duration) {
          return [
            ...acc,
            { ...curr, expected_date: { start_date: getDateForResource(curr.start_date), duration: curr.duration } },
          ];
        }
      }
      return acc;
    }, []);

    const resourcesNotUpdated = resources
      .filter(r => r.old_id && !resourcesToUpdate.some(ur => ur.old_id === r.old_id))
      .map(r => ({ ...r, expected_date: { start_date: getDateForResource(r.start_date), duration: r.duration } }));

    const newResources = [...resourcesToAdd, ...resourcesToUpdate, ...resourcesNotUpdated];
    const lowestStartDate = Math.min(...newResources.map(resource => resource.expected_date.start_date));
    const highestEndDate = Math.max(...newResources.map(resource => resource.end_date));
    const durationDiffrence = moment.unix(highestEndDate).diff(moment.unix(lowestStartDate), 'minutes');

    const updateActivityRequest = {
      ...activity,
      name: activities[0]?.name || job_name,
      expected_date: {
        start_date: lowestStartDate,
        duration: durationDiffrence,
      },
    };
    try {
      setCreatingActivities(true);
      await onSaveResources(activity, resourcesToAdd, resourcesToUpdate, resourcesToRemove);
      await dispatch(updateActivity({ activity_id: activity?.id, request: updateActivityRequest }));
      dispatch(addToast({ text: t('ACTIVITY_UPDATED_SUCCESSFULLY'), id: nanoid() }));
      setModal(initModal);
      onSuccess && onSuccess();
    } catch (error) {
      dispatch(addToast({ error: true, text: t('FAILED_UPDATE_ACTIVITY'), id: nanoid() }));
      setCreatingActivities(false);
    }
  };

  const onCreateActivity = () => {
    if (!selectedService) {
      dispatch(addToast({ error: true, text: t('PLEASE_SELECT_SERVICE'), id: nanoid() }));
      return;
    }
    const activityRequest = activities
      .map(activity => {
        const { resources, name } = activity;
        const newResources = resources
          .map(resource => {
            const { organization, team, user, start_date, end_date, duration, services } = resource;
            if (start_date && end_date) {
              return {
                organization: organization ? { id: organization.id } : null,
                team: team ? { id: team.id } : null,
                user: user ? { id: user.id } : null,
                service: selectedService
                  ? selectedService
                      ?.filter(service => services?.some(s => s.id === service.id))
                      ?.map(s => ({ id: s.id }))?.[0] ||
                    services?.[0] ||
                    selectedService?.[0]
                  : null,
                expected_date: { start_date: getDateForResource(start_date), duration: duration },
                end_time: getDateForResource(end_date),
              };
            }
            return null;
          })
          .filter(Boolean);
        if (newResources.length === 0) {
          return null;
        }
        const lowestStartDate = Math.min(...newResources.map(resource => resource.expected_date.start_date));
        const highestEndDate = Math.max(...newResources.map(resource => resource.end_time));
        const durationDiffrence = moment.unix(highestEndDate).diff(moment.unix(lowestStartDate), 'minutes');

        return {
          name: name || job_name,
          description: '',
          engagement: {
            id: engagement?.id,
          },
          event_location: 'PROPERTY',
          creator_note: null,
          resources: newResources,
          parent: null,
          expected_date: {
            start_date: lowestStartDate,
            duration: durationDiffrence,
          },
        };
      })
      .flat()
      .filter(Boolean);
    if (activityRequest.length === 0) {
      dispatch(addToast({ error: true, text: 'Please add atleast one resource and time', id: nanoid() }));
      return;
    }
    setCreatingActivities(true);
    dispatch(createActivities({ request: activityRequest }))
      .then(data => {
        dispatch(addToast({ text: t('ACTIVITY_CREATED_SUCCESSFULLY'), id: nanoid() }));
        setModal(initModal);
        onSuccess && onSuccess();
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: 'Something went wrong while creating activities', id: nanoid() }));
      })
      .finally(() => setCreatingActivities(false));
  };

  const onSchedule = () => {
    if (isReschedule) {
      if (!activities[0]?.resources.length) {
        dispatch(
          addToast({ error: true, text: 'Please add atleast one resource while updating activity', id: nanoid() }),
        );
        return;
      }
      onReschedule();
      return;
    }
    onCreateActivity();
  };

  const createActivity = () => {
    const newActivity = { id: nanoid(), resources: [], name: job_name };
    setActivities([...activities, newActivity]);
    setSelectedActivity(newActivity);
  };

  const renderTodayButton = () => {
    if (todayRef.current === selectedDate) {
      return null;
    }
    return (
      <Button
        fontSize="14px"
        className="primary-grey specified-width px-4"
        width="auto"
        size="customsize"
        label="Today"
        onClick={() => setSelectedDate(todayRef.current)}
      />
    );
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <div className="flex flex-1 overflow-scroll">
        <AddActivityWrapper className="flex">
          <div className="flex-column activity-left border-right">
            <div className="flex items-center justify-between pxy-6">
              <label className="inter-600-text natural-900-text font-20">Scheduling</label>
            </div>
            <div className="flex-column overflow-scroll">
              {activities.map((activity, index) => (
                <ActivityItem
                  key={index}
                  activity={activity}
                  selectedActivity={selectedActivity}
                  job_name={job_name}
                  setSelectedActivity={setSelectedActivity}
                  onUpdateActivityTime={onUpdateActivityTime}
                  onDeleteResource={onDeleteResource}
                  selectedDate={selectedDate}
                  services={services}
                  activityIndex={index}
                  activities={activities}
                  setActivities={setActivities}
                  appointments={appointments}
                  setAppointments={setAppointments}
                />
              ))}
            </div>
            {!isReschedule && (
              <div className="px-6 pb-5 border-bottom">
                <Button
                  label="Add activity"
                  className="secondary"
                  disabled={creatingActivities}
                  size="medium"
                  afterIcon={<AddIcon className="primary-text" height={16} width={16} />}
                  onClick={createActivity}
                />
              </div>
            )}
          </div>
          <div className="flex-column flex-1 pxy-8 overflow-scroll schedule-wrapper-scroll">
            <div className="flex items-center justify-between">
              <div className="flex gap-6">
                <DateSelectorElement
                  isDisabled={creatingActivities}
                  className="w-fit-content radius-full"
                  selectedDate={selectedDate}
                  setSelectedDate={date => setSelectedDate(date)}
                  placeholder={'Select'}
                  format="ddd DD MMM, YYYY"
                  arrows={true}
                  isCalendarIcon={false}
                  minStartDate={moment().subtract(1, 'days').startOf('day').unix()}
                />
                {renderTodayButton()}

                {!creatingActivities && (
                  <Popover
                    isOpen={showFilterPopover}
                    offset={[0, 2]}
                    content={
                      <FilterPopover
                        setShowFilterPopover={setShowFilterPopover}
                        isReschedule={isReschedule}
                        setResourceType={setResourceType}
                        services={services}
                        selectedService={selectedService}
                        setSelectedService={setSelectedService}
                      />
                    }>
                    <div className="btn-container" onClick={() => setShowFilterPopover(!showFilterPopover)}>
                      <div className="flex items-center justify-center h-full py-2 px-4 ml-3 cursor bg-primary-100 filter-button radius-full">
                        <label className="inter-500-text primary-text mr-1">{t('FILTER')}</label>
                        <FilterIcon className="primary-text" />
                      </div>
                    </div>
                  </Popover>
                )}
              </div>
              <UpdateAction
                primaryBtnProps={{ label: 'Schedule' }}
                className="w-fit-content"
                size="large"
                btnClassname="specified-width py-2_5 px-6"
                width="120px"
                height="44px"
                loading={creatingActivities}
                disabled={creatingActivities}
                onCancel={() => setModal(initModal)}
                onUpdate={onSchedule}
              />
            </div>
            <div
              className={classNames(
                'h-fit-content border radius-2 overflow-scroll mt-6',
                !(selectedService?.length > 0) && 'flex-1',
              )}>
              <ActivityScheduler
                isServiceSelected={selectedService?.length > 0}
                appointments={appointments}
                type={resourceType}
                resourceTypeLabel={resourceType}
                onAddResource={onAddResource}
                onRemoveResources={onRemoveResources}
                onDeleteResource={resource => onDeleteResource(selectedActivity || {}, resource)}
                selectedDate={selectedDate}
                loading={loadingAvailabilities}
                selectedResourcesInActivity={() => getSelectedResourcesInActivity(selectedActivity)}
                services={services}
                appointmentDuration={selectedService?.[0]?.estimated_duration || 60}
              />
            </div>
          </div>
        </AddActivityWrapper>
      </div>
    </CSSTransition>
  );
};

const AddActivityWrapper = styled.div`
  min-width: max(calc(100dvw - 64px), 1300px);
  max-width: calc(100dvw - 64px);
  min-height: max(calc(100dvh - 80px), 500px);
  max-height: max(calc(100dvh - 80px), 500px);

  .schedule-wrapper-scroll {
    * ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    * ::-webkit-scrollbar-thumb {
      background: #a3a3a3;
      border-radius: 10px;
    }
  }

  .menu-wrapper {
    display: flex;
    width: 222px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 6px;
    box-shadow: 0px 4px 6px -4px #1018281a;
    padding: 0px;
    .menu-item {
      display: flex;
      padding: 16px 20px;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border-radius: 0px;
      &:hover {
        border-radius: 6px;
      }
      svg {
        margin: 0;
      }
      span {
        color: #171717;
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .menu-btn {
      width: 24px;
      height: 24px;
    }
    .menu-item {
      border-bottom: 1px solid #e5e5e5;
    }
  }

  .activity-left {
    min-width: 427px;
    max-width: 427px;
  }

  .no-resources {
    p {
      width: 100%;
    }
  }
  .filter-button {
    height: 40px;
  }
`;

export default AddActivity;
