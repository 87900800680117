import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as AddIcon } from '../../assets/icons/add-icon.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/CrossIcons.svg';
import { ReactComponent as RightIcon } from '../../assets/icons/right-Icons.svg';
import { getErrorDescription } from '../../helpers/utils';
import { getProductSpecifications } from '../../store/features/productsSlice';
import { addToast } from '../../store/features/toastSlice';
import IconContainer from '../common/icon-container';
import InputElement from '../common/input';
import MenuV2 from '../common/menu/menu-v2';
import Dropdown from '../common/select-dropdown';
import TableHeader from '../common/table-header';

const specificationHeaders = [
  { name: 'CLASSIFICATION', key: 'CLASSIFICATION' },
  { name: 'SPECIFICATION', key: 'SPECIFICATION' },
  { name: 'UNIT', key: 'UNIT' },
  { name: '', key: 'MENU' },
];

const classificationOptions = [
  { value: 'WIDTH', label: 'Width' },
  { value: 'HEIGHT', label: 'Height' },
  { value: 'DEPTH', label: 'Depth' },
  { value: 'SPEED', label: 'Speed' },
  { value: 'POWER_OUT', label: 'Power Out' },
];

const unitOptions = [
  { value: 'KG', label: 'kg' },
  { value: 'CM', label: 'cm' },
  { value: 'WATT', label: 'watt' },
  { value: 'KWH', label: 'kWh' },
];

const ProductSpecificationItem = ({
  specification,
  showBorderTop,
  isEdit,
  onEdit,
  onCancel,
  onSave,
  specificationEdit,
  setSpecificationEdit,
}) => {
  const { classification, specification: spec, units, id } = isEdit ? specificationEdit : specification;

  const classificationOption = classificationOptions.find(option => option.value === classification) || null;
  const unitOption = unitOptions.find(option => option.value === units) || null;

  if (isEdit) {
    return (
      <div className={classNames('data-container items-center relative', showBorderTop && 'border-top')}>
        <Dropdown
          options={classificationOptions}
          value={classificationOption}
          onChange={option => setSpecificationEdit({ ...specificationEdit, classification: option.value })}
          placeholder="Select"
          menuPortalTarget={document.body}
          customStyle={{
            control: { height: '32px', minHeight: '32px', borderRadius: '6px' },
            dropdownIndicator: { margin: '0' },
          }}
        />
        <InputElement
          value={spec}
          type="number"
          showNumberArrows={false}
          placeholder={'Enter'}
          onChange={value => setSpecificationEdit({ ...specificationEdit, specification: value })}
          style={{ height: '32px', minHeight: '32px', borderRadius: '6px', padding: '2px 16px' }}
        />
        <Dropdown
          options={unitOptions}
          value={unitOption}
          onChange={option => setSpecificationEdit({ ...specificationEdit, units: option.value })}
          placeholder="Select"
          menuPortalTarget={document.body}
          customStyle={{
            control: { height: '32px', minHeight: '32px', borderRadius: '6px' },
            dropdownIndicator: { margin: '0' },
          }}
        />
        <div className="flex justify-center" />

        <div className="flex justify-center items-center pxy-2 radius-3 absolute bg-white col-gap-2 action-container">
          <IconContainer
            Icon={CrossIcon}
            backgroundColor="white"
            iconContainerClassname="radius-full border cursor"
            iconColor="natural_400"
            onClick={() => onCancel()}
          />
          <IconContainer
            Icon={RightIcon}
            backgroundColor="primary_500"
            iconContainerClassname="radius-full cursor"
            iconColor="white"
            onClick={() => onSave()}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={classNames('data-container items-center', showBorderTop && 'border-top')}>
      <label className="inter-500-text">{classificationOption?.label || ''}</label>
      <label className="inter-500-text">{spec}</label>
      <label className="inter-500-text">{unitOption?.label || ''}</label>
      <div className="flex justify-center">
        <MenuV2 iconClassname="rotate-90" menuList={[{ name: 'Edit', onClick: onEdit }]} />
      </div>
    </div>
  );
};

const ProductSpecification = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [productSpecifications, setProductSpecifications] = useState([]);
  const [specificationEdit, setSpecificationEdit] = useState({});

  const fetchProductSpecifications = () => {
    dispatch(getProductSpecifications({ product_id: id }))
      .then(data => {
        setProductSpecifications(data);
      })
      .catch(error => {
        setProductSpecifications([]);
        const errorText = getErrorDescription(error, 'Failed to fetch product specifications');
        dispatch(addToast({ error: true, text: errorText, id: nanoid() }));
      });
  };

  useEffect(() => {
    fetchProductSpecifications();
  }, [id]);

  const handleSave = () => {
    console.log('save', specificationEdit);
    setSpecificationEdit({});
  };

  return (
    <ProductSpecificationWrapper>
      <div className="w-full card radius-1_5 border overflow-scroll">
        <div className="flex-column w-fit-content min-w-full h-full">
          <TableHeader headers={specificationHeaders} />
          {productSpecifications.map((specification, index) => (
            <ProductSpecificationItem
              key={specification.id}
              specification={specification}
              showBorderTop={index !== 0}
              isEdit={specificationEdit?.id === specification.id}
              onEdit={() => setSpecificationEdit({ ...specification, isEdit: true })}
              onCancel={() => setSpecificationEdit({})}
              onSave={() => handleSave()}
              setSpecificationEdit={setSpecificationEdit}
              specificationEdit={specificationEdit}
            />
          ))}
          {specificationEdit.isNew && (
            <ProductSpecificationItem
              key={specificationEdit.id}
              specification={specificationEdit}
              showBorderTop={productSpecifications.length > 0}
              isEdit={specificationEdit.isEdit}
              onCancel={() => setSpecificationEdit({})}
              onSave={() => handleSave()}
              setSpecificationEdit={setSpecificationEdit}
              specificationEdit={specificationEdit}
            />
          )}
          <div className="add-new-specification pxy-4 border-top">
            <label
              className="inter-400-text primary-500-text flex items-center cursor"
              onClick={() => setSpecificationEdit({ isEdit: true, isNew: true, id: nanoid() })}>
              <IconContainer
                Icon={AddIcon}
                iconColor="primary_500"
                backgroundColor="transparent"
                iconContainerClassname="p-0 mr-1"
                iconHeight={14}
                iconWidth={14}
              />
              Add specification
            </label>
          </div>
        </div>
      </div>
    </ProductSpecificationWrapper>
  );
};

const ProductSpecificationWrapper = styled.div`
  .header-container {
    height: 52px;
    background-color: ${({ theme }) => theme.natural_50};
  }

  .header-container,
  .data-container {
    display: grid;
    grid-template-columns: minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) 40px;
    column-gap: 16px;
    padding: 16px;
  }

  .action-container {
    right: 0;
    bottom: -34px;
    box-shadow: 0px 4px 6px -4px rgba(16, 24, 40, 0.1), 0px 10px 15px -3px rgba(16, 24, 40, 0.1);
  }
`;

export default ProductSpecification;
