import classNames from 'classnames';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as AddIcon } from '../../../assets/icons/add-icon.svg';
import { ReactComponent as MasqueradeIcon } from '../../../assets/icons/masquerade.svg';
import { ReactComponent as Logout } from '../../../assets/icons/property/logout.svg';
import { ReactComponent as Usericon } from '../../../assets/icons/user-outline.svg';
import Logo from '../../../assets/images/logo.svg';
import { OrganisationContext } from '../../../context/organisationContext';
import { setItemInLocalStorage } from '../../../helpers/localstorage';
import { getUserInitials } from '../../../helpers/utils';
import { setUser } from '../../../store/features/userSlice';
import { NavBarWrapper } from '../../../styles/components/main-layout/navbar.styles';
import IconContainer from '../../common/icon-container';
import ProfileMenuDropdown from '../../common/menu/profile-menu-dropdown';
import CustomTooltip from '../../common/tooltip-new';

const NavBar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector(state => state.user);
  const { breadcrumbs } = useSelector(state => state.navbar);
  const { setSideModal } = useContext(OrganisationContext);

  const [showAddMenu, setShowAddMenu] = React.useState(false);

  const quickAddMenuItems = [
    { name: t('ADD_PROPERTY'), value: 'ADD_PROPERTY', onClick: () => setSideModal({ type: 'add-new-property-side' }) },
    {
      name: t('ADD_TASK'),
      value: 'ADD_TASK',
      onClick: () => setSideModal({ type: 'add-new-task', content: { fromTaskList: true } }),
    },
  ];

  const logout = () => {
    localStorage.removeItem('user');
    navigate('/login', { replace: true });
  };

  const onExitMasquerade = () => {
    const api_tokens = user.original_api_tokens;
    const organizations = user.original_organizations;
    const updatedUser = {
      ...user,
      api_tokens: { ...api_tokens },
      organizations: organizations,
      accountDetails: null,
      isAccessAccount: false,
      original_api_tokens: null,
      masquarage_organizations: null,
      selectedAccount: null,
    };
    setItemInLocalStorage('user', updatedUser);
    dispatch(setUser(updatedUser));
    navigate('/properties', { replace: true });
  };

  const onEditUserProfile = user => {
    setSideModal({
      type: 'edit-user-profile',
      content: {
        user_id: user?.id,
        fromMyProfile: true,
        onSuccess: () => {},
      },
    });
  };

  const masqueradeMenuItem = user.isAccessAccount
    ? {
        name: t('EXIT_MASQUERADE'),
        icon: MasqueradeIcon,
        withIcon: true,
        onClick: () => onExitMasquerade(),
      }
    : null;

  const handleShowAddTask = () => {
    setSideModal({
      type: 'add-new-task',
      content: {
        fromTaskList: true,
      },
    });
  };

  return (
    <NavBarWrapper className="flex justify-between w-full px-6 py-4">
      <div className="flex">
        <div className="flex items-center logo-container">
          <img className="logo" src={Logo} alt="logo" />
        </div>
        <div className="ml-6 flex overflow-hidden one-line">
          {breadcrumbs.map((breadcrumb, i) => (
            <div key={i} className="flex items-center">
              <Label
                className={classNames(
                  'cursor inter-400-text',
                  i < breadcrumbs.length - 1 ? 'natural-400-text underline-light' : 'natural-900-text underline-dark',
                )}
                onClick={() =>
                  breadcrumb.onClick ? breadcrumb.onClick() : breadcrumb.path ? navigate(breadcrumb.path) : {}
                }>
                {breadcrumb.text}
              </Label>
              {i < breadcrumbs.length - 1 && <label className="ml-1 mr-1 inter-400-text natural-400-text">/</label>}
            </div>
          ))}
        </div>
      </div>
      <div className="flex items-center">
        {/* <IconContainer
          iconContainerClassname="ml-4 nav-icon-wrapper"
          iconHeight={18}
          iconWidth={18}
          Icon={SearchIcon}
          backgroundColor="natural_150"
          iconColor="natural_500"
        />

        <IconContainer
          iconContainerClassname="ml-4 nav-icon-wrapper"
          iconHeight={18}
          iconWidth={18}
          Icon={NotificationIcon}
          backgroundColor="natural_150"
          iconColor="natural_500"
        /> */}
        <CustomTooltip
          id="quick-add-tooltip"
          place="bottom"
          clickable
          tooltipClassname="p-0 quick-add-tooltip-wrapper"
          content={
            <div className="flex flex-column">
              {quickAddMenuItems.map((item, index) => (
                <div
                  key={item.value}
                  className={classNames(
                    'flex items-center cursor-pointer cursor py-3 px-4',
                    index !== 0 && 'border-top',
                  )}
                  onClick={() => {
                    item.onClick();
                    setShowAddMenu(false);
                  }}>
                  <label className="inter-400-text natural-900-text">{item.name}</label>
                </div>
              ))}
            </div>
          }
          hidden={!showAddMenu}
          isOpen={showAddMenu}
          onBlurTooltip={() => setShowAddMenu(false)}>
          <IconContainer
            iconContainerClassname="ml-4 cursor nav-icon-wrapper"
            iconHeight={18}
            iconWidth={18}
            Icon={AddIcon}
            backgroundColor="primary_50"
            iconColor="primary_500"
            onClick={() => setShowAddMenu(!showAddMenu)}
          />
        </CustomTooltip>

        <ProfileMenuDropdown
          menuList={[
            {
              name: t('MY_PROFILE'),
              icon: Usericon,
              withIcon: true,
              onClick: () => onEditUserProfile(user?.user),
            },
            {
              name: t('LOG_OUT'),
              icon: Logout,
              withIcon: true,
              onClick: () => logout(),
            },
            masqueradeMenuItem,
          ].filter(Boolean)}
          title={getUserInitials(user?.user)}
        />
      </div>
    </NavBarWrapper>
  );
};

export const Label = styled.label`
  position: relative;

  &::after {
    position: absolute;
    content: '';
    width: 0;
    height: 1px;
    bottom: -6px;
    left: 0;
    border-radius: 2px;
    transition: 400ms ease;
  }

  &:hover {
    &::after {
      width: 100%;
    }
  }

  &.underline-light {
    &::after {
      background: ${({ theme }) => theme.natural_400};
    }
  }
  &.underline-dark {
    &::after {
      background: ${({ theme }) => theme.natural_900};
    }
  }
`;

export default NavBar;
