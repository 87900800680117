import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../assets/images/toast-close.svg';
import IconContainer from '../../components/common/icon-container';
import { removeToast } from '../../store/features/toastSlice';
const Toast = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { toasts } = useSelector(state => state.toast);

  const onRemoveToast = toast => {
    dispatch(removeToast(toast));
  };

  const toastsReducer = toasts.reduce((acc, curr) => {
    if (!acc.find(toast => toast.id === curr.id)) {
      acc.push(curr);
    }
    return acc;
  }, []);

  const DefaultToast = ({ toast }) => {
    return (
      <div className="flex h-16 items-center justify-between radius-2 mb-2 toast pr-3 sidebar-close-ignore">
        <div className="flex flex-1 items-center toast-left">
          <div className={classNames('toast-color', toast.error && 'error-toast-color')} />
          <div className="flex-1 flex-column ml-5">
            <div className="flex items-center justify-between">
              <label className="inter-600-text natural-900-text">
                {toast.title ? toast.title : toast.error ? 'Error' : 'Success'}
              </label>
              <IconContainer
                iconContainerClassname="cursor"
                Icon={CloseIcon}
                onClick={() => onRemoveToast(toast)}
                iconColor="natural_500"
                backgroundColor="transparent"
              />
            </div>
            <label className="inter-400-text natural-600-text font-12">{toast.text}</label>
          </div>
        </div>
      </div>
    );
  };

  return (
    <ToastWrapper className="ignore-click">
      {toasts && (
        <TransitionGroup>
          {toastsReducer.map(
            (toast, i) =>
              toast?.type !== 'download' && (
                <CSSTransition key={i} classNames="fade" timeout={200}>
                  <DefaultToast toast={toast} />
                </CSSTransition>
              ),
          )}
        </TransitionGroup>
      )}
    </ToastWrapper>
  );
};

const ToastWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 24px;
  top: 24px;
  z-index: 50000;

  &:has(.download-toast) {
    height: 100%;
    > div {
      height: 100%;
    }
  }

  .progress {
    position: relative;
    width: 296px;
    height: 4px;
    border-radius: 100px;
    background: #e5e5e5;
    overflow: hidden;
    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      background: #2496ff;
      width: var(--progress-file, 0px);
      transition: 100ms ease width;
      height: 100%;
    }
  }
  .toast {
    background: #ffffff;
    box-shadow: 2px 8px 30px rgba(5, 49, 73, 0.1);
    /* height: 64px; */
    min-width: 340px;
    user-select: none;

    .file-svg {
      width: 20px;
      height: 24px;
      color: ${({ theme }) => theme.natural_500};
    }
    .toast-left {
      .toast-color {
        width: 4px;
        height: 64px;
        background: ${({ theme }) => theme.primary_500};
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      .error-toast-color {
        background: ${({ theme }) => theme.error_500};
      }
    }

    .close-btn {
      margin-right: 16px;
      margin-left: 24px;
      cursor: pointer;
    }
  }

  .fade-enter {
    opacity: 0.01;
  }

  .fade-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-in;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit-active {
    opacity: 0.01;
    transition: opacity 200ms ease-in;
  }
`;

export default Toast;
