import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { ReactComponent as AlertIcon } from '../../assets/icons/alert-blue.svg';
import { ReactComponent as CircleActiveIcon } from '../../assets/icons/circle-active.svg';
import { ReactComponent as CircleIcon } from '../../assets/icons/circle.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/toast-close.svg';
import Button from '../../components/common/button/button';
import IconContainer from '../../components/common/icon-container';
import { OrganisationContext } from '../../context/organisationContext';
import { initModal } from '../../helpers/utils';
import { updateWorkitemOwners } from '../../store/features/workitemSlice';

const ModelHeader = props => {
  const { title, description } = props;
  const { setModal } = useContext(OrganisationContext);
  return (
    <div>
      <div className="mb-5">
        <div className="alert-icon-wrapper">
          <IconContainer
            Icon={AlertIcon}
            iconColor="natural-900-text"
            backgroundColor="transparent"
            iconContainerClassname="p-0"
            iconHeight={48}
            iconWidth={48}
          />
        </div>
        <div className="close-icon-wrapper" onClick={() => setModal(initModal)}>
          <IconContainer
            Icon={CloseIcon}
            iconColor="natural-900-text"
            iconClassName="cursor"
            backgroundColor="transparent"
            iconContainerClassname="p-0"
          />
        </div>
      </div>
      <div>
        <p className="text-center mb-2 inter-500-text font-18 natural-500-text color-neutral-900">{title}</p>
      </div>
      <div>
        <p className="text-center mb-8 inter-400-text px-2_5 font-14 model-description color-gray-secondary">
          {description}
        </p>
      </div>
    </div>
  );
};

const ProgressAndExit = ({ steps, handleProgress }) => {
  const { t } = useTranslation();
  const { setModal } = useContext(OrganisationContext);
  const [activeStage, setActiveStage] = useState('');

  return (
    <div className="w-full p-6">
      <ModelHeader title={'Progress Job'} description={'Select the stage to which you want to progress:'} />
      <div>
        <div className="mb-3 flex-column row-gap-3 steps-wrapper">
          {steps.map(({ stage }) => (
            <div
              key={stage.id}
              onClick={() => setActiveStage(stage)}
              className="radius-1_5 flex cursor items-center gap-2 border py-2 px-2_5">
              <p>
                <IconContainer
                  Icon={stage.id === activeStage.id ? CircleActiveIcon : CircleIcon}
                  iconWidth={24}
                  iconHeight={24}
                  backgroundColor="transparent"
                />
              </p>
              <p className="font-16 inter-400-text natural-900-text line-height-150">{stage.name}</p>
            </div>
          ))}
        </div>
        <div className="mb-3">
          <Button
            className={`primary flex-1`}
            disabled={!activeStage}
            onClick={() => handleProgress(activeStage)}
            label={t('PROGRESS_AND_EXIT')}
            size="large"
          />
        </div>
        <div>
          <Button className={`default flex-1 `} label={t('CANCEL')} size="large" onClick={() => setModal(initModal)} />
        </div>
      </div>
    </div>
  );
};

const InitialModel = props => {
  const { t } = useTranslation();
  const { setSelectedAction, userDetails, workitemDetails, setModal, blockerProceed, handleUnassignOwner } = props;
  const { owners = [] } = workitemDetails || {};

  const isUserJobOwner = owners.find(o => o.user.id === userDetails.id);

  return (
    <div className="w-full p-6">
      <ModelHeader title={t('EXIT_JOB')} description={t('COMPLETE_FOLLOWING')} />
      <div>
        <div className="mb-3">
          <Button
            className={`primary flex-1`}
            onClick={() => blockerProceed()}
            label={t('DO_NOTHING_EXIT_JOB')}
            size="large"
          />
        </div>
        <div className="mb-3">
          <Button
            className={`primary flex-1`}
            onClick={() => setSelectedAction('PROGRESS_AND_EXIT')}
            label={t('PROGRESS_AND_EXIT')}
            size="large"
          />
        </div>
        {isUserJobOwner && (
          <div className="mb-3">
            <Button
              className={`primary flex-1`}
              onClick={() => handleUnassignOwner()}
              label={t('UNASSIGN_AND_EXIT')}
              size="large"
            />
          </div>
        )}
        <div>
          <Button className={`default flex-1`} label={t('CANCEL')} size="large" onClick={() => setModal(initModal)} />
        </div>
      </div>
    </div>
  );
};

const ExitJobDetails = () => {
  const [selectedAction, setSelectedAction] = useState(null);

  const dispatch = useDispatch();
  const { setModal, modal } = useContext(OrganisationContext);

  const { workitemDetails } = useSelector(state => state.board);
  const { userDetails } = useSelector(state => state.user);
  const { workItemProgress, allowedProgressAbleStageIds, stageDecisions } = useSelector(state => state.workItem);

  const steps = workItemProgress.filter(w => allowedProgressAbleStageIds.includes(w.stage.id));

  const blockerProceed = () => {
    modal.content.blocker.proceed();
    setModal(initModal);
  };

  const handleUnassignOwner = () => {
    dispatch(updateWorkitemOwners({ request: [], workitem_id: workitemDetails.id })).then(() => {
      blockerProceed();
    });
  };

  const handleProgress = activeStage => {
    const decisionStage = stageDecisions?.find(({ stage: { id } }) => id === activeStage.id);
    setModal({
      type: 'move-to-stage',
      content: {
        workitem_id: workitemDetails?.id,
        stage: activeStage,
        decisionStage: decisionStage,
        onSuccess: blockerProceed,
      },
    });
  };

  useEffect(() => {
    if (selectedAction === 'DO_NOTHING_AND_EXIT_THE_JOB') {
      blockerProceed();
    }
  }, [selectedAction]);

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <ExitJobWrapper>
        {!selectedAction && (
          <InitialModel
            workitemDetails={workitemDetails}
            setSelectedAction={setSelectedAction}
            userDetails={userDetails}
            handleUnassignOwner={handleUnassignOwner}
            blockerProceed={blockerProceed}
            setModal={setModal}
          />
        )}
        {selectedAction === 'PROGRESS_AND_EXIT' && (
          <ProgressAndExit steps={steps} handleProgress={handleProgress} setSelectedAction={setSelectedAction} />
        )}
      </ExitJobWrapper>
    </CSSTransition>
  );
};

export const ExitJobWrapper = styled.div`
  max-width: 384px;

  .alert-icon-wrapper {
    width: 100%;
  }
  .close-icon-wrapper {
    position: absolute;
    top: 29px;
    right: 29px;
  }

  .steps-wrapper {
    max-height: 265px;
    overflow-y: auto;
  }
`;

export default ExitJobDetails;
