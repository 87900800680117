import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as NoDocumentIcon } from '../../assets/icons/empty-document.svg';
import { setTaskList } from '../../store/features/taskSlice';
import InfiniteScrollV2 from '../common/infinite-scroll-v2';
import NoData from '../common/no-data';
import SkeletonTransition from '../common/skeleton-transition';
import TableHeader from '../common/table-header';
import TaskListItem from './task-list-item';

const taskTableHeaders = [
  { name: 'NAME', key: 'NAME' },
  { name: 'SUBTASKS', key: 'SUBTASKS' },
  { name: 'DATE', key: 'DATE' },
  // { name: 'TYPE', key: 'TYPE' },
  { name: 'STATUS', key: 'STATUS' },
  { name: 'PRIORITY', key: 'PRIORITY' },
  { name: 'ASSIGNEE', key: 'ASSIGNEE' },
  { name: 'JOB', key: 'JOB' },
  { name: '', key: 'MENU' },
];

const TaskList = ({ debouncedSearch, fetchTasksList }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pageRef = useRef(null);
  const { taskList } = useSelector(state => state.task);
  const { content: task, last } = taskList || {};
  const [loading, setLoading] = useState(true);
  const fetchTasks = (page, merge, showLoading = true) => {
    pageRef.current = page;
    setLoading(showLoading && !merge);
    const promise = fetchTasksList(page, merge, showLoading);
    promise.finally(() => setLoading(false));
  };
  const fetchMoreData = () => {
    fetchTasks(pageRef.current + 1, true, true);
  };

  useEffect(() => {
    fetchTasks(0, false, true);
  }, [debouncedSearch]);

  useEffect(() => {
    return () => {
      dispatch(setTaskList({}));
    };
  }, []);

  return (
    <TaskListWrapper className="flex-column flex-1  radius-1 mt-6 mx-6 overflow-hidden">
      <SkeletonTransition
        loading={loading}
        loaderClassName="item flex items-center justify-center flex-1 mb-6"
        height={'100%'}
        containerClassName="line-height-1 h-full w-full">
        {(task || []).length > 0 ? (
          <div className="w-full card radius-1_5 border overflow-scroll">
            <div className="flex-column w-fit-content min-w-full h-full">
              <TableHeader headers={taskTableHeaders} />
              <InfiniteScrollV2
                hasMore={!last}
                fetchMoreData={fetchMoreData}
                infiniteScrollClassName="custom-scrollbar thin-scrollbar">
                {(task || []).map(task => (
                  <TaskListItem task={task} key={task.id} arrows={true} fetchTasks={() => fetchTasks(0, false, true)} />
                ))}
              </InfiniteScrollV2>
            </div>
          </div>
        ) : debouncedSearch ? (
          <div className="flex-column flex-1 mb-6 items-center justify-center">
            <NoData
              title={t('NO_RESULTS_FOUND')}
              description={t('NO_RESULTS_DESCRIPTION')}
              className="search-terms"
              EmptyIcon={NoDocumentIcon}
              iconClassName="mb-6 relative"
            />
          </div>
        ) : (
          <div className="flex-column flex-1 mb-6 items-center justify-center">
            <NoData
              title={t('NO_TASKS')}
              className="search-terms"
              description={t('NO_TASKS_HERE')}
              EmptyIcon={NoDocumentIcon}
              iconClassName="mb-6 relative"
            />
          </div>
        )}
      </SkeletonTransition>
    </TaskListWrapper>
  );
};

const TaskListWrapper = styled.div`
  border-radius: 6px;
  .header-container,
  .data-container {
    display: grid;
    grid-template-columns:
      minmax(160px, 1fr) 80px minmax(80px, 100px) minmax(80px, 110px) 120px 80px minmax(220px, 1fr)
      32px;
    column-gap: 32px;
    padding: 0 31px;
  }

  .header-container {
    height: 52px;
    background-color: ${({ theme }) => theme.natural_50};
  }

  .no-result-container,
  .no-data-container {
    width: 300px;
  }

  .search-terms {
    max-width: 343px;
  }
`;

export default TaskList;
