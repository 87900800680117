import classNames from 'classnames';
import { motion } from 'framer-motion';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as CrossIcon } from '../../assets/icons/CrossIcons.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { ReactComponent as EditPensil } from '../../assets/icons/edit-pensil.svg';
import { ReactComponent as EmptyIcon } from '../../assets/icons/empty-document.svg';
import { ReactComponent as FileIcon } from '../../assets/icons/file.svg';
import { ReactComponent as RetryIcon } from '../../assets/icons/property/refresh-upload.svg';
import { ReactComponent as UploadIcon } from '../../assets/icons/property/uploadicon.svg';
import { ReactComponent as RoundExplanationIcon } from '../../assets/icons/round-explanation.svg';
import { ReactComponent as UploadFileIcon } from '../../assets/icons/upload-file.svg';
import { ReactComponent as DeleteIcons } from '../../assets/images/DeleteIcons.svg';
import { ReactComponent as HorizontalDots } from '../../assets/images/HorizontalDots.svg';
import { ReactComponent as LeftArrow } from '../../assets/images/arrow-left.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/close-image.svg';
import { ReactComponent as ViewIcon } from '../../assets/images/hide-password.svg';
import { ReactComponent as Loader } from '../../assets/images/loading.svg';
import { OrganisationContext } from '../../context/organisationContext';
import useDebounce from '../../helpers/useDebounceHook';
import { bytesToSize, getImageThumbnail } from '../../helpers/utils';
import { downloadEvidence, downloadFile } from '../../store/features/downloadSlice';
import { uploadFile } from '../../store/features/fileuploadSlice';
import { createEvidences, getEvidences, updateEvidencePropertyFile } from '../../store/features/propertySlice';
import { addToast } from '../../store/features/toastSlice';
import { Avatar } from '../common/avatar-group';
import Button from '../common/button/button';
import Checkbox from '../common/checkbox';
import IconContainer from '../common/icon-container';
import InputElement from '../common/input';
import InputSearch from '../common/input-search';
import MenuV2 from '../common/menu/menu-v2';
import NoData from '../common/no-data';
import SkeletonTransition from '../common/skeleton-transition';
import TableHeader from '../common/table-header';
import CustomTooltip from '../common/tooltip-new';
import UpdateAction from '../common/update-action';
import ViewFile from '../view-file/view-file';
import ViewFileImage from '../view-file/view-file-image';

const filesTableHeaders = [
  { name: '', key: 'ITEM_SELECTOR' },
  { name: 'Name', key: 'Name' },
  { name: 'SOURCE', key: 'SOURCE' },
  { name: 'UPLOAD DATE', key: 'UPLOAD DATE' },
  { name: 'BY', key: 'BY' },
  { name: '', key: 'MENU' },
];

const maxFileSize = 50 * 1024 * 1024; // 50 MB max file size
const imageExtensions = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/bmp',
  'image/svg',
  'image/svg+xml',
];
const pdfExtensions = ['application/pdf'];

export const FileUpload = ({ file, setFiles, showDeletePopup = true, children, wrapperClassName = '' }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setModal } = useContext(OrganisationContext);

  const { id, media_id, name, size, url, type, file_data, media_url, created_on } = file || {};

  const [progress, setProgress] = useState(0);
  const [fileUploading, setFileUploading] = useState(true);
  const [maxSizeError, setMaxSizeError] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [isEdit, setEdit] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [showViewFile, setShowViewFile] = useState(false);
  const [showViewImage, setShowViewImage] = useState(false);

  const onUploadFile = async () => {
    if (size > maxFileSize) {
      setMaxSizeError(true);
      setFileUploading(false);
      return;
    }
    setFileUploading(true);
    dispatch(
      uploadFile({
        file: file_data,
        onSuccess: data => {
          setFileUploading(false);
          setFiles(prev =>
            prev.map(item =>
              item.id === file.id
                ? { ...item, media_id: data?.media_id, media_url: data?.url, created_on: moment().unix() }
                : item,
            ),
          );
        },
        onError: () => {
          dispatch(addToast({ error: true, text: t('ERROR_WHILE_UPLOADING_FILE', { name: name }), id: nanoid() }));
          setFileUploading(false);
          setUploadError(true);
        },
        setProgress,
      }),
    );
  };

  const onRetryFile = () => {
    setUploadError(false);
    setFileUploading(true);
    setProgress(0);
    onUploadFile();
  };

  const onRemoveFile = () => {
    setFiles(prev => prev.filter(item => item.id !== id));
  };

  const onEditFile = () => {
    if (isEdit == '') {
      dispatch(
        addToast({
          error: true,
          text: t('Please_ADD_NAME_FOR_THE_FILE'),
          id: nanoid(),
        }),
      );
      return;
    }
    setFiles(prev => prev.map(item => (item.id === id ? { ...item, name: isEdit } : item)));
    setEdit(null);
    setIsEditable(false);
  };

  const onDelete = () => {
    onRemoveFile();
    dispatch(
      addToast({
        error: false,
        title: t('FILE_DELETED'),
        text: t('SUCCESSFULLY_DELETED_FILE', { value: name }),
        id: nanoid(),
      }),
    );
  };

  const onDeleteFile = () => {
    if (showDeletePopup) {
      setModal({
        type: 'jobfiles-action',
        content: {
          isEnable: true,
          fileData: file,
          onDelete: onDelete,
          title: `${t('DELETE_FILE')}?`,
          description: t('ARE_YOU_SURE_TO_DELETE_THIS_FILE'),
        },
      });
    } else {
      onDelete();
    }
  };

  const onViewFile = file => {
    if (pdfExtensions.includes(type)) {
      setShowViewFile({ ...file, name, created_on, media: { url: media_url } });
    } else if (imageExtensions.includes(type)) {
      setShowViewImage({ ...file, name, created_on, media: { url: media_url } });
    } else {
      dispatch(
        addToast({
          error: true,
          text: file?.media_id ? t('FORMAT_NOT_SUPPORTED_TO_VIEW') : t('THERE_IS_NO_MEDIA_ATTECHED'),
          id: nanoid(),
        }),
      );
    }
  };

  useEffect(() => {
    if (id && !media_id) {
      onUploadFile();
    }
  }, [id]);

  return (
    <FileUploadWrapper className={classNames('border px-4 py-3 radius-1_5', wrapperClassName)}>
      <div className="flex gap-3">
        <div className="flex items-center uploaded-file">
          {imageExtensions.includes(type) ? (
            <img
              className={classNames(
                'radius-1_5',
                fileUploading || maxSizeError || uploadError ? 'uploading' : 'uploaded',
              )}
              src={url}
            />
          ) : (
            <IconContainer
              Icon={FileIcon}
              iconWidth={fileUploading || maxSizeError || uploadError ? 24 : 12}
              iconHeight={fileUploading || maxSizeError || uploadError ? 24 : 12}
              iconContainerClassname={classNames(fileUploading || maxSizeError ? 'uploading' : 'uploaded')}
            />
          )}
        </div>
        <div className="w-full">
          <div className="flex items-center justify-between">
            {isEditable ? (
              <div className="flex flex-1 items-center justify-between">
                <InputElement
                  className="flex-1 w-full edit-file-input"
                  value={isEdit}
                  autoFocus
                  onChange={value => setEdit(value)}
                />
                <UpdateAction
                  className="w-fit-content"
                  btnClassname="specified-width"
                  width="70px"
                  onCancel={() => {
                    setEdit(null);
                    setIsEditable(false);
                  }}
                  onUpdate={onEditFile}
                />
              </div>
            ) : (
              <Fragment>
                <div className="flex items-center gap-1 mr-2 file-name">
                  <p className="inter-400-text natural-900-text">{name}</p>
                  <p className="inter-400-text natural-400-text inter-400-text font-12">{`(${bytesToSize(size)})`}</p>
                </div>
                <div className="flex items-center gap-3">
                  {fileUploading || maxSizeError || uploadError ? (
                    <Fragment>
                      {uploadError && (
                        <IconContainer
                          Icon={RetryIcon}
                          iconHeight={12}
                          iconWidth={12}
                          iconColor="natural_500"
                          backgroundColor="transparent"
                          iconContainerClassname="cursor"
                          onClick={onRetryFile}
                        />
                      )}
                      <IconContainer
                        Icon={CloseIcon}
                        iconHeight={16}
                        iconWidth={16}
                        iconColor="natural_500"
                        backgroundColor="transparent"
                        iconContainerClassname="cursor"
                        onClick={onRemoveFile}
                      />
                    </Fragment>
                  ) : (
                    <Fragment>
                      {children}
                      <CustomTooltip
                        tooltipClassname="tooltip"
                        id="view-image-tooltip"
                        content={<span className="inter-400-text font-12">View image</span>}>
                        <IconContainer
                          Icon={ViewIcon}
                          iconHeight={18}
                          iconWidth={18}
                          iconColor="natural_500"
                          backgroundColor="transparent"
                          iconContainerClassname="cursor p-0"
                          onClick={onViewFile}
                        />
                      </CustomTooltip>
                      <CustomTooltip
                        tooltipClassname="tooltip"
                        id="edit-image-name-tooltip"
                        content={<span className="inter-400-text font-12">Edit image name</span>}>
                        <IconContainer
                          Icon={EditPensil}
                          iconColor="natural_500"
                          backgroundColor="transparent"
                          iconContainerClassname="cursor p-0"
                          onClick={() => {
                            setEdit(name);
                            setIsEditable(true);
                          }}
                        />
                      </CustomTooltip>
                      <CustomTooltip
                        tooltipClassname="tooltip"
                        id="delete-image-tooltip"
                        content={<span className="inter-400-text font-12">Delete image</span>}>
                        <IconContainer
                          Icon={DeleteIcons}
                          iconColor="natural_500"
                          backgroundColor="transparent"
                          iconContainerClassname="cursor p-0"
                          onClick={onDeleteFile}
                        />
                      </CustomTooltip>
                    </Fragment>
                  )}
                </div>
              </Fragment>
            )}
          </div>
          {fileUploading ? (
            <div className="flex items-center w-full gap-3 mt-2">
              <div className="w-full progress-container">
                <div className="h-full progress-bar" style={{ width: `${Math.ceil(progress)}%` }}></div>
              </div>
              <div className="inter-400-text natural-400-text progress-label">{Math.ceil(progress)}%</div>
            </div>
          ) : (
            (maxSizeError || uploadError) && (
              <div className="flex items-center mt-2">
                <IconContainer
                  Icon={RoundExplanationIcon}
                  iconColor="error_500"
                  iconContainerClassname="mr-1 p-0"
                  backgroundColor="transparent"
                />
                <p className="error-text inter-400-text font-12">
                  {maxSizeError ? t('THIS_FILE_TOO_LARGE') : t('ERROR_WHILE_UPLOADING_FILE_TRY_AGAIN')}
                </p>
              </div>
            )
          )}
        </div>
      </div>
      {showViewFile && <ViewFile close={() => setShowViewFile(null)} file={showViewFile} selectedDoc={showViewFile} />}
      {showViewImage && (
        <ViewFileImage close={() => setShowViewImage(null)} file={showViewImage} selectedDoc={showViewImage} />
      )}
    </FileUploadWrapper>
  );
};

const PropertyFileUpload = ({ setIsFileUploadOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { workitemDetails } = useSelector(state => state.board);
  const { engagement } = workitemDetails || {};

  const [onFile, setOnFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  const onAddFiles = addedFiles => {
    const newFiles = addedFiles?.map(file => ({
      file_data: file,
      name: file.name,
      size: file.size,
      type: file.type,
      url: URL.createObjectURL(file),
      id: nanoid(),
    }));
    setFiles(prev => [...prev, ...newFiles]);
  };

  const onFileDrop = e => {
    e.preventDefault();
    onAddFiles([...e.dataTransfer?.files]);
    setOnFile(false);
  };

  const onFileAdd = e => {
    e.preventDefault();
    onAddFiles([...e?.target?.files]);
    e.target.value = null;
  };

  const onDone = async () => {
    if (!files?.length) {
      dispatch(addToast({ error: true, text: t('PLEASE_ADD_FILES'), id: nanoid() }));
      return;
    }
    const isAllFilesUploaded = files.every(file => file?.media_id);
    if (!isAllFilesUploaded) {
      dispatch(addToast({ error: true, text: t('PLEASE_WAIT_TO_FINISH_UPLOAD_FILE'), id: nanoid() }));
      return;
    }
    if (files?.length > 0) {
      setLoading(true);
      const request = files.map(({ name, media_id }) => ({
        created_date: moment().unix(),
        name: name,
        engagement: {
          id: engagement?.id,
        },
        activity: null,
        task: null,
        task_evidence_config: null,
        media: {
          id: media_id,
        },
        form_session: null,
        device_media_id: null,
        media_count: null,
      }));

      dispatch(createEvidences({ request }))
        .then(({ data }) => {
          setLoading(false);
          setIsFileUploadOpen(false);
          dispatch(
            addToast({
              text: t('EVIDENCE_CREATED_SUCCESSFULLY'),
            }),
          );
        })
        .catch(() => {
          setLoading(false);
          dispatch(addToast({ error: true, text: t('ERROR_WHILE_ADDING_EVIDENCES'), id: nanoid() }));
        });
    }
  };

  return (
    <PropertyFileUploadWrapper className="flex-column row-gap-6 overflow-hidden">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <IconContainer
            iconContainerClassname="cursor radius-0 h-24px w-24px p-0"
            Icon={LeftArrow}
            iconColor="natural_900"
            backgroundColor="transparent"
            onClick={() => setIsFileUploadOpen(false)}
          />
          <label className="font-20 inter-600-text natural-900-text">{t('UPLOAD_FILES')}</label>
        </div>
        <Button
          fontSize="14px"
          size="medium"
          width="80px"
          borderRadius="100px"
          className="primary specified-width"
          label={'Done'}
          disabled={loading}
          onClick={onDone}
        />
      </div>
      <div className="mb-2 flex-1 overflow-scroll">
        <div
          className={classNames(
            'py-10 border-dotted radius-1_5 flex-column items-center justify-center mb-6',
            onFile ? 'on-droparea' : 'off-droparea',
          )}
          onDragLeave={e => {
            e.preventDefault();
            setOnFile(false);
          }}
          onDragOver={e => {
            e.preventDefault();
            setOnFile(true);
          }}
          onDrop={onFileDrop}>
          <IconContainer
            iconContainerClassname="mr-2"
            Icon={UploadFileIcon}
            iconColor="natural_300"
            backgroundColor="transparent"
            iconWidth={52}
            iconHeight={52}
          />
          <p className="flex font-16 inter-400-text mt-4">
            <span className="natural-700-text mr-1">{t('DRAG_AND_DEOP_FILES_HERE')}</span>
            <p className="flex">
              <input type="file" multiple id="file-upload-input" style={{ display: 'none' }} onChange={onFileAdd} />
              <label htmlFor="file-upload-input" className="primary-text cursor">
                {t('BROWSE')}
              </label>
            </p>
          </p>
          <label className="font-12 inter-400-text natural-500-text mt-1">{t('MAXIMUM_FILE_SIZE_50MP')}</label>
        </div>
        <div className="flex-column row-gap-4">
          {files.length > 0 && files?.map(file => <FileUpload key={file.id} file={file} setFiles={setFiles} />)}
        </div>
      </div>
    </PropertyFileUploadWrapper>
  );
};

const PropertyFiles = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { hasPermission, setModal } = useContext(OrganisationContext);

  const { workitemDetails } = useSelector(state => state.board);
  const { evidenceFileList } = useSelector(state => state.property);
  const { engagement } = workitemDetails || {};

  const [loading, setLoading] = useState(true);
  const [downlaodLoading, setDownlaodLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [isFileUploadOpen, setIsFileUploadOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [showViewFile, setShowViewFile] = useState(false);
  const [showViewImage, setShowViewImage] = useState(false);
  // const [isDownloading, setIsDownloading] = useState(false);
  const [fileIDUpdate, setFileIdUpdate] = useState('');
  const [valueUpdate, setValueUpdate] = useState({});
  const [getFilesallData, setGetFilesallData] = useState();
  const debouncedSearch = useDebounce(search, 500);
  const [checked, setChecked] = useState([]);

  const fetchFiles = (engagement_id, debouncedSearch) => {
    setLoading(true);
    dispatch(getEvidences({ params: { engagement_id, search: debouncedSearch } }))
      .then(data => setFiles(data))
      .catch(() => {
        dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_FILES') }));
        setFiles([]);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (engagement?.id && !isFileUploadOpen) {
      fetchFiles(engagement?.id, debouncedSearch);
    }

    return () => {
      setFiles([]);
    };
  }, [engagement?.id, debouncedSearch, isFileUploadOpen]);

  const onDownloadFile = async file => {
    const { media } = file || {};
    if (checked.length > 0) {
      const evidenceIds = checked.map(fileId => ({ id: fileId }));
      const milestones = [0, 10, 40, 80, 100];
      setDownlaodLoading(true);
      dispatch(
        downloadEvidence(
          {
            request: evidenceIds,
            filename: 'evidence.zip',
          },
          {
            onDownloadProgress: progressEvent => {
              const newProgress = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
              setProgress(newProgress);
            },
          },
        ),
      )
        .then(() => {
          setDownlaodLoading(false);
          setChecked([]);
        })
        .catch(error => {
          dispatch(addToast({ error: true, text: t('ERROR_WHILE_DOWNLOADING_FILES'), id: nanoid() }));
        })
        .finally(() => {
          setDownlaodLoading(false);
        });
    } else if (media?.url) {
      dispatch(
        downloadFile({
          url: media?.url,
          filename: file?.name,
          onDownloadProgress: progress => {
            setProgress(progress);
            if (progress == '100') {
              setTimeout(() => {
                setIsDownload(false);
              }, 2000);
            }
            setSingleFileName(file?.name);
          },
        }),
      );
    } else {
      dispatch(addToast({ error: true, text: t('THERE_IS_NO_MEDIA_ATTECHED'), id: nanoid() }));
    }
  };

  const onViewFile = file => {
    if (file?.media?.format === 'pdf') {
      setShowViewFile(file);
    } else if (
      file?.media?.type === 'PHOTO' ||
      file?.media?.format === 'jpg' ||
      file?.media?.format === 'jpeg' ||
      file?.media?.format === 'png'
    ) {
      setShowViewImage(file);
    } else {
      dispatch(
        addToast({
          error: true,
          text: file?.media ? t('FORMAT_NOT_SUPPORTED_TO_VIEW') : t('THERE_IS_NO_MEDIA_ATTECHED'),
          id: nanoid(),
        }),
      );
    }
  };

  const getSourceFromFile = file => {
    const { task, activity } = file || {};
    if (!task && !activity) {
      return (
        <label className="primary-500-text bg-primary-50 flex items-center px-3 py-1 radius-6 inter-500-text font-12 w-fit-content">
          {t('DIRECT')}
        </label>
      );
    }
    if (activity) {
      return (
        <label className="w-fit-content purple-500-text bg-purple-50 flex items-center px-3 py-1 radius-6 inter-500-text font-12">
          {activity.name}
        </label>
      );
    }
    if (task) {
      return (
        <label className="success-500-text bg-success-50 flex items-center px-3 py-1 radius-6 inter-500-text font-12">
          {task.name}
        </label>
      );
    }
  };

  const onRemoveFile = file_id => {
    setFiles(prev => prev.filter(item => item.id !== file_id));
    const updatedCheckedItem = checked.filter(data => data !== file_id);
    setChecked(updatedCheckedItem);
  };

  const onDeleteFiles = (file_id, file) => {
    setModal({
      type: 'jobfiles-action',
      content: {
        fileDetails: 'files',
        isEnable: true,
        fileData: file,
        file_id: file_id,
        onSuccess: onRemoveFile,
        title: `${t('DELETE_FILE')}?`,
        description: t('ARE_YOU_SURE_TO_DELETE_THIS_FILE'),
      },
    });
  };

  const UpdateFilesData = files_id => {
    const valueFileSelect = evidenceFileList?.find(data => data?.id === files_id);
    setGetFilesallData(valueFileSelect);
    setValueUpdate({ ...valueUpdate, files: valueFileSelect?.name });
  };

  const clickToUpdateFiles = async () => {
    const request = {
      name: valueUpdate.files,
      engagement: {
        id: getFilesallData?.engagement?.id,
      },
      activity: getFilesallData?.activity?.id
        ? {
            id: getFilesallData?.activity?.id,
          }
        : null,
      task: getFilesallData?.task?.id
        ? {
            id: getFilesallData?.task?.id || '',
          }
        : null,
      task_evidence_config: getFilesallData?.task_evidence_config?.id
        ? {
            id: getFilesallData?.task_evidence_config?.id || '',
          }
        : null,
      upload_date: getFilesallData?.evidence_date?.uploaded_on || '',
      media: {
        id: getFilesallData?.media?.media_external_id || '',
      },
      local_url: getFilesallData?.local_url || null,
      form_session: getFilesallData?.form_session?.id
        ? {
            id: getFilesallData?.form_session?.id || '',
            session_start: getFilesallData?.form_session?.session_start || '',
            session_end: getFilesallData?.form_session?.session_end || '',
            media: getFilesallData?.form_session?.media || '',
          }
        : null,
    };
    dispatch(updateEvidencePropertyFile({ request, evidence_id: fileIDUpdate }))
      .then(data => {
        setFileIdUpdate('');
        setValueUpdate({});
        setFiles(prev => prev.map(item => (item.id === fileIDUpdate ? data : item)));
        dispatch(addToast({ text: t('FILE_UPDATED_SUCCESSFULLY'), id: nanoid() }));
      })
      .catch(error => {
        setFileIdUpdate('');
        setValueUpdate({});
        dispatch(addToast({ error: true, text: t('ERROR_WHILE_UPDATING_FILE') }));
      });
  };

  const onEnterToUpdate = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      clickToUpdateFiles();
    }
    if (e.key === 'Escape') {
      setFileIdUpdate('');
      setValueUpdate({});
    }
  };

  const onRemoveFiles = fileIds => {
    setFiles(prev => prev.filter(item => !fileIds.includes(item.id)));
    const updatedCheckedItem = checked.filter(data => !fileIds.includes(data));
    setChecked(updatedCheckedItem);
  };

  const onDeleteMultiFiles = () => {
    setModal({
      type: 'jobfiles-action',
      content: {
        isEnable: true,
        fileData: checked,
        isMulti: true,
        onSuccess: () => onRemoveFiles(checked),
        title: `${t('Delete these')} ${checked.length} ${t('FILES')}?`,
        description: `${t('ARE_YOU_SURE_TO_DELETE_THESE')} ${checked.length} ${t('FILES')}? ${t('ONCE_DELETED')}`,
      },
    });
  };

  return (
    <div className="flex-column flex-1 pxy-6 row-gap-6 overflow-hidden">
      {isFileUploadOpen ? (
        <PropertyFileUpload setIsFileUploadOpen={setIsFileUploadOpen} />
      ) : (
        <Fragment>
          {checked?.length ? (
            <div className="bg-natural-50 justify-between items-center radius-full py-2 px-4">
              <div className="flex items-center gap-2">
                <CrossIcon height={20} width={20} className="cursor" onClick={() => setChecked([])} />
                <span className="font-14 inter-500-text color-neutral-900">
                  {checked?.length} {t('SELECTED')}
                </span>
              </div>
              <div className="flex items-center w-100px justify-evenly">
                <div className="w-32px h-32px flex items-center justify-center border radius-full cursor">
                  {downlaodLoading ? (
                    <Loader width={14} height={14} />
                  ) : (
                    <DownloadIcon height={14} width={14} onClick={onDownloadFile} />
                  )}
                </div>
                <IconContainer
                  Icon={DeleteIcons}
                  iconColor="natural_900"
                  backgroundColor="transparent"
                  iconContainerClassname="cursor border radius-full pxy-2"
                  iconWidth={14}
                  iconHeight={14}
                  onClick={onDeleteMultiFiles}
                />
              </div>
            </div>
          ) : null}
          <div className="flex justify-between">
            <div className="flex align-center">
              <InputSearch placeholder={'Search'} value={search} onChange={setSearch} />
            </div>
            {hasPermission('EVIDENCE_MANAGE') && (
              <Button
                fontSize="14px"
                size="average"
                width="102px"
                borderRadius="100px"
                className="primary specified-width  px-4 py-2_5 ml-3"
                afterIcon={<UploadIcon className="mr-0 white-text" height={16} width={16} />}
                label={t('UPLOAD')}
                lableSize="w-48px line-height-20"
                onClick={() => setIsFileUploadOpen(true)}
              />
            )}
          </div>
          <div className="mb-2 flex-1 flex overflow-scroll">
            <SkeletonTransition
              loading={loading}
              loaderClassName="item w-full flex items-center justify-center flex-1"
              height={'50vh'}
              containerClassName="line-height-1 flex-1 radius-2"
              baseColor="#CDCDCD"
              highlightColor="#E8E8E8">
              <FileListWrapper className="flex-column flex-1 radius-1 overflow-hidden inter-600-text font-12">
                {files.length > 0 ? (
                  <motion.div
                    key={'file-details'}
                    initial={{ opacity: 0.3, y: 10 }}
                    animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}
                    className="w-full card radius-1_5 border overflow-scroll">
                    <div className="flex-column w-fit-content min-w-full h-auto files-edit-full">
                      <TableHeader
                        headers={filesTableHeaders}
                        is_cBAChecked_done={true}
                        allSelected={checked.length === files.length}
                        is_checked_done={true}
                        onSelectorClick={() => {
                          if (checked.length === files.length) {
                            setChecked([]);
                          } else {
                            setChecked(files.map(file => file.id));
                          }
                        }}
                      />
                      {(files || []).map((file, index) => (
                        <FileListItemWrapper
                          initial={{ opacity: 0.3, y: 10 }}
                          animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}
                          key={file.id}
                          onDoubleClick={() => onViewFile(file)}
                          className={classNames(
                            fileIDUpdate === file?.id ? 'flex items-center px-6 gap-3 ' : 'data-container',
                            'py-2 cursor',
                            {
                              'border-top': index,
                            },
                          )}>
                          <Checkbox
                            onChange={() => {
                              if (checked.includes(file.id)) {
                                setChecked(checked?.filter(id => id !== file.id));
                              } else {
                                setChecked([...checked, file.id]);
                              }
                            }}
                            checked={checked.includes(file.id)}
                            is_checked_done={true}
                          />
                          <div className={classNames(fileIDUpdate === file?.id ? 'w-full' : '', 'flex items-center')}>
                            {file?.media?.url &&
                            (file?.media?.type === 'PHOTO' ||
                              file?.media?.format === 'jpg' ||
                              file?.media?.format === 'jpeg' ||
                              file?.media?.format === 'png') ? (
                              <img
                                loading="eager"
                                alt={file?.name}
                                className="file-img"
                                src={getImageThumbnail(file?.media)}
                              />
                            ) : (
                              <IconContainer
                                iconContainerClassname="mr-2 radius-1_3 pxy-1_5 file-box-border"
                                Icon={FileIcon}
                                iconColor="natural_500"
                                backgroundColor="natural_100"
                              />
                            )}
                            <span
                              className={classNames(
                                'font-14 inter-500-text natural-900-text one-line',
                                fileIDUpdate === file?.id && 'w-full',
                              )}>
                              {fileIDUpdate && fileIDUpdate === file?.id ? (
                                <div className="flex  items-start gap-6 content-text py-2 px-2">
                                  <input
                                    name="filename"
                                    autoFocus
                                    value={valueUpdate?.files}
                                    onChange={e => {
                                      setValueUpdate({ ...valueUpdate, files: e.target.value });
                                    }}
                                    onKeyDown={onEnterToUpdate}
                                    className="w-full h-full font-14 radius-1_5 px-2 py-1 edit-input"
                                  />
                                </div>
                              ) : (
                                file?.name
                              )}
                            </span>
                          </div>
                          {fileIDUpdate !== file?.id && (
                            <>
                              {getSourceFromFile(file)}
                              <div className="font-14 inter-400-text line-height-20">
                                {file?.evidence_date?.created_on
                                  ? moment(file?.evidence_date?.created_on * 1000).format('DD/MM/YY')
                                  : '-'}
                              </div>
                              <CustomTooltip
                                wrapperClassName="file-user-tooltip w-fit-content h-fit-content"
                                id={file.id}
                                place="bottom"
                                noArrow={false}
                                content={
                                  <label className="inter-500-text white-text font-12">
                                    {file?.evidence_date?.created_by?.name}
                                  </label>
                                }>
                                <Avatar
                                  variant="medium"
                                  avatarWrapperClassname="workitem-avatar"
                                  avatar={file?.evidence_date?.created_by}
                                  backgroundColorIndex={0}
                                  avatarLabelClassname="font-9"
                                />
                              </CustomTooltip>
                              <MenuV2
                                Icon={HorizontalDots}
                                menuClassName="files-menue"
                                portal={files?.length < 3}
                                menuList={[
                                  {
                                    name: t('VIEW'),
                                    onClick: () => onViewFile(file),
                                    condition: !!file?.media?.url,
                                    withIcon: true,
                                    icon: ViewIcon,
                                    iconClassName: 'natural-400-text',
                                  },
                                  {
                                    name: t('RENAME'),
                                    onClick: () => {
                                      UpdateFilesData(file?.id);
                                      setFileIdUpdate(file?.id);
                                    },
                                    withIcon: true,
                                    icon: EditPensil,
                                    iconClassName: 'natural-400-text',
                                  },
                                  hasPermission('EVIDENCE_VIEW') && {
                                    name: t('DOWNLOAD'),
                                    onClick: () => onDownloadFile(file),
                                    condition: !!file?.media?.url,
                                    withIcon: true,
                                    icon: DownloadIcon,
                                    iconClassName: 'natural-400-text',
                                  },
                                  hasPermission('EVIDENCE_MANAGE') && {
                                    name: t('DELETE'),
                                    icon: DeleteIcons,
                                    withIcon: true,
                                    onClick: () => onDeleteFiles(file?.id, file),
                                    permission: 'EVIDENCE_MANAGE',
                                    iconClassName: 'natural-400-text',
                                  },
                                ].filter(item => item?.condition ?? true)}
                              />
                            </>
                          )}
                        </FileListItemWrapper>
                      ))}
                    </div>
                  </motion.div>
                ) : (
                  <div className="flex-column items-center w-full justify-center flex-1">
                    <NoData
                      title={t('NO_FILES')}
                      description={t('NO_FILES_HERE')}
                      className="mt-12"
                      EmptyIcon={EmptyIcon}
                      iconClassName="relative"
                    />
                  </div>
                )}
              </FileListWrapper>
            </SkeletonTransition>
          </div>
          {showViewFile && (
            <ViewFile
              close={() => setShowViewFile(null)}
              file={showViewFile}
              selectedDoc={showViewFile}
              downloadPdf={onDownloadFile}
            />
          )}
          {showViewImage && (
            <ViewFileImage close={() => setShowViewImage(null)} file={showViewImage} selectedDoc={showViewImage} />
          )}
          {/* {isDownload && (
            <FilesWrapper>
              <div className="flex justify-end items-center relative h-full">
                <div className="download-toast  radius-full h-auto pxy-3 absolute ">
                  <div className="justify-between items-center">
                    <div className="flex items-center gap-3">
                      <div className="flex items-center justify-center border downloading">
                        <DownloadIcon height={14} width={14} />
                      </div>
                      <span className="font-14 inter-400-text color-neutral-900">
                        {t('DOWNLOADING')} {checked?.length ? checked?.length : singleFileName} {t('FILES')}
                      </span>
                    </div>
                    <CrossIcon height={16} width={16} className="cursor" />
                  </div>
                  <div className="flex items-center gap-2 mt-2">
                    <div className="progress-bar ">
                      <div className="progress" style={{ width: `${progress}%` }}>
                        {' '}
                      </div>
                    </div>
                    <p className="font-12 inter-400-text ">{progress}%</p>
                  </div>
                </div>
              </div>
            </FilesWrapper>
          )} */}
        </Fragment>
      )}
    </div>
  );
};

const FileListWrapper = styled.div`
  border-radius: 6px;

  .header-container,
  .data-container {
    display: grid;
    grid-template-columns: 24px minmax(180px, 1fr) minmax(180px, 1fr) 140px 60px 40px;
    column-gap: 12px;
    padding: 12px 24px;
  }

  .files-menue {
    .custom-tooltip {
      min-width: 157px !important;
    }
  }

  .edit-input {
    border: 1px solid #afd8fe;
    &:focus {
      box-shadow: 0px 0px 0px 4px #d3eafe;
    }
  }

  .header-container {
    background-color: ${({ theme }) => theme.natural_50};
  }

  .file-img {
    border-radius: 2px;
    margin-right: 8px;
    width: 24px;
    height: 24px;
    border: 0.5px solid #e5e5e5;
  }

  .file-box-border {
    border: 0.25px solid #e5e5e5 !important;
  }
`;

const FileListItemWrapper = styled(motion.div)`
  min-height: 58px;
  /* max-height: 58px; */

  &:hover {
    background: ${({ theme }) => theme.natural_50};
  }

  .file-user-tooltip {
    .custom-tooltip {
      min-width: auto;
      padding: 8px 12px;
      background-color: #000;
      filter: drop-shadow(0px 0px 6px #1018281a);
    }
  }
`;

const PropertyFileUploadWrapper = styled.div`
  .off-droparea {
    border-color: ${({ theme }) => theme.natural_300};
    background-color: ${({ theme }) => theme.natural_50};
  }
  .on-droparea {
    border-color: ${({ theme }) => theme.primary_500};
    background-color: ${({ theme }) => theme.primary_50};
  }
`;

const FilesWrapper = styled.div`
  .download-toast {
    width: 415px;
    border-radius: 6px;
    bottom: 12px;
    right: -12px;
    z-index: 99;
    background-color: #fff;
    box-shadow: 0px 20px 25px -5px rgba(16, 24, 40, 0.1), 0px 8px 10px -6px rgba(16, 24, 40, 0.1);
    .downloading {
      width: 28px;
      height: 28px;
      border-radius: 6px;
      background: ${({ theme }) => theme.natural_100};
    }
  }
  .progress-bar {
    width: 100%;
    height: 10px;
    background-color: #ddd;
    border-radius: 5px;
  }

  .progress {
    height: 100%;
    background-color: ${({ theme }) => theme.primaryBlue};
    border-radius: 5px;
    transition: width 0.3s ease-in-out;
  }
`;

const FileUploadWrapper = styled.div`
  .progress-container {
    height: 4px;
    background-color: ${({ theme }) => theme.natural_200};
    border-radius: 100px;

    .progress-bar {
      background-color: ${({ theme }) => theme.primary_500};
      border-radius: 100px;
    }
  }

  .uploaded-file {
    .uploading {
      height: 48px;
      width: 48px;
      border-radius: 6px;
    }

    .uploaded {
      height: 24px;
      width: 24px;
      border-radius: 2px;
    }
  }

  .edit-file-input {
    .input-element {
      height: 24px;
      padding: 2px 8px;
    }
  }

  .tooltip {
    padding: 4px 12px;
    border-radius: 4px;
  }
`;

export default PropertyFiles;
