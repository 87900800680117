import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as DownIcon } from '../../../assets/images/arrow-right.svg';
import IconContainer from '../icon-container';

function Accordion(props) {
  const { children, title, titleClass = '', headerClass = '', icon, showIcon, contentClass } = props;
  const [active, setActive] = useState(false);
  const content = useRef(null);

  return (
    <AccordionWrapper className="flex-col">
      <div
        className={classNames(
          'no-select cursor pb-3 flex items-center justify-between',
          !active && 'border-bottom',
          headerClass,
        )}
        onClick={() => setActive(!active)}>
        <p
          className={classNames(
            'inter-400-text opacity-80 font-14 flex items-center gap-2',
            titleClass,
            active && 'color-neutral-900',
          )}>
          {showIcon && (
            <IconContainer
              Icon={showIcon}
              iconColor="Neutral_700"
              backgroundColor="transparent"
              iconContainerClassname="p-0"
              iconWidth={20}
              iconHeight={20}
            />
          )}
          <p>{title}</p>
        </p>
        <div className={classNames('flex items-center justify-center', active ? 'rotate-270' : 'rotate-90')}>
          {icon ? icon : <DownIcon color="#404040" height={16} width={16} />}
        </div>
      </div>
      <div
        ref={content}
        style={{ height: active ? content.current.scrollHeight : 0 }}
        className={classNames('transition overflow-hidden', active && contentClass)}>
        {children}
      </div>
    </AccordionWrapper>
  );
}

const AccordionWrapper = styled.div``;

export default Accordion;
