import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as NoDocumentIcon } from '../../assets/icons/empty-document.svg';
import InfiniteScrollV2 from '../common/infinite-scroll-v2';
import NoData from '../common/no-data';
import SkeletonTransition from '../common/skeleton-transition';
import TableHeader from '../common/table-header';
import QuoteTemplateListItem from './quote-template-list-items';

const QuoteTemplatetList = ({
  debouncedSearch = '',
  fetchMoreData,
  quotesTemplatetList,
  handleDeleteQuoteTemplate,
  redirectQuoteTemplateDetails,
  showLoading,
}) => {
  const { t } = useTranslation();

  const quoteTemplateListTableHeaders = useMemo(() => {
    return [
      { name: t('NAME'), key: 'NAME' },
      { name: t('DESCRIPTION'), key: 'DESCRIPTION' },
      { name: '', key: 'STATUS' },
      { name: '', key: 'MENU' },
    ];
  }, []);

  return (
    <QuoteTemplateListWrapper className="flex-column flex-1 p-6 w-full radius-1 overflow-hidden">
      <SkeletonTransition
        loading={showLoading}
        loaderClassName="item flex items-center justify-center flex-1 mb-6"
        height={'100%'}
        containerClassName="line-height-1 h-full w-full">
        {quotesTemplatetList?.length > 0 ? (
          <div className="w-full card radius-1_5 border overflow-scroll">
            <div className="flex-column w-fit-content min-w-full h-full">
              <TableHeader headers={quoteTemplateListTableHeaders} />
              <InfiniteScrollV2 hasMore={!quotesTemplatetList} fetchMoreData={fetchMoreData}>
                {(quotesTemplatetList || []).map((quoteTemplateData, index) => (
                  <QuoteTemplateListItem
                    templateList={quoteTemplateData}
                    key={index}
                    handleDeleteQuoteTemplate={handleDeleteQuoteTemplate}
                    redirectQuoteTemplateDetails={redirectQuoteTemplateDetails}
                  />
                ))}
              </InfiniteScrollV2>
            </div>
          </div>
        ) : debouncedSearch ? (
          <NoData className="flex-1" title={t('NO_RESULTS_FOUND')} description={t('NO_RESULTS_DESCRIPTION')} />
        ) : (
          <div className="flex-column flex-1 items-center justify-center">
            <NoData
              title={t('NO_QUOTE_TEMPLATE')}
              description={t('NO_QUOTE_TEMPLATE_HERE')}
              className="search-quote-tempalte"
              EmptyIcon={NoDocumentIcon}
              iconClassName="relative"
            />
          </div>
        )}
      </SkeletonTransition>
    </QuoteTemplateListWrapper>
  );
};

const QuoteTemplateListWrapper = styled.div`
  border-radius: 6px;

  .header-container {
    height: 52px;
    background-color: ${({ theme }) => theme.natural_50};
  }

  .header-container,
  .data-container {
    display: grid;
    grid-template-columns: minmax(200px, 1fr) minmax(200px, 3fr) 70px 80px;
    column-gap: 48px;
    padding: 16px 24px;
  }

  .search-quote-tempalte {
    max-width: 400px;
  }
`;

export default QuoteTemplatetList;
