import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import uuid from 'react-uuid';
import styled from 'styled-components';
import { ReactComponent as AlertIcon } from '../assets/icons/AlertIcon.svg';
import { ReactComponent as CrossIcon } from '../assets/icons/CrossIcons.svg';
import Button from '../components/common/button/button';
import { OrganisationContext } from '../context/organisationContext';
import { bytesToSize, initModal } from '../helpers/utils';
import { deleteFiles } from '../store/features/propertySlice';
import { addToast } from '../store/features/toastSlice';

const JobsFileAction = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { modal, setModal } = useContext(OrganisationContext);
  const {
    title,
    description,
    file_id,
    fileData,
    onDelete,
    onSuccess,
    displayFile = false,
    isMulti = false,
    imageExtensions = [],
  } = modal.content;

  const [loading, setLoading] = useState(false);

  const handleDone = async () => {
    if (onDelete) {
      onDelete();
      setModal(initModal);
      return;
    }
    if (isMulti) {
      setLoading(true);
      await Promise.all(fileData.map(file => dispatch(deleteFiles({ file_id: file }))))
        .then(data => {
          setModal(initModal);
          setLoading(false);
          onSuccess && onSuccess();
          dispatch(
            addToast({
              error: false,
              title: t('FILES_DELETED'),
              text: t('SUCCESSFULLY_DELETED_FILES', { value: fileData?.length }),
            }),
          );
        })
        .catch(response => {
          const { data } = response?.response;
          dispatch(
            addToast({
              error: true,
              text: data?.error_description ? data?.error_description : t('ERROR_WHILE_DELETING_FILES'),
              id: uuid(),
            }),
          );
          setLoading(false);
        });
    } else {
      setLoading(true);
      dispatch(deleteFiles({ file_id: file_id }))
        .then(data => {
          setModal(initModal);
          setLoading(false);
          onSuccess && onSuccess(file_id);
          dispatch(
            addToast({
              error: false,
              title: t('FILE_DELETED'),
              text: t('SUCCESSFULLY_DELETED_FILE', { value: fileData?.name }),
            }),
          );
        })
        .catch(response => {
          const { data } = response?.response;
          dispatch(
            addToast({
              error: true,
              text: data?.error_description ? data?.error_description : t('ERROR_WHILE_DELETING_FILE'),
              id: uuid(),
            }),
          );
          setLoading(false);
        });
    }
  };

  return (
    <CSSTransition appear classNames="popup-fade " in timeout={300}>
      <FilesActionWrapper className="sidebar-close-ignore">
        <div className="flex-column content-wrapper items-center relative w-full">
          <AlertIcon />
          <CrossIcon className="absolute right-0 cursor" onClick={() => setModal(initModal)} />
          <div className="flex-column gap-2 items-center justify-start w-full">
            <label className="inter-500-text font-18 text-center">{title}</label>
            <p className="inter-400-text  text-center">{description}</p>
          </div>
        </div>

        {!isMulti && (
          <div className="w-full flex-column gap-2">
            {displayFile && isMulti ? (
              fileData.map(file => <DisplayFile key={file.id} imageExtensions={imageExtensions} file={file} />)
            ) : (
              <DisplayFile file={fileData} imageExtensions={imageExtensions} />
            )}
          </div>
        )}

        <div className="action-wrapper flex-column justify-center w-full gap-3">
          <Button
            className={`delete-btn negative`}
            label={t('DELETE')}
            disabled={loading}
            loading={loading}
            onClick={() => handleDone()}
            size="large"
            borderRadius="100px"
          />
          <Button
            className={`cancel-btn default`}
            label={t('CANCEL')}
            disabled={loading}
            onClick={() => setModal(initModal)}
            size="large"
            borderRadius="100px"
          />
        </div>
      </FilesActionWrapper>
    </CSSTransition>
  );
};

const DisplayFile = ({ file, imageExtensions }) => (
  <div className="border px-4 py-3 radius-1_5 w-full flex gap-3">
    <div className="flex items-center uploaded-file">
      {imageExtensions.includes(file?.media?.format) && (
        <img className={'w-24px h-24px radius-1_5'} src={file?.media?.url} />
      )}
    </div>
    <div className="flex items-center justify-between">
      <div className="flex inter-400-text line-height-20 items-center gap-1">
        <p className="natural-900-text one-line file-name">{file?.name}</p>
        {file?.size && <p className="natural-400-text font-12 flex-shrink-0">{`(${bytesToSize(file?.size)})`}</p>}
      </div>
    </div>
  </div>
);

export const FilesActionWrapper = styled.div`
  width: 384px;
  padding: 24px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  flex: 1 0 0;

  .file-name {
    max-width: 200px;
  }

  .content-wrapper {
    gap: 20px;
    label {
      color: #171717;
    }
    p {
      color: #737373;
    }
  }
  .action-wrapper {
    button {
      display: flex;
      padding: 12px 20px;
      justify-content: center;
      font-size: 16px;
      line-height: 24px;
      align-items: center;
      gap: 8px;
      align-self: stretch;
    }
    .delete-btn {
      background: #ef4444;
    }
    .cancel-btn {
      border: 1px solid #e5e5e5;
      background: #fff;
      label {
        color: #404040;
      }
    }
  }
`;

export default JobsFileAction;
