import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { ReactComponent as AlertIcon } from '../../assets/icons/alert-blue.svg';
import { ReactComponent as MailIcon } from '../../assets/images/mail.svg';
import { ReactComponent as MobileIcon } from '../../assets/images/mobile.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/toast-close.svg';
import Button from '../../components/common/button/button';
import IconContainer from '../../components/common/icon-container';
import { OrganisationContext } from '../../context/organisationContext';
import { initModal } from '../../helpers/utils';

const SendQuotePopup = () => {
  const { t } = useTranslation();

  const { modal, setModal } = useContext(OrganisationContext);
  const { onSuccess, sendQuoteToRecipient, recipient } = modal.content;
  const handleSubmit = async () => {
    await sendQuoteToRecipient();
    onSuccess && onSuccess();
  };

  return (
    <div className="relative">
      <CSSTransition appear classNames="popup-fade" in timeout={300}>
        <QuotesActionWrapper className="w-full p-6">
          <div>
            <div className="mb-5">
              <div className="w-full">
                <IconContainer
                  Icon={AlertIcon}
                  iconColor="natural_900"
                  backgroundColor="transparent"
                  iconContainerClassname="p-0"
                  iconHeight={48}
                  iconWidth={48}
                />
              </div>
              <div className="close-icon-wrapper" onClick={() => setModal(initModal)}>
                <IconContainer
                  Icon={CloseIcon}
                  iconColor="natural_900"
                  iconClassName="cursor"
                  backgroundColor="transparent"
                  iconContainerClassname="p-0"
                />
              </div>
            </div>
            <div>
              <p className="text-center mb-5 font-18 fw-500 color-neutral-900">{t('SEND_QUOTE_CONFIRMATION')}</p>
            </div>
            <div className="flex-column p-4 radius-1_5 bg-natural-50 gap-1 mb-6">
              <p className="inter-500-text line-height-24 natural-900-text">
                {recipient?.contact?.contact_type === 'COMPANY' ? recipient?.contact?.company_name : recipient.name}
              </p>
              {recipient?.phones?.length > 0 && (
                <div className="flex items-center gap-6px">
                  <MobileIcon className="natural-500-text" />
                  <span className="line-height-20 natural-900-text inter-400-text">
                    {(recipient.phones[0]?.country_code || '') + (recipient.phones[0]?.value || '')}
                  </span>
                </div>
              )}
              {recipient.emails?.length > 0 && (
                <div className="flex items-center gap-6px">
                  <MailIcon className="natural-500-text" />
                  <span className="line-height-20 natural-900-text inter-400-text">
                    {recipient.emails[0]?.value || ''}
                  </span>
                </div>
              )}
            </div>
            <div>
              <div className="mb-3">
                <Button className={`primary flex-1`} label={t('SEND')} onClick={handleSubmit} size="large" />
              </div>
              <div>
                <Button
                  className={`default flex-1`}
                  label={t('CANCEL')}
                  size="large"
                  onClick={() => setModal(initModal)}
                />
              </div>
            </div>
          </div>
        </QuotesActionWrapper>
      </CSSTransition>
    </div>
  );
};

export const QuotesActionWrapper = styled.div`
  max-width: 420px;
  .close-icon-wrapper {
    position: absolute;
    padding: 5px;
    top: 24px;
    right: 24px;
  }
`;

export default SendQuotePopup;
