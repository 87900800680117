import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as CrossIcon } from '../../assets/icons/CrossIcons.svg';
import { ReactComponent as DownIcon } from '../../assets/icons/down-arrow.svg';
import { ReactComponent as RightIcon } from '../../assets/icons/right-Icons.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { getFormattedDate } from '../../helpers/utils';
import { addToast } from '../../store/features/toastSlice';
import Button from '../common/button/button';
import DateSelectorElement from '../common/date-selector/date-selector-element';
import IconContainer from '../common/icon-container';
import CustomTooltip from '../common/tooltip-new';
import TemplateQuoteEdit from '../quote-details/TemplateQuoteEdit';

const QuoteHeader = ({
  isQuoteEditable = true,
  quoteDetailsInitial,
  setQuoteDetailsInitial,
  notEditableQuoteInfo = '',
  defaultTemplate,
  showActionBtn = true,
  onCancel = () => {},
  onSave = () => {},
  loading = false,
  className = '',
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { hasPermission } = useContext(OrganisationContext);

  const [isEditKey, setEditKey] = useState('');
  const [quoteDetailsInfo, setQuoteDetailsInfo] = useState(quoteDetailsInitial || {});

  const { code, description, created_on, expiry_date, issued_date, quote_template } = quoteDetailsInfo || {};

  const onUpdateQuote = (key, value) => {
    setQuoteDetailsInitial({ ...quoteDetailsInitial, [key]: value });
    setEditKey(null);
  };

  const handleEnterKeyPress = (event, key, value) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      onUpdateQuote(key, value);
    }
  };

  const handleInputFocus = event => {
    event.target.select();
  };

  const renderItem = (value, key, labelValue, noValueData = '', isEditable = true) => {
    return (
      <div
        className={classNames(
          'flex items-center col-gap-3 info-details-quote',
          key === 'DESCRIPTION' && 'flex-column row-gap-1 items-baseline py-2 overflow-hidden',
        )}>
        <label
          className={classNames(
            'inter-400-text natural-400-text font-12',
            isEditable && 'px-1',
            key !== 'DESCRIPTION' && 'font-14',
            key === 'DESCRIPTION' && 'one-line',
          )}>
          {labelValue}:
        </label>
        {!isQuoteEditable ? (
          <CustomTooltip
            wrapperClassName="quote-tooltip h-fit-content"
            id="quote-tooltip"
            place="bottom-start"
            content={<label className="flex col-gap-1 items-center">{notEditableQuoteInfo}</label>}>
            <label className={classNames('inter-400-text natural-900-text px-1', key !== 'DESCRIPTION' && 'one-line')}>
              {value}
            </label>
          </CustomTooltip>
        ) : (
          <div
            className={classNames(
              'flex ites-center radius-1_5',
              isEditable && isQuoteEditable && 'cursor quote-detail-item',
              key === 'DESCRIPTION' && 'w-full',
            )}
            onClick={() => isEditable && isQuoteEditable && setEditKey(key)}>
            <label
              onFocus={() => isEditable && isQuoteEditable && setEditKey(key)}
              tabIndex="0"
              className={classNames(
                'inter-400-text flex items-center one-line',
                value ? 'natural-900-text' : 'natural-400-text',
                key === 'DESCRIPTION' && 'block',
              )}>
              {value || noValueData}
            </label>
          </div>
        )}
      </div>
    );
  };

  const editContent = (editKey, value, labelValue, placeholder, type, valueKey) => {
    const key = valueKey || editKey;
    return (
      <div className={classNames('flex-column w-full input-container relative')}>
        <label className="inter-400-text natural-400-text font-12 mb-1">{labelValue}</label>
        <input
          className={classNames('inter-400-text natural-900-text one-line input')}
          value={value}
          autoFocus
          type={type}
          placeholder={placeholder}
          onFocus={handleInputFocus}
          onKeyDown={event => handleEnterKeyPress(event, key, value)}
          onBlur={() => onUpdateQuote(key, value)}
          onChange={({ target: { value } }) => setQuoteDetailsInfo({ ...quoteDetailsInfo, [key]: value })}
        />
        <div className="w-full flex justify-end">
          <div className="flex justify-center items-end radius-3 quote-update-action mt-1 border pxy-2 gap-2_5 absolute">
            <IconContainer
              Icon={CrossIcon}
              onClick={() => {
                setEditKey(null);
                setQuoteDetailsInfo({ ...quoteDetailsInitial });
              }}
              iconColor="natural_500"
              iconContainerClassname="bg-white radius-50-percent border cursor"
            />
            <IconContainer
              iconContainerClassname="bg-primary-500 radius-50-percent cursor"
              Icon={RightIcon}
              iconColor="white"
              onClick={() => onUpdateQuote(key, value)}
            />
          </div>
        </div>
      </div>
    );
  };

  const onQuoteExpiryChange = date => {
    if (issued_date && date < issued_date) {
      dispatch(
        addToast({
          error: true,
          text: t('ERROR_UPDATE_EXPIRY_ON_DATE'),
          id: nanoid(),
        }),
      );
      return;
    }
    onUpdateQuote('expiry_date', date);
  };

  useEffect(() => {
    setQuoteDetailsInfo(quoteDetailsInitial);
  }, [quoteDetailsInitial]);

  return (
    <QuoteHeaderWrapper className={classNames('flex-column row-gap-3', className)}>
      <div className="items-center quote-info-sec-top ">
        <div className="flex-column row-gap-1">
          {renderItem(code ?? '', 'CODE', t('QUOTE_NUMBER'), t('EXAMPLE_EH_0001'), false)}
        </div>
        <div className="line-vertical"></div>
        <div className="flex items-center col-gap-3 info-details-quote">
          <label className="inter-400-text natural-400-text font-14">{t('ISSUED')}:</label>
          <label className="inter-400-text natural-900-text">
            {!issued_date ? (
              <label className="inter-400-text natural-400-text">{t('NOT_ISSUED')}</label>
            ) : (
              getFormattedDate(issued_date, 'DD/MM/YYYY', false)
            )}
          </label>
        </div>
        <div className="line-vertical"></div>
        <div
          className="flex items-center col-gap-3 info-details-quote"
          onFocus={() => setEditKey('EXPIRY')}
          onBlur={() => setEditKey(null)}>
          <label className="inter-400-text natural-400-text font-14 ">{t('EXPIRY')}:</label>
          {isQuoteEditable ? (
            <DateSelectorElement
              selectedDate={expiry_date}
              isCalendarIcon={false}
              isOpen={isEditKey === 'EXPIRY'}
              useParentFocus={true}
              minStartDate={created_on}
              setSelectedDate={date => onQuoteExpiryChange(date)}
              className="quote-date-field"
              onCalendarClose={() => setEditKey(null)}
            />
          ) : (
            <CustomTooltip
              wrapperClassName="quote-tooltip h-fit-content"
              id="quote-tooltip"
              place="bottom-start"
              content={<label className="flex col-gap-1 items-center">{notEditableQuoteInfo}</label>}>
              <label className="inter-400-text natural-900-text">
                {getFormattedDate(expiry_date, 'DD/MM/YYYY', false)}
              </label>
            </CustomTooltip>
          )}
        </div>
        {showActionBtn && (
          <div className="flex items-center col-gap-4 justify-end">
            <Button
              label={t('CANCEL')}
              size="medium"
              className="default specified-width"
              width="80px"
              onClick={onCancel}
              disabled={loading}
            />
            <Button
              label={t('SAVE')}
              size="medium"
              className="primary specified-width"
              width="80px"
              onClick={onSave}
              loading={loading}
            />
          </div>
        )}
      </div>
      <div className=" items-center quote-info-sec-bottom">
        <div className="flex-column row-gap-1 w-full">
          {isEditKey === 'DESCRIPTION'
            ? editContent(
                'DESCRIPTION',
                description,
                t('DESCRIPTION'),
                t('DESCRIPTION_PLACEHOLDER'),
                'text',
                'description',
              )
            : renderItem(
                description ?? '',
                'DESCRIPTION',
                t('DESCRIPTION'),
                t('NO_DESCRIPTION'),
                hasPermission('QUOTES_MANAGE') && isQuoteEditable,
              )}
        </div>
        <div id="template-wrapper" className="relative">
          {isEditKey === 'EDIT_TEMPLATE' ? (
            <TemplateQuoteEdit
              setEditKey={setEditKey}
              onUpdateQuote={onUpdateQuote}
              quoteDetailsInitial={quoteDetailsInitial}
              quote_template={quote_template}
            />
          ) : (
            <div className="flex-column row-gap-1">
              <QuoteTemplateWrapper className="flex-column col-gap-3 radius-1_5">
                <label className="inter-400-text natural-400-text font-14 ml-1">{t('TEMPLATE')}:</label>
                <div className="flex items-center col-gap-2 flex-grow">
                  {isQuoteEditable && hasPermission('QUOTES_MANAGE') ? (
                    <div
                      className="flex col-gap-2 edit-template-quote"
                      tabIndex="0"
                      onFocus={() => setEditKey('EDIT_TEMPLATE')}
                      onClick={() => setEditKey('EDIT_TEMPLATE')}>
                      <p
                        className={classNames(
                          'inter-400-text flex-1 one-line',
                          quote_template?.name || defaultTemplate?.name ? 'natural-900-text' : 'natural-400-text',
                        )}>
                        {quote_template?.name || defaultTemplate?.name || t('NO_TEMPLATE')}
                      </p>
                      <DownIcon height={16} width={16} className="o0 color-gray-secondary" />
                    </div>
                  ) : (
                    <CustomTooltip
                      id="quote-tooltip"
                      place="bottom-start"
                      content={<label className="flex col-gap-1 items-center">{notEditableQuoteInfo}</label>}>
                      <div className="flex pr-6">
                        <p
                          className={classNames(
                            'inter-400-text natural-400-text',
                            quote_template?.name && defaultTemplate?.name && 'natural-900-text',
                          )}>
                          {quote_template?.name || defaultTemplate?.name || t('NO_TEMPLATE')}
                        </p>
                      </div>
                    </CustomTooltip>
                  )}
                </div>
              </QuoteTemplateWrapper>
            </div>
          )}
        </div>
      </div>
    </QuoteHeaderWrapper>
  );
};

const QuoteHeaderWrapper = styled.div`
  .quote-detail-item {
    height: 100%;
    padding: 4px;
    min-height: 24px;

    &:hover {
      background-color: ${({ theme }) => theme.natural_100};
    }
  }

  .quote-update-action {
    background-color: ${({ theme }) => theme.white};
    z-index: 999;
    box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1);
  }

  @media (max-width: 1024px) {
    .quote-date-field {
      padding: 0;
    }
  }

  .quote-info-sec-top {
    display: grid;
    grid-template-columns: minmax(100px, 220px) 10px minmax(100px, 150px) 10px minmax(100px, 180px) minmax(240px, 1fr);
    column-gap: 4px;
  }

  .quote-info-sec-bottom {
    display: grid;
    grid-template-columns: minmax(116px, 6fr) minmax(100px, 3fr);
    column-gap: 16px;
  }

  .show-calendar {
    box-shadow: 0px 0px 0px 4px ${({ theme }) => theme.focus_border} !important;
    label {
      border-radius: 6px;
    }
  }

  .line-vertical {
    width: 1px;
    height: 24px;
    background-color: ${({ theme }) => theme.natural_200};
  }

  .quote-date-field.date-value {
    border: none;
    min-height: auto;
    border-radius: 0%;
    box-shadow: none;
    padding: 0 4px;
    min-height: 24px;
    border-radius: 6px;
    &:hover {
      background-color: ${({ theme }) => theme.natural_100};
    }
  }

  .quote-template-wrapper {
    .contact-info {
      display: flex;
      flex-direction: column;
      align-items: start;
      row-gap: 4px;

      .template-edit-dropdown {
        width: 100% !important;
      }
    }
  }
`;

const QuoteTemplateWrapper = styled.div`
  .edit-template-quote {
    width: 322px;
    height: 32px !important;
    padding: 2px 8px;
    border-radius: 6px;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.natural_100} !important;
      .o0 {
        opacity: 1;
      }
    }
  }
`;

export default QuoteHeader;
