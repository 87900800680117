import { motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as AddIcon } from '../../assets/icons/add-icon.svg';
import { ReactComponent as EmptyIcon } from '../../assets/icons/empty-document.svg';
import { ReactComponent as NoSearchIcon } from '../../assets/icons/no-search-result.svg';
import { ReactComponent as HorizontalDots } from '../../assets/images/HorizontalDots.svg';
import { OrganisationContext } from '../../context/organisationContext';
import useDebounce from '../../helpers/useDebounceHook';
import { capitalizeFirstLetterOfWords, formatText, getFormattedDate, getFormattedNumber } from '../../helpers/utils';
import { getQuotesList } from '../../store/features/quotesSlice';
import { addToast } from '../../store/features/toastSlice';
import Button from '../common/button/button';
import InputSearch from '../common/input-search';
import MenuV2 from '../common/menu/menu-v2';
import NoData from '../common/no-data';
import SkeletonTransition from '../common/skeleton-transition';
import Status from '../common/status';

const PropertQuotes = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { setModal, hasPermission } = useContext(OrganisationContext);

  const { workitemDetails } = useSelector(state => state.board);
  const engagement_id = workitemDetails?.engagement?.id;

  const { propertyDetails } = useSelector(state => state.property);

  const [quoteList, setQuoteList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const fetchQuotesData = () => {
    setLoading(true);
    dispatch(
      getQuotesList({
        engagement_id: engagement_id,
        params: { search: debouncedSearch },
      }),
    )
      .then(data => {
        setQuoteList(data);
      })
      .catch(() => dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_QUOTES_LIST') })))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchQuotesData();
  }, [debouncedSearch]);

  const onDeleteQuotes = quote => {
    setModal({
      type: 'delete-quote',
      content: {
        quote: quote,
        title: t('REMOVE_THIS_QUOTE'),
        description: t('REMOVE_THIS_QUOTE_DESCRIPTION'),
        onSuccess: fetchQuotesData,
      },
    });
  };

  const onAddQuote = () => {
    navigate(`quotes/new`);
  };

  const onQuoteClick = quote => {
    navigate(`quotes/${quote?.id}`);
  };

  return (
    <PropertQuotesWrapper
      key={'property-quotes'}
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}
      className="pxy-6 flex-column row-gap-8 flex-1 overflow-hidden">
      <div className="flex justify-between ">
        <div className="flex align-center">
          <InputSearch placeholder={t('SEARCH')} value={search} onChange={setSearch} />
        </div>
        {hasPermission('QUOTES_MANAGE') && (
          <Button
            fontSize="14px"
            size="average"
            borderRadius="100px"
            className="primary specified-width px-4 py-2_5 ml-1_5"
            afterIcon={<AddIcon className="white-text mr-0" height={16} width={16} />}
            label={t('ADD_QUOTE')}
            onClick={onAddQuote}
          />
        )}
      </div>
      <div className="w-full flex-column row-gap-6 flex-1 overflow-hidden">
        <SkeletonTransition
          loading={loading}
          loaderClassName="item w-full flex items-center justify-center flex-1 mb-6"
          height={'50vh'}
          containerClassName="line-height-1 flex-1"
          baseColor="#CDCDCD"
          highlightColor="#E8E8E8">
          {quoteList.length > 0 ? (
            <motion.div
              initial={{ opacity: 0.3, y: 10 }}
              animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}
              className="quotes-wrapper flex-column row-gap-5 overflow-scroll">
              {quoteList?.map(quote => (
                <div className="flex-column p-4 radius-3 border" key={quote.id} onClick={() => onQuoteClick(quote)}>
                  <div className="flex items-center justify-between pb-3 border-bottom">
                    <div>
                      <p>
                        <span className="inter-500-text natural-900-text font-14">{quote?.code}</span>
                        <span className="inter-400-text natural-500-text font-14"> - {quote?.subtitle}</span>
                        <span className="inter-400-text natural-500-text font-14">
                          {quote?.salesperson?.name || ''}
                        </span>
                      </p>
                    </div>
                    <div className="flex items-center gap-3 h-24px">
                      <div>
                        <p className="inter-400-text natural-500-text font-12">
                          {t('CREATED')}:{' '}
                          <span className="inter-400-text natural-900-text font-12">
                            {getFormattedDate(quote.created_on, 'DD/MM/YYYY', false)}
                          </span>
                        </p>{' '}
                      </div>
                      <div>
                        <p className=" inter-400-text natural-500-text font-12">
                          {t('EXP_DATE')}:{' '}
                          <span className="inter-400-text natural-900-text font-12">
                            {getFormattedDate(quote.expiration_date, 'DD/MM/YYYY', false)}
                          </span>
                        </p>{' '}
                      </div>
                      <div className="flex gap-1 cursor">
                        <Status
                          withDot={false}
                          withDottedBorder={false}
                          status={quote?.status}
                          statusText={capitalizeFirstLetterOfWords(formatText(quote?.status))}
                        />
                      </div>
                      <MenuV2
                        Icon={HorizontalDots}
                        menuList={[
                          { name: t('DETAILS'), onClick: () => onQuoteClick(quote) },
                          {
                            name: t('DELETE'),
                            onClick: () => onDeleteQuotes(quote),
                          },
                        ]}
                      />
                    </div>
                  </div>
                  <div className="pt-4 flex items-center justify-between  gap-4 quotes-value">
                    <div className="bg-natural-100 radius-1_5 h-28px py-1 px-2 flex justify-between items-center w-full">
                      <p className="inter-400-text natural-500-text font-12">{`${t('NET')}:`}</p>
                      <p className="inter-400-text natural-900-text font-12">
                        {getFormattedNumber(quote.total_net || '0')}
                      </p>
                    </div>
                    <div className="bg-natural-100 radius-1_5 h-28px py-1 px-2 flex justify-between items-center w-full">
                      <p className="inter-400-text natural-500-text font-12">{`${t('TAX')}:`}</p>
                      <p className="inter-400-text natural-900-text font-12">
                        {getFormattedNumber(quote?.total_tax || '0')}
                      </p>
                    </div>
                    <div className="bg-natural-100 radius-1_5 h-28px py-1 px-2 flex justify-between items-center w-full">
                      <p className="inter-400-text natural-500-text font-12">{`${t('TOTAL_DISCOUNT')}:`}</p>
                      <p className="inter-400-text natural-900-text font-12">
                        {getFormattedNumber(quote?.total_discount || '0')}
                      </p>
                    </div>
                    <div className="bg-natural-100 radius-1_5 h-28px py-1 px-2 flex justify-between items-center w-full">
                      <p className="inter-400-text natural-500-text font-12">{`${t('TOTAL')}:`}</p>
                      <p className="inter-400-text natural-900-text font-12">
                        {getFormattedNumber(quote?.total_amount || '0')}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </motion.div>
          ) : debouncedSearch ? (
            <NoData
              title={t('NO_RESULTS_FOUND')}
              description={t('NOTHING_FOUND_FOR_REQUEST')}
              className="mt-12 h-full"
              EmptyIcon={NoSearchIcon}
              iconClassName="mb-6 relative"
            />
          ) : (
            <NoData
              title={t('NO_QUOTES')}
              description={t('NO_QUOTES_AVAILABLE')}
              className="mt-12 h-full"
              EmptyIcon={EmptyIcon}
              iconClassName="relative"
            />
          )}
        </SkeletonTransition>
      </div>
    </PropertQuotesWrapper>
  );
};

export const PropertQuotesWrapper = styled(motion.div)``;

export default PropertQuotes;
