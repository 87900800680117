import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as CrossIcon } from '../../assets/icons/CrossIcons.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/add-icon.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit-pensil.svg';
import { ReactComponent as RightIcon } from '../../assets/icons/right-Icons.svg';
// import IconContainer from '../../components/common/icon-container';
import Button from '../../components/common/button/button';
import InputElement from '../../components/common/input';
import Dropdown from '../../components/common/select-dropdown';
import { getErrorDescription, uppercase } from '../../helpers/utils';
import { getOrganizationServices } from '../../store/features/activitiesSlice';
import { addToast } from '../../store/features/toastSlice';
import { updateUsersServices } from '../../store/features/userManagementSlice';

const RenderServiceItem = ({ serviceData }) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center justify-between gap-2" key={serviceData.name}>
      <div>
        <label className="inter-400-text font-14 line-height-20 natural-900-text">{serviceData.name}</label>
      </div>
      <div className="flex items-center col-gap-2">
        <label className="inter-400-text font-14 line-height-20 natural-500-text">{t('DAILY_CAPACITY')}:</label>
        <p className="inter-400-text font-14 line-height-20 natural-900-text">{serviceData.daily_capacity || '-'}</p>
      </div>
    </div>
  );
};

const UserServices = ({
  editKey,
  setEditKey,
  services = [],
  user_id,
  userDetails,
  setUserDetails,
  handleUpdateServices,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { user } = useSelector(state => state);
  const { userOrganization } = user;

  const [serviceList, setServiceList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const alreadyAddedService = services?.map(s => s?.id) || [];
  const filteredServiceList = serviceList.filter(service => !alreadyAddedService.includes(service.id));

  const fetchOrganizationServices = organizations_id => {
    dispatch(getOrganizationServices({ organization_id: organizations_id }))
      .then(data => {
        setServiceList(data);
      })
      .catch(() => {
        setServiceList([]);
        dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_SERVICE_LIST'), id: nanoid() }));
      });
  };

  useEffect(() => {
    if (userOrganization?.id) {
      fetchOrganizationServices(userOrganization?.id);
    }
  }, [userOrganization?.id]);

  const onAddService = () => {
    setUserDetails({ ...userDetails, services: [...services, { service_id: nanoid() }] });
  };

  const onRemoveService = service => {
    const updatedServices = services.filter(s => s.service_id !== service.service_id);
    setUserDetails({ ...userDetails, services: updatedServices });
  };

  const onUpdateService = service => {
    const updatedServices = services.map(s => (s.service_id === service.service_id ? service : s));
    setUserDetails({ ...userDetails, services: updatedServices });
  };

  const onUpdateUserService = async () => {
    const allServiceWithDailyCapacity = services.every(service => service?.id && service?.daily_capacity);
    if (!allServiceWithDailyCapacity) {
      dispatch(addToast({ error: true, text: t('SERVICE_DAILY_CAPACITY_IS_REQUIRED'), id: nanoid() }));
      return;
    }
    const request = services?.map(service => ({
      service: {
        id: service.id,
      },
      daily_capacity: service?.daily_capacity,
    }));

    setIsLoading(true);
    dispatch(updateUsersServices({ user_id: user_id, request: request }))
      .then(data => {
        dispatch(addToast({ error: false, text: t('USER_VALUE_UPDATED', { value: 'services' }) }));
        setEditKey(null);
        handleUpdateServices(data);
      })
      .catch(error => {
        const errorText = getErrorDescription(error, t('ERROR_WHILE_UPDATING_SERVICES'));
        dispatch(addToast({ error: true, text: errorText, id: nanoid() }));
      })
      .finally(() => setIsLoading(false));
  };

  const editServices = item => {
    return (
      <div className="flex items-center gap-4 w-full" key={item.service_id}>
        <Dropdown
          options={filteredServiceList}
          placeholder={t('SELECT_SERVICE')}
          value={item.id ? item : null}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          isSearchable={true}
          onChange={option => {
            const updatedService = { ...item, ...option };
            onUpdateService(updatedService);
          }}
          className="dropdown-wrapper"
        />
        <InputElement
          className="w-full"
          type="number"
          name=""
          placeholder={t('CAPACITY')}
          value={item?.daily_capacity}
          onChange={value => {
            const updatedService = { ...item, daily_capacity: value };
            onUpdateService(updatedService);
          }}
        />
        <div>
          <CrossIcon color="#171717" width={16} height={16} onClick={() => onRemoveService(item)} />
        </div>
      </div>
    );
  };

  return (
    <ServiceListWrapper>
      <div
        className="py-5 border-bottom teams-service-edit-wrapper cursor"
        onClick={() => editKey !== 'SERVICES' && setEditKey('SERVICES')}>
        <div className="flex justify-between items-center w-full mb-4">
          <label className="inter-600-text font-14 line-height-20 natural-900-text">{uppercase(t('SERVICES'))}</label>
          {editKey === 'SERVICES' ? (
            <>
              <Button
                fontSize="12px"
                size="average"
                width="auto"
                borderRadius="100px"
                afterIcon={<PlusIcon className="after-icon blue-primary-icon" height={14} width={14} />}
                className="secondary specified-width px-4 py-2_5 ml-3"
                label={`${t('ADD_NEW_ITEM', { item: t('SERVICES') })}`}
                onClick={onAddService}
              />
            </>
          ) : (
            <EditIcon width={14} height={14} className="edit-icon cursor" color="#2496FF" />
          )}
        </div>
        {editKey === 'SERVICES' ? (
          <div>
            <div className="flex justify-between service-label-grid items-center w-full mb-1">
              <label className="inter-500-text font-14 line-height-20 natural-900-text">{t('SERVICE')}</label>
              <label className="inter-500-text font-14 line-height-20 natural-900-text">{t('DAILY_CAPACITY')}</label>
            </div>
            <div className="flex-column service-grid row-gap-4">
              {services?.length ? (
                services?.map(item => editServices(item))
              ) : (
                <label className="inter-400-text font-14 line-height-20 natural-400-text">{t('NO_DATA')}</label>
              )}
            </div>
            <div className="w-full flex justify-end relative">
              <div
                className={classNames(
                  'flex justify-center items-end radius-3 profile-update-action mt-1 border pxy-2 gap-2_5 absolute',
                  isLoading && 'disabled',
                )}>
                <span
                  className="flex justify-center items-center cross-icon border radius-100 cursor"
                  onClick={() => {
                    setEditKey(null);
                  }}>
                  <CrossIcon color="#171717" />
                </span>
                <span
                  className="flex justify-center items-center right-icon border radius-100 cursor"
                  onClick={() => onUpdateUserService()}>
                  <RightIcon color="#FFFFFF" />
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex-column row-gap-2">
            {services.length > 0 ? (
              services.map(serviceData => <RenderServiceItem serviceData={serviceData} key={serviceData.name} />)
            ) : (
              <label className="inter-400-text font-14 line-height-20 natural-400-text">{t('NO_DATA')}</label>
            )}
          </div>
        )}
      </div>
    </ServiceListWrapper>
  );
};

const ServiceListWrapper = styled.div`
  .dropdown-wrapper {
    min-width: 214px;
  }
  .service-label-grid {
    gap: 24px;

    label {
      min-width: 214px;
    }
  }
`;

export default UserServices;
