import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { ReactComponent as AlertIcon } from '../../../assets/icons/AlertIcon.svg';
import { ReactComponent as CrossIcon } from '../../../assets/icons/CrossIcons.svg';
import Button from '../../../components/common/button/button';
import Status from '../../../components/common/status';
import { OrganisationContext } from '../../../context/organisationContext';
import { capitalizeFirstLetterOfWords, formatText, initModal } from '../../../helpers/utils';
import { deleteQuoteTemplate } from '../../../store/features/quotesSlice';
import { addToast } from '../../../store/features/toastSlice';

const DeleteQuoteTemplate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { modal, setModal } = useContext(OrganisationContext);
  const { quoteTemplateData, title, description, onSuccess } = modal.content;

  const [loading, setLoading] = useState(false);

  const handleDeleteQuoteTemplate = () => {
    setLoading(true);
    dispatch(deleteQuoteTemplate({ quote_template_id: quoteTemplateData.id })).then(() => {
      setLoading(false);
      dispatch(
        addToast({
          error: false,
          title: 'Quote template deleted',
          text: t('QUOTE_TEMPLATE_DELETED_SUCCESSFULLY', { value: quoteTemplateData.name }),
        }),
      );
      if (onSuccess) {
        onSuccess();
      }
      setModal(initModal);
    });
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <DeleteQuoteTemplateWrapper className="flex-column items-center pxy-6 gap-8">
        <div className="flex-column gap-5 content-wrapper items-center relative">
          <AlertIcon />
          <CrossIcon className="absolute right-0 cursor" width={20} height={20} onClick={() => setModal(initModal)} />
          <div className="flex-column gap-2 items-center justify-start w-full">
            <p className="font-18 text-center inter-500-text natural-900-text">{title}</p>
            <span className="inter-400-text font-14 natural-500-text text-center">{description}</span>
          </div>
        </div>

        <div className="px-3 py-4 flex-column gap-3 w-full radius-1_5 border white-background team-details-container">
          <div className="flex-column text-start">
            <div className="flex items-center justify-between w-full">
              <label className="font-16 inter-600-text natural-900-text line-height-24">
                {quoteTemplateData?.name}
              </label>
              {quoteTemplateData?.is_default && (
                <Status
                  withDot={false}
                  withDottedBorder
                  status={quoteTemplateData?.is_default ? 'DEFAULT' : ''}
                  statusText={
                    quoteTemplateData?.is_default ? capitalizeFirstLetterOfWords(formatText(t('DEFAULT'))) : ''
                  }
                />
              )}
            </div>
            <label className="inter-400-text font-14 natural-500-text mt-2 line-height-20">
              {quoteTemplateData?.description}
            </label>
          </div>
        </div>

        <div className="action-wrapper flex-column justify-center w-full gap-3">
          <Button
            className={`negative`}
            disabled={loading}
            loading={loading}
            label={t('DELETE')}
            onClick={handleDeleteQuoteTemplate}
            size="large"
            color="white"
            borderRadius="100px"
          />
          <Button
            className={`cancel-btn default `}
            label={t('CANCEL')}
            disabled={loading}
            onClick={() => setModal(initModal)}
            size="large"
            borderRadius="100px"
          />
        </div>
      </DeleteQuoteTemplateWrapper>
    </CSSTransition>
  );
};

const DeleteQuoteTemplateWrapper = styled.div`
  width: 384px;
`;

export default DeleteQuoteTemplate;
