import { MenuItem } from '@szhsin/react-menu';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as CrossIcon } from '../../assets/icons/CrossIcons.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit-pensil.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/info-icon.svg';
import { ReactComponent as Sppiner } from '../../assets/icons/property/sppiner.svg';
import { ReactComponent as RightIcon } from '../../assets/icons/right-Icons.svg';
import { Avatar } from '../../components/common/avatar-group';
import Button from '../../components/common/button/button';
import InputElement from '../../components/common/input';
import MenuV2 from '../../components/common/menu/menu-v2';
import OtpInput from '../../components/common/otp-input/otp-input';
import PhoneInput from '../../components/common/phone-input';
import Status from '../../components/common/status';
import Switch from '../../components/common/switch';
import CustomTooltip from '../../components/common/tooltip-new';
import { OrganisationContext } from '../../context/organisationContext';
import { uppercase } from '../../helpers/utils';
import { addToast } from '../../store/features/toastSlice';
import { OTPConfirmation, updateUsersList, updateUsersStatus } from '../../store/features/userManagementSlice';
import UserAddress from './user-address';

const UserContact = ({
  userDetails,
  setUserDetails,
  onUpdateUserProfile,
  isLoading,
  fromMyProfile,
  editKey,
  setEditKey,
  mainUserDetails,
  updateMainUserDetails,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setModal } = useContext(OrganisationContext);

  const { name, role } = userDetails;
  const { status } = mainUserDetails;
  const is_active = status === 'ACTIVE';

  const onRemoveUser = () => {
    setModal({
      type: 'user-action',
      content: {
        user: userDetails,
        top: true,
        isDelete: true,
        title: t('ARE_YOU_SURE_TO_DELETE_THIS_USER'),
        description: t('DELETE_ACTION_DESCRIPTION'),
      },
    });
  };

  const onChangeStatus = async () => {
    const { status: previousStatus, id } = mainUserDetails;
    const newStatus = is_active ? 'INACTIVE' : 'ACTIVE';
    const params = {
      is_active: !is_active,
    };
    dispatch(updateUsersList({ id: id }));
    updateMainUserDetails({ ...mainUserDetails, status: newStatus });
    dispatch(updateUsersStatus({ id: id, params }))
      .then(() => {
        dispatch(addToast({ error: false, text: t('USER_VALUE_UPDATED', { value: 'status' }) }));
      })
      .catch(error => {
        dispatch(updateUsersList({ id: id }));
        updateMainUserDetails({ ...mainUserDetails, status: previousStatus });
        dispatch(
          addToast({
            error: true,
            text: t('ERROR_UPDATE_STATUS'),
            id: nanoid(),
          }),
        );
      });
  };

  const onResendClick = async () => {
    setModal({
      type: 'user-action',
      content: {
        user: userDetails,
        top: true,
        isReinvite: true,
        title: t('RESEND_INVITE_USER'),
      },
    });
  };

  const getExtraMenuOption = () => {
    if (status === 'EXPIRED' || status === 'INVITED') {
      return (
        <Button
          fontSize="14px"
          size="medium"
          borderRadius="100px"
          className={`secondary w-full`}
          label={t('RESEND_INVITE')}
          onClick={onResendClick}
        />
      );
    } else {
      return (
        <div className="flex items-center justify-between w-full">
          <span className="inter-400-text flex items-center">{t('ACTIVE')}</span>
          <Switch onClick={onChangeStatus} enabled={is_active} disabled={false} />
        </div>
      );
    }
  };

  const renderItem = (value, key, valueClassName = '') => {
    return (
      <div className="flex items-center col-gap-4 rander-item justify-center" onClick={() => setEditKey(key)}>
        <div className="flex items-center edit-text overflow-hidden">
          <label className={classNames('inter-400-text natural-900-text one-line', valueClassName)}>{value}</label>
          <EditIcon width={14} height={14} className="edit-icon cursor flex-shrink-0" color="#2496FF" />
        </div>
      </div>
    );
  };

  const editContent = (key, value) => {
    return (
      <div className={classNames('flex-column items-center row-gap-1 w-full input-container relative px-4')}>
        <div className="flex items-center w-full">
          <InputElement
            className="w-full"
            variant="size_24"
            autoFocus
            value={value}
            onChange={value => setUserDetails({ ...userDetails, [key]: value })}
          />
        </div>
        <div className="w-full flex justify-end">
          <div
            className={classNames(
              'flex justify-center items-end radius-3 profile-update-action mt-1 border pxy-2 gap-2_5 absolute',
              isLoading && 'disabled',
            )}>
            <span
              className="flex justify-center items-center cross-icon border radius-100 cursor"
              onClick={() => {
                setEditKey(null);
                setUserDetails({ ...userDetails });
              }}>
              <CrossIcon color="#171717" />
            </span>
            <span
              className="flex justify-center items-center right-icon border radius-100 cursor"
              onClick={() => onUpdateUserProfile('NAME')}>
              <RightIcon color="#FFFFFF" />
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="gray-avatar h-auto w-full flex items-center justify-center relative py-4 radius-1_5">
      <div className="flex-column justify-center items-center row-gap-4 w-full relative">
        <Status status={status} statusWrapperClassName="absolute user-status" />
        {!fromMyProfile && (
          <div className="flex items-center justify-center absolute user-menu">
            <MenuV2
              place="bottom-end"
              menuList={[{ name: 'Remove user', onClick: onRemoveUser }]}
              iconClassName="rotate-90"
              ExtraOptions={
                <MenuItem style={{ background: '#fff' }}>
                  <div className="flex items-center justify-between  w-full  extra-option white-background">
                    {getExtraMenuOption()}
                  </div>
                </MenuItem>
              }
            />
          </div>
        )}
        <Avatar
          avatar={userDetails}
          backgroundColor="#000"
          avatarWrapperClassname="cursor border-0 avtar-box"
          avatarLabelClassname="font-20"
          variant="size_56"
        />
        <div className="flex-column items-center w-full">
          {editKey === 'NAME'
            ? editContent('name', name, 'px-4')
            : renderItem(name, 'NAME', 'inter-600-text font-16 line-height-24 natural-900-text cursor items-center')}
          <p className="inter-400-text font-14 line-height-20 natural-400-text">{role?.name}</p>
        </div>
      </div>
    </div>
  );
};

const UserInfo = ({
  editKey,
  setEditKey,
  userDetails,
  setUserDetails,
  fetchProfileDetails,
  fromMyProfile,
  user_id,
  handleUpdateProfile,
  updateMainUserDetails,
  mainUserDetails,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user);

  const { email, phone: editPhone, locations: userLocations, country_code = '+44', id } = userDetails || {};
  const ownUser = user?.user;
  const isOwnUserEdit = ownUser?.id === id;

  const phone = editPhone?.replaceAll('+44', '').replaceAll('+1', '');
  const locations = userLocations?.[0] || userLocations;

  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [otpValue, setOtpValue] = useState('');
  const [isOtpValid, setIsOtpValid] = useState(true);
  const [isCodeSent, setIsCodeSent] = useState(false);

  useEffect(() => {
    if (editKey) {
      setOtpValue('');
      setIsOtpValid(true);
      setIsCodeSent(false);
    }
  }, [editKey]);

  const onConfirm = async () => {
    const request = {
      code: otpValue,
      type: 'PHONE',
    };
    setIsOtpValid(true);
    await dispatch(OTPConfirmation({ request }))
      .then(data => {
        dispatch(addToast({ error: false, text: t('PROFILE_UPDATE_SUCCESSFULLY') }));
        fetchProfileDetails(user_id);
        setEditKey(null);
      })
      .catch(error => {
        setIsOtpValid(false);
        dispatch(addToast({ error: true, text: error?.response?.data?.error_description || 'Invalid OTP' }));
      });
  };

  const onSendCode = () => {};

  const onConfirmCode = () => {};

  const editPhoneNumber = () => {
    return (
      <div className="w-full flex-column relative gap-6">
        <div className="relative">
          <label className="regular-text main-grey-text mb-1 inter-500-text natural-900-text">{t('MOBILE')}</label>
          <div className="flex mt-1 gap-4 items-center">
            <PhoneInput
              className={classNames('phone-input', !isOwnUserEdit && 'w-full')}
              selectedCountry={country_code || '+44'}
              setSelectedCountry={value => setUserDetails({ ...userDetails, country_code: value })}
              setPhone={value => setUserDetails({ ...userDetails, phone: value })}
              phone={phone}
              error={error.phone && !phone}
            />
            {isOwnUserEdit && (
              <Button
                height="40px"
                label={isLoading ? <Sppiner /> : 'Send code'}
                className={classNames('primary px-3 py-2')}
                onClick={() => {
                  handleUpdateProfile('PHONE', true);
                  setIsCodeSent(true);
                }}
                disabled={isLoading}
              />
            )}
          </div>
          {!isOwnUserEdit && editKey === 'MOBILE' && (
            <div className="flex items-center absolute right-0 mt-1">
              <div
                className={classNames(
                  'flex justify-center items-end radius-3 profile-update-action border pxy-2 gap-2_5 ',
                  isLoading && 'disabled',
                )}>
                <span
                  className="flex justify-center items-center cross-icon border radius-100 cursor"
                  onClick={() => {
                    setEditKey(null);
                    setUserDetails({ ...userDetails });
                  }}>
                  <CrossIcon color="#171717" />
                </span>
                <span
                  className="flex justify-center items-center right-icon border radius-100 cursor"
                  onClick={() => handleUpdateProfile('PHONE')}>
                  <RightIcon color="#FFFFFF" />
                </span>
              </div>
            </div>
          )}
        </div>
        {isCodeSent && (
          <div className="w-full flex form flex-column items-center">
            <div className="w-full items-start flex-column gap-1">
              <div className="flex justify-between w-full items-center">
                <OtpInput
                  className={'otp-input'}
                  containerClass={'w-full otp-content gap-2'}
                  errorStyle={'error-border'}
                  hasErrored={!isOtpValid}
                  inputStyle={'input input-style'}
                  isInputNum={true}
                  onChange={setOtpValue}
                  placeholder="000000"
                  value={otpValue}
                />
              </div>
              <div className="w-full flex items-center justify-between">
                <label
                  onClick={() => handleUpdateProfile('PHONE', true)}
                  className="inter-500-text primary-text font-12 cursor">
                  {t('SEND_THE_CODE_AGAIN')}
                </label>
                <div
                  className={classNames(
                    'flex justify-center items-end radius-3 profile-update-action border pxy-2 gap-2_5',
                    isLoading && 'disabled',
                  )}>
                  <span
                    className="flex justify-center items-center cross-icon border radius-100 cursor"
                    onClick={() => {
                      setEditKey(null);
                      setUserDetails({ ...userDetails });
                    }}>
                    <CrossIcon color="#171717" />
                  </span>
                  <span
                    className="flex justify-center items-center right-icon border radius-100 cursor"
                    onClick={() => onConfirm()}>
                    <RightIcon color="#FFFFFF" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderItem = (label, value, key, isEditable = true, valueClassName = '') => {
    return (
      <div
        className={classNames(
          'flex items-center col-gap-4 rander-item',
          key === 'NAME' && 'justify-center',
          isEditable && 'cursor profile-detail-item w-full',
        )}
        onClick={() => {
          if (isEditable) {
            setEditKey(key);
          }
        }}>
        {label && (
          <label className="inter-400-text font-14 line-height-20 natural-500-text contact-label">{label}:</label>
        )}
        <div className="flex items-center edit-text overflow-hidden">
          <label
            className={classNames(
              value ? 'inter-400-text natural-900-text one-line' : 'inter-400-text natural-400-text',
              valueClassName,
            )}>
            {value || (label && t(`NO_${label?.toUpperCase()}`))}
          </label>
          {key === 'EMAIL' ? (
            <CustomTooltip
              id={`user-info-tooltip-${key}`}
              place="bottom-center"
              wrapperClassName="flex"
              hidden={isEditable}
              content={<label className="flex col-gap-1 items-center">{t('CANT_BE_EDITED')}</label>}>
              <InfoIcon width={14} height={14} className="natural-700-text edit-icon info-icon cursor" />
            </CustomTooltip>
          ) : (
            <EditIcon width={14} height={14} className="edit-icon cursor flex-shrink-0" color="#2496FF" />
          )}
        </div>
      </div>
    );
  };

  return (
    <ProfileInfoWrapper>
      <UserContact
        userDetails={userDetails}
        setUserDetails={setUserDetails}
        onUpdateUserProfile={handleUpdateProfile}
        isLoading={isLoading}
        fromMyProfile={fromMyProfile}
        editKey={editKey}
        setEditKey={setEditKey}
        updateMainUserDetails={updateMainUserDetails}
        mainUserDetails={mainUserDetails}
      />
      <div className={classNames('py-5 border-bottom')}>
        <div className="pb-4">
          <label className="inter-600-text font-14 line-height-20 natural-900-text">{uppercase(t('CONTACT'))}</label>
        </div>
        <div className="flex-column row-gap-3 ">
          {editKey === 'MOBILE'
            ? editPhoneNumber()
            : renderItem(t('MOBILE'), phone ? `${country_code || ''} ${phone}` : '', 'MOBILE', true, '')}

          {renderItem(t('EMAIL'), email, 'EMAIL', false, '')}

          {editKey === 'ADDRESS' ? (
            <UserAddress
              setEditKey={setEditKey}
              userDetails={userDetails}
              setUserDetails={setUserDetails}
              onUpdateUserProfile={handleUpdateProfile}
            />
          ) : (
            renderItem(t('ADDRESS'), locations?.formatted_address, 'ADDRESS', true, '')
          )}
        </div>
      </div>
    </ProfileInfoWrapper>
  );
};

const ProfileInfoWrapper = styled.div`
  .user-status {
    top: 0;
    left: 12px;
  }

  .user-menu {
    top: 0;
    right: 12px;

    .extra-option {
      border-radius: 0 0 12px 12px;
    }
  }
`;

export default UserInfo;
