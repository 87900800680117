import classNames from 'classnames';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { components } from 'react-select';
import { useTheme } from 'styled-components';
import { ReactComponent as PlusIcon } from '../../assets/icons/add-icon.svg';
import { ReactComponent as LeftArrow } from '../../assets/icons/left-arrow-to-left.svg';
import { ReactComponent as ArrowLeftIcon } from '../../assets/images/arrow-left.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/close-image.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/images/menu/arrow-down-black.svg';
import { Avatar } from '../../components/common/avatar-group';
import Button from '../../components/common/button/button';
import DateSelectorElement from '../../components/common/date-selector/date-selector-element';
import IconContainer from '../../components/common/icon-container';
import InputElement from '../../components/common/input';
import SearchableDropdown from '../../components/common/searchable-dropdown';
import Dropdown from '../../components/common/select-dropdown';
import Status from '../../components/common/status';
import Switch from '../../components/common/switch';
import CustomTooltip from '../../components/common/tooltip-new';
import { OrganisationContext } from '../../context/organisationContext';
import { getSimplifiedErrorResponse } from '../../helpers/functions';
import { capitalizeFirstLetterOfWords, formatText, initSideModal } from '../../helpers/utils';
import { getJobs } from '../../store/features/jobSlice';
import { addJobActivityTask, getForms, getJobTypeServices } from '../../store/features/taskSlice';
import { addToast } from '../../store/features/toastSlice';
import { getEngagementAccess } from '../../store/features/workitemSlice';
import { TaskWrapper } from '../sidemodel.styled';

const statusOptions = [
  { label: 'To do', value: 'TODO' },
  { label: 'In-progress', value: 'STARTED' },
  { label: 'Blocked', value: 'BLOCKED' },
  { label: 'Done', value: 'COMPLETE' },
];
const priorityOptions = [
  { label: 'Highest', value: 'VERY_HIGH' },
  { label: 'High', value: 'HIGH' },
  { label: 'Medium', value: 'MEDIUM' },
  { label: 'Low', value: 'LOW' },
  { label: 'Lowest', value: 'VERY_LOW' },
];
const evidenceOptions = [
  { label: 'Photo', value: 'IMAGE' },
  { label: 'Document', value: 'DOCUMENT' },
  { label: 'Video', value: 'VIDEO' },
  { label: 'Form', value: 'FORM' },
];
const initialValues = {
  status: 'TODO',
  name: '',
  description: '',
  priority: 'MEDIUM',
  start_date: moment().unix(),
};

const CustomValueContainer = ({ children, ...props }) => {
  const {
    selectProps: { value },
  } = props;

  const firstValue = (value && value[0]) || {};

  return (
    <components.ValueContainer {...props}>
      {value?.length && (
        <div className="flex items-center gap-2 status-select">
          <div className="flex items-center col-gap-1 one-line">
            <Avatar avatar={firstValue?.user || firstValue} variant="semi_large" />
          </div>
          <label className="inter-400-text font-14 natural-900-text">
            {firstValue?.user?.name || firstValue?.name}
          </label>
        </div>
      )}
      <div
        className={classNames(
          'w-full cursor flex items-center justify-between',
          !value?.length && 'h-44px status-select o1 relative',
          value?.length && 'o0 absolute',
        )}>
        <div className="flex items-center justify-between w-full">
          <label className="w-auto flex items-center justify-between font-14 text-center">{children}</label>
        </div>
      </div>
    </components.ValueContainer>
  );
};

const CustomOption = props => {
  const { innerProps, data } = props;
  const { name, property } = data || {};
  const { formatted_address } = property || {};

  return (
    <div className="w-full px-4 py-3 cursor border-bottom" {...innerProps}>
      <div className="flex-column">
        <span className="inter-400-text natural-900-text font-14 line-height-20 text-ellipsis">{name}</span>
        <span className="inter-400-text natural-500-text font-12 line-height-20 text-ellipsis">
          {formatted_address}
        </span>
      </div>
    </div>
  );
};

const Jobs = ({ selectedJob, setSelectedJob, error = false }) => {
  const { t } = useTranslation();
  const [searchedJob, setSearchedJob] = useState('');

  return (
    <div className="flex items-start justify-between">
      <div className="w-full">
        <SearchableDropdown
          inputValue={searchedJob}
          onInputChange={setSearchedJob}
          placeholder={t('SEARCH_TYPING_TO_SEE_SOME_RESULTS')}
          isSearchable={true}
          isCustomSearchable={false}
          loadOptionsOnMenuOpen={false}
          isMulti={false}
          openMenuOnFocus
          value={selectedJob}
          error={error}
          onChange={options => {
            setSelectedJob(options);
          }}
          name="Job"
          defaultAdditional={{
            page: 0,
            fetchFunction: getJobs,
            pageable: true,
          }}
          customStyle={{
            control: {
              minHeight: 44,
            },
          }}
          customComponent={{ Option: CustomOption }}
        />
      </div>
    </div>
  );
};

const PropertyJobOwners = ({ engagement_id, selectedOwner, setSelectedOwner, fromActivity, resources, disabled }) => {
  const { t } = useTranslation();

  const [searchedOwner, setSearchedOwner] = useState('');

  const CustomOwnerOption = ({ data, innerProps, innerRef, isSelected }) => {
    const owner = fromActivity ? data?.user : data;
    return (
      <div className="flex items-center px-4 py-3 border-bottom cursor" ref={innerRef} {...innerProps}>
        <Avatar variant="medium" avatar={owner} backgroundColorIndex={0} />
        <label className="inter-400-text natural-900-text font-14 ml-2 flex-1">{owner?.name}</label>
      </div>
    );
  };

  return (
    <div className="flex items-start justify-between">
      <div className="w-full">
        <SearchableDropdown
          key={`owner_${engagement_id}`}
          inputValue={searchedOwner}
          onInputChange={setSearchedOwner}
          placeholder={t('UNASSIGNED')}
          isCustomSearchable={true}
          isMulti={false}
          openMenuOnFocus
          value={selectedOwner}
          isDisabled={disabled}
          onChange={option => {
            setSelectedOwner([{ ...option }]);
          }}
          getOptionValue={option => (fromActivity ? option?.user?.id : option?.id)}
          defaultAdditional={{
            page: 0,
            defaultOptions: fromActivity ? resources : [],
            fetchFunction: !fromActivity ? getEngagementAccess : null,
            pageable: false,
            payload: { id: engagement_id },
          }}
          customStyle={{
            menu: { right: 0 },
            control: {
              minHeight: 28,
              borderColor: '#E5E5E5',
              cursor: 'pointer',
            },
            valueContainer: { display: 'flex', padding: '0' },
            dropdownIndicator: {
              svg: {
                width: '15px',
                height: '15px',
                color: '#A3A3A3',
              },
            },
          }}
          customComponent={{ Option: CustomOwnerOption, ValueContainer: CustomValueContainer }}
        />
      </div>
    </div>
  );
};

const AddNewTask = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { sideModal, setSideModal } = useContext(OrganisationContext);
  const {
    taskType,
    activityId,
    content: { onSuccess, fromTaskList, fromActivity, fromJobTasks, resources },
  } = sideModal;

  const resourceServices = resources?.reduce((acc, resource) => {
    const { service } = resource || {};
    if (acc.indexOf(service) === -1) {
      acc.push({ ...service, label: service.name, value: service.id });
    }
    return acc;
  }, []);

  const { userDetails } = useSelector(state => state.user);
  const { workitemDetails } = useSelector(state => state.board);

  const { engagement } = workitemDetails;
  const { id: engagement_id, job } = engagement || {};
  const { job_type } = job || {};

  const [task, setTask] = useState(initialValues);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [isAddSubTasks, setIsAddSubTasks] = useState(false);
  const [isRequiredEvidence, setIsRequiredEvidence] = useState(false);
  const [isSubTask, setIsSubTask] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formsData, setFormsData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(statusOptions.find(item => item?.value === task?.status));
  const [priorityTooltipOpen, setPriorityTooltipOpen] = useState(false);
  const [selectedPriority, setSelectedPriority] = useState(
    priorityOptions.find(item => item?.value === task?.priority),
  );
  const [evidences, setEvidences] = useState([]);
  const [services, setServices] = useState([]);
  const [createdTaskData, setCreatedTaskData] = useState({});
  const [selectedOwner, setSelectedOwner] = useState();
  const [selectedJob, setSelectedJob] = useState();
  const [errors, setErrors] = useState({});

  const isServiceVisible = !fromJobTasks && !fromTaskList;
  const isJobVisible = fromTaskList;

  const checkErrors = () => {
    const { name, service } = task;

    if (!name?.trim() || (isServiceVisible && !service?.id) || (isJobVisible && !selectedJob?.id)) {
      setErrors({
        name: !name?.trim(),
        service: isServiceVisible && !service?.id,
        job: isJobVisible && !selectedJob?.id,
      });
      dispatch(addToast({ error: true, text: t('PLEASE_FILL_ALL_THE_REQUIRED_FIELDS'), id: nanoid() }));
      return true;
    }
    setErrors({});
    return false;
  };

  const onAddTask = () => {
    const assignee = selectedOwner?.length > 0 ? selectedOwner[0] : {};
    const owner = assignee?.user ? assignee?.user : assignee || {};
    if (checkErrors()) {
      return;
    }
    setLoading(true);
    let request = {
      ...task,
      evidence: transformData(evidences),
      service: task?.service?.id
        ? {
            id: task?.service?.id,
          }
        : null,
      assignee: owner?.id
        ? {
            id: owner?.id,
          }
        : null,
      engagement:
        selectedJob || engagement_id
          ? {
              id: selectedJob?.id || engagement_id,
            }
          : null,
      task_type: 'BASIC',
      ...(taskType && taskType === 'job-activity-task' && activityId && { activity: { id: activityId } }),
      ...(isSubTask && createdTaskData?.id && { parent: { id: createdTaskData?.id } }),
    };

    dispatch(addJobActivityTask({ request: request }))
      .then(data => {
        setCreatedTaskData(data);
        if (isAddSubTasks) {
          setIsSubTask(true);
          setTask(initialValues);
          setIsAddSubTasks(false);
        } else {
          dispatch(addToast({ error: false, text: t('TASK_ADDED'), id: nanoid() }));
          setSideModal(initSideModal);
          onSuccess && onSuccess();
        }
      })
      .catch(error => {
        const errorText = getSimplifiedErrorResponse(error, t('ERROR_WHILE_ADDING_TASK'));
        dispatch(addToast({ error: true, text: errorText, id: nanoid() }));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchOrganizationServices = job_type_id => {
    if (!isServiceVisible) {
      return;
    }
    dispatch(getJobTypeServices({ job_type_id: job_type_id }))
      .then(data => {
        const services = data.map(({ service }) => ({
          ...service,
          label: service.name,
          value: service.id,
        }));
        setServices(services);
        if (!isReschedule) {
          setSelectedService(services);
        }
        if (services.length === 0) {
          setLoadingAvailabilities(false);
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (job_type?.id) {
      fetchOrganizationServices(job_type?.id);
    }
  }, [job_type]);

  function updateTask(key, value) {
    setTask({
      ...task,
      [key]: value,
    });
  }

  const handleSelectChange = (index, event) => {
    const newItems = [...evidences];
    newItems[index].selectedOption = event;
    setEvidences(newItems);
  };

  const handleNameChange = (index, event) => {
    const newItems = [...evidences];
    newItems[index].name = event;
    setEvidences(newItems);
  };

  const removeItem = index => {
    setEvidences(evidences.filter((_, i) => i !== index));
  };

  const transformData = data => {
    return data.map(item => {
      const name = typeof item.name === 'object' ? item.name.name : item.name;
      const type = item.selectedOption?.value || '';
      const defautValue = {
        name: name,
        evidence_category: null,
        evidence_type: type,
        form: null,
        timeline: 'BEFORE',
        is_required: true,
        tag: null,
      };
      return defautValue;
    });
  };

  const fetchForms = () => {
    dispatch(getForms())
      .then(data => {
        setFormsData(data?.content);
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_TASK_EVIDENCE') }));
      })
      .finally(() => {});
  };

  useEffect(() => {
    fetchForms();
  }, []);

  const getStatusStyles = status => {
    switch (status) {
      case 'COMPLETE':
        return {
          color: theme.success_500,
          background: theme.success_50,
        };
      case 'BLOCKED':
        return {
          color: theme.primary_500,
          background: theme.primary_50,
        };
      case 'STARTED':
        return {
          color: theme.purple_500,
          background: theme.purple_50,
        };
      default:
        return {
          color: theme.natural_600,
          background: theme.natural_150,
        };
    }
  };

  const StatusLabel = ({ data, isMainContent = false }) => {
    const { color, background } = useMemo(() => getStatusStyles(data?.value, theme), [data?.value, theme]);

    if (isMainContent) {
      return (
        <div
          className="border cursor flex items-center justify-between status-select"
          onClick={e => {
            e.stopPropagation();
            setTooltipOpen(true);
          }}>
          <label
            style={{
              background,
              color,
              borderRadius: '50px',
              width: 'fit-content',
              height: '24px',
            }}
            className="w-auto select-label flex items-center justify-between font-12 px-3 text-center gap-1">
            <label style={{ color }} className="font-12 inter-500-text">
              {selectedStatus?.label}
            </label>
          </label>
          <IconContainer
            Icon={ArrowDownIcon}
            iconColor="natural_400"
            backgroundColor={background}
            iconContainerClassname="p-0"
            iconWidth={15}
            iconHeight={15}
          />
        </div>
      );
    } else {
      return (
        <label
          style={{
            background,
            color,
            borderRadius: '50px',
            width: 'fit-content',
            height: '24px',
          }}
          className="flex select-label items-center justify-center font-12 px-3 text-center w-auto gap-1 font-12 fw-500">
          {data?.label}
        </label>
      );
    }
  };

  const onAssignToMe = () => {
    const { id, forename, surname } = userDetails;
    const name = `${forename} ${surname}`;
    const request = [{ id: id, name: name }];
    setSelectedOwner(request);
  };

  const isDisabled = fromTaskList && !selectedJob?.id;

  return (
    <TaskWrapper className="flex flex-column h-full w-full overflow-scroll">
      <div className="flex w-full items-center py-5 px-6 border-bottom justify-between">
        <LeftArrow height={24} width={24} className="cursor" onClick={() => setSideModal(initSideModal)} />
      </div>
      <div className="flex flex-column flex-1 gap-6 overflow-scroll pxy-6">
        {isSubTask && (
          <div
            className="flex w-full gap-4 relative cursor"
            onClick={() => {
              setIsSubTask(false);
              setIsAddSubTasks(false);
            }}>
            <IconContainer
              Icon={ArrowLeftIcon}
              backgroundColor="transparent"
              iconColor="primary_500"
              iconContainerClassname="p-0"
            />
            <label className="inter-600-text natural-900-text font-20">{t('SUB_TASK')}</label>
          </div>
        )}
        <div className="flex-column row-gap-1 w-full relative">
          <label className="inter-500-text natural-900-text font-14">{t('STATUS')}</label>
          <CustomTooltip
            wrapperClassName="status-tooltip"
            tooltipClassname="custom-scrollbar thin-scrollbar"
            id={`status-tooltip`}
            place="bottom"
            clickable
            isOpen={tooltipOpen}
            onBlurTooltip={() => setTooltipOpen(false)}
            hidden={!tooltipOpen}
            noArrow={true}
            content={statusOptions
              ?.filter(status => status?.label !== selectedStatus?.label)
              ?.map((data, index) => (
                <div
                  key={data?.label}
                  onClick={() => {
                    updateTask('status', data?.value);
                    setSelectedStatus(data);
                    setTooltipOpen(false);
                  }}
                  className="px-4 py-3 font-14 border-bottom cursor proposal-config">
                  <StatusLabel data={data} />
                </div>
              ))}>
            <StatusLabel data={selectedStatus} isMainContent={true} />
          </CustomTooltip>
        </div>
        <div className="flex-column row-gap-1 w-full relative">
          <label className="inter-500-text natural-900-text font-14">{t('TASK_NAME')}</label>
          <InputElement
            placeholder={'Task Name'}
            className="border-color-grey"
            onChange={value => updateTask('name', value)}
            value={task?.name}
            error={errors?.name && !task?.name?.trim()}
          />
        </div>
        <div className="flex-column row-gap-1 w-full relative">
          <label className="inter-500-text natural-900-text font-14">
            {t('DESCRIPTION')} <span className="natural-400-text inter-400-text">{t('OPTIONAL')}</span>
          </label>
          <InputElement
            onChange={value => updateTask('description', value)}
            value={task?.description || ''}
            placeholder={t('DESCRIPTION')}
            className="border-color-grey"
            type="textarea"
          />
        </div>
        <div className="w-full border-bottom"></div>
        {fromTaskList && (
          <Jobs selectedJob={selectedJob} setSelectedJob={setSelectedJob} error={errors.job && !selectedJob?.id} />
        )}
        <div className="flex-column row-gap-1 w-full relative">
          <div className="flex items-center justify-between">
            <label className="inter-500-text natural-900-text font-14">
              {t('ASSIGNEE')} <span className="natural-400-text inter-400-text">{t('OPTIONAL')}</span>
            </label>
            <div className="flex items-center justify-end">
              <CustomTooltip
                id={'toggle-overlay'}
                place="left"
                wrapperClassName="tooltip-wrapper"
                content={isDisabled && <span className="inter-400-text font-12">{t('PLEASE_SELECT_JOB_FIRST')}</span>}>
                <span
                  className="inter-500-text font-14 blue-primary-icon cursor text-right w-auto text-hover"
                  onClick={isDisabled ? null : onAssignToMe}>
                  {t('ASSIGN_TO_ME')}
                </span>
              </CustomTooltip>
            </div>
          </div>
          <CustomTooltip
            id={'toggle-overlay'}
            place="left"
            wrapperClassName="tooltip-wrapper"
            content={isDisabled && <span className="inter-400-text font-12">{t('PLEASE_SELECT_JOB_FIRST')}</span>}>
            <PropertyJobOwners
              selectedJob={selectedJob}
              setSelectedOwner={setSelectedOwner}
              selectedOwner={selectedOwner}
              engagement_id={selectedJob ? selectedJob?.id : engagement_id}
              fromActivity={fromActivity}
              resources={resources}
              disabled={isDisabled}
            />
          </CustomTooltip>
        </div>
        {!fromTaskList && !fromJobTasks && (
          <div className="flex-column row-gap-1 w-full relative">
            <label className="inter-500-text natural-900-text font-14">{t('SERVICE')}</label>
            <Dropdown
              placeholder={t('SELECT')}
              onChange={option => updateTask('service', option)}
              options={fromActivity ? resourceServices || [] : services || []}
              value={task?.service}
              customStyle={{
                control: {
                  borderColor: '#E5E5E5',
                  cursor: 'pointer',
                },
                dropdownIndicator: {
                  svg: {
                    width: '15px',
                    height: '15px',
                    color: '#A3A3A3',
                  },
                },
              }}
              error={errors?.service && !task?.service?.id}
            />
          </div>
        )}
        {taskType !== 'job-activity-task' && (
          <>
            <div className="flex-column row-gap-1 w-full relative">
              <label className="inter-500-text natural-900-text font-14">
                {t('DUE_DATE')} <span className="natural-400-text inter-400-text">{t('OPTIONAL')}</span>
              </label>
              <DateSelectorElement
                className={'justify-between'}
                setSelectedDate={value => {
                  setTask({ ...task, due_date: value });
                }}
                selectedDate={task?.due_date || ''}
                placeholder={t('SELECT_DATE')}
                isCalendarIconRight={true}
                isCalendarIcon={false}
                minStartDate={moment().subtract(1, 'day').startOf('day').unix()}
              />
            </div>
            <div className="flex-column row-gap-1 w-full relative">
              <label className="inter-500-text natural-900-text font-14">{t('PRIORITY')}</label>
              <CustomTooltip
                wrapperClassName="status-tooltip"
                tooltipClassname="custom-scrollbar thin-scrollbar"
                id={`priority-tooltip`}
                place="bottom"
                clickable
                isOpen={priorityTooltipOpen}
                onBlurTooltip={() => setPriorityTooltipOpen(false)}
                hidden={!priorityTooltipOpen}
                noArrow={true}
                content={priorityOptions
                  ?.filter(priority => priority?.label !== selectedPriority?.label)
                  ?.map((data, index) => (
                    <div
                      key={data?.label}
                      onClick={() => {
                        updateTask('priority', data?.value);
                        setSelectedPriority(data);
                        setPriorityTooltipOpen(false);
                      }}
                      className="px-4 py-3 font-14 border-bottom cursor proposal-config">
                      <Status
                        withDot={false}
                        withDottedBorder={false}
                        status={data?.value}
                        icon={true}
                        statusText={capitalizeFirstLetterOfWords(formatText(data?.label))}
                      />
                    </div>
                  ))}>
                <div
                  className="border cursor flex items-center justify-between status-select"
                  onClick={e => {
                    e.stopPropagation();
                    setPriorityTooltipOpen(true);
                  }}>
                  <label className="w-auto select-label flex items-center justify-between font-14 text-center gap-1">
                    <div className="flex items-center">
                      <Status
                        withDot={false}
                        withDottedBorder={false}
                        status={selectedPriority?.value}
                        icon={true}
                        statusText={capitalizeFirstLetterOfWords(formatText(selectedPriority?.label))}
                      />
                    </div>
                  </label>
                  <IconContainer
                    Icon={ArrowDownIcon}
                    iconColor="natural_500"
                    backgroundColor={'white'}
                    iconContainerClassname="p-0"
                    iconWidth={14}
                    iconHeight={14}
                  />
                </div>
              </CustomTooltip>
            </div>
          </>
        )}
        <div className="w-full border-bottom"></div>
        {!isSubTask && (
          <div className="flex justify-between items-center w-full relative">
            <label className="inter-400-text natural-900-text font-14">{t('ADD_SUBTASK')}</label>
            <Switch
              enabled={isAddSubTasks}
              disabled={false}
              onClick={() => {
                setIsAddSubTasks(!isAddSubTasks);
              }}
            />
          </div>
        )}
        {!isAddSubTasks && (
          <>
            <div className="w-full border-bottom"></div>
            <div className="flex-column row-gap-4 w-full relative">
              <div className="flex justify-between items-center w-full relative">
                <label className="inter-400-text natural-900-text font-14">{t('REQUIRES_EVIDENCE')}</label>
                <Switch
                  enabled={isRequiredEvidence}
                  disabled={false}
                  onClick={() => setIsRequiredEvidence(!isRequiredEvidence)}
                />
              </div>
              {isRequiredEvidence && (
                <>
                  {evidences?.length > 0 && (
                    <div>
                      {evidences.map((item, index) => {
                        return (
                          <div key={index} style={{ marginBottom: '10px' }}>
                            <div className="flex gap-3">
                              <div className="flex-column">
                                {index === 0 && (
                                  <label className="inter-400-text natural-900-text font-14 mb-1">{t('TYPE')}</label>
                                )}
                                <Dropdown
                                  className="w-auto"
                                  onChange={e => handleSelectChange(index, e)}
                                  options={evidenceOptions}
                                  placeholder={t('SELECT_TYPE')}
                                  value={item.selectedOption}
                                  customStyle={{
                                    control: {
                                      width: '140px',
                                    },
                                    valueContainer: {
                                      paddingRight: '0px !important',
                                      margin: '0px !important',
                                    },
                                  }}
                                />
                              </div>
                              {item?.selectedOption?.value === 'FORM' ? (
                                <div className="flex-column w-full">
                                  {index === 0 && (
                                    <label className="inter-400-text natural-900-text font-14 mb-1">
                                      {t('FORM_TYPE')}
                                    </label>
                                  )}
                                  <Dropdown
                                    onChange={option => {
                                      handleNameChange(index, option);
                                    }}
                                    options={formsData}
                                    getOptionLabel={option => {
                                      const { name } = option;
                                      return name;
                                    }}
                                    getOptionValue={option => option.id}
                                    placeholder={'select form'}
                                    className="w-full"
                                    value={item.name}
                                  />
                                </div>
                              ) : (
                                <div className="flex-column">
                                  {index === 0 && (
                                    <label className="inter-400-text natural-900-text font-14 mb-1">{t('NAME')}</label>
                                  )}
                                  <InputElement
                                    placeholder={t('FILE_NAME_GOES_HERE')}
                                    onChange={e => handleNameChange(index, e)}
                                    value={item?.name}
                                  />
                                </div>
                              )}
                              <IconContainer
                                Icon={CloseIcon}
                                iconColor="natural_500"
                                backgroundColor={'white'}
                                iconContainerClassname={classNames('p-0 cursor', index === 0 && 'mt-4')}
                                iconWidth={20}
                                iconHeight={20}
                                onClick={() => removeItem(index)}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  <div
                    className="flex items-center gap-1"
                    onClick={() => {
                      setEvidences([...evidences, { name: '', selectedOption: '' }]);
                    }}>
                    <IconContainer
                      backgroundColor="transparent"
                      Icon={PlusIcon}
                      iconWidth={12}
                      iconHeight={12}
                      iconColor="primary_500"
                    />
                    <label className="inter-500-text primary-text cursor font-14 line-height-20">
                      {t('ADD_EVIDENCE')}
                    </label>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
      <div className="flex col-gap-6 justify-center pt-5 px-6 border-top pb-6 w-full">
        <Button
          onClick={() => setSideModal(initSideModal)}
          className={classNames('flex-1 primary-grey')}
          label={t('CANCEL')}
          disabled={loading}
          size="large"
          borderRadius="100px"
        />
        <Button
          onClick={onAddTask}
          className={classNames('primary flex-1')}
          label={t('ADD')}
          loading={loading}
          size="large"
          borderRadius="100px"
        />
      </div>
    </TaskWrapper>
  );
};

export default AddNewTask;
