import classNames from 'classnames';
import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { components } from 'react-select';
import { useTheme } from 'styled-components';
import { ReactComponent as MobileIcon } from '../../assets/icons/mobile.svg';
import { formatText, getContactValueByType } from '../../helpers/utils';
import { getPropertyContacts } from '../../store/features/propertySlice';
import IconContainer from '../common/icon-container';
import SearchableDropdown from '../common/searchable-dropdown';
import { QuoteRecipientWrapper } from './wrapper';

const EditQuoteRecipient = ({ recipient, setEditKey, onUpdateQuote }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const { t } = useTranslation();

  const { id: propertyId } = useSelector(state => state.property.propertyDetails);

  const [searchedRecipient, setSearchedRecipient] = useState('');
  const [selectedRecipient, setSelectedRecipient] = useState();
  const [isSubmit, setIsSubmit] = useState(false);

  const CustomRecipientOption = props => {
    const { innerProps, data, isSelected, isFocused, selectProps } = props;
    const { getOptionLabel } = selectProps;

    const [email] = data?.emails || [];
    const phones = data?.phones || [];

    return (
      <components.Option {...props} getStyles={() => {}}>
        <div
          className={classNames(
            'relative flex items-center w-full px-4 py-3 cursor border-bottom option-wrapper',
            isSelected && 'selected',
            isFocused && 'bg-lightgray-1',
          )}
          {...innerProps}>
          <div className="flex-column row-gap-1 w-full">
            <div className="flex items-center justify-between">
              <label className="inter-400-text natural-900-text option-text one-line fw-500">
                {getOptionLabel(data)}
              </label>
              <div className="bg-pink px-2_5 py-0_5 rounded-6">
                <p className="text-capitalize font-12 fw-500 color-purple line-height-20 one-line">
                  {capitalize(formatText(data?.property_contact_type))}
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <p className="font-12 one-line line-height-20 color-light-black-1 pr-2">{email?.value}</p>
              {getContactValueByType(phones, 'contact_type', 'MOBILE', 'value') && (
                <p className="pl-2 font-12 line-height-20 color-light-black-1 border-left">
                  {getContactValueByType(phones, 'contact_type', 'MOBILE', 'value')}
                </p>
              )}
            </div>
          </div>
        </div>
      </components.Option>
    );
  };

  return (
    <div className="flex-column row-gap-1">
      <QuoteRecipientWrapper>
        <div className="flex-column row-gap-1 px-4 py-2 radius-1_5 contact-info">
          <label className="inter-400-text natural-400-text font-12">{t('CONTACT_INFORMATION')}</label>
          <div className="flex items-center col-gap-6">
            <div className="flex-grow">
              <SearchableDropdown
                inputValue={searchedRecipient}
                onInputChange={setSearchedRecipient}
                placeholder="Select"
                isSearchable={true}
                isCustomSearchable={false}
                autoFocus
                openMenuOnFocus
                value={
                  selectedRecipient ? selectedRecipient : recipient ? { id: recipient.id, label: recipient.name } : null
                }
                onChange={option => onUpdateQuote('recipient', option)}
                onClose={() => {
                  setEditKey(null);
                }}
                getOptionLabel={option => {
                  return option.contact_type === 'COMPANY' ? option.company_name : option.name;
                }}
                defaultAdditional={{
                  page: 0,
                  fetchFunction: getPropertyContacts,
                  pageable: false,
                  payload: { property_id: propertyId },
                }}
                customStyle={{
                  control: {
                    height: 24,
                    minHeight: 24,
                    backgroundColor: theme.natural_150,
                    padding: '2px 8px',
                    gap: '20px',
                    borderRadius: '6px',
                    borderColor: theme.natural_300,
                  },
                  input: {
                    margin: 0,
                    padding: 0,
                  },
                  singleValue: {
                    color: theme.natural_900,
                    margin: 0,
                  },
                  valueContainer: {
                    padding: 0,
                    width: '125px',
                  },
                  indicatorsContainer: {
                    height: 16,
                    width: 16,
                    alignSelf: 'center',
                  },
                  dropdownIndicator: {
                    padding: 0,
                    height: 16,
                    width: 16,
                  },
                  loadingIndicator: {
                    display: 'none',
                  },
                  menuPortal: {
                    zIndex: 10,
                    top: ' 100%',
                    left: 0,
                    right: 0,
                    width: '100%',
                  },
                  menu: {
                    right: 0,
                    width: 400,
                  },
                }}
                customComponent={{ Option: CustomRecipientOption }}
              />
            </div>
            <div className="flex items-center col-gap-1 flex-grow">
              <IconContainer Icon={MobileIcon} iconColor="natural_500" backgroundColor="transparent" />
              <label className="inter-400-text natural-900-text">
                {getContactValueByType(selectedRecipient?.phones || [], 'contact_type', 'MOBILE', 'value')}
              </label>
            </div>
          </div>
        </div>
      </QuoteRecipientWrapper>
    </div>
  );
};

export default EditQuoteRecipient;
