import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as StarIcon } from '../../assets/icons/star.svg';
import { ReactComponent as SummaryIcon } from '../../assets/icons/summary.svg';
import { ReactComponent as Arrow } from '../../assets/images/arrow-right.svg';
import { ReactComponent as RightArrow } from '../../assets/images/arrow.svg';
import { ReactComponent as SolarBattery } from '../../assets/images/batteries.svg';
import { ReactComponent as SolarInvertor } from '../../assets/images/invertor.svg';
import { ReactComponent as SolarPanel } from '../../assets/images/panel.svg';
import Button from '../../components/common/button/button';
import Loader from '../../components/loader';
import { getFormattedNumber } from '../../helpers/utils';
import { getSolarOptions } from '../../store/features/propertySlice';
import { getProductComponents } from '../../store/features/quotesSlice';
import { addToast } from '../../store/features/toastSlice';
import SolarPanels from './solar-panels';

const summaryFamily = {
  id: 'SUMMARY',
  name: 'Summary',
  icon: {
    active: SummaryIcon,
    inactive: SummaryIcon,
  },
  showIconComponent: true,
};

const productFamilies = [
  {
    id: 'SOLAR_PANELS',
    name: 'Panels',
    icon: {
      active: SolarPanel,
      inactive: SolarPanel,
    },
    showIconComponent: true,
  },
  {
    id: 'SOLAR_INVERTORS',
    name: 'Invertors',
    icon: {
      active: SolarInvertor,
      inactive: SolarInvertor,
    },
    showIconComponent: true,
  },
  {
    id: 'SOLAR_BATTERIES',
    name: 'Battery',
    icon: {
      active: SolarBattery,
      inactive: SolarBattery,
    },
    showIconComponent: true,
  },
];

const addOnFamily = {
  id: 'ADD_ONS',
  name: 'Add-On Extras',
  icon: {
    active: StarIcon,
    inactive: StarIcon,
  },
  showIconComponent: true,
};

const EstimatedOffset = ({ optionPercentage, selectedSolarOption, setSelectedSolarOption }) => {
  const { estimate } = selectedSolarOption || {};
  const { annual_production_offset_pct } = estimate || {};
  console.log('estimate', optionPercentage, Object.keys(optionPercentage));
  return (
    <EstimatedOffsetWrapper className="px-3 gap-4 py-4 border-bottom flex items-center wrap">
      <label className="inter-600-text font-12 nowrap">Estimated electrical offset</label>
      <div className="relative bg-primary-100 flex radius-full overflow-auto">
        {Object.keys(optionPercentage).map(progress => (
          <div
            key={progress}
            className="flex-1 cursor"
            onClick={() => setSelectedSolarOption(optionPercentage[progress])}>
            <p
              className={classNames(
                'px-3 py-1 natural-900-text line-height-20 inter-500-text font-12 primary-900-text',
                parseFloat(annual_production_offset_pct) >= parseFloat(progress) && 'white-text bg-primary-500',
                parseFloat(annual_production_offset_pct) === parseFloat(progress) &&
                  'rounded-right inter-700-text font-14',
              )}>
              {progress}%
            </p>
          </div>
        ))}
      </div>
    </EstimatedOffsetWrapper>
  );
};

const SummaryDetails = ({ selectedSolarOption }) => {
  const { product } = selectedSolarOption || {};
  const { component_maps } = product || {};
  return (
    <SummaryDetailsWrapper className="flex-column row-gap-4 pxy-4 flex-1">
      {component_maps
        ?.filter(c => c.map_type !== 'ADD_ON')
        .map(main_component => (
          <div key={main_component.id} className="flex-column row-gap-1">
            <label className="inter-700-text font-12">{main_component.name}</label>
            <div className="flex-column row-gap-4">
              {main_component.components
                ?.filter(c => c.sell_type === 'BASE')
                .map(component => (
                  <div key={component.id} className="product-component border-natural-200 border px-4 py-3 radius-1_5">
                    <div className="flex gap-4 items-start">
                      <div className="pxy-1 border-natural-200 border radius-1_5 flex items-center bg-white">
                        <img
                          className="box-shadow-xl object-fit-cover"
                          src={component?.image?.url}
                          width={54}
                          height={80}
                        />
                      </div>
                      <div className="items-start justify-between flex-1 gap-4 flex-column">
                        <div className="flex-1">
                          <p className="mb-1 font-12 color-neutral-900 inter-600-text">{component.name}</p>
                          <p className="font-8 color-gray-secondary inter-400-text" style={{ maxWidth: '550px' }}>
                            {component.description}
                          </p>
                        </div>
                        <div className="tw-flex md:tw-flex-col max-md:tw-w-full tw-justify-between tw-items-center md:tw-self-stretch">
                          <div className="px-3 font-8 py-1 flex gap-1 bg-lightgray-1 color-light-black-1 radius-full">
                            <span className="inter-500-text font-8">Qty: </span>
                            <span className="inter-500-text font-8">{component.quantity}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ))}
    </SummaryDetailsWrapper>
  );
};

const Families = ({ productFamilies, selectedFamily, setSelectedFamily }) => {
  const productFamilyWithSummary = [summaryFamily, ...productFamilies, addOnFamily];

  const renderFamily = family => {
    const { id, icon, showIconComponent } = family;
    const isSelected = selectedFamily.id === id;
    const FamilyIcon = isSelected ? icon?.active : icon?.inactive;

    return (
      <div
        key={family.id}
        className={classNames(
          'flex-column flex-shrink-0 items-center cursor row-gap-1 pxy-3 border-bottom',
          isSelected && 'selected-family',
        )}
        onClick={() => setSelectedFamily(family)}>
        {showIconComponent ? (
          <FamilyIcon
            height={24}
            width={24}
            className={classNames(isSelected ? 'primary-500-text' : 'natural-400-text')}
          />
        ) : (
          <img src={FamilyIcon} alt={family.name} width={24} height={24} />
        )}
        <label
          className={classNames(
            'inter-500-text font-12 text-center',
            isSelected ? 'primary-500-text' : 'natural-700-text',
          )}>
          {family.name}
        </label>
      </div>
    );
  };

  return (
    <FamiliesWrapper className="flex-column border-right flex-shrink-0">
      {productFamilyWithSummary.map(family => renderFamily(family))}
    </FamiliesWrapper>
  );
};

const ProductsWrapper = ({
  tabComponents,
  upgradedComponents,
  baseComponent,
  otherComponents,
  currentOtherComponent,
  onPreviousComponent,
  onNextComponent,
  currentOtherComponentIndex,
  setComponent,
  onUpgradeOrAdd,
}) => {
  const dotsArray = new Array(otherComponents.length).fill('_');

  const getComponentPrice = (component, format = true) => {
    const { pricing, quantity } = component || {};
    const { prices } = pricing?.[0] || {};
    const { price, currency } = prices?.[0] || {};
    if (!format) return { price: price || 0, currency, quantity: quantity || 1 };
    return getFormattedNumber(price || 0, currency || 'GBP');
  };

  const getPriceDifference = (component, baseComponent) => {
    const { price: componentPrice, currency, quantity: componentQty } = getComponentPrice(component, false);
    const { price: baseComponentPrice, quantity: baseComponentQty } = getComponentPrice(baseComponent, false);
    const priceDiff = componentQty * componentPrice - baseComponentQty * baseComponentPrice;
    return getFormattedNumber(priceDiff, currency);
  };

  const getImageFromTab = tab => {
    switch (tab) {
      case 'SOLAR_PANELS':
        return SolarPanel;
      case 'SOLAR_INVERTORS':
        return SolarInvertor;
      case 'SOLAR_BATTERIES':
        return SolarBattery;
      default:
        return SolarPanel;
    }
  };

  const DefaultImageComponent = useMemo(() => {
    return getImageFromTab('SOLAR_PANELS');
  }, []);

  return (
    <div className=" w-full h-full overflow-auto relative">
      <div className="px-4 pt-4 flex gap-4 mb-4 items-start">
        <div className="pxy-1 border-natural-200 border radius-1_5 flex items-center bg-white">
          {baseComponent?.image?.url ? (
            <img
              className="object-fit-contain max-md:tw-w-full max-md:tw-aspect-square object-fit-cover"
              src={baseComponent?.image?.url}
              style={{
                width: '54px',
                height: '71px',
              }}
              width={42}
              height={71}
            />
          ) : (
            <DefaultImageComponent width={40} height={40} className="natural-300-text" />
          )}
        </div>
        <div className="flex-column items-start">
          <div>
            <p className="mb-1 font-12 color-neutral-900 inter-600-text">{baseComponent?.name}</p>
            {baseComponent?.description && (
              <p
                className="font-8 color-gray-secondary inter-400-text mb-5"
                style={{
                  maxWidth: '276px',
                }}>
                {baseComponent?.description}
              </p>
            )}
          </div>
          <div className="px-3 font-8 py-1 flex gap-1 bg-lightgray-1 color-light-black-1 radius-full">
            <span className="font-8 color-light-black-1 inter-500-text">Qty: </span>
            <span className="font-8 color-light-black-1 inter-500-text">{baseComponent?.quantity}</span>
          </div>
        </div>
      </div>
      <div className="px-4">
        <div className="pt-4 pl-3 pr-3 pb-3 bg-natural-50 radius-1_5 border border-natural-50 mb-4">
          <div className="flex items-center justify-between mb-4 gap-5" style={{ padding: '0 10px' }}>
            <div className="flex flex-column gap-2 items-center">
              {baseComponent?.image?.url ? (
                <div className="pxy-1 border-natural-200 border radius-1_5 flex items-center bg-white">
                  <img
                    className="object-fit-contain max-md:tw-w-full max-md:tw-aspect-square object-fit-cover"
                    src={baseComponent?.image?.url}
                    style={{
                      width: '54px',
                      height: '71px',
                    }}
                    width={42}
                    height={71}
                  />
                </div>
              ) : (
                <DefaultImageComponent width={40} height={40} className="natural-300-text" />
              )}
              <label
                className="md:tw-hidden inter-500-text natural-900-text text-center font-12"
                style={{ maxWidth: '133px' }}>
                {baseComponent?.name}
              </label>
            </div>

            <div className="flex items-center justify-center">
              <Arrow />
            </div>
            <div className="flex flex-column gap-2 items-center">
              {currentOtherComponent?.image?.url ? (
                <div className="pxy-1 border-natural-200 border radius-1_5 flex items-center bg-white">
                  <img
                    className="object-fit-contain max-md:tw-w-full max-md:tw-aspect-square object-fit-cover"
                    src={currentOtherComponent?.image?.url}
                    style={{
                      width: '54px',
                      height: '71px',
                    }}
                    width={42}
                    height={71}
                  />
                </div>
              ) : (
                <DefaultImageComponent width={40} height={40} className="natural-300-text" />
              )}
              <label
                className="md:tw-hidden inter-500-text natural-900-text text-center font-12 inline-block"
                style={{ maxWidth: '133px' }}>
                {currentOtherComponent?.name}
              </label>
            </div>
          </div>
          {currentOtherComponent?.description && (
            <p className="inter-400-text font-12 natural-500-text">{currentOtherComponent?.description}</p>
          )}
        </div>
      </div>
      <div className="px-4 flex items-center justify-between pb-4">
        <div>
          <p className="inter-600-text font-18 natural-900-text">
            {getPriceDifference(currentOtherComponent, baseComponent)}
            <label className="inter-400-text natural-500-text ml-1">to upgrade</label>
          </p>
        </div>
        <Button
          label={'Upgrade'}
          size="medium"
          className={classNames('specified-width  primary specified-width  py-2_5')}
          width="104px"
          onClick={() => onUpgradeOrAdd(currentOtherComponent)}
        />
      </div>
      {otherComponents.length > 1 && (
        <div className="sticky left-0 right-0 bottom-0 bg-white p-4 flex items-center justify-between">
          <div
            onClick={onPreviousComponent}
            className={` flex items-center radius-100 grey-text-04 cursor justify-center border border-neutral-200 ${
              currentOtherComponentIndex === 0 && 'disabled disabled-next-prev'
            }`}
            style={{ height: '32px', width: '32px' }}>
            <RightArrow className="rotate-180" width={12} height={12} />
          </div>
          <div className="flex items-center gap-6px">
            {dotsArray.map((_, index) => {
              return (
                <span
                  className={`radius-100 h-1_2 cursor w-1_2 block  ${
                    currentOtherComponentIndex === index ? 'bg-primary-400' : 'gray-avatar'
                  }`}
                  key={index}
                  onClick={() => setComponent(index)}
                />
              );
            })}
          </div>
          <div
            onClick={onNextComponent}
            className={`flex items-center radius-100 grey-text-04 cursor justify-center border border-neutral-200 ${
              currentOtherComponentIndex === otherComponents.length - 1 && 'disabled disabled-next-prev'
            }`}
            style={{ height: '32px', width: '32px' }}>
            <RightArrow className width={12} height={12} />
          </div>
        </div>
      )}
    </div>
  );
};

const getComponentPrice = (component, format = true) => {
  const { pricing, quantity } = component || {};
  const { prices } = pricing?.[0] || {};
  const { price, currency } = prices?.[0] || {};
  if (!format) return { price: price || 0, currency, quantity: quantity || 1 };
  return getFormattedNumber(price || 0, currency || 'GBP');
};

const AddOnsExtra = ({ addOnComponents, upgradedComponents, onUpgradeOrAdd }) => {
  if (addOnComponents.length === 0) return null;

  return (
    <div className="p-4 flex-column gap-2">
      {addOnComponents.map(addOn => (
        <div key={addOn.id} className="flex-column radius-3 pxy-3 gap-3 border">
          <div className="flex gap-3 bg-white ">
            <div className="border radius-1_5 box-shadow-xl bg-white">
              <img className="object-fit-contain h-full" src={addOn?.image?.url} width={80} />
            </div>
            <div className="flex-column">
              <label className="inter-700-text font-14 mb-1 natural-900-text">{addOn?.name}</label>
              <label className="tw-block font-14 md:tw-hidden inter-500-text natural-900-text">
                {addOn?.description}
              </label>
            </div>
          </div>
          <div className="flex-column flex-1 row-gap-1">
            <p className="tw-block tw-mt-2 tw-mb-4 md:tw-hidden inter-400-text natural-500-text font-12">
              {addOn?.reason}
            </p>
          </div>
          <div className="flex justify-between row-gap-4 items-center">
            <label className="inter-700-text font-18 natural-900-text">{getComponentPrice(addOn)}</label>
            <Button
              label={upgradedComponents.addOns?.includes(addOn) ? 'Remove' : 'Add'}
              size="medium"
              className={classNames(
                'specified-width px-4',
                upgradedComponents.addOns?.includes(addOn) ? 'negative' : 'primary',
              )}
              width="120px"
              onClick={() => onUpgradeOrAdd(addOn, true)}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export const QuoteSolarApi = ({
  quoteDetails,
  setSelectedSolarOptionProp = () => {},
  setSolarOptionsProp = () => {},
}) => {
  const dispatch = useDispatch();

  const { propertyDetails } = useSelector(state => state.property);
  const [selectedFamily, setSelectedFamily] = useState(summaryFamily);
  const [solarOptions, setSolarOptions] = useState();
  const [selectedSolarOption, setSelectedSolarOption] = useState();
  const [upgradedComponents, setUpgradedComponents] = useState({});
  const [productComponents, setProductComponents] = useState([]);
  const [currentOtherComponentIndex, setCurrentOtherComponentIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const tabComponents = productComponents?.find(component => component.tag === selectedFamily.id)?.components || [];
  const baseComponent = tabComponents?.find(component => component?.sell_type === 'BASE');
  const otherComponents = tabComponents?.filter(component => component?.sell_type !== 'BASE') || [];
  const currentOtherComponent = otherComponents[currentOtherComponentIndex];

  const optionPercentage = useMemo(() => {
    if (!solarOptions?.options) return {};
    return solarOptions.options.reduce((acc, item) => {
      const { annual_production_offset_pct } = item?.estimate || {};
      const offsetPct = parseFloat(annual_production_offset_pct).toFixed(2);
      acc[offsetPct] = item;
      return acc;
    }, {});
  }, [solarOptions]);

  const addOnComponents = useMemo(() => {
    return productComponents?.find(component => component.tag === 'SOLAR_UPGRADES')?.components || [];
  }, [productComponents]);

  useEffect(() => {
    if (!selectedSolarOption?.product?.id) return;
    setLoading(true);
    dispatch(
      getProductComponents({ product_id: selectedSolarOption?.product?.id, params: { include_pricing: true } }),
    ).then(data => {
      setProductComponents(data);
      setLoading(false);
    });
  }, [selectedSolarOption]);

  const fetchSolarOptions = () => {
    setLoading(true);
    dispatch(getSolarOptions({ property_id: propertyDetails.id }))
      .then(data => {
        const { options } = data || {};
        const { products } = quoteDetails || {};
        const firstProductId = products?.find(p => p?.product?.id)?.product?.id;
        const firstOption =
          firstProductId && options ? options?.find(o => o?.product?.id === firstProductId) : options?.[0] || {};
        setSelectedSolarOption(firstOption);
        setSolarOptions(data);
        setLoading(false);
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: 'Failed to fetch solar options', id: nanoid() }));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchSolarOptions();

    return () => {
      setSolarOptions(null);
      setSelectedSolarOption(null);
    };
  }, []);

  useEffect(() => {
    setSolarOptionsProp(solarOptions);
  }, [solarOptions]);

  useEffect(() => {
    setSelectedSolarOptionProp(selectedSolarOption);
  }, [selectedSolarOption]);

  const onUpgradeOrAdd = (component, isAddOn = false) => {
    const selectedTab = selectedFamily.id;
    if (isAddOn) {
      const addedAddOns = upgradedComponents?.addOns || [];
      const isAlreadyAdded = addedAddOns.includes(component);
      if (isAlreadyAdded) {
        const updatedAddOns = addedAddOns.filter(addOn => addOn !== component);
        setUpgradedComponents({ ...upgradedComponents, addOns: updatedAddOns });
      } else {
        setUpgradedComponents({ ...upgradedComponents, addOns: [...addedAddOns, component] });
      }
    } else {
      const isAlreadyAdded = upgradedComponents[selectedTab] && upgradedComponents[selectedTab] === component;
      if (isAlreadyAdded) {
        setUpgradedComponents({ ...upgradedComponents, [selectedTab]: null });
      } else {
        setUpgradedComponents({ ...upgradedComponents, [selectedTab]: component });
      }
    }
  };

  const onPreviousComponent = () => {
    const isDisabled = currentOtherComponentIndex === 0;
    if (isDisabled) return;
    setCurrentOtherComponentIndex(currentOtherComponentIndex - 1);
  };

  const setComponent = index => {
    setCurrentOtherComponentIndex(index);
  };

  const onNextComponent = () => {
    const isDisabled = currentOtherComponentIndex === otherComponents.length - 1;
    if (isDisabled) return;
    setCurrentOtherComponentIndex(currentOtherComponentIndex + 1);
  };

  return (
    <Fragment>
      <div className="bg-white radius-2 flex-column data-wrapper">
        <label className="inter-600-text font-16 border-bottom flex pb-1 mx-3 pt-3">Equipment</label>
        <EstimatedOffset
          optionPercentage={optionPercentage}
          selectedSolarOption={selectedSolarOption}
          setSelectedSolarOption={setSelectedSolarOption}
        />
        {loading ? (
          <Loader />
        ) : (
          solarOptions && (
            <div className="flex flex-1 family-product-wrapper">
              <Families
                selectedFamily={selectedFamily}
                setSelectedFamily={setSelectedFamily}
                productFamilies={productFamilies}
              />
              {selectedFamily.id === 'SUMMARY' && (
                <SummaryDetails selectedSolarOption={selectedSolarOption} productFamilies={productFamilies} />
              )}
              {selectedFamily.id === 'ADD_ONS' && (
                <AddOnsExtra
                  addOnComponents={addOnComponents}
                  onUpgradeOrAdd={onUpgradeOrAdd}
                  upgradedComponents={upgradedComponents}
                />
              )}
              {selectedFamily.id !== 'SUMMARY' && selectedFamily.id !== 'ADD_ONS' && (
                <ProductsWrapper
                  productComponents={productComponents}
                  tabComponents={tabComponents}
                  baseComponent={baseComponent}
                  otherComponents={otherComponents}
                  currentOtherComponent={currentOtherComponent}
                  upgradedComponents={upgradedComponents}
                  onUpgradeOrAdd={onUpgradeOrAdd}
                  onPreviousComponent={onPreviousComponent}
                  onNextComponent={onNextComponent}
                  currentOtherComponentIndex={currentOtherComponentIndex}
                  setComponent={setComponent}
                />
              )}
            </div>
          )
        )}
      </div>
    </Fragment>
  );
};

const QuoteSolar = () => {
  const { quoteDetails } = useSelector(state => state.quotes);
  const [solarOptionsProp, setSolarOptionsProp] = useState();
  const [selectedSolarOptionProp, setSelectedSolarOptionProp] = useState();

  return (
    <Fragment>
      <SolarPanels solarOptions={solarOptionsProp} selectedSolarOption={selectedSolarOptionProp} />
      <QuoteSolarWrapper className="absolute">
        <QuoteSolarApi
          quoteDetails={quoteDetails}
          setSelectedSolarOptionProp={setSelectedSolarOptionProp}
          setSolarOptionsProp={setSolarOptionsProp}
        />
      </QuoteSolarWrapper>
    </Fragment>
  );
};

const QuoteSolarWrapper = styled.div`
  left: 8px;
  top: 8px;
  height: calc(100% - 16px);
  overflow-y: auto;

  .data-wrapper {
    min-width: 400px;
    width: 400px;
    height: 100%;
  }

  .box-shadow-xl {
    box-shadow: 0px 8px 10px -6px rgba(16, 24, 40, 0.1), 0px 20px 25px -5px rgba(16, 24, 40, 0.1);
  }

  .family-product-wrapper {
    flex: 1;
    overflow-y: auto;
  }
`;

const EstimatedOffsetWrapper = styled.div``;

const FamiliesWrapper = styled.div`
  min-width: 72px;
  width: 72px;
  overflow-y: auto;

  .selected-family {
    border-bottom: 2px solid ${({ theme }) => theme.primary_500};
  }
`;

const SummaryDetailsWrapper = styled.div`
  overflow-y: auto;

  .qty-wrapper {
    height: auto;
    padding: 4px 8px;
  }
`;

export default QuoteSolar;
