import classNames from 'classnames';
import { t } from 'i18next';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import Button from '../../components/common/button/button';
import EmailSuggestions from '../../components/common/email-suggestions';
import SearchableDropdown from '../../components/common/searchable-dropdown';
import CustomTooltip from '../../components/common/tooltip-new';
import { OrganisationContext } from '../../context/organisationContext';
import { initModal } from '../../helpers/utils';
import { getEvidences } from '../../store/features/propertySlice';
import { sendProposal } from '../../store/features/quotesSlice';
import { addToast } from '../../store/features/toastSlice';

const LabelWithText = ({ labelText, text, lableClass }) => (
  <>
    <div className="flex justify-between col-gap-4">
      <label className="inter-400-text font-12 line-height-20 w-30 natural-500-text">{labelText}:</label>
      {labelText === t('SENT') ? (
        <label className={classNames('inter-400-text font-12 line-height-20 natural-900-text', lableClass)}>
          {text}
        </label>
      ) : (
        <CustomTooltip
          id={text}
          wrapperClassName="tooltip-wrapper one-line"
          content={
            <label className={classNames('inter-400-text font-12 line-height-20 natural-900-text', lableClass)}>
              {text}
            </label>
          }>
          <p className={classNames('inter-400-text font-12 natural-900-text text-ellipsis', lableClass)}>{text}</p>
        </CustomTooltip>
      )}
    </div>
  </>
);

const RenderLogData = ({ eventLogData, setSelectEvent, selectEvent }) => {
  const { t } = useTranslation();

  return eventLogData?.content?.map(logData => {
    const { event_data, id, performed_on } = logData || {};
    const { email, cc, bcc } = event_data;

    const sentDate = moment.unix(performed_on).format('DD.MM.YYYY HH:mm');
    const isSelected = selectEvent && selectEvent.id === id;

    const onSelect = () => {
      setSelectEvent(isSelected ? null : logData);
    };

    return (
      <div
        key={id}
        className={classNames(
          'px-4 py-3 flex-column row-gap-1 cursor border radius-2',
          isSelected && 'bg-primary-50 select-event',
        )}
        onClick={onSelect}>
        {email && (
          <LabelWithText labelText={t('SENT_TO')} text={email} lableClass="bg-natural-150 radius-full px-1_5" />
        )}
        {cc && <LabelWithText labelText={t('CC')} text={cc} lableClass="bg-natural-150 radius-full px-1_5" />}
        {bcc && <LabelWithText labelText={t('BCC')} text={bcc} lableClass="bg-natural-150 radius-full px-1_5" />}
        {sentDate && <LabelWithText labelText={t('SENT')} text={sentDate} />}
      </div>
    );
  });
};

const CustomOptions = ({ innerProps, data, isSelected, selectProps }) => {
  const {
    evidence_date: { created_on },
    name: file_name,
  } = data;
  return (
    <div
      className={classNames(
        'flex items-center w-full px-4 py-3 cursor border-bottom option-wrapper',
        isSelected && 'selected',
      )}
      {...innerProps}>
      <label className="inter-400-text one-line flex-1">{file_name}</label>
      <label className="inter-400-text font-12 natural-500-text nowrap">
        {moment.unix(created_on).format('MMM DD, YYYY HH:mm')}
      </label>
    </div>
  );
};

const ShareProposal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { setModal, modal } = useContext(OrganisationContext);
  const { eventLogData, solar_id, property_id, onSuccess = () => {} } = modal?.content;

  const [selectEvent, setSelectEvent] = useState(null);
  const [loading, setLoading] = useState(false);

  const [proposalData, setProposalData] = useState({
    evidence: null,
    to: [],
    cc: [],
    bcc: [],
    subject: '',
    message: '',
  });

  const { evidence, to, cc, bcc, subject, message } = proposalData;

  const disabledButton = !subject.trim() || !message.trim() || !to.length || !evidence;

  useEffect(() => {
    if (selectEvent?.id) {
      const { event_data } = selectEvent;
      const { email: data_to, cc: data_cc, bcc: data_bcc, evidence, subject, message } = event_data || {};
      const to = data_to ? data_to.split(',').map(value => ({ value, isAdded: true })) : [];
      const cc = data_cc ? data_cc.split(',').map(value => ({ value, isAdded: true })) : [];
      const bcc = data_bcc ? data_bcc.split(',').map(value => ({ value, isAdded: true })) : [];

      setProposalData({
        evidence,
        to,
        cc,
        bcc,
        subject,
        message,
      });
    }
  }, [selectEvent]);

  const handleSendProposal = () => {
    const to_request = to.map(({ value }) => value);
    const cc_request = cc.map(({ value }) => value);
    const bcc_request = bcc.map(({ value }) => value);
    const to_as_string = to_request.join(',');
    const cc_as_string = to_request.join(',');
    const bcc_as_string = to_request.join(',');

    const request = {
      to: to_request,
      cc: cc_request,
      bcc: bcc_request,
      subject: subject.trim(),
      message: message.trim(),
      to_as_string: to_as_string,
      cc_as_string: cc_as_string,
      bcc_as_string: bcc_as_string,
    };
    setLoading(true);
    dispatch(sendProposal({ id: evidence.id, request }))
      .then(data => {
        onSuccess();
        dispatch(addToast({ error: false, text: t('PROPOSAL_SENT_SUCCESSFULLY') }));
        setModal(initModal);
      })
      .catch(() => dispatch(addToast({ error: true, text: t('ERROR_WHILE_SEND_PRORPOSAL') })))
      .finally(() => setLoading(false));
  };

  const renderInput = (key, label, placeholder, value, type, optional) => {
    return (
      <div className="flex-column w-full input-container relative">
        <div className="mb-1 one-line flex items-center col-gap-2 w-full">
          <label className="inter-500-text natural-900-text line-height-20 font-14">{label}</label>
          {optional && <label className="inter-400-text natural-400-text line-height-20 font-14">({optional})</label>}
        </div>
        {type === 'textarea' ? (
          <>
            <textarea
              value={value || ''}
              className={classNames(
                'inter-400-text natural-900-text w-full textarea px-3 py-2 border',
                (label === 'Terms' || label === 'Contact Details') && 'textarea-scroll',
              )}
              placeholder={placeholder}
              onChange={({ target: { value } }) => {
                setProposalData({
                  ...proposalData,
                  [key]: value,
                });
              }}
            />
          </>
        ) : (
          <input
            className="inter-400-text natural-900-text input one-line w-full px-3 py-1 border input-element"
            value={value || ''}
            placeholder={placeholder}
            type={type}
            onChange={({ target: { value } }) => {
              setProposalData({
                ...proposalData,
                [key]: value,
              });
            }}
          />
        )}
      </div>
    );
  };

  return (
    <ShareProposalWrapper className="flex-column">
      <div className="flex justify-between px-10 pt-8 pb-5 border-bottom">
        <p className="inter-700-text natural-900-text font-24 mb-2 line-height-32">{t('SHARE_PROPOSAL')}</p>
        <Button
          label={t('DOWNLOAD_PDF')}
          className="secondary specified-width"
          size="medium"
          width="128px"
          height="32px"
          afterIcon={<DownloadIcon className="primary-500-text" width={14} height={14} />}
          onClick={() => {}}
          fontSize="12px"
        />
      </div>
      <div className="flex col-gap-9 px-10 pt-8 pb-10 overflow-scroll thin-scrollbar custom-scrollbar">
        <div className="proposal-left flex-column row-gap-5">
          <div className="border-bottom pb-2">
            <label className="inter-600-text font-18 line-height-28 natural-900-text">{t('LAST_SHARE')}</label>
          </div>
          <RenderLogData eventLogData={eventLogData} setSelectEvent={setSelectEvent} selectEvent={selectEvent} />
        </div>
        <div className="proposal-right flex-1 flex-column row-gap-5">
          <div className="border-bottom pb-2">
            <label className="inter-600-text font-18 line-height-28 natural-900-text">{t('NEW_SHARE')}</label>
          </div>
          <div className="flex-column row-gap-5">
            <SearchableDropdown
              placeholder={t('SELECT_PROPOSAL')}
              name={t('PROPOSAL')}
              isCustomSearchable={false}
              isSearchable={false}
              value={evidence}
              onChange={option => {
                setProposalData({ ...proposalData, evidence: option });
              }}
              defaultAdditional={{
                fetchFunction: getEvidences,
                pageable: false,
                payload: {
                  forFetchOnly: true,
                  params: { solar_design_id: solar_id },
                },
                formatOptions: options => {
                  return options
                    ?.filter(pdf => pdf.status !== 'FAILED')
                    ?.sort((a, b) => {
                      const {
                        evidence_date: { created_on: created_on_A },
                      } = a;
                      const {
                        evidence_date: { created_on: created_on_B },
                      } = b;
                      return moment.unix(created_on_B).diff(moment.unix(created_on_A));
                    });
                },
              }}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              customComponent={{ Option: CustomOptions }}
            />
            <EmailSuggestions
              name={t('TO')}
              placeholder={t('EMAIL_PLACEHOLDER')}
              property_id={property_id}
              selectedEmails={to}
              ignoreEmails={[...cc, ...bcc]}
              setSelectedEmails={emails => setProposalData({ ...proposalData, to: emails })}
            />
            <EmailSuggestions
              name={t('CC')}
              sub_name={t('OPTIONAL')}
              placeholder={t('EMAIL_PLACEHOLDER')}
              property_id={property_id}
              selectedEmails={cc}
              ignoreEmails={[...to, ...bcc]}
              setSelectedEmails={emails => setProposalData({ ...proposalData, cc: emails })}
            />
            <EmailSuggestions
              name={t('BCC')}
              sub_name={t('OPTIONAL')}
              placeholder={t('EMAIL_PLACEHOLDER')}
              property_id={property_id}
              selectedEmails={bcc}
              ignoreEmails={[...to, ...cc]}
              setSelectedEmails={emails => setProposalData({ ...proposalData, bcc: emails })}
            />
            {renderInput('subject', t('SUBJECT'), t('SUBJECT_PLACEHOLDER'), subject, 'text')}
            {renderInput('message', t('MESSAGE'), t('MESSAGE_PLACEHOLDER'), message, 'textarea')}
          </div>
        </div>
      </div>
      <div className="flex px-10 py-6 justify-between w-full border-top">
        <Button
          className="primary-grey specified-width"
          label={'Cancel'}
          onClick={() => setModal(initModal)}
          size="large"
          borderRadius="100px"
          width="100px"
        />
        <div className="flex gap-3">
          <Button
            className="primary specified-width"
            label={t('SHARE')}
            disabled={disabledButton}
            loading={loading}
            onClick={handleSendProposal}
            size="large"
            borderRadius="100px"
            width="100px"
          />
        </div>
      </div>
    </ShareProposalWrapper>
  );
};

const ShareProposalWrapper = styled.div`
  width: 900px;
  max-height: 90dvh;
  .proposal-left {
    width: 277px;
  }
  .select-event {
    border: 1px solid ${({ theme }) => theme?.primary_300};
  }
  .input,
  .textarea {
    color: #171717;
  }
`;

export default ShareProposal;
