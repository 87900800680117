import styled from 'styled-components';

export const BoxWrapper = styled.div`
  height: 88px;
  border: 1px solid ${({ theme }) => theme.lightGrey};

  :hover {
    border: 1px solid ${({ theme }) => theme.primaryBlue};
  }

  .subtitle-text {
    color: ${({ theme }) => theme.greyText2};
  }
`;
