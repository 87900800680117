import classNames from 'classnames';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { formatText } from '../../helpers/utils';
import { useQuery } from '../../hooks/useQuery';
import { getIntegrationList } from '../../store/features/integrationsSlice';
import {
  createQuote,
  createQuoteAttachments,
  getProductFamilies,
  getQuoteAttachments,
  getQuoteDetails,
  getQuoteTemplateDetail,
  setProductFamilies,
  setQuoteDetails,
  updateQuoteDetails,
} from '../../store/features/quotesSlice';
import { addToast } from '../../store/features/toastSlice';
import GoogleMaps from '../common/google-maps';
import SkeletonTransition from '../common/skeleton-transition';
import QuoteDetailsHeader from './QuoteDetailsHandler';
import QuoteDetailsInfo from './QuoteDetailsInfo';
import QuoteDetailsPayment from './QuoteDetailsPayment';
import QuoteDetailsProducts from './QuoteDetailsProducts';
import QuoteTermWrapper from './QuoteTermWrapper';
import QuoteTotalValues from './QuoteTotalValues';
import { QuoteDetailsWrapper } from './wrapper';

const QUOTE_DETAILS_INITIAL = {
  code: '',
  description: '',
  quote_type: 'COST',
  status: 'DRAFT',
  products: [],
  created_on: moment().startOf('day').unix(),
  issued_date: moment().startOf('day').unix(),
  expiry_date: moment().add(30, 'days').startOf('day').unix(),
};

const QuoteDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { board_id, workitem_id, quote_id } = useParams();

  const query = useQuery();
  const showQuoteSolar = query.get('showQuoteSolar');

  const isNewQuote = quote_id === 'new';

  const { propertyDetails, jobDetails } = useSelector(state => state.property);
  const { quoteDetails } = useSelector(state => state.quotes);
  const { workitemDetails } = useSelector(state => state.board);
  const { user: userAuth } = useSelector(state => state.user);

  const { engagement: { id: engagement_id } = {} } = workitemDetails || { engagement: {} };
  const { user: { id: userId } = {} } = userAuth || { user: {} };

  const { job_type } = jobDetails || {};

  const [quoteDetailsInitial, setQuoteDetailsInitial] = useState(isNewQuote ? QUOTE_DETAILS_INITIAL : {});
  const [integrations, setIntegrations] = useState([]);
  const [showActionButtons, setShowActionButtons] = useState(false);
  const [loadingQuote, setLoadingQuote] = useState(!isNewQuote);
  const [loading, setLoading] = useState(false);
  const [showSolarMap, setShowSolarMap] = useState(false);
  const [quotesTemplatetList, setQuoteTemplateList] = useState([]);
  const [defaultTemplate, setDefaultTemplate] = useState();

  const { status, integration, quote_template } = quoteDetailsInitial || {};

  const [isQuoteEditable, setIsQuoteEditable] = useState(isNewQuote);
  const notEditableQuoteInfo = t('YOU_CANNOT_EDIT_A_QUOTE', { status: formatText(status) });

  const calculateTotalAmountOfProducts = products => {
    return products.reduce(
      (acc, product) => {
        const { discount_rate, total_net, taxation_scheme } = product;
        const { rate } = taxation_scheme || { rate: 0 };

        const discountAmount = total_net * (discount_rate / 100) || 0;
        const taxableAmount = (total_net - discountAmount) * (rate / 100) || 0;

        acc.total_net += Math.abs(total_net);
        acc.total_discount += discountAmount || 0;
        acc.total_tax += taxableAmount || 0;
        acc.total_amount += Math.abs(total_net) + (taxableAmount || 0) - (discountAmount || 0);
        return acc;
      },
      { total_net: 0, total_discount: 0, total_tax: 0, total_amount: 0 },
    );
  };

  const calculateProductAmounts = (products, quoteIntegration) => {
    return products.map(productData => {
      const { discount_rate, total_net, product, taxation_scheme, line_item_type, integration } = productData;
      const { rate } = taxation_scheme || { rate: 0 };
      const discountAmount = total_net * (discount_rate / 100) || 0;
      const taxableAmount = (total_net - discountAmount) * (rate / 100) || 0;
      const lineItemType =
        line_item_type === 'CATALOG'
          ? integration?.id === quoteIntegration?.id
            ? 'CATALOG'
            : 'OTHER'
          : line_item_type;

      return {
        ...productData,
        taxation_scheme: taxation_scheme?.id
          ? {
              ...taxation_scheme,
              integration: quoteIntegration
                ? {
                    id: quoteIntegration.id,
                  }
                : null,
            }
          : null,
        product: product?.id ? { id: product?.id } : null,
        discount_amount: total_net * (discount_rate / 100) || 0,
        total_net: Math.abs(total_net) || 0,
        total_tax: Math.abs(taxableAmount) || 0,
        total_amount: Math.abs(total_net) + total_net * (rate / 100) - total_net * (discount_rate / 100) || 0,
        line_item_type: lineItemType,
      };
    });
  };

  const fetchUserDetails = async templateID => {
    setLoading(true);
    dispatch(getQuoteTemplateDetail({ quote_template_id: templateID }))
      .then(data => setQuoteDetailsInitial(prev => ({ ...prev, term: data?.terms })))
      .catch(() =>
        dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_QUOTE_TEMPLATE_DETAILS'), id: nanoid() })),
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (quote_template?.id) {
      fetchUserDetails(quote_template?.id);
    }
  }, [quote_template?.id]);

  const fetchQuoteDetails = () => {
    setLoadingQuote(true);
    dispatch(getQuoteDetails({ quote_id: quote_id }))
      .then(data => {
        setQuoteDetailsInitial({ ...data });
        dispatch(getQuoteAttachments({ id: quote_id })).then(attachments => {
          setQuoteDetailsInitial(prev => {
            return { ...prev, attachments: attachments?.length > 0 ? attachments : [] };
          });
        });
        setIsQuoteEditable(data.status === 'DRAFT' || data.status === 'ESTIMATE');
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_QUOTE_DETAILS'), id: nanoid() }));
      })
      .finally(() => setLoadingQuote(false));
  };

  const fetchIntegrations = () => {
    dispatch(getIntegrationList({ params: { action: 'QUOTE_CREATE' } }))
      .then(data => {
        setIntegrations(data);
        if (data?.length === 0) {
          setQuoteDetailsInitial(prev => ({
            ...prev,
            integration: { label: 'None', id: null, name: 'None', value: null, connector: {} },
          }));
        }
      })
      .catch(() => {
        setIntegrations([]);
      });
  };

  const fetchProductFamilies = () => {
    const { quote_category } = job_type || {};
    dispatch(getProductFamilies({ params: { tag: quote_category } })).catch(() => {});
  };

  useEffect(() => {
    if (!isNewQuote) {
      fetchQuoteDetails();
    }
    fetchIntegrations();

    return () => {
      dispatch(setQuoteDetails({}));
    };
  }, [quote_id]);

  useEffect(() => {
    if (job_type) {
      fetchProductFamilies();
    }

    return () => {
      dispatch(setProductFamilies([]));
    };
  }, [job_type]);

  useEffect(() => {
    const isQuoteInitialsUpdated = JSON.stringify(quoteDetailsInitial) !== JSON.stringify(quoteDetails);
    setShowActionButtons(isQuoteInitialsUpdated);
  }, [quoteDetailsInitial]);

  const onCancelUpdates = () => {
    if (isNewQuote) {
      navigate(`/boards/board/${board_id}/workitem/${workitem_id}?selectedTab=QUOTES`);
      return;
    } else {
      setQuoteDetailsInitial({ ...quoteDetails });
      setIsQuoteEditable(quoteDetailsInitial.status === 'DRAFT' || quoteDetailsInitial.status === 'ESTIMATE');
    }
  };

  const onSaveUpdates = () => {
    setLoading(true);
    const { products, recipient, recipients, payment_terms_template, quote_template, code, ...rest } =
      quoteDetailsInitial;
    const isCatalogProductHasProduct = products?.every(product =>
      product?.line_item_type === 'CATALOG' ? product?.product?.id : true,
    );
    if (!isCatalogProductHasProduct) {
      dispatch(addToast({ error: true, text: t('MUST_SELECT_PRODUCT_WHEN_ADDING_CATALOG'), id: nanoid() }));
      setLoading(false);
      return;
    }
    const request = {
      ...rest,
      ...calculateTotalAmountOfProducts(products || []),
      code,
      integration: integration?.id ? { id: integration.id } : null,
      products: calculateProductAmounts(products || [], integration),
      payment_terms_template: payment_terms_template?.id ? { id: payment_terms_template?.id } : null,
      quote_template:
        quote_template?.id || defaultTemplate?.id ? { id: quote_template?.id || defaultTemplate?.id } : null,
      recipients: recipient?.id ? [{ contact: { id: recipient?.id }, requires_acceptance: true }] : null,
    };
    if (isNewQuote) {
      if (!recipient?.id) {
        dispatch(addToast({ error: true, text: t('PLEASE_ADD_RECIPIENT_QUOTE'), id: nanoid() }));
        setLoading(false);
        return;
      }
      dispatch(
        createQuote({
          engagement_id: engagement_id,
          request: {
            ...request,
            code: code?.trim() ? code : null,
            salesperson: { id: userId },
          },
        }),
      )
        .then(data => {
          dispatch(addToast({ error: false, text: t('SUCCESSFULLY_QUOTE_ADDED'), id: nanoid() }));
          quoteDetails?.attachments?.length > 0 &&
            Promise.all(
              quoteDetails.attachments.map(async file => {
                await dispatch(
                  createQuoteAttachments({
                    id: data.id,
                    request: [{ name: file.name, media: { id: file.media_id } }],
                  }),
                );
              }),
            );
          navigate(`/boards/board/${board_id}/workitem/${workitem_id}/quotes/${data.id}`, { replace: true });
        })
        .catch(() => {
          dispatch(addToast({ error: true, text: t('ERROR_WHILE_ADDING_QUOTE'), id: nanoid() }));
        })
        .finally(() => setLoading(false));
    } else {
      dispatch(updateQuoteDetails({ quote_id, request }))
        .then(data => {
          dispatch(addToast({ error: false, text: t('QUOTE_DETAILS_UPDATED_SUCCESSFULLY'), id: nanoid() }));
          setQuoteDetailsInitial({ ...data });
          setIsQuoteEditable(data.status === 'DRAFT' || data.status === 'ESTIMATE');
        })
        .catch(() => {
          dispatch(addToast({ error: true, text: t('ERROR_WHILE_UPDATING_QUOTE_DETAILS'), id: nanoid() }));
        })
        .finally(() => setLoading(false));
    }
  };

  const onUpdateQuoteProducts = products => {
    setQuoteDetailsInitial({ ...quoteDetailsInitial, products });
  };

  useEffect(() => {
    const defaultSelectedTemplate = quotesTemplatetList.find(template => template?.is_default);
    setDefaultTemplate(defaultSelectedTemplate);
  }, [quotesTemplatetList]);

  return (
    <div className={classNames('px-6 py-5 flex-1 overflow-scroll', showSolarMap && 'flex-column')}>
      <SkeletonTransition loading={loadingQuote} width={'100%'} height={'100%'} loaderClassName={'h-full'}>
        <QuoteDetailsWrapper
          id="quote-wrapper"
          className={classNames(classNames('border radius-3', showSolarMap && 'flex-1 flex-column'))}>
          <QuoteDetailsHeader
            loading={loading}
            quoteDetailsInitial={quoteDetailsInitial}
            setQuoteDetailsInitial={setQuoteDetailsInitial}
            onCancelUpdates={onCancelUpdates}
            onSaveUpdates={onSaveUpdates}
            setIsQuoteEditable={setIsQuoteEditable}
            integrations={integrations}
            showActionButtons={showActionButtons}
            setShowActionButtons={setShowActionButtons}
            notEditableQuoteInfo={notEditableQuoteInfo}
            isNewQuote={isNewQuote}
            showSolarMap={showSolarMap}
            setShowSolarMap={setShowSolarMap}
            jobDetails={jobDetails}
          />
          {showSolarMap ? (
            <div className="overflow-scroll pxy-2 flex flex-column flex-1">
              <div className={classNames('flex items-center justify-center radius-3 flex-1')}>
                <GoogleMaps coordinates={[propertyDetails.location]} isQuoteSolar={true} isSolar={false} />
              </div>
            </div>
          ) : (
            <Fragment>
              <QuoteDetailsInfo
                quoteDetailsInitial={quoteDetailsInitial}
                setQuoteDetailsInitial={setQuoteDetailsInitial}
                isQuoteEditable={isQuoteEditable}
                notEditableQuoteInfo={notEditableQuoteInfo}
                isNewQuote={isNewQuote}
                setQuoteTemplateList={setQuoteTemplateList}
                defaultTemplate={defaultTemplate}
              />
              <QuoteDetailsProducts
                isQuoteEditable={isQuoteEditable}
                onUpdateQuoteProducts={onUpdateQuoteProducts}
                products={quoteDetailsInitial?.products}
                notEditableQuoteInfo={notEditableQuoteInfo}
                integration={integration}
              />
              <div className="flex justify-between pxy-6 col-gap-6">
                <div className="flex-1">
                  <QuoteTermWrapper
                    isNewQuote={isNewQuote}
                    isQuoteEditable={isQuoteEditable}
                    notEditableQuoteInfo={notEditableQuoteInfo}
                    quoteDetailsInitialTerm={quoteDetailsInitial?.term}
                    setQuoteDetailsInitial={setQuoteDetailsInitial}
                  />
                  <QuoteDetailsPayment
                    quoteDetailsInitial={quoteDetailsInitial}
                    setQuoteDetailsInitial={setQuoteDetailsInitial}
                    isQuoteEditable={isQuoteEditable}
                    notEditableQuoteInfo={notEditableQuoteInfo}
                  />
                </div>
                <QuoteTotalValues products={quoteDetailsInitial?.products} />
              </div>
            </Fragment>
          )}
        </QuoteDetailsWrapper>
      </SkeletonTransition>
    </div>
  );
};

export default QuoteDetails;
