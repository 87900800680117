import classNames from 'classnames';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as AddIcon } from '../../assets/icons/add-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/icons/right-arrow.svg';
import { OrganisationContext } from '../../context/organisationContext';
import Button from '../common/button/button';
import IconContainer from '../common/icon-container';
import Segment from './segment';

const Roof = ({ info, property_id, index }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { setModal } = useContext(OrganisationContext);

  const { whole_roof, segments } = info;
  const { id: roof_id } = whole_roof || {};

  const [isCollapsed, setIsCollapsed] = useState(false);

  const segmentColors = useMemo(() => {
    return [theme.primary_500, theme.success_500, theme.orange_500, theme.error_500, theme.natural_500];
  }, []);

  const onDeleteRoof = () => {
    setModal({
      type: 'delete-roof-segment-action',
      content: {
        roof_id: roof_id,
        isSegment: false,
        property_id: property_id,
        title: `${t('ROOF_TITLE', { index: index + 1 })}?`,
        description: t('ARE_YOU_SURE_DELETE_ROOF'),
      },
    });
  };

  return (
    <RoofWrapper className="flex-column row-gap-4">
      <div className="flex items-center">
        <label className="inter-600-text flex-1">ROOF {index + 1}</label>
        <Button
          icon={<AddIcon className="primary-500-text" />}
          label="Add section"
          width="120px"
          className="secondary specified-width mr-4"
        />
        <IconContainer
          Icon={DeleteIcon}
          backgroundColor="primary_50"
          iconColor="primary_500"
          iconContainerClassname="radius-50-percent cursor mr-4"
          onClick={onDeleteRoof}
        />
        <IconContainer
          Icon={RightArrowIcon}
          iconContainerClassname={classNames('radius-50-percent cursor', isCollapsed ? 'rotate-0' : 'rotate-90')}
          onClick={() => setIsCollapsed(!isCollapsed)}
        />
      </div>
      {!isCollapsed &&
        segments.map((segment, index) => (
          <Segment
            key={segment.id}
            index={index}
            segment={segment}
            roof_id={roof_id}
            property_id={property_id}
            segmentColor={segmentColors[index % segmentColors.length]}
          />
        ))}
    </RoofWrapper>
  );
};

const RoofWrapper = styled.div``;

export default Roof;
