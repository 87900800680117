import React from 'react';
import { AutomationLogDetailLeftWrapper } from '../../../styles/components/automation-log-detail/automation-log-details.styled';
import AutomatioLogDetailBox from './automation-log-detail-box';
import AutomationLogEventDetails from './automation-log-event-details';

const AutomationLogDetailsLeft = () => {
  return (
    <AutomationLogDetailLeftWrapper className="overflow-scroll">
      <div className="left-box-wrapper border flex-column pt-5 pb-4 px-3">
        <AutomatioLogDetailBox />
      </div>
      <div className="left-box-wrapper border flex-column mt-6 pt-5 pb-4 px-3">
        <AutomationLogEventDetails />
      </div>
    </AutomationLogDetailLeftWrapper>
  );
};

export default AutomationLogDetailsLeft;
