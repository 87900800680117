import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import Select, { components } from 'react-select';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as DownIcon } from '../../../assets/images/arrow-right.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/toast-close.svg';
import Checkbox from '../checkbox';
import InputSearch from '../input-search';

const defaultCustomStyle = {
  control: {},
  valueContainer: {},
  menu: {},
  menuList: {},
};

const variants = {
  primary: {
    control: { height: '44px', borderRadius: '6px' },
  },
};

const Option = props => {
  const { innerProps, data, isSelected, isMulti, selectProps } = props;
  const { getOptionLabel } = selectProps;

  return (
    <div
      className={classNames(
        'flex items-center w-full px-4 py-3 cursor border-bottom option-wrapper',
        isSelected && 'selected',
      )}
      {...innerProps}>
      {isMulti && (
        <Checkbox isStopPropagation={false} is_checked_done checked={isSelected} className="mr-2 multi-checkbox" />
      )}
      {data.icon && <div className={classNames(data?.code ? '' : 'mr-2')}>{data.icon}</div>}
      <span
        className={classNames(
          'flex-1 inter-400-text natural-900-text option-text one-line',
          isSelected && 'inter-500-text',
        )}>
        {getOptionLabel(data)}
      </span>
    </div>
  );
};

const DropdownIndicator = props => {
  const {
    selectProps: { menuIsOpen },
  } = props;
  return (
    <components.DropdownIndicator {...props} className="mr-2">
      <DownIcon
        height={14}
        width={14}
        className={classNames(menuIsOpen ? 'rotate-270 natural-900-text' : 'rotate-90 natural-400-text')}
      />
    </components.DropdownIndicator>
  );
};

const ClearIndicator = props => {
  return <CloseIcon height={14} width={14} onClick={props.clearValue} />;
};

const MultiValue = ({ children, ...props }) => {
  const { selectProps, isMulti } = props;
  const selectedOption = children && children[0] && children[0].props && children[0].props.data;

  return (
    <components.ValueContainer {...props}>
      {isMulti && selectProps?.value?.length > 0 ? (
        <div>
          <span className="flex items-center inter-400-text natural-900-text option-text one-line">
            {selectProps?.value?.map(o => o.label).join(', ')}
          </span>
        </div>
      ) : (
        <div className="flex items-center w-full">
          {selectedOption && selectedOption.icon && (
            <div className={classNames(selectedOption?.code ? '' : 'mr-2')}>{selectedOption.icon}</div>
          )}
          <span className="flex inter-400-text natural-900-text option-text one-line">
            {selectedOption?.code ? `+${selectedOption?.code}` : children}
          </span>
        </div>
      )}
    </components.ValueContainer>
  );
};

const CustomInputSearch = ({ selectProps, ...props }) => {
  const { onInputChange, inputValue, onMenuInputFocus, isSearchable } = selectProps;

  return (
    <div>
      {isSearchable && (
        <div className="p-3 border-bottom">
          <InputSearch
            className="custom-input-search"
            value={inputValue || ''}
            onChange={value =>
              onInputChange(value, {
                action: 'input-change',
              })
            }
            onMouseDown={e => {
              e.stopPropagation();
              e.target.focus();
            }}
            onTouchStart={e => {
              e.stopPropagation();
              e.target.focus();
            }}
            onFocus={onMenuInputFocus}
            placeholder="Search"
          />
        </div>
      )}
      <components.MenuList {...props} selectProps={selectProps} />
    </div>
  );
};

const Dropdown = ({
  options = [],
  placeholder = '',
  isSearchable = false,
  onChange = () => {},
  value = null,
  isMulti = false,
  name = '',
  sub_name = '',
  is_required = false,
  className = '',
  error = false,
  customStyle = defaultCustomStyle,
  menuPlacement = 'auto',
  variant = '',
  isClearable = false,
  isDisabled = false,
  nameClassName = 'inter-500-text natural-900-text',
  customComponent = {},
  hideSelectedOptions = false,
  ...rest
}) => {
  const theme = useTheme();
  const containerRef = useRef(null);

  const [isFocused, setIsFocused] = useState(false);

  const onDomClick = e => {
    let menu = containerRef.current.querySelector('.select__menu');
    if (!containerRef.current.contains(e.target) || !menu || !menu.contains(e.target)) {
      setIsFocused(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', onDomClick);

    return () => {
      document.removeEventListener('mousedown', onDomClick);
    };
  }, []);

  const dropdownStyle = {
    control: (baseStyles, { selectProps: { menuIsOpen } }) => ({
      ...baseStyles,
      borderColor: error ? theme.additionalRed : menuIsOpen ? theme.focus_border : theme.lightGrey,
      boxShadow: error ? `0px 0px 0px 4px ${theme.error_border}` : 'none',
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minHeight: '44px',
      cursor: 'pointer',
      // boxShadow: 'none',

      '&:hover': {
        borderColor: error ? theme.additionalRed : menuIsOpen ? theme.focus_border : theme.lightGrey,
        boxShadow: `0px 0px 0px 4px ${menuIsOpen && !error ? theme.focus_border : '#fff'}`,
      },
      ...(variants[variant]?.control || {}),
      ...customStyle.control,
      ...(menuIsOpen && customStyle.controlIsOpen && { ...customStyle.controlIsOpen }),
    }),
    placeholder: baseStyles => ({
      ...baseStyles,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontFamily: 'Inter',
      color: theme.natural_400,
      fontSize: '14px',
      fontWeight: 400,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: baseStyles => ({
      ...baseStyles.dropdownIndicator,
      ...customStyle.dropdownIndicator,
    }),
    indicatorsContainer: baseStyles => ({
      ...baseStyles,
      color: theme.natural_900,
      ...customStyle.indicatorsContainer,
    }),
    menu: baseStyles => ({
      ...baseStyles,
      borderRadius: '8px',
      overflow: 'hidden',
      zIndex: 11,
      ...customStyle.menu,
    }),
    menuList: baseStyles => ({
      ...baseStyles,
      padding: '0px',
      maxHeight: '180px',
      ...customStyle.menuList,
    }),
    noOptionsMessage: baseStyles => ({
      ...baseStyles,
      fontFamily: 'Inter',
      color: theme.natural_400,
    }),
    valueContainer: baseStyles => ({
      ...baseStyles,
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'nowrap',
      fontFamily: 'Inter',
      fontSize: '14px',
      '> .select__value-container ': {
        minWidth: 'max-content',
        padding: '0 4px 0 0',
      },
      ...customStyle.valueContainer,
    }),
    container: baseStyles => ({
      ...baseStyles,
      ...customStyle.container,
    }),
    option: baseStyles => ({
      ...baseStyles,
      ...customStyle.option,
    }),
    singleValue: baseStyles => ({
      ...baseStyles,
      ...customStyle.singleValue,
    }),
    dropdownIndicator: baseStyles => ({
      ...baseStyles,
      ...customStyle.dropdownIndicator,
    }),
    menuPortal: baseStyles => ({
      ...baseStyles,
      zIndex: 9999,
    }),
  };
  return (
    <DropdownWrapper className={classNames('w-full', className)} ref={containerRef}>
      {name && (
        <div className="mb-1 one-line">
          <span className={classNames(isDisabled ? 'inter-500-text natural-400-text' : nameClassName)}>{name}</span>
          {sub_name && <span className="inter-400-text natural-400-text ml-1">{sub_name}</span>}
          {is_required && <span className="required-star ml-1">*</span>}
        </div>
      )}
      <Select
        options={options}
        placeholder={placeholder}
        isSearchable={isSearchable}
        onChange={option => {
          onChange(option);
        }}
        isDisabled={isDisabled}
        isClearable={isClearable}
        classNamePrefix={'select'}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        onMenuOpen={() => setIsFocused(true)}
        onMenuInputFocus={() => setIsFocused(true)}
        onMenuClose={() => setIsFocused(false)}
        onBlur={() => setIsFocused(false)}
        hideSelectedOptions={hideSelectedOptions}
        tabSelectsValue={false}
        value={value}
        styles={dropdownStyle}
        menuPlacement={menuPlacement}
        components={{
          Option,
          DropdownIndicator,
          ClearIndicator,
          MultiValue,
          MenuList: CustomInputSearch,
          ...customComponent,
        }}
        {...{
          menuIsOpen: isFocused || undefined,
          isFocused: isFocused || undefined,
        }}
        {...rest}
      />
    </DropdownWrapper>
  );
};

export const DropdownWrapper = styled.div`
  .option-wrapper {
    &:hover {
      background-color: ${({ theme }) => theme.natural_50};
    }
  }

  .selected {
    background-color: ${({ theme }) => theme.white};

    &:hover {
      background-color: ${({ theme }) => theme.natural_50};
    }
  }

  .select__value-container {
    padding: 2px 16px;
  }
  .multi-checkbox {
    .checkbox {
      min-width: 20px;
      min-height: 20px;
    }
  }
  .select__placeholder {
    display: flex;
    align-items: center;
  }

  .select__input {
    color: transparent !important;
  }

  .custom-input-search {
    .input {
      background-color: ${({ theme }) => theme.natural_50};
      border: 0;
    }
  }
`;

export default Dropdown;
