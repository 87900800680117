import classNames from 'classnames';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { components } from 'react-select';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as Attachment } from '../../assets/icons/attachment.svg';
import { ReactComponent as CheckDoneIcon } from '../../assets/icons/check-done-black.svg';
import { ReactComponent as CrossIcon, ReactComponent as CrossIcons } from '../../assets/icons/CrossIcons.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit-pensil.svg';
import { ReactComponent as EmptyIcon } from '../../assets/icons/empty-document.svg';
import { ReactComponent as FileIcon } from '../../assets/icons/file-icon.svg';
import { ReactComponent as ImageAdd } from '../../assets/icons/image-add.svg';
import { ReactComponent as ImageContent } from '../../assets/icons/image-content.svg';
import { ReactComponent as LeftArrow } from '../../assets/icons/left-arrow-to-left.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/icons/right-arrow.svg';
import { ReactComponent as RightIcon } from '../../assets/icons/right-Icons.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/images/menu/arrow-down-black.svg';
import { Avatar } from '../../components/common/avatar-group';
import Button from '../../components/common/button/button';
import IconContainer from '../../components/common/icon-container';
import Menu from '../../components/common/menu';
import NoData from '../../components/common/no-data';
import SkeletonTransition from '../../components/common/skeleton-transition';
import Status from '../../components/common/status';
import CustomTooltip from '../../components/common/tooltip-new';
import { OrganisationContext } from '../../context/organisationContext';

import { capitalizeFirstLetterOfWords, formatText, getUserInitials, initSideModal } from '../../helpers/utils';
import {
  getTaskComments,
  getTaskDetails,
  getTaskEvidences,
  getTaskSubTasks,
  updateTask,
} from '../../store/features/taskSlice';
import { addToast } from '../../store/features/toastSlice';
import { TaskDetailsWrapper } from '../sidemodel.styled';

import DateSelectorElement from '../../components/common/date-selector/date-selector-element';
import SearchableDropdown from '../../components/common/searchable-dropdown';
import { getJobs } from '../../store/features/jobSlice';
import { getEngagementAccess } from '../../store/features/workitemSlice';
import SubTaskItem from './sub-task-item';

const commentTabs = [
  { name: 'Comments', key: 'Comments' },
  // { name: 'Activity Logs', key: 'Activity Logs' },
];

const statusOptions = [
  { label: 'To do', value: 'TODO' },
  { label: 'In-progress', value: 'STARTED' },
  { label: 'Blocked', value: 'BLOCKED' },
  { label: 'Done', value: 'COMPLETE' },
];

const priorityOptions = [
  { label: 'Highest', value: 'VERY_HIGH' },
  { label: 'High', value: 'HIGH' },
  { label: 'Medium', value: 'MEDIUM' },
  { label: 'Low', value: 'LOW' },
  { label: 'Lowest', value: 'VERY_LOW' },
];

const TaskDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const { t } = useTranslation();

  const { sideModal, setSideModal } = useContext(OrganisationContext);
  const { user } = useSelector(state => state.user);

  const {
    content: { taskDetails, isJobDetailsTask, fromActivity, onSuccess, activityId },
  } = sideModal;

  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [subTasks, setSubTasks] = useState([]);
  const [taskComments, setTaskComments] = useState([]);
  const [selectedSubTab, setSelectedSubTab] = useState('Comments');
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState({});
  const [originalTaskDetails, setOriginalTaskDetails] = useState({});
  const [isEditKey, setEditKey] = useState('');
  const [searchedJob, setSearchedJob] = useState('');
  const [priorityTooltipOpen, setPriorityTooltipOpen] = useState(false);

  const { id: task_id } = taskDetails;
  const { content: commentContent } = taskComments;
  const { engagement, progress } = selectedTask || {};
  const { id: engagement_id, property, workitems } = engagement || {};
  const { status } = progress || {};
  const { id: workitem_id } = (workitems && workitems[0]) || {};
  const { id: property_id } = property || {};

  const fetchTaskDetails = () => {
    setLoading(true);
    dispatch(getTaskDetails({ id: task_id }))
      .then(data => {
        setSelectedTask(data);
        setOriginalTaskDetails(data);
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_TASK_DETAILS') }));
        setSelectedTask(taskDetails);
        setOriginalTaskDetails(taskDetails);
      })
      .finally(() => setLoading(false));
  };

  const fetchTaskEvidences = () => {
    setLoading(true);
    dispatch(getTaskEvidences({ task_id: task_id }))
      .then(data => {
        setFiles(data);
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_TASK_EVIDENCE') }));
        setFiles([]);
      })
      .finally(() => setLoading(false));
  };

  const fetchTaskSubTasks = () => {
    setLoading(true);
    dispatch(getTaskSubTasks({ task_id: task_id }))
      .then(data => {
        setSubTasks(data);
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_SUB_TASKS') }));
        setSubTasks([]);
      })
      .finally(() => setLoading(false));
  };

  const fetchComments = () => {
    setLoading(true);
    dispatch(getTaskComments({ engagement_id: engagement_id, params: { task_id: task_id } }))
      .then(data => {
        setTaskComments(data);
      })
      .catch(() => dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_NOTES_LIST') })))
      .finally(() => setLoading(false));
  };

  const fetchData = () => {
    fetchTaskDetails();
    fetchTaskEvidences();
    fetchTaskSubTasks();
    fetchComments();
  };

  useEffect(() => {
    if (task_id) {
      setLoading(true);
      fetchData();
    }
  }, [task_id]);

  const gotToJobdetails = () => {
    setSideModal(initSideModal);
    navigate(`/properties/property/${property_id}/workitems/${workitem_id}`);
  };

  const getStatusStyles = status => {
    switch (status) {
      case 'COMPLETE':
        return {
          color: theme.success_500,
          background: theme.success_50,
        };
      case 'BLOCKED':
        return {
          color: theme.primary_500,
          background: theme.primary_50,
        };
      case 'STARTED':
        return {
          color: theme.purple_500,
          background: theme.purple_50,
        };
      default:
        return {
          color: theme.natural_600,
          background: theme.natural_150,
        };
    }
  };

  const StatusLabel = ({ data, isMainContent = false }) => {
    const { color, background } = useMemo(() => getStatusStyles(data?.value, theme), [data?.value, theme]);

    if (isMainContent) {
      return (
        <label
          style={{
            background,
            color,
            borderRadius: '50px',
            width: 'fit-content',
            height: '24px',
          }}
          onClick={() => setTooltipOpen(true)}
          className="select-label flex items-center justify-between font-12 px-3 text-center gap-1">
          <label style={{ color }} className="font-12 inter-500-text">
            {data?.label}
          </label>
          <IconContainer
            Icon={ArrowDownIcon}
            iconColor={color}
            backgroundColor={background}
            iconContainerClassname="p-0"
            iconWidth={14}
            iconHeight={14}
          />
        </label>
      );
    } else {
      return (
        <label
          style={{
            background,
            color,
            borderRadius: '50px',
            height: '24px',
          }}
          className="flex items-center justify-center font-12 px-3 text-center w-full gap-1 font-12 fw-500">
          {data?.label}
        </label>
      );
    }
  };

  const TaskDetailsFileUpload = ({ type, file }) => {
    const { media, name } = file || {};
    const { url } = media || {};
    const { t } = useTranslation();
    const [onFile, setOnFile] = useState(false);

    const onAddFiles = addedFiles => {
      const newFiles = addedFiles?.map(file => ({
        file_data: file,
        name: file.name,
        size: file.size,
        type: file.type,
        url: URL.createObjectURL(file),
        id: nanoid(),
      }));
      setFiles(prevItems =>
        prevItems.map(fileItem =>
          fileItem.id === file?.id ? { ...fileItem, media: (newFiles && newFiles[0]) || {} } : fileItem,
        ),
      );
    };

    const onFileDrop = e => {
      e.preventDefault();
      onAddFiles([...e.dataTransfer?.files]);
      setOnFile(false);
    };

    const onFileAdd = e => {
      e.preventDefault();
      onAddFiles([...e?.target?.files]);
      e.target.value = null;
    };

    return (
      <TaskFileUploadWrapper className="flex-column row-gap-6 overflow-hidden">
        <div className="mb-2 flex-1 overflow-scroll">
          <div
            className={classNames(
              'pxy-2 border-dotted radius-1_5 items-center justify-between',
              onFile ? 'on-droparea' : 'off-droparea',
            )}
            onDragLeave={e => {
              e.preventDefault();
              setOnFile(false);
            }}
            onDragOver={e => {
              e.preventDefault();
              setOnFile(true);
            }}
            onDrop={onFileDrop}>
            <p className="flex justify-between items-center w-full font-16 inter-400-text">
              <div className="flex items-center">
                {url ? (
                  <img className={classNames('radius-1_5 w-32px h-32px')} src={url} />
                ) : (
                  <div className="icon-border border">
                    <IconContainer
                      Icon={type}
                      iconColor="natural_300"
                      backgroundColor="transparent"
                      iconContainerClassname="p-2"
                      iconWidth={16}
                      iconHeight={16}
                      className="p-3 border"
                    />
                  </div>
                )}
                <span className="ml-2 natural-700-text mr-1 font-14 text-ellipsis">{name}</span>
              </div>

              <p className="flex">
                <input type="file" id="file-upload-input" style={{ display: 'none' }} onChange={onFileAdd} />
                <UploadLabelWrapper>
                  {media ? (
                    <Button
                      fontSize="12px"
                      size="average"
                      width="auto"
                      height="24px"
                      borderRadius="100px"
                      className="primary specified-width px-4 py-2_5 ml-3"
                      label={t('DOWNLOAD')}
                    />
                  ) : fromActivity ? (
                    <Button
                      fontSize="12px"
                      size="average"
                      width="auto"
                      height="24px"
                      borderRadius="100px"
                      className="grey-150 specified-width px-4 py-2_5 ml-3"
                      label={t('NOT_UPLOADED')}
                    />
                  ) : (
                    <label htmlFor="file-upload-input" className="image-upload-button primary-text cursor">
                      {t('UPLOAD')}
                    </label>
                  )}
                </UploadLabelWrapper>
              </p>
            </p>
          </div>
        </div>
      </TaskFileUploadWrapper>
    );
  };

  const onUpdate = async label => {
    if (
      selectedTask?.name === originalTaskDetails?.name &&
      selectedTask?.description === originalTaskDetails?.description &&
      selectedTask?.engagement?.id === originalTaskDetails?.engagement?.id &&
      selectedTask?.assignee?.id === originalTaskDetails?.assignee?.id &&
      selectedTask?.due_date === originalTaskDetails?.due_date &&
      selectedTask?.progress?.status === originalTaskDetails?.progress?.status &&
      selectedTask?.priority === originalTaskDetails?.priority
    ) {
      return setEditKey(null);
    } else {
      setLoading(true);
      try {
        const request = {
          ...selectedTask,
          name: selectedTask?.name,
          description: selectedTask?.description,
          engagement: {
            id: selectedTask?.engagement?.id,
          },
          service: { id: selectedTask?.service?.id ? selectedTask?.service?.id : '' },
          assignee: selectedTask?.assignee?.id ? { id: selectedTask?.assignee?.id } : null,
          due_date: selectedTask?.due_date,
          priority: selectedTask?.priority,
          evidence: selectedTask?.evidence ? selectedTask?.evidence : [],
          estimated: selectedTask?.estimated?.duration ? selectedTask?.estimated : null,
        };
        const updateValue = await dispatch(updateTask({ id: task_id, request }));
        dispatch(addToast({ error: false, text: `${label} updated successfully` }));
        setEditKey(null);

        if (updateValue && taskDetails) {
          fetchData();
          onSuccess && onSuccess();
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        dispatch(
          addToast({ error: true, text: error?.response?.data?.error_description || t('ERROR_WHILE_UPDATE_TASK') }),
        );
      }
    }
  };

  const CustomSingleValue = ({ data, ...rest }) => {
    const { name, property } = data || {};
    const { formatted_address } = property || {};
    return (
      <components.SingleValue {...rest} className="">
        <div className="w-full ">
          <div className="flex-column">
            <span className="inter-400-text natural-900-text font-14 line-height-20 text-ellipsis mr-2">{name}</span>
            <span className="inter-400-text natural-500-text font-12 line-height-20 text-ellipsis">
              {formatted_address}
            </span>
          </div>
        </div>
      </components.SingleValue>
    );
  };

  const CustomOption = props => {
    const { innerProps, data } = props;
    const { name, property } = data || {};
    const { formatted_address } = property || {};

    return (
      <div className="w-full px-4 py-3 cursor border-bottom" {...innerProps}>
        <div className="flex-column">
          <span className="inter-400-text natural-900-text font-14 line-height-20 text-ellipsis mr-2">{name}</span>
          <span className="inter-400-text natural-500-text font-12 line-height-20 text-ellipsis">
            {formatted_address}
          </span>
        </div>
      </div>
    );
  };

  const PropertyJobOwners = ({ fromActivity = false }) => {
    const { t } = useTranslation();

    const [searchedOwner, setSearchedOwner] = useState('');

    const CustomOwnerOption = ({ data, innerProps, innerRef, isSelected }) => {
      const owner = fromActivity ? data?.user : data;
      return (
        <div className="flex items-center px-4 py-3 border-bottom cursor" ref={innerRef} {...innerProps}>
          <Avatar variant="medium" avatar={owner} backgroundColorIndex={0} />
          <label className="inter-400-text natural-900-text font-14 ml-2 flex-1">{owner?.name}</label>
        </div>
      );
    };

    const SingleOwnerOption = ({ data, ...rest }) => {
      const owner = fromActivity ? data?.user : data;
      return (
        <components.SingleValue {...rest} className="">
          <div className="flex items-center cursor">
            <Avatar variant="medium" avatar={owner} backgroundColorIndex={0} />
            <label className="inter-400-text natural-900-text font-14 ml-2 flex-1">{owner?.name}</label>
          </div>
        </components.SingleValue>
      );
    };

    return (
      <div className="flex justify-end w-full">
        <div className="w-full">
          <SearchableDropdown
            className="px-2"
            key={`owner_${engagement_id}`}
            inputValue={searchedOwner}
            onInputChange={setSearchedOwner}
            placeholder={t('UNASSIGNED')}
            isCustomSearchable={true}
            isMulti={false}
            openMenuOnFocus
            value={selectedTask?.assignee}
            onChange={option => {
              setSelectedTask({ ...selectedTask, assignee: option });
            }}
            getOptionValue={option => (fromActivity ? option?.user?.id : option?.id)}
            defaultAdditional={{
              page: 0,
              // defaultOptions: fromActivity ? resources : [],
              fetchFunction: !fromActivity ? getEngagementAccess : [],
              pageable: false,
              payload: { id: selectedTask?.engagement?.id },
            }}
            customStyle={{
              menu: { right: 0 },
              control: {
                minHeight: 28,
                borderColor: '#E5E5E5',
                cursor: 'pointer',
              },
              valueContainer: { display: 'flex', padding: '0' },
              dropdownIndicator: {
                svg: {
                  width: '15px',
                  height: '15px',
                  color: '#A3A3A3',
                },
              },
            }}
            customComponent={{ Option: CustomOwnerOption, SingleValue: SingleOwnerOption }}
          />
        </div>
      </div>
    );
  };

  const handleInputFocus = event => {
    setTimeout(() => {
      event.target.select();
    }, 0);
  };

  const editInputContent = (key, value, label, type) => {
    return (
      <div className="flex-column w-full input-container relative">
        <div className={classNames('flex items-center')}>
          {key === 'description' ? (
            <textarea
              defaultValue={value}
              placeholder={t('ADD_SOME_TASK_DESCRIPTION')}
              className="inter-400-text natural-900-text overflow-auto input w-full textarea px-2 py-1 h-full job-input border"
              style={{ maxHeight: '260px' }}
              autoFocus
              onFocus={handleInputFocus}
              onChange={({ target: { value } }) => setSelectedTask({ ...selectedTask, [key]: value })}
            />
          ) : key === 'engagement' ? (
            <SearchableDropdown
              className="mx-2"
              inputValue={searchedJob}
              onInputChange={setSearchedJob}
              placeholder={t('SEARCH_TYPING_TO_SEE_SOME_RESULTS')}
              isSearchable={true}
              isCustomSearchable={false}
              loadOptionsOnMenuOpen={true}
              isMulti={false}
              openMenuOnFocus
              value={value}
              onChange={option => {
                setSelectedTask({ ...selectedTask, [key]: option });
              }}
              getOptionValue={option => option?.value}
              defaultAdditional={{
                page: 0,
                fetchFunction: getJobs,
                pageable: true,
              }}
              customStyle={{
                control: {
                  minHeight: 44,
                },
                menu: { zIndex: 9999 },
              }}
              customComponent={{ Option: CustomOption, SingleValue: CustomSingleValue }}
            />
          ) : key === 'assignee' ? (
            <PropertyJobOwners
              selectedJob={selectedTask?.engagement}
              engagement_id={selectedTask?.engagement ? selectedTask?.engagement?.id : engagement_id}
            />
          ) : key === 'due_date' ? (
            <DateSelectorElement
              className={'justify-between gap-3 mx-2 w-fit-content task-date-field'}
              setSelectedDate={value => {
                setSelectedTask({ ...selectedTask, due_date: value });
              }}
              wrapperClassName="date-selector-wrapper"
              selectedDate={selectedTask?.due_date || ''}
              placeholder={t('SELECT_DATE')}
              isCalendarIcon={false}
              minStartDate={moment().subtract(1, 'day').startOf('day').unix()}
            />
          ) : key === 'status' ? (
            <CustomTooltip
              wrapperClassName="status-tooltip"
              tooltipClassname="custom-scrollbar thin-scrollbar"
              id={`task-status-tooltip-${selectedTask?.id}`}
              place="bottom"
              clickable
              isOpen={tooltipOpen}
              onBlurTooltip={() => setTooltipOpen(false)}
              hidden={!tooltipOpen}
              noArrow={true}
              content={statusOptions
                ?.filter(statusVal => statusVal?.value !== status)
                ?.map((data, index) => (
                  <div
                    key={data?.label}
                    onClick={e => {
                      setSelectedTask({ ...selectedTask, progress: { status: data?.value } });
                      setTooltipOpen(false);
                    }}
                    className="px-4 py-2 font-14 border-bottom cursor proposal-config">
                    <StatusLabel data={data} />
                  </div>
                ))}>
              <div
                onClick={() => setTooltipOpen(!tooltipOpen)}
                className={classNames('radius-1_5 w-auto cursor px-1 py-0_5 hover-edit')}>
                <StatusLabel
                  data={statusOptions?.find(item => item?.value == selectedTask?.progress?.status)}
                  isMainContent={true}
                />
              </div>
            </CustomTooltip>
          ) : key === 'priority' ? (
            <CustomTooltip
              wrapperClassName="status-tooltip"
              tooltipClassname="custom-scrollbar thin-scrollbar"
              id={`priority-tooltip`}
              place="bottom"
              clickable
              isOpen={priorityTooltipOpen}
              onBlurTooltip={() => setPriorityTooltipOpen(false)}
              hidden={!priorityTooltipOpen}
              noArrow={true}
              content={priorityOptions
                ?.filter(priority => priority?.value !== selectedTask?.priority)
                ?.map((data, index) => (
                  <div
                    key={data?.label}
                    onClick={() => {
                      setSelectedTask({ ...selectedTask, [key]: data?.value });
                      setPriorityTooltipOpen(false);
                    }}
                    className="px-4 py-3 font-14 border-bottom cursor proposal-config">
                    <Status
                      withDot={false}
                      withDottedBorder={false}
                      status={data?.value}
                      icon={true}
                      statusText={capitalizeFirstLetterOfWords(formatText(data?.label))}
                    />
                  </div>
                ))}>
              <div
                onClick={() => setPriorityTooltipOpen(!priorityTooltipOpen)}
                className="flex items-center radius-1_5 cursor px-1 py-0_5 hover-edit">
                <Status
                  withDot={false}
                  withDottedBorder={false}
                  status={selectedTask?.priority}
                  icon={true}
                  statusText={capitalizeFirstLetterOfWords(formatText(selectedTask?.priority))}
                />
              </div>
            </CustomTooltip>
          ) : (
            <input
              className="inter-400-text natural-900-text one-line input w-full px-2 py-1 h-auto job-input border"
              defaultValue={value}
              type={type}
              autoFocus
              onFocus={handleInputFocus}
              onChange={({ target: { value } }) => setSelectedTask({ ...selectedTask, [key]: value })}
            />
          )}
        </div>
        <div className="w-full flex justify-end">
          <div className="flex justify-center items-end radius-3 job-update-action mt-1 border pxy-2 gap-2_5 absolute">
            <span
              className="flex justify-center items-center cross-icon border radius-100 cursor"
              onClick={() => {
                setSelectedTask({ ...originalTaskDetails });
                setEditKey(null);
              }}>
              <CrossIcon color="#171717" />
            </span>
            <span
              className="flex justify-center items-center right-icon border radius-100 cursor"
              onClick={() => onUpdate(label)}>
              <RightIcon color="#fff" />
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <TaskDetailsWrapper className="flex flex-column h-full w-full overflow-scroll">
      <SkeletonTransition
        loading={loading}
        setLoading={setLoading}
        loaderClassName={'item flex items-center justify-center flex-1 mb-6'}
        height={'100%'}
        containerClassName="line-height-1 h-full w-full">
        <div className="flex items-center justify-center py-5 px-6 border-bottom justify-between">
          <div className="flex w-full items-center justify-between">
            {isJobDetailsTask && <label className="font-20 inter-600-text">{t('TASK_DETAILS')}</label>}
            <LeftArrow height={24} width={24} className="cursor" onClick={() => setSideModal(initSideModal)} />
          </div>
          <Menu
            iconClassName="rotate-90"
            place="bottom-left"
            menuList={[
              {
                withIcon: true,
                icon: EditIcon,
                iconClassName: 'natural-700-text',
                name: t('EDIT_TASK'),
                onClick: () => {},
                permission: '',
              },
              {
                withIcon: true,
                icon: DeleteIcon,
                iconClassName: 'natural-700-text',
                name: t('DELETE_TASK'),
                onClick: () => {},
                permission: '',
              },
            ]}
          />
        </div>
        <div className="flex flex-column flex-1 overflow-scroll pxy-4">
          <div className="flex gap-2 w-full px-2">
            <div className="flex gap-2 w-full items-center">
              {isEditKey === 'TASK_STATUS' ? (
                <div className="flex">{editInputContent('status', selectedTask?.name, t('STATUS'), 'text')}</div>
              ) : (
                <div
                  onClick={() => {
                    setEditKey('TASK_STATUS');
                    setTooltipOpen(true);
                  }}
                  className="radius-1_5 cursor px-1 py-0_5">
                  <StatusLabel data={statusOptions?.find(item => item?.value == status)} isMainContent={true} />
                </div>
              )}
              {status !== 'COMPLETE' && (
                <div
                  className="border radius-100 h-5 w-5"
                  onClick={() => setSelectedTask({ ...selectedTask, status: 'COMPLETE' })}>
                  <IconContainer
                    Icon={CheckDoneIcon}
                    iconColor="natural_400"
                    backgroundColor="transparent"
                    iconContainerClassname="cursor "
                    iconWidth={14}
                    iconHeight={14}
                    className="p-3 border"
                  />
                </div>
              )}
              <Button
                label={t('GO_TO_JOB_DETAILS')}
                className="specified-width px-2"
                bgColor="transparent"
                borderColor={theme.primary_500}
                color={theme.primary_500}
                height="25px"
                size="medium"
                fontSize="12px"
                afterIcon={<RightArrowIcon />}
                onClick={gotToJobdetails}
              />
            </div>
            <IconContainer
              Icon={CrossIcons}
              iconColor="natural_400"
              backgroundColor="natural_100"
              iconContainerClassname="cursor radius-full"
              iconWidth={20}
              iconHeight={20}
              className="border"
              onClick={() => setSideModal(initSideModal)}
            />
          </div>
          <div className="flex flex-column items-start w-full radius-1_5 flex-1 overflow-scroll mt-4 gap-6">
            <div className="w-full px-2">
              {isEditKey === 'TASK_NAME' ? (
                editInputContent('name', selectedTask?.name, t('NAME'), 'text')
              ) : (
                <label
                  onClick={() => setEditKey('TASK_NAME')}
                  className="flex radius-1_5 cursor px-1 py-0_5 w-full hover-edit font-20 inter-600-text">
                  {selectedTask?.name}
                </label>
              )}
              {isEditKey === 'TASK_DESCRIPTION' ? (
                <div className="mt-2">
                  {editInputContent('description', selectedTask?.description, t('DESCRIPTION'))}
                </div>
              ) : (
                <p
                  onClick={() => setEditKey('TASK_DESCRIPTION')}
                  className="inter-400-text font-14 natural-400-text mt-2 radius-1_5 cursor px-1 py-0_5 w-full hover-edit text-ellipsis">
                  {selectedTask?.description || t('NO_DESCRIPTION_HERE')}
                </p>
              )}
            </div>
            {!fromActivity && !isJobDetailsTask && (
              <div className="pt-6 border-top w-full">
                {isEditKey === 'TASK_JOB' ? (
                  editInputContent('engagement', selectedTask?.engagement, t('JOB'))
                ) : !selectedTask?.engagement ? (
                  <div
                    onClick={() => setEditKey('TASK_JOB')}
                    className="inter-400-text natural-400-text line-height-20 font-14 radius-1_5 cursor px-1 py-0_5 hover-edit">
                    No job selected
                  </div>
                ) : (
                  <div
                    onClick={() => setEditKey('TASK_JOB')}
                    className="flex flex-column radius-1_5 cursor px-1 py-0_5 hover-edit">
                    <p className="inter-500-text natural-700-text line-height-20 font-16 text-ellipsis">
                      {selectedTask?.engagement?.name}
                    </p>
                    <p className="inter-400-text natural-400-text line-height-20 font-14 text-ellipsis">
                      {selectedTask?.engagement?.property?.formatted_address}
                    </p>
                  </div>
                )}
              </div>
            )}
            <div className="w-full flex flex-column gap-3 border-top pt-6">
              <div className="flex items-center justify-between">
                <p className="inter-400-text font-14 natural-400-text w-30">{t('ASSIGNEE')}</p>
                {isEditKey === 'TASK_ASSIGNEE' ? (
                  <div className="flex items-center w-full justify-end">
                    {editInputContent('assignee', selectedTask?.assignee, t('ASSIGNEE'))}
                  </div>
                ) : (
                  <div
                    onClick={() => setEditKey('TASK_ASSIGNEE')}
                    className="flex items-center radius-1_5 cursor px-1 py-0_5 hover-edit">
                    {selectedTask?.assignee?.name && (
                      <div className="flex items-center col-gap-1 one-line">
                        <Avatar avatar={selectedTask?.assignee} variant="medium" />
                        <span className="inter-400-text natural-900-text one-line flex-1">
                          {selectedTask?.assignee?.name}
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="flex items-center justify-between">
                <p className="inter-400-text font-14 natural-400-text w-30">{t('DUE_DATE')}</p>
                {isEditKey === 'DUE_DATE' ? (
                  <div className="flex items-center justify-end">
                    {editInputContent('due_date', selectedTask?.engagement, t('DUE_DATE'))}
                  </div>
                ) : (
                  <label
                    onClick={() => setEditKey('DUE_DATE')}
                    className={classNames(
                      'inter-400-text line-height-20 font-14 radius-1_5 cursor px-1 py-0_5 hover-edit',
                      !selectedTask?.due_date && 'natural-400-text',
                    )}>
                    {selectedTask?.due_date
                      ? moment.unix(Number(selectedTask?.due_date)).format('DD MMM')
                      : 'No due date'}
                  </label>
                )}
              </div>
              <div className="flex items-center justify-between">
                <p className="inter-400-text font-14 natural-400-text w-30">{t('PRIORITY')}</p>
                {isEditKey === 'PRIORITY' ? (
                  <div className="flex items-center justify-end">
                    {editInputContent('priority', selectedTask?.priority, t('PRIORITY'))}
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setEditKey('PRIORITY');
                      setPriorityTooltipOpen(true);
                    }}
                    className="flex items-center radius-1_5 cursor px-1 py-0_5">
                    <Status
                      withDot={false}
                      withDottedBorder={false}
                      status={selectedTask?.priority}
                      icon={true}
                      statusText={capitalizeFirstLetterOfWords(formatText(selectedTask?.priority))}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="w-full border-top border-bottom py-6">
              <p className="inter-400-text font-14 natural-400-text mb-5">
                {t('EVIDENCE')} {`(${(files || []).length})`}
              </p>
              {(files || []).length > 0 ? (
                <div className="flex flex-column gap-3 mt-3">
                  {files.map(file => (
                    <TaskDetailsFileUpload
                      key={file?.id}
                      type={file?.evidence_type === 'IMAGE' ? ImageContent : FileIcon}
                      file={file}
                    />
                  ))}
                </div>
              ) : (
                <p className="inter-400-text font-14 natural-400-text text-center mt-3">No evidence available</p>
              )}
            </div>

            {(subTasks || []).length > 0 && (
              <div className="w-full">
                <p className="inter-400-text font-14 natural-400-text mb-5">
                  {t('SUBTASKS')} {`(${(subTasks || []).length})`}
                </p>
                {(subTasks || []).length > 0 && (
                  <div className="flex flex-column gap-3 mt-3">
                    {subTasks.map((task, index) => (
                      <SubTaskItem key={index} task={task} index={index} subTasks={subTasks} />
                    ))}
                  </div>
                )}
              </div>
            )}

            <div className="w-full">
              <div className="flex justify-between items-center w-full">
                <div className="flex gap-5 w-full">
                  {commentTabs.map(tab => (
                    <div
                      key={tab.key}
                      onClick={() => setSelectedSubTab(tab.name)}
                      className={classNames(
                        'px-6 py-4_5 cursor w-full',
                        tab.name === selectedSubTab && 'selected-tab',
                      )}>
                      <label className="inter-500-text natural-700-text text-center flex justify-center w-full">
                        {tab.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex flex-column flex-1 w-full px-5 p-4">
                {selectedSubTab === 'Comments' &&
                  ((commentContent || []).length > 0 ? (
                    <div className="flex-column gap-3">
                      {commentContent &&
                        commentContent.map((comment, index) => {
                          const { status, description, name, id } = comment?.task;
                          const lastIndex = commentContent?.length - 1;
                          return (
                            <div
                              key={id}
                              className={classNames(
                                'flex gap-2 w-full pb-0',
                                lastIndex !== index && 'border-bottom pb-3',
                              )}>
                              <div className="flex items-start">
                                {selectedTask?.assignee?.name && (
                                  <div className="flex items-center col-gap-1 one-line">
                                    <Avatar avatar={selectedTask?.assignee} variant="medium" />
                                  </div>
                                )}
                              </div>
                              <div className="w-full">
                                <div className="flex justify-between">
                                  <label className="inter-500-text natural-700-text">{name}</label>
                                  <label className="inter-500-text natural-500-text">9 Nov, 2023</label>
                                </div>
                                <label className="inter-500-text natural-500-text w-full">{description}</label>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  ) : (
                    <NoData
                      title={'No Comments'}
                      description={'There are no comments here.'}
                      className="mt-12"
                      EmptyIcon={EmptyIcon}
                      iconClassName="relative"
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full border-top py-5 px-6">
          <div className="flex items-start gap-3 relative">
            <div className="flex items-start">
              <div className={classNames('flex items-center justify-center cursor user-logo-wrapper')}>
                <label className="bold-text white-text font-10">{getUserInitials(user?.user)}</label>
              </div>
            </div>
            <div className="w-full relative">
              <textarea
                placeholder={t('WRITE_COMMENT')}
                className="inter-400-text natural-900-text overflow-auto input w-full textarea px-4 py-2 h-full job-input border"
                style={{ maxHeight: '84px' }}
              />
              <div className="flex items-center gap-2 absolute bottom-0 left-0 mb-3 ml-4">
                <IconContainer
                  Icon={ImageAdd}
                  iconColor="natural_400"
                  backgroundColor="transparent"
                  iconContainerClassname="cursor p-2"
                  iconWidth={16}
                  iconHeight={16}
                  className="p-3 border"
                />
                <IconContainer
                  Icon={Attachment}
                  iconColor="natural_400"
                  backgroundColor="transparent"
                  iconContainerClassname="cursor p-2"
                  iconWidth={16}
                  iconHeight={16}
                  className="p-3 border"
                />
              </div>
            </div>
          </div>
        </div>
      </SkeletonTransition>
    </TaskDetailsWrapper>
  );
};

const MediaDropareaWrapper = styled.div`
  padding: 8px;
  &.off-droparea {
    border-color: ${({ theme }) => theme.natural_300};
    background-color: ${({ theme }) => theme.natural_50};
  }
  &.on-droparea {
    border-color: ${({ theme }) => theme.primary_500};
    background-color: ${({ theme }) => theme.primary_50};
  }
  .icon-border {
    border-radius: 4px;
    width: 32px;
    height: 32px;
    .p-2 {
      padding: 8px;
    }
    img {
      border-radius: 4px;
    }
  }
`;
const UploadLabelWrapper = styled.div`
  .image-upload-button {
    padding: 2px 12px;
    background-color: #2496ff;
    color: white;
    border-radius: 50px;
    font-size: 12px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`;

const TaskFileUploadWrapper = styled.div`
  .off-droparea {
    border-color: ${({ theme }) => theme.natural_300};
  }
  .on-droparea {
    border-color: ${({ theme }) => theme.primary_500};
    background-color: ${({ theme }) => theme.primary_50};
  }
  .icon-border {
    border-radius: 4px;
    width: 32px;
    height: 32px;
    .p-2 {
      padding: 8px;
    }
    img {
      border-radius: 4px;
    }
  }
`;

export default TaskDetails;
