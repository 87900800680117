import { motion } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddIcon } from '../../assets/icons/add-icon.svg';
import { ReactComponent as EmptyIcon } from '../../assets/icons/empty-document.svg';
import Button from '../common/button/button';
import InputSearch from '../common/input-search';
import NoData from '../common/no-data';

const PropertyLog = () => {
  const { t } = useTranslation();

  return (
    <motion.div
      key={'property-log'}
      className="pxy-6 flex-column flex-1"
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}>
      <div className="flex justify-between">
        <div className="flex align-center">
          <InputSearch placeholder={'Search'} />
        </div>
        <Button
          fontSize="14px"
          size="average"
          width="145px"
          borderRadius="100px"
          className="primary specified-width  px-4 py-2 ml-3"
          afterIcon={<AddIcon className="after-icon white-text" height={16} width={16} />}
          label={t('ADD_LOG')}
        />
      </div>

      <NoData
        title={t('NO_LOG')}
        description={t('NO_LOG_HERE')}
        className="flex-1 mt-12 h-full"
        EmptyIcon={EmptyIcon}
        iconClassName="mb-6 relative"
      />
    </motion.div>
  );
};

export default PropertyLog;
