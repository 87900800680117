export const calculateTotalAmountOfProducts = products => {
  return products.reduce(
    (acc, product) => {
      const { discount_rate, total_net, taxation_scheme } = product;
      const { rate } = taxation_scheme || { rate: 0 };

      const discountAmount = total_net * (discount_rate / 100) || 0;
      const taxableAmount = (total_net - discountAmount) * (rate / 100) || 0;

      acc.total_net += Math.abs(total_net);
      acc.total_discount += discountAmount || 0;
      acc.total_tax += taxableAmount || 0;
      acc.total_amount += Math.abs(total_net) + (taxableAmount || 0) - (discountAmount || 0);
      return acc;
    },
    { total_net: 0, total_discount: 0, total_tax: 0, total_amount: 0 },
  );
};

export const calculateProductAmounts = (products, quoteIntegration) => {
  return products.map(productData => {
    const { discount_rate, total_net, product, taxation_scheme, line_item_type, integration } = productData;
    const { rate } = taxation_scheme || { rate: 0 };
    const discountAmount = total_net * (discount_rate / 100) || 0;
    const taxableAmount = (total_net - discountAmount) * (rate / 100) || 0;
    const lineItemType =
      line_item_type === 'CATALOG' ? (integration?.id === quoteIntegration?.id ? 'CATALOG' : 'OTHER') : line_item_type;

    return {
      ...productData,
      product: product?.id ? { id: product?.id } : null,
      taxation_scheme: taxation_scheme?.id
        ? {
            ...taxation_scheme,
            integration: quoteIntegration
              ? {
                  id: quoteIntegration.id,
                }
              : null,
          }
        : null,
      discount_amount: total_net * (discount_rate / 100) || 0,
      total_net: Math.abs(total_net) || 0,
      total_tax: Math.abs(taxableAmount) || 0,
      total_amount: Math.abs(total_net) + total_net * (rate / 100) - total_net * (discount_rate / 100) || 0,
      line_item_type: lineItemType,
    };
  });
};

export const getSimplifiedErrorResponse = (error, defaultErrorText = '') => {
  const data = error?.response?.data || {};
  const { field_errors, error_description } = data || {};
  if (field_errors?.length > 0) {
    const combiledFieldErrors = field_errors.map(({ field, message }) => `${field} ${message}`).join(',');
    return combiledFieldErrors;
  }
  return error_description || defaultErrorText;
};
