import styled from 'styled-components';

const QuoteDetailsTermsWrapper = styled.div`
  max-width: 830px;
  width: 100%;
  outline: none;
  border: 1px solid ${({ theme }) => theme.lightGrey};
  border-radius: 6px;
  padding: 12px 16px;

  &:focus-within {
    background-color: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.focus_border};
    box-shadow: 0px 0px 0px 4px ${({ theme }) => theme.focus_border};
  }

  .icon-pencil {
    top: 8px;
    right: 8px;
  }

  .textarea-element {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    border: none;
    box-shadow: none;
    display: -webkit-box;
    height: initial;
    max-height: 120px;
    line-height: 20px;
    overflow: auto;
    padding: 0;
    text-overflow: ellipsis;
    background: inherit;
    overflow: hidden;
    &:focus {
      display: block;
      box-shadow: none;
      overflow: initial;
      max-height: 160px;
      border: none;

      scrollbar-width: thin;
      scrollbar-color: ${({ theme }) => theme.primary_500} transparent !important;
    }
  }

  .textarea-content-visible {
    .textarea-element {
      -webkit-line-clamp: 99;
    }
  }
`;

export default QuoteDetailsTermsWrapper;
