import styled from 'styled-components';

const QuoteDetailsProductItemWrapper = styled.div`
  padding: 0 4px;

  .product-detail-item {
    display: inline-flex;
    min-height: 26px;
    border: 1px solid transparent;

    &:hover {
      background-color: ${({ theme }) => theme.natural_100};
      border-color: ${({ theme }) => theme.natural_200};
      border-radius: 6px;
    }
  }

  .row-span-2 {
    grid-row: span 2;
  }

  .cost-wrapper {
    grid-column: 4 / span 5;
    grid-row: 2;

    .cost-container {
      display: grid;
      grid-template-columns: 118px 80px 80px 100px 90px;
      column-gap: 40px;
      grid-template-rows: minmax(32px, 1fr) 28px;
      row-gap: 8px;

      .cost-profit-container {
        grid-row: 2;
        grid-column: 3 / span 3;
        width: fit-content;
        display: grid;
        grid-template-columns: 80px 100px 80px;
        column-gap: 40px;
        align-items: center;
        border-radius: 6px;
      }
    }
  }
`;

export default QuoteDetailsProductItemWrapper;
