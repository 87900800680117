import React from 'react';
import { TestNameDetailsWrapper } from '../../styles/components/test-name-details/test-name-details.styled';
import TeamMembers from './team-members';
import TeamDetails from './team-name-details';
const TeamName = () => {
  return (
    <TestNameDetailsWrapper className="flex flex-1 col-gap-6 py-2 px-6 overflow-hidden">
      <TeamDetails />
      <TeamMembers />
    </TestNameDetailsWrapper>
  );
};

export default TeamName;
