import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

const minimumLoadingTime = 500;

const SkeletonTransition = ({ children, customLoaderComponent, loading, loaderClassName, ...rest }) => {
  const [prolongedLoading, setProlongedLoading] = useState(true);
  const [isDelay, setIsDelay] = useState(true);

  const loaderComponent = (
    <div className={loaderClassName}>
      <Skeleton {...rest} />
    </div>
  );

  useEffect(() => {
    const delayTimeout = setTimeout(() => setIsDelay(false), minimumLoadingTime);
    if (loading) {
      setIsDelay(true);
      clearTimeout(delayTimeout);
    }

    let loadingTimeout;
    if (!isDelay && !loading) {
      setProlongedLoading(false);
    } else if (!loading) {
      loadingTimeout = setTimeout(() => setProlongedLoading(false), minimumLoadingTime);
    } else {
      setProlongedLoading(loading);
    }

    return () => {
      clearTimeout(loadingTimeout);
      clearTimeout(delayTimeout);
    };
  }, [loading, isDelay]);

  return <>{prolongedLoading ? (customLoaderComponent ? customLoaderComponent : loaderComponent) : children}</>;
};

export default SkeletonTransition;
