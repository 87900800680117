import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import AutomationLog from './automation-log';
import Configuration from './configuration';

const AutomationTabs = ({ selectedTab, setSelectedTab }) => {
  const defaultTabs = [
    { name: 'Configuration', key: 'CONFIGURATION' },
    { name: 'Automation Log', key: 'AUTOMATION_LOG' },
  ];

  return (
    <div className="flex px-6 pt-3 border-bottom justify-between items-center">
      <div className="flex gap-5">
        {defaultTabs.map((tab, index) => (
          <div
            key={index}
            onClick={() => {
              setSelectedTab(tab.key);
            }}
            className={classNames('px-4 py-4_5 cursor', selectedTab === tab.key && 'selected-tab')}>
            <label className="inter-500-text natural-700-text">{tab.name}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

function AutomationDetailsRight() {
  let [searchParams, setSearchParams] = useSearchParams();

  const [selectedTab, setSelectedTab] = useState(searchParams.get('tab') || 'CONFIGURATION');

  useEffect(() => {
    setSearchParams({ tab: selectedTab });
  }, [selectedTab]);

  return (
    <AutomationDetailsRightWrapper className="flex-column border flex-1 radius-4 overflow-hidden">
      <AutomationTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      {selectedTab === 'CONFIGURATION' && <Configuration />}
      {selectedTab === 'AUTOMATION_LOG' && <AutomationLog />}
    </AutomationDetailsRightWrapper>
  );
}

const AutomationDetailsRightWrapper = styled.div`
  .selected-tab {
    border-bottom: 1px solid ${({ theme }) => theme.selectedText};
    label {
      color: ${({ theme }) => theme.selectedText};
    }
  }
`;

export default AutomationDetailsRight;
