import React, { useState } from 'react';
import HttpsRedirect from 'react-https-redirect';
import { useTranslation } from 'react-i18next';
import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useDispatch, useSelector, useStore } from 'react-redux';
import 'react-tooltip/dist/react-tooltip.css';
import { ThemeProvider } from 'styled-components';
import ReloadIcon from './assets/images/reload2.svg';
import { LanguageProvider } from './context/LanguageContext';
import { ThemePreferenceContextProvider, initialThemeState } from './context/ThemePreferenceContext';
import { getLanguage } from './helpers/i18n';
import './index.css';
import { Router } from './routes/router';
import { init } from './store/features/reloadSlice';
import setUpInterceptor from './store/services/interceptor';
import { CommonStyleWrapper } from './styles/Common.styled';
import { dark, light } from './styles/theme/index';
import './translations/i18n';

const themesMap = {
  light,
  dark,
};
const App = () => {
  const store = useStore();
  setUpInterceptor(store);
  const dispatch = useDispatch();
  const state = useSelector(state => state.reload);
  const { i18n } = useTranslation();

  const [language, setLanguage] = useState(getLanguage());
  const [theme, setTheme] = useState(initialThemeState.theme);

  const onLanguageChange = lang => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
  };

  return (
    <LanguageProvider value={{ onLanguageChange, language }}>
      <ThemePreferenceContextProvider value={{ theme, setTheme }}>
        <ThemeProvider theme={themesMap[theme]}>
          <SkeletonTheme baseColor="#F0F0F0" highlightColor="#FAFAFA">
            <CommonStyleWrapper />
            <div className={`theme-${theme}`}>
              <HttpsRedirect>
                <Router />
                {state.updated && (
                  <div className="fixed new-version-popup flex justify-center pxy-12">
                    <div className="flex flex-column items-center card mt-20 pxy-12 refresh-view">
                      <label className="bold-text font-24">New version available!</label>
                      <label className="regular-text font-14 mt-2 grey-text text-center w-70">
                        Please refresh the page to get access to new platform updates
                      </label>
                      <img className="mt-10" src={ReloadIcon} />
                      <div
                        className="flex items-center justify-center mt-12 cursor refresh-btn"
                        onClick={() => {
                          state.serviceWorkerRegistration.waiting.postMessage({ type: 'SKIP_WAITING' });
                          dispatch(init());
                          window.location.reload();
                        }}>
                        <label className="medium-text font-16 white-text">Refresh</label>
                      </div>
                    </div>
                  </div>
                )}
              </HttpsRedirect>
            </div>
          </SkeletonTheme>
        </ThemeProvider>
      </ThemePreferenceContextProvider>
    </LanguageProvider>
  );
};

export default App;
