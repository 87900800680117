import { createSlice } from '@reduxjs/toolkit';
import api from '../services';
import { getUserRoles } from './userManagementSlice';

export const roleUserSlice = createSlice({
  name: 'roleUser',
  initialState: {
    RolePermission: null,
    PermissionTick: null,
  },
  reducers: {
    setRolePermission: (state, { payload }) => {
      state.RolePermission = payload;
    },
    setPermissionTick: (state, { payload }) => {
      state.PermissionTick = payload;
    },
  },
});

export const createNewRole = payload => async dispatch => {
  const { organization_id, request } = payload;
  try {
    const { data } = await api.post(`/organizations/${organization_id}/roles`, request);
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getPermissionGroups = payload => async dispatch => {
  try {
    const { data } = await api.get(`/permission_groups`);
    dispatch(setRolePermission(data));
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getRolePermissions =
  ({ role_id }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/roles/${role_id}/permissions`);
      dispatch(setPermissionTick(data));
      return Promise.resolve({ data });
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const UpdatePermissionTick = payload => async dispatch => {
  const { id, request } = payload;
  try {
    const { data } = await api.put(`/roles/${id}/permissions`, request);
    dispatch(setPermissionTick(data));
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const UpdateUserRole = payload => async dispatch => {
  const { roleId, organization_id, request } = payload;
  try {
    const { data } = await api.put(`/organizations/${organization_id}/roles/${roleId}`, request);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteUserRoles = payload => async dispatch => {
  const { organization_id, id } = payload;
  try {
    const { data } = await api.delete(`/organizations/${organization_id}/roles/${id}`);
    dispatch(getUserRoles());
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const { setRolePermission, setPermissionTick } = roleUserSlice.actions;
export default roleUserSlice.reducer;
