import styled from 'styled-components';

export const TestNameDetailsWrapper = styled.div`
  .member-count {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    background: #f5f5f5;
    width: 50px;
    border-radius: 100px;
    height: 28px;
    gap: 4px;
  }

  .padding {
    padding: 24px 22px;
  }
`;

export const TestNameMemberWrapper = styled.div``;

export const TeamMemberBodyWrapper = styled.div`
  .active {
    align-items: center;
    padding: 1px 4px;
    background: ${({ theme }) => theme.additionalGreen};
    border-radius: 4px;
    color: #fff;
  }
`;
