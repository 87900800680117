import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';
import EditorToolbar, { formats, modules } from './editorToolbar';

export const Editor = ({ onChange, getParmsValue, readOnly }) => {
  const { t } = useTranslation();
  return (
    <TextEditorWrapper className="text-editor">
      <EditorToolbar readOnly={readOnly} />
      <ReactQuill
        readOnly={readOnly}
        theme="snow"
        defaultValue={getParmsValue}
        value={getParmsValue}
        onChange={onChange}
        placeholder={t('WRITE_SOMTHING_AWSOME')}
        modules={modules}
        formats={formats}
      />
    </TextEditorWrapper>
  );
};

const TextEditorWrapper = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 16px;

  .ql-container.ql-snow {
    border: none !important;
  }
  .ql-editor {
    padding: 20px !important;
    font-family: 'Inter' !important;
    font-weight: 400 !important;
    color: #171717 !important;
    font-size: 14px;
  }
  .ql-editor.ql-blank::before {
    left: 20px !important;
  }
  .ql-tooltip {
    left: 0px !important;
  }
`;

export default Editor;
