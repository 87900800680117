import classNames from 'classnames';
import { motion } from 'framer-motion';
import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import Button from '../components/common/button/button';
import CommonStepper from '../components/common/stepper-common';
import { OrganisationContext } from '../context/organisationContext';
import { initModal } from '../helpers/utils';
import { createContact } from '../store/features/newContactSlice';
import { addToast } from '../store/features/toastSlice';
import { ContactDetailsNew, ContactSummary, getContactRequest } from './add-new-property';

const steps = [
  { title: 'Contact', value: 'CONTACT' },
  { title: 'Summary', value: 'SUMMARY' },
];

const AddContact = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { setModal, modal } = useContext(OrganisationContext);

  const { onSuccess, propertyData } = modal?.content || {};

  const [propertyAddress, setPropertyAddress] = useState({});
  const [contacts, setContacts] = useState([]);
  const [selectedTab, setSelectedTab] = useState('CONTACT');
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [loadingAddress, setLoadingAddress] = useState(false);

  const checkPropertyContactErrors = () => {
    if (!contacts.length) {
      dispatch(addToast({ error: true, text: t('PLEASE_ADD_ATLEAST_ONE_CONTACT_OR_COMPANY'), id: nanoid() }));
      return true;
    }
    return false;
  };

  const onDone = async () => {
    setLoading(true);

    const request = {
      contacts: getContactRequest({ contacts: contacts }),
    };
    try {
      await dispatch(createContact({ id: propertyData?.id, request: request?.contacts }));
      setLoading(false);
      setModal(initModal);
      dispatch(addToast({ error: false, text: t('CONTACT_CREATED_SUCCESSFULLY') }));
      onSuccess && onSuccess();
    } catch (error) {
      setLoading(false);
      dispatch(
        addToast({ error: true, text: error?.response?.data?.error_description ?? t('ERROR_WHILE_CREATE_CONTACT') }),
      );
    }
  };

  const onNextOrDone = () => {
    if (selectedTab === 'CONTACT') {
      if (checkPropertyContactErrors()) {
        return;
      }
      setActiveStep(activeStep + 1);
      setSelectedTab('SUMMARY');
    } else {
      onDone();
    }
  };

  const onBack = () => {
    if (selectedTab === 'SUMMARY') {
      setActiveStep(activeStep - 1);
      setSelectedTab('CONTACT');
    }
  };

  const renderComponents = () => {
    switch (selectedTab) {
      case 'CONTACT':
        return (
          <ContactDetailsNew
            propertyAddress={propertyData}
            setContacts={setContacts}
            contacts={contacts}
            setError={setError}
            error={error}
          />
        );
      case 'SUMMARY':
        return (
          <ContactSummary
            propertyDetails={propertyData}
            propertyAddress={propertyData}
            contacts={contacts}
            isContact={true}
            steps={steps}
            setSelectedTab={setSelectedTab}
            setActiveStep={setActiveStep}
          />
        );
      default:
        return null;
    }
  };

  return (
    <CSSTransition appear className="popup-fade" in timeout={300}>
      <AddContactWrapper className="flex-column" activeStep={activeStep}>
        <div className="flex justify-between px-10 pt-8 pb-5 border-bottom">
          <p className="inter-700-text natural-900-text font-24 mb-2">{'Add New Contact'}</p>
          <div className="flex items-center" style={{ width: '207px', height: '40px' }}>
            <CommonStepper steps={steps} actvieStep={activeStep} />
          </div>
        </div>
        <div className="px-10 py-7 flex-column overflow-scroll" style={{ maxHeight: '70vh' }}>
          {renderComponents()}
        </div>
        <div className="flex px-10 py-6 justify-between w-full border-top">
          <Button
            className={classNames('primary-grey specified-width', loading && 'disabled')}
            label={'Cancel'}
            disabled={loading}
            onClick={() => setModal(initModal)}
            size="large"
            borderRadius="100px"
            width="100px"
          />
          <div className="flex gap-3">
            {selectedTab !== 'CONTACT' && (
              <Button
                className={classNames('secondary specified-width', loading && 'disabled')}
                label={'Back'}
                disabled={loading || loadingAddress}
                onClick={onBack}
                size="large"
                borderRadius="100px"
                width="100px"
              />
            )}
            <Button
              className={classNames('primary specified-width', loading && 'disabled')}
              label={selectedTab === 'SUMMARY' ? 'Add' : 'Next'}
              disabled={loading || loadingAddress}
              onClick={onNextOrDone}
              size="large"
              borderRadius="100px"
              width="100px"
            />
          </div>
        </div>
      </AddContactWrapper>
    </CSSTransition>
  );
};

const AddContactWrapper = styled.div`
  width: 900px;

  .selected-tab {
    border-bottom: 1px solid ${({ theme }) => theme.selectedText};
    label {
      color: ${({ theme }) => theme.selectedText};
    }
  }

  .add-property-stepper {
    div div div div:nth-child(1) {
      box-shadow: 0px 0px 0px 4px #d3eafe;
    }
  }

  .span-2 {
    grid-column: span 2;
  }

  .search-address {
    .searchable-select__menu {
      width: 485px;
    }
  }

  .address-plus-icon {
    top: 35px;
    width: 20px;
    height: 20px;
    right: 15px;
  }

  .contact-list-wrapper {
    max-height: 56vh;
  }

  .contact-summary {
    max-height: 40vh;
  }

  .address-field {
    height: 44px;
    border: 1px solid ${({ theme }) => theme.natural_300};
    background-color: ${({ theme }) => theme.natural_100};
  }

  .address-field-error {
    border-color: ${({ theme }) => theme.error_500};

    &:hover {
      border-color: ${({ theme }) => theme.error_500};
    }

    &:focus {
      border-color: ${({ theme }) => theme.error_500};
      box-shadow: none;
    }
  }
`;

export const ContactDetailsWrapper = styled(motion.div)`
  .summary-details {
    transition: all 500ms ease-in-out;
    row-gap: 0;
    display: grid;
    grid-template-rows: repeat(1, 0fr);
    scroll-behavior: smooth;
    & > div {
      overflow: hidden;
    }
    .contact-details {
      overflow: hidden;
    }
  }

  .opened-user {
    height: auto;
    row-gap: 12px;
    grid-template-rows: repeat(1, 1fr);
  }
`;

export default AddContact;
