import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from '../../components/page-header';
import TeamDetails from '../../components/team-name-details';
import { getTeamDetails, getTeamMembers } from '../../store/features/teamsManagmentSlice';

const TeamName = () => {
  const { teamDetails: teamDetails } = useSelector(state => state.teamManagement);
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTeamDetails({ teamId: id }));
    dispatch(getTeamMembers({ teamId: id }));
  }, []);

  return (
    <div className="flex-column flex-1">
      <PageHeader
        names={[
          { text: t('SETTINGS'), onClick: () => navigate('/settings') },
          { text: t('SECURITY'), onClick: () => navigate('/settings') },
          { text: t('TEAMS'), onClick: () => navigate('/settings/security/teams') },
          { text: `${teamDetails?.name}`, onClick: () => {} },
        ]}
      />
      <div>
        <TeamDetails />
      </div>
    </div>
  );
};

export default TeamName;
