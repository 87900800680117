import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PersonsListWrapper } from '../../styles/components/persons-list/persons-list.styled';
import PersonsListItem from './persons-list-item';

const PersonsList = () => {
  const { t } = useTranslation();
  const { personsList } = useSelector(state => state.persons);
  const { content: persons } = personsList || {};

  return (
    <PersonsListWrapper className="flex-column flex-1">
      {persons?.length > 0 ? (
        persons?.map(person => <PersonsListItem key={person?.id} person={person} />)
      ) : (
        <div className="flex-column items-center justify-center h-full">
          <label className="bold-text light-grey-text font-40 mt-4">{t('NO_DATA')}</label>
        </div>
      )}
    </PersonsListWrapper>
  );
};

export default PersonsList;
