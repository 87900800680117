import { createSlice } from '@reduxjs/toolkit';
import api from '../services';

export const boardSlice = createSlice({
  name: 'boards',
  initialState: {
    boardList: [],
    boardAccessList: [],
    boardStageList: [],
    workitemDetails: {},
    stageWorkitemList: {},
  },
  reducers: {
    setBoardList: (state, { payload }) => {
      state.boardList = payload;
    },
    setBoardAccessList: (state, { payload }) => {
      state.boardAccessList = payload;
    },
    setBoardStageList: (state, { payload }) => {
      state.boardStageList = payload;
    },
    setStageWorkitemList: (state, { payload }) => {
      const { data, stage_id, merge } = payload;
      const { content = [] } = data || {};
      state.stageWorkitemList = {
        ...state.stageWorkitemList,
        [stage_id]: merge ? [...state.stageWorkitemList[stage_id], ...content] : content,
      };
    },
    updateStageWorkitemList: (state, { payload }) => {
      state.stageWorkitemList = {
        ...state.stageWorkitemList,
        ...payload,
      };
    },
    setWorkitemDetails: (state, { payload }) => {
      state.workitemDetails = payload;
    },
  },
});

export const getBoardList = payload => async dispatch => {
  try {
    const { data } = await api.get(`/boards`);
    dispatch(setBoardList(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getBoardAccessList = payload => async dispatch => {
  try {
    const { board_id, params } = payload;
    const { data } = await api.get(`/boards/${board_id}/access`, { params: params });
    dispatch(setBoardAccessList(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getBoardStageList = payload => async dispatch => {
  try {
    const { board_id } = payload;
    const { data } = await api.get(`/boards/${board_id}/stages`);
    const stageFiltered = data.filter(st => st.stage.stage_type === 'NORMAL' || st.stage.stage_type === 'TRANSITION');
    dispatch(setBoardStageList(stageFiltered));
    return Promise.resolve(stageFiltered);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getStageWorkitemList = payload => async dispatch => {
  try {
    const { stage_id, params, merge } = payload;
    const { data } = await api.get(`/stages/${stage_id}/workitems`, { params: params });
    dispatch(setStageWorkitemList({ data, stage_id, merge }));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getWorkitemDetails = payload => async dispatch => {
  try {
    const { workitem_id, params } = payload;
    const { data } = await api.get(`/workitems/${workitem_id}`, { params: params });
    dispatch(setWorkitemDetails(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getWorkitemProgress = payload => async dispatch => {
  try {
    const { workitem_id, params } = payload;
    const { data } = await api.get(`/workitems/${workitem_id}/progress`, { params: params });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getStageDecisions =
  ({ stage_id }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/stages/${stage_id}/decisions`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const {
  setBoardList,
  setBoardAccessList,
  setBoardStageList,
  setWorkitemDetails,
  setStageWorkitemList,
  updateStageWorkitemList,
} = boardSlice.actions;
export default boardSlice.reducer;
