import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import uuid from 'react-uuid';
import { ReactComponent as AlertIcon } from '../assets/icons/AlertIcon.svg';
import { ReactComponent as CrossIcon } from '../assets/icons/CrossIcons.svg';
import Button from '../components/common/button/button';
import { OrganisationContext } from '../context/organisationContext';
import { initModal } from '../helpers/utils';
import { deleteNote } from '../store/features/notesSlice';
import { addToast } from '../store/features/toastSlice';
import { NotesActionWrapper } from './modal.styled';

const NotesAction = () => {
  const { modal, setModal } = useContext(OrganisationContext);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { notesDetails, title, description, note_id, fetchNotesList } = modal.content;
  const { t } = useTranslation();

  const handleDone = () => {
    setLoading(true);
    dispatch(deleteNote({ note_id: note_id }))
      .then(data => {
        dispatch(addToast({ error: false, text: t('NOTE_DELETED_SUUCESSFULLY') }));
        setModal(initModal);
        setLoading(false);
        fetchNotesList();
      })
      .catch(({ response }) => {
        const { data } = response;
        dispatch(
          addToast({
            error: true,
            text: data?.error_description ? data?.error_description : t('ERROR_WHILE_DELETING_NOTES_DETAILS'),
            id: uuid(),
          }),
        );
        setLoading(false);
      });
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <NotesActionWrapper>
        <div className="flex-column content-wrapper items-center relative">
          <AlertIcon />
          <CrossIcon className="absolute right-0 cursor" onClick={() => setModal(initModal)} />

          <div className="flex-column gap-2 items-center justify-start w-full">
            <label className="inter-500-text font-18 text-center">{title}</label>
            <p className="inter-400-text  text-center">{description}</p>
          </div>
        </div>
        <div className="action-wrapper flex-column justify-center w-full gap-3">
          <Button
            className={`delete-btn negative ${loading && 'disabled'}`}
            label={t('DELETE')}
            disabled={loading}
            onClick={() => handleDone()}
            size="large"
            borderRadius="100px"
          />
          <Button
            className={`cancel-btn ${loading && 'disabled'}`}
            label={t('CANCEL')}
            disabled={loading}
            onClick={() => setModal(initModal)}
            size="large"
            borderRadius="100px"
          />
        </div>
      </NotesActionWrapper>
    </CSSTransition>
  );
};

export default NotesAction;
