import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as PlusIcon } from '../assets/icons/add-icon.svg';
import { ReactComponent as CheckIcon } from '../assets/icons/check-done.svg';
import { ReactComponent as EditIcon } from '../assets/icons/edit-pensil.svg';
import { ReactComponent as CloseIcon } from '../assets/images/close-image.svg';
import Checkbox from '../components/common/checkbox';
import IconContainer from '../components/common/icon-container';
import InputElement from '../components/common/input';
import MobileInput from '../components/common/mobile-input';
import PhoneInput from '../components/common/phone-input';
import Dropdown from '../components/common/select-dropdown';
import { OrganisationContext } from '../context/organisationContext';
import {
  companyTypeOptions,
  contactTitleOptions,
  contactTypeOptions,
  employeeTypeOptions,
} from '../helpers/optionData';
import { capitalize, formatText, getErrorFieldJoined, initModal } from '../helpers/utils';
import { updateContact } from '../store/features/newContactSlice';
import { addToast } from '../store/features/toastSlice';

const EditContact = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { sideModal, setSideModal, setInsideModal } = useContext(OrganisationContext);
  const { companyContactDetails, employeeDetail, property_id, onSuccess, propertyAddress } = sideModal?.content;
  const {
    id: contact_id,
    company_name,
    emails,
    phones,
    property_contact_type,
    title,
    addresses,
    forename,
    surname,
    contact_type,
    job_title,
    is_primary,
    contact_period,
    call_times,
    parent,
  } = employeeDetail ?? companyContactDetails;

  const emailPrimary = emails?.find(email => email.is_primary) || emails?.[0] || {};
  const mobilePrimary = phones?.find(phone => phone.contact_type === 'MOBILE') || {};
  const landlinePrimary = phones?.find(phone => phone.contact_type === 'LANDLINE') || {};
  const addressPrimary = addresses?.find(address => address.is_primary) || addresses?.[0] || {};

  const typeOptions = useMemo(() => {
    return contact_type === 'COMPANY'
      ? companyTypeOptions
      : contact_type === 'EMPLOYEE'
      ? employeeTypeOptions
      : contactTypeOptions;
  }, [contact_type]);

  const [error, setError] = useState({});
  const [contactEdit, setContactEdit] = useState({});
  const [loading, setLoading] = useState(false);

  const {
    type,
    name,
    email,
    mobile,
    landline,
    address,
    title: editTitle,
    country_code,
    is_primary: editIsPrimary,
  } = contactEdit || {};

  const isContactError = (email, landline, mobile) => {
    return !email && !landline && (contact_type === 'COMPANY' ? true : !mobile);
  };

  const onAddressAddOrUpdate = () => {
    setInsideModal({
      type: 'address-popup',
      content: {
        addressDetails: contactEdit?.address,
        setAddressDetails: address => setContactEdit(prevContactEdit => ({ ...prevContactEdit, address })),
        propertyAddress: propertyAddress,
      },
    });
  };

  const checkCompanyErrors = () => {
    const { type, title, name, email, landline, address, mobile } = contactEdit;
    const isContactError = !email && !landline && (contact_type === 'COMPANY' ? true : !mobile);
    const checkArrressError = contact_type !== 'EMPLOYEE';
    const error = {
      type: !type ? 'type' : false,
      title: contact_type !== 'COMPANY' && !title ? 'title' : false,
      name: !name ? 'name' : false,
      fullName: contact_type !== 'COMPANY' && name && name?.trim().split(' ').length <= 1 ? 'full name' : false,
      contact: isContactError ? 'at least one contact' : false,
      address: checkArrressError ? (!address?.formatted_address ? 'address' : false) : false,
    };
    if (Object.values(error).some(value => value)) {
      const errorFields = getErrorFieldJoined(error, (_, value) => value, ' and ');
      const errorText = `Please add ${errorFields}`;
      setError(error);
      dispatch(addToast({ error: true, text: errorText, id: nanoid() }));
      return true;
    }
    setError({});
    return false;
  };

  const emailRequest = () => {
    if (emailPrimary.id) {
      return emails?.map(email => (email.id === emailPrimary.id ? { ...email, value: contactEdit.email } : email));
    }
    return [
      {
        value: contactEdit.email,
        is_primary: true,
        email_type: 'WORK',
      },
    ];
  };

  const mobileRequest = () => {
    const mobileContacts = phones?.filter(phone => phone.contact_type === 'MOBILE');
    if (!contactEdit.mobile) {
      return [];
    }
    if (mobilePrimary.id && mobileContacts?.length > 0) {
      return mobileContacts?.map(phone => {
        if (phone.contact_type === 'MOBILE' && phone.id === mobilePrimary.id) {
          return {
            ...phone,
            contact_value: contactEdit.mobile?.replaceAll(' ', '')?.replaceAll('-', '') || '',
            country_code: contactEdit.country_code || '+44',
          };
        }
        return { ...phone, contact_value: phone.value };
      });
    }
    return [
      {
        contact_type: 'MOBILE',
        contact_value: contactEdit.mobile?.replaceAll(' ', '')?.replaceAll('-', '') || '',
        is_primary: true,
        phone_type: 'WORK',
        country_code: contactEdit.country_code || '+44',
      },
    ];
  };

  const landlineRequest = () => {
    const landlineContacts = phones?.filter(phone => phone.contact_type === 'LANDLINE');
    if (!contactEdit.landline) {
      return [];
    }
    if (landlinePrimary.id && landlineContacts?.length > 0) {
      return landlineContacts?.map(phone => {
        if (phone.contact_type === 'LANDLINE' && phone.id === landlinePrimary.id) {
          return {
            ...phone,
            contact_value: contactEdit.landline?.replaceAll(' ', '') || '',
          };
        }
        return { ...phone, contact_value: phone.value || '' };
      });
    }
    return [
      {
        contact_type: 'LANDLINE',
        contact_value: contactEdit.landline?.replaceAll(' ', '') || '',
        is_primary: true,
        phone_type: 'WORK',
        country_code: '+44',
      },
    ];
  };

  const contactAddresses = () => {
    if (addressPrimary.id) {
      return addresses?.map(address => {
        if (address.id === addressPrimary.id) {
          return {
            ...address,
            ...contactEdit.address,
          };
        }
        return address;
      });
    }
    return [
      {
        country_code: 'GB',
        is_billing: true,
        is_residential: true,
        is_postal: true,
        ...contactEdit.address,
        is_primary: true,
      },
    ];
  };

  const addressRequest = () => {
    const address = contactAddresses();
    return address.map(a => ({ ...a, location: a?.location?.lat && a?.location?.lon ? a.location : null }));
  };

  const onUpdateCompanyContact = async () => {
    if (checkCompanyErrors()) {
      return;
    }
    setLoading(true);
    const fullName = contactEdit?.name?.trim()?.split(' ');
    const phoneRequest = [...mobileRequest(), ...landlineRequest()];
    const request = {
      shared_percentage: 0,
      property_contact_type: contactEdit?.type?.value,
      is_primary: contactEdit?.is_primary,
      contact: {
        title: contactEdit?.title?.label || '',
        forename: contact_type === 'COMPANY' ? '' : fullName?.[0],
        surname: contact_type === 'COMPANY' ? '' : fullName?.[1],
        timezone: null,
        date_of_birth: null,
        pronouns: null,
        gender: null,
        parent: contact_type === 'EMPLOYEE' ? { id: parent?.id } : null,
        profession: null,
        company_name: contact_type === 'COMPANY' ? contactEdit?.name : company_name,
        contact_type: contact_type,
        job_title: job_title,
        phones: phoneRequest.length > 0 ? phoneRequest : [],
        emails: emailRequest(),
        addresses: contact_type !== 'EMPLOYEE' ? addressRequest() : null,
        call_times: call_times ? call_times : null,
        contact_period: contact_period ? contact_period : null,
      },
    };

    dispatch(updateContact({ id: property_id, contact_id: contact_id, request: request }))
      .then(() => {
        dispatch(addToast({ error: false, text: t('CONTACT_UPDATED_SUCCESSFULLY') }));
        setSideModal(initModal);
        onSuccess && onSuccess();
      })
      .catch(error => {
        dispatch(
          addToast({ error: true, text: error?.response?.data?.error_description ?? t('ERROR_UPDATING_CONTACT') }),
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setContactEdit({
      type: typeOptions.find(option => option.value === property_contact_type),
      title: contactTitleOptions.find(option => option.label === title),
      name: contact_type === 'COMPANY' ? company_name : `${forename} ${surname}`,
      email: emailPrimary.value,
      mobile: mobilePrimary.value,
      country_code: mobilePrimary?.country_code,
      landline: landlinePrimary.value,
      address: addressPrimary,
      is_primary: is_primary || false,
    });
  }, []);

  return (
    <EditContactWrapper>
      <div className="modal-header flex px-6 pt-6 pb-5 border-bottom justify-between items-center">
        <p className="inter-600-text natural-900-text font-20">
          {contact_type === 'EMPLOYEE' ? t('EDIT_COMPANY_CONTACT') : `Edit ${capitalize(formatText(contact_type))}`}
        </p>
        <div className="flex justify-end gap-3">
          <IconContainer
            Icon={CloseIcon}
            backgroundColor={loading ? 'natural_50' : 'transparent'}
            iconWidth={14}
            iconHeight={14}
            iconColor={loading ? 'natural_400' : 'natural_900'}
            iconContainerClassname="border radius-50-percent pxy-2 cursor"
            onClick={() => {
              if (loading) return;
              setSideModal(initModal);
            }}
          />
          <IconContainer
            Icon={CheckIcon}
            iconWidth={14}
            iconHeight={14}
            backgroundColor={loading ? 'primary_300' : 'primary_500'}
            iconContainerClassname="radius-50-percent pxy-2 cursor"
            onClick={() => {
              if (loading) return;
              onUpdateCompanyContact();
            }}
          />
        </div>
      </div>
      <div className="flex flex-column p-6 overflow-auto">
        {contact_type === 'EMPLOYEE' && <label className="font-16 inter-500-text mb-4">{parent?.company_name}</label>}
        <Fragment>
          <div className="flex-column gap-6">
            <div className="flex gap-6">
              <Dropdown
                className=" provider-selector"
                onChange={option => setContactEdit({ ...contactEdit, type: option })}
                name={t('TYPE')}
                options={typeOptions}
                placeholder={t('SELECT_FROM_LIST')}
                value={type}
                error={error.type && !type}
              />

              {contact_type !== 'COMPANY' && (
                <Dropdown
                  className="provider-selector"
                  onChange={option => setContactEdit({ ...contactEdit, title: option })}
                  name={t('TITLE')}
                  options={contactTitleOptions}
                  placeholder={t('SELECT_FROM_LIST')}
                  value={editTitle}
                  error={error.title && !editTitle}
                />
              )}
            </div>

            <div className="flex-column gap-6">
              <InputElement
                className="w-full"
                name={t('FULL_NAME')}
                placeholder={t('ENTER_FULL_NAME')}
                value={name}
                onChange={value => setContactEdit({ ...contactEdit, name: value })}
                error={(error.name && !name) || (error.fullName && !name?.trim().split(' ').length <= 1)}
              />
              <InputElement
                className="w-full"
                name={t('EMAIL')}
                sub_name={t('PRIMARY')}
                placeholder={t('ENTER_EMAIL')}
                value={email}
                onChange={value => setContactEdit({ ...contactEdit, email: value })}
                error={error?.contact && isContactError(email, landline, mobile)}
              />
              {contact_type !== 'COMPANY' && (
                <div className="w-full flex-column gap-1">
                  <div className="one-line">
                    <span className="inter-500-text natural-900-text">{t('MOBILE')}</span>
                    <span className="inter-400-text natural-400-text ml-1">{t('PRIMARY')}</span>
                  </div>
                  <PhoneInput
                    selectedCountry={country_code}
                    setSelectedCountry={country_code =>
                      setContactEdit({ ...contactEdit, country_code: country_code, mobile: '' })
                    }
                    phone={mobile}
                    setPhone={phone => setContactEdit({ ...contactEdit, mobile: phone })}
                    error={error?.contact && isContactError(email, landline, mobile)}
                  />
                </div>
              )}
              <div className="w-full flex-column gap-1">
                <div className="one-l">
                  <span className="inter-500-text natural-900-text">{t('LANDLINE')}</span>
                  <span className="inter-400-text natural-400-text ml-1">{t('PRIMARY')}</span>
                </div>
                <MobileInput
                  phone={landline}
                  setPhone={phone => setContactEdit({ ...contactEdit, landline: phone })}
                  error={error?.contact && isContactError(email, landline, mobile)}
                />
              </div>
              {contact_type !== 'EMPLOYEE' && (
                <Fragment>
                  <div className="flex-column">
                    <div className="mb-1 one-line">
                      <span className="inter-500-text natural-900-text">{t('ADDRESS')}</span>
                      <span className="inter-400-text natural-400-text ml-1">{t('PRIMARY')}</span>
                    </div>
                    <div
                      className={classNames(
                        'radius-1_5 px-4 py-3 flex items-center justify-between cursor address-field',
                        error.address && !address?.formatted_address && 'address-field-error',
                      )}
                      onClick={onAddressAddOrUpdate}>
                      {address?.formatted_address && (
                        <span className="inter-400-text natural-900-text font-14 one-line">
                          {address?.formatted_address || ''}
                        </span>
                      )}
                      {!address?.formatted_address && (
                        <span className="inter-300-text natural-400-text font-14">{t('CLICK_TO_ADD_ADDRESS')}</span>
                      )}
                      <div className="address-plus-icon o03">
                        {address?.formatted_address ? (
                          <EditIcon height={20} width={20} />
                        ) : (
                          <PlusIcon className="w-full h-full pxy-0_5" />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center col-gap-2">
                    <Checkbox
                      onChange={() => setContactEdit({ ...contactEdit, is_primary: !editIsPrimary })}
                      checked={editIsPrimary}
                      is_checked_done={true}
                      id="is_primary"
                    />
                    <label className="inter-500-text natural-700-text">{t('MAKE_PRIMARY_CONTACT')}</label>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </Fragment>
      </div>
    </EditContactWrapper>
  );
};

const EditContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .address-field {
    height: 44px;
    border: 1px solid ${({ theme }) => theme.natural_300};
    background-color: ${({ theme }) => theme.natural_100};
  }

  .address-field-error {
    border-color: ${({ theme }) => theme.error_500};

    &:hover {
      border-color: ${({ theme }) => theme.error_500};
    }

    &:focus {
      border-color: ${({ theme }) => theme.error_500};
      box-shadow: none;
    }
  }
`;

export default EditContact;
