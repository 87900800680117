import classNames from 'classnames';
import React, { useEffect, useId, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusIcon } from '../../assets/icons/add-icon.svg';
import { ReactComponent as EditPencil } from '../../assets/icons/edit-pensil.svg';
import { ReactComponent as MinusIcon } from '../../assets/icons/minus.svg';
import IconContainer from '../common/icon-container';
import InputElement from '../common/input';
import CustomTooltip from '../common/tooltip-new';
import QuoteDetailsTermsWrapper from './wrapper/QuoteDetailsTermWrapper';

const QuoteTermWrapper = props => {
  const { isQuoteEditable, notEditableQuoteInfo, setQuoteDetailsInitial, quoteDetailsInitialTerm } = props;
  const { t } = useTranslation();
  const id = useId();

  const [isEditKey, setEditKey] = useState('');
  const [showMore, setShowMore] = useState(false);
  const [isShowMoreHidden, setIsShowMoreHidden] = useState(true);

  const termInputRef = useRef();

  useLayoutEffect(() => {
    const target = termInputRef.current;
    if (target) {
      const scrollHeight = target.scrollHeight;
      const offsetHeight = target.offsetHeight;
      termInputRef.current.style.height = `${
        quoteDetailsInitialTerm ? (scrollHeight > offsetHeight ? scrollHeight : offsetHeight) : 40
      }px`;
      setIsShowMoreHidden(quoteDetailsInitialTerm ? scrollHeight < 120 : true);
    }
  }, [quoteDetailsInitialTerm]);

  useLayoutEffect(() => {
    const target = termInputRef.current;
    if (target) {
      if (showMore) {
        const scrollHeight = target.scrollHeight;
        termInputRef.current.style.height = scrollHeight + 'px';
        termInputRef.current.style.maxHeight = scrollHeight + 'px';
        termInputRef.current.style.webkitLineClamp = scrollHeight / 20;
      } else {
        termInputRef.current.style.removeProperty('max-height');
        termInputRef.current.style.removeProperty('-webkit-line-clamp');
      }
    }
  }, [showMore]);

  useEffect(() => {
    if (isEditKey) {
      handleTermInput('', termInputRef.current, false, true);
    } else {
      scrollBackToTop();
      setShowMore(false);
    }
  }, [isEditKey]);

  const handleTermInput = (value, target, updateState = true, focus = false) => {
    if (target) {
      const offsetHeight = target.offsetHeight;
      const scrollHeight = target.scrollHeight;
      termInputRef.current.style.height = `${scrollHeight > offsetHeight ? scrollHeight : offsetHeight}px`;
    }
    if (updateState) setQuoteDetailsInitial(prev => ({ ...prev, term: value }));
    if (focus) {
      target.focus();
      target.select();
    }
  };

  const scrollBackToTop = () => {
    const target = termInputRef.current;
    if (target) {
      target.scrollTop = 0;
    }
  };

  return (
    <QuoteDetailsTermsWrapper
      className="relative mb-4 border"
      onBlur={() => {
        setEditKey('');
        setShowMore(false);
      }}>
      <CustomTooltip
        id={`quote-tooltip-terms`}
        place="top-start"
        hidden={isQuoteEditable}
        content={<label className="flex col-gap-1 items-center">{notEditableQuoteInfo}</label>}>
        {isQuoteEditable && !isEditKey && (
          <div className="absolute icon-pencil">
            <IconContainer
              Icon={EditPencil}
              onClick={() => isQuoteEditable && setEditKey('TERM')}
              backgroundColor={'bg-natural-50'}
              iconColor="primary_500"
            />
          </div>
        )}
        <label htmlFor={id} className="block inter-500-text natural-900-text pb-3">
          {t('TERMS')}
        </label>
        <div
          className={classNames(showMore && 'textarea-content-visible')}
          onFocus={() => isQuoteEditable && setEditKey('TERM')}
          onBlur={() => setEditKey('')}>
          <InputElement
            id={id}
            type="textarea"
            inputRef={termInputRef}
            value={quoteDetailsInitialTerm || ''}
            disabled={!isQuoteEditable}
            onChange={handleTermInput}
          />
        </div>
        {isEditKey !== 'TERM' && !isShowMoreHidden && (
          <div className="show-more-button pt-3">
            <div className="flex items-center gap-2 p-0 cursor" onClick={() => setShowMore(!showMore)}>
              <IconContainer
                Icon={showMore ? MinusIcon : PlusIcon}
                backgroundColor={'natural-50'}
                iconColor="primary_500"
              />
              <span className="inter-500-text primary-500-text font-12 line-height-20">
                {showMore ? 'Hide' : 'Show more'}
              </span>
            </div>
          </div>
        )}
      </CustomTooltip>
    </QuoteDetailsTermsWrapper>
  );
};

export default QuoteTermWrapper;
