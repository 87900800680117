import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as DownArrow } from '../../../assets/icons/down-arrow.svg';
import Menu from '../menu/index.js';

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 2px;

  .menu-button {
    color: ${({ theme }) => theme.primary_500};
    background-color: transparent;
    :hover {
      background-color: transparent;
    }
  }

  * {
    cursor: pointer;
  }
`;

const BaseButton = styled.div`
  padding: 10px;
  font-size: 16px;
  border: unset;
  background: ${props => props.bgColor};
  color: ${props => props.color};
  cursor: pointer;

  .after-icon {
    margin-right: 0;
    margin-left: 8px;
  }

  label {
    color: ${props => props.color};
    font-size: ${props => props.fontSize};
  }

  &.primary {
    background: ${({ theme }) => theme.primaryBlue};
    border: 1px solid ${({ theme }) => theme.primaryBlue};

    label {
      color: #ffffff;
    }
  }
  &.default {
    background: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.natural_200};
    label {
      color: ${({ theme }) => theme.natural_700};
    }
  }

  &.secondary {
    background: ${({ theme }) => theme.secondaryBlue};
    border: 1px solid ${({ theme }) => theme.secondaryBlue};

    label {
      color: ${({ theme }) => theme.primaryBlue};
    }
  }

  &.negative {
    background: ${({ theme }) => theme.error_500};
    border: 1px solid ${({ theme }) => theme.error_500};

    label {
      color: #ffffff;
    }
  }

  &.primary-white {
    background: #ffffff;
    border: 1px solid ${({ theme }) => theme.primaryBlue};

    label {
      color: ${({ theme }) => theme.primaryBlue};
    }
  }

  &.primary-blue {
    background: ${({ theme }) => theme.primary_50};
    border: 1px solid ${({ theme }) => theme.primary_50};

    label {
      color: ${({ theme }) => theme.primaryBlue};
    }
  }

  &.primary-grey {
    background: #ffffff;
    border: 1px solid ${({ theme }) => theme.natural_200};

    label {
      color: ${({ theme }) => theme.natural_700};
    }
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: not-allowed !important;

    svg {
      * {
        stroke: #ffffff;
      }
    }
  }

  &.specified-width {
    width: ${({ width }) => width};
  }
`;

const Button70 = styled(BaseButton)`
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  flex-basis: 70%;
`;

const Button30 = styled(BaseButton)`
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  flex-basis: 30%;
  min-width: 40px;
  padding: 0;
`;

const ButtonWithDropdown = ({
  bgColor = '#BEF5EC',
  borderColor = '#BEF5EC',
  color = '#053149',
  disabled,
  afterIcon = null,
  value = {},
  size = 'small',
  width = 'fit-content',
  borderRadius = '100px',
  place = 'bottom',
  borderWidth = '1px',
  options,
  clickFunction,
  ...buttonProps
}) => {
  let height = '24px';
  let fontSize = '12px';

  switch (size) {
    case 'large':
      height = '48px';
      fontSize = '16px';
      break;
    case 'medium':
      height = '32px';
      fontSize = '14px';
      break;
    case 'tiny':
      height = '15px';
      break;
    case 'average':
      height = '40px';
      fontSize = '16px';
      break;
    case 'customsize':
      height = '44px';
      fontSize = '14px';
      break;
    default:
      break;
  }

  const [selectedButton, setSelectedButton] = useState({});

  useEffect(() => {
    setSelectedButton(options?.[0] || {});
  }, [options]);

  return (
    <ButtonContainer
      bgColor={bgColor}
      borderColor={borderColor}
      borderRadius={borderRadius}
      color={color}
      fontSize={fontSize}
      height={height}
      width={width}
      borderWidth={borderWidth}
      {...buttonProps}
      {...(disabled && {
        onClick: null,
        className: `${buttonProps.className} disabled w-full`,
      })}>
      <Button70
        onClick={() => clickFunction(selectedButton.value, selectedButton.message)}
        className={`flex items-center justify-center h-full ${buttonProps.className}`}
        bgColor={bgColor}
        color={color}>
        {selectedButton?.name && <label className="inter-500-text nowrap">{selectedButton.name}</label>}
      </Button70>
      <Button30 className={`flex items-center justify-center ${buttonProps.className}`} bgColor={bgColor} color={color}>
        <Menu
          menuList={options.filter(item => item.value !== selectedButton.value)}
          place={place}
          Icon={DownArrow}
          iconClassName="menu-button"
          setValue={option => clickFunction(option.value, option.message)}
        />
      </Button30>
    </ButtonContainer>
  );
};

export default ButtonWithDropdown;
