import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Loading from '../../assets/images/loading.svg';
import { getIntegrationDetail, setIntegrationDetails } from '../../store/features/integrationsSlice';
import IntegrationDetailsLeft from './detail-left';
import IntegrationDetailsRight from './detail-right';

const IntegrationDetails = () => {
  const dispatch = useDispatch();
  const { integration_id } = useParams();
  const [loading, setLoading] = useState(true);

  const fetchIntegrationDetails = integration_id => {
    setLoading(true);
    dispatch(
      getIntegrationDetail({
        integration_id: integration_id,
      }),
    )
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (integration_id) {
      fetchIntegrationDetails(integration_id);
    }

    return () => {
      dispatch(setIntegrationDetails({}));
    };
  }, [integration_id]);

  return (
    <IntegrationDetailsWrapper className="flex-column flex-1 mt-6 mx-2 overflow-hidden">
      {loading ? (
        <div className="item flex items-center justify-center flex-1">
          <img alt="loading" height="40px" src={Loading} />
        </div>
      ) : (
        <div className="flex flex-1 col-gap-6 h-full px-6">
          <IntegrationDetailsLeft />
          <IntegrationDetailsRight onRefresh={fetchIntegrationDetails} />
        </div>
      )}
    </IntegrationDetailsWrapper>
  );
};

export const IntegrationDetailsWrapper = styled.div`
  .hover-edit-hide {
    min-height: 32px;
    margin: 0 -8px;

    .edit-icon {
      display: none;
    }
  }

  .hover-edit {
    .edit-icon {
      display: none;
      width: 32px;
      height: 32px;
      padding: 6px 12px;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 12px;
      top: 14px;
      border-radius: 100px;
      background: var(--primary-50, #eff6ff);
    }
    &:hover {
      box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1), 0px 4px 6px -4px rgba(16, 24, 40, 0.1);
      border-radius: 8px;

      .edit-icon {
        display: flex;
      }

      .hover-edit-margin {
        margin-right: 0;
      }
    }
  }
  .content-hover-edit {
    .edit-icon {
      display: none;
    }
    &:hover {
      box-shadow: 0px 4px 10px rgba(9, 19, 53, 0.1);
      border-radius: 8px;
      margin-left: -8px;
      padding-left: 8px;
      .edit-icon {
        display: flex;
      }
    }
  }

  .hover-edit-show {
    box-shadow: 0px 4px 10px rgba(9, 19, 53, 0.1);
    border-radius: 8px;
    .edit-icon {
      display: flex;
    }

    .hover-edit-margin {
      margin-right: 0;
    }
  }
`;

export default IntegrationDetails;
