import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as UserIcon } from '../../../assets/icons/user-assignee.svg';
import { getUserInitials } from '../../../helpers/utils';
import IconContainer from '../../common/icon-container';

const backgroundColors = ['#2496FF', '#8324FF', '#F59E0B', '#22C55E', '#D942FF', '#171717'];

const avatarVariants = {
  size_56: { width: '56px', height: '56px', fontSize: '20px' },
  large: { width: '40px', height: '40px', fontSize: '14px' },
  size_32: { width: '32px', height: '32px', fontSize: '12px' },
  semi_large: { width: '28px', height: '28px', fontSize: '10px' },
  medium: { width: '24px', height: '24px', fontSize: '8px' },
  small: { width: '20px', height: '20px', fontSize: '8px' },
};

export const Avatar = ({
  id,
  avatar,
  backgroundColor,
  backgroundColorIndex = 1,
  zIndex = 1,
  getAvatarName = getUserInitials,
  avatarLabelClassname = '',
  avatarWrapperClassname = '',
  variant = 'large',
  onAvatarClick = () => {},
  hiddenAvatars = [],
}) => {
  const variantStyle = avatarVariants[variant] || avatarVariants.large;
  const colorIndex = backgroundColorIndex % backgroundColors.length;

  return (
    <OwnerWrapper
      {...variantStyle}
      className={classNames('flex items-center justify-center', avatarWrapperClassname)}
      zIndex={zIndex}
      onClick={onAvatarClick}
      key={id}
      backgroundColor={backgroundColor || backgroundColors[colorIndex]}>
      <label className={classNames('inter-600-text white-text', avatarLabelClassname)}>
        {avatar?.name || !hiddenAvatars?.length > 0 ? (
          getAvatarName(avatar ?? {})
        ) : (
          <IconContainer
            Icon={UserIcon}
            iconColor="white"
            backgroundColor="transparent"
            iconContainerClassname="p-0"
            iconWidth={14}
            iconHeight={14}
          />
        )}
      </label>
    </OwnerWrapper>
  );
};

const OwnerGroup = ({
  avatars = [],
  groupWrapperClassname = '',
  getAvatarName = getUserInitials,
  avatarVariant = 'large',
  onAvatarClick = () => {},
  selectOwners = [],
}) => {
  const selectedAvatars = Array.from(selectOwners);
  const selectedOwnersIds = selectOwners.map(item => item.id);
  const filteredAvatars = avatars.filter(item => !selectedOwnersIds?.includes(item.id));
  const visibleAvatars = filteredAvatars?.slice(0, 3);
  const hiddenAvatars = visibleAvatars?.length;

  return (
    <OwnerGroupWrapper className={classNames('flex items-center justify-center', groupWrapperClassname)}>
      {selectedAvatars?.length > 0 && (
        <div className="flex items-center justify-center mr-5">
          {selectedAvatars.map((avatar, index) => (
            <Avatar
              key={avatar.id || index}
              id={avatar.id}
              avatar={avatar}
              zIndex={index + 1}
              backgroundColor={backgroundColors[index]}
              avatarWrapperClassname="avatar selected-avatar"
              getAvatarName={getAvatarName}
              variant={avatarVariant}
            />
          ))}
        </div>
      )}
      {visibleAvatars.map((avatar, index) => (
        <Avatar
          key={avatar.id || index}
          id={avatar.id}
          avatar={avatar}
          zIndex={index + 1}
          backgroundColor={backgroundColors[index]}
          avatarWrapperClassname="cursor avatar"
          getAvatarName={getAvatarName}
          variant={avatarVariant}
        />
      ))}

      <Avatar
        id={'hidden-avatars'}
        zIndex={visibleAvatars.length + 1}
        backgroundColor={hiddenAvatars ? '#E5E5E5' : '#2496FF'}
        getAvatarName={() => `+${hiddenAvatars}`}
        avatarLabelClassname="inter-400-text natural-700-text"
        avatarWrapperClassname="cursor avatar"
        variant={avatarVariant}
        hiddenAvatars={hiddenAvatars}
        onAvatarClick={onAvatarClick}
      />
    </OwnerGroupWrapper>
  );
};

const OwnerGroupWrapper = styled.div`
  .avatar {
    margin-right: -8px;
  }
  .selected-avatar {
    box-shadow: rgb(211, 234, 254) 0px 0px 0px 4px;
  }
`;

const OwnerWrapper = styled.div`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  background: ${({ backgroundColor }) => backgroundColor};
  z-index: ${({ zIndex }) => zIndex};
  border: 2px solid #fff;
  border-radius: 50%;

  label {
    font-size: ${({ fontSize }) => fontSize};
  }
`;

export default OwnerGroup;
