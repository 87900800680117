import classNames from 'classnames';
import React, { useContext } from 'react';
import { ReactComponent as ArrowRight } from '../../../assets/images/arrow-right.svg';
import { OrganisationContext } from '../../../context/organisationContext';
import { SidebarMenuItemWrapper } from '../../../styles/components/main-layout/sidebar.styled';

const MenuItem = ({ item, onClick }) => {
  const { checkMultiplePermission, hasMultiplePermission } = useContext(OrganisationContext);

  const subItems = item.subitems?.filter(({ hasPermission, isHidden }) => hasPermission && !isHidden);

  return (
    <SidebarMenuItemWrapper>
      <div className="menu-item mt-3">
        <div
          className="flex items-center menu-item-container"
          style={{
            opacity: hasMultiplePermission(item.permission) ? 1 : 0.5,
          }}>
          <div
            className={classNames('flex items-center justify-between cursor w-full pl-6 mr-3 mi-item radius-r-100', {
              'mi-subitem-selected': item.selected && subItems.length,
              'mi-item-selected': item.selected && !subItems.length,
            })}
            onClick={() => checkMultiplePermission(() => onClick(item.id), item.permission)}>
            <div className="flex items-center">
              <div className="flex pxy-1 radius-2 icon-container">
                <item.icon className="natural-400-text mi-icon" />
              </div>
              <label className="inter-500-text natural-700-text ml-1 menu-item-name">{item.text}</label>
            </div>
            {subItems.length > 0 && (
              <ArrowRight
                height={14}
                width={14}
                className={classNames('natural-400-text menu-arrow mr-4', { 'menu-arrow-down': item.open })}
              />
            )}
          </div>
        </div>
        {subItems.length > 0 && (
          <div
            className={classNames('flex-column cursor menu-subitems mr-3', {
              'menu-subitems-show': item.open,
              'menu-subitems-hide': !item.open,
            })}
            style={{ height: item.open ? `${44 * subItems.length}px` : 0 }}>
            {subItems.map((subitem, i) => (
              <div
                key={i}
                className={classNames('flex subitem-container radius-r-100 cursor mt-1', {
                  'subitem-selected': subitem.selected,
                })}
                onClick={() => checkMultiplePermission(() => onClick(item.id, subitem.id), subitem.permission)}
                style={{
                  opacity: hasMultiplePermission(subitem.permission) ? 1 : 0.5,
                }}>
                <div key={subitem.id} className="flex items-center w-full subitem  relative pl-10">
                  <div className="flex pxy-1 radius-2 icon-container">
                    {subitem.imgIcon ? (
                      <img src={subitem.imgIcon} alt="img-icon" height={16} width={16} />
                    ) : (
                      <subitem.icon className="natural-400-text mi-icon" />
                    )}
                  </div>
                  <label className="inter-500-text natural-700-text ml-1 sub-item-name one-line">{subitem.text}</label>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </SidebarMenuItemWrapper>
  );
};

export default MenuItem;
