import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import BreadcrumbWrapper from '../../../components/common/breadcrumb-wrapper';
import PageHeader from '../../../components/page-header';
import ProjectList from '../../../components/project-list';
import { OrganisationContext } from '../../../context/organisationContext';
import useDebounce from '../../../helpers/useDebounceHook';
import { getProjectList } from '../../../store/features/jobSlice';
import { addToast } from '../../../store/features/toastSlice';

function Project() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const pageRef = useRef(0);

  const [search, setSearchText] = useState('');
  const dispatch = useDispatch();
  const [showLoading, setShowLoading] = useState(true);
  const [projectList, setProjectList] = useState([]);
  const { hasPermission } = useContext(OrganisationContext);

  const debouncedSearch = useDebounce(search, 500);
  const { setModal, setSideModal } = useContext(OrganisationContext);

  const fetchProjectList = (page, debouncedSearch, showLoading = true) => {
    setShowLoading(showLoading);
    dispatch(
      getProjectList({
        params: {
          page: page,
          size: 10,
          search: debouncedSearch,
        },
      }),
    )
      .then(data => {
        setProjectList(data);
        setShowLoading(false);
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: t('ERROR_WHILE_FETCHING_PROJECT_LIST'), id: nanoid() })),
          setProjectList([]);
      });
  };

  const fetchMoreData = () => {
    fetchProjectList(pageRef.current + 1, debouncedSearch, false);
  };

  useEffect(() => {
    fetchProjectList(0, debouncedSearch);
  }, [debouncedSearch]);

  const onDeleteProject = projects => {
    setModal({
      type: 'delete-project',
      content: {
        projects: projects,
        top: false,
        title: t('ARE_YOU_SURE_WANT_TO_DELETE_THIS_PROJECT'),
        description: t('DELETE_ACTION_DESCRIPTION'),
      },
    });
  };

  const redirectToProjectDetails = projects => {
    setSideModal({
      type: 'edit-project-details',
      content: {
        projects: projects,
        onDeleteProject: onDeleteProject,
        onSuccess: () => fetchProjectList(pageRef.current + 1, debouncedSearch, false),
      },
    });
  };

  return (
    <ProjectWrapper className="flex-column w-full h-full items-start justify-start flex-1">
      <BreadcrumbWrapper
        breadcrumbs={[
          { text: t('SETTINGS'), onClick: () => navigate('/settings', { state: 'PLATFORM_CONFIGURATION' }) },
          {
            text: t('PLATFORM_CONFIGURATION'),
            onClick: () => navigate('/settings', { state: 'PLATFORM_CONFIGURATION' }),
          },
          { text: t('PROJECT'), onClick: () => {} },
        ]}
      />
      <PageHeader
        showSearchOnLeft={true}
        showAddNewBtn={hasPermission('PROJECTS_MANAGE')}
        onAddClick={() =>
          setModal({
            type: 'add-project',
            content: { onSuccess: project => setProjectList(prevProjects => [...prevProjects, project]) },
          })
        }
        showSearch
        searchText={search}
        addButtonLabel={t('ADD_NEW')}
        onSearchChange={setSearchText}
      />
      <ProjectList
        debouncedSearch={debouncedSearch}
        onDeleteProject={onDeleteProject}
        redirectToProjectDetails={redirectToProjectDetails}
        fetchMoreData={fetchMoreData}
        showLoading={showLoading}
        projectList={projectList}
      />
    </ProjectWrapper>
  );
}

const ProjectWrapper = styled.div``;

export default Project;
