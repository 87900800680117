import { AnimatePresence, motion } from 'framer-motion';
import React, { Fragment, useContext } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { OrganisationContext } from '../context/organisationContext';
import { initModal } from '../helpers/utils';
import AddressPopup from './address-popup';
import { ModalWrapper } from './modal.styled';

const PopupComponents = {
  'address-popup': AddressPopup,
};

const InsideModal = () => {
  const { setInsideModal, insideModal } = useContext(OrganisationContext);

  const onClick = e => {
    if (e.target === e.currentTarget) {
      setInsideModal(initModal);
    }
  };

  const ref = useOnclickOutside(
    () => {
      setInsideModal(initModal);
    },
    {
      ignoreClass: `ignore-click`,
    },
  );

  const setPopupComponent = () => {
    const Component = PopupComponents[insideModal.type];
    if (Component) {
      return <Component />;
    }
    return <Fragment />;
  };

  if (!insideModal.type) return null;

  return (
    <AnimatePresence>
      <ModalWrapper
        initial={{ opacity: 0, background: 'transparent' }}
        animate={{ opacity: 1, background: 'rgba(4, 32, 48, 0.8)' }}
        exit={{ opacity: 0, background: 'transparent' }}
        transition={{ duration: 0.3 }}
        key="modal-wrapper-inside-id"
        id="modal-wrapper-inside-id"
        onclick={onClick}
        style={{ justifyContent: insideModal.content?.top ? 'start' : 'center', zIndex: 10 }}>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          transition={{ duration: 0.5 }}
          key="modal-content-inside-id"
          ref={ref}
          className="modal-content"
          id="modal-content-inside-id"
          style={{
            borderRadius: insideModal.content?.noRadius ? '0' : '16px',
            marginTop: insideModal.content?.top ? '80px' : '0',
          }}>
          {setPopupComponent()}
        </motion.div>
      </ModalWrapper>
    </AnimatePresence>
  );
};

export default InsideModal;
