import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { ReactComponent as Chart } from '../../assets/icons/property/chart.svg';
import { ReactComponent as Square } from '../../assets/icons/property/square.svg';
import { capitalizeFirstLetterOfWords, formatText } from '../../helpers/utils';
import MenuV2 from '../common/menu/menu-v2';
import Status from '../common/status';
import CustomTooltip from '../common/tooltip-new';

const PropertyListItem = ({ property = {} }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id: propertyId, name, formatted_address, contacts = [], property_class, engagements } = property;

  const { class_type } = property_class || {};
  const primaryContact = contacts?.[0] || {};
  const primaryPhone = primaryContact.phones?.[0] || {};

  const [isAnimating, setIsAnimating] = useState(true);
  const [jobTooltipOpened, setJobTooltipOpened] = useState(false);

  const isNoMobilePrimary = !primaryPhone.value || /^(na|n\/a)$/i.test(primaryPhone.value.trim());

  const onPropertyClick = () => {
    navigate(`property/${propertyId}`);
  };

  const renderContactDetails = (contact, phone, isNoMobile) => {
    return (
      <>
        {(contact?.contact_type === 'COMPANY' ? contact.company_name : contact.name) || phone.value ? (
          <div className="flex-column row-gap-2 justify-center overflow-hidden py-4">
            <div className="flex items-start col-gap-1">
              <span className="inter-500-text natural-500-text font-14 line-height-20">
                {contact?.contact_type === 'COMPANY' ? t('COMPANY') : t('PERSON')}:
              </span>
              <label
                className={classNames(
                  'inter-500-text natural-400-text font-14 line-height-20 one-line',
                  (contact?.company_name || contact?.name) && 'natural-700-text',
                )}>
                {contact?.contact_type === 'COMPANY'
                  ? capitalizeFirstLetterOfWords(formatText(contact.company_name || t('NO_COMPANY')))
                  : capitalizeFirstLetterOfWords(formatText(contact.name || t('NO_PERSON')))}
              </label>
            </div>
            <div className="flex items-center col-gap-1">
              <span className="inter-500-text natural-500-text font-14 line-height-20">{t('MOBILE')}:</span>
              <label
                className={classNames(
                  'inter-500-text natural-400-text font-14 line-height-20',
                  !isNoMobile && 'natural-900-text',
                )}>
                {isNoMobile ? 'No mobile' : phone.value}
              </label>
            </div>
          </div>
        ) : (
          <div className="flex items-center col-gap-1">
            <label className="inter-400-text natural-500-text font-14 line-height-20">{t('NO_CONTACT')}</label>
          </div>
        )}
      </>
    );
  };

  const addressTooltipContent = () => {
    return (
      <div style={{ maxWidth: '300px' }}>
        <label className="inter-500-text font-12">{formatted_address}</label>
      </div>
    );
  };

  const getStageNameOfJob = workitems => {
    const workitem = workitems?.[0] || {};
    const workitemLength = workitems?.length || 0;
    const stageName = workitem?.stage?.name || null;
    return stageName ? `${workitem?.stage?.name}${workitemLength > 1 ? ` + ${workitemLength}` : ''}` : '-';
  };

  const onJobClick = engagement => {
    const { workitems } = engagement;
    const firstWorkitem = workitems?.[0];
    if (firstWorkitem) {
      navigate(`property/${propertyId}/workitems/${firstWorkitem.id}`);
    }
  };

  const JobItem = ({ job, tooltip, index }) => {
    const isLastItem = index === engagements.length - 1;

    return (
      <div
        onClick={e => {
          e.stopPropagation();
          onJobClick(job);
        }}
        className={classNames(
          'flex-column justify-center gap-1  border-bottom-0 jobs-content-tooltip cursor',
          tooltip ? 'py-2 px-4' : 'my-2 px-2',
          !isLastItem && 'border-bottom',
        )}>
        <label className="inter-500-text natural-700-text line-height-20 font-14">{job?.name}</label>
        <div className="flex items-center gap-2 justify-between">
          <div className="flex items-center gap-2">
            <div className="flex gap-1">
              <Chart className="orange-500-text mr-1" />
              <label className="flex items-center inter-400-text natural-900-text line-height-20 font-14">
                {job?.workitems[0]?.days_in_process || '0'}
              </label>
            </div>
            <div className="flex gap-1">
              <div className="flex items-center">
                <Square className="success-500-text" />
                <label className="flex items-center inter-400-text natural-900-text line-height-20 font-14">
                  {job?.workitems[0]?.days_in_stage || '0'}
                </label>
              </div>
            </div>
          </div>

          {getStageNameOfJob(job?.workitems) && (
            <div className="flex gap-1 cursor">
              <label className="color-purple bg-pink px-3 py-0_5 radius-6 inter-500-text font-12 line-height-20">
                {getStageNameOfJob(job?.workitems)}
              </label>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <PropertyListItemWrapper
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}
      key={propertyId}
      className="data-container py-2 border-bottom cursor"
      onClick={onPropertyClick}
      onAnimationComplete={() => setIsAnimating(false)}
      onAnimationStart={() => setIsAnimating(true)}>
      <div className="flex items-center overflow-hidden py-4">
        <CustomTooltip
          wrapperClassName="flex-column row-gap-2 justify-center overflow-hidden"
          id={formatted_address.replace("'", '')}
          place="right"
          hidden={!formatted_address || isAnimating}
          content={addressTooltipContent()}>
          {name && <label className="inter-500-text natural-700-text">{name}</label>}
          <label
            className={classNames(
              name ? 'inter-400-text natural-500-text one-line' : 'inter-500-text natural-700-text three-lines',
            )}>
            {formatted_address}
          </label>
        </CustomTooltip>
      </div>
      <div className="flex-column justify-center row-gap-2 overflow-hidden py-4">
        <div className="flex items-center">
          <Status
            withDot={false}
            withDottedBorder={false}
            status={class_type}
            statusText={capitalizeFirstLetterOfWords(formatText(class_type))}
          />
        </div>
      </div>
      {renderContactDetails(primaryContact, primaryPhone, isNoMobilePrimary)}
      {engagements?.length > 1 ? (
        <div className="flex-column row-gap-2 justify-center overflow-hidden py-4">
          <CustomTooltip
            wrapperClassName="job-tooltip"
            tooltipClassname="custom-scrollbar thin-scrollbar"
            id={`${engagements[0]?.id}-workflow`}
            place="bottom"
            clickable
            isOpen={jobTooltipOpened}
            hidden={!jobTooltipOpened}
            onBlurTooltip={() => setJobTooltipOpened(false)}
            noArrow={true}
            content={engagements?.map((data, index) => (
              <JobItem key={data?.id} job={data} tooltip={true} index={index} />
            ))}>
            <Status
              onClick={e => {
                e.stopPropagation();
                setJobTooltipOpened(!jobTooltipOpened);
              }}
              withDot={false}
              withDottedBorder={false}
              status={'DRAFT'}
              statusText={capitalizeFirstLetterOfWords(formatText(`${engagements?.length} ${t('JOBS')}`))}
            />
          </CustomTooltip>
        </div>
      ) : engagements?.length == 1 ? (
        engagements?.map((jobitem, index) => <JobItem key={jobitem?.id} job={jobitem} index={index} />)
      ) : (
        <div className="flex items-center col-gap-1">
          <label className="inter-400-text natural-400-text font-14 line-height-20">{t('NO_JOB')}</label>
        </div>
      )}

      <MenuV2
        menuList={[
          {
            name: t('DETAILS'),
            onClick: onPropertyClick,
          },
        ]}
        menuClassName=" h-fit-content property-menu"
        iconClassname="my-4"
      />
    </PropertyListItemWrapper>
  );
};

const PropertyListItemWrapper = styled(motion.div)`
  min-height: 92px;
  max-height: 92px;

  &:hover {
    background: ${({ theme }) => theme.natural_50};
  }
  .job-tooltip {
    .custom-tooltip {
      padding: 0;
      width: 276px;
      height: auto;
      max-height: 272px !important;
      overflow: auto;
      filter: none;
      box-shadow: rgb(179 179 179 / 67%) 0px 0px 3px 0px !important;
    }
  }
  .status-text {
    font-size: 14px;
  }
  .property-menu {
    .custom-tooltip {
      width: 157px !important;
      filter: drop-shadow(0px 0px 6px #1018281a);

      .menu-wrapper .menu-item:hover {
        width: 157px !important;
      }
    }
  }

  .jobs-content-tooltip {
    &:hover {
      background-color: ${({ theme }) => theme.primary_50};
    }
  }
`;

export default PropertyListItem;
