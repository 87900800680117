import classNames from 'classnames';
import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckDone } from '../assets/icons/check-done.svg';
import { ReactComponent as EditIcon } from '../assets/icons/edit-pensil.svg';
import { ReactComponent as DeleteIcons } from '../assets/images/DeleteIcons.svg';
import { ReactComponent as CrossIcon } from '../assets/images/toast-close.svg';
import Button from '../components/common/button/button';
import IconContainer from '../components/common/icon-container';
import { OrganisationContext } from '../context/organisationContext';

const SideModelHeader = ({
  deletePermission,
  onSaveUpdate,
  permission,
  showSave,
  title,
  handleEdit,
  isLoading = false,
  handleDelete,
  isIconButtons = false,
  onClose,
}) => {
  const { t } = useTranslation();
  const { hasPermission } = useContext(OrganisationContext);

  return (
    <div className="flex w-full items-center justify-between border-bottom pb-5_5 pt-6 px-6">
      <div>
        <h2 className="inter-600-text font-20 line-height-28 natural-900-text my-0">{title}</h2>
      </div>

      <div className={classNames('flex items-center col-gap-4', { 'flex-row-reverse': isIconButtons && showSave })}>
        {showSave ? (
          <div>
            {isIconButtons ? (
              <IconContainer
                Icon={CheckDone}
                backgroundColor="primary_500"
                iconColor="white"
                iconClassName="mxy-2"
                iconHeight={14}
                iconWidth={14}
                iconContainerClassname={'cursor border, radius-50-percent'}
                onClick={() => !isLoading && onSaveUpdate()}
              />
            ) : (
              <Button
                fontSize="14px"
                size="medium"
                width="96px"
                height="32px"
                borderRadius="100px"
                className={`primary specified-width ml-3`}
                style={{ padding: '6px 16px' }}
                disabled={isLoading}
                icon={<CheckDone className="mr-0" width={14} height={14} />}
                label={t('SAVE')}
                onClick={onSaveUpdate}
                loading={isLoading}
              />
            )}
          </div>
        ) : (
          <Fragment>
            {hasPermission(permission) && (
              <IconContainer Icon={EditIcon} iconColor="primary_500" onClick={() => handleEdit()} />
            )}
            {hasPermission(deletePermission) && (
              <IconContainer iconColor="primary_500" Icon={DeleteIcons} onClick={handleDelete} />
            )}
          </Fragment>
        )}
        <IconContainer
          Icon={CrossIcon}
          onClick={() => !isLoading && onClose()}
          backgroundColor="transparent"
          iconColor="natural_900"
          iconClassName={isIconButtons && showSave ? 'mxy-2' : ''}
          iconHeight={isIconButtons && showSave ? 14 : 16}
          iconWidth={isIconButtons && showSave ? 14 : 16}
          iconContainerClassname={classNames('cursor', {
            border: isIconButtons && showSave,
            'radius-50-percent': isIconButtons && showSave,
          })}
        />
      </div>
    </div>
  );
};

export default SideModelHeader;
