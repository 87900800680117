export const draftTypeOption = [
  { name: 'Send Quote', value: 'SENT_QUOTE_MODEL' },
  {
    name: 'Mark as sent',
    value: 'SENT',
    message: code => `Quote ${code} has been put marked as sent`,
  },
];

export const sentTypeOption = [
  { name: 'Mark as accepted', value: 'ACCEPTED', message: code => `Quote ${code} has been marked as accepted` },
  {
    name: 'Send',
    value: 'SENT_QUOTE_MODEL',
    message: code => `Quote ${code} has been sent to the recipient`,
  },
  {
    name: 'Revise',
    value: 'REVISE',
    message: code => `Quote ${code} has been put marked as draft, you can now revise the quote`,
  },
  { name: 'Mark as Rejected', value: 'REJECTED', message: code => `Quote ${code} has been marked as rejected` },
];

export const acceptedTypeOption = [
  { name: 'Send Quote', value: 'SENT_QUOTE_MODEL' },
  {
    name: 'Unmark as Accepted',
    value: 'SENT',
    message: code => `Quote ${code} has been marked as sent`,
  },
];

export const rejectedOption = [
  {
    name: 'Unmark as rejected',
    value: 'UNMARK_AS_REJECTED',
    message: code => `Quote ${code} has been marked as draft, you can now revise the quote`,
  },
  {
    name: 'Revise',
    value: 'REVISE',
    message: code => `Quote ${code} has been marked as draft, you can now revise the quote`,
  },
];

export const estimateTypeOption = [
  {
    name: 'Send',
    value: 'SENT_QUOTE_MODEL',
    message: code => `Quote ${code} has been sent to the recipient`,
  },
  {
    name: 'Convert to draft quote',
    value: 'CONVERT_TO_DRAFT',
    message: code => `Quote ${code} has been converted to draft quote`,
  },
];
