import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as CrossIcon } from '../../../assets/icons/CrossIcons.svg';
import { getProductFamilies } from '../../../store/features/quotesSlice';

import Accordion from '../Accordion';
import Button from '../button/button';
import Checkbox from '../checkbox';
import SearchableDropdown from '../searchable-dropdown';
import Dropdown from '../select-dropdown';

const FilterPopover = ({
  setShowFilterPopover,
  productDrawStatusFilter,
  productFilters,
  setProductFilters,
  onApplyFilters,
  onClearFilters,
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');

  function updateFilters(key, value) {
    setProductFilters({
      ...productFilters,
      [key]: value,
    });
  }

  function handleSoldAs(key, value) {
    setProductFilters({
      ...productFilters,
      soldAs: {
        ...productFilters?.soldAs,
        [key]: value,
      },
    });
  }

  const applyFilters = () => {
    onApplyFilters();
    setShowFilterPopover(false);
  };
  return (
    <FilterPopoverWrapper className="popover-content bg-white py-5 radius-2 flex-column">
      <ProductFilterWrapper className="w-full py-5" onClick={e => e.stopPropagation()}>
        <div>
          <div className="flex px-5 mb-6 items-center justify-between">
            <p className="inter-500-text font-18 natural-900-text">Filter by</p>
            <CrossIcon
              onClick={() => setShowFilterPopover(false)}
              className="cursor natural-700-text"
              width={22}
              height={22}
            />
          </div>
          <div className="px-5" style={{ marginBottom: '14px' }}>
            <Dropdown
              className="drop-down-filter"
              onChange={option => updateFilters('status', option)}
              options={productDrawStatusFilter}
              placeholder={'ALL'}
              value={productFilters?.status}
              customStyle={{
                control: {
                  borderRadius: '0px',
                  borderTop: '0px',
                  borderLeft: '0px',
                  borderRight: '0px',
                  borderBottom: '1px solid #E5E5E5',
                  '&:hover': {
                    boxShadow: 'none',
                  },
                },
                dropdownIndicator: {
                  marginRight: '0px',
                },
              }}
            />
          </div>
          <div>
            <Accordion titleClass="natural-400-text" title="Sold as" headerClass="mx-5 mr-1">
              <div className="px-4 py-5 bg-natural-50 mt-3">
                <div className="flex items-center gap-2 mb-4">
                  <Checkbox
                    is_checked_done={true}
                    checked={productFilters?.soldAs?.single}
                    onChange={e => handleSoldAs('single', e)}
                    id="sold-single"
                  />
                  <label htmlFor="sold-single" className="no-select inter-400-text font-16">
                    Single
                  </label>
                </div>
                <div className="flex items-center gap-2">
                  <Checkbox
                    is_checked_done={true}
                    id="sold-bundle"
                    checked={productFilters?.soldAs?.bundle}
                    onChange={e => handleSoldAs('bundle', e)}
                  />
                  <label htmlFor="sold-bundle" className="no-select inter-400-text font-16">
                    Bundle
                  </label>
                </div>
              </div>
            </Accordion>
          </div>

          <div className="mb-7 px-5 ">
            <SearchableDropdown
              isSearchable={false}
              isClearable
              inputValue={search}
              onInputChange={setSearch}
              className="w-full drop-down-filter"
              placeholder={'Family name'}
              showIconInOption={false}
              customStyle={{
                control: {
                  borderRadius: '0px',
                  borderTop: '0px',
                  borderLeft: '0px',
                  borderRight: '0px',
                  borderBottom: '1px solid #E5E5E5',
                  '&:hover': {
                    boxShadow: 'none',
                  },
                },
              }}
              value={productFilters?.family}
              onChange={option => updateFilters('family', option)}
              defaultAdditional={{
                page: 0,
                fetchFunction: getProductFamilies,
                pageable: false,
                setToRedux: false,
              }}
            />
          </div>
        </div>
      </ProductFilterWrapper>
      <div className="flex col-gap-6 px-5 justify-center mt-1 ">
        <Button
          className={classNames('primary-grey')}
          label={t('CLEAR_ALL')}
          onClick={() => {
            setShowFilterPopover(false);
            onClearFilters();
          }}
          size="average"
          borderRadius="100px"
        />
        <Button
          className={classNames('primary ')}
          label={t('APPLy_FILTERS')}
          onClick={() => {
            applyFilters();
            setShowFilterPopover(false);
          }}
          size="average"
          borderRadius="100px"
        />
      </div>
    </FilterPopoverWrapper>
  );
};

const FilterPopoverWrapper = styled.div`
  &.popover-content {
    z-index: 10;
    width: 357px;
    box-shadow: 0px 2px 8px -4px rgba(16, 24, 40, 0.1), 0px 6px 17px -3px rgba(16, 24, 40, 0.1);
  }

  .radio-container {
    margin-bottom: 14px;
  }

  .radio-label {
    margin-left: 5px;
  }

  input[type='radio'] {
    width: 20px;
    height: 20px;
  }

  .service-container {
    gap: 14px;
    margin-top: 14px;
  }
  .scroll-container {
    max-height: 350px;
    overflow: auto;
  }
`;

const ProductFilterWrapper = styled.div`
  max-width: 357px;
  min-width: 357px;
  pointer-events: all;
  .drop-down-filter {
    .select__value-container,
    .searchable-select__placeholder,
    .searchable-select__value-container,
    .searchable-select__indicator {
      padding: 0 !important;
      margin: 0px !important;
    }
  }
`;

export default FilterPopover;
