import { createSlice } from '@reduxjs/toolkit';
import api from '../services';

export const integrationsSlice = createSlice({
  name: 'integrations',
  initialState: {
    integrationList: [],
    integrationDetails: {},
    integrationConfigsDetail: [],
  },
  reducers: {
    setIntegrationList: (state, { payload }) => {
      state.integrationList = payload;
    },
    setIntegrationDetails: (state, { payload }) => {
      state.integrationDetails = { ...payload };
    },
    setIntegrationConfigsDetail: (state, { payload }) => {
      state.integrationConfigsDetail = payload;
    },
    setConnectors: (state, { payload }) => {
      state.connectors = payload;
    },
    addNewIntegrationInList: (state, { payload }) => {
      state.integrationList = [...state.integrationList, payload];
    },
    updateIntegrationInList: (state, { payload }) => {
      state.integrationList = [
        ...state.integrationList.map(integration =>
          integration.id === payload.id ? { ...payload } : { ...integration },
        ),
      ];
    },
    setIntegrationAutomations: (state, { payload }) => {
      state.integrationAutomations = payload;
    },
    removeIntegrationFromList: (state, { payload }) => {
      state.integrationList = [...state.integrationList.filter(integration => integration.id !== payload.id)];
    },
    updatedIntegrationStateInList: (state, { payload }) => {
      state.integrationList = [
        ...state.integrationList.map(integration =>
          integration.id === payload.id ? { ...integration, is_enabled: !integration.is_enabled } : { ...integration },
        ),
      ];
    },
  },
});

export const getIntegrationList =
  ({ params, storeData = false }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/integrations`, { params: params });
      storeData && dispatch(setIntegrationList(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getIntegrationDetail =
  ({ integration_id }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/integrations/${integration_id}`);
      dispatch(setIntegrationDetails(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getIntegrationConfig = payload => async dispatch => {
  try {
    const { data } = await api.get(`/connector/${payload.connectorId}/config`);
    dispatch(setIntegrationConfigsDetail(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getConnectors = () => async dispatch => {
  try {
    const { data } = await api.get(`/connectors`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createIntegration = payload => async dispatch => {
  try {
    const { data } = await api.post(`/integrations`, payload.request);
    dispatch(addNewIntegrationInList(data));
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateIntegration = payload => async dispatch => {
  try {
    const { data } = await api.put(`/integrations/${payload.id}`, payload.request);
    dispatch(updateIntegrationInList(data));
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getIntegrationAutomations = payload => async dispatch => {
  try {
    const { data } = await api.get(`/integrations/${payload.id}/automations`);
    dispatch(setIntegrationAutomations(data));
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteIntegration = payload => async dispatch => {
  try {
    const { data } = await api.delete(`/integrations/${payload.id}`);
    dispatch(removeIntegrationFromList(payload));
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateIntegrationState = payload => async dispatch => {
  try {
    const { id, request, updateList } = payload;
    const { data } = await api.put(`/integrations/${id}/state`, request);
    if (updateList) {
      dispatch(updatedIntegrationStateInList(payload));
    }
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const connectOauth2 =
  ({ integration_id }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/integrations/${integration_id}/oauth2_connect`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const disconnectOauth2 =
  ({ integration_id }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/integrations/${integration_id}/oauth2_disconnect`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const redirectOauth2 =
  ({ request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/integrations/oauth2_redirect`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getIntegrationTemplateListOptions =
  ({ integration_id, list_id, params = {} }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/integrations/${integration_id}/lists/${list_id}`, { params });
      return Promise.resolve({ data });
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const {
  setIntegrationList,
  setIntegrationDetails,
  setIntegrationConfigsDetail,
  setConnectors,
  addNewIntegrationInList,
  updateIntegrationInList,
  setIntegrationAutomations,
  removeIntegrationFromList,
  updatedIntegrationStateInList,
} = integrationsSlice.actions;
export default integrationsSlice.reducer;
