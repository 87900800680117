import styled from 'styled-components';

export const IntegrationsDetailWrapper = styled.div`
  overflow: hidden;

  .hover-edit-hide {
    min-height: 25px;
    .edit-icon {
      display: none;
    }
  }

  .hover-edit {
    .edit-icon {
      display: none;
    }
    &:hover {
      box-shadow: 0px 4px 10px rgba(9, 19, 53, 0.1);
      border-radius: 8px;
      margin: 2px;
      .edit-icon {
        display: flex;
        position: absolute;
        right: 12px;
        width: 32px;
        height: 32px;
        padding: 6px 12px;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        background: #eff6ff;
        gap: 6px;
      }
    }
  }
`;

export const IntegrationsDetailLeftWrapper = styled.div`
  height: 100%;
  min-width: 350px;
  max-width: 350px;
  border-radius: 16px;
  max-height: 100%;
  overflow: hidden;

  .update-detail-contaner {
    display: flex;
    padding: 12px;
    gap: 24px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 6px;
    background: var(--generic-white, #fff);
    box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1), 0px 4px 6px -4px rgba(16, 24, 40, 0.1);
    .input {
      height: 40px;
    }
  }
`;

export const IntegrationsDetailRightWrapper = styled.div`
  border-radius: 16px;
  max-height: 100%;

  .action-container {
    .update-action {
      bottom: -27px;
      z-index: 2;
    }
  }

  .hover-edit-right:hover {
    .value-container {
      padding: 8px 12px;
    }

    .edit-icon {
      top: unset;
      right: unset;
      bottom: -16px;
      left: 8px;
      box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1), 0px 4px 6px -4px rgba(16, 24, 40, 0.1);
    }
  }

  .update-detail-container {
    display: flex;
    padding: 12px 0;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 6px;
    background: var(--generic-white, #fff);
    box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1), 0px 4px 6px -4px rgba(16, 24, 40, 0.1);
  }
  .input {
    height: 40px;
  }
  .integration-input {
    .input {
      padding-right: 40px;
    }
    .input-show-img {
      position: absolute;
      right: 0;
      height: 100%;
    }
  }

  .integration-input-dropdown {
    .organisations-border {
      height: 48px;

      .selected-name {
        font-size: 16px;
      }
    }
    .mc-options {
      top: 53px;
    }
  }
`;
