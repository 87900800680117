import classNames from 'classnames';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
// import { ReactComponent as PartnersIcon } from '../../assets/icons/partners-Icons.svg';
import { ReactComponent as FilterIcon } from '@assets/icons/filter.svg';
import Button from '../../components/common/button/button';
import DateSelectorElement from '../../components/common/date-selector/date-selector-element';
import GoogleMaps from '../../components/common/google-maps';
import Popover from '../../components/common/popover';
import FilterPopover from '../../components/common/popover/filter-popover';
import { getItemFromLocalStorage } from '../../helpers/localstorage';
import ActivityScheduler from '../../popup/activity/activity-scheduler';
import { getAvailability, getOrganizationServices } from '../../store/features/activitiesSlice';

const viewTypeOptions = [
  { label: 'Day', value: 'DAY' },
  { label: 'Week', value: 'WEEK' },
];

const Activities = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const localUser = getItemFromLocalStorage('user');
  const { organizations } = localUser;

  const todayRef = useRef(moment().startOf('day').unix());

  const [activities, setActivities] = useState([{ id: nanoid(), resources: [] }]);
  const [selectedActivity, setSelectedActivity] = useState(activities.at(0));
  const [resourceType, setResourceType] = useState('USER');
  const [viewType, setViewType] = useState(viewTypeOptions.find(option => option.value === 'DAY'));
  const [selectedService, setSelectedService] = useState(null);
  const [selectedDate, setSelectedDate] = useState(todayRef.current);
  const [availability, setAvailability] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [services, setServices] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [loadingAvailabilities, setLoadingAvailabilities] = useState(false);
  const [location, setLocation] = useState([]);
  const [showFilterPopover, setShowFilterPopover] = useState(false);

  const fetchOrganizationServices = organizations_id => {
    dispatch(getOrganizationServices({ organization_id: organizations_id }))
      .then(data => {
        const services = data.map(service => ({
          ...service,
          label: service.name,
          value: service.id,
        }));
        setServices(services);
        if (services.length === 0) {
          setLoadingAvailabilities(false);
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (organizations?.[0]?.id) {
      fetchOrganizationServices(organizations?.[0]?.id);
    }
  }, []);

  const fetchAvailability = services => {
    const servicesIds = services.map(service => service.id).join(',');
    const activity_start_date = moment.unix(selectedDate).startOf('day').unix();
    const activity_end_date = moment.unix(selectedDate).endOf('day').unix();
    setLoadingAvailabilities(true);
    dispatch(
      getAvailability({
        params: {
          ...(servicesIds && { service_id: servicesIds }),
          resource_type: resourceType,
          'activity_date.start': activity_start_date,
          'activity_date.end': activity_end_date,
        },
      }),
    )
      .then(data => {
        setAvailability(data);
      })
      .catch(() => {})
      .finally(() => setLoadingAvailabilities(false));
  };

  useEffect(() => {
    if (resourceType) {
      const selectedServices = selectedService ? selectedService : services;
      fetchAvailability(selectedServices);
    }
  }, [resourceType, selectedDate, selectedService, showMap]);

  useEffect(() => {
    if (resourceType === 'USER' || resourceType === 'ORGANIZATION') {
      setAppointments(availability);
    }
    if (resourceType === 'TEAM') {
      const groupByTeam = availability?.reduce((acc, curr) => {
        const { team } = curr;
        if (!acc[team?.id]) {
          acc[team?.id] = { team, resources: [] };
        }
        acc[team?.id]?.resources.push({ ...curr, isPartOfTeamOrOrganization: true });
        return acc;
      }, {});
      setAppointments(
        Object.entries(groupByTeam)
          .map(([_, value]) => [
            { ...value.team, user: { ...value.team }, isTeamOrOrganization: true },
            ...value?.resources,
          ])
          .flat(),
      );
    }
  }, [availability]);

  const getSelectedResourcesInActivity = selectedActivity => {
    const activity = activities.find(a => a.id === selectedActivity.id);
    if (!activity) {
      return [];
    }
    return activity?.resources.map(r => r.appointment_id);
  };

  const renderTodayButton = () => {
    if (todayRef.current === selectedDate) {
      return null;
    }
    return (
      <Button
        fontSize="14px"
        className="primary-grey specified-width px-5"
        width="auto"
        size="customsize"
        label="Today"
        onClick={() => setSelectedDate(todayRef.current)}
      />
    );
  };

  useEffect(() => {
    if (appointments?.length > 0) {
      const uniqueLocationsArray = [
        ...new Set(
          appointments.map(appointment => {
            const location = appointment?.resources?.[0]?.activity?.engagement?.property?.location;
            if (location) {
              const { lat, lon } = location;
              return { lat: parseFloat(lat), lon: parseFloat(lon) };
            }
            return null;
          }),
        ),
      ].filter(location => location !== null);

      setLocation(uniqueLocationsArray);
    } else {
      setLocation([]);
    }
  }, [appointments]);

  return (
    <ActivitiesWrapper className="flex-column flex-1">
      <ActivitiesHeaderWrapper className="flex items-center w-full border-bottom-light py-4 px-6 justify-between">
        <label className="inter-700-text natural-900-text font-24">{t('ACTIVITIES')}</label>
        <div className="flex col-gap-6">
          <Popover
            isOpen={showFilterPopover}
            offset={[0, 2]}
            content={
              <FilterPopover
                setShowFilterPopover={setShowFilterPopover}
                isReschedule={false}
                setResourceType={setResourceType}
                services={services}
                selectedService={selectedService}
                setSelectedService={setSelectedService}
              />
            }>
            <div className="btn-container" onClick={() => setShowFilterPopover(!showFilterPopover)}>
              <div className="flex items-center justify-center h-full py-2 px-4 ml-3 cursor bg-primary-100 filter-button radius-full">
                <label className="inter-500-text primary-text mr-1">{t('FILTER')}</label>
                <FilterIcon className="primary-text" />
              </div>
            </div>
          </Popover>
          <DateSelectorElement
            isDisabled={false}
            className="w-fit-content radius-full"
            selectedDate={selectedDate}
            setSelectedDate={date => setSelectedDate(date)}
            placeholder={t('SELECT')}
            isCalendarIcon={false}
            arrows={true}
            format="ddd DD MMM, YYYY"
          />
          {renderTodayButton()}
          {/* <div className="flex items-center gap-3 view-days">
            <label className="inter-400-text  font-16">{t('VIEW')}:</label>
            <Dropdown
              isDisabled={false}
              options={viewTypeOptions}
              placeholder={t('SELECT')}
              value={viewType}
              onChange={option => setViewType(option)}
              isSearchable={false}
              customStyle={{ control: { borderRadius: '100px' } }}
            />
          </div>
          <IconContainer
            iconContainerClassname="flex download-icon cursor"
            iconClassName="natural-400-text"
            iconHeight={20}
            iconWidth={20}
            iconColor={'primary_500'}
            backgroundColor={'primary_50'}
            Icon={DownloadIcon}
          />
          <div className="flex items-center">
            <label className="inter-400-text natural-900-text font-16 mr-1">{t('MAP')}</label>
            <Switch enabled={showMap} onClick={() => setShowMap(!showMap)} />
          </div> */}
        </div>
      </ActivitiesHeaderWrapper>

      {showMap ? (
        <div className="overflow-scroll pxy-6 h-full">
          <div className={classNames('flex items-center justify-center radius-3 entire-map-wrapper h-full')}>
            <GoogleMaps coordinates={location} />
          </div>
        </div>
      ) : (
        <ActivitiesDetailsWrapper className="flex-1 w-full overflow-scroll h-full px-6 mb-4">
          <div className="flex-1 mt-6 border radius-2">
            <ActivityScheduler
              isServiceSelected={selectedService?.length > 0}
              appointments={appointments}
              type={resourceType}
              resourceTypeLabel={resourceType}
              onAddResource={() => {}}
              isShowAddress={true}
              selectedService={selectedService}
              fetchAvailability={fetchAvailability}
              selectedDate={selectedDate}
              loading={loadingAvailabilities}
              isCheckbox={false}
              services={services}
              fromActivityScheduler={false}
              selectedResourcesInActivity={() => getSelectedResourcesInActivity(selectedActivity)}
            />
          </div>
        </ActivitiesDetailsWrapper>
      )}
    </ActivitiesWrapper>
  );
};

const ActivitiesWrapper = styled.div`
  max-height: 100vh;
  overflow: hidden;
`;

const ActivitiesHeaderWrapper = styled.div`
  .resource-type,
  .service-type {
    width: auto;
  }
  .download-icon {
    border-radius: 100%;
    padding: 0px;
    width: 44px;
    height: 44px;
  }
  .filter-button {
    height: 40px;
  }
`;

const ActivitiesDetailsWrapper = styled.div`
  * ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  * ::-webkit-scrollbar-thumb {
    background: #a3a3a3;
    border-radius: 10px;
  }
`;

export default Activities;
