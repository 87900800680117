import moment from 'moment/moment';
import React, { useContext } from 'react';
import { CSSTransition } from 'react-transition-group';
import { ReactComponent as Closeicon } from '../../assets/icons/property/close.svg';
import { ReactComponent as RightArrow } from '../../assets/icons/property/rightArrow.svg';
import Button from '../../components/common/button/button.js';
import { StageWrapper } from '../modal.styled.js';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { OrganisationContext } from '../../context/organisationContext.js';
import { initModal } from '../../helpers/utils.js';

const buttonColors = [
  { color: '#8324FF', bgColor: '#F3EAFE' },
  { color: '#2496FF', bgColor: '#EFF6FF' },
  { color: '#404040', bgColor: '#FFF' },
];

const RedirectToStage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { modal, setModal } = useContext(OrganisationContext);
  const { jobDetails, propertyDetails } = modal?.content || {};
  const { name, contact, engagement_dates, workitems } = jobDetails || {};
  const { expected_completion, deadline } = engagement_dates || {};

  const getStageNameOfJob = workitems => {
    const workitem = workitems?.[0] || {};
    const workitemLength = workitems?.length || 0;
    const stageName = workitem?.stage?.name || null;
    return stageName ? `${workitem?.stage?.name}${workitemLength > 1 ? ` + ${workitemLength}` : ''}` : null;
  };

  const onStageButtonClick = workitem => {
    navigate(`/properties/property/${propertyDetails?.id}/workitems/${workitem?.id}`);
    setModal(initModal);
  };

  const renderStageButtons = workitems => {
    return workitems.map((workitem, index) => (
      <Button
        className="flex-1 py-3 px-5"
        label={workitem?.stage?.name}
        onClick={() => onStageButtonClick(workitem)}
        size="large"
        borderRadius="100px"
        padding="12px 20px"
        afterIcon={<RightArrow className="ml-2" />}
        color={buttonColors[index % 3]?.color}
        bgColor={buttonColors[index % 3]?.bgColor}
        borderColor="transparent"
      />
    ));
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <StageWrapper className="flex-column p-6 gap-8">
        <div className="flex-column gap-2 relative items-center">
          <p className="inter-600-text font-18">{'Which stage?'}</p>
          <p className="inter-400-text color-gray-secondary line-height-20 font-14 text-center">
            {'The job you’ve selected is in multiple stages. Please select which stage you’d like to navigate to.'}
          </p>
          <Closeicon className="absolute close-icon" />
        </div>
        <div className="w-full flex-column px-5 gap-3 py-4 air-con-info">
          <div className="flex justify-between items-center">
            <span className="inter-600-text natural-900-text font-16 line-height-150">{name}</span>
            {getStageNameOfJob(workitems) && (
              <label className="purple-label inter-500-text font-12">{getStageNameOfJob(workitems)}</label>
            )}
          </div>
          <div className="w-full flex-column gap-2">
            <div className="flex gap-1">
              <label className="inter-400-text natural-900-text line-height-20">{t('OWNER')}:</label>
              <label className="inter-400-text natural-500-text line-height-20">{contact?.name}</label>
            </div>
            <div className="flex gap-1">
              <label className="inter-400-text natural-900-text line-height-20">{t('DEADLINE')}:</label>
              <label className="inter-400-text natural-500-text line-height-20">
                {deadline ? moment(deadline * 1000).format('DD/MM/YY') : '-'}
              </label>
            </div>
            <div className="flex gap-1">
              <label className="inter-400-text natural-900-text line-height-20">{t('EXPECTED_COMPLETION')}:</label>
              <label className="inter-400-text natural-500-text line-height-20">
                {expected_completion ? moment(expected_completion * 1000).format('DD/MM/YY') : '-'}
              </label>
            </div>
          </div>
        </div>
        <div className="w-full flex-column gap-3">
          {renderStageButtons(workitems)}
          <Button
            className={`flex-1 py-3 px-5`}
            label={t('CANCEL')}
            onClick={() => setModal(initModal)}
            secondary={true}
            size="large"
            borderRadius="100px"
            padding="12px 20px"
            color="#404040"
            bgColor="#FFF"
            borderColor="#E5E5E5"
          />
        </div>
      </StageWrapper>
    </CSSTransition>
  );
};

export default RedirectToStage;
