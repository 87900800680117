import html2canvas from 'html2canvas';
import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { ReactComponent as RightIcon } from '../assets/icons/right-Icons.svg';
import { ReactComponent as CloseIcon } from '../assets/images/close-image.svg';
import Button from '../components/common/button/button';
import IconContainer from '../components/common/icon-container';
import InputElement from '../components/common/input';
import { OrganisationContext } from '../context/organisationContext';
import { initModal } from '../helpers/utils';
import { uploadFile } from '../store/features/fileuploadSlice';
import { addSolarDesignImages } from '../store/features/quotesSlice';
import { addToast } from '../store/features/toastSlice';

const TakePhoto = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { setModal, modal } = useContext(OrganisationContext);

  const { engagement_id, designId, selectedSnapshot, onSuccess = () => {} } = modal?.content || {};
  const { ratio, lexicon } = selectedSnapshot || { ratio: 1 };

  const [photo, setPhoto] = useState({ name: selectedSnapshot?.label, file: null });
  const [progress, setProgress] = useState(0);
  const [nameEditing, setNameEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();

  const getSnapshotOfElement = (element, posX, posY, width, height, convertedSnapshotCallback, convertIntoFile) => {
    const elementToCapture = document.querySelector(element);
    const elementRect = elementToCapture.getBoundingClientRect();
    const elementWidthToCapture = elementRect.width - elementRect.width * 0.1;
    const elementHeightToCapture = elementRect.height - elementRect.height * 0.3;
    html2canvas(document.querySelector(element), {
      useCORS: true,
      allowTaint: true,
      scale: 2,
      x: elementRect.width / 10,
      y: elementRect.height / 10,
      width: elementWidthToCapture,
      height: elementHeightToCapture,
      logging: false,
      ignoreElements: node => {
        return node.nodeName === 'IFRAME';
      },
    }).then(canvas => {
      const context = canvas.getContext('2d');
      const imageData = context.getImageData(posX, posY, canvas.width, canvas.height).data;
      const outputCanvas = document.createElement('canvas');
      const outputContext = outputCanvas.getContext('2d');
      outputCanvas.width = canvas.width;
      outputCanvas.height = canvas.height;

      const outputIData = outputContext.createImageData(canvas.width, canvas.height);
      outputIData.data.set(imageData);
      outputContext.putImageData(outputIData, 0, 0);
      convertedSnapshotCallback(
        //use it to get the raw base64 data without format mention
        //outputCanvas.toDataURL().replace("data:image/png;base64,", "")
        outputCanvas.toDataURL(),
      );
      outputCanvas.toBlob(blob => {
        const file = new File([blob], 'Screenshot.png', { type: 'image/png' });
        convertIntoFile(file);
      });
    });
  };

  useEffect(() => {
    getSnapshotOfElement(
      '#map-google',
      0,
      0,
      100,
      100,
      snapshot => {
        setPhoto({ ...photo, file: snapshot });
      },
      file => {
        setSelectedFile(file);
      },
    );
  }, []);

  const handleSubmit = (takeAnother = false) => {
    setLoading(true);
    dispatch(
      uploadFile({
        file: selectedFile,
        onSuccess: data => {
          let requestData = {
            lexicon: lexicon,
            media: {
              id: data?.media_id,
            },
          };
          dispatch(addSolarDesignImages({ engagementId: engagement_id, designId: designId, request: requestData }))
            .then(data => {
              setLoading(false);
              dispatch(addToast({ error: false, text: t('ADD_SOLAR_DESIGN_IMAGE'), id: nanoid() }));
              closeModal();
              if (!takeAnother) {
                onSuccess();
              }
            })
            .catch(errors => {
              setLoading(false);
              dispatch(
                addToast({
                  error: true,
                  text: errors?.response?.data?.error_description || t('ERROR_SOLAR_DESIGN_IMAGE'),
                  id: nanoid(),
                }),
              );
            })
            .finally(() => {
              setLoading(false);
            });
        },
        onError: () => {
          setLoading(false);
          dispatch(
            addToast({
              error: true,
              text: t('ERROR_WHILE_UPLOADING_FILE', { name: selectedFile?.name }),
              id: nanoid(),
            }),
          );
        },
        setProgress,
      }),
    );
  };

  const closeModal = () => {
    setModal(initModal);
  };

  const onCancelNameEditing = () => {
    setNameEditing(false);
    setPhoto({ ...photo, name: selectedSnapshot?.label });
  };

  return (
    <CSSTransition appear className="popup-fade pxy-6 flex-column row-gap-5" in timeout={300}>
      <TakePhotoWrapper>
        <div className="flex items-center col-gap-4 justify-between">
          {nameEditing && (
            <div className="flex items-center relative">
              <InputElement
                variant="size_32"
                value={photo?.name}
                onChange={value => setPhoto({ ...photo, name: value })}
              />
              <div className="flex items-center justify-end col-gap-2 action-wrapper">
                <IconContainer
                  variant="size_32"
                  Icon={CloseIcon}
                  iconHeight={16}
                  iconWidth={16}
                  iconContainerClassname="border bg-white radius-full cursor"
                  onClick={onCancelNameEditing}
                />
                <IconContainer
                  Icon={RightIcon}
                  iconHeight={16}
                  iconWidth={16}
                  iconColor="white"
                  iconContainerClassname="bg-primary-500 radius-full cursor"
                  onClick={() => setNameEditing(false)}
                />
              </div>
            </div>
          )}
          {!nameEditing && (
            <div
              className="flex items-center name-container pxy-1 radius-2 cursor"
              onClick={() => setNameEditing(true)}>
              <label className="inter-400-text font-16">{photo?.name}</label>
            </div>
          )}
          <Button
            size="medium"
            label="Re-take"
            className="secondary specified-width"
            width="100px"
            onClick={closeModal}
            disabled={!selectedFile?.size}
          />
        </div>
        <div className="overflow-scroll">
          {!selectedFile?.size ? (
            <Skeleton height={'250px'} />
          ) : (
            <img
              src={photo?.file || ''}
              width={'100%'}
              height={'auto'}
              style={{
                borderRadius: '8px',
                aspectRatio: ratio,
                objectFit: 'cover',
              }}
            />
          )}
        </div>

        <div className="flex justify-between">
          <Button label="Cancel" size="medium" onClick={closeModal} className="default specified-width" width="100px" />
          <div className="flex col-gap-2">
            <Button
              label="Confirm"
              size="medium"
              className="primary specified-width"
              width="120px"
              onClick={() => handleSubmit(false)}
              loading={loading}
              disabled={loading || !selectedFile?.size}
            />
            <Button
              loading={loading}
              disabled={loading || !selectedFile?.size}
              label="Confirm & Take another photo"
              size="medium"
              className="primary px-2 py-1"
              onClick={() => handleSubmit(true)}
            />
          </div>
        </div>
      </TakePhotoWrapper>
    </CSSTransition>
  );
};

const TakePhotoWrapper = styled.div`
  width: 648px;
  max-height: 90dvh;

  .action-wrapper {
    position: absolute;
    right: 0;
    bottom: -36px;
    z-index: 1000;
    padding: 4px 12px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 4px 6px -4px rgba(16, 24, 40, 0.1), 0px 10px 15px -3px rgba(16, 24, 40, 0.1);
  }

  .name-container {
    :hover {
      background-color: ${({ theme }) => theme.natural_200};
    }
  }
`;

export default TakePhoto;
