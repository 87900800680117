import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as LocationIcon } from '../../assets/icons/map-pin.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import { ReactComponent as ActivityIcon } from '../../assets/images/arrow-right.svg';
import { ReactComponent as MailIcon } from '../../assets/images/mail.svg';
import { ReactComponent as PhoneIcon } from '../../assets/images/mobile.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { PersonsListItemWrapper } from '../../styles/components/persons-list/persons-list.styled';
import MenuV2 from '../common/menu/menu-v2';

const PersonsListItem = ({ person }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { setModal } = useContext(OrganisationContext);

  const onAutomation = automation => {
    // navigate(`/platforms/integrations/${integration.id}`);
  };

  const onDeleteAutomation = () => {
    // setModal({
    //   type: 'integration-action',
    //   content: {
    //     integration: automation,
    //     top: true,
    //     isDelete: true,
    //   },
    // });
  };

  const onChangeStatus = async () => {
    // const { is_enabled } = automation;
    // if (is_enabled) {
    //   setModal({ type: 'integration-action', content: { integration: automation, top: true, isDisable: true } });
    // } else {
    //   const request = {
    //     enabled: !is_enabled,
    //   };
    //   dispatch(updatedIntegrationStateInList(automation));
    //   dispatch(updateIntegrationState({ id: automation?.id, request, updateList: false })).catch(error => {
    //     const errorData = error?.response?.data;
    //     dispatch(updatedIntegrationStateInList(automation));
    //     dispatch(
    //       addToast({
    //         error: true,
    //         text:
    //           errorData?.error_code === 'BAD_REQUEST'
    //             ? t('REQUIRED_INTIGRATION')
    //             : t('ERROR_UPDATE_STATUS'),
    //         id: uuid(),
    //       }),
    //     );
    //   });
    // }
  };

  const getContactValue = (data, field) => {
    const contact = data?.find(d => d.is_primary) || data?.[0] || {};
    return contact ? contact.value : null;
  };

  const getLocationValue = data => {
    const location = data?.find(d => d.is_primary) || data?.[0] || {};
    const { line1, line2, line3, postcode, state, city, country_code } = location;
    const address = [line1, line2, line3, city, state, postcode, country_code].filter(Boolean).join(', ');
    return address;
  };

  const getPreferredTimeValue = preferred_contact_time => {
    const contactTime = preferred_contact_time?.[0] || {};
    return contactTime?.name || 'Morning';
  };

  return (
    <PersonsListItemWrapper className="w-full cursor" onClick={() => onAutomation(person)}>
      <div className="border-right flex items-center px-2 overflow-hidden">
        <div className="flex items-center justify-center mr-2 icon-container">
          <UserIcon />
        </div>
        <label className="regular-text one-line">{person?.name}</label>
      </div>
      <div className="border-right flex items-center px-6 overflow-hidden">
        <div className="flex items-center justify-center icon-container">
          <LocationIcon />
        </div>
        <label className="regular-text pl-2 two-lines">{getLocationValue(person?.addresses)}</label>
      </div>
      <div className="border-right flex items-center px-6">
        <div className="flex items-center justify-center mr-2 icon-container">
          <PhoneIcon />
        </div>
        <label className="regular-text one-line">{getContactValue(person?.phones, 'number')}</label>
      </div>
      <div className="border-right flex items-center px-6 overflow-hidden">
        <div className="flex items-center justify-center mr-2 icon-container">
          <MailIcon />
        </div>
        <label className="regular-text one-line">{getContactValue(person?.emails, 'email')}</label>
      </div>

      <div className="flex items-center px-6">
        <label className="regular-text grey-text mr-1">{t('PREFERED_TIME')}:</label>
        <label className="regular-text">{getPreferredTimeValue(person.preferred_contact_time)}</label>
      </div>
      <div className="flex items-center justify-between">
        <ActivityIcon width={16} height={16} />
        <MenuV2
          menuList={[
            { name: t('EDIT'), onClick: () => {} },
            { name: t('DELETE'), onClick: () => {} },
          ]}
        />
      </div>
    </PersonsListItemWrapper>
  );
};

export default PersonsListItem;
