import classNames from 'classnames';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/property/calendar.svg';
import { ReactComponent as RightArrowIcon } from '../../../assets/icons/right-arrow.svg';
import IconContainer from '../icon-container';
import CustomTooltip from '../tooltip-new';
import DateSelector from './date-selector';

const DateSelectorElement = ({
  selectedDate,
  setSelectedDate,
  placeholder,
  format = 'DD/MM/YYYY',
  className = '',
  minStartDate = null,
  maxEndDate = null,
  showCurrentMonth = false,
  name = '',
  is_required = false,
  error = false,
  isDisabled = false,
  isCalendarIcon = true,
  isCalendarIconRight = false,
  isOpen = false,
  arrows = false,
  sub_name = '',
  useParentFocus = false,
  wrapperClassName = '',
}) => {
  const uniqId = useRef(nanoid());
  const [showCalendar, setShowCalendar] = useState(false);

  useEffect(() => {
    useParentFocus && setShowCalendar(isOpen);
  }, [isOpen, useParentFocus]);

  const changeSelectedDate = date => {
    setSelectedDate(date);
    setShowCalendar(false);
  };

  const onBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setShowCalendar(false);
    }
  };

  const onPreviousDayClick = e => {
    e.stopPropagation();
    if (isDisabled) return;
    const newDate = moment.unix(selectedDate).subtract(1, 'days');
    if (!minStartDate || (minStartDate && newDate.isAfter(moment.unix(minStartDate).startOf('day')))) {
      changeSelectedDate(newDate.unix());
    }
  };

  const onNextDayClick = e => {
    e.stopPropagation();
    if (isDisabled) return;
    const newDate = moment.unix(selectedDate).add(1, 'days');
    if (!maxEndDate || (maxEndDate && newDate.isBefore(moment.unix(maxEndDate).startOf('day')))) {
      changeSelectedDate(newDate.unix());
    }
  };

  return (
    <DateSelectorElementWrapper className={classNames('flex flex-1', wrapperClassName)} tabIndex={1} onBlur={onBlur}>
      <CustomTooltip
        wrapperClassName="flex flex-1 date-selector-tooltip"
        id={`date-selector-${uniqId.current}`}
        clickable
        place="bottom"
        noArrow
        content={
          showCalendar &&
          !isDisabled && (
            <DateSelector
              className="date-selector"
              setEndDate={() => {}}
              setStartDate={changeSelectedDate}
              startDate={moment.unix(selectedDate || moment().unix())}
              minStartDate={minStartDate}
              maxEndDate={maxEndDate}
              showCurrentMonth={showCurrentMonth}
            />
          )
        }
        isOpen={showCalendar}>
        <div className="flex-column flex-1">
          {name && (
            <div className="flex-1 mb-1">
              <span className="inter-500-text natural-900-text">{name}</span>
              {sub_name && <span className="inter-400-text natural-400-text ml-1">{sub_name}</span>}
              {is_required && <span className="required-star ml-1">*</span>}
            </div>
          )}
          <div
            className={classNames(
              'border flex flex-1 items-center px-4 cursor py-1 radius-2 date-value',
              showCalendar && 'show-calendar',
              error && !selectedDate && 'error-border',
              className,
            )}
            onClick={e => {
              if (useParentFocus) return;
              !isDisabled && setShowCalendar(prev => !prev);
            }}
            tabIndex={0}>
            {isCalendarIcon && (
              <IconContainer
                iconHeight={18}
                iconWidth={18}
                Icon={CalendarIcon}
                backgroundColor={'white'}
                iconContainerClassname="mr-2"
              />
            )}
            {arrows && (
              <IconContainer
                iconHeight={20}
                iconWidth={20}
                Icon={RightArrowIcon}
                iconClassName="rotate-180"
                iconColor={'natural_900'}
                backgroundColor={'white'}
                iconContainerClassname="mr-2 p-0"
                onClick={onPreviousDayClick}
              />
            )}

            {selectedDate ? (
              <label className="inter-400-text natural-900-text font-14">
                {moment.unix(selectedDate).format(format)}
              </label>
            ) : (
              <label className="inter-400-text natural-400-text font-14 one-line">{placeholder}</label>
            )}
            {isCalendarIconRight && (
              <IconContainer
                iconHeight={18}
                iconWidth={18}
                Icon={CalendarIcon}
                backgroundColor={'white'}
                iconContainerClassname=""
              />
            )}
            {arrows && (
              <IconContainer
                iconHeight={20}
                iconWidth={20}
                Icon={RightArrowIcon}
                iconColor={'natural_900'}
                backgroundColor={'white'}
                iconContainerClassname="ml-2 p-0"
                onClick={onNextDayClick}
              />
            )}
          </div>
        </div>
      </CustomTooltip>
    </DateSelectorElementWrapper>
  );
};

const DateSelectorElementWrapper = styled.div`
  .date-value {
    min-height: 44px;
  }
  .show-calendar {
    border: 1px solid ${({ theme }) => theme.focus_border};
    box-shadow: 0px 0px 0px 4px ${({ theme }) => theme.focus_border};
  }
`;

export default DateSelectorElement;
