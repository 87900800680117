import classNames from 'classnames';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { components } from 'react-select';
import styled from 'styled-components';
import { useWindowSize } from '../../hooks/useWindowSize';
import Dropdown from '../common/select-dropdown';

const WorkitemProgress = props => {
  const { workitemProgress, openMoveToStagePopup, allowedProgressableStageIds, currentStageIndex } = props;
  const stepRef = useRef();

  const { width } = useWindowSize();
  const [stepOverflow, setStepOverflow] = useState(false);
  const [stepRightIndex, setStepRightIndex] = useState(0);
  const [stepLeftIndex, setStepLeftIndex] = useState(0);
  const [stepsWidth, setStepsWidth] = useState([]);
  const [selectedSteps, setSelectedSteps] = useState([]);

  const leftSelectOptions = workitemProgress.slice(0, stepLeftIndex);
  const rightSelectOptions = workitemProgress.slice(stepRightIndex);

  useLayoutEffect(() => {
    const everyStepWidth = [];
    const section = stepRef?.current;
    const scrollWidth = section.scrollWidth;
    const offsetWidth = section.offsetWidth;

    if (section) {
      const isOverflow = offsetWidth < scrollWidth;
      if (isOverflow) {
        const elements = section.childNodes;
        elements.forEach((singleElement, index) => {
          everyStepWidth.push({ width: singleElement.getBoundingClientRect().width, index });
        });
        setStepOverflow(true);
      }
      setStepsWidth(everyStepWidth);
    }
  }, [workitemProgress]);

  useEffect(() => {
    const possibleSteps = [];
    let usedWidth = 160;
    let next = currentStageIndex + 1;
    let prev = currentStageIndex - 1;
    const section = stepRef?.current;
    const offsetWidth = section.offsetWidth;
    if (stepsWidth.length > 0) {
      if (currentStageIndex !== workitemProgress.length - 1) {
        possibleSteps.push(workitemProgress[currentStageIndex]?.stage);
        usedWidth += stepsWidth[currentStageIndex]?.width;
        while (prev >= 0 || next <= workitemProgress.length) {
          if (stepsWidth[prev]?.width) {
            if (usedWidth + stepsWidth[prev]?.width < offsetWidth) {
              usedWidth += stepsWidth[prev]?.width;
              possibleSteps.unshift(workitemProgress[prev]?.stage);
            } else break;
          }

          if (stepsWidth[next]?.width) {
            if (usedWidth + stepsWidth[next]?.width < offsetWidth) {
              possibleSteps.push(workitemProgress[next]?.stage);
              usedWidth += stepsWidth[next]?.width;
            } else break;
          }
          prev--;
          next++;
        }
      } else {
        workitemProgress.forEach((singleStep, index) => {
          if (stepsWidth[index]?.width) {
            if (usedWidth + stepsWidth[index]?.width < offsetWidth) {
              usedWidth += stepsWidth[index]?.width;
              possibleSteps.push(singleStep?.stage);
            }
          }
        });
      }
      setSelectedSteps(possibleSteps);

      const leftIndex = workitemProgress.findIndex(s => s?.stage?.id === possibleSteps[0]?.id);
      const rightIndex = workitemProgress.findIndex(s => s?.stage?.id === possibleSteps[possibleSteps?.length - 1]?.id);
      setStepLeftIndex(leftIndex);
      setStepRightIndex(rightIndex + 1);
    }
  }, [stepsWidth, width, workitemProgress]);

  const StepExpand = ({ className, show, position, options = [] }) => {
    if (!show || options.length === 0) return null;
    options = options.map(option => {
      return {
        ...option,
        value: option?.stage?.id,
        label: option?.stage?.name,
        position,
      };
    });
    if (position === 'left') {
      options = options.reverse();
    }

    return (
      <div className={classNames('flex items-center step step-expand', className && className)}>
        <Dropdown
          className={'w-full provider-selector flex absolute inset-0'}
          options={options}
          onChange={option => openMoveToStagePopup(option?.stage)}
          customStyle={{
            control: {
              background: 'transparent',
              minHeight: 'auto',
              position: 'absolute',
              inset: 0,
              border: 'none',
              '&:hover': {
                boxShadow: 'none',
              },
            },
            container: {
              width: '100%',
            },
            indicatorsContainer: {
              display: 'none',
            },
            menu: {
              width: 'max-content',
            },
            option: {
              padding: 0,
            },
          }}
          customComponent={{ Control: Control, Option: Option }}
        />
      </div>
    );
  };

  const Control = props => {
    return (
      <components.Control {...props}>
        <div className="absolute control-label">
          <p className="inter-500-text font-12">...</p>
        </div>
        <div className="w-full h-full">{props.children}</div>
      </components.Control>
    );
  };

  const Option = props => {
    const { innerProps, data } = props;
    return (
      <components.Option {...props}>
        <div
          className={classNames(
            'flex items-center w-full px-4 py-3 cursor border-bottom option-wrapper',
            allowedProgressableStageIds.includes(data.value) ? 'hover-green' : 'hover-red',
          )}
          {...innerProps}>
          <span className={classNames('hover-text flex-1 inter-400-text natural-900-text option-text one-line')}>
            {data.label}
          </span>
        </div>
      </components.Option>
    );
  };

  return (
    <WorkItemProgressWrapper>
      <div className="arrow-steps flex cursor mt-6" ref={stepRef}>
        <StepExpand
          className="step-left"
          show={stepOverflow && stepLeftIndex}
          options={leftSelectOptions}
          position={'left'}
        />

        {workitemProgress?.map(({ stage }, index) => {
          const showStep = selectedSteps?.length > 0 ? selectedSteps.find(s => s?.id === stage?.id) : true;

          return (
            <div
              className={classNames(
                'flex items-center step single-step w-full',
                allowedProgressableStageIds.includes(stage?.id) ? 'hover-green' : 'hover-red',
                index <= currentStageIndex && 'current',
                !showStep && 'hidden',
              )}
              key={stage?.id}
              onClick={() => openMoveToStagePopup(stage)}>
              <label
                className={classNames(
                  'inter-500-text font-12 flex flex-1 one-line hover-text',
                  index <= currentStageIndex && 'white-text',
                )}>
                {stage?.name}
              </label>
            </div>
          );
        })}

        <StepExpand
          className="step-right"
          show={stepOverflow && stepRightIndex != workitemProgress.length}
          options={rightSelectOptions}
          position="right"
        />
      </div>
    </WorkItemProgressWrapper>
  );
};

export default WorkitemProgress;

const WorkItemProgressWrapper = styled.div`
  .step-left .select__menu {
    left: 0;
  }
  .select__menu .hover-green:hover,
  .select__option--is-focused:has(.hover-green) {
    background-color: ${({ theme }) => theme.success_500};
    .hover-text {
      color: #fff;
    }
  }
  .select__menu .hover-red:hover,
  .select__option--is-focused:has(.hover-red) {
    background-color: ${({ theme }) => theme.error_500};
    .hover-text {
      color: #fff;
    }
  }
  .step-right .select__menu {
    right: 0;
  }
  .control-label {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .single-step {
    min-width: 170px;
  }
`;
