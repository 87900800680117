import { Polygon } from '@react-google-maps/api';
import React, { useCallback, useRef } from 'react';

const PolygonWrapper = ({
  polygonId,
  editable,
  draggable,
  paths,
  options,
  setPaths = () => {},
  onPolygonClick = () => {},
  onLoadPolygon = () => {},
  onUnmountPolygon = () => {},
  onPolygonMouseUp = () => {},
  onPolygonMouseDown = () => {},
  onPolygonMouseOver = () => {},
}) => {
  const polygonRef = useRef(null);

  const onEdit = () => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map(latLng => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      setPaths(nextPath, polygonId);
    }
  };

  const onLoad = useCallback(polygon => {
    if (polygonRef.current) {
      polygonRef.current.setMap(null);
      polygonRef.current = null;
    }
    polygonRef.current = polygon;
    onLoadPolygon(polygon);
  }, []);

  const onUnmount = useCallback(() => {
    polygonRef.current.setMap(null);
    polygonRef.current = null;
    onUnmountPolygon();
  }, []);

  const onClick = () => {
    if (polygonRef.current) {
      onPolygonClick(polygonRef.current, polygonId);
    }
  };

  const onMouseUp = () => {
    if (polygonRef.current) {
      onPolygonMouseUp(polygonRef.current, polygonId);
    }
  };

  const onMouseDown = () => {
    if (polygonRef.current) {
      onPolygonMouseDown(polygonRef.current, polygonId);
    }
  };

  const onMouseOver = () => {
    if (polygonRef.current) {
      onPolygonMouseOver(polygonRef.current, polygonId);
    }
  };

  return (
    <Polygon
      key={polygonId}
      editable={editable}
      draggable={draggable}
      paths={paths}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={options}
      onClick={onClick}
      onDragEnd={() => (editable || draggable ? onEdit() : null)}
      onMouseUp={() => (editable || draggable ? onEdit() : onMouseUp())}
      onMouseDown={onMouseDown}
      onMouseOver={onMouseOver}
    />
  );
};

export default PolygonWrapper;
