import styled from 'styled-components';

export const AutomationDetailContainerWrapper = styled.div`
  max-height: 100vh;
  overflow: hidden;

  .left-box-wrapper {
    height: fit-content;
    min-width: 350px;
    max-width: 350px;
    border-radius: 16px;
    max-height: 100%;
    overflow: hidden;
  }
`;

export const AutomationDetailsRightWrapper = styled.div`
  border-radius: 16px;
  max-height: 100%;

  .vertical-line {
    height: 24px;
    border-color: red;
  }
  .automation-log-action-wrapper {
    .header-border-left {
      border-left: 4px solid ${({ theme }) => theme.additionalGreen};
    }

    .action-container {
      width: calc(100% - 24px);
    }

    .icon-wrapper {
      background: ${({ theme }) => theme.navBackground};
      border-radius: 6px;
      padding: 3px;
    }

    .retry-text {
      color: ${({ theme }) => theme.selectedText};
    }

    .sub-header-border {
      border: 1px solid ${({ theme }) => theme.borderColor};
      width: calc(100% - 24px);
    }

    .schedule-conditon-wrapper {
      background: ${({ theme }) => theme.lightGreyBackground};
    }

    .dot {
      height: 6px;
      width: 6px;
      border-radius: 50%;
      background: ${({ theme }) => theme.primaryGreen};
    }
  }
`;
