import styled from 'styled-components';
const QuoteRecipientWrapper = styled.div`
  border-radius: 6px;
  background-color: ${({ theme }) => theme.natural_50};
  .searchable-select__control--is-focused {
    box-shadow: 0px 0px 0px 4px ${({ theme }) => theme.focus_border};
  }

  .edit-recipient {
    border: 1px dashed transparent;
    padding: 2px 0;
    border-radius: 6px;
    cursor: pointer;
    &:hover {
      background-color: ${({ theme }) => theme.natural_150};
      border: 1px dashed ${({ theme }) => theme.natural_300};
      .o0 {
        opacity: 1;
      }
    }

    .recipient-option-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
  .cross-icon {
    width: 32px;
    height: 32px;
  }

  .right-icon {
    background-color: ${({ theme }) => theme.primary_500};
    width: 32px;
    height: 32px;
  }

  .recipient-update-action {
    z-index: 10;
    background-color: ${({ theme }) => theme.white};
    z-index: 999;
    box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1);
  }
`;

export default QuoteRecipientWrapper;
