import Axios from 'axios';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as Closeicon } from '../../assets/icons/property/closeicon.svg';
import { ReactComponent as Mail } from '../../assets/icons/property/mail.svg';
import { ReactComponent as Mobile } from '../../assets/icons/property/mobile.svg';
import { ReactComponent as Sppiner } from '../../assets/icons/property/sppiner.svg';
import { ReactComponent as ArrowLeft } from '../../assets/images/arrow-left.svg';
import { ReactComponent as ErrorIcon } from '../../assets/images/error.svg';
import HidePassword from '../../assets/images/hide-password.svg';
import ShowPassword from '../../assets/images/show-password.svg';
import AuthContainer from '../../components/common/auth-container';
import Button from '../../components/common/button/button';
import OtpInput from '../../components/common/otp-input/otp-input';
import PhoneInput from '../../components/common/phone-input';
import CustomTooltip from '../../components/common/tooltip-new';
import { countMatchesInRegex, emailRegEx } from '../../helpers/utils';
import { addToast } from '../../store/features/toastSlice';
import { getPasswordRequirements } from '../../store/features/userSlice';

const upperCaseRegex = /[A-Z]/g;
const lowerCaseRegex = /[a-z]/g;
const numberCaseRegex = /[0-9]/g;
const specialCharacterRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [user, setUser] = useState({ employee_id: '', phone: '', country: '+44', password: '', confirmPassword: '' });

  const [email, setEmail] = useState();
  const [phoneNumber, setIsPhoneNumber] = useState('');
  const [active, setActive] = useState();
  const [passwordActive, setPasswordActive] = useState();
  const [currentStep, setCurrentStep] = useState('VERIFICATION_METHOD');
  const [isOtpValid, setIsOtpValid] = useState(true);
  const [otpValue, setOtpValue] = useState('');
  const [password, setPassword] = useState();
  const [confimPassword, setConfirmPassword] = useState();
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [authenticationToken, setAuthenticationToken] = useState('');
  const [emailActive, setEmailActive] = useState(false);
  const [mobileActive, setMobileActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isResentLoading, setIsResentLoading] = useState(false);
  const [otpError, setOtpError] = useState('');
  const [resentOTP, setResentOTP] = useState('');
  const [resentSuccessMsg, setResentSuccessMsg] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [pswError, setPswError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [isNetworkError, setIsNetworkError] = useState(false);

  const [passwordRequirementError, setPasswordRequirementError] = useState({
    minumumPasswordLengthError: false,
    upperCaseError: false,
    lowerCaseError: false,
    numberError: false,
    specialCharacterError: false,
  });
  const [passwordRequiremets, setPasswordRequirements] = useState({
    min_length: 16,
    min_digits: 1,
    min_uppercase: 1,
    min_special: 1,
    min_lowercase: 1,
  });

  const fetchPasswordRequirements = async authenticationToken => {
    dispatch(getPasswordRequirements(authenticationToken))
      .then(data => setPasswordRequirements(data))
      .catch(() => setPasswordRequirements({}));
  };

  useEffect(() => {
    if (authenticationToken) {
      fetchPasswordRequirements(authenticationToken);
    }
  }, [authenticationToken]);

  const checkPasswordRequirements = () => {
    const { min_length, min_uppercase, min_lowercase, min_digits, min_special } = passwordRequiremets;
    const minumumPasswordLengthError = password ? password?.length < min_length : true;
    const upperCaseError = min_uppercase > 0 ? countMatchesInRegex(upperCaseRegex, password) < min_uppercase : false;
    const lowerCaseError = min_lowercase > 0 ? countMatchesInRegex(lowerCaseRegex, password) < min_lowercase : false;
    const numberError = min_digits > 0 ? countMatchesInRegex(numberCaseRegex, password) < min_digits : false;
    const specialCharacterError =
      min_special > 0 ? countMatchesInRegex(specialCharacterRegex, password) < min_special : false;
    setPasswordRequirementError({
      minumumPasswordLengthError,
      upperCaseError,
      lowerCaseError,
      numberError,
      specialCharacterError,
    });
    return minumumPasswordLengthError || upperCaseError || lowerCaseError || numberError || specialCharacterError;
  };

  const isEmailValid = email => {
    return emailRegEx.test(email);
  };

  useEffect(() => {
    setActive(email && email.length > 2);
  }, [email]);

  useEffect(() => {
    setPasswordActive(password && confimPassword && password.length > 2 && confimPassword.length > 2);
    setPasswordError(false);
  }, [password, confimPassword]);

  const resetPassword = async () => {
    if (active) {
      if (!isEmailValid(email)) {
        setEmailError(true);
        return;
      }
      await Axios.post(`${process.env.REACT_APP_API_URL}/password/otp`, {
        destination: email,
        destination_type: 'EMAIL',
      })
        .then(data => {
          setCurrentStep('OTP');
          setIsNetworkError(false);
        })
        .catch(error => {
          const { code } = error || {};
          if (code === 'ECONNABORTED' || code === 'ERR_NETWORK') {
            setIsNetworkError(true);
          }
        });
    }
  };

  const sendSms = async () => {
    const { country, phone } = user;
    const cleanedPhone = phone.replace(/[, ]/g, '');
    const phoneNumber = country + cleanedPhone;
    setIsPhoneNumber(phoneNumber);
    if (active) {
      if (!user?.phone) {
        setPhoneError(true);
        setErrorText(t('PLEASE_ENTER_PHONE_NUMBER'));
        return;
      }
      if (user?.phone && user?.phone.length < 12) {
        setPhoneError(true);
        setErrorText(t('PHONE_NUMBER_IS_NOT_VALID'));
        return;
      }
      await Axios.post(`${process.env.REACT_APP_API_URL}/password/otp`, {
        destination: phoneNumber,
        destination_type: 'SMS',
      })
        .then(data => {
          setCurrentStep('OTP');
          setIsNetworkError(false);
        })
        .catch(error => {
          const { code } = error || {};
          if (code === 'ECONNABORTED' || code === 'ERR_NETWORK') {
            setIsNetworkError(true);
          }
        });
    }
  };

  const gotoVerification = async () => {
    if (active) {
      if (emailActive) {
        setCurrentStep('EMAIL');
        setIsNetworkError(false);
      } else {
        setCurrentStep('MOBILE');
        setIsNetworkError(false);
      }
    }
  };

  const sendOtp = async () => {
    if (otpValue && otpValue.length === 6) {
      let request = {};

      if (emailActive) {
        request = {
          code: otpValue,
          destination: email,
          destination_type: 'EMAIL',
        };
      } else {
        request = {
          code: otpValue,
          destination: phoneNumber,
          destination_type: 'SMS',
        };
      }
      setLoading(true);
      Axios.post(`${process.env.REACT_APP_API_URL}/password/otp/validate`, request)
        .then(({ data }) => {
          setAuthenticationToken(data.access_token);
          setCurrentStep('PASSWORD');
          setIsNetworkError(false);
        })
        .catch(error => {
          const { code } = error || {};
          if (code === 'ECONNABORTED' || code === 'ERR_NETWORK') {
            setIsNetworkError(true);
          } else {
            const errorData = error?.response?.data;
            setOtpError(errorData?.error_description);
            setIsOtpValid(false);
            setIsNetworkError(false);
          }
          setLoading(false);
        });
    }
  };

  const resendOtp = async () => {
    setOtpError('');
    setResentOTP('');
    setIsOtpValid(true);
    setOtpValue('');
    setIsResentLoading(true);
    const destination = mobileActive ? phoneNumber : email;
    const destination_type = mobileActive ? 'SMS' : 'EMAIL';
    await Axios.post(`${process.env.REACT_APP_API_URL}/password/otp`, {
      destination: destination,
      destination_type: destination_type,
    })
      .then(({ data }) => {
        setResentSuccessMsg(t('SUCCESSFULLY_OTP_SEND'));
        setIsResentLoading(false);
        setIsNetworkError(false);
      })
      .catch(error => {
        const { code } = error || {};
        if (code === 'ECONNABORTED' || code === 'ERR_NETWORK') {
          setIsNetworkError(true);
        } else {
          const errorData = error?.response?.data;
          setResentOTP(t('MANY_TIME_ATTEMPTED'));
          setIsNetworkError(false);
        }
        setIsResentLoading(false);
      });
  };

  useEffect(() => {
    if (resentSuccessMsg) {
      setTimeout(() => {
        setResentSuccessMsg('');
      }, 4000);
    }
  }, [resentSuccessMsg]);

  const activate = async () => {
    if (checkPasswordRequirements()) {
      setPswError(false);
      setPasswordError(t('PASSWORD_DO_NOT_MATCH_REQUIREMENT'));
      return;
    }
    if (password !== confimPassword) {
      setPswError(false);
      setPasswordError(t('PASSWORD_DO_NOT_MATCH'));
      return;
    }
    try {
      await Axios.put(
        `${process.env.REACT_APP_API_URL}/users/password`,
        {
          password: password,
        },
        { headers: { authorization: `Bearer ${authenticationToken}` } },
      );
      dispatch(addToast({ error: false, text: t('PASSWORD_RESET_SUCCESSFULLY') }));
      navigate('/login', { replace: true });
    } catch (error) {
      setPswError(true);
    }
  };

  useEffect(() => {
    setIsOtpValid(true);
  }, [otpValue]);

  const onBack = () => {
    switch (currentStep) {
      case 'VERIFICATION_METHOD':
        navigate('/login', { replace: true });
        break;
      case 'EMAIL':
        setMobileActive(false);
        setEmailActive(false);
        setIsPhoneNumber('');
        setCurrentStep('VERIFICATION_METHOD');
        setIsNetworkError(false);
        setActive(false);
        break;
      case 'MOBILE':
        setEmail('');
        setIsPhoneNumber('');
        setMobileActive(false);
        setEmailActive(false);
        setActive(false);
        setCurrentStep('VERIFICATION_METHOD');
        setIsNetworkError(false);
        break;
      case 'OTP':
        setCurrentStep(mobileActive ? 'MOBILE' : 'EMAIL');
        setOtpError('');
        setOtpValue('');
        setIsNetworkError(false);
        break;
      case 'PASSWORD':
        setIsNetworkError(false);
        navigate('/login', { replace: true });
        break;
      case 'PASSWORD_RESET':
        setCurrentStep('OTP');
        setIsNetworkError(false);
        setPassword('');
        setConfirmPassword('');
        setShowPassword(false);
        break;
      default:
        break;
    }
  };

  const onTryAnotherMethod = () => {
    setMobileActive(false);
    setEmailActive(false);
    setCurrentStep('VERIFICATION_METHOD');
    setIsNetworkError(false);
    setActive(false);
    setPassword('');
    setConfirmPassword('');
    setOtpValue('');
    setOtpError('');
  };

  const passwordRequirementTooltip = () => {
    const { min_length, min_uppercase, min_lowercase, min_digits, min_special } = passwordRequiremets;
    return (
      <div className="flex">
        <ErrorIcon height={16} width={16} className="error-text" />
        <div className="flex-column ml-2">
          <span className="inter-400-text natural-900-text font-12">{t('PASSWORD_MUST')}:</span>

          {passwordRequirementError.minumumPasswordLengthError && (
            <span className="inter-400-text natural-400-text font-12">
              {t('HAVE')} {min_length} {t('CHARACTERS')}
            </span>
          )}
          {passwordRequirementError.upperCaseError && (
            <span className="inter-400-text natural-400-text font-12">
              {t('HAVE')} {min_uppercase} {t('UPPER_CASE')}
            </span>
          )}
          {passwordRequirementError.lowerCaseError && (
            <span className="inter-400-text natural-400-text font-12">
              {t('HAVE')} {min_lowercase} {t('LOWER_CASE')}
            </span>
          )}
          {passwordRequirementError.numberError && (
            <span className="inter-400-text natural-400-text font-12">
              {t('HAVE')} {min_digits} {t('NUMBER')}
            </span>
          )}
          {passwordRequirementError.specialCharacterError && (
            <span className="inter-400-text natural-400-text font-12">
              {t('HAVE')} {min_special} {t('SPECIAL_CHARACTER')}
            </span>
          )}
        </div>
      </div>
    );
  };

  return (
    <AuthContainer>
      <ForgotPasswordWrapper className="flex-column h-full w-full">
        <div className="back-arrow cursor flex items-center w-fit-content" onClick={onBack}>
          <ArrowLeft height={16} width={16} className="natural-500-text" />
          <label className="inter-400-text natural-500-text font-16 ml-1">{t('BACK')}</label>
        </div>
        <div className="forgot-password-page m-auto">
          <div className="flex-column items-center gap-10 fp-content">
            {currentStep === 'VERIFICATION_METHOD' ? (
              <>
                {/* heading */}
                <div className="flex flex-column items-center gap-2">
                  <label className="inter-700-text natural-900-text font-28">{t('SELECT_VERIFICATION_METHOD')}</label>
                  <label className="inter-400-text natural-500-text text-center">
                    {t('RECIEVING_VERIFICATION_CODE')}
                  </label>
                </div>
                {/* end */}
                <div className="w-full flex gap-5 form flex-column items-center">
                  <div
                    onClick={() => {
                      setEmailActive(true);
                      setMobileActive(false);
                      setActive(true);
                    }}
                    className={classNames(
                      'flex w-full radius-full items-center gap-2 px-5 py-3 cursor',
                      emailActive ? 'box-active' : 'border-1',
                    )}>
                    <Mail className="w-6 h-6" />
                    <label className="inter-500-text natural-700-text font-16">{t('EMAIL')}</label>
                  </div>
                  <div
                    onClick={() => {
                      setMobileActive(true);
                      setEmailActive(false);
                      setActive(true);
                    }}
                    className={classNames(
                      'flex w-full radius-full items-center gap-2 px-5 py-3 cursor',
                      mobileActive ? 'box-active' : 'border-1',
                    )}>
                    <Mobile className="w-6 h-6" />
                    <label className="inter-500-text natural-700-text font-16">{t('SMS')}</label>
                  </div>
                </div>
                {/* foot bottom btn */}
                <div className="w-full flex flex-column gap-5 items-center">
                  <Button
                    size="large"
                    label={t('CONTINUE')}
                    className={classNames('primary', !active && 'disabled')}
                    onClick={gotoVerification}
                  />
                </div>
              </>
            ) : currentStep === 'EMAIL' ? (
              <>
                {/* Heading */}
                <div className="flex flex-column items-center gap-2">
                  <label className="inter-700-text natural-900-text font-28">{t('FORGOT_PASSWORD')}?</label>
                  <label className="inter-400-text natural-500-text text-center">
                    {t('ENTER_EMAIL_ADDRESS_SEND_CODE')}
                  </label>
                </div>
                {/* end */}
                {isNetworkError && (
                  <div className="flex error-message-alert items-center justify-between  w-full">
                    <label className="inter-400-text error-text py-3 w-full">{t('OFFLINE_OR_POOR_WIFI')}</label>
                    <Closeicon />
                  </div>
                )}
                <div className="w-full flex gap-5 form flex-column items-center">
                  <div className="w-full flex-column gap-5px">
                    <label className="inter-500-text natural-900-text">{t('EMAIL_ADDRESS')}</label>
                    <input
                      className={classNames('input radius-2', emailError && 'error-border')}
                      placeholder={t('ENTER_EMAIL_ADDRESS')}
                      value={email}
                      onChange={e => {
                        const newEmail = e.target.value;
                        setEmail(newEmail);
                        setEmailError(!isEmailValid(newEmail));
                      }}
                    />
                    {emailError && (
                      <label className="inter-400-text error-text block mt-2">{t('INVALID_EMAIL_FORMAT')}</label>
                    )}
                  </div>
                </div>
                <div className="w-full flex flex-column gap-5 items-center">
                  <Button
                    size="large"
                    label={t('SEND_EMAIL')}
                    className={classNames('primary', !active && 'disabled')}
                    onClick={resetPassword}
                  />
                  <a className="inter-500-text primary-text cursor" onClick={onTryAnotherMethod}>
                    {t('TRY_ANOTHER_METHOD')}
                  </a>
                </div>
              </>
            ) : currentStep === 'MOBILE' ? (
              <>
                {/* Heading */}
                <div className="flex flex-column items-center gap-2">
                  <label className="inter-700-text natural-900-text font-28">{t('FORGOT_PASSWORD')}?</label>
                  <label className="inter-400-text natural-500-text text-center">
                    {t('ENTER_PHONE_NUMBER_ASSOCIATED_YOUR_ACCOUNT')}
                  </label>
                </div>
                {/* end */}
                {isNetworkError && (
                  <div className="flex error-message-alert items-center justify-between  w-full">
                    <label className="inter-400-text error-text py-3 w-full">{t('OFFLINE_OR_POOR_WIFI')}</label>
                    <Closeicon />
                  </div>
                )}
                <div className="w-full flex gap-5 form flex-column items-center">
                  <div className="w-full flex-column gap-5px">
                    <PhoneInput
                      selectedCountry={user.country}
                      setSelectedCountry={country => setUser({ ...user, country, phone: '' })}
                      phone={user.phone}
                      setPhone={number => {
                        setUser({ ...user, phone: number });
                        setPhoneError(false);
                      }}
                      error={phoneError}
                    />
                    {phoneError && <label className="inter-400-text error-text block mt-2">{t(errorText)}</label>}
                  </div>
                </div>
                <div className="w-full flex flex-column gap-5 items-center">
                  <Button
                    size="large"
                    label={t('SEND_SMS')}
                    className={classNames('primary', !active && 'disabled')}
                    onClick={sendSms}
                  />
                  <a className="inter-500-text primary-text cursor" onClick={onTryAnotherMethod}>
                    {t('TRY_ANOTHER_METHOD')}
                  </a>
                </div>
              </>
            ) : currentStep === 'OTP' ? (
              <>
                {/* Heading */}

                {resentOTP && (
                  <div className="error-message-alert items-center justify-between flex">
                    <p className="py-3 flex flex-column gap-1">
                      <label className="inter-600-text error-text ">{t('SOMETHING_WRONG')}</label>
                      <span className="inter-400-text error-text">{resentOTP}</span>
                    </p>
                    <Closeicon className="cursor" onClick={() => setResentOTP('')} />
                  </div>
                )}

                {resentSuccessMsg && (
                  <div className="success-message-alert items-center justify-between flex">
                    <p className="py-3 flex flex-column gap-1">
                      <label className="inter-600-text success-text ">
                        {mobileActive ? t('SEND_EMAIL') : t('SEND_EMAIL')}
                      </label>
                      <span className="inter-400-text success-text">{resentSuccessMsg}</span>
                    </p>
                  </div>
                )}

                <div className="flex flex-column items-center gap-2">
                  <label className="inter-700-text natural-900-text font-28">
                    {mobileActive ? t('SENT_YOU_AN_SMS') : t('SENT_YOU_AN_EMAIL')}
                  </label>
                  <label className="inter-400-text natural-500-text text-center">
                    {mobileActive ? t('WE_SENT_SMS_TO') : t('WE_SENT_EMAIL_TO')}{' '}
                    <span className="inter-500-text natural-500-text">{mobileActive ? phoneNumber : email}</span>.{' '}
                    {t('ENTER_THE_SIX_DIGIT')}
                  </label>
                </div>
                {/* end */}

                {!isOtpValid && (
                  <div className="error-message-alert items-center justify-between flex">
                    <p className="py-3 flex flex-column gap-1">
                      <label className="inter-600-text error-text ">{t('INCORRECT_CODE')}</label>
                      <span className="inter-400-text error-text">{otpError}</span>
                    </p>
                    <Closeicon className="cursor" onClick={() => setIsOtpValid(true)} />
                  </div>
                )}
                {isNetworkError && (
                  <div className="flex error-message-alert items-center justify-between  w-full">
                    <label className="inter-400-text error-text py-3 w-full">{t('OFFLINE_OR_POOR_WIFI')}</label>
                    <Closeicon />
                  </div>
                )}
                <div className="w-full flex gap-5 form flex-column items-center">
                  <div className="w-full items-center flex-column gap-5px">
                    <OtpInput
                      className={'otp-input'}
                      containerClass={'otp-content'}
                      errorStyle={'error-border'}
                      hasErrored={!isOtpValid}
                      inputStyle={'input input-style'}
                      isInputNum={true}
                      onChange={setOtpValue}
                      placeholder="000000"
                      value={otpValue}
                    />
                  </div>
                </div>
                <div className="w-full flex flex-column gap-5 items-center">
                  <Button
                    size="large"
                    label={loading ? <Sppiner /> : 'Continue'}
                    className={classNames('primary', (!otpValue || (otpValue && otpValue.length !== 6)) && 'disabled')}
                    onClick={sendOtp}
                    disabled={loading}
                  />
                  <div className="flex items-center">
                    <label className="inter-400-text natural-500-text text-center">
                      {t('DONT_RECEIVE_CODE')}
                      <span
                        className={`inter-600-text primary-text cursor ml-1 ${isResentLoading && 'disabled'}`}
                        onClick={resendOtp}>
                        {mobileActive ? t('RESEND_SMS') : t('RESEND_EMAIL')}
                      </span>
                    </label>
                  </div>
                  <a className="inter-500-text primary-text cursor" onClick={onTryAnotherMethod}>
                    {t('TRY_ANOTHER_METHOD')}
                  </a>
                </div>
              </>
            ) : currentStep === 'PASSWORD' ? (
              <>
                {/* Heading */}
                <div className="flex flex-column items-center gap-2">
                  <label className="inter-700-text natural-900-text font-28">{t('RESET_PASSWORD')}</label>
                  <label className="inter-400-text natural-500-text text-center">
                    {t('PASSWORD_MUST_DIFFRENT_FROM_PREVIOUS_PASSWORD')}
                  </label>
                </div>
                {/* end */}

                {pswError && (
                  <div className="error-message-alert items-center justify-between flex">
                    <p className="py-3 flex flex-column gap-1">
                      <label className="inter-600-text error-text ">{t('INCORRECT_PASSWORD')}</label>
                      <span className="inter-400-text error-text">{passwordError}</span>
                    </p>
                    <Closeicon />
                  </div>
                )}
                <div className="w-full flex gap-5 form flex-column items-center">
                  <div className="w-full flex-column gap-5px">
                    <label className="inter-500-text natural-900-text">{t('PASSWORD')}</label>
                    <div className="password-requirement-tooltip">
                      <CustomTooltip
                        id="password-requirement-tooltip"
                        place="left"
                        isOpen={
                          passwordError &&
                          (passwordRequirementError.minumumPasswordLengthError ||
                            passwordRequirementError.upperCaseError ||
                            passwordRequirementError.lowerCaseError ||
                            passwordRequirementError.numberError ||
                            passwordRequirementError.specialCharacterError)
                        }
                        className="password-requirement-tooltip"
                        content={passwordRequirementTooltip()}>
                        <div className="relative">
                          <input
                            placeholder={t('ENTER_NEW_PASSWORD')}
                            value={password}
                            className={`input radius-2 w-full password-input ${passwordError && 'error-border'}`}
                            onChange={e => setPassword(e.target.value)}
                            type={showPassword ? 'text' : 'password'}
                          />
                          <img
                            className="show-password"
                            src={showPassword ? HidePassword : ShowPassword}
                            alt="show password"
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        </div>
                      </CustomTooltip>
                    </div>
                  </div>
                  <div className="w-full flex-column gap-5px">
                    <label className="inter-500-text natural-900-text">{t('CONFIRM_PASSWORD')}</label>
                    <div className="relative">
                      <input
                        placeholder={t('CONFIRM_NEW_PASSWORD')}
                        value={confimPassword}
                        className={`input radius-2 w-full password-input ${passwordError && 'error-border'}`}
                        onChange={e => setConfirmPassword(e.target.value)}
                        type={showPassword ? 'text' : 'password'}
                      />
                      {passwordError && (
                        <label className="inter-400-text error-text block block mt-2">{passwordError}</label>
                      )}
                      <img
                        className="show-password"
                        src={showPassword ? HidePassword : ShowPassword}
                        alt="show password"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full flex flex-column gap-5 items-center">
                  <Button
                    size="large"
                    label={'Reset Password'}
                    className={classNames('primary', !passwordActive && 'disabled')}
                    onClick={activate}
                  />
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      </ForgotPasswordWrapper>
    </AuthContainer>
  );
};

const ForgotPasswordWrapper = styled.div`
  padding: 32px;
  .forgot-password-page {
    width: 370px;

    .fp-content {
      .subtitle {
        max-width: 360px;
        text-align: center;
      }

      .otp-content {
        justify-content: space-between;
        width: 100%;
        .otp-input {
          .input-style {
            width: 48px;
            height: 48px;
            border-radius: 8px;
          }

          .error-style {
            border: 1px solid #ff5b5b;
          }
        }
      }

      .error-input {
        border: 1px solid #ff406e;

        &:focus {
          border-color: #ff406e;
        }
      }
    }

    .password-input {
      padding-right: 48px;
    }

    .show-password {
      position: absolute;
      width: 24px;
      height: 24px;
      right: 16px;
      top: 12px;
      cursor: pointer;
    }

    .otp-error {
      width: 360px;
      background: #ff8888;
      border-radius: 8px;
      padding: 8px;

      img {
        width: 16px;
        height: 16px;
      }
    }

    .password-error {
      width: 330px;
      background: #ff8888;
      border-radius: 8px;

      img {
        width: 16px;
        height: 16px;
      }
    }

    .re-send {
      user-select: none;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .password-requirement-tooltip {
    background-color: white;
    color: ${({ theme }) => theme.natural_900};
    border-radius: 8px;
    filter: drop-shadow(0px 3px 3px #0000000d);
  }
`;

export default ForgotPassword;
