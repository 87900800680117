import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as AreaResizeIcon } from '../../assets/icons/area-resize.svg';
import { ReactComponent as EnergyLeafIcon } from '../../assets/icons/energy-leaf.svg';
import { ReactComponent as SolarPanelIcon } from '../../assets/icons/solar-panel.svg';
import { ReactComponent as SunIcon } from '../../assets/icons/sun.svg';
import IconContainer from '../../components/common/icon-container';
import { getFormattedNumberStyle } from '../../helpers/utils';
import { findClosestBuildingInsights } from './findClosestBuildingInsights';

const SolarApi = ({ coord: coordinate, map }) => {
  const theme = useTheme();

  const solarPanelPolygonReferencesRef = useRef(new Map());
  const solarPanelPolygonsRef = useRef([]);

  const [buildingInsights, setBuildingInsights] = useState(null);
  const [currentPanel, setCurrentPanel] = useState(0);
  const [data, setData] = useState({ maxArrayPanelsCount: 0, solarPanelConfigs: [], solarPanels: [] });

  const currentPanelData = data.solarPanelConfigs ? data.solarPanelConfigs[currentPanel] : null;
  const lastPanelData = data.solarPanelConfigs ? data.solarPanelConfigs[data.totalData - 1] : null;

  const location = useMemo(() => {
    return {
      latitude: coordinate.lat,
      longitude: coordinate.lng,
    };
  }, [coordinate]);

  const fetchSolarData = async () => {
    try {
      const buildingInsights = await findClosestBuildingInsights(process.env.REACT_APP_GOOGLE_API_KEY, {
        location,
        requiredQuality: 'LOW',
      });
      setBuildingInsights(buildingInsights);
    } catch (error) {
      console.error('Error fetching building insights', error);
    }
  };

  const onSliderChange = e => {
    setCurrentPanel(e.target.value);
  };

  useEffect(() => {
    if (coordinate) {
      fetchSolarData();
    }
  }, [coordinate]);

  useEffect(() => {
    if (buildingInsights) {
      const { solarPotential } = buildingInsights;
      const { solarPanelConfigs, maxArrayPanelsCount, buildingStats, maxSunshineHoursPerYear, solarPanels } =
        solarPotential;
      const totalData = solarPanelConfigs.length;
      setData({
        ...solarPotential,
        maxArrayPanelsCount,
        solarPanelConfigs,
        totalData,
        buildingStats,
        maxSunshineHoursPerYear,
        solarPanels: solarPanels ? solarPanels.map(s => ({ ...s, id: nanoid() })) : [],
      });
    }
  }, [buildingInsights]);

  useEffect(() => {
    if (data && data.solarPanelConfigs && data.solarPanels && data.solarPanels.length > 0) {
      const { solarPanelConfigs } = data;
      const solarPanelConfig = solarPanelConfigs[currentPanel] || { roofSegmentSummaries: [] };
      const solarPanelPolygons = [];
      const solarPanelPolygonReferences = solarPanelPolygonReferencesRef.current;
      let panelsCount = 0;

      solarPanelConfig.roofSegmentSummaries.forEach(roofSegmentSummary => {
        data.solarPanels
          .filter(solarPanel => solarPanel.segmentIndex === roofSegmentSummary.segmentIndex)
          .slice(0, Math.min(solarPanelConfig.panelsCount - panelsCount, roofSegmentSummary.panelsCount))
          .forEach(solarPanel => {
            let height = data.panelHeightMeters / 2;
            let width = data.panelWidthMeters / 2;

            if (solarPanel.orientation === 'LANDSCAPE') {
              const previousHeight = height;

              height = width;
              width = previousHeight;
            }

            const angle = roofSegmentSummary.azimuthDegrees;

            if (!solarPanelPolygonReferences.has(solarPanel.id)) {
              const center = {
                lat: solarPanel.center.latitude,
                lng: solarPanel.center.longitude,
              };

              const top = google.maps.geometry.spherical.computeOffset(center, height, angle + 0);
              const right = google.maps.geometry.spherical.computeOffset(center, width, angle + 90);
              const left = google.maps.geometry.spherical.computeOffset(center, width, angle + 270);

              const topRight = google.maps.geometry.spherical.computeOffset(top, width, angle + 90);
              const bottomRight = google.maps.geometry.spherical.computeOffset(right, height, angle + 180);
              const bottomLeft = google.maps.geometry.spherical.computeOffset(left, height, angle + 180);
              const topLeft = google.maps.geometry.spherical.computeOffset(left, height, angle + 0);

              solarPanelPolygonReferences.set(
                solarPanel.id,
                new google.maps.Polygon({
                  map: map,

                  fillColor: '#2B2478',
                  fillOpacity: 0.8,

                  strokeWeight: 1,
                  strokeColor: '#AAAFCA',
                  strokeOpacity: 1,

                  geodesic: false,

                  paths: [topRight, bottomRight, bottomLeft, topLeft],
                }),
              );
            }
            const polygon = solarPanelPolygonReferences.get(solarPanel.id);
            polygon?.setMap(map);

            solarPanelPolygons.push(polygon);
          });
        solarPanelPolygonsRef.current = solarPanelPolygons;
        solarPanelPolygonReferencesRef.current = solarPanelPolygonReferences;
        panelsCount += roofSegmentSummary.panelsCount;
      });
    }

    return () => {
      solarPanelPolygonsRef.current.forEach(polygon => polygon.setMap(null));
    };
  }, [currentPanel, data]);

  const renderData = (label, value, icon) => {
    return (
      <div className="flex items-center col-gap-1 w-full">
        <IconContainer
          Icon={icon}
          iconColor="primary_500"
          backgroundColor="natural_100"
          iconWidth={12}
          iconHeight={12}
        />
        <label className="inter-500-text font-10 flex-1 one-line">{label}</label>
        <label className="inter-600-text font-10 text-right">{value}</label>
      </div>
    );
  };

  const renderCircularProgress = (header, label, value, lastValue, icon, color, showRightBorder = false) => {
    const progress = (value / lastValue) * 75;

    return (
      <div
        className={classNames(
          'flex-column items-center flex-1 justify-center row-gap-2',
          showRightBorder && 'border-right',
        )}>
        <label className="inter-600-text font-12">{header}</label>
        <CircularProgress color={theme[color]} progress={`${progress}%`}>
          <div className="flex-column items-center justify-center data">
            <IconContainer Icon={icon} iconColor={color} backgroundColor="transparent" iconWidth={24} iconHeight={24} />
          </div>
        </CircularProgress>
        <label className="inter-500-text font-10 flex-1">{label}</label>
      </div>
    );
  };

  return (
    <SolarApiWrapper className="absolute">
      {buildingInsights ? (
        <div className="flex-column row-gap-2">
          <div className="pxy-3 bg-white radius-2 flex-column row-gap-2 data-wrapper">
            <label className="inter-600-text font-12 pb-1 border-bottom">Typical Installation</label>
            {renderData(
              'Sunshine hours/year',
              getFormattedNumberStyle(data.maxSunshineHoursPerYear, 'decimal', 0, 0),
              SunIcon,
            )}
            {renderData(
              'Area meters2',
              getFormattedNumberStyle(data.buildingStats?.areaMeters2 || 0, 'decimal', 0, 0),
              AreaResizeIcon,
            )}
            {renderData('Max panels count', data.maxArrayPanelsCount, SolarPanelIcon)}
            {renderData('Recommended Panels', data.maxArrayPanelsCount, SolarPanelIcon)}
            {renderData(
              'Your Yearly Energy',
              `${getFormattedNumberStyle(currentPanelData?.yearlyEnergyDcKwh || 0, 'decimal', 0, 0)} kWh`,
              EnergyLeafIcon,
            )}
          </div>
          <div className="pxy-2 bg-white flex items-center radius-2 ">
            {renderCircularProgress(
              'Panel Required',
              `${currentPanelData?.panelsCount}/${lastPanelData?.panelsCount}`,
              currentPanelData?.panelsCount || 0,
              lastPanelData?.panelsCount || 0,
              SolarPanelIcon,
              'primary_500',
              true,
            )}
            {renderCircularProgress(
              'Annual Energy',
              `${getFormattedNumberStyle(currentPanelData?.yearlyEnergyDcKwh || 0, 'decimal', 0, 0)} kWh`,
              currentPanelData?.yearlyEnergyDcKwh || 0,
              lastPanelData?.yearlyEnergyDcKwh || 0,
              EnergyLeafIcon,
              'success_500',
            )}
          </div>
          <div className="pxy-2 bg-white radius-2 flex col-gap-1 items-center">
            <label className="inter-600-text font-10">Panels Count</label>
            <input
              className="slider flex-1"
              type="range"
              min="0"
              max={data.totalData - 1}
              value={currentPanel}
              onChange={onSliderChange}
            />
            <label className="inter-600-text font-10">{currentPanelData?.panelsCount}</label>
          </div>
        </div>
      ) : null}
    </SolarApiWrapper>
  );
};

const SolarApiWrapper = styled.div`
  left: 8px;
  top: 8px;

  .data-wrapper {
    min-width: 268px;
  }
`;

const CircularProgress = styled.div`
  width: 72px;
  height: 72px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(-135deg);
  background: conic-gradient(rgb(232, 234, 237) 75%, transparent 0deg);

  &::before {
    content: '';
    position: absolute;
    inset: 0px;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background: conic-gradient(${({ color }) => color} ${({ progress }) => progress}, transparent 0);
  }

  .data {
    border-radius: 50%;
    background: white;
    font-weight: bold;
    text-align: center;
    transform: rotate(135deg);
    width: 56px;
    height: 56px;
  }
`;

export default SolarApi;
