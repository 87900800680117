import classNames from 'classnames';
import React from 'react';
import TimeField from 'react-simple-timefield';
import 'react-time-picker/dist/TimePicker.css';
import styled from 'styled-components';
import { ReactComponent as ClockIcon } from '../../../assets/icons/clock.svg';
import IconContainer from '../icon-container';

const TimeSelectorElement = ({
  selectedTime,
  setSelectedTime,
  placeholder = 'HH:MM',
  className = '',
  isRequired = false,
  error = false,
  showClockIcon = true,
  containerClassname = '',
  disabled = false,
  ...rest
}) => {
  return (
    <TimeSelectorElementWrapper
      className={classNames('flex flex-1', containerClassname, error && !selectedTime && 'error-border')}>
      {showClockIcon && (
        <IconContainer
          Icon={ClockIcon}
          iconColor="natural_900"
          backgroundColor="transparent"
          iconContainerClassname="o2"
        />
      )}
      <TimeField
        style={{ width: '100%' }}
        required={isRequired}
        className={classNames('inter-400-text', className)}
        disabled={disabled}
        placeholder={placeholder}
        value={selectedTime || undefined}
        onChange={(_, value) => setSelectedTime(value)}
        onFocus={e =>
          setTimeout(() => {
            e.target.select();
          }, 0)
        }
        {...rest}
      />
    </TimeSelectorElementWrapper>
  );
};

const TimeSelectorElementWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.natural_200};
  border-radius: 6px;
  padding: 4px 12px;

  input {
    border: none;
    padding: 4px 8px;
    display: flex;
    align-items: center;

    :disabled {
      background-color: unset;
    }
    ::placeholder {
      // opacity: 0.3;
      color: ${({ theme }) => theme.natural_400};
    }
  }

  &.error-border {
    input {
      border: 1px solid ${({ theme }) => theme.error_500};
    }
  }
`;

export default TimeSelectorElement;
