import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as CrossIcon } from '../../assets/icons/CrossIcons.svg';
import { ReactComponent as RightIcon } from '../../assets/icons/right-Icons.svg';
import { ReactComponent as SearchIcon } from '../../assets/images/search.svg';
import InputElement from '../../components/common/input';
import SearchableDropdown from '../../components/common/searchable-dropdown';
import { getPropertyAddress, getPropertyAddressById } from '../../store/features/newPropertySlice';
import { addToast } from '../../store/features/toastSlice';
const formatted_address_format = ['line1', 'line2', 'line3', 'city', 'state', 'postcode'];

const Address = ({ address, onAddressUpdate, setLoadingAddress, className = '' }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [search, setSearch] = useState('');

  const onAddressSelect = address => {
    setLoadingAddress(true);
    dispatch(getPropertyAddressById({ id: address.id }))
      .then(data => {
        onAddressUpdate(data);
      })
      .catch(e => {
        return '';
      })
      .finally(() => setLoadingAddress(false));
  };

  return (
    <AddressWrapper className={classNames('relative', className)}>
      <div className="search-icon absolute o03">
        <SearchIcon />
      </div>
      <SearchableDropdown
        loadOptionsOnMenuOpen={false}
        className="w-full"
        isDisabled={false}
        isCustomSearchable={false}
        placeholder={t('SEARCH_FOR_AN_ADDRESS_OR_POSTCODE')}
        customStyle={{
          control: { height: '32px', borderRadius: '100px', width: 350, background: '#F5F5F5' },
          valueContainer: { padding: '0 16px' },
        }}
        inputValue={search}
        onInputChange={setSearch}
        getOptionLabel={option => option.address}
        value={address ? { label: address, address } : null}
        onChange={onAddressSelect}
        isSearchable={true}
        defaultAdditional={{ page: 0, fetchFunction: getPropertyAddress, pageable: false }}
      />
    </AddressWrapper>
  );
};

const UserAddress = ({ setEditKey, userDetails, setUserDetails, onUpdateUserProfile }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [error, setError] = useState({});
  const [loadingAddress, setLoadingAddress] = useState(false);

  const { locations: userLocations } = userDetails || {};
  const locations = userLocations?.[0] || userLocations;
  const { formatted_address, line1, line2, line3, city, state, postcode } = locations || {};

  const onCancelUpdate = () => {
    setEditKey(null);
  };

  const checkAddressError = () => {
    const { formatted_address, line1, city, postcode } = locations || {};
    const error = {
      formatted_address: !formatted_address?.trim(),
      line1: !line1?.trim(),
      city: !city?.trim(),
      postcode: !postcode?.trim(),
    };
    setError(error);
    return Object.values(error).some(err => Boolean(err));
  };

  const onAddressUpdate = address => {
    const formatted_address = formatted_address_format
      .map(a => address[a])
      .filter(value => Boolean(value))
      .join(', ');
    setUserDetails({
      ...userDetails,
      locations: {
        is_billing: false,
        is_postal: false,
        is_primary: false,
        is_residential: false,
        ...address,
        formatted_address,
      },
    });
  };

  const onChangeAddress = (key, value) => {
    const updatedAddress = { ...locations, [key]: value };
    const formatted_address = formatted_address_format
      .map(a => updatedAddress[a])
      .filter(value => Boolean(value))
      .join(', ');

    setUserDetails({
      ...userDetails,
      locations: {
        is_billing: false,
        is_postal: false,
        is_primary: false,
        is_residential: false,
        ...updatedAddress,
        formatted_address,
      },
    });
  };

  const onUpdateAddressDetails = () => {
    if (checkAddressError()) {
      dispatch(addToast({ error: true, text: t('PLEASE_FILL_THE_REQUIRED_FIELDS') }));
      return null;
    } else {
      onUpdateUserProfile('ADDRESS');
    }
  };

  return (
    <EditProfileAddressWrapper className="flex-column relative">
      <div className="flex-column gap-6">
        <div className="flex-column items-start gap-2 justify-start">
          <p className="inter-500-text natural-900-text line-height-20 font-14">{t('ADDRESS')}</p>
          <Address
            address={formatted_address}
            onAddressUpdate={onAddressUpdate}
            loadingAddress={loadingAddress}
            setLoadingAddress={setLoadingAddress}
          />
        </div>

        <InputElement
          className="w-full"
          name={t('STREET_ADDRESS')}
          placeholder={t('EXAMPLE_WALNUT_AVENUE')}
          value={line1}
          onChange={value => onChangeAddress('line1', value)}
          error={error?.line1 && !locations?.line1}
        />

        <InputElement
          className="w-full"
          name={t('APARTMENT_SUITE')}
          sub_name={t('OPTIONAL')}
          placeholder={t('EXAMPLE_APARTMENT')}
          value={line2}
          onChange={value => onChangeAddress('line2', value)}
        />

        <InputElement
          className="w-full"
          name={t('LINE_3')}
          sub_name={t('OPTIONAL')}
          placeholder={t('LINE_3')}
          value={line3}
          onChange={value => onChangeAddress('line2', value)}
        />

        <div className="flex-column address-grid row-gap-6">
          <InputElement
            className="w-full"
            name={t('CITY_TOWN')}
            placeholder={t('EXAMPLE_ACOMB')}
            value={city}
            onChange={value => onChangeAddress('city', value)}
            error={error?.city && !locations?.city}
          />
          <InputElement
            className="w-full"
            name={t('POSTCODE')}
            placeholder={t('EXAMPLE_BN16_78F')}
            value={postcode}
            onChange={value => onChangeAddress('postcode', value)}
            error={error?.postcode && !locations?.postcode}
          />
        </div>
        <InputElement
          className="w-full"
          name={t('COUNTY')}
          sub_name={t('OPTIONAL')}
          placeholder={t('EXAMPLE_ISLE_OF_WIGHT')}
          value={state}
          onChange={value => onChangeAddress('state', value)}
          error={error?.state && !locations?.state}
        />
      </div>

      <div className="w-full flex justify-end">
        <div className="flex justify-center items-end radius-3 profile-update-action mt-1 border pxy-2 gap-2_5 absolute">
          <span
            className="flex justify-center items-center cross-icon border radius-100 cursor"
            onClick={onCancelUpdate}>
            <CrossIcon color="#171717" />
          </span>
          <span
            className="flex justify-center items-center right-icon border radius-100 cursor"
            onClick={() => onUpdateAddressDetails()}>
            <RightIcon color="#FFFFFF" />
          </span>
        </div>
      </div>
    </EditProfileAddressWrapper>
  );
};

const EditProfileAddressWrapper = styled.div`
  .address-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }
`;

const AddressWrapper = styled.div`
  width: 350px;

  .search-icon {
    top: 14px;
    left: 16px;
    z-index: 999;
  }

  .searchable-select__input-container,
  .searchable-select__placeholder,
  .searchable-select__single-value {
    margin-left: 20px;
  }
`;

export default UserAddress;
