import { motion } from 'framer-motion';
import React, { useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
// import { ReactComponent as PlusIcon } from '../../../assets/icons/add-icon.svg';
// import { ReactComponent as ArrowDownIcon } from '../../../assets/images/menu/arrow-down-black.svg';
// import { ReactComponent as ArrowLeftIcon } from '../../../assets/images/menu/arrow-right-next.svg';
import { ReactComponent as AddIcon } from '@assets/icons/add-icon.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/add-icon.svg';
import { ReactComponent as EmptyIcon } from '../../assets/icons/empty-document.svg';

import { useTranslation } from 'react-i18next';
import { OrganisationContext } from '../../context/organisationContext';
import Button from '../common/button/button';
import IconContainer from '../common/icon-container';
import NoData from '../common/no-data';
import TableHeader from '../common/table-header';
import TaskListItem from './activity-task-list-item';

const taskHeaders = [
  { name: 'NAME', key: 'NAME' },
  { name: 'SUBTASKS', key: 'SUBTASKS' },
  // { name: 'STATUS', key: 'STATUS' },
  { name: 'ASSIGNEE', key: 'ASSIGNEE' },
  { name: 'EVIDENCE', key: 'EVIDENCE' },
  { name: '', key: 'MENU' },
];

const Tasks = props => {
  const { t } = useTranslation();
  const { setSideModal } = useContext(OrganisationContext);
  const { loading = false, arrows = false, accordionContent = [], fetchActivityTasks, activityId, resources } = props;

  const handleShowAddTask = () => {
    setSideModal({
      type: 'add-new-task',
      taskType: 'job-activity-task',
      activityId: activityId,
      content: {
        resources: resources,
        fromActivity: true,
        onSuccess: () => fetchActivityTasks(),
      },
    });
  };

  return (
    <TaskListWrapper className="flex-column flex-1 radius-1 overflow-hidden">
      {loading ? (
        <div className="item flex items-center justify-center flex-1 mb-6">
          <Skeleton height={'270px'} containerClassName="line-height-1 h-full w-full" />
        </div>
      ) : (accordionContent || []).length > 0 ? (
        <div className="w-full card radius-1_5 border overflow-scroll">
          <div className="flex-column w-fit-content min-w-full h-auto">
            <TableHeader headers={taskHeaders} headerClassName="w-fit-content" />
            {accordionContent?.length > 0 && (
              <div className="flex items-center gap-1 pl-10 py-3 border-bottom" onClick={handleShowAddTask}>
                <IconContainer
                  backgroundColor="transparent"
                  Icon={PlusIcon}
                  iconWidth={12}
                  iconHeight={12}
                  iconColor="primary_500"
                />
                <label className="inter-500-text primary-text cursor font-14 line-height-20">Add new task</label>
              </div>
            )}
            {(accordionContent || []).map((task, index) => (
              <TaskListItem
                task={task}
                key={index}
                arrows={true}
                fetchTasks={() => fetchActivityTasks()}
                activityId={activityId}
              />
            ))}
          </div>
        </div>
      ) : (
        <>
          <NoData
            title={t('NO_TASK')}
            description={t('NO_TASK_HERE')}
            className="mt-12"
            EmptyIcon={EmptyIcon}
            iconClassName="mb-6 relative"
          />
          <div className="flex items-center justify-center mt-5">
            <Button
              fontSize="14px"
              size="average"
              borderRadius="100px"
              lableSize="font-14 inter-500-text line-height-20"
              className="primary px-4 py-2_5 ml-1_5 w-fit-content"
              icon={<AddIcon className="white-text mr-0" height={16} width={16} />}
              label={t('ADD_NEW_TASK')}
              onClick={handleShowAddTask}
            />
          </div>
        </>
      )}
    </TaskListWrapper>
  );
};

export const TaskListWrapper = styled(motion.div)`
  border-radius: 6px;
  .header-container,
  .data-container {
    display: grid;
    grid-template-columns: minmax(300px, 1fr) 100px 100px 100px 80px;
    column-gap: 48px;
    padding: 0 24px 0 46px;
    width: 100%;
  }

  .header-container {
    height: 52px;
    background-color: ${({ theme }) => theme.natural_50};
  }

  .no-result-container,
  .no-data-container {
    width: 300px;
  }
`;

export default Tasks;
