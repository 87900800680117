import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CheckedIcon } from '../../../assets/icons/right-Icons.svg';

const CommonStepper = ({ steps = [], stepperClassName = '', actvieStep = 0 }) => {
  return (
    <CommonStepperWrapper className={classNames('', stepperClassName)}>
      <ul className={classNames('flex common-stepper p-0')}>
        {steps?.length > 0 &&
          steps?.map((item, index) => {
            return (
              <li
                className={classNames(
                  'list-item flex-column items-center gap-2 relative',
                  actvieStep === index && 'active',
                  actvieStep >= index && actvieStep !== index && 'complete',
                )}
                key={index}>
                <span className="progress-bar flex items-center justify-center relative overflow-hidden bg-white">
                  {actvieStep >= index && <CheckedIcon width={12} height={12} className="white-text" />}
                </span>
                <p className="natural-900-text font-12 inter-500-text"> {item?.title}</p>
              </li>
            );
          })}
      </ul>
    </CommonStepperWrapper>
  );
};

export default CommonStepper;

const CommonStepperWrapper = styled.div`
  .common-stepper {
    list-style: none;
    .list-item {
      min-width: 120px;
      &::after {
        background-color: ${({ theme }) => theme.natural_200};
        height: 2px;
        width: 110px;
        content: '';
        position: absolute;
        top: 8px;
        left: 65px;
        z-index: 0;
      }
      &:last-child::after {
        display: none;
      }
      .progress-bar {
        width: 16px;
        height: 16px;
        border-radius: 50px;
        border: 2px solid ${({ theme }) => theme.natural_200};
        z-index: 5;
      }
    }
    .complete {
      .progress-bar {
        background-color: ${({ theme }) => theme.primary_500};
        border: none;
      }
      &::after {
        background-color: ${({ theme }) => theme.primary_500};
      }
    }
    .active {
      .progress-bar {
        box-shadow: 0px 0px 0px 4px ${({ theme }) => theme.focus_border};
        border: 2px solid ${({ theme }) => theme.primary_500};
      }
    }
  }
`;
