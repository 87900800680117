import classNames from 'classnames';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as High } from '../../../assets/icons/high.svg';
import { ReactComponent as Highest } from '../../../assets/icons/highest.svg';
import { ReactComponent as Low } from '../../../assets/icons/low.svg';
import { ReactComponent as Lowest } from '../../../assets/icons/lowest.svg';
import { ReactComponent as Medium } from '../../../assets/icons/medium.svg';
import { capitalize, formatText } from '../../../helpers/utils';
import IconContainer from '../icon-container';

const Status = ({
  withDot = false,
  icon = false,
  status = '',
  withDottedBorder = false,
  dotColor = null,
  statusText = null,
  statusTextClassName = 'inter-500-text font-12',
  statusWrapperClassName = '',
  sub_status = '',
  ...rest
}) => {
  const theme = useTheme();

  const getStatusStyle = status => {
    switch (status) {
      case 'DRAFT':
        return {
          color: theme.natural_600,
          dotColor: theme.natural_100,
          background: theme.natural_100,
        };
      case 'SENT':
      case 'PENDING':
      case 'INACTIVE':
      case 'SKIPPED':
      case 'BLOCK':
      case 'LABOUR':
      case 'MEDIUM':
        return {
          color: theme.orange_500,
          dotColor: theme.orange_50,
          background: theme.orange_50,
        };

      case 'ACCEPTED':
      case 'SUCCESS':
      case 'ACTIVE':
      case 'HOUSE':
      case 'LAND':
      case 'COMMERCIAL':
      case 'VERY_LOW':
      case 'LOW':
        return {
          color: theme.success_500,
          dotColor: theme.success_50,
          background: theme.success_50,
        };

      case 'EXPIRED':
      case 'REJECTED':
      case 'CANCELED':
      case 'FAILURE':
      case 'EXPENSE':
      case 'HIGH':
      case 'VERY_HIGH':
        return {
          color: theme.error_500,
          dotColor: theme.error_50,
          background: theme.error_50,
        };
      case 'RESIDENTIAL':
      case 'MATERIALS':
      case 'SINGLE':
      case 'VALIDATE':
        return {
          color: theme.primary_500,
          dotColor: theme.primary_500,
          background: theme.primary_50,
        };
      case 'QUOTE':
      case 'DEFAULT':
      case 'BUNDLE':
        return {
          color: theme.purple_500,
          dotColor: theme.purple_500,
          background: theme.purple_50,
        };

      case 'INACTIVE':
        return {
          color: theme.natural_700,
          dotColor: theme.natural_700,
          background: theme.natural_50,
        };

      default:
        return {
          color: theme.natural_600,
          dotColor: theme.natural_100,
          background: theme.natural_100,
        };
    }
  };

  const getStatusIcon = status => {
    switch (status) {
      case 'VERY_HIGH':
        return {
          icon: Highest,
        };
      case 'HIGH':
        return {
          icon: High,
        };
      case 'LOW':
        return {
          icon: Low,
        };
      case 'VERY_LOW':
        return {
          icon: Lowest,
        };
      default:
        return {
          icon: Medium,
        };
    }
  };

  return (
    <StatusWrapper
      className={classNames(
        'flex items-center justify-center',
        withDottedBorder && 'dotted-border',
        statusWrapperClassName,
      )}
      withDottedBorder={withDottedBorder}
      {...getStatusStyle(status)}
      {...rest}>
      {icon && (
        <IconContainer
          Icon={getStatusIcon(status).icon}
          iconColor={getStatusStyle(status).color}
          backgroundColor={getStatusStyle(status).background}
          iconWidth={14}
          iconHeight={14}
          iconContainerClassname="p-0"
          iconClassName={'mr-1'}
        />
      )}
      {withDot && <div className="dot mr-1" />}
      <span className={classNames('status-text', statusTextClassName)}>
        {statusText ? statusText : capitalize(formatText(status))}
        {sub_status && <span className="inter-400-text font-12 sub-status-text">{`: ${sub_status}`}</span>}
      </span>
    </StatusWrapper>
  );
};

const StatusWrapper = styled('div')`
  padding: 2px 10px;
  height: 24px;
  width: fit-content;
  border-radius: 18px;
  background: ${({ background }) => background};

  &.dotted-border {
    border: 1px dashed ${({ background, borderColor }) => borderColor || background};
  }

  .dot {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: ${({ color, dotColor }) => dotColor || color};
  }

  .icon {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: ${({ color, dotColor }) => dotColor || color};
  }

  .status-text,
  .sub-status-text {
    color: ${({ color }) => color};
  }
`;

export default Status;
