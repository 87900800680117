import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';

const InputNumber = ({
  name,
  is_required = false,
  value,
  defaultValue,
  onChange = () => {},
  placeholder,
  className = '',
  error,
  disabled = false,
  error_text = '',
  autofill = 'off',
  sub_name = '',
  ...props
}) => {
  return (
    <InputNumberWrapper className={className}>
      <div className="mb-1">
        <span className="inter-500-text natural-900-text">{name}</span>
        {is_required && <span className="required-star ml-1">*</span>}
        {sub_name && <span className="inter-400-text natural-400-text ml-1">{sub_name}</span>}
      </div>
      <input
        autoComplete={autofill}
        className={classNames('inter-400-text natural-900-text w-full input-element', error && 'input-error')}
        defaultValue={defaultValue}
        value={value}
        disabled={disabled}
        type="number"
        placeholder={placeholder}
        onChange={({ target: { value } }) => onChange(value)}
        {...props}
      />
      {error && error_text && (
        <div>
          <span className="inter-400-text font-12 error-text">{error_text}</span>
        </div>
      )}
    </InputNumberWrapper>
  );
};

const InputNumberWrapper = styled('div')`
  .input-element {
    outline: none;
    height: 44px;
    border: 1px solid ${({ theme }) => theme.lightGrey};
    border-radius: 6px;
    padding: 12px 16px;

    &::-webkit-inner-spin-button {
      opacity: 1;
    }

    &:focus {
      border: 1px solid ${({ theme }) => theme.focus_border};
      box-shadow: 0px 0px 0px 4px ${({ theme }) => theme.focus_border};
    }

    &::placeholder {
      color: ${({ theme }) => theme.natural_400};
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      /* -webkit-appearance: none; */
      margin: 0;
    }
  }

  .input-error {
    border-color: ${({ theme }) => theme.error_500};

    &:hover {
      border-color: ${({ theme }) => theme.error_500};
    }

    &:focus {
      border-color: ${({ theme }) => theme.error_500};
      box-shadow: none;
    }
  }
`;

export default InputNumber;
