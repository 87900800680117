import React, { Fragment, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import BreadcrumbWrapper from '../../components/common/breadcrumb-wrapper';
import Popover from '../../components/common/popover';
import SortPopover from '../../components/common/popover/sort-popover';
import MapPanels from '../../components/map-panels';
import PageHeader from '../../components/page-header';
import PropertyDetailsContainer from '../../components/property-details-container';
import PropertyList from '../../components/property-list';
import SystemOverview from '../../components/system-overview';
import WorkitemDetails from '../../components/workitem-details';
import { OrganisationContext } from '../../context/organisationContext';
import useDebounce from '../../helpers/useDebounceHook';
import { getPropertyList } from '../../store/features/propertySlice';
import RoofDetailsV1 from './RoofDetailsV1';
import RoofDetailsV2 from './RoofDetailsV2';

const Properties = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { propertyDetails } = useSelector(state => state.property);
  const { workitemDetails } = useSelector(state => state.board);
  const { setSideModal } = useContext(OrganisationContext);

  const [searchText, setSearchText] = useState('');
  const [showSortPopover, setShowSortPopover] = useState(false);
  const debouncedSearch = useDebounce(searchText, 500);
  const [orderBy, setOrderBy] = useState('DESC');
  const [sortBy, setSortBy] = useState('created_date');

  const onAddProperty = () => {
    setSideModal({
      type: 'add-new-property-side',
      content: {
        onSuccess: response => {
          navigate(`property/${response?.id}`);
          dispatch(
            getPropertyList({
              params: {
                page: 0,
                size: 20,
                search: debouncedSearch,
              },
            }),
          );
        },
      },
    });
  };
  const SortWrapper = useCallback(
    ({ children }) => (
      <Popover
        isOpen={showSortPopover}
        offset={[0, 2]}
        content={
          <SortPopover
            setShowSortPopover={setShowSortPopover}
            setMainOrderBy={setOrderBy}
            setMainSortBy={setSortBy}
            mainOrderBy={orderBy}
            mainSortBy={sortBy}
          />
        }>
        {children}
      </Popover>
    ),
    [showSortPopover],
  );

  return (
    <PropertiesWrapper className="flex-column flex-1">
      <Routes>
        <Route
          element={
            <PropertyDetailsWrapper className="flex-column flex-1">
              <BreadcrumbWrapper
                breadcrumbs={[
                  { text: t('PROPERTIES'), path: `/properties` },
                  { text: propertyDetails?.formatted_address, path: `/properties/property/${propertyDetails?.id}` },
                  { text: workitemDetails?.engagement?.name },
                ]}
              />
              <WorkitemDetails fromProperty />
            </PropertyDetailsWrapper>
          }
          path="/property/:property_id/workitems/:workitem_id/*"
        />
        <Route
          element={
            <PropertyDetailsWrapper className="flex-column flex-1 px-6 pt-6 bg-natural-150">
              <BreadcrumbWrapper
                breadcrumbs={[
                  { text: t('PROPERTIES'), path: `/properties` },
                  { text: propertyDetails?.formatted_address },
                ]}
              />
              <PropertyDetailsContainer />
            </PropertyDetailsWrapper>
          }
          path="/property/:property_id"
        />
        <Route
          element={
            <PropertyDetailsWrapper className="flex-column flex-1">
              <BreadcrumbWrapper
                breadcrumbs={[
                  { text: t('PROPERTIES'), path: `/properties` },
                  { text: propertyDetails?.formatted_address },
                ]}
              />
              <SystemOverview fromProperty />
            </PropertyDetailsWrapper>
          }
          path="/property/:property_id/roof-details/system-overview/:solar_id"
        />
        <Route
          element={
            <PropertyDetailsWrapper className="flex-column flex-1">
              <BreadcrumbWrapper
                breadcrumbs={[
                  { text: t('PROPERTIES'), path: `/properties` },
                  { text: propertyDetails?.formatted_address },
                ]}
              />
              <RoofDetailsV2 fromProperty />
            </PropertyDetailsWrapper>
          }
          path="/property/:property_id/roof-details"
        />
        <Route
          element={
            <PropertyDetailsWrapper className="flex-column flex-1">
              <BreadcrumbWrapper
                breadcrumbs={[
                  { text: t('PROPERTIES'), path: `/properties` },
                  { text: propertyDetails?.formatted_address },
                  { text: 'V1' },
                ]}
              />
              <RoofDetailsV1 fromProperty />
            </PropertyDetailsWrapper>
          }
          path="/property/:property_id/roof-details/v1"
        />
        <Route
          element={
            <PropertyDetailsWrapper className="flex-column flex-1">
              <BreadcrumbWrapper
                breadcrumbs={[
                  { text: t('PROPERTIES'), path: `/properties` },
                  { text: propertyDetails?.formatted_address },
                  { text: 'V2' },
                ]}
              />
              <MapPanels fromProperty />
            </PropertyDetailsWrapper>
          }
          path="/property/:property_id/roof-details/v2"
        />
        <Route
          index
          element={
            <Fragment>
              <PageHeader
                headerTitle={t('PROPERTIES')}
                showAddNewBtn
                showSearch
                showSort
                onSortClick={() => setShowSortPopover(prev => !prev)}
                onAddClick={onAddProperty}
                addButtonLabel={t('ADD_PROPERTY')}
                addBtnPermission="PROPERTIES_MANAGE"
                searchText={searchText}
                onSearchChange={setSearchText}
                SortWrapper={SortWrapper}
              />
              <PropertyList debouncedSearch={debouncedSearch} orderBy={orderBy} sortBy={sortBy} />
            </Fragment>
          }
        />
      </Routes>
    </PropertiesWrapper>
  );
};

const PropertiesWrapper = styled.div`
  max-height: 100vh;
  overflow: hidden;
`;

const PropertyDetailsWrapper = styled.div`
  max-height: 100vh;
  overflow: hidden;

  .page-header {
    padding: 12px 24px;
    background-color: white;
  }
`;

export default Properties;
