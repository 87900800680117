import classNames from 'classnames';
import React from 'react';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';

const MobileInput = ({ phone, setPhone, className = '', error = false, is_mobile = false }) => {
  const maskField = [/\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

  return (
    <PhoneInputWrapper className={`flex relative ${className}`}>
      <MaskedInput
        mask={maskField}
        value={phone || null}
        className={classNames('input w-full phone-input', error && 'error-border')}
        placeholder={is_mobile ? '0000 000 000' : '00000 000000'}
        guide={false}
        onChange={({ target: { value } }) => setPhone(value)}
      />
    </PhoneInputWrapper>
  );
};

const PhoneInputWrapper = styled('div')`
  .phone-input-dropdown {
    .organisations-border {
      border: none;
      height: 100%;
      width: 110px;
    }

    .option-name,
    .selected-name {
      font-size: 16px;
    }

    .mc-option-item:hover label {
      color: ${({ theme }) => theme.RegularText};
    }
  }
`;

export default MobileInput;
