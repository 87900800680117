import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/add-icon.svg';
import { ReactComponent as MouseClickIcon } from '../../../../assets/images/mouse-click.svg';
import { OrganisationContext } from '../../../../context/organisationContext';
import { getAutomationsDetails } from '../../../../store/features/automationsSlice';
import Button from '../../../common/button/button';
import IconContainer from '../../../common/icon-container';

const AutomationTrigger = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { id } = useParams();
  const { setModal } = useContext(OrganisationContext);

  const { automationDetails } = useSelector(state => state.automations);
  const { event_type } = automationDetails || {};

  const onAddTrigger = (isEdit = false) => {
    setModal({
      type: 'add-trigger',
      content: {
        automationDetails,
        event_type,
        isEdit: isEdit,
        onSuccess: () => dispatch(getAutomationsDetails(id)),
      },
    });
  };

  return (
    <AutomationTriggerWrapper className="flex-column w-full h-auto px-6 py-5 border radius-1_5 row-gap-6">
      <div className="flex col-gap-3 border-bottom pb-4">
        <IconContainer
          iconContainerClassname="border radius-1_5"
          backgroundColor="white"
          Icon={MouseClickIcon}
          iconColor="primary_500"
          iconWidth={35}
          iconHeight={35}
        />
        <div className="flex-column">
          <span className="inter-600-text natural-700-text font-18">{t('TRIGGER')}</span>
          <span className="inter-400-text natural-500-text">{t('AUTOMATION_TRIGGERED_FOLLOWING_EVENT')}</span>
        </div>
      </div>
      {!event_type && (
        <Button
          label={t('ADD_TRIGGER')}
          className="secondary"
          size="large"
          afterIcon={<PlusIcon className="primary-500-text" />}
          onClick={() => onAddTrigger()}
        />
      )}
      {event_type && (
        <div className="flex items-center border px-4 py-3 radius-1_5 event-type-container">
          <div className="flex pxy-1 border radius-1_5 mr-2 trigger-icon bg-white">
            <img src={event_type?.icon?.active} alt="event-type-icon" height={24} width={24} />
          </div>
          <div className="flex-column flex-1">
            <span className="inter-500-text natural-700-text">{event_type?.name}</span>
            <span className="inter-400-text natural-500-text font-12">{event_type?.description}</span>
          </div>
          <span className="inter-500-text primary-500-text cursor" onClick={() => onAddTrigger(true)}>
            {t('CHANGE')}
          </span>
        </div>
      )}
    </AutomationTriggerWrapper>
  );
};

const AutomationTriggerWrapper = styled('div')`
  .event-type-container {
    background-color: ${({ theme }) => theme?.natural_50};
  }
`;

export default AutomationTrigger;
